import { ListButton } from '../../shared/ui/lists';
import styles from '../../pages/settings/AuthorizedSubUser/style.module.scss';
import { SubUsersApi } from '../../api/endpoints/subProfiles';
import { Button } from '../../shared/ui/buttons';
import { ChevronRightIcon } from '../../shared/ui/icons';
import { Typography } from '../../shared/ui/typography';
import { TransitionAnimation } from '../../shared/ui/animations';
import { Status } from '../../enums';

interface Props {
  data: SubUsersApi[];
  onClick(subUserId: number): void;
}

const getConnectedCards = (item: SubUsersApi) => 
  item.attachedPaymentCards?.filter((pc) => pc.status !== Status.Closed) ?? [];

export const SubUsersList = ({ data, onClick }: Props) => {
  return (
    <TransitionAnimation>
      <ListButton className={styles.listBtn}>
        {data.map((item: SubUsersApi) => {
          const cards = getConnectedCards(item);
          return (
            <Button
              key={item.id}
              endIcon={<ChevronRightIcon />}
              onClick={() => onClick(item.id)}
            >
              <div className={styles.boxText}>
                <Typography>
                  {item.givenName} {item.familyName}
                </Typography>
                <Typography className={styles.subLabel}>
                  {cards.length + ' connected cards'}
                </Typography>
              </div>
            </Button>
          );
        })}
      </ListButton>
    </TransitionAnimation>
  );
};
