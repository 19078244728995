import React, { FC } from "react";
import styles from "../style.module.scss";
import BeatLoader from "react-spinners/BeatLoader";
import AccountButtons from "../../../../components/button/AccountButtons";

const BUTTON_WIDTH = 285;

interface Props {
  onSubmit: () => void;
  onCancel?: () => void;
  disabled: boolean;
  isLoading: boolean;
}

const LimitButtons: FC<Props> = ({
  onSubmit,
  onCancel,
  disabled,
  isLoading,
}) => {
  return (
    <div className={styles.limitButtons}>
      <AccountButtons
        disabled={isLoading || disabled}
        name={isLoading ? "" : "Save changes"}
        handleSubmit={onSubmit}
        width={BUTTON_WIDTH}
      >
        {isLoading && <BeatLoader size={10} color="#939598" />}
      </AccountButtons>

      <AccountButtons
        disabled={false}
        secondary
        name="Cancel"
        handleSubmit={onCancel}
        width={BUTTON_WIDTH}
      />
    </div>
  );
};

export default LimitButtons;
