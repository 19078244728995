import * as yup from 'yup';

const notOnlyWhitespace = (value: string) => {
  return value ? value.trim().length > 0 : false;
}


export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Please enter a password')
    .test('not-only-whitespace', 'Password cannot be only whitespace', notOnlyWhitespace), 
  
  newPassword: yup
    .string()
    .required('Please enter a New password')
    .min(8, 'New password  must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'New password needs: 1 digit, 1 lowercase, 1 uppercase, 1 special character'
    )
    .test('not-only-whitespace', 'New password cannot be only whitespace', notOnlyWhitespace), 
  
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'New password and Confirm password must match') 
    .required('Please enter a Confirm password') 
    .min(8, 'Confirm password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'New password needs: 1 digit, 1 lowercase, 1 uppercase, 1 special character'
    )
    .test('not-only-whitespace', 'Confirm password cannot be only whitespace', notOnlyWhitespace), 
})