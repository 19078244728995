import { useState } from 'react';
import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { IntroReissue } from './components/introReissue';
import { PhysicalCardReissue } from './components/physicalCardReissue';
import { PhysicalCardConfirm } from './components/physicalCardConfirm';
import { useBoolean } from 'shared/hooks/useBoolean';
import { FullName, FullAddress } from 'pages/cardManage/types';
import { ReissueReason, DeliveryMethod } from 'api/endpoints/paymentCards';

interface Props {
  isShow: boolean;
  onClose: () => void;
}

const initialFullAddress = {
  locality: '',
  postalCode: '',
  region: '',
  streetAddress: '',
  additionalAddress: '',
};

const initialFullName = {
  givenName: '',
  familyName: '',
};

export const ReissueCard = (props: Props) => {
  const { isShow, onClose } = props;

  const [deliveryService, setDeliveryService] = useState<
    DeliveryMethod | string
  >('');
  const [selectedReason, setSelectedReason] = useState<ReissueReason | null>(
    null
  );
  const [fullAddress, setFullAddress] =
    useState<FullAddress>(initialFullAddress);

  const [fullName, setFullName] = useState<FullName>(initialFullName);

  const anotherAddressBool = useBoolean();

  const darawerOnClose = () => {
    setSelectedReason(null);
    setDeliveryService('');
    setFullAddress(initialFullAddress);
    setFullName(initialFullName);
    anotherAddressBool.setFalse();
    onClose();
  };

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'Re-issue card',
        prevId: null,
        Element: (
          <IntroReissue
            darawerOnClose={darawerOnClose}
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Re-issue physical card',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <PhysicalCardReissue
            deliveryService={deliveryService}
            setDeliveryService={setDeliveryService}
            anotherAddressBool={anotherAddressBool}
            setFullAddress={setFullAddress}
            setFullName={setFullName}
          />
        ),
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Re-issue physical card',
        prevId: '1',
        resetStepsOnReach: true,
        Element: (
          <PhysicalCardConfirm
            deliveryService={deliveryService}
            fullAddress={fullAddress}
            fullName={fullName}
            selectedReason={selectedReason}
            darawerOnClose={darawerOnClose}
          />
        ),
      },
    ],
  ];

  const MapSteps = new Map(steps);
  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={darawerOnClose}
    />
  );
};
