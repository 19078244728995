import React from 'react'
import {
  FormControlLabel as FormControlLabelMUI,
  FormControlLabelProps as FormControlLabelPropsMUI,
  Radio as RadioMUI,
  RadioProps as RadioPropsMUI,
} from '@mui/material'

import styles from './styles.module.scss'
import cn from 'classnames'

type LabelProps = Pick<
  FormControlLabelPropsMUI,
  'labelPlacement' | 'inputRef' | 'className'
>

interface RadioProps
  extends Pick<
    RadioPropsMUI,
    | 'checked'
    | 'disabled'
    | 'required'
    | 'id'
    | 'name'
    | 'value'
    | 'onChange'
    | 'inputRef'
  > {
  LabelProps?: LabelProps
  label?: string | React.ReactNode
}

export const Radio = (props: RadioProps) => {
  const { label, LabelProps, ...rest } = props

  const labelClasses: FormControlLabelPropsMUI['classes'] = {
    root: cn(styles.labelRoot),
    label: styles.label,
  }
  const radioClasses: RadioPropsMUI['classes'] = {
    root: cn(styles.radioRoot),
    checked: styles.checked,
  }

  if (label) {
    return (
      <FormControlLabelMUI
        label={label}
        classes={labelClasses}
        control={
          <RadioMUI
            classes={radioClasses}
            {...rest}
          />
        }
        {...LabelProps}
      />
    )
  }
  return (
    <RadioMUI
      classes={radioClasses}
      {...rest}
    />
  )
}
