import { TransitionAnimation } from 'shared/ui/animations';
import SimpleContainer from 'shared/components/containers/simpleContainer';
import {
  selectFromFinancialAccount,
  selectTransferType,
  TransferType,
  StepContent,
  SourceAccount,
  setTotalAmount,
  setTransfers,
  selectTransfers,
  createBatchTransfersSchema,
} from '../../model';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import helper from 'services/helper';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees, Payee } from 'api/hooksApi/useExternalPayees';
import { findObjById } from 'entities/transfers';
import { TotalBalance } from '../total-balance';
import { SourceAccountList } from '../source-account-list';
import { useMediaQuery, Divider } from '@mui/material';
import { BatchTransferFormItem } from './batch-transfer-form-item';
import {
  useNeteviaPayeesControllerQuery,
  PayeeType,
} from 'api/endpoints/fundsMovement';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';
import { Skeleton } from 'components/skeleton';
interface BatchTransferFormProps {
  setStepContent: (step: StepContent) => void;
}

export const BatchTransferForm = ({
  setStepContent,
}: BatchTransferFormProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { payees, isLoadingPayees } = useExternalPayees();
  const { data: neteviaPayees, isLoading: isLoadingNeteviaPayees } =
    useNeteviaPayeesControllerQuery();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount);
  const transferType = useAppSelector(selectTransferType);
  const transfers = useAppSelector(selectTransfers);

  const initialFormState = {
    from: fromFinancialAccount?.id || '',
    to: '',
    amount: '',
    memo: '',
  };

  const isTransferToNetevia = transferType === TransferType.toNetevia;

  const formik = useFormik({
    initialValues: {
      forms: transfers || [initialFormState],
    },
    validateOnMount: true,
    validationSchema: createBatchTransfersSchema(unclosedFinancialAccounts),
    onSubmit: (values) => {
      dispatch(
        setTransfers({
          value: values.forms,
        })
      );

      dispatch(
        setTotalAmount({
          value: totalAmount,
        })
      );
      setStepContent(StepContent.confirm);
    },
  });

  const calculateTotalByAccountForSourceList = () =>
    formik.values.forms.reduce((acc: SourceAccount[], curr) => {
      const existingItem = acc.find(
        (item) => item.fromFinancialAccount === curr.from
      );
      const amountToAdd = !isNaN(parseFloat(curr.amount))
        ? parseFloat(curr.amount)
        : 0;

      if (existingItem) {
        existingItem.totalAmount += amountToAdd;
      } else {
        acc.push({
          fromFinancialAccount: curr.from,
          totalAmount: amountToAdd,
        });
      }

      return acc;
    }, []);

  const sourceList = calculateTotalByAccountForSourceList();

  const errorListTotalBalance = sourceList.some((item) => {
    const account = findObjById(
      item.fromFinancialAccount,
      unclosedFinancialAccounts
    );
    return item.totalAmount > account?.availableCash?.value;
  });

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const optionsToPayee = (payees) => {
    return payees.map((payee: Payee | PayeeType) => ({
      id: payee.id,
      value: payee.id,
      content: (
        <div>
          {payee.name} ({payee.last4})
        </div>
      ),
    }));
  };

  const applyAllAmount = (amountValue: string) => {
    formik.values.forms.forEach((_, index) => {
      formik.setFieldValue(`forms.${index}.amount`, amountValue);
    });
  };

  const applyAllMemo = (memo: string) => {
    const updatedForms = formik.values.forms.map((form) => ({
      ...form,
      memo,
    }));
    formik.setValues({
      ...formik.values,
      forms: updatedForms,
    });
  };

  const totalAmount = formik.values.forms.reduce(
    (acc, curr) => acc + (parseInt(curr.amount) || 0),
    0
  );

  const handleDeleteTransfer = (index: number) => {
    const newForms = formik.values.forms.filter((_, idx) => idx !== index);
    formik.setFieldValue('forms', newForms);
  };

  const handleAddAnotherTransfer = () => {
    formik.setFieldValue('forms', [...formik.values.forms, initialFormState]);
  };

  const disabled = !formik.isValid || errorListTotalBalance;

  const isLoading =
    financialAccountsIsLoading || isLoadingPayees || isLoadingNeteviaPayees;

  return (
    <TransitionAnimation>
      <SimpleContainer
        title={`Batch transfer - ${transferType}`}
        onTitleClick={() => setStepContent(StepContent.typeSelect)}
      >
        {isLoading ? (
          <Skeleton
            width={isMobile ? '100%' : '528px'}
            height='110px'
          />
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className={styles.container}
          >
            <div className={styles.boxInfo}>
              <SourceAccountList sourceList={sourceList} />
              {isMobile && <Divider />}
              <TotalBalance
                total={totalAmount}
                errorForTotalBalance={errorListTotalBalance}
              />
              {isMobile && <Divider />}
            </div>
            {formik.values.forms.map((form, index) => (
              <BatchTransferFormItem
                key={index}
                formIndex={index}
                formik={formik}
                handleDeleteTransfer={() => handleDeleteTransfer(index)}
                optionsFromAcc={optionsFromAcc}
                optionsToPayee={optionsToPayee(
                  isTransferToNetevia ? neteviaPayees?.payees : payees
                )}
                applyAllAmount={applyAllAmount}
                applyAllMemo={applyAllMemo}
              />
            ))}
            <Button
              className={styles.btn}
              variant='text'
              onClick={handleAddAnotherTransfer}
            >
              + Add another transfer
            </Button>
            <Button
              type='submit'
              className={styles.btnSubmit}
              variant='contained'
              disabled={disabled}
            >
              continue
            </Button>
          </form>
        )}
      </SimpleContainer>
    </TransitionAnimation>
  );
};
