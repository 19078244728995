import type { ButtonGroupProps as ButtonGroupMUIProps } from '@mui/material';
import { ButtonGroup as ButtonGroupMUI } from '@mui/material';
import styles from './styles.module.scss';
import cn from 'classnames';

interface ButtonGroupProps
    extends Pick<ButtonGroupMUIProps, 'className' | 'children'> {}

export const ButtonGroup = (props: ButtonGroupProps) => {
    const { children, className } = props;

    return (
        <ButtonGroupMUI
            orientation="vertical"
            fullWidth
            className={cn(styles.buttonGroup, className)}
            disableElevation
        >
            {children}
        </ButtonGroupMUI>
    );
};
