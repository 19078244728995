import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { EditOptions } from './components/editOptions';
import { EditSubUserForm } from './components/editSubUserForm';
import { ManageConnectedCards } from './components/mangeConnectedCards';
import { ManageConnectedAccounts } from './components/manageConnectedAccounts';

interface Props {
  isShow: boolean;
  onClose: () => void;
  subId: number;
  subUserGivenName: string;
  subUserFamilyName: string;
  readonly?: boolean;
  isConnectedAccountsHidden?: boolean;
}

export const EditSubUser = (props: Props) => {
  const {
    isShow,
    onClose,
    subId,
    subUserGivenName,
    subUserFamilyName,
    isConnectedAccountsHidden,
    readonly,
  } = props;

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: `Authorized User "${subUserGivenName} ${subUserFamilyName}"`,
        prevId: null,
        Element: (
          <EditOptions
            subId={subId}
            readonly={readonly}
            isConnectedAccountsHidden={isConnectedAccountsHidden}
          />
        ),
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: `Edit "${subUserGivenName} ${subUserFamilyName}"`,
        prevId: '0',
        resetStepsOnReach: true,
        Element: <EditSubUserForm subId={subId} />,
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Manage Connected Cards',
        prevId: '0',
        resetStepsOnReach: true,
        Element: <ManageConnectedCards />,
      },
    ],
    [
      '3',
      {
        id: '3',
        isCanGoBack: true,
        title: 'Manage Connected Accounts',
        prevId: '0',
        resetStepsOnReach: true,
        Element: <ManageConnectedAccounts subId={subId} />,
      },
    ],
  ];

  const MapSteps = new Map(steps);

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={onClose}
    />
  );
};
