import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useForgotPasswordMutation,
  useForgotPasswordOTPMutation,
} from "api/endpoints/account";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import ForgotForm from "../forgotForm";
import CheckBoxGroup from "./components/checkBoxGroup";
import { AnimateWrapper } from "components/animate-wrapper";
import RecoveryCode from "./components/recoveryCode";
import {
  useRestorePasswordMutation,
  useRestorePasswordOTPMutation,
} from "api/endpoints/account";
import * as yup from "yup";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";

const TIMER = 30;
export const EMAIL = "Email";
export const SMS = "SMS";
export type CheckBox = "Email" | "SMS";
const PASSWORD = "Password";
export const RECOVERY_EMAIL = "RecoveryEmail";
export const RECOVERY_SMS = "RecoverySMS";
type ForgotPassword = "Password" | "RecoveryEmail" | "RecoverySMS";

interface ValidErrors {
  verificateCode?: string;
  password?: string;
}

const ForgotPassword = () => {
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [forgotPasswordMutation, { isLoading: forgotPasswordIsLoading }] =
    useForgotPasswordMutation();
  const [
    forgotPasswordOTPMutation,
    { isLoading: forgotPasswordOTPdIsLoading },
  ] = useForgotPasswordOTPMutation();
  const [restorePassword, { isLoading: restorePasswordIsLoading }] =
    useRestorePasswordMutation();
  const [
    restorePasswordOTPMutation,
    { isLoading: restorePasswordOTPIsLoading },
  ] = useRestorePasswordOTPMutation();

  const [userName, setUserName] = useState<string>("");
  const [recoveryType, setRecoveryType] = useState<CheckBox>(EMAIL);
  const [verificateCode, setVerificateCode] = useState<string>("");
  const [pageMode, setPageMode] = useState<ForgotPassword>("Password");

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [validErrorCode, setValidErrorCode] = useState<string>("");
  const [validErrorPassword, setValidErrorPassword] = useState<string>("");
  const [pathPhone, setPathPhone] = useState<string>("");
  const [nextSendDate, setNextSendDate] = useState<Date | undefined>(undefined);
  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      ),
    verificateCode: yup
      .string()
      .matches(/^\d{6}$/, "Code should be exactly 6 digits long"),
  });

  const handleSubitForgotPassword = async () => {
    try {
      if (recoveryType === EMAIL) {
        const res = await forgotPasswordMutation({
          nickName: userName,
        }).unwrap();
        setPathPhone(res?.success);
        setPageMode(RECOVERY_EMAIL);
      } else {
        const res = await forgotPasswordOTPMutation({
          nickName: userName,
        }).unwrap();
        const currentDate = new Date();
        const nextSendDate = new Date(currentDate.getTime() + TIMER * 1000);
        setNextSendDate(nextSendDate);
        setPathPhone(res?.success);
        setPageMode(RECOVERY_SMS);
      }
    } catch (e: any) {
      console.log("error", e);
      setSnackBar({
        type: "error",
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleRestorePassword = async () => {
    try {
      await schema.validate({ verificateCode }, { abortEarly: false });
      if (password !== confirmPassword) {
        setValidErrorPassword(
          t("Settings.Passwords do not match", {
            defaultValue: "Passwords do not match",
          })
        );
        return;
      }
      let res;
      if (recoveryType === EMAIL) {
        res = await restorePassword({
          NickName: userName,
          Code: verificateCode,
          Password: password,
          PasswordConfirm: confirmPassword,
        }).unwrap();
        updateResponse(res);
      } else {
        res = await restorePasswordOTPMutation({
          NickName: userName,
          Code: verificateCode,
          Password: password,
          PasswordConfirm: confirmPassword,
        });
        updateResponse(res?.data);
      }
    } catch (error: any) {
      console.log("error", error);
      if (error.name === "ValidationError") {
        console.log("ValidationError", error.message);
        const errors: ValidErrors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });

        setValidErrorCode(errors.verificateCode ?? "");
      } else if (error?.data?.Password) {
        setValidErrorPassword(error?.data?.Password);
      } else {
        setSnackBar({
          type: "error",
          message: helper.formatErrors(error.data),
          isShow: true,
        });
      }
    }
  };

  const updateResponse = (response) => {
    if (response === 1) {
      setSnackBar({
        type: "success",
        message: t("Login.Your password has been successfully updated", {
          defaultValue: "Your password has been successfully updated.",
        }),
        isShow: true,
      });
      navigate("/login");
    } else {
      const message =
        response === 3
          ? t("common.The entered code is expired", {
              defaultValue: "The entered code is expired",
            })
          : t("common.The entered code is not correct", {
              defaultValue: "The entered code is not correct",
            });
      setSnackBar({
        type: "error",
        message: message,
        isShow: true,
      });
    }
  };

  const handleOnUserName = (e) => {
    setUserName(e.target.value.trim());
  };
  const handleUserNameOnCancel = () => {
    navigate("/login");
  };
  const handleChangeType = (type) => {
    setRecoveryType(type);
  };

  const handleOnChangeVerificateCode = (e) => {
    setVerificateCode(e.target.value.trim());
    setValidErrorCode("");
  };
  const handeleVerificateOnCancel = () => {
    setRecoveryType(EMAIL);
    setUserName("");
    setValidErrorPassword("");
    setVerificateCode("");
    setPassword("");
    setConfirmPassword("");
    setValidErrorCode("");
    setPageMode(PASSWORD);
  };
  return (
    <AnimateWrapper className="fade">
      <div className={styles.conteiner}>
        <div className={styles.conteiner_content}>
          {pageMode === PASSWORD && (
            <ForgotForm
              title={t("Login.Password recovery", {
                defaultValue: "Password recovery",
              })}
              subTitle={t("Login.Enter your username ", {
                defaultValue:
                  "Enter your username and choose your preferred method of verification for confirming password recovery.",
              })}
              inputLabel={t("common.Username", {
                defaultValue: "Username",
              })}
              inputValue={userName}
              handleOnChange={handleOnUserName}
              isLoading={forgotPasswordIsLoading || forgotPasswordOTPdIsLoading}
              sumbitButtonText={'Recover password'}
              onSumbit={handleSubitForgotPassword}
              cancelButtonText={t("Login.Back to login", {
                defaultValue: "Back to login",
              })}
              onCancel={handleUserNameOnCancel}
              inputMaxLength={50}
            >
              <CheckBoxGroup
                type={recoveryType}
                handleChangeType={handleChangeType}
              />
            </ForgotForm>
          )}
          {pageMode === RECOVERY_EMAIL && (
            <RecoveryCode
              code={verificateCode}
              onChangeCode={handleOnChangeVerificateCode}
              codeMaxLength={6}
              onCancel={handeleVerificateOnCancel}
              isLoading={restorePasswordIsLoading}
              onSumbit={handleRestorePassword}
              password={password}
              confirmPassword={confirmPassword}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              validErrorPassword={validErrorPassword}
              setValidErrorPassword={setValidErrorPassword}
              validErrorCode={validErrorCode}
              typeMode={RECOVERY_EMAIL}
              path={pathPhone}
            />
          )}
          {pageMode === RECOVERY_SMS && (
            <RecoveryCode
              code={verificateCode}
              onChangeCode={handleOnChangeVerificateCode}
              codeMaxLength={6}
              onCancel={handeleVerificateOnCancel}
              isLoading={restorePasswordOTPIsLoading}
              onSumbit={handleRestorePassword}
              password={password}
              confirmPassword={confirmPassword}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              validErrorPassword={validErrorPassword}
              setValidErrorPassword={setValidErrorPassword}
              validErrorCode={validErrorCode}
              typeMode={RECOVERY_SMS}
              path={pathPhone}
              nextSendDate={nextSendDate}
              setNextSendDate={setNextSendDate}
              onResend={handleSubitForgotPassword}
            />
          )}
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default ForgotPassword;
