import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import HistoryListItem from "./components/historyListItem";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import { Skeleton } from "components/skeleton";
import Filter from "./components/filter";
import OpenFilter from "./components/openFilter";
import { useBoolean } from "shared/hooks/useBoolean";
import { Ticket } from "api/endpoints/tickets";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
import { TicketStatus } from "api/endpoints/tickets";
import moment from "moment";

const initialPeriodDate = {
  dateFrom: dayjs().startOf('month').startOf('day'), 
  dateTo: dayjs().endOf('day'),
}

const History = () => {
  const { history, historyIsLoading } = useSupportHistory();

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [periodDate, setPeriodDate] = useState<DateRange<Dayjs | null>>([initialPeriodDate.dateFrom, initialPeriodDate.dateTo]);
  const [type, setType] = useState<TicketStatus[]>([])

  const showFilterBool = useBoolean()

  useEffect(() => {
    if (history) {
      setTickets(history);
    }
  }, [history]);

  useEffect(() => {
    if (!showFilterBool.value && !historyIsLoading) {
      filtered();
    }
  }, [ periodDate, type, historyIsLoading ]);

  const filteredByDate = (periodDate) => {
    const start = periodDate[0].toDate();
    const end = periodDate[1].toDate().setHours(23, 59, 59, 999);

    return history.filter((item) => {
      const itemDate = new Date(item.createdDate);
      return itemDate >= start && itemDate <= end;
    });
  };

  const filteredByType = (data: Ticket[]) => {
    return data.filter((item) => (
      (type.includes(TicketStatus.NEW) && item.status === TicketStatus.NEW) ||
      (type.includes(TicketStatus.WORKING_IN_PROGRESS) && 
      (item.status === TicketStatus.WORKING_IN_PROGRESS || item.status === TicketStatus.PENDING)) ||
      (type.includes(TicketStatus.RESOLVED) && item.status === TicketStatus.RESOLVED)
    ));
  };

  const filtered = () => {
    let filteredData = history.sort((a, b) => {
      return moment(b.createdDate).diff(a.createdDate);
    });
 
    if (periodDate[0] && periodDate[1]) {
      filteredData = filteredByDate(periodDate);
    }
    if (!!type.length) {
      filteredData = filteredByType(filteredData);
    }
    setTickets(filteredData);
    showFilterBool.setFalse()
  };


  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <OpenFilter
          showFilterBool={showFilterBool}
          periodDate={periodDate} 
          setPeriodDate={setPeriodDate}
          type={type}
          setType={setType}
        />
        {historyIsLoading ? (
          <Skeleton width="100%" height="110px" />
        ) : (
          <HistoryListItem historyArr={tickets} />
        )}

        {showFilterBool.value && (
          <Filter
            showFilterBool={showFilterBool}
            periodDate={periodDate} 
            setPeriodDate={setPeriodDate}
            type={type}
            setType={setType}
            setTickets={setTickets}
          />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default History;
