import { FC } from "react";
import styles from "./style.module.scss";

interface BadgeProps {
  label: string;
  color: string;
  styleClass?: string;
  textColor?: string
}

const Badge: FC<BadgeProps> = ({ label, color, styleClass, textColor }) => {
  const badgeBgColor = { backgroundColor: color, color: textColor };

  return (
    <div
      className={styles[styleClass ? styleClass : "wrapper"]}
      style={badgeBgColor}
    >
      <span className={styles.text}>{label}</span>
    </div>
  );
};

export default Badge;
