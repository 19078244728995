import { TextInput } from 'shared/ui/inputs';
import { SearchIcon } from 'shared/ui/icons';
import { InputAdornment } from '@mui/material';
import { useTransactions } from 'pages/externalAccountComponent/provider';

import styles from './style.module.scss';

export const FilterByName = () => {
  const { searchTransactionByName, setSearchTransactionByName } = useTransactions();

  return (
    <TextInput
     fullWidth
      placeholder='Search'
      value={searchTransactionByName}
      onChange={(event) => setSearchTransactionByName(event.target.value)}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            className={styles.adornment}
          >
            <SearchIcon />
          </InputAdornment>
        ),
        className: styles.input
      }}
    />
  );
};
