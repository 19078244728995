import { TransitionAnimation } from 'shared/ui/animations';
import { useSearchParams } from 'react-router-dom';
import { FilterByDate } from './componentns/filterByDate';
import { useFormik } from 'formik';
import { Dayjs } from 'dayjs';
import { TransactionFilterSchema } from './componentns/validationFilterTransactionSchema';
import { LoadingButton } from '@mui/lab';
import { initialTransactionApiParams } from 'pages/accountComponent/contsants';
import { useTransactions } from '../provider';

import styles from './style.module.scss';

export const FilterTransactions = () => {
  const [, setSearchParams] = useSearchParams();
  const {
    isFetchingTransactions,
    transactionApiParams,
    setTransactionApiParams,
  } = useTransactions();

  const formik = useFormik({
    initialValues: {
      date: [transactionApiParams.dateFrom, transactionApiParams.dateTo] as [
        Dayjs | null,
        Dayjs | null,
      ],
    },
    validationSchema: TransactionFilterSchema,
    onSubmit: (values) => {
      setTransactionApiParams({
        dateFrom: values.date[0] as Dayjs,
        dateTo: values.date[1] as Dayjs,
      });
      setSearchParams({});
    },
  });

  const onReset = () => {
    formik.setValues({
      date: [
        initialTransactionApiParams.dateFrom,
        initialTransactionApiParams.dateTo,
      ],
    });
  };

  return (
    <TransitionAnimation>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.boxScroll}>
          <FilterByDate formik={formik} />
        </div>
        <div className={styles.boxBtn}>
          <LoadingButton
            variant='text'
            onClick={() => onReset()}
            loading={isFetchingTransactions}
          >
            clear all
          </LoadingButton>
          <LoadingButton
            type='submit'
            variant='contained'
            classes={{ disabled: styles.btnDisabled }}
            disabled={Object.values(formik.errors).some(Boolean)}
            loading={isFetchingTransactions}
          >
            apply
          </LoadingButton>
        </div>
      </form>
    </TransitionAnimation>
  );
};
