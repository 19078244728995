import { FC } from "react";
import { FormControl, TextField, InputAdornment } from "@mui/material";
import errorIcon from "assets/svg/error-red.svg";
import { ChangeEvent } from "react";

interface PoinstInputProps {
  error: boolean;
  value: string;
  handleOnChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label: string;
}

const PoinstInput: FC<PoinstInputProps> = ({
  value,
  handleOnChange,
  error,
  label,
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-required"
        label={label}
        value={value}
        onChange={handleOnChange}
        fullWidth
        inputProps={{ maxLength: 16 }}
        error={error}
        type="number"
        autoComplete="off"
        sx={{
          "& .MuiOutlinedInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {error && <img src={errorIcon} alt="Error" />}
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
export default PoinstInput;
