import { Popup } from 'components/popup';
import { Period } from 'api/endpoints/statements';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import styles from './style.module.scss';
import { FileFormat } from 'api/endpoints/statements';
import { TextInput } from 'shared/ui/inputs';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';

const schemaEmail = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Please enter a valid email address'
    ),
});

interface AllAccountsModalProps {
  email: string;
  setConfirmPeriod: (date: Period | undefined) => void;
  confirmPeriod: Period;
  isLoading: boolean;
  handleSubmit: (email: string, format: FileFormat) => Promise<void>;
}

export const AllAccountsModal = (props: AllAccountsModalProps) => {
  const { setConfirmPeriod, confirmPeriod, isLoading, email, handleSubmit } =
    props;

  const formik = useFormik({
    initialValues: {
      email: email,
      format: '',
    },
    validationSchema: schemaEmail,
    onSubmit: (values) => {
      handleSubmit(values.email, values.format as FileFormat);
    },
  });

  return (
    <Popup onClose={() => setConfirmPeriod(undefined)}>
      <form
        className={styles.container}
        onSubmit={formik.handleSubmit}
      >
        <Typography className={styles.title}>
          Enter your email address to receive the document directly to your
          inbox.
        </Typography>
        <TextInput
          placeholder='Email address'
          error={formik.errors.email}
          {...formik.getFieldProps('email')}
        />
        <div className={styles.boxBtn}>
          {confirmPeriod.fileFormats.includes(FileFormat.PDF) && (
            <LoadingButton
              type='submit'
              variant='contained'
              onClick={() => formik.setFieldValue('format', FileFormat.PDF)}
              loading={isLoading}
              disabled={!!formik.errors.email}
              classes={{root:styles.btnRoot, disabled: styles.btnDisabled}}
            >
              SEND PDF
            </LoadingButton>
          )}
          {confirmPeriod.fileFormats.includes(FileFormat.CSV) && (
            <LoadingButton
              type='submit'
              variant='contained'
              onClick={() => formik.setFieldValue('format', FileFormat.CSV)}
              loading={isLoading}
              disabled={!!formik.errors.email}
              classes={{root:styles.btnRoot, disabled: styles.btnDisabled}}
            >
              SEND CSV
            </LoadingButton>
          )}
        </div>
      </form>
      <div className={styles.boxCancel}>
        <Button
          variant='text'
          className={styles.btnCancel}
          onClick={() => setConfirmPeriod(undefined)}
        >
          Cancel
        </Button>
      </div>
    </Popup>
  );
};
