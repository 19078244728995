import styles from './style.module.scss';
import { AnimateWrapper } from 'components/animate-wrapper';
import cx from 'classnames';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import HeaderButton from 'pages/login/components/headerButton';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from 'shared/hooks/useScreensSize';
import {
  NeteviaLogoIcon,
  FinanceChipIcon,
  CashIcon,
  CurrencyOutlinedsIcon,
} from 'shared/ui/icons';

const StartMainSection = () => {
  const { t, isLoadingLanguage } = useTranslationProvider();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const handleNavigate = () => {
    navigate('/login');
  };
  const renderMobVer = () => {
    return (
      <NeteviaLogoIcon
        className={styles.img}
        onClick={handleNavigate}
      />
    );
  };

  const renderDesctopVer = () => {
    return (
      <div>
        <NeteviaLogoIcon
          className={styles.img}
          onClick={handleNavigate}
        />
        <div className={styles.title}>
          <p className={styles.title_text}>
            {t('Login.Payments, banking, and cash flow', {
              defaultValue: 'Payments, banking, and cash flow',
            })}{' '}
            -
          </p>
          <p className={cx(styles.title_text, styles.title_opacity)}>
            {t('Login.united for you', {
              defaultValue: 'united for you',
            })}
          </p>
        </div>
        <div className={styles.info}>
          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <FinanceChipIcon />
              <span className={styles.info_box_title_text}>
                {t('Login.Zero Fees', {
                  defaultValue: 'Zero Fees',
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t('Login.Enjoy clear and cost-free', {
                defaultValue:
                  'Enjoy clear and cost-free transactions without hidden charges',
              })}
            </p>
          </div>

          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <CashIcon />
              <span className={styles.info_box_title_text}>
                {t('Login.Cash Back Rewards', {
                  defaultValue: 'Cash Back Rewards',
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t('Login.Receive a generous', {
                defaultValue:
                  'Receive a generous 1% unlimited cash back on every purchase, giving you extra value.',
              })}
            </p>
          </div>

          <div className={styles.info_box}>
            <div className={styles.info_box_title}>
              <CurrencyOutlinedsIcon />
              <span className={styles.info_box_title_text}>
                {t('Login.Financial Flexibility', {
                  defaultValue: 'Financial Flexibility',
                })}
              </span>
            </div>
            <p className={styles.info_box_subTitleText}>
              {t('Login.Netevia offers', {
                defaultValue:
                  'Netevia offers financial flexibility with customizable spend limits, including employee cards.',
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.wrapper}>
      <HeaderButton />
      <AnimateWrapper
        className='fade'
        inStatus={!isLoadingLanguage}
      >
        {isMobile ? renderMobVer() : renderDesctopVer()}
      </AnimateWrapper>
    </div>
  );
};

export default StartMainSection;
