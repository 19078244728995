import { useEffect } from 'react'

export const useGooglePlaceAutoComplete = (
  input: HTMLInputElement,
  callback: (autoComplete: google.maps.places.Autocomplete) => void,
  options?: google.maps.places.AutocompleteOptions
) => {
  useEffect(() => {
    const defaultOptions: google.maps.places.AutocompleteOptions = {
      // limit to North America for now
      componentRestrictions: { country: ['us'] }, //, types: ['geocode']
      fields: ['address_component'],
      types: ['address'],
    }

    let autoComplete = new window.google.maps.places.Autocomplete(
      input,
      Object.assign(defaultOptions, options)
    )

    const handlePlaceChange = () => {
      callback(autoComplete)
    }

    const lsr = autoComplete.addListener('place_changed', handlePlaceChange)

    return () => {
      lsr.remove()
      autoComplete.unbindAll()
    }
  }, [input, callback, options])
}
