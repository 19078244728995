import React, { FC, useState } from "react";
import InputForPassword from "pages/settings/security/components/changePassword/components/inputForPassword";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";

interface InputPasswordGroupProps {
  password: string;
  confirmPassword: string;
  setPassword: (string) => void;
  setConfirmPassword: (string) => void;
  validError: string;
  setValidError: (string) => void;
}
const InputPasswordGroup: FC<InputPasswordGroupProps> = ({
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  validError,
  setValidError,
}) => {
  const { t } = useTranslationProvider();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <InputForPassword
        value={password}
        label={t("Settings.New password", {
          defaultValue: "New password",
        })}
        isShow={isShowPassword}
        setShow={setIsShowPassword}
        setValue={setPassword}
        setError={setValidError}
        error={!!validError}
      />
      <div>
        <InputForPassword
          value={confirmPassword}
          label={t("Login.Confirm new password", {
            defaultValue: "Confirm new password",
          })}
          isShow={isShowPassword}
          setShow={setIsShowPassword}
          setValue={setConfirmPassword}
          setError={setValidError}
          error={!!validError}
        />
        {validError && <p className={styles.error}>{validError}</p>}
      </div>
    </div>
  );
};
export default InputPasswordGroup;
