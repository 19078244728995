import { FC } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./style.module.scss";

const BORDER_VALUE = "1px solid #E0E0E0";
interface FAQItemQuestionsProps {
  index: number;
  question: string;
  answer: string;
}

const FAQItemQuestions: FC<FAQItemQuestionsProps> = ({
  index,
  answer,
  question,
}) => {
  const getItemBorderTop = (index) => (index === 0 ? BORDER_VALUE : "none");

  const accordionStyle = {
    border: BORDER_VALUE,
    borderTop: getItemBorderTop(index),
    "&:before": {
      display: "none",
    },
  };
  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      key={index}
      sx={accordionStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p className={styles.textQuestion}>{question}</p>
      </AccordionSummary>
      <AccordionDetails>
        <p className={styles.textAnswer}>{answer}</p>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItemQuestions;
