import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { SignUpBusinessProgressBar } from '../progressBar';
import {
  AnotherOwnerForm,
  BusinessSignUpScreen,
  useSignUpBusiness,
} from '../provider';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { Button } from '../../../shared/ui/buttons';
import { SignUpSummaryBlock } from '../../../shared/signUp/summaryBlock';
import {
  BUSINESS_TYPES_DATA,
  createArrByNumber,
  formatSignUpAddress,
  formatSignUpBirthOfDate,
  JOB_TITLE_DATA, SignUpQsTypes,
} from '../../../shared/signUp/shared';
import styles from './style.module.scss';
import { useSearchParams } from 'react-router-dom';

const EditButton = ({ screenToGo }: { screenToGo: BusinessSignUpScreen }) => {
  const { setScreen, isSubmitApplicationLoading } = useSignUpBusiness();
  return (
    <Button
      disabled={isSubmitApplicationLoading}
      variant='text'
      onClick={() => setScreen(screenToGo)}
    >
      Edit
    </Button>
  );
};

export const BusinessSignUpConfirm = () => {
  const {
    setScreen,
    contextForm,
    submitSignUpApplication,
    isSubmitApplicationLoading,
  } = useSignUpBusiness();

  const [, setSearchParams] = useSearchParams();

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Last touch! Let’s make sure that the information entered is correct.
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Check all the information below and make sure that the information
          entered correctly.
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar curScreen={BusinessSignUpScreen.confirm} />

        <SignUpFormsLayout.SectionTitle
          extraAction={
            <EditButton screenToGo={BusinessSignUpScreen.businessInfo} />
          }
        >
          Business information
        </SignUpFormsLayout.SectionTitle>
        <SignUpSummaryBlock
          items={[
            ['Company name', contextForm.legalBusinessName],
            ['DBA', contextForm.doingBusinessAsName],
            ['Phone', contextForm.businessPhone],
            ['Website', contextForm.website || '-'],
            ['Tax ID #(EIN)', contextForm.taxId],
            [
              'Business type',
              BUSINESS_TYPES_DATA.find(
                (bt) => bt.businessType === contextForm.businessType
              )?.title,
            ],
          ]}
        />

        <SignUpFormsLayout.SectionTitle
          extraAction={
            <EditButton screenToGo={BusinessSignUpScreen.businessAddress} />
          }
        >
          Address
        </SignUpFormsLayout.SectionTitle>
        <SignUpSummaryBlock items={[[formatSignUpAddress(contextForm)]]} />

        <SignUpFormsLayout.SectionTitle
          extraAction={
            <EditButton
              screenToGo={BusinessSignUpScreen.primaryAuthorizedPerson}
            />
          }
        >
          Primary authorized person
        </SignUpFormsLayout.SectionTitle>
        <SignUpSummaryBlock
          items={[
            [
              'Name',
              `${contextForm.primaryAuthPerson.givenName} ${contextForm.primaryAuthPerson.familyName}`,
            ],
            [
              'Date of birth',
              formatSignUpBirthOfDate(
                contextForm.primaryAuthPerson.dateOfBirth
              ),
            ],
            ['Email', contextForm.primaryAuthPerson.email],
            ['Phone', contextForm.primaryAuthPerson.phone],
            ['Address', formatSignUpAddress(contextForm.primaryAuthPerson)],
            [
              'Job title',
              JOB_TITLE_DATA[contextForm.primaryAuthPerson.jobTitle],
            ],
            ['SSN', contextForm.primaryAuthPerson.ssn],
            [
              'Ownership percentage',
              `${Number(contextForm.primaryAuthPerson.ownershipPercentage)}%`,
            ],
          ]}
        />

        {!contextForm.owners.isSameAsPrimary &&
          createArrByNumber(contextForm.owners.anotherPersonsCount).map(
            (ownerPosition) => {
              const anotherOwner = contextForm.owners[
                `owner${ownerPosition}`
              ] as AnotherOwnerForm;
              if (!anotherOwner) {
                return null;
              }
              return (
                <div
                  key={ownerPosition}
                  className={styles.formWrapper}
                >
                  <SignUpFormsLayout.SectionTitle
                    extraAction={
                      <Button
                        disabled={isSubmitApplicationLoading}
                        variant='text'
                        onClick={() => {
                          setSearchParams({
                            [SignUpQsTypes.ownerPosition]: ownerPosition.toString(),
                          });
                          setScreen(BusinessSignUpScreen.anotherOwners);
                        }}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Primary owner #{ownerPosition}
                  </SignUpFormsLayout.SectionTitle>
                  <SignUpSummaryBlock
                    items={[
                      [
                        'Name',
                        `${anotherOwner.givenName} ${anotherOwner.familyName}`,
                      ],
                      [
                        'Date of birth',
                        formatSignUpBirthOfDate(anotherOwner.dateOfBirth),
                      ],
                      ['Email', anotherOwner.email],
                      ['Phone', anotherOwner.phone],
                      ['Address', formatSignUpAddress(anotherOwner)],
                      ['SSN', anotherOwner.ssn],
                      [
                        'Ownership percentage',
                        `${Number(anotherOwner.ownershipPercentage)}%`,
                      ],
                    ]}
                  />
                </div>
              );
            }
          )}

        <br />
        <br />

        <SignUpFormsLayout.FormControls>
          <Button
            disabled={isSubmitApplicationLoading}
            onClick={() => setScreen(BusinessSignUpScreen.primaryOwner)}
            type='button'
            variant='text'
          >
            Back
          </Button>
          <Button
            disabled={isSubmitApplicationLoading}
            type='button'
            variant='contained'
            onClick={submitSignUpApplication}
          >
            Next
          </Button>
        </SignUpFormsLayout.FormControls>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
