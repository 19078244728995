import { FC, useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import helper from "services/helper";
import styles from "./style.module.scss";

export interface CategoryItemProps {
  amount: number;
  category: string;
  color: string;
  img: string;
  percentage: number;
}
const CategoryItem: FC<CategoryItemProps> = ({
  amount,
  category,
  color,
  img,
  percentage,
}) => {
  const [progressLine, setProgressLine] = useState(0);

  useEffect(() => {
    let currentProgress = 0;
    const increment = (percentage / 1000) * 2;

    const timer = setInterval(() => {
      currentProgress += increment;
      setProgressLine(currentProgress);

      if (currentProgress >= percentage) {
        clearInterval(timer);
      }
    }, 0.05);

    return () => {
      clearInterval(timer);
    };
  }, [percentage]);

  return (
    <div className={styles.container}>
      <img src={img} />
      <div>
        <div className={styles.boxCategory}>
          <span className={styles.boxCategory_name}>{category}</span>
          <span className={styles.boxCategory_amount}>
            ${helper.formatUsdValue(amount)}
          </span>
        </div>
        <LinearProgress
          variant="determinate"
          value={progressLine}
          sx={{
            backgroundColor: "#FFFFFF",
            "& .MuiLinearProgress-bar": {
              backgroundColor: color,
            },
          }}
        />
        <span className={styles.boxCategory_proccent}>{percentage}%</span>
      </div>
    </div>
  );
};

export default CategoryItem;
