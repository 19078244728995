import { NeteviaPayeeAddForm } from './netevia-payee-add-form';
import { TransitionAnimation } from 'shared/ui/animations';
import { createPayeeAddSchema } from 'features/transfers/transfer-to-netevia-customer/model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector, useAppDispatch } from 'shared/models';
import {
  setNewPayee,
  selectNewPayee,
  selectNotRegisteredPayee,
} from 'features/transfers/transfer-to-netevia-customer/model';
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement';
import { Skeleton } from 'components/skeleton';
import { useFormik } from 'formik';

export const ManagePayeeAdd = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { data: neteviaPayees, isFetching: isFetchingNeteviaPayees } =
    useNeteviaPayeesControllerQuery();
  const newPayee = useAppSelector(selectNewPayee);
  const notRegisteredPayee = useAppSelector(selectNotRegisteredPayee);

  const formik = useFormik({
    initialValues: {
      accountName: newPayee.name || notRegisteredPayee?.name || '',
      accountNumber:
        newPayee.number || notRegisteredPayee?.fullAccountNumber || '',
    },
    validationSchema: createPayeeAddSchema(neteviaPayees?.payees || []),
    onSubmit: (form) => {
      dispatch(
        setNewPayee({
          value: {
            name: form.accountName,
            number: form.accountNumber,
          },
        })
      );
      goNext('11');
    },
  });

  return isFetchingNeteviaPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <NeteviaPayeeAddForm formik={formik} />
    </TransitionAnimation>
  );
};
