import { AnimateWrapper } from 'components/animate-wrapper';
import RewardsFilter from './components/rewardsFilter';
import HistoryList from './components/historyList';
import HistoryLastEarnedPoints from './components/historyLastEarnedPoints';
import DownloadStatement from './components/downloadStatement';
import styles from './styles.module.scss';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { appRoutes } from 'routes';

const HistoryRewards = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints?.down('sm'));
    const navigate = useNavigate()
    const { t } = useTranslationProvider();
  
    return (
        <AnimateWrapper className="fade">
            <div className={styles.container}>
                {isMobile && (
                    <div className={styles.container_nav}>
                        <img
                            src={ArrowLeft}
                            onClick={() => navigate(appRoutes.rewards())}
                        />
                        <span className={styles.container_nav_title}>
                            {t('Rewards.History', {
                                defaultValue: 'History',
                            })}
                        </span>
                    </div>
                )}
                <div className={styles.container_box}>
                    <RewardsFilter />
                    <DownloadStatement />
                    {isMobile && <HistoryLastEarnedPoints />}
                    <HistoryList />
                </div>
                {!isMobile && <HistoryLastEarnedPoints />}
            </div>
        </AnimateWrapper>
    );
};

export default HistoryRewards;
