import styles from './style.module.scss';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { Button } from '../../../../../shared/ui/buttons';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';
import { useDrawerBehavior } from '../../../../../providers/drawer-behavior';
import { useCurrentUser } from '../../../../../providers/user.provider';
import { useSearchParams } from 'react-router-dom';
import { LOGIN_OPTIONS, MFA_SETTINGS } from '../../securitySearchParams';

export const MfaSettings = () => {
  const mfa = useMfa();
  const drawerBehavior = useDrawerBehavior();
  const { user, currentUserRefetch } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || LOGIN_OPTIONS;


  const handleSetup = () => {
    mfa.show({
      title: 'Setup MFA',
      isOnlyOtpFlow: true,
      onOtpSecretCreated() {
        mfa.close();
        setSearchParams({ page, pageMode: MFA_SETTINGS });
      }
    });
  };

  const handleDelete = async () => {
    drawerBehavior.lockForClose();
    await mfa.disableOtp();
    await currentUserRefetch();
    drawerBehavior.unlockForClose();
    mfa.close();
    setSearchParams({ page, pageMode: MFA_SETTINGS });
  }

  const handleChangeMfaSignIn = async (checked: boolean) => {
    drawerBehavior.lockForClose();
    await mfa.setMfaSignIn(checked);
    await currentUserRefetch();
    drawerBehavior.unlockForClose();
  };

  return (
    <div className={styles.wrapper}>
      {mfa.isLoading ? (
        <Skeleton height={150} width="100%"/>
      ) : (
        <>
          {!mfa.isTimeBasedSetUp && (
            <div className={styles.hintText}>
              You currently don’t have an authenticator set up.
              Please follow the steps below to complete the setup. An authenticator is required to perform transactions.
            </div>
          )}
          {mfa.isTimeBasedSetUp && (
            <div className={styles.sectionWrapper}>
              <div className={styles.sectionLabel}>Options:</div>
              <div>
                <div>
                  <FormControlLabel
                    control={<Checkbox disabled checked={true}/>}
                    label="Authenticator for Transactions (Required)"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="login"
                        checked={user?.twoFactorAuthenticationEnabled || false}
                        onChange={(_, checked) => handleChangeMfaSignIn(checked)}
                        disabled={drawerBehavior.isCloseDisabled}
                      />
                    }
                    label="Authenticator for Login (Optional)"
                  />
                </div>
              </div>
            </div>
          )}
          <div>
            {mfa.isTimeBasedSetUp ? (
              <Button onClick={handleDelete} color="error" variant="outlined">Disable</Button>
            ) : (
              <Button onClick={handleSetup} variant="contained">Setup MFA</Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
