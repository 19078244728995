import { TransitionAnimation } from 'shared/ui/animations';
import SimpleContainer from 'shared/components/containers/simpleContainer';
import { useNavigate } from 'react-router-dom';
import { BatchTransferTypeSelectForm } from './batch-transfer-type-select-form';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import helper from 'services/helper';
import styles from './styles.module.scss';
import {
  BatchTypeSelectFormSchema,
  setFromFinancialAccount,
  setTransferType,
  selectFromFinancialAccount,
  selectTransferType,
  TransferType,
  StepContent,
  clearTransfers,
} from '../../model';
import { findObjById } from 'entities/transfers';
import { Skeleton } from 'components/skeleton';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { appRoutes } from 'routes';
import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useCurrentUser } from '../../../../../providers/user.provider';
interface BatchTransferTypeSelectProps {
  setStepContent: (step: StepContent) => void;
}

export const BatchTransferTypeSelect = ({
  setStepContent,
}: BatchTransferTypeSelectProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isConsumer } = useCurrentUser();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { isLoadingPayees } = useExternalPayees();
  const { isLoading: isLoadingPayeesNetevia } =
    useNeteviaPayeesControllerQuery();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount);
  const transferType = useAppSelector(selectTransferType);

  const formik = useFormik({
    initialValues: {
      from: fromFinancialAccount?.id || '',
      transferType: transferType || '',
    },
    validationSchema: BatchTypeSelectFormSchema,
    onSubmit: (form) => {
      dispatch(
        setFromFinancialAccount({
          value: findObjById(form.from, unclosedFinancialAccounts),
        })
      );
      const transferType = form.transferType as TransferType;
      dispatch(setTransferType({ value: transferType }));
      dispatch(clearTransfers());
      setStepContent(StepContent.form);
    },
  });

  useEffect(() => {
    if (isConsumer) {
      formik.setFieldValue('transferType', TransferType.toAch);
    }
  }, [isConsumer]);

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));
  const isLaoding =
    isLoadingPayees || financialAccountsIsLoading || isLoadingPayeesNetevia;

  return (
    <TransitionAnimation>
      <SimpleContainer
        title={'Batch transfer'}
        onTitleClick={() => navigate(appRoutes.transfer())}
      >
        {isLaoding ? (
          <Skeleton
            width={isMobile ? '100%' : '528px'}
            height='110px'
          />
        ) : (
          <BatchTransferTypeSelectForm
            formik={formik}
            optionsFromAcc={optionsFromAcc}
          />
        )}
      </SimpleContainer>
    </TransitionAnimation>
  );
};
