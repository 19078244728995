import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { SignUpBusinessProgressBar } from '../progressBar';
import {
  BusinessSignUpScreen,
  SignUpBusinessInfo,
  useSignUpBusiness,
} from '../provider';
import { useFormik } from 'formik';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import * as yup from 'yup';
import styles from './style.module.scss';
import cx from 'classnames';
import { Button } from '../../../shared/ui/buttons';
import { BUSINESS_TYPES_DATA } from '../../../shared/signUp/shared';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';
import { onlyNumbers } from '../../../shared/utils/pipes';
import { useScrollToMuiFieldAfterError } from '../../../shared/hooks/useScrollToMuiFieldAfterError';

const validateSchema = yup.object({
  legalBusinessName: yup
    .string()
    .required('Enter company name')
    .max(255, 'Company name must be exactly 255 characters'),
  doingBusinessAsName: yup
    .string()
    .required('Enter a DBA')
    .max(255, 'DBA must be exactly 255 characters'),
  businessPhone: yup
    .string()
    .required('Enter a Phone')
    .transform((v) => onlyNumbers(v))
    .min(10, 'Phone number must be exactly 10 characters')
    .max(10, 'Phone number must be exactly 10 characters'),
  businessStartDate: yup.string().required('Enter a Business start date'),
  annualBusinessRevenue: yup
    .string()
    .required('Enter an Annual Business Revenue'),
  taxId: yup
    .string()
    .required('Enter a TAX ID')
    .transform((v) => onlyNumbers(v))
    .min(9, 'TAX ID must be exactly 9 characters')
    .max(9, 'TAX ID must be exactly 9 characters'),
  businessType: yup.number().required(),
});

export const BusinessSignUpInfo = () => {
  const { contextForm, updateContextForm, setScreen } = useSignUpBusiness();
  const formik = useFormik<SignUpBusinessInfo>({
    validateOnChange: false,
    validationSchema: validateSchema,
    initialValues: {
      legalBusinessName: contextForm.legalBusinessName,
      doingBusinessAsName: contextForm.doingBusinessAsName,
      businessPhone: contextForm.businessPhone,
      website: contextForm.website,
      taxId: contextForm.taxId,
      businessType: contextForm.businessType,
      businessStartDate: contextForm.businessStartDate,
      annualBusinessRevenue: contextForm.annualBusinessRevenue,
    },
    onSubmit(values) {
      updateContextForm(values);
      setScreen(BusinessSignUpScreen.businessAddress);
    },
  });

  useScrollToMuiFieldAfterError(formik.isSubmitting);

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Welcome to Netevia! Let&apos;s get started.
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Help us understand your business better so we can tailor our services
          to your needs. Don&apos;t worry, it&apos;s a quick and easy process!
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar
          curScreen={BusinessSignUpScreen.businessInfo}
        />

        <form
          onSubmit={formik.handleSubmit}
          className={styles.formWrapper}
        >
          <TextField
            fullWidth
            type='text'
            variant='outlined'
            label='Company name'
            error={Boolean(
              formik.touched.legalBusinessName &&
                formik.errors.legalBusinessName
            )}
            helperText={
              formik.touched.legalBusinessName &&
              formik.errors.legalBusinessName
            }
            {...formik.getFieldProps('legalBusinessName')}
          />

          <TextField
            fullWidth
            type='text'
            variant='outlined'
            label='DBA'
            error={Boolean(
              formik.touched.doingBusinessAsName &&
                formik.errors.doingBusinessAsName
            )}
            helperText={
              formik.touched.doingBusinessAsName &&
              formik.errors.doingBusinessAsName
            }
            {...formik.getFieldProps('doingBusinessAsName')}
          />

          <InputMask
            mask='(999) 999-9999'
            maskChar=' '
            {...formik.getFieldProps('businessPhone')}
            onChange={(e: any) => {
              formik.setFieldValue('businessPhone', e.target.value);
            }}
            onBlur={() => {
              formik.setFieldTouched('businessPhone', true);
            }}
          >
            {/** @ts-ignore */}
            {() => (
              <TextField
                fullWidth
                type='tel'
                variant='outlined'
                label='Phone'
                error={Boolean(
                  formik.touched.businessPhone && formik.errors.businessPhone
                )}
                helperText={
                  formik.touched.businessPhone && formik.errors.businessPhone
                }
              />
            )}
          </InputMask>

          <TextField
            fullWidth
            type='text'
            variant='outlined'
            label='Website'
            error={Boolean(formik.touched.website && formik.errors.website)}
            helperText={formik.touched.website && formik.errors.website}
            {...formik.getFieldProps('website')}
          />

          <InputMask
            mask='99-9999999'
            maskChar=' '
            {...formik.getFieldProps('taxId')}
            onChange={(e: any) => {
              formik.setFieldValue('taxId', e.target.value);
            }}
            onBlur={() => {
              formik.setFieldTouched('taxId', true);
            }}
          >
            {/** @ts-ignore */}
            {() => (
              <TextField
                fullWidth
                type='text'
                variant='outlined'
                label='Tax ID #(EIN)'
                error={Boolean(formik.touched.taxId && formik.errors.taxId)}
                helperText={formik.touched.taxId && formik.errors.taxId}
              />
            )}
          </InputMask>

          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                fullWidth
                disableFuture
                label='Business start date'
                name='date'
                value={formik.values.businessStartDate}
                onChange={(newDate) => {
                  formik.setFieldValue('businessStartDate', newDate);
                  if (dayjs.isDayjs(newDate) && newDate.isValid()) {
                    setTimeout(() => {
                      formik.validateForm();
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    error: Boolean(
                      formik.touched.businessStartDate &&
                        formik.errors.businessStartDate
                    ),
                  },
                }}
              />
              {Boolean(
                formik.touched.businessStartDate &&
                  formik.errors.businessStartDate
              ) && (
                <FormHelperText error>
                  {formik.errors.businessStartDate}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </FormControl>

          <TextField
            fullWidth
            type='text'
            variant='outlined'
            label='Annual business revenue'
            error={Boolean(
              formik.touched.annualBusinessRevenue &&
                formik.errors.annualBusinessRevenue
            )}
            helperText={
              formik.touched.annualBusinessRevenue &&
              formik.errors.annualBusinessRevenue
            }
            {...formik.getFieldProps('annualBusinessRevenue')}
            onChange={(e) => {
              formik.setFieldValue(
                'annualBusinessRevenue',
                e.target.value.replace(/\D/g, '')
              );
            }}
          />

          <SignUpFormsLayout.SectionTitle>
            What&apos;s you business type?
          </SignUpFormsLayout.SectionTitle>

          <div className={styles.businessTypes}>
            {BUSINESS_TYPES_DATA.map(({ description, title, businessType }) => (
              <div
                key={title}
                onClick={() =>
                  formik.setFieldValue('businessType', businessType)
                }
                className={cx(
                  styles.businessType,
                  formik.values.businessType === businessType &&
                    styles.businessTypeActive
                )}
              >
                <div className={styles.businessTypeTitle}>{title}</div>
                <div className={styles.businessTypeText}>{description}</div>
              </div>
            ))}
          </div>

          <SignUpFormsLayout.FormControls>
            <Button
              onClick={() => setScreen(BusinessSignUpScreen.loginAndPass)}
              type='button'
              variant='text'
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={typeof formik.values.businessType !== 'number'}
            >
              Next
            </Button>
          </SignUpFormsLayout.FormControls>
        </form>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
