import React, { FC } from "react";
import { List, ListItemButton, Divider } from "@mui/material";
import ButtonChevronRigth from "shared/components/buttonChevronRigth";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { LOGIN_OPTIONS } from "pages/settings/security/securitySearchParams";

interface ButtonItems {
  label: string;
  img: string;
  pageMode: string;
}
interface LogintOptionsListProps {
  title?: string;
  buttonArr: ButtonItems[];
}

const LogintOptionsList: FC<LogintOptionsListProps> = ({
  title,
  buttonArr,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || LOGIN_OPTIONS;
  const renderItem = () => {
    return buttonArr?.map((i, index) => (
      <div key={index}>
        <ListItemButton
          onClick={() =>
            setSearchParams({ page, pageMode: i.pageMode, title: i.label })
          }
          sx={{
            padding: 0,
          }}
        >
          <ButtonChevronRigth label={i.label} img={i.img} />
        </ListItemButton>
        {index < buttonArr.length - 1 && <Divider />}
      </div>
    ));
  };
  return (
    <div className={styles.wrapper}>
      {title && <span>{title}</span>}
      <List disablePadding className={styles.listWrapper}>
        {renderItem()}
      </List>
    </div>
  );
};

export default LogintOptionsList;
