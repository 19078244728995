import { useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { TextInput } from 'shared/ui/inputs';
import { Select } from 'shared/ui/inputs';
import { MenuItem } from '@mui/material';
import { departmentList } from 'pages/settings/accountSettings/config/constants';
import styles from './style.module.scss';
import { ManageAccess } from '../../../manageAccess';
import { useFormik } from 'formik';
import { useSubUserAccessRightsControllerQuery } from 'api/endpoints/usersManagement';
import { Skeleton } from 'components/skeleton';
import { Dayjs } from 'dayjs';
import { Button } from 'shared/ui/buttons';
import {
  useUpdateSubUserControllerMutation,
  useSubUsersControllerQuery,
  useDeleteSubUserControllerMutation,
} from 'api/endpoints/subProfiles';
import { useEditSubUserAccessRightsControllerMutation } from 'api/endpoints/usersManagement';
import { EditSubUserSchema } from '../../../validationSchema';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import {
  hasSubUserAccessChanged,
  hasSubUserProfileChanged,
} from 'pages/settings/accountSettings/lib';
import { Rights } from 'api/endpoints/user';
import { SubUserFormValues } from 'pages/settings/accountSettings/types';
import { RemoveSubUserPopupGroup } from '../removeSubUserPopupGroup';
interface EditSubUserFormProps {
  subId: number;
}

export const EditSubUserForm = ({ subId }: EditSubUserFormProps) => {
  const { goNext, lockForClose, unlockForClose, close } =
    useDrawerStepperContext();

  const { setSnackBar } = useSnackBar();
  const {
    data: subUserAccess,
    isFetching: isFetchingSubUserAccess,
    isSuccess: isSuccessSubUserAccess,
  } = useSubUserAccessRightsControllerQuery({ subUserId: subId });
  const { data: subUsers, isFetching: isFetchingSubUsers } =
    useSubUsersControllerQuery();

  const subUser = subUsers?.find((user) => user.id === subId);
  const subUserFullName = `${subUser?.givenName} ${subUser?.familyName}`;

  const [updateSubUserMutation] = useUpdateSubUserControllerMutation();
  const [editSubUserAccessRightsMutation] =
    useEditSubUserAccessRightsControllerMutation();
  const [deleteSubUserMutation, { isLoading: isLoadingDeleteSubUser }] =
    useDeleteSubUserControllerMutation();

  const loadingUpdateBool = useBoolean();
  const removeGroupBool = useBoolean();

  const handleDelete = async (deleteCards: boolean) => {
    removeGroupBool.setFalse();
    try {
      await deleteSubUserMutation({
        id: subId.toString(),
        deleteCards: deleteCards,
      }).unwrap();
      close();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleUpdate = async (form: SubUserFormValues) => {
    try {
      loadingUpdateBool.setTrue();
      lockForClose();
      if (hasSubUserProfileChanged(form, subUser) && subUser) {
        await updateSubUserMutation({
          email: form.email,
          givenName: form.firstName,
          familyName: form.lastName,
          department: form.department,
          subProfileId: subUser.id,
        }).unwrap();
      }
      if (
        hasSubUserAccessChanged(form.access, subUserAccess as Rights) &&
        subUser
      ) {
        const data = {
          openBankingCard: form.access.openBankingCard,
          openFinancialAccount: form.access.openFinancialAccount,
          viewAllCardsAndFinancialAccounts:
            form.access.viewAllCardsAndFinancialAccounts,
          makeTransfers: form.access.makeTransfers,
          mainAccess: form.access.mainAccess,
        };
        await editSubUserAccessRightsMutation({
          data,
          subUserId: subUser.id,
        }).unwrap();
      }

      loadingUpdateBool.setFalse();
      unlockForClose();
      goNext('0');
      setSnackBar({
        type: 'success',
        message: 'Authorized user information updated successfully',
        isShow: true,
      });
    } catch (e: any) {
      loadingUpdateBool.setFalse();
      unlockForClose();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const formik = useFormik<SubUserFormValues>({
    initialValues: {
      firstName: subUser?.givenName || '',
      lastName: subUser?.familyName || '',
      email: subUser?.email || '',
      department: subUser?.department || '',
      access: {
        manage: false,
        openBankingCard: false,
        openFinancialAccount: false,
        viewAllCardsAndFinancialAccounts: false,
        makeTransfers: false,
        mainAccess: false,
      },
      date: null as Dayjs | null,
      primaryAccounId: '',
      phone: '',
    },
    validationSchema: EditSubUserSchema,
    onSubmit: (form) => handleUpdate(form),
  });
  useEffect(() => {
    if (subUserAccess && isSuccessSubUserAccess) {
      formik.resetForm({
        values: {
          ...formik.values,
          access: {
            manage: Object.values(subUserAccess).some((value) => value),
            openBankingCard: subUserAccess.openBankingCard,
            openFinancialAccount: subUserAccess.openFinancialAccount,
            viewAllCardsAndFinancialAccounts:
              subUserAccess.viewAllCardsAndFinancialAccounts,
            makeTransfers: subUserAccess.makeTransfers,
            mainAccess: subUserAccess.mainAccess,
          },
        },
      });
    }
  }, [subUserAccess, isSuccessSubUserAccess]);

  const disabledOnSubmit =
    !formik.isValid ||
    (subUserAccess &&
      !hasSubUserProfileChanged(formik.values, subUser) &&
      !hasSubUserAccessChanged(formik.values.access, subUserAccess as Rights));

  return isFetchingSubUserAccess || isFetchingSubUsers ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.boxName}>
          <TextInput
            fullWidth
            label='First Name'
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && formik.errors.firstName}
          />
          <TextInput
            fullWidth
            label='Last Name'
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </div>
        <TextInput
          label='Email'
          {...formik.getFieldProps('email')}
          error={formik.touched.email && formik.errors.email}
        />
        <Select
          label='Department'
          {...formik.getFieldProps('department')}
        >
          {departmentList.map((item) => (
            <MenuItem
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <ManageAccess formik={formik} />

        <Button
          className={styles.btn}
          type='submit'
          loading={loadingUpdateBool.value}
          disabled={disabledOnSubmit || isLoadingDeleteSubUser}
        >
          <div>Save changes</div>
        </Button>
        <Button
          variant='outlined'
          className={styles.btn}
          disabled={loadingUpdateBool.value}
          loading={isLoadingDeleteSubUser}
          onClick={() => removeGroupBool.setTrue()}
        >
          Delete
        </Button>
      </form>
      {removeGroupBool.value && (
        <RemoveSubUserPopupGroup onDelete={handleDelete} />
      )}
    </TransitionAnimation>
  );
};
