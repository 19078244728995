import { useOpenPartnerCardVirtualMutation } from 'api/endpoints/paymentCards';
import { useState } from 'react';
import { usePartnerProductIdByName } from '../../../api/hooksApi/usePartnerProductIdByName';
import { requireNotNull } from '../../../shared/utils/require-not-null';

export const useOpenVirtualCard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const partnerProduct = usePartnerProductIdByName();
  const [openPartnerCardVirtual] = useOpenPartnerCardVirtualMutation();

  const openVirtualCard = async () => {
    const result = { isError: false };

    setIsLoading(true);

    try {
      const productId = await partnerProduct.getProductId('OrderOut');
      await openPartnerCardVirtual({ cardProductId: requireNotNull(productId) }).unwrap();
    } catch (error) {
      result.isError = true;
    } finally {
      setIsLoading(false);
    }

    return result;
  };

  return {
    openVirtualCard,
    isLoading,
  };
};
