import { AccountBalance } from '../AccountBalance';
import { ActionsButtons } from '../ActionsButtons';
import styles from './style.module.scss';

export const AccountHeader = () => {
  return (
    <div className={styles.container}>
      <AccountBalance />
      <ActionsButtons />
    </div>
  )
}