import { Select, SelectItem } from 'shared/ui/inputs';
import { Divider, InputAdornment, ListSubheader } from '@mui/material';
import { SearchIcon } from 'shared/ui/icons';
import { Button } from 'shared/ui/buttons';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.module.scss';
import { TextInput } from 'shared/ui/inputs';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { DatePicker } from 'shared/ui/pickers';
import { CalendarOutlineIcon } from 'shared/ui/icons';
import { Typography } from 'shared/ui/typography';
import { FormikProps } from 'formik';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Dayjs } from 'dayjs';

type HandleSearchPayee = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => Promise<void>;

interface FormValues {
  from: string;
  to: string;
  amount: string;
  frequency: string;
  dateTransfer: Dayjs;
  memo: string;
}
interface MakeTransferFormProps {
  formik: FormikProps<FormValues>;
  optionsFromAcc: SelectItem[];
  handleSearchPayee: HandleSearchPayee;
  optionsFrequency: SelectItem[];
  disabledDateAndFrequenc: boolean;
  showRegisteredPayee: React.ReactNode;
  showNotRegisteredPayee: () => React.ReactNode;
}

export const MakeTransferForm = (props: MakeTransferFormProps) => {
  const { goNext } = useDrawerStepperContext();
  const {
    formik,
    optionsFromAcc,
    handleSearchPayee,
    optionsFrequency,
    disabledDateAndFrequenc,
    showRegisteredPayee,
    showNotRegisteredPayee,
  } = props;

  const disabedBtn =
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.root}
    >
      <Select
        label='From'
        options={optionsFromAcc}
        {...formik.getFieldProps('from')}
        error={formik.touched.from && formik.errors.from}
      />
      <Select
        label='To'
        {...formik.getFieldProps('to')}
        error={formik.touched.to && formik.errors.to}
      >
        <ListSubheader className={styles.box}>
          <TextInput
            fullWidth
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
            onChange={handleSearchPayee}
            placeholder={'Search by acc number, phone or email'}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListSubheader>
        <Button
          className={styles.button}
          onClick={() => goNext('10')}
          startIcon={<AddIcon />}
          variant='text'
        >
          Add Payee
        </Button>
        {showNotRegisteredPayee()}
        <Divider className={styles.divider} />
        {showRegisteredPayee}
      </Select>
      <Divider />
      <CurrencyInput2
        fullWidth
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        name='amount'
        value={formik.values.amount}
        onChange={(value) => {
          formik.setFieldTouched('amount', true);
          formik.setFieldValue('amount', value);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && formik.errors.amount}
        getCaretBoundary={() => [false]}
      />
      <TextInput
        label="Memo"
        autoComplete="off"
        {...formik.getFieldProps('memo')}
        error={formik.touched.memo && formik.errors.memo}
      />
      <Typography className={styles.warningText}>
        Schedule and recurring transfers are only available for registered
        payees. Please add a payee before scheduling transfers.
      </Typography>
      <Select
        label='Frequency'
        options={optionsFrequency}
        disabled={disabledDateAndFrequenc}
        {...formik.getFieldProps('frequency')}
      />
      <DatePicker
        label='Date'
        slots={{
          openPickerIcon: CalendarOutlineIcon,
        }}
        disabled={disabledDateAndFrequenc}
        disablePast
        value={formik.values.dateTransfer}
        onChange={(newDate) => {
          formik.setFieldTouched('dateTransfer', true);
          formik.setFieldValue('dateTransfer', newDate);
        }}
        slotProps={{
          textField: {
            error: formik.touched.dateTransfer && !!formik.errors.dateTransfer,
            helperText: <>{formik.errors.dateTransfer}</>,
          },
        }}
      />
      <Button
        className={styles.btnSubmit}
        type='submit'
        variant='contained'
        disabled={disabedBtn}
      >
        Continue
      </Button>
    </form>
  );
};
