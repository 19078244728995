import { FC } from "react";
import ChevronLeftIcon from "assets/svg/chevron-left-black.svg";
import ChevronRightIcon from "assets/svg/chevron-right-black..svg";
import CalendarIcon from "assets/svg/calendar.svg";
import CalendarBlack from "assets/svg/calendar-account.svg";
import styles from "./style.module.scss";
import { useMediaQuery, useTheme } from "@mui/material";

interface MonthPickerProps {
  date: string;
  handlePrevMonth: () => void;
  handleNextMonth: () => void;
  disableNextMonth: boolean;
}
const MonthPicker: FC<MonthPickerProps> = ({
  date,
  handlePrevMonth,
  handleNextMonth,
  disableNextMonth,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));

  return (
    <div className={styles.container}>
      <img src={ChevronLeftIcon} onClick={handlePrevMonth} />
      <div>
        <img
          src={matches ? CalendarIcon : CalendarBlack}
          className={styles.container_calendar}
        />
        <span className={styles.container_date}>{date}</span>
      </div>
      {!disableNextMonth && (
        <img src={ChevronRightIcon} onClick={handleNextMonth} />
      )}
    </div>
  );
};
export default MonthPicker;
