import React, { useState } from "react";
import { Marker } from "@react-google-maps/api";

interface MarkerProps {
  position: {
    lat: number;
    lng: number;
  };
  key: string;
  name?: string;
  icon?: any;
  renderTooltip?: () => JSX.Element;
}

const CustomMarker = ({
  position,
  key,
  name,
  icon,
  renderTooltip,
}: MarkerProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Marker
        position={position}
        key={key}
        icon={icon}
        zIndex={20}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        title={name}
        label={name}
      >
        {isHovered && renderTooltip && renderTooltip()}
      </Marker>
    </>
  );
};

export default CustomMarker;
