import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { PropsWithChildren, ReactElement } from "react";

const TooltipChildren = ({
  children,
  description,
}: PropsWithChildren<{ children: ReactElement; description: string }>) => {
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={description}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: "Noto Sans",
            fontSize: "12px",
            fontWight: "400",
            lineHeight: "166%",
            letterSpacing: "0.4px",
            color: "rgba(0, 0, 0, 0.87)",
            background: "#FFF",
            boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.25)",
          },
        },

        arrow: {
          sx: {
            color: "#FFF",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipChildren;
