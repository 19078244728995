import { Typography } from 'shared/ui/typography'
import cn from 'classnames'
import styles from './styles.module.scss'
import { getNameDeliveryById } from '../../lib/getNameDeliveryById'
import {
  DELIVERY_SERVICES,
  DELIVERY_SERVICE_ICONS,
} from 'entities/delivery-service/config/constants'

interface DeliveryServiceCardProps {
  serviceTypeId: string
  className?: string
}
export const DeliveryServiceCard = (props: DeliveryServiceCardProps) => {
  const { serviceTypeId, className } = props

  const deliveryServiceName = getNameDeliveryById(serviceTypeId)

  const deliveryServiceDetails = DELIVERY_SERVICES[deliveryServiceName].find(
    (item) => item.id === serviceTypeId
  )

  if (!deliveryServiceDetails) {
    return <div>Something went wrong</div>
  }

  const ServiceUI = DELIVERY_SERVICE_ICONS[deliveryServiceName]

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.containerIcon}>
        <ServiceUI.icon />
        <Typography variant='subtitle2'>{ServiceUI.title}</Typography>
      </div>

      <div className={styles.containerShip}>
        <Typography variant='subtitle2'>Shipping type</Typography>
        <Typography
          variant='body2'
          className={styles.secColor}
        >{`${deliveryServiceDetails.name} (${deliveryServiceDetails.description})`}</Typography>
      </div>

      <div className={styles.containerCost}>
        <Typography variant='subtitle2'>Cost</Typography>
        <Typography
          variant='body2'
          className={styles.secColor}
        >
          {deliveryServiceDetails.price}
        </Typography>
      </div>
    </div>
  )
}
