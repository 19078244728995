import { useCallback, useState, useEffect } from 'react'
import {
  useGenerateSecretControllerMutation,
  useLazyQrCodeQuery,
} from 'api/endpoints/account'

export const useGetSecret = (checkIsTimeBasedSetUp: boolean) => {
  const [getQrCode] = useLazyQrCodeQuery()
  const [generateSecretMutation] = useGenerateSecretControllerMutation()

  const [isLoading, setIsLoading] = useState(false)
  const [secretData, setSecretData] = useState<{
    secret: string
    secretQr: string
    qrCode: string
  }>({ secret: '', secretQr: '', qrCode: '' })

  useEffect(() => {
    if (!checkIsTimeBasedSetUp) {
      getSecret()
    }
  }, [checkIsTimeBasedSetUp])

  const getSecret = useCallback(async () => {
    const result = { isError: false }

    setIsLoading(true)

    try {
      const secretQr = await generateSecretMutation({
        useQR: true,
      }).unwrap()
      const secret = await generateSecretMutation({
        useQR: false,
      }).unwrap()

      const qrCode = await getQrCode({
        secret: secret,
      }).unwrap()

      setSecretData({ secret: secret, secretQr: secretQr, qrCode: qrCode })
    } catch (error) {
      result.isError = true
    } finally {
      setIsLoading(false)
      return result
    }
  }, [])

  return {
    secretData,
    isLoadingSecretData: isLoading,
  }
}
