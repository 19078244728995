import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';

export interface Rights {
  id: number;
  mainAccess: boolean;
  makeTransfers: boolean;
  openBankingCard: boolean;
  openFinancialAccount: boolean;
  viewAllCardsAndFinancialAccounts: boolean;
}

export interface UserApi {
  accessRights: Rights;
  email: string;
  familyName: string;
  givenName: string;
  id: number;
  nickName: string;
  twoFactorAuthenticationEnabled: boolean;
  type: number;
  isPasswordChangeRequired?: boolean;
  accessToProductFunding: boolean;
  cardIcon: string | null;
  cardIconLarge: string | null;
  deletedAt: string | null;
  exportedLoanServie: string | null;
  isNotLoan: boolean;
  isOriginalUser: boolean;
  isShowRequestLoan: boolean;
  isoId: string | null;
  linkedAccounts: any[];
  marketingLink: string | null;
  middleName: string | null;
  partnerVisibleName: string | null;
  productFundingId: number | null;
  productId: number | null;
  statusApplication: string | null;
  systemModuleAccess: string | null;
}

export interface CheckAvailableLoginResults {
  isFree: boolean;
}

export interface LoginHistoryItem {
  createdDate: string;
  deviceId: string;
  deviceName: string;
  ipAddress: string | null;
  location: string | null;
  userAgent: string | null;
  userProfileId: number;
}

export const userApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'user',
  tagTypes: [ApiTags.GetUser],
  endpoints: (builder) => ({
    currentUserController: builder.query<UserApi, any>({
      providesTags: [ApiTags.GetUser],
      query: () => ({
        url: 'api/users',
        method: 'GET',
      }),
    }),
    //использую этот метод на странице login переписал на мутацию
    forLoginPageCurrentUserController: builder.mutation<UserApi, any>({
      query: ({ token }) => ({
        url: 'api/users',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      }),
    }),
    loginHistory: builder.query<LoginHistoryItem[], any>({
      query: ({ count }) => ({
        url: 'api/users/logins',
        method: 'GET',
        params: {
          count,
        },
      }),
    }),
    aprilURL: builder.mutation<string, void>({
      query: () => ({
        url: 'api/users/aprilUrl',
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    checkAvailableLogin: builder.mutation<CheckAvailableLoginResults, string>({
      query(nickCandidate: string) {
        return {
          method: 'GET',
          url: `api/users/ByNickname?login=${nickCandidate}`
        }
      },
    }),
    registrationPersonal: builder.mutation<any, any>({
      query(queryArg) {
        return {
          method: 'POST',
          url: `/api/users/personal`,
          body: queryArg,
        }
      },
    }),
    registrationBusiness: builder.mutation<any, any>({
      query(queryArg) {
        return {
          method: 'POST',
          url: `/api/users`,
          body: queryArg,
        }
      },
    }),
  }),
});

export const {
  useCurrentUserControllerQuery,
  useForLoginPageCurrentUserControllerMutation,
  useLoginHistoryQuery,
  useAprilURLMutation,
  useCheckAvailableLoginMutation,
  useRegistrationPersonalMutation,
  useRegistrationBusinessMutation,
} = userApi;
