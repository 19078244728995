import { ExternalPayeeAddForm } from './external-payee-add-form';
import { TransitionAnimation } from 'shared/ui/animations';
import { ExternalPayeeAddSchema } from '../../model/externalPayeeAddModel';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { setNewExternalPayee, selectNewExternalPayee } from '../../model';
import { useFormik } from 'formik';

export const ManagePayeeAdd = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();

  const newPayee = useAppSelector(selectNewExternalPayee);
  const formik = useFormik({
    initialValues: {
      payeeName: newPayee.name,
      payeeType: newPayee.type,
      accountNumber: newPayee.accountNumber,
      routingNumber: newPayee.routingNumber,
    },
    validationSchema: ExternalPayeeAddSchema,
    onSubmit: (form) => {
      dispatch(
        setNewExternalPayee({
          value: {
            name: form.payeeName,
            type: form.payeeType,
            accountNumber: form.accountNumber,
            routingNumber: form.routingNumber,
          },
        })
      );
      goNext('11');
    },
  });

  return (
    <TransitionAnimation>
      <ExternalPayeeAddForm formik={formik} />
    </TransitionAnimation>
  );
};
