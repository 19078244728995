import { useMemo } from "react";
import {
  usePaymentCardsControllerQuery,
  useAttachedPaymentCardsControllerQuery,
  usePaymentCardNameControllerQuery,
  PaymentCard,
} from "../endpoints/paymentCards";

interface PaymentCardsReturnType {
  paymentCards: PaymentCard[];
  paymentCardsIsLoading: boolean;
  paymentCardsIsSuccess: boolean;
  paymentCardsRefetch: () => void;
  paymentCardsIsFetching: boolean;
}

export const usePaymentCards = (): PaymentCardsReturnType => {
  const {
    data: paymentCards,
    isLoading: paymentCardsIsLoading,
    isSuccess: paymentCardsIsSuccess,
    isFetching: paymentCardsIsFetching,
    refetch: paymentRefetch,
  } = usePaymentCardsControllerQuery();

  const {
    data: attachedPaymentCards,
    isLoading: attachedPaymentCardsIsLoading,
    isSuccess: attachedPaymentCardsIsSuccess,
    isFetching: attachedPaymentCardsIsFetching,
    refetch: attachedPaymentCardsRefetch,
  } = useAttachedPaymentCardsControllerQuery({});

  const {
    data: paymentCardName,
    isLoading: paymentCardNameIsLoading,
    isSuccess: paymentCardNameIsSuccess,
    isFetching: paymentCardNameIsFetching,
  } = usePaymentCardNameControllerQuery({});

  const value = useMemo<any>(() => {
    if (!paymentCards || !attachedPaymentCards || !paymentCardName) {
      return [];
    }

    if (paymentCards && attachedPaymentCards && paymentCardName) {
      const temtpPaymentCards = paymentCards
        ?.slice()
        .sort((a, b) => +a.last4 - +b.last4)
        .map((i) => {
          return {
            ...i,
            attachedToSubProfile: attachedPaymentCards?.find(
              (a) => a.paymentCardId === i.id
            ),
          };
        });

      return temtpPaymentCards;
    }
  }, [paymentCards, attachedPaymentCards, paymentCardName]);

  return {
    paymentCards: value,
    paymentCardsIsLoading:
      paymentCardsIsLoading || attachedPaymentCardsIsLoading,
    paymentCardsIsSuccess:
      paymentCardsIsSuccess && attachedPaymentCardsIsSuccess,
    paymentCardsIsFetching:
      paymentCardsIsFetching ||
      attachedPaymentCardsIsFetching ||
      paymentCardNameIsFetching,
    paymentCardsRefetch: () => {
      paymentRefetch();
      attachedPaymentCardsRefetch();
    },
  };
};
