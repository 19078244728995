import { FC } from "react";
import { useActivateCardControllerMutation } from "api/endpoints/paymentCards";
import AccountButtons from "components/button/AccountButtons";
import { useConfirmDialog } from "providers/confirm.provider";
import { useMediaQuery, useTheme } from "@mui/material";
import { usePaymentCards } from "api/hooksApi/usePaymentCards";
import styles from "./style.module.scss";
import { useSnackBar } from "providers/snackBar.provider";

interface ButtonActivateCardProps {
  cardId: string;
  getCardInfo?: (string) => Promise<void>;
}

export const ButtonActivateCard: FC<ButtonActivateCardProps> = ({
  cardId,
  getCardInfo,
}) => {
  const confirm = useConfirmDialog();
  const { setSnackBar } = useSnackBar();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const [activateCardMutation] = useActivateCardControllerMutation();
  const { paymentCardsRefetch } = usePaymentCards();

  const handleActivateCard = (event) => {
    event.stopPropagation();
    confirm.show({
      applyButtonText: "Activate Now",
      dialogTitle: "Activate your card",
      dialogText:
        "Are you sure you want to activate your card? Click 'Activate' to proceed.",
      cancelButtonType: "text",
      cancelError: true,
      cancelButtonText: "Cancel",
      typeNew: true,
      async onApply() {
        await activateCardMutation({ cardId: cardId! }).unwrap();
        paymentCardsRefetch();
        getCardInfo && getCardInfo(cardId);
        setSnackBar({
          type: "success",
          message: "Your card has been activated and is now ready for use.",
          isShow: true,
        });
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <AccountButtons
        name={"Activate Card"}
        handleSubmit={(event) => handleActivateCard(event)}
        width={matches ? 183 : 144}
        backgroundColor={"#ffffff"}
      />
    </div>
  );
};
