import * as yup from 'yup'

export const ChangePasswordHQSchema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter a new password')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'Password needs: 1 digit, 1 lowercase, 1 uppercase, 1 special character'
    ),
  passwordConfirm: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
})
