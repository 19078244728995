import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { Intro } from '../intro';
import { MakeTransferConfirm } from '../make-transfer-confirm';
import { AuntificatorTransfer } from '../auntificatorTransfer';
import { MakeTransferSuccess } from '../make-transfer-success';
import { ManageConnectedAccount } from '../manage-connected-account';
import { ConnectedAccountHistory } from '../connected-account-history';
import { ManageScheduleTransferDetail } from '../manage-schedule-transfer-detail';
import { ManageScheduleTransferEdit } from '../manage-schedule-transfer-edit';
import { AuntificatorScheduleTransfer } from '../auntificatorScheduleTransfer ';
import { ManageScheduleTransferSuccess } from '../manage-schedule-transfer-success';
import { ManageAutomations } from '../manage-automations';
import { ManageAutomationsAdd } from '../manage-automations-add';
import { ManagaAutomationsEdit } from '../manage-automations-edit';
import { AuntificatorAutomationsAdd } from '../auntificatorAutomationsAdd';
import { AuntificatorAutomationsEdit } from '../auntificatorAutomationsEdit';

interface Props {
  isShow: boolean;
  onClose: () => void;
  startStep?: string;
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'From connected account',
      prevId: null,
      Element: <Intro />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'From connected account',
      prevId: '0',
      Element: <MakeTransferConfirm />,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: true,
      title: 'From connected account',
      prevId: '1',
      Element: <AuntificatorTransfer />,
    },
  ],
  [
    '3',
    {
      id: '3',
      isCanGoBack: false,
      title: 'From connected account',
      prevId: '0',
      Element: <MakeTransferSuccess />,
    },
  ],

  [
    '4',
    {
      id: '4',
      isCanGoBack: true,
      title: 'Transfer details',
      prevId: '0',
      Element: <ManageScheduleTransferDetail />,
    },
  ],
  [
    '5',
    {
      id: '5',
      isCanGoBack: true,
      title: 'Edit transfer',
      prevId: '4',
      Element: <ManageScheduleTransferEdit />,
    },
  ],
  [
    '6',
    {
      id: '6',
      isCanGoBack: true,
      title: 'Edit transfer',
      prevId: '5',
      Element: <AuntificatorScheduleTransfer />,
    },
  ],
  [
    '7',
    {
      id: '7',
      isCanGoBack: false,
      title: 'Edit transfer',
      prevId: '6',
      Element: <ManageScheduleTransferSuccess />,
    },
  ],
  [
    '8',
    {
      id: '8',
      isCanGoBack: true,
      title: 'Manage connected accounts',
      prevId: '0',
      Element: <ManageConnectedAccount />,
    },
  ],
  [
    '9',
    {
      id: '9',
      isCanGoBack: true,
      title: 'Connected Accounts History',
      prevId: '8',
      Element: <ConnectedAccountHistory />,
    },
  ],
  [
    '10',
    {
      id: '10',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '0',
      Element: <ManageAutomations />,
    },
  ],
  [
    '11',
    {
      id: '11',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '10',
      Element: <ManageAutomationsAdd />,
    },
  ],
  [
    '12',
    {
      id: '12',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '11',
      Element: <AuntificatorAutomationsAdd />,
    },
  ],
  [
    '13',
    {
      id: '13',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '10',
      Element: <ManagaAutomationsEdit />,
    },
  ],
  [
    '14',
    {
      id: '14',
      isCanGoBack: true,
      title: 'Manage Automations',
      prevId: '13',
      Element: <AuntificatorAutomationsEdit />,
    },
  ],
];

const MapSteps = new Map(steps);

export const FromConnectedAccount = (props: Props) => {
  const { isShow, onClose ,startStep} = props;

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep={startStep ? startStep :'0'}
      steps={MapSteps}
      onClose={onClose}
    />
  );
};
