import styles from './style.module.scss';
import React, { PropsWithChildren } from 'react';
import Footer from '../../shared/components/footer';
import { footerLegal } from '../../shared/components/footerLegal';
import { ReactComponent as NeteviaLogoSvg } from './logo.svg';
import { appRoutes } from '../../routes';

interface Props {
  isConsumerMode?: boolean;
}

export const SignUpFormsLayout = ({
  children,
  isConsumerMode,
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topbar}>
        <a href={appRoutes.login()}>
          <NeteviaLogoSvg />
        </a>
      </div>
      <div className={styles.content}>
        <div className={styles.contentBody}>{children}</div>
      </div>
      <Footer
        legal={isConsumerMode ? footerLegal.consumer : footerLegal.business}
      />
    </div>
  );
};

SignUpFormsLayout.Title = function SignUpFormsLayoutTitle({
  children,
  textCenter,
}: PropsWithChildren<{ textCenter?: boolean }>) {
  return (
    <h1
      className={styles.title}
      style={{ textAlign: textCenter ? 'center' : 'initial' }}
    >
      {children}
    </h1>
  );
};

SignUpFormsLayout.Subtitle = function SignUpFormsLayoutSubtitle({
  children,
  textCenter,
}: PropsWithChildren<{ textCenter?: boolean }>) {
  return (
    <h3
      className={styles.subtitle}
      style={{ textAlign: textCenter ? 'center' : 'initial' }}
    >
      {children}
    </h3>
  );
};

SignUpFormsLayout.SectionTitle = function SignUpFormsSectionTitle({
  children,
  extraAction,
}: PropsWithChildren<{ extraAction?: React.ReactNode }>) {
  return (
    <div className={styles.sectionTitle}>
      {children}
      {extraAction && extraAction}
    </div>
  );
};

SignUpFormsLayout.FormControls = function SignUpFormsLayoutFormControls({
  children,
}: PropsWithChildren) {
  return <div className={styles.formControls}>{children}</div>;
};
