import InfoTooltipImg from "assets/svg/InfoTooltip.svg";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";
interface InfoTooltipProps {
  text: string;
}

export const InfoTooltip = ({ text }: InfoTooltipProps) => {
  return (
    <TooltipChildren description={text}>
      <img src={InfoTooltipImg} />
    </TooltipChildren>
  );
};
