import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import styles from './style.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useSubUsersAttachedFinancialAccounts } from 'api/hooksApi/useSubUsersAttachedFinancialAccounts';
import { ConnectedCardList } from '../../../connectedCardList';
import { ConnectedAccountList } from '../../../connectedAccountList';
import { Skeleton } from 'components/skeleton';
import {
  AttachedPaymentCard,
  useSubUsersControllerQuery,
} from 'api/endpoints/subProfiles';
import { useSearchParams } from 'react-router-dom';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { useDrawerBehavior } from '../../../../../../../providers/drawer-behavior';
import { Status } from '../../../../../../../enums';

interface EditOptionsProps {
  subId: number;

  readonly?: boolean;
  isConnectedAccountsHidden?: boolean;
}

const unclosedCards = (apc: AttachedPaymentCard[] | undefined) => {
  return apc && apc.filter((c) => c.status !== Status.Closed);
};

export const EditOptions = ({
  subId,
  isConnectedAccountsHidden,
  readonly,
}: EditOptionsProps) => {
  const { goNext } = useDrawerStepperContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  const {
    subUserAttachedFinancialAccount,
    subUserAttachedFinancialAccountIsLoading,
  } = useSubUsersAttachedFinancialAccounts(subId.toString());
  const {
    data: subUsers,
    isLoading: isLoadingSubUsers,
    refetch: refetchSubUsers,
  } = useSubUsersControllerQuery();
  const drawerBehavior = useDrawerBehavior();

  const { paymentCards, paymentCardsIsFetching } = usePaymentCards();
  const subUser = subUsers?.find((user) => user.id === subId);

  const mapAttachedPaymentCards = (
    attachedPaymentCards: AttachedPaymentCard[],
    paymentCards: PaymentCard[]
  ) => {
    return attachedPaymentCards.map((attachedCard) => {
      const matchingCard = paymentCards.find(
        (paymentCard) => paymentCard.id === attachedCard.id
      );

      if (matchingCard && !!matchingCard.financialAccounts.length) {
        return {
          ...attachedCard,
          financialAccountId: matchingCard.financialAccounts[0].id,
        };
      }

      return attachedCard;
    });
  };

  const handleNavigateToManageCards = () => {
    setSearchParams({
      page,
      id: subId.toString(),
    });
    goNext('2');
  };

  const subUsersRefetch = async () => {
    await refetchSubUsers();
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.boxBtn}>
          <Typography>User information</Typography>
          {!readonly && (
            <Button
              variant='text'
              onClick={() => goNext('1')}
              disabled={drawerBehavior.isCloseDisabled}
            >
              Edit
            </Button>
          )}
        </div>
        {isLoadingSubUsers ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <>
            <div className={styles.boxText}>
              <Typography>Email address</Typography>
              <Typography>{subUser?.email}</Typography>
            </div>
            {subUser?.department && (
              <div className={styles.boxText}>
                <Typography>Department</Typography>
                <Typography>{subUser?.department}</Typography>
              </div>
            )}
          </>
        )}

        {isLoadingSubUsers || paymentCardsIsFetching ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <div className={styles.boxConnected}>
            <div className={styles.boxBtn}>
              <Typography>
                Connected cards (
                {unclosedCards(subUser?.attachedPaymentCards)?.length || 0})
              </Typography>
              {!readonly && (
                <Button
                  variant='text'
                  onClick={handleNavigateToManageCards}
                  disabled={drawerBehavior.isCloseDisabled}
                >
                  Manage
                </Button>
              )}
            </div>
            <ConnectedCardList
              onSubUsersRefetch={subUsersRefetch}
              subProfileId={subId}
              attachedPaymentCards={
                unclosedCards(
                  mapAttachedPaymentCards(
                    subUser?.attachedPaymentCards || [],
                    paymentCards || []
                  )
                ) || []
              }
            />
          </div>
        )}
        {!isConnectedAccountsHidden &&
          (subUserAttachedFinancialAccountIsLoading ? (
            <Skeleton
              width='100%'
              height='110px'
            />
          ) : (
            <div className={styles.boxConnected}>
              <div className={styles.boxBtn}>
                <Typography>
                  Connected accounts (
                  {subUserAttachedFinancialAccount?.length || 0})
                </Typography>
                <Button
                  variant='text'
                  onClick={() => goNext('3')}
                  disabled={drawerBehavior.isCloseDisabled}
                >
                  Manage
                </Button>
              </div>
              <ConnectedAccountList
                attachedFinancialAccount={subUserAttachedFinancialAccount}
              />
            </div>
          ))}
      </div>
    </TransitionAnimation>
  );
};
