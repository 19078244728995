import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from '../baseApi'
import { ApiTags } from '../api-tags'
import {  SecureOperationType } from 'enums'
interface SaveEmailArg {
  email: string
}

interface SaveAssociatedEmailArg extends SaveEmailArg {
  associatedPersonId: string
}

interface SavePhoneNumberArg {
  phone: string
}

interface SaveAssociatedPhoneArg extends SavePhoneNumberArg {
  associatedPersonId: string
}

interface SaveAddressArg {
  streetAddress: string
  extendedAddress: string
  postalCode: string
  state: number
  city: string
}
interface SaveNewPasswordArg {
  currentPassword: string
  password: string
  passwordConfirm: string
}

interface BankingRequisitesArg {
  financialAccountId: string
}

interface BankingRequisitesApi {
  data: any
  content: any
}

interface GenerateSecretArg {
  useQR: boolean
}

interface CreatePlaidLinkTokenArg {
  platform: string
}

interface CreatePlaidAccount {
  id: string
  name: string
  mask: string
  subtype: string
  type: string
}

interface CreatePlaidExchangePublicTokenArg {
  institution: {
    id: string
    name: string
  }
  publicToken: string
  accounts: CreatePlaidAccount[]
}

interface ForgotPasswordArg {
  nickName: string
}

interface SaveAssociatedAddressArg {
  StreetAddress: string
  PostalCode: string
  State: number
  City: string
  associatedPersonId: string
}

interface OpsConfirmAuthArg {
  multifactorSecret: string
}

interface DocumentUploadRequirementArg {
  token?: string
}
export interface DocumentUploadRequirementApi {
  businessProfile: Profile
  primaryAuthorizedPerson: Profile
  ultimateBeneficialOwners: Profile
}

interface Profile {
  results: null
  requiredDocuments: RequiredDocument[]
}

export enum RequiredDocumentStatus {
  SUBMITTED = 'SUBMITTED',
  INITIATED = 'INITIATED'
}

export interface RequiredDocument {
  createdAt: Date
  updatedAt: Date
  status: RequiredDocumentStatus
  documentUploadSession: DocumentUploadSession
}

interface DocumentUploadSession {
  type: number
  id: string
  primaryDocumentTypes: string[]
}

export interface UploadDocumentForVerificationArg {
  token: string
  queryArg: {
    profileId: number
    documentUploadSessionId: string
    documentType: string
    fileName: string
    data: string
    contentType: string
    verificatedEntity: string
  }
}

export interface SendErrorArg {
  error: string;
}

interface AccountHolderInformation {
  countryCodeAlpha3: string;
  email: string;
  locality: string;
  streetAddress: string;
  postalCode: string;
  region: string;
  state: number;
  familyName: string;
  givenName: string;
  phone: string;
}

export interface HolderEmployer {
  date: string;
  enabled: string;
  id: number;
  name: string;
}

export interface IHolderV2ControllerApi {
  id: number
  createdDate: Date
  updatedDate: null
  userProfileId: number
  step: number
  accountHolderInformation: AccountHolderInformation | null;
  businessAccountHolder: IBusinessAccountHolder
  isSubmitted: boolean
  accountHolderId: string
  nickName: string
  cardProductId: string | null
  openApplicationId: string
  financialAccountId: string
  applicationStatus: number
  bankingStatus: string
  applicationDate: Date
  approvalDate: Date | null
  isDeleted: boolean
  isLocked: boolean
  isAccessLocked: boolean
  isEnable2Fa: boolean
  agentPayoutProfileId: number
  salesRepresentativeId: number
  rewordType: number
  isNew: boolean
  isExceptional: boolean
  profileSettings: IProfileSettings
  applicationCreationType: string
  exceptionalTemplateId: null
  profileType: number;
  employer?: HolderEmployer;
}

export interface IBusinessAccountHolder {
  email: string
  givenName: string
  familyName: string
  middleName: string
  phone: string
  ssn: string
  dateOfBirth: Date
  streetAddress: string
  extendedAddress: string
  postalCode: string
  locality: string
  state: number
  countryCodeAlpha3: string
  percentageOwnership: number
  authorizingPersonTitle: number
  legalBusinessName: string
  doingBusinessAsName: string
  website: string
  businessType: number
  businessPhone: string
  employerIdentificationNumber: string
  primaryAuthorizedPersonId: null
  primaryAuthorizedStreetAddress: string
  primaryAuthorizedExtendedAddress: string
  primaryAuthorizedPostalCode: string
  primaryAuthorizedLocality: string
  primaryAuthorizedState: number
  businessStartDate: Date
  annualBusinessRevenue: number
  owners: IOwner[]
  merchantProfileId: null
  id: number
  createdDate: Date
  updatedDate: null
}

export interface IOwner {
  highNoteId: null
  businessAccountId: number
  givenName: string
  familyName: string
  middleName: string
  email: string
  dateOfBirth: Date
  percentageOwnership: number
  phoneNumber: string
  ssn: string
  streetAddress: string
  extendedAddress: string
  postalCode: string
  locality: string
  state: number
  countryCodeAlpha3: string
  ownerType: number
  id: number
  createdDate: Date
  updatedDate: null
}

interface IProfileSettings {
  financialAccountsPermissions: IFinancialAccountsPermissions
  transferAmountLimits: ITransferAmountLimits
  loanOff: boolean
}

interface IFinancialAccountsPermissions {
  financialAccountsMaxNumber: number
  externalAccountsMaxNumber: number
}

interface ITransferAmountLimits {
  achOutTransferAmountLimit: null
  internalTransferAmountLimit: null
}

interface EndSessionDocHNArg {
  token: string;
  profileId: number;
  verificatedEntity: string;
  documentUploadSessionId: string;
}

interface EndSessionDocsArg {
  token: string;
  documentUploadSessionId: string;
}
interface ChangePhoneOtpArg {  
  phone: string,
  oneTimeCode: string,
  secureOperationType: SecureOperationType
}
interface ChangeEmailOtpArg{
  email: string,
  oneTimeCode: string,
  secureOperationType: SecureOperationType
}
interface ChangeAddressOtpArg{
  streetAddress: string,
  extendedAddress: string,
  postalCode: string,
  city: string,
  state: number,
  oneTimeCode: string,
  secureOperationType: SecureOperationType
}

export const accountApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'account',
  tagTypes: [
    ApiTags.GetHolder,
    ApiTags.ChekSecretOtp,
    ApiTags.GetUser,
  ],
  endpoints: (builder) => ({
    endSessionDocHN: builder.mutation<void, EndSessionDocHNArg>({
      query: (queryArg) => ({
        url: 'endUploadDocumentSession',
        method: 'POST',
        body: {
          profileId: queryArg.profileId,
          verificatedEntity: queryArg.verificatedEntity,
          documentUploadSessionId: queryArg.documentUploadSessionId,
        },
        headers: {
          Authorization: `Bearer ${queryArg.token}`,
        },
      }),
    }),
    endSessionDoc: builder.mutation<void, EndSessionDocsArg>({
      query: (queryArg) => ({
        url: `endUploadDocumentVerificationSession/${queryArg.documentUploadSessionId}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${queryArg.token}`,
        },
      }),
    }),
    productApplicationController: builder.mutation<any, any>({
      query: ({ token }) => ({
        url: 'v2/productApplication',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    uploadDocumentController: builder.mutation<any, any>({
      query: ({ token, queryArg }) => ({
        url: 'uploadDocument',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: queryArg,
      }),
    }),
    endUploadDocumentSessionController: builder.mutation<any, any>({
      query: (queryArg) => ({
        url: 'endUploadDocumentSession',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${queryArg.token}`,
        },
        body: queryArg,
      }),
    }),
    saveEmailController: builder.mutation<any, SaveEmailArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changeEmail',
        method: 'POST',
        body: queryArg,
      }),
    }),
    savePhoneNumberController: builder.mutation<any, SavePhoneNumberArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changePhone',
        method: 'POST',
        body: queryArg,
      }),
    }),
    saveAddressController: builder.mutation<any, SaveAddressArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changeAddress',
        method: 'POST',
        body: queryArg,
      }),
    }),
    saveNewPasswordController: builder.mutation<any, SaveNewPasswordArg>({
      query: (queryArg) => ({
        url: 'changePassword',
        method: 'POST',
        body: queryArg,
      }),
    }),
    restorePassword: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'restorePassword', //"v2/restorePassword",
        method: 'POST',
        params: queryArg,
      }),
    }),
    restorePasswordOTP: builder.mutation<any, any>({
      query: (data) => ({
        url: 'restorePasswordOTP', //  "v2/restorePasswordOTP",
        method: 'POST',
        params: data,
      }),
    }),
    bankingRequisitesController: builder.mutation<
      BankingRequisitesApi,
      BankingRequisitesArg
    >({
      query: ({ financialAccountId }) => ({
        url: 'BankingRequisites',
        method: 'GET',
        params: {
          financialAccountId,
        },
      }),
    }),
    generateSecretController: builder.mutation<any, GenerateSecretArg>({
      query: ({ useQR = false }) => ({
        url: 'api/generateSecret',
        method: 'GET',
        responseHandler: 'text',
        // responseHandler: useQR
        //     ? async (response) => {
        //           if (response.status === 200) {
        //               const blob = await response.blob();
        //               const dataUrl = URL.createObjectURL(blob);
        //               return dataUrl;
        //           }
        //       }
        //     : 'text',
        params: {
          useQR,
        },
      }),
    }),

    createPlaidLinkTokenController: builder.query<
      string,
      CreatePlaidLinkTokenArg
    >({
      query: ({ platform }) => ({
        url: 'api/plaidLinkToken',
        responseHandler: 'text',
        method: 'GET',
        params: {
          platform,
        },
      }),
    }),
    createPlaidExchangePublicTokenController: builder.mutation<
      string,
      CreatePlaidExchangePublicTokenArg
    >({
      query: (queryArg) => ({
        url: 'api/plaidExchangeToken/v2',
        responseHandler: 'text',
        method: 'POST',
        body: queryArg,
      }),
    }),
    forgotPassword: builder.mutation<any, ForgotPasswordArg>({
      query: (queryArg) => ({
        url: 'v2/forgotPassword',
        method: 'POST',
        body: queryArg,
      }),
    }),
    forgotPasswordOTP: builder.mutation<any, ForgotPasswordArg>({
      query: (queryArg) => ({
        url: 'v3/forgotPasswordOTP',
        method: 'POST',
        body: queryArg,
      }),
    }),
    createFinicityLink: builder.query<string, void>({
      query: () => ({
        url: 'api/connectFinancialAccount/v2',
        responseHandler: 'text',
        method: 'GET',
      }),
    }),
    saveAssociatedAddress: builder.mutation<any, SaveAssociatedAddressArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changeAssociatedAddress',
        method: 'POST',
        body: queryArg,
      }),
    }),
    saveAssociatedPhone: builder.mutation<any, SaveAssociatedPhoneArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changeAssociatedPhone',
        method: 'POST',
        body: queryArg,
      }),
    }),
    saveAssociatedEmail: builder.mutation<any, SaveAssociatedEmailArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'changeAssociatedEmail',
        method: 'POST',
        body: queryArg,
      }),
    }),
    opsConfirmAuthController: builder.mutation<any, OpsConfirmAuthArg>({
      invalidatesTags: [ApiTags.ChekSecretOtp],
      query: (queryArg) => ({
        url: 'api/opsConfirmAuth',
        method: 'POST',
        body: queryArg,
      }),
    }),
    checkIsTimeBasedSetUp: builder.query<any, any>({
      providesTags: [ApiTags.ChekSecretOtp],
      query: () => ({
        url: 'api/isTimeBasedSetUp',
        method: 'GET',
      }),
    }),
    setMfaSecret: builder.mutation<any, { secret: string }>({
      invalidatesTags: [ApiTags.GetUser],
      query: ({ secret }) => ({
        url: 'settings/MfaSecret',
        method: 'POST',
        body: {
          multifactorSecret: secret,
        },
      }),
    }),
    deleteMfaSecret: builder.mutation<any, void>({
      invalidatesTags: [ApiTags.GetUser],
      query: () => ({
        url: 'settings/MfaSecret',
        method: 'DELETE',
      }),
    }),
    setMfaSignIn: builder.mutation<any, { isEnabled: boolean }>({
      invalidatesTags: [ApiTags.GetUser],
      query: ({ isEnabled }) => ({
        url: 'settings/MfaSignIn',
        method: isEnabled ? 'POST' : 'DELETE',
      }),
    }),
    qrCode: builder.query<any, any>({
      query: ({ secret }) => ({
        url: 'api/qrCode',
        responseHandler: async (response) => {
          if (response.status === 200) {
            const blob = await response.blob()
            const dataUrl = URL.createObjectURL(blob)
            return dataUrl
          }
        },
        method: 'GET',
        params: {
          secret,
        },
      }),
    }),
    changeLogin: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetUser],
      query: (queryArg) => ({
        url: 'changeLogin',
        method: 'POST',
        body: queryArg,
      }),
    }),
    uploadDocumentForVerification: builder.mutation<
      any,
      UploadDocumentForVerificationArg
    >({
      query: ({ token, queryArg }) => ({
        url: 'uploadDocumentForVerification',
        method: 'POST',
        body: queryArg,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    uploadDocumentForVerificationHN: builder.mutation<
      any,
      UploadDocumentForVerificationArg
    >({
      query: ({ token, queryArg }) => ({
        url: 'uploadDocument',
        method: 'POST',
        body: queryArg,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    sendErrorLog: builder.mutation<
      any,
      SendErrorArg
    >({
      query: ({ error }) => ({
        url: 'api/clientApplication/log',
        method: 'POST',
        params: {
          type: 'WEB',
          log: error,
        }
      }),
    }),
    documentUploadRequirement: builder.query<
      DocumentUploadRequirementApi,
      DocumentUploadRequirementArg
    >({
      query: ({ token }) => ({
        url: 'DocumentUploadRequirement',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    holderV2Controller: builder.query<IHolderV2ControllerApi, void>({
      providesTags: [ApiTags.GetHolder],
      query: () => ({
        url: 'v2/holder',
        method: 'GET',
      }),
    }),
    changePhoneOtp: builder.mutation<void, ChangePhoneOtpArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'api/account/changePhoneOtp',
        method: 'POST',
        body: queryArg,
      }),
    }),

    changeEmailOtp: builder.mutation<void, ChangeEmailOtpArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'api/account/changeEmailOtp',
        method: 'POST',
        body: queryArg,
      }),
    }),
    changeAddressOtp: builder.mutation<void, ChangeAddressOtpArg>({
      invalidatesTags: [ApiTags.GetHolder],
      query: (queryArg) => ({
        url: 'api/account/changeAddressOtp',
        method: 'POST',
        body: queryArg,
      }),
    }),
   
  }),
})

export const {
  useProductApplicationControllerMutation,
  useUploadDocumentControllerMutation,
  useEndUploadDocumentSessionControllerMutation,
  useSaveEmailControllerMutation,
  useSavePhoneNumberControllerMutation,
  useSaveAddressControllerMutation,
  useSaveNewPasswordControllerMutation,
  useBankingRequisitesControllerMutation,
  useGenerateSecretControllerMutation,
  useCreatePlaidLinkTokenControllerQuery,
  useCreatePlaidExchangePublicTokenControllerMutation,
  useForgotPasswordMutation,
  useRestorePasswordMutation,
  useCreateFinicityLinkQuery,
  useSaveAssociatedAddressMutation,
  useSaveAssociatedEmailMutation,
  useSaveAssociatedPhoneMutation,
  useOpsConfirmAuthControllerMutation,
  useCheckIsTimeBasedSetUpQuery,
  useRestorePasswordOTPMutation,
  useForgotPasswordOTPMutation,
  useQrCodeQuery,
  useLazyQrCodeQuery,
  useChangeLoginMutation,
  useUploadDocumentForVerificationMutation,
  useUploadDocumentForVerificationHNMutation,
  useLazyDocumentUploadRequirementQuery,
  useDocumentUploadRequirementQuery,
  useHolderV2ControllerQuery,
  useEndSessionDocHNMutation,
  useEndSessionDocMutation,
  useSendErrorLogMutation,
  useSetMfaSecretMutation,
  useSetMfaSignInMutation,
  useDeleteMfaSecretMutation,
  useChangePhoneOtpMutation,
  useChangeEmailOtpMutation,
  useChangeAddressOtpMutation
} = accountApi
