import React from 'react';

export const footerLegal = {
  consumer: (
    <>
      Netevia is a financial services company, not a bank. Banking services are
      provided by CFSB;
      <br />
      Member FDIC. Deposits are FDIC-insured up to $250,000.
    </>
  ),
  business: (
    <>
      Netevia is not a bank. Banking services provided by Sutton Bank, Member
      FDIC.
      <br />
      Should the member bank holding your balance fail, you are insured for up
      to $250,000 of your money in the account.
    </>
  ),
} as const;

