import { PlaidBadgeIcon, FinicityBadgeIcon } from 'shared/ui/icons'
import { ExternalBankAccountsProvider } from 'api/endpoints/fundsMovement'
import styles from './styles.module.scss'

interface ExternalBadgeProviderStatusProps {
  provider: ExternalBankAccountsProvider
}
export const ExternalBadgeProviderStatus = ({
  provider,
}: ExternalBadgeProviderStatusProps) => {
  const renderBadgeProvider =
    provider === ExternalBankAccountsProvider.PLAID ? (
      <PlaidBadgeIcon className={styles.badgeProvider} />
    ) : (
      <FinicityBadgeIcon className={styles.badgeProvider} />
    )

  return renderBadgeProvider
}
