import { useEffect, useState } from "react";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./style.module.scss";
import helper from "services/helper";
import SnackBarSuccess from "shared/components/snackBar/snackBarSuccess";
import Radiogroup from "shared/components/radioGroup";
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowLeft from "assets/svg/arrow-left-gray.svg";

export const Language = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const { t, changeLanguage, currentLanguage } = useTranslationProvider();
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const handleChangeLanguage = async (value: string) => {
    changeLanguage(value);
    await helper.sleep(500);
    setIsShowSuccess(true);
  };
  useEffect(() => {}, [currentLanguage]);
  const items = [
    {
      label: t("common.English", {
        defaultValue: "English",
      }),
      value: "en",
    },
    {
      label: t("common.Español", {
        defaultValue: "Español",
      }),
      value: "es",
    },
  ];
  return (
    <>
      {!matches && (
        <div className={styles.container_nav}>
          <img src={ArrowLeft} onClick={() => setSearchParams({})} />
          <span className={styles.container_nav_title}>
            {t("Settings.Company information", {
              defaultValue: "Company information",
            })}
          </span>
        </div>
      )}
      <div className={styles.container}>
        <Radiogroup
          iteams={items}
          onChange={handleChangeLanguage}
          currentValue={currentLanguage}
        />
      </div>
      <SnackBarSuccess
        message={t("Settings.The language has been successfully changed", {
          defaultValue: "The language has been successfully changed",
        })}
        isOpen={isShowSuccess}
        onClose={() => setIsShowSuccess(false)}
      />
    </>
  );
};
