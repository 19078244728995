import {  MenuItem, Select } from "@mui/material";
import styles from "./style.module.scss";

export interface SelectYearProps {
  value: string;
  onChange: (value: string) => void;
  periods: string[];
}

const SelectYear = ({ onChange, value, periods }: SelectYearProps) => {
  const handleYearChange = (event) => {
    const selectedYear = event.target.value as string;
    if (onChange) {
      onChange(selectedYear);
    }
  };
  if (!periods) return <></>;
  return (
    <div className={styles.container}>
      <Select value={value} onChange={handleYearChange}>
        {periods.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default SelectYear;
