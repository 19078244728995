import { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { requireNotNull } from "../../shared/utils/require-not-null";
import styles from "./style.module.scss";
import { ClickAwayListener } from "@mui/material";
import { useEscape } from "../../shared/hooks/use-keyboards";
import cx from "classnames";

interface Props {
  onClose?(): void;
  size?: "small";
  stylesWrapper?: any;
  stopPropagation?: boolean;
}

type PopupProps = PropsWithChildren<Props>;

const PopupContent = ({
  children,
  onClose,
  size = "small",
  stylesWrapper,
  stopPropagation,
}: PopupProps) => {
  const handleCloseImg = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    onClose && onClose();
  };
  const handleClose = (e) => {
    if (e.target.id === "pupap-success") onClose && onClose();
  };
  useEscape(handleClose);

  return (
    <div className={styles.overlay} id="pupap-success">
      <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
        <div
          className={cx(
            styles.content,
            {
              [styles.contentSmall]: size === "small",
            },
            stylesWrapper
          )}
        >
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export const Popup = (props: PopupProps) => {
  return ReactDOM.createPortal(
    <PopupContent {...props} />,
    requireNotNull(document.getElementById("app-popup"))
  );
};
