import { FC } from 'react';
import styles from './style.module.scss';
import helper from 'services/helper';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
interface PrimaryAccItemProps {
  item: FinancialAccount;
  handleSumbit?: (id: string) => void;
  sumbitIsLoading?: boolean;
}

const PrimaryAccItem: FC<PrimaryAccItemProps> = ({
  item,
  handleSumbit,
  sumbitIsLoading,
}) => {
  return (
    <div className={styles.item}>
      <div>
        <div className={styles.item_box}>
          <Typography className={styles.item_name}>{item?.name}</Typography>
          <Typography className={styles.item_chekingText}>
            Checking Account:
            {handleSumbit && ` ****${item?.accountNumber?.slice(-4)}`}
          </Typography>
        </div>
      </div>

      <div>
        {handleSumbit ? (
          <Button
            variant='text'
            onClick={() => handleSumbit(item.financialAccountId)}
            disabled={sumbitIsLoading}
          >
            Set as primary
          </Button>
        ) : (
          <>
            <Typography className={styles.item_amount}>
              ${helper.moneyFormat(item?.availableCash?.value)}
            </Typography>
            <Typography className={styles.item_last4}>
              ****
              {item?.accountNumber?.slice(-4)}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};
export default PrimaryAccItem;
