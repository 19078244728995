import { Typography } from 'shared/ui/typography';
import { Badge } from 'shared/ui/badge';
import styles from './styles.module.scss';
import { ReadMore } from 'shared/ui/expand/readMore';
import helper from 'services/helper';
import cn from 'classnames';
interface DetailsItemProps {
  logo: string;
  name: string;
  clientDiscount: number;
  description: string;
  giftCardBalance?: string;
  priceToPay?: number;
}

export const DetailsInfo = (props: DetailsItemProps) => {
  const {
    logo,
    name,
    clientDiscount,
    description,
    giftCardBalance,
    priceToPay,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInfo}>
        <div className={styles.boxImg}>
          <img
            src={logo}
            alt={name}
          />
        </div>
        <div className={styles.boxName}>
          <Typography>{name}</Typography>
          <div className={styles.box}>
            <Typography className={styles.text}>Discount</Typography>
            <Badge
              styledWrapper={styles.badge}
              styledLabel={styles.badgeLabel}
              label={`save ${clientDiscount}%`}
            />
          </div>
          {!!giftCardBalance && (
            <div className={styles.box}>
              <Typography className={styles.text}>
                Gift card balance:
              </Typography>
              <Typography className={styles.text}>
                ${helper.moneyFormat(giftCardBalance)}
              </Typography>
            </div>
          )}
          {!!priceToPay && (
            <div className={styles.box}>
              <Typography className={styles.text}>Price to pay:</Typography>

              <Typography className={cn(styles.strikethrough, styles.text)}>
                ${helper.moneyFormat(giftCardBalance)}
              </Typography>
              <Typography className={styles.text}>
                ${helper.moneyFormat(priceToPay)}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <ReadMore
        text={description || ''}
        maxLength={147}
        styledText={styles.descriptionText}
      />
    </div>
  );
};
