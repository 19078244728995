import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import DrawerModal from "shared/components/drawerModal";
import SwitchDirectDeposit from "shared/components/SwitchDirectDeposit";
import { useBoolean, UseBooleanReturn} from "shared/hooks/useBoolean";

interface SwitchDirectDepositValue {
  value: UseBooleanReturn['value'],
  show: UseBooleanReturn['setTrue'],
  hide: UseBooleanReturn['setFalse'],
}

const initialValue = {
  value: false,
  show: () => {},
  hide: () => {},
};

const SwitchDirectDepositContext = createContext<SwitchDirectDepositValue>(initialValue);

export const SwitchDirectDepositProvider = ({ children }: PropsWithChildren) => {
  const switchDirectDeposit = useBoolean();

  const value = useMemo(() => ({
    value: switchDirectDeposit.value,
    show: switchDirectDeposit.setTrue,
    hide: switchDirectDeposit.setFalse,
  }), [switchDirectDeposit.value]);
  
  return (
    <SwitchDirectDepositContext.Provider value={value}>
      {children}
      <DrawerModal
        titleText="Switch Direct Deposit"
        isShow={value.value}
        onClose={value.hide}
      >
        <SwitchDirectDeposit onSuccess={value.hide} />
      </DrawerModal>
    </SwitchDirectDepositContext.Provider>
  );
};

export const useSwitchDirectDepositDrawer = () => useContext(SwitchDirectDepositContext);
