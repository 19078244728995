import {
  ChevronRightIcon,
  CreditCardIcon,
  CreditCardSuccessIcon,
} from 'shared/ui/icons'
import { ButtonGroup } from 'shared/ui/button-groups'
import { Typography } from 'shared/ui/typography'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { Button } from 'shared/ui/buttons'
import styles from './styles.module.scss'
import { TransitionAnimation } from 'shared/ui/animations'

export const SelectingCardType = () => {
  const { goNext } = useDrawerStepperContext()

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography variant='body1'>
          You&apos;re on your way to enjoying the benefits of the OrderOut Card.
          We&apos;re here to make it easy for you.
        </Typography>

        <Typography variant='body1'>
          Select the type of card you want to open
        </Typography>

        <ButtonGroup>
          <Button
            startIcon={<CreditCardIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('2')}
          >
            Physical OrderOut card
          </Button>

          <Button
            startIcon={<CreditCardSuccessIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('3')}
          >
            Virtual OrderOut card
          </Button>
        </ButtonGroup>
      </div>
    </TransitionAnimation>
  )
}
