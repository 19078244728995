import { ReactComponentElement, useState } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from "assets/svg/calendar.svg";

interface IMonthCalendar {
  currentDate?: Date;
  onChange: (date: Date) => void;
  children?: any;
  disableFuture?: boolean;
  isDatapiker?: boolean;
  styleWrapperTooltip?: any;
  fill?: string;
  hideLabel?: boolean;
  height?: number;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}
const MonthCalendar = ({
  onChange,
  currentDate,
  children,
  placement,
  disableFuture,
  isDatapiker,
  styleWrapperTooltip,
  fill,
  hideLabel,
  height,
}: IMonthCalendar) => {
  const [isOpen, setIsopen] = useState(false);

  const handelClose = () => {
    setIsopen(false);
  };

  const handleChangeDate = (date) => {
    date && onChange(date.$d);
    handelClose();
  };

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 340,
      ...styleWrapperTooltip,
    },
  });

  const getCalendar = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        views={["month", "year"]}
        openTo="month"
        onChange={handleChangeDate}
        defaultValue={dayjs(currentDate)}
        disableFuture={disableFuture}
        sx={{
          "& .MuiSvgIcon-root": { fill: fill ? fill : "white" },
        }}
      />
    </LocalizationProvider>
  );

  return (
    <div>
      {!isDatapiker ? (
        <ClickAwayListener onClickAway={handelClose}>
          <div>
            <CustomWidthTooltip
              PopperProps={{
                disablePortal: true,
              }}
              placement={placement ? placement : "left"}
              open={isOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              TransitionComponent={Fade}
              onClose={handelClose}
              title={getCalendar()}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setIsopen(!isOpen)}
              >
                {children ? children : <img src={CalendarIcon} />}
              </div>
            </CustomWidthTooltip>
          </div>
        </ClickAwayListener>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            openTo="month"
            onChange={handleChangeDate}
            defaultValue={dayjs(currentDate)}
            disableFuture={disableFuture}
            label={hideLabel ? "" : "Select month"}
            views={["year", "month"]}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": height
                ? { height: `${height}px`, minHeight: `${height}px` }
                : {},
            }}
          />
        </LocalizationProvider>
      )}
    </div>
  );
};

export default MonthCalendar;
