import { Typography } from 'shared/ui/typography'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { OrderOutCard } from 'entities/orderout-card'
import { Button } from 'shared/ui/buttons'
import styles from './styles.module.scss'
import { useMediaQuery } from '@mui/material'
import { useOpenVirtualCard } from '../../model'
import { TransitionAnimation } from 'shared/ui/animations'

export const OpenVirtualCardStep1 = () => {
  const { goNext } = useDrawerStepperContext()

  const { openVirtualCard, isLoading } = useOpenVirtualCard()

  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleConfirmClick = async () => {
    const result = await openVirtualCard()

    if (!result.isError) goNext('4')
  }

  return (
    <TransitionAnimation containerProps={{ className: styles.animContainer }}>
      <div className={styles.root}>
        <Typography variant='body1'>
          You are about to open a Virtual OrderOut card, it’s going to be linked
          to your Netevia account. Ready to proceed?
        </Typography>

        <div className={styles.cardContainer}>
          <OrderOutCard />
        </div>

        <Button
          loading={isLoading}
          fullWidth={isMobile}
          className={styles.button}
          onClick={handleConfirmClick}
        >
          Confirm
        </Button>
      </div>
    </TransitionAnimation>
  )
}
