import { Button } from "shared/ui/buttons"
import AddIcon from '@mui/icons-material/Add'
import { Typography } from "@mui/material";
import { useBoolean } from "shared/hooks/useBoolean";
import DrawerAddExternalBank from "./components/DrawerAddExternalBank";

import styles from './style.module.scss'

const AddExternalBank = () => {
  const isShowDrawer = useBoolean()

  return (
    <>
      <Button variant="text" className={styles.btn} onClick={isShowDrawer.setTrue}>
        <AddIcon /> <Typography>add external bank</Typography>
      </Button>
      <DrawerAddExternalBank 
        isShow={isShowDrawer.value} 
        onClose={isShowDrawer.setFalse} 
      />
    </>

  )
}

export default AddExternalBank;