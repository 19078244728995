import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
import {
  usePlaidApplicationsControllerQuery,
  useUnlinkPlaidApplicationsControllerMutation,
} from "api/endpoints/settings";
import { LincedItem } from "./LincedItem";

export const Integrations = () => {
  const { t } = useTranslationProvider();
  const { data, isLoading, error } = usePlaidApplicationsControllerQuery({});
  const [unlinkPlaidApplicationsController, { isLoading: unlinkIsLoading }] =
    useUnlinkPlaidApplicationsControllerMutation();
  const items = data ? data : [];
  return (
    <>
      <div className={styles.title}>Connected applications</div>
      {/* @ts-ignore */}
      {/* {error && <div className={styles.info}>{error.data}</div>} */}

      <>
        <div className={styles.info}>
          We make sure it is simple for you to keep track of which apps and
          websites are connected to your account. You can easily see what
          information is being shared and stop sharing with any app or website
          whenever you want.
        </div>
        <div className={styles.info}>Active ({items.length})</div>
        {items?.length ? (
          <div className={styles.container}>
            {data.map((item, index) => (
              <LincedItem
                unlinkPlaidApplicationsController={
                  unlinkPlaidApplicationsController
                }
                key={index}
                {...item}
              />
            ))}
          </div>
        ) : (
          <div>No connected applications</div>
        )}
      </>
    </>
  );
};
