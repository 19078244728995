import {
  TableHead as TableHeadMUI,
  TableRow as TableRowMUI,
  TableCell as TableCellMUI,
} from '@mui/material'
import styles from './styles.module.scss'

interface TableHeaderProps {
  header: string[]
}

export const TableHeader = ({ header }: TableHeaderProps) => {
  const renderTableCell = () =>
    header.map((el) => (
      <TableCellMUI
        key={el}
        classes={{ root: styles.label }}
      >
        {el}
      </TableCellMUI>
    ))

  return (
    <TableHeadMUI classes={{ root: styles.wrapper }}>
      <TableRowMUI>{renderTableCell()}</TableRowMUI>
    </TableHeadMUI>
  )
}
