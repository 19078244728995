import { useState, useEffect } from 'react'
import USPS from '../../../assets/svg/USPS svg.svg'
import Square from '../../../assets/svg/new-account-square.svg'
import NewCheckSquare from '../../../assets/svg/new-check-square.svg'
import UPS from '../../../assets/svg/ups-logo 1.svg'
import { useDeliveryCompanyControllerMutation } from '../../../api/endpoints/enums'
import MoonLoader from 'react-spinners/MoonLoader'
import styles from './style.module.scss'
import { Radio, RadioGroup } from '@mui/material'
import { Skeleton } from 'components/skeleton'

interface DeliveryServiceProps {
  deliveryChoise: string
  deliveryChoiseSave: any
}

const DeliveryService = (props: DeliveryServiceProps) => {
  const { deliveryChoise, deliveryChoiseSave } = props
  const [
    deliveryCompanyMutation,
    { isLoading: deliveryCompanyIsLoading, data },
  ] = useDeliveryCompanyControllerMutation()
  const [delivery, setDelivery] = useState<any>([])

  useEffect(() => {
    getDeliveryCompany()
  }, [])

  const getDeliveryCompany = async () => {
    const res = await deliveryCompanyMutation({}).unwrap()
    const unique = new Map(res?.map((el) => [el.name, el]))
    const item = Object.fromEntries(unique)
    setDelivery(item)
  }

  return (
    <div className={styles.container}>
      {deliveryCompanyIsLoading ? (
        <Skeleton
          height={200}
          width={'100%'}
        />
      ) : (
        <>
          <div className={styles.wrapperDelivery}>
            <div className={styles.boxUSPS}>
              <img
                className={styles.boxUSPS_img}
                src={USPS}
              />
              <p className={styles.boxUSPS_title}>USPS</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() => deliveryChoiseSave(delivery?.USPS_GROUND?.name)}
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.USPS_GROUND?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.USPS_GROUND?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.USPS_GROUND?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Ground</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.USPS_GROUND?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() =>
                  deliveryChoiseSave(delivery?.USPS_PRIORITY?.name)
                }
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.USPS_PRIORITY?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.USPS_PRIORITY?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.USPS_PRIORITY?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Priority</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.USPS_PRIORITY?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() => deliveryChoiseSave(delivery?.USPS_EXPRESS?.name)}
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.USPS_EXPRESS?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.USPS_EXPRESS?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.USPS_EXPRESS?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Express</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.USPS_EXPRESS?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
          </div>

          <div className={styles.wrapperDelivery}>
            <div className={styles.boxUPS}>
              <img
                className={styles.boxUPS_img}
                src={UPS}
              />
              <p className={styles.boxUPS_title}>UPS</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() => deliveryChoiseSave(delivery?.UPS_GROUND?.name)}
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.UPS_GROUND?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.UPS_GROUND?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.UPS_GROUND?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Ground</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.UPS_GROUND?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() =>
                  deliveryChoiseSave(delivery?.UPS_SECOND_DAY.name)
                }
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.UPS_SECOND_DAY?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.UPS_SECOND_DAY?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.UPS_SECOND_DAY?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Priority</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.UPS_SECOND_DAY?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
            <div className={styles.boxDelivery}>
              <div
                onClick={() => deliveryChoiseSave(delivery?.UPS_NEXT_DAY?.name)}
                className='cursor-pointer'
              >
                <RadioGroup
                  value={
                    deliveryChoise === delivery?.UPS_NEXT_DAY?.name
                      ? 'selected'
                      : 'notSelected'
                  }
                  onChange={() =>
                    deliveryChoiseSave(delivery?.UPS_NEXT_DAY?.name)
                  }
                >
                  <Radio
                    value='selected'
                    checked={deliveryChoise === delivery?.UPS_NEXT_DAY?.name}
                    color='primary'
                    className={styles.radio}
                  />
                </RadioGroup>
              </div>
              <p className={styles.boxDelivery_label}>Express</p>
              <p className={styles.boxDelivery_description}>
                {delivery?.UPS_NEXT_DAY?.description}
              </p>
              <p className={styles.boxDelivery_infotext}>FREE</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default DeliveryService

export { DeliveryService }
