import {
  BusinessSignUpScreen,
  SignUpBusinessProvider,
  useSignUpBusiness,
} from './provider';
import { SignUpLoginAndPass } from '../../shared/signUp/loginAndPass';
import { LoginLayoutRender } from '../../layouts/LoginLayout';
import { BusinessSignUpInfo } from './businessInfo';
import { BusinessAddress } from './businessAddress';
import { BusinessSignUpPrimaryAuthorizedPerson } from './primaryAuthorizedPerson';
import { BusinessSignUpPrimaryOwnerInformation } from './primaryOwnerInformation';
import { BusinessSignUpConfirm } from './confirm';
import { SignUpApplicationStatus } from '../../shared/signUp/applicationStatus';
import { BusinessSignUpAnotherOwners } from './anotherOwners';

const ResolveScreen = () => {
  const { screen, updateContextForm, setScreen, contextForm } =
    useSignUpBusiness();

  if (screen === BusinessSignUpScreen.loginAndPass) {
    return (
      <LoginLayoutRender>
        <SignUpLoginAndPass
          initialValues={{
            login: contextForm.login,
            password: contextForm.password,
            passwordConfirm: contextForm.passwordConfirm,
          }}
          onSubmit={(values) => {
            updateContextForm(values);
            setScreen(BusinessSignUpScreen.businessInfo);
          }}
        />
      </LoginLayoutRender>
    );
  }

  if (screen === BusinessSignUpScreen.businessInfo) {
    return <BusinessSignUpInfo />;
  }

  if (screen === BusinessSignUpScreen.businessAddress) {
    return <BusinessAddress />;
  }

  if (screen === BusinessSignUpScreen.primaryAuthorizedPerson) {
    return <BusinessSignUpPrimaryAuthorizedPerson />;
  }

  if (screen === BusinessSignUpScreen.primaryOwner) {
    return <BusinessSignUpPrimaryOwnerInformation />;
  }

  if (screen === BusinessSignUpScreen.anotherOwners) {
    return <BusinessSignUpAnotherOwners />;
  }

  if (screen === BusinessSignUpScreen.confirm) {
    return <BusinessSignUpConfirm />;
  }

  if (screen === BusinessSignUpScreen.applicationStatus) {
    return (
      <SignUpApplicationStatus
        isConsumerMode={false}
        title='Sign up complete!'
        subtitle='We need some time to check the details. Application review usually takes approximately 1-2 business days. You will get a notification to your email address, when it’s done. In case of any questions, feel free to contact us.'
        steps={[
          { title: 'Get started', isPassed: true },
          { title: 'Application submitted', isPassed: true },
          { title: 'Under review', isPassed: false },
          { title: 'Account ready', isPassed: false },
        ]}
      />
    );
  }

  return null;
};

export const SignUpBusiness = () => (
  <SignUpBusinessProvider>
    <ResolveScreen />
  </SignUpBusinessProvider>
);
