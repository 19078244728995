import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import cn from 'classnames';
interface BatchTransferItemProps {
  transferIndex: number;
  fromAccName: string;
  fromAccLast4: string;
  toPayeeName: string;
  toPayeeLast4: string;
  amount: string;
  memo?: string;
}
export const BatchTransferItem = (props: BatchTransferItemProps) => {
  const {
    fromAccName,
    fromAccLast4,
    toPayeeName,
    toPayeeLast4,
    amount,
    transferIndex,
    memo,
  } = props;

  return (
    <div className={styles.transferItem}>
      <Typography className={styles.title}>#{transferIndex + 1}</Typography>
      <div className={styles.wrapperAccount}>
        <Typography className={styles.label}>From</Typography>
        <div className={styles.box}>
          <Typography>{fromAccName}</Typography>
          <Typography className={styles.last4}>({fromAccLast4})</Typography>
        </div>
      </div>
      <div className={styles.wrapperAccount}>
        <Typography className={styles.label}>To</Typography>
        <div className={styles.box}>
          <Typography>{toPayeeName}</Typography>
          <Typography className={styles.last4}>({toPayeeLast4})</Typography>
        </div>
      </div>
      <div className={styles.wrapperAmount}>
        <Typography className={styles.label}>Amount</Typography>
        <Typography>{amount}</Typography>
      </div>

      <div className={styles.wrapperMemo}>
        <Typography className={styles.label}>Memo</Typography>
        <Typography className={styles.memoMessage}>{memo || '  '}</Typography>
      </div>
    </div>
  );
};
