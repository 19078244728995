import * as yup from 'yup';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import dayjs, { Dayjs } from 'dayjs';

export const createAchOrNeteviaTransferSchema = (
  financialAccounts: FinancialAccount[]
) => {
  return yup.object().shape({
    from: yup
      .string()
      .required()
      .test(
        'amount-check',
        'The amount in your account should not exceed the balance',
        function (value) {
          const { amount } = this.parent;
          const financialAccount = financialAccounts.find(
            (account) => account.financialAccountId === value
          );

          if (
            financialAccount &&
            amount &&
            Number(amount) > financialAccount.availableCash.value
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
    to: yup.string().required(),
    amount: yup
      .string()
      .required('Amount is a required field')
      .test({
        name: 'is-non-zero',
        message: 'Float value must not be zero',
        test: (value) => Number(value) !== 0,
      }),
    frequency: yup.string(),
    dateTransfer: yup.mixed<Dayjs>().nullable(),
    memo: yup.string().max(1024, 'Memo must be at most 1024 characters'),
  });
};
