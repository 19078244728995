import { useState, ReactNode, SyntheticEvent } from 'react';
import { Tab as TabMUI } from '@mui/material';
import {
    TabContext as TabContextMUI,
    TabList as TabListMUI,
    TabPanel as TabPanelMUI,
} from '@mui/lab';
import { AnimateWrapper } from 'components/animate-wrapper';
import styles from './styles.module.scss';
import cn from 'classnames';

interface ITab {
    id: string;
    label: string;
    component: ReactNode;
}

interface ITabsProps {
    data: ITab[];
    initialTab?: string;
    styleWrapper?: string;
    onChangeTab?: (tabId: string) => void;
}

export const Tabs = ({ initialTab, data, styleWrapper, onChangeTab }: ITabsProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(
        initialTab || data[0].id
    );

    const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
        onChangeTab?.(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <TabContextMUI value={selectedTab}>
                <TabListMUI
                    onChange={handleChangeTab}
                    aria-label="lab API tabs example"
                    className={cn(styleWrapper,styles.list)}
                >
                    {data?.map((el) => (
                        <TabMUI label={el.label} value={el.id} key={el.id} />
                    ))}
                </TabListMUI>
                {data?.map((el) => (
                    <TabPanelMUI
                        key={el.id}
                        value={el.id}
                        className={styles.tabPanel}
                    >
                        <AnimateWrapper className="fade">
                            {el.component}
                        </AnimateWrapper>
                    </TabPanelMUI>
                ))}
            </TabContextMUI>
        </div>
    );
};
