import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import { findObjById } from 'entities/transfers'
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts'
import { SourceAccountItem } from 'entities/transfers'
import { SourceAccount } from '../../model'

interface SourceAccountListProps {
  sourceList: SourceAccount[]
}

export const SourceAccountList = ({ sourceList }: SourceAccountListProps) => {
  const { unclosedFinancialAccounts } = useFinancialAccounts()

  const renderList = () => {
    return sourceList?.map((el) => {
      const account = findObjById(
        el.fromFinancialAccount,
        unclosedFinancialAccounts
      )
      const error = el.totalAmount > account?.availableCash?.value
      return (
        <SourceAccountItem
          key={el.fromFinancialAccount}
          from={findObjById(el.fromFinancialAccount, unclosedFinancialAccounts)}
          totalAmount={el.totalAmount}
          visibleError={error}
        />
      )
    })
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>Selected source account:</Typography>
      <div className={styles.list}>{renderList()}</div>
    </div>
  )
}
