import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { DetailsTransfer } from 'entities/transfers';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector } from 'shared/models';
import moment from 'moment';
import { useAppDispatch } from 'shared/models';
import { clearDataAch } from '../../model';
import styles from './styles.module.scss';
import { selectScheduleTransfer } from '../../model';
import helper from 'services/helper';

export const ManageScheduleTransferSuccess = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();

  const scheduleTransfer = useAppSelector(selectScheduleTransfer);

  const handleNavigate = () => {
    dispatch(clearDataAch());
    goNext('0');
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {scheduleTransfer && (
          <DetailsTransfer
            amount={`$${helper.moneyFormat(scheduleTransfer?.amount)}`}
            accountFrom={scheduleTransfer?.fromAccount}
            accountTo={scheduleTransfer?.toAccount}
            date={moment(scheduleTransfer?.dateTransfer).format('MM/DD/YYYY')}
            frequency={scheduleTransfer?.frequency}
            memo={scheduleTransfer?.memo}
          />
        )}

        <Button
          variant='contained'
          loading={false}
          onClick={handleNavigate}
        >
          done
        </Button>
      </div>
    </TransitionAnimation>
  );
};
