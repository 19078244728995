import * as yup from 'yup';

export const AddressSchema = yup.object().shape({
  streetAddress: yup.string().required('Street address is required'),
  locality: yup.string().required('Locality is required'),
  region: yup.string().required('Region is required'),
  postalCode: yup.string().required('Postal code is required'),
});
export type Address = {
  streetAddress: string;
  locality: string;
  region: string;
  postalCode: string;
};

export const formatAddress = (address: Address, line2?: string) => {
  try {
    AddressSchema.validateSync(address);
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      throw new Error(error.errors.join(', '));
    }
  }

  return `${address.streetAddress},${line2 ? ` ${line2},` : ''}\n${
    address.locality
  }, ${address.region} ${address.postalCode}`;
};
