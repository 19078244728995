import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { DetailsTransfer } from 'entities/transfers/ui/detailsTransfer';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAppSelector } from 'shared/models';
import {
  selectFromPayee,
  selectToFinancialAccount,
  selectAmount,
  selectDateTransfer,
  selectFrequency,
  selectTransferMemo,
} from '../../model';
import moment from 'moment';
import helper from 'services/helper';

export const MakeTransferConfirm = () => {
  const { goNext } = useDrawerStepperContext();

  const fromPayee = useAppSelector(selectFromPayee);
  const toFinancialAccount = useAppSelector(selectToFinancialAccount);
  const amount = useAppSelector(selectAmount);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const frequency = useAppSelector(selectFrequency);
  const memo = useAppSelector(selectTransferMemo);

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>Review & confirm</Typography>
        <DetailsTransfer
          amount={`$${helper.moneyFormat(amount)}`}
          accountFrom={fromPayee}
          accountTo={toFinancialAccount}
          date={moment(dateTransfer).format('MM/DD/YYYY')}
          frequency={frequency}
          memo={memo}
        />
        <Button
          variant='contained'
          loading={false}
          onClick={() => goNext('2')}
        >
          confirm transfer
        </Button>
      </div>
    </TransitionAnimation>
  );
};
