import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppDispatch, useAppSelector } from 'shared/models';
import {
  clearDataToMyOwnAccount,
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
  selectTransferMemo,
} from 'features/transfers/transfer-to-my-own-account/model';
import { DetailsTransfer } from 'entities/transfers/ui/detailsTransfer';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import helper from 'services/helper';

export const MakeTransferSuccess = () => {
  const dispatch = useAppDispatch();
  const { close, goNext } = useDrawerStepperContext();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)
  const memo = useAppSelector(selectTransferMemo)

  const handleClick = () => {
    dispatch(clearDataToMyOwnAccount());
    goNext('0');
    close();
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsTransfer
          title='Transfer successfully completed'
          amount={`$${helper.moneyFormat(amount)}`}
          accountFrom={fromFinancialAccount}
          accountTo={toFinancialAccount}
          memo={memo}
        />
        <Button
          variant='contained'
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  );
};
