import { ChangeEvent, useEffect, useState } from 'react';
import { AnimateWrapper } from 'components/animate-wrapper';
import { DocumentUploadRequirementApi, RequiredDocument, RequiredDocumentStatus, useDocumentUploadRequirementQuery, useEndSessionDocHNMutation, useEndSessionDocMutation, useUploadDocumentForVerificationHNMutation, useUploadDocumentForVerificationMutation } from 'api/endpoints/account';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentList from './components/documentList';
import { Button, css, styled, Typography } from '@mui/material';
import { convertFileToBase64 } from 'shared/utils/convertFileToBase64';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Skeleton } from 'components/skeleton';
import { appRoutes, VerifyDocsSubRoute } from '../../routes';
import { useBoolean } from '../../shared/hooks/useBoolean';

export interface Document {
  createdAt: Date;
  updatedAt: Date;
  status: string;
  documentUploadSession: {
    type: number;
    id: string;
    primaryDocumentTypes: string;
  };
}

export interface ObjFiles {
  file: File;
  documentUploadSessionId: string;
  documentType: string;
  verificatedEntity: string;
}

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10 MB

interface Props {
  userId?: any;
  onAllDocsSubmit(): void;
  submitButtonText: string;
  isBackButtonHidden?: boolean;
  successText: string;
}

const GroupButton = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-top: 8px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: row-reverse;
      align-items: center;
      gap: 24px;
      margin-top: 24px;
    }
  `}
`;

const GroupLists = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 16px;

    ${theme.breakpoints.down('sm')} {
      gap: 32px;
    }
  `}
`;

export const VerificationDocument = ({ userId, onAllDocsSubmit, submitButtonText, isBackButtonHidden, successText }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSnackBar } = useSnackBar();
  const token = location.state?.token;
  const statusHN = location.state?.statusHN;

  const {
    data: docs,
    isFetching: documentRequirementIsLaoding,
    refetch: documentRequirementRefetch,
  } = useDocumentUploadRequirementQuery({ token });

  const uploadProcessingBool = useBoolean();

  const [uploadDocsMutation] =
    useUploadDocumentForVerificationMutation();
  const [uploadDocsMutationHN] =
    useUploadDocumentForVerificationHNMutation();

  const [endSessionDocHN] = useEndSessionDocHNMutation();
  const [endSessionDoc] = useEndSessionDocMutation();

  const [businessFiles, setBusinessFiles] = useState<ObjFiles[]>([]);
  const [primaryFiles, setPrimaryFiles] = useState<ObjFiles[]>([]);
  const [ultimateFiles, setUltimateFiles] = useState<ObjFiles[]>([]);

  useEffect(() => {
    if (docs && checkDocStatusSubmitted(docs)) {
      onAllDocsSubmit();
    }
  }, [docs, onAllDocsSubmit]);

  const checkDocStatusSubmitted = (data: DocumentUploadRequirementApi) => {
    const checkStatus = (documents: RequiredDocument[]): boolean =>
      documents?.every((doc) =>
        doc.status === RequiredDocumentStatus.SUBMITTED || doc.documentUploadSession?.primaryDocumentTypes?.length === 0
      );

    const businessProfileStatus = checkStatus(
      data.businessProfile?.requiredDocuments
    );
    const primaryAuthorizedPersonStatus = checkStatus(
      data.primaryAuthorizedPerson?.requiredDocuments
    );
    const ultimateBeneficialOwnersStatus = checkStatus(
      data.ultimateBeneficialOwners?.requiredDocuments
    );

    return (
      businessProfileStatus &&
      primaryAuthorizedPersonStatus &&
      ultimateBeneficialOwnersStatus
    );
  };

  const transformPrimaryDocumentTypes = (arr: RequiredDocument[]) =>
    arr.flatMap((documentItem) => {
      return documentItem.documentUploadSession.primaryDocumentTypes.map(item => {
        return {
          ...documentItem,
          documentUploadSession: {
            ...documentItem.documentUploadSession,
            primaryDocumentTypes: item,
          },
        };
      })
    });

  const businessProfileRequiredDocuments = transformPrimaryDocumentTypes(
    docs?.businessProfile?.requiredDocuments || []
  );

  const primaryAuthorizedPersonRequiredDocuments =
    transformPrimaryDocumentTypes(
      docs?.primaryAuthorizedPerson?.requiredDocuments || []
    );

  const ultimateBeneficialOwnersRequiredDocuments =
    transformPrimaryDocumentTypes(
      docs?.ultimateBeneficialOwners?.requiredDocuments || []
    );

  const condidtionsRenderList = (arr: Document[]) =>
    !!arr?.length &&
    arr.some(
      (doc) => doc?.documentUploadSession?.primaryDocumentTypes?.length
    );

  let allFiles = [...businessFiles, ...primaryFiles, ...ultimateFiles];

  const sumbitDocs = async () => {
    try {
      uploadProcessingBool.setTrue();

      for (const item of allFiles) {
        if (item.file) {
          const fileBase64 = (await convertFileToBase64(item.file)) as string;
          const formattedFileBase64 = fileBase64?.substring(
            fileBase64.indexOf(',') + 1
          );

          const queryArg = {
            profileId: userId,
            documentUploadSessionId: item.documentUploadSessionId,
            documentType: item.documentType,
            fileName: item.file.name,
            data: formattedFileBase64,
            contentType: item.file.type,
            verificatedEntity: item.verificatedEntity,
          };

          if (statusHN) {
            await uploadDocsMutationHN({
              token,
              queryArg,
            }).unwrap();

            await endSessionDocHN({
              token,
              profileId: userId,
              verificatedEntity: 'Business',
              documentUploadSessionId: item.documentUploadSessionId,
            });
          } else {
            await uploadDocsMutation({
              token,
              queryArg,
            }).unwrap();
          }
        }
      }

      const docs = await documentRequirementRefetch().unwrap();
      const isAllDocsSubmitted = checkDocStatusSubmitted(docs);

      if (!statusHN && isAllDocsSubmitted && allFiles.length > 0) {
        await endSessionDoc({
          token,
          documentUploadSessionId: allFiles[0].documentUploadSessionId,
        }).unwrap();
      }

      cleanUploadFiles();

      if (isAllDocsSubmitted) {
        setSnackBar({
          type: 'success',
          message: successText ||  'success',
          isShow: true,
        });
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    } finally {
      uploadProcessingBool.setFalse();
    }
  };

  const cleanUploadFiles = () => {
    allFiles = [];
    setBusinessFiles([]);
    setPrimaryFiles([]);
    setUltimateFiles([]);
  };

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
    id: string,
    verificatedEntity: string
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (MAX_FILE_SIZE > file.size) {
        const fileObj = {
          file,
          documentUploadSessionId: id,
          documentType: type,
          verificatedEntity,
        };

        switch (verificatedEntity) {
          case 'Business':
            setBusinessFiles((prevFiles: ObjFiles[]) => [
              ...prevFiles,
              fileObj,
            ]);
            break;
          case 'Personal':
            setPrimaryFiles((prevFiles: ObjFiles[]) => [...prevFiles, fileObj]);
            break;
          case 'Owner':
            setUltimateFiles((prevFiles: ObjFiles[]) => [
              ...prevFiles,
              fileObj,
            ]);
            break;
        }
      } else {
        setSnackBar({
          type: 'error',
          message: 'The maximum allowed file size for uploads is 20 MB',
          isShow: true,
        });
      }
    }
  };

  return documentRequirementIsLaoding
    ? <Skeleton width="100%" height={200} />
    : (
      <>
        <GroupLists>
          {condidtionsRenderList(businessProfileRequiredDocuments) && (
            <DocumentList
              title="Business information"
              array={businessProfileRequiredDocuments}
              uploadFile={handleFileUpload}
              setFile={setBusinessFiles}
              files={businessFiles}
              verificatedEntity="Business"
              isDeleteDisabled={uploadProcessingBool.value}
            />
          )}
          {condidtionsRenderList(
            primaryAuthorizedPersonRequiredDocuments
          ) && (
            <DocumentList
              title="Primary authorized user"
              array={primaryAuthorizedPersonRequiredDocuments}
              uploadFile={handleFileUpload}
              setFile={setPrimaryFiles}
              files={primaryFiles}
              verificatedEntity="Personal"
              isDeleteDisabled={uploadProcessingBool.value}
            />
          )}
          {condidtionsRenderList(
            ultimateBeneficialOwnersRequiredDocuments
          ) && (
            <DocumentList
              title="Ultimate Beneficial Owner"
              array={ultimateBeneficialOwnersRequiredDocuments}
              uploadFile={handleFileUpload}
              setFile={setUltimateFiles}
              files={ultimateFiles}
              verificatedEntity="Owner"
              isDeleteDisabled={uploadProcessingBool.value}
            />
          )}
        </GroupLists>
        <br />
        <GroupButton>
          <LoadingButton
            variant="contained"
            onClick={sumbitDocs}
            disabled={!allFiles?.length}
            loading={uploadProcessingBool.value}
            sx={{
              background: '#023047',
            }}
          >
            <div>{submitButtonText}</div>
          </LoadingButton>
          {!isBackButtonHidden && (
            <Button
              variant="text"
              disabled={uploadProcessingBool.value}
              onClick={() => {
                navigate('/');
              }}
            >
              Back
            </Button>
          )}
        </GroupButton>
      </>
  )
};
