import ChevronRight from "../../../pages/newProduct/components/ChevronRight";
import styles from "./style.module.scss";

ButtonWithChevron.defaultProps = {};

interface ButtonWithChevron {
  text: string;
  img?: any;
  onClick?: () => void;
}

export default function ButtonWithChevron(props: ButtonWithChevron) {
  return (
    <div onClick={props.onClick} className={styles.container}>
      <div className={styles.box}>
        <img className={styles.box_img} src={props.img} />
        <p className={styles.box_text}>{props.text}</p>
      </div>
      <ChevronRight />
    </div>
  );
}
