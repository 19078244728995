import { TransitionAnimation } from 'shared/ui/animations';
import { ListButton } from 'shared/ui/lists';
import { Button } from 'shared/ui/buttons';
import styles from './style.module.scss';
import { ChevronRightIcon } from 'shared/ui/icons';
import { CloseOutlinedIcon } from 'shared/ui/icons';
import { PersonOutlineIcon } from 'shared/ui/icons';
import { WalletIcon } from 'shared/ui/icons';
import { BarChartIcon } from 'shared/ui/icons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { usePermission } from 'shared/hooks/usePermission'

export const Intro = () => {
    const { goNext } = useDrawerStepperContext()
    const {manageAutomationsPermission} = usePermission()

  return (
    <TransitionAnimation>
      <div>
        <ListButton className={styles.listBtn}>
        {manageAutomationsPermission && ( 
          <Button
            startIcon={<WalletIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('1')}
          >
            Manage automations
          </Button>
          )}
          <Button
            startIcon={<BarChartIcon/>}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('2')}
          >
            Spending report
          </Button>
          <Button
            startIcon={<PersonOutlineIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('3')}
          >
            Rename account
          </Button>
          <Button
            startIcon={<CloseOutlinedIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('4')}
          >
            Close account
          </Button>
        </ListButton>
      </div>
    </TransitionAnimation>
  );
};
