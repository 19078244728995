import styles from  "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Typography } from "shared/ui/typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormikProps } from "formik";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useMediaQuery } from "@mui/material";
import { FilterFormValues } from "pages/support/types/interfaces";

interface FilterByDateProsp {
  formik: FormikProps<FilterFormValues>;
}

const FilterByDate= ({ formik }: FilterByDateProsp) => {
  const { t } = useTranslationProvider();
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div className={styles.wrapper}>
      <Typography className={styles.wrapperTitle}>
        {t('Support.Filter by dates', {
          defaultValue: 'Filter by dates',
        })}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          disableFuture
          localeText={{ start: 'From', end: 'To' }}
          calendars={1}
          value={formik.values.byDate}
          onChange={(newDateRange) => {
            formik.setFieldValue('byDate', newDateRange);
          }}
          slotProps={{
            textField: {
              error: !!formik.errors.byDate,
            },
            fieldSeparator: {
              display: 'none',
            },
            fieldRoot: {
              direction: isMobile ? 'column' : 'row',
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};
export default FilterByDate;
