import React from 'react'
import type { ListProps as ListMUIProps } from '@mui/material'
import {
  List as ListMUI,
  ListSubheader as ListSubheaderMUI,
} from '@mui/material'
import styles from './styles.module.scss'
import cn from 'classnames'

interface ListSubheaderProps
  extends Pick<ListMUIProps, 'className' | 'children'> {
  label: string | React.ReactNode
}

export const ListSubheader = (props: ListSubheaderProps) => {
  const { label, children, className } = props

  return (
    <ListMUI
      className={cn(styles.listButtonGroup, className)}
      component='div'
      aria-labelledby='nested-list-subheader'
      disablePadding
      subheader={
        <ListSubheaderMUI
          component='div'
          id='nested-list-subheader'
        >
          {label}
        </ListSubheaderMUI>
      }
    >
      {children}
    </ListMUI>
  )
}
