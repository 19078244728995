import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import queryString from "query-string";
import {
  getLocalStorageObject,
  LocalStorageKeys,
} from "../shared/utils/local-storage";
import { logOut } from "./logOut";
import { Mutex } from "async-mutex";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL_API,
  paramsSerializer: (params) => {
    return queryString.stringify(params, {
      arrayFormat: "none",
    });
  },
  prepareHeaders: (headers) => {
    const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiRtkMutex = new Mutex();

export const rtkBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await apiRtkMutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    await logOut();
  }

  if (result.error?.data) {
    // todo make global snackbar
  }

  return result;
};
