import React, { FC } from "react";
import copyIcon from "assets/svg/copy-blue.svg";
import Status from "pages/support/history/components/status/Status";
import styles from "./style.module.scss";
import cx from "classnames";
import { IconButton } from "@mui/material";
import { useHolderV2ControllerQuery } from "api/endpoints/account";

interface HeaderChatProps {
  ticketId: number | undefined;
  date: string;
  status: any;
}
const HeaderChat: FC<HeaderChatProps> = ({ ticketId, date, status }) => {
  const { data: holder } = useHolderV2ControllerQuery();

  const handleCopyTicketNumber = (e) => {
    navigator.clipboard.writeText(e);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_box}>
        <div className={styles.block}>
          <p className={styles.block_text}>Ticket</p>
          <div className={styles.block_ticketId}>
            <p className={styles.block_ticketId_text}>#{ticketId}</p>
            <IconButton
              color="inherit"
              aria-label="Copy ticket"
              onClick={() => handleCopyTicketNumber(ticketId)}
              className={styles.block_ticketId_img}
            >
              <img src={copyIcon} />
            </IconButton>
          </div>
        </div>
        <Status status={status} />
      </div>
      <div className={styles.wrapper_box}>
        <p className={cx(styles.wrapper_box_text, styles.wrapper_box_date)}>
          {date}
        </p>
        <p className={styles.wrapper_box_text}>
          {holder?.businessAccountHolder?.legalBusinessName}
        </p>
      </div>
    </div>
  );
};
export default HeaderChat;
