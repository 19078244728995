import { FC } from "react";
import styles from "./style.module.scss";
import cx from "classnames";
import moment from "moment";
import cancelIcon from "assets/svg/cancel-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import Status from "../status/Status";
import { UseBooleanReturn } from "shared/hooks/useBoolean";
import { Button } from "shared/ui/buttons";
import { FilterIcon } from "shared/ui/icons";
import { TicketStatus } from "api/endpoints/tickets";
import { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro";
interface OpenFilterProps {
  showFilterBool: UseBooleanReturn
  periodDate: DateRange<Dayjs | null>
  setPeriodDate: (periodDate: DateRange<Dayjs | null>) => void
  type: TicketStatus[]
  setType: ( type: TicketStatus[] ) => void
}

const OpenFilter: FC<OpenFilterProps> = ({
  showFilterBool,
  periodDate,
  setPeriodDate,
  type,
  setType
}) => {
  const { t } = useTranslationProvider();

  const [startDate, endDate] = periodDate;
  const periodDateBoolean = !!startDate && !!endDate
  const statusNew = type.includes(TicketStatus.NEW)
  const statusProgress =  type.includes(TicketStatus.WORKING_IN_PROGRESS)
  const statusResolved = type.includes(TicketStatus.RESOLVED)

  const filterBoolean = [
    periodDateBoolean,
    statusNew ||
    statusProgress ||
    statusResolved
  ].some(Boolean);


  const styleWrapper = filterBoolean
    ? cx(styles.wrapper, styles.haveFilter)
    : styles.wrapper;

  const handleDeletePeriod = () => {
    setPeriodDate([null,null])
  };

  const handleDeleteType = (status: TicketStatus) => {
    const updatedType = type.filter(item => item !== status);
    setType(updatedType)
  };

  return (
    <div className={styleWrapper}>
      <div className={styles.boxFilter}>
        {periodDateBoolean && (
          <div className={styles.wrapper_date}>
            <span className={styles.wrapper_date_text}>
            {moment(startDate.toDate()).format("MM/DD/YYYY")} - {moment(endDate.toDate()).format("MM/DD/YYYY")}
            </span>
            <img src={cancelIcon} onClick={handleDeletePeriod} />
          </div>
        )}
        {statusNew  && (
          <Status status={TicketStatus.NEW}
            handleDelete={handleDeleteType}
          />
        )}
        {statusProgress && (
          <Status
            status={TicketStatus.WORKING_IN_PROGRESS}
            handleDelete={handleDeleteType}
          />
        )}
        {statusResolved && (
          <Status
            status={TicketStatus.RESOLVED}
            handleDelete={handleDeleteType}
          />
        )}
      </div>
      <div className={styles.boxButton}>
        <Button
          onClick={() => showFilterBool.setTrue()}
          variant="contained"
        >
          <FilterIcon className={styles.filterIcon}/>
          {t("common.Filter", {
            defaultValue: "Filter",
          })}
        </Button>
      </div>
    </div>
  );
};

export default OpenFilter;
