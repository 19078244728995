import { Typography } from 'shared/ui/typography';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Button } from 'shared/ui/buttons';
import { useMediaQuery } from '@mui/material';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';

export const OpenPhysicalCardStep3 = () => {
  const { close } = useDrawerStepperContext();

  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleClick = () => {
    close();
  };

  return (
    <TransitionAnimation containerProps={{ className: styles.animContainer }}>
      <div className={styles.root}>
        <Typography variant="h6">
          Success! Your request for a physical card is complete
        </Typography>

        <Typography variant="body1">
          It will take 5 to 10 minutes for us to confirm your request.
        </Typography>

        <Button
          fullWidth={isMobile}
          className={styles.button}
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  );
};
