import { FC } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { CategoryItemProps } from "../categoryItem";
import { AnimateWrapper } from "components/animate-wrapper";
import { useMediaQuery, useTheme } from "@mui/material";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface DonutChartProps {
  categories: CategoryItemProps[];
  total: string | undefined;
}

const DonutChart: FC<DonutChartProps> = ({ categories, total }) => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const width = matches ? 186 : 113;

  const notTransactions = [
    {
      label: t("Settings.No transactions for selected period", {
        defaultValue: "No transactions for selected period",
      }),
      value: 100,
      color: "#D9D9D9",
    },
  ];

  const donutData = !!categories?.length
    ? categories?.map((categoryItem) => ({
        label: categoryItem.category,
        value: categoryItem.percentage < 0.6 ? 0.6 : categoryItem.percentage,
        color: categoryItem.color,
        proccent:
          categoryItem.percentage < 0.6 ? categoryItem.percentage : undefined,
      }))
    : notTransactions;

  const formatValue = (item, categories) => {
    if (!!categories?.length) {
      let formattedValue = `${item.value}%`;
      if (!!item.proccent) {
        formattedValue = ` ${item.proccent}%`;
      }
      return formattedValue;
    } else {
      return `${item.value * 0}%`;
    }
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
    fontWeight: 500,
    whiteSpace: "pre-wrap",
  }));

  const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
    const { height, top } = useDrawingArea();

    return (
      <StyledText x={width + 3} y={top + height / 2}>
        {children}
      </StyledText>
    );
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <PieChart
          series={[
            {
              data: donutData,
              innerRadius: matches ? 130 : 116,
              outerRadius: matches ? 186 : 80,
              paddingAngle: matches ? 4 : 1,
              cx: width,
              valueFormatter: (item) => formatValue(item, categories),
            },
          ]}
          legend={{ hidden: true }}
          width={matches ? 380 : 235}
          height={matches ? 380 : 235}
        >
          <PieCenterLabel>
            Total
            {"\n"} $ {total}
          </PieCenterLabel>
        </PieChart>
      </div>
    </AnimateWrapper>
  );
};
export default DonutChart;
