import { css, styled, Typography } from "@mui/material";
import emptyIcon from "assets/svg/arrows-in-circle.svg";

const EmptyRecentPoits = () => {
  return (
    <Wrapper>
      <img src={emptyIcon} alt={"arrows"} />
      <Text>
        You don`t have any recent transactions to display at the moment. Stay
        tuned for updates and explore our services to begin recording your
        transactions.
      </Text>
    </Wrapper>
  );
};
export default EmptyRecentPoits;

const Wrapper = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    & > img {
      width: 24px;
      height: 24px;
    }
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
  `}
`;
