import { FC } from "react";
import Badge from "./component/badge";
import BadgeTwo from "./component/badgeTwo";
import { useCurrentUser } from "providers/user.provider";
interface CardBadgeProps {
  isClosed?: boolean;
  isAuthorizedUser: any;
  isLocked?: boolean;
  isActivate?: boolean;
  isSmallSize?: boolean;
}
const BadgeConfigurations = {
  //   activate: { label: "Activate the card", color: "#8BB557" },
  primary: { label: "Primary Card", color: "#E5E0FF" },
  authorized: { label: "Authorized User", color: "#EBEBEB" },
  locked: { label: "Card is locked", color: "#535F74" },
  closed: { label: "Card is closed", color: "#535F74" },
};

const CardBadge: FC<CardBadgeProps> = ({
  isClosed,
  isAuthorizedUser,
  isLocked,
  isActivate,
  isSmallSize,
}) => {
  const { isSubUser, user, subUserRights } = useCurrentUser();
  const renderBadge = () => {
    const subUserPrimaryBadge =
      isSubUser &&
      isAuthorizedUser?.isMainCard &&
      isAuthorizedUser?.subProfileId === user?.id;
    const subUserBadgeForUser = isAuthorizedUser && !(isClosed || isLocked);
    if (isAuthorizedUser?.isMainCard) {
      return (
        <Badge
          styleClass={isSmallSize ? "smallWrapper" : undefined}
          label={BadgeConfigurations.primary.label}
          color={BadgeConfigurations.primary.color}
        />
      )
    }

    if (subUserPrimaryBadge) {
      return (
        <Badge
          label={BadgeConfigurations.primary.label}
          color={BadgeConfigurations.primary.color}
        />
      );
    } else if (subUserBadgeForUser) {
      return (
        <Badge
          label={BadgeConfigurations.authorized.label}
          color={BadgeConfigurations.authorized.color}
        />
      );
    } else if (isLocked) {
      return (
        <Badge
          label={BadgeConfigurations.locked.label}
          color={BadgeConfigurations.locked.color}
          textColor="#fff"
        />
      );
    } else if (isClosed) {
      return (
        <Badge
          label={BadgeConfigurations.closed.label}
          color={BadgeConfigurations.closed.color}
        />
      );
    }
  };
  return <>{renderBadge()}</>;
};

export default CardBadge;
