import { FC } from 'react';
import styles from './styles.module.scss';
import { Button } from '@mui/material';
import cn from 'classnames';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
interface SliderHeaderProps {
  goToPrev: () => void;
  disabledPrev: boolean;
  goToNext: () => void;
  disabledNext: boolean;
  title?: string;
  styleWrapperHeader?: string;
}

const SliderHeader: FC<SliderHeaderProps> = ({
  goToPrev,
  disabledPrev,
  goToNext,
  disabledNext,
  title,
  styleWrapperHeader,
}) => {
  return (
    <div className={cn(styleWrapperHeader, styles.title)}>
      {title && <span>{title}</span>}
      <div>
        <Button
          variant='outlined'
          onClick={goToPrev}
          disabled={disabledPrev}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <Button
          variant='outlined'
          onClick={goToNext}
          disabled={disabledNext}
        >
          <ArrowForwardIosIcon />
        </Button>
      </div>
    </div>
  );
};
export default SliderHeader;
