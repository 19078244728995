import React from 'react'
import { EmptyPayees } from 'entities/transfers'
import { ConnectedPayee } from 'entities/transfers'
import { Divider } from '@mui/material'
import { AttacheCaseIcon } from 'shared/ui/icons'
import { Typography } from 'shared/ui/typography'
import { Skeleton } from 'components/skeleton'
import styles from './styles.module.scss'
import { PlaidPayees } from 'api/hooksApi/useExternalPayees'
import { TransitionAnimation } from 'shared/ui/animations'
import { Button } from 'shared/ui/buttons'
import { ListSubheader } from 'shared/ui/lists'
import { ChevronRightIcon } from 'shared/ui/icons'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'

interface ConnectedPayeeListProps {
  payees: PlaidPayees[]
  isLoading: boolean
  handleDeletePayee: (id: string) => Promise<void>
  isLoadingDelete: boolean
  deletingPayeeId: string | null
}

export const ConnectedPayeeList = (props: ConnectedPayeeListProps) => {
  const {
    payees,
    isLoading,
    handleDeletePayee,
    isLoadingDelete,
    deletingPayeeId,
  } = props
  const { goNext } = useDrawerStepperContext()
  const labeleForList = (
    <div className={styles.labelForList}>
      <AttacheCaseIcon />
      <Typography>Connected accounts</Typography>
    </div>
  )

  const renderPayee = () => {
    return !!payees?.length ? (
      <ListSubheader
        label={labeleForList}
        className={styles.list}
      >
        {payees.map((payee, index) => (
          <React.Fragment key={payee.id}>
            <ConnectedPayee
              key={payee.id}
              payee={payee}
              handleDeletePayee={handleDeletePayee}
              isLoadingDelete={isLoadingDelete}
              deletingPayeeId={deletingPayeeId}
            />
            {index !== payees.length - 1 && (
              <Divider className={styles.divider} />
            )}
          </React.Fragment>
        ))}
        <Button
          variant='text'
          endIcon={<ChevronRightIcon />}
          className={styles.btnList}
          onClick={() => goNext('9')}
        >
          connected account history
        </Button>
      </ListSubheader>
    ) : (
      <EmptyPayees
        title={'No connected financial accounts'}
        subTitle={'Connected financial accounts will be displayed here'}
        icon={<AttacheCaseIcon />}
      />
    )
  }
  return (
    <TransitionAnimation>
      {isLoading ? (
        <Skeleton
          width='100%'
          height='110px'
        />
      ) : (
        renderPayee()
      )}
    </TransitionAnimation>
  )
}
