import React, { ChangeEvent, FC } from "react";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import styles from "./style.module.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface FormCommentProps {
  question: string;
  setQuestion: (question: string) => void;
}

const FormComment: FC<FormCommentProps> = ({ question, setQuestion }) => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };
  return (
    <FormControl fullWidth>
      <TextField
        value={question}
        onChange={handleChangeValue}
        variant="outlined"
        placeholder={t("Support.Type your question here", {
          defaultValue: "Type your question here",
        })}
        multiline
        rows={matches ? 10 : 5}
        InputProps={{
          className: styles.textFieldInput,
        }}
      />
    </FormControl>
  );
};
export default FormComment;
