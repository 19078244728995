import { FC } from "react";
import { Divider, List } from "@mui/material";
import helper from "services/helper";
import styles from "./styles.module.scss";
import SelectRewardsItem from "../selectRewardsItem";
import { AnimateWrapper } from "components/animate-wrapper";
import { FinancialAccount } from "api/endpoints/financialAccounts";

interface SelectRewardsListProps {
  list: FinancialAccount[];
  handleChooseAcc: (id: string) => void;
}

const SelectRewardsList: FC<SelectRewardsListProps> = ({
  list,
  handleChooseAcc,
}) => {
  const renderItem = () => {
    return list?.map((user: FinancialAccount, index) => (
      <div
        key={user.financialAccountId}
        onClick={() => handleChooseAcc(user.financialAccountId)}
      >
        <SelectRewardsItem
          name={user?.name}
          accountNumberLast4={user?.accountNumber.slice(-4)}
          balance={helper.moneyFormat(user.availableCash?.value) || ''}
        />
        {index < list.length - 1 && <Divider />}
      </div>
    ));
  };
  return (
    <AnimateWrapper className="fade">
      <List disablePadding className={styles.listWrapper}>
        {renderItem()}
      </List>
    </AnimateWrapper>
  );
};
export default SelectRewardsList;
