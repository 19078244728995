import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";
import moment from "moment";


interface AvailableToSpendCardControllerArg {
  paymentCardId: string;
}

interface CardTransactionEventsControllerArg {
  paymentCardId: string;
  transactionId: string;
}

interface SetTransactionMemoEventsControllerArg {
  paymentCardId: string;
  transactionId: string;
  memo: string;
}

export enum EnumTransactionStatuses {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  OTHER = "OTHER",
  DECLINED = "DECLINED",
}

export enum TransactionType {
  GiftCardPurchase = 'Gift Card Purchase',
  RewardsDeposit = 'Rewards deposit',
  Transfer = 'Transfer',
  ACHTransfer = 'ACH Transfer',
  TransferBetweenOwnAccounts = 'Transfer between own accounts',
  Deposit = 'Deposit'
}

export interface Amount {
  symbol: string;
  value: number;
  currencyCode: string;
}

export interface Transaction {
  id: string;
  date: string;
  balance: {
    value: number;
    currencyCode: string;
  } | null;
  status: EnumTransactionStatuses;
  amount: Amount;
  clearAmount: Amount | null;
  pendingAmount: Amount | null;
  name: string;
  last4: {
    type: string;
    value: string;
  };
  type: string | TransactionType;
  authorizedUser: string | null;
  merchantCategory: any | null;
  transactionSource: any | null;
  rewardPoints: number | null
}

export interface FilterItem {
  displayName: string;
  value: boolean;
  items: FilterItem[] | null;
}

export interface Filters {
  all: FilterItem;
  deposit: FilterItem;
  withdraw: FilterItem;
  transfers: {
    displayName: string;
    value: boolean;
    items: FilterItem[] | null;
  };
  pos: {
    displayName: string;
    value: boolean;
    items: FilterItem[] | null;
  };
}
export interface FiltersArg {
  all?: FilterItem;
  deposit?: FilterItem;
  withdraw?: FilterItem;
  transfers?: {
    displayName: string;
    value: boolean;
    items: FilterItem[] | null;
  };
  pos?: {
    displayName: string;
    value: boolean;
    items: FilterItem[] | null;
  };
}

export interface TransactionData {
  data: Transaction[];
  filter: Filters;
}


export interface FinancialAccountTransactionsServerArg {
  financialAccountId: string;
  dateFrom: string;
  dateTo: string;
  filter?: FiltersArg;
  jsonFilter?: string;
  spendEvents?: string[];
  amountFilter?: {
    minValue?: number | null;
    maxValue?: number  | null;
  };
  searchString?: string;
}

export interface ExternalTransactionsArgs {
  financialAccountId: string;
  dateFrom: string;
  dateTo: string;
}

export const transactionApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "transaction",
  tagTypes: [ApiTags.GetCardTransactionEvents],
  endpoints: (builder) => ({
    financialAccountTransactionsServerController: builder.query<
    TransactionData,
    FinancialAccountTransactionsServerArg
  >({
      query: (queryArg) => ({
        url: 'api/transaction/v3/byFinancialAccount/server',
        method: 'post',
        body: queryArg,
      }),
    }),
    financialAccountTransactionsController: builder.mutation<
      TransactionData,
      any
    >({
      query: ({ financialAccountId, from, to, filter }) => ({
        url: "api/transaction/v3/byFinancialAccount/server",
        method: "post",
        body: {
          financialAccountId,
          dateFrom: moment(from).utc().format(),
          dateTo: moment(to).utc().format(),
          filter,
        },
      }),
    }),
    availableToSpendCardController: builder.query<
      any,
      AvailableToSpendCardControllerArg
    >({
      query: ({ paymentCardId }) => ({
        url: "api/transaction/availableToSpend",
        method: "GET",
        params: {
          paymentCardId,
        },
      }),
    }),
    setTransactionMemoEventsController: builder.mutation<
      any,
      SetTransactionMemoEventsControllerArg
    >({
      invalidatesTags: [ApiTags.GetCardTransactionEvents],
      query: (data) => ({
        url: "api/transaction/setTransactionMemo",
        method: "POST",
        body: data,
      }),
    }),
    getReceiptFile: builder.mutation<any, any>({
      // invalidatesTags: [ApiTags.GetCardTransactionEvents],
      query: (data) => ({
        url: "Files/transactionIcon",
        method: "GET",
        params: data,
      }),
    }),
    uploadFile: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetCardTransactionEvents],
      query: (data) => ({
        url: "Files/UploadFile",
        method: "POST",
        body: data,
      }),
    }),
    deleteFile: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetCardTransactionEvents],
      query: (data) => ({
        url: "Files/deleteIcon",
        method: "POST",
        headers: {
          Accept: "text/plain",
        },
        body: data,
      }),
    }),
    transactionById: builder.query<any, { transactionId: string }>({
      providesTags: [ApiTags.GetCardTransactionEvents],
      query: ({ transactionId }) => ({
        url: `/api/transaction/id/${transactionId}`,
        method: "GET",
      }),
    }),
    externalTransactions: builder.query<Transaction[], ExternalTransactionsArgs>({
      query: (params) => ({
        url: 'api/transaction/externalAccountActivity',
        method: 'GET',
        params,
      })
    }),
  }),
});

export const {
  useFinancialAccountTransactionsServerControllerQuery,
  useLazyFinancialAccountTransactionsServerControllerQuery,
  useFinancialAccountTransactionsControllerMutation,
  useAvailableToSpendCardControllerQuery,
  useSetTransactionMemoEventsControllerMutation,
  useDeleteFileMutation,
  useUploadFileMutation,
  useGetReceiptFileMutation,
  useTransactionByIdQuery,
  useExternalTransactionsQuery,
} = transactionApi;
