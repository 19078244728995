import { Button } from 'shared/ui/buttons';
import styles from './style.module.scss';
import { useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterByName } from 'pages/accountComponent/components/filterTransactions/componentns/filterByName';
import { useCardInformation } from 'pages/cardComponent/provider';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useState } from 'react';

export const FilterControl = () => {
  const {
    isFetchingCardTransactions,
    transactionApiFilter,
    setTransactionApiFilter
  } = useCardInformation();

  const isMobile = useMediaQuery('(max-width: 600px)');
  const [, setSearchParams] = useSearchParams();
  const [localSearchQuery, setLocalSearchQuery] = useState<string>(transactionApiFilter.searchString || '') 
 
  const debouncedSetSearchQuery = useDebounce(async (query: string) => {
    setTransactionApiFilter({
      all: transactionApiFilter.all,
      deposit: transactionApiFilter.deposit,
      pos:  transactionApiFilter.pos,
      transfers: transactionApiFilter.transfers,
      withdraw:  transactionApiFilter.withdraw,
      searchString:  query
    });
  }, 700)

  const handleSearchInputChange = (query: string) => {
    setLocalSearchQuery(query);
    debouncedSetSearchQuery(query)
  }
  return (
    <div className={styles.wrapper}>
      <FilterByName
        searchTransactionByName={localSearchQuery}
        setSearchTransactionByName={handleSearchInputChange}
      />
      <Button
        onClick={() => setSearchParams({ mode: 'filters' })}
        variant='outlined'
        className={styles.btnFilterCard}
        disabled={isFetchingCardTransactions}
      >
        {isFetchingCardTransactions ? <FilterListIcon /> : <FilterIcon />}
        {!isMobile && 'filter'}
      </Button>
    </div>
  );
};
