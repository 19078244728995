import { AccountsFromSelect } from "shared/components/accountsSelect";
import styles from "./style.module.scss";
import Upload from "assets/svg/upload.svg";
import IconGarbage from "assets/svg/IconGarbage.svg";
import UploadFileFilled from "assets/svg/UploadFileFilled.svg";
import CheckCircleOutlined from "assets/svg/CheckCircleOutlined.svg";
import FileDownloadOutlined from "assets/svg/FileDownloadOutlined.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useEffect, useState } from "react";
import { Button, SelectChangeEvent, SvgIcon, styled } from "@mui/material";

interface UploadReceiptProps {
  file?: File;
  onUpload: (file: any) => void;
  onDelite: () => void;
}

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const UploadReceipt = ({ file, onUpload, onDelite }: UploadReceiptProps) => {
  const { t } = useTranslationProvider();
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setDownloadUrl(fileUrl);
    }
  }, [file]);

  const handleChangeUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      const fileUrl = URL.createObjectURL(uploadedFile);
      setDownloadUrl(fileUrl);
      onUpload(uploadedFile);
    }
  };
  const handleDeliteFile = () => {
    onUpload(undefined);
    onDelite();
  };
  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Reseipt");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  function formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }
  return (
    <div className={styles.container}>
      {!file && (
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          variant="text"
          color="primary"
        >
          <img src={Upload} />
          Upload receipt
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={handleChangeUpload}
          />
        </Button>
      )}

      {!!file && (
        <>
          <div className={styles.fileContainer}>
            <div className={styles.content}>
              <img src={UploadFileFilled} />
              <div>
                <div className={styles.fileName}>{"Reseipt"}</div>

                <div className={styles.fileSize}>{formatBytes(file?.size)}</div>
              </div>
            </div>

            <div className={styles.content}>
              <Button onClick={handleDeliteFile} variant="text">
                <img src={IconGarbage} />
              </Button>
              <img src={CheckCircleOutlined} />
            </div>
          </div>
          <Button
            onClick={handleDownload}
            disabled={!downloadUrl}
            variant="outlined"
            fullWidth
          >
            <img style={{ marginRight: "10px" }} src={FileDownloadOutlined} />
            Download File
          </Button>
        </>
      )}
    </div>
  );
};

export default UploadReceipt;
