import AppsOutlined from "assets/svg/AppsOutlined.svg";
import styles from "./style.module.scss";
import { Button } from "@mui/material";

interface LincedItemProps {
  name: string;
  createdAt: string;
  applicationId: string;
  unlinkPlaidApplicationsController: (applicationId: string) => void;
}

export const LincedItem = ({
  name,
  createdAt,
  unlinkPlaidApplicationsController,
  applicationId,
}: LincedItemProps) => {
  const handleDisconnect = () => {
    unlinkPlaidApplicationsController(applicationId);
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={AppsOutlined} />
        <div className={styles.textBlock}>
          <div className={styles.text}>{name}</div>
          <div className={styles.date}>{createdAt}</div>
        </div>
      </div>

      <Button onClick={handleDisconnect} variant="outlined">
        disconnect
      </Button>
    </div>
  );
};
