import { useMemo } from "react";
import {
  useRewardsHistoryControllerQuery,
  RewardsHistoryApi,
  RewardsHistory,
} from "api/endpoints/rewards";
import { RewardsHistoryDatePeriod } from "pages/reward/provider/useRewards";
import { RewardsTypesApi } from "api/endpoints/enums";

interface RewardsHistoryReturnType {
  history: RewardsHistoryApi;
  isFetchingHistory: boolean;
  refetchRewardsHistory: () => void;
}

export const useRewardsHistory = (
  rewardsHistoryDatePeriod: RewardsHistoryDatePeriod,
  rewardHistoryType?: RewardsTypesApi
): RewardsHistoryReturnType => {
  const { data, isFetching, refetch } = useRewardsHistoryControllerQuery({
    dateFrom: rewardsHistoryDatePeriod.dateFrom,
    dateTo: rewardsHistoryDatePeriod.dateTo,
  });

  const value = useMemo<any>(() => {
    if (!data) {
      return {};
    }
    if (data) {
      const publishedReports = data?.publishedReports;
      const totalPoints = data?.totalPoints;

      const filteredHistories = () => {
        const tepmpArr = data?.histories;
        if (rewardHistoryType) {
          return tepmpArr.filter((item: RewardsHistory) => {
            if (rewardHistoryType.id === 15) {
              return true;
            } else if (rewardHistoryType.id === 1) {
              return item.type === 1 || item.type === 10;
            } else {
              return item.type === rewardHistoryType.id;
            }
          });
        } else {
          return tepmpArr;
        }
      };
      const histories = filteredHistories();

      return {
        histories,
        publishedReports,
        totalPoints,
      };
    }
  }, [data, rewardsHistoryDatePeriod, rewardHistoryType]);

  return {
    history: value,
    isFetchingHistory: isFetching,
    refetchRewardsHistory: refetch,
  };
};
