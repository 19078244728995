import { Outlet } from "react-router-dom";
import { AnimateWrapper } from "components/animate-wrapper";
import Footer from "shared/components/footer";
import styles from "./style.module.scss";
import { css, styled } from "@mui/material";

const VerifyInProcessLayout = () => {
  return (
    <AnimateWrapper className="fade">
      <Container>
        <Content>
          <Outlet />
        </Content>
        <Footer stylesFooter={{container: styles.stylesWrapperFooter}} />
      </Container>
    </AnimateWrapper>
  );
};

export default VerifyInProcessLayout;

const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #ffffff;
    height: 100vh;
    align-items: center;
  `}
`;
const Content = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 710px;
    justify-content: center;
    padding-top: 100px;
    ${theme.breakpoints.down("sm")} {
      width: 100%;
      padding: 40px 16px;
    }
  `}
`;
