import { useMemo } from "react";
import { useNotificationsControllerQuery } from "../endpoints/settings";

interface NotificationsReturnType {
  notifications: any;
  notificationsIsLoading: boolean;
  notificationsIsSuccess: boolean;
}

export const useNotifications = (): NotificationsReturnType => {
  const {
    data: notifications,
    isFetching: notificationsIsLoading,
    isSuccess: notificationsIsSuccess,
  } = useNotificationsControllerQuery({});

  const value = useMemo<any>(() => {
    if (!notifications) {
      return {};
    }

    if (notifications) {
      return {
        emailEnabled: notifications.emailEnabled,
        pushEnabled: notifications.pushEnabled,
      };
    }
  }, [notifications]);

  return {
    notifications: value,
    notificationsIsLoading,
    notificationsIsSuccess,
  };
};
