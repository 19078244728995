import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import styles from './style.module.scss';

interface ContentHeaderProps {
  count: number;
  disabled: boolean;
  handleReadAllMessages: () => Promise<void>;
}

export const ContentHeader = ({ count, disabled,handleReadAllMessages }: ContentHeaderProps) => {
  return (
    <div className={styles.container}>
      <Typography>Unread ({count})</Typography>
      <Button
        variant='text'
        onClick={handleReadAllMessages}
        disabled={disabled}
      >
        Mark all as read
      </Button>
    </div>
  );
};
