import { css, styled, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const VerificationFailed = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Box>
        <Title>Verification failed</Title>
        <SubTitle>
          We regret to inform you that your application did not successfully
          pass our verification process. If you have any questions, feel free to
          contact our customer support for further assistance. Thank you for
          your understanding.
        </SubTitle>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          Log in with another account
        </Button>
      </Box>
    </Container>
  );
};

export default VerificationFailed;

const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.down("sm")} {
      padding: 24px 16px 64px 16px;
    }
  `}
`;

const Box = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 16px;

    & > button {
      background-color: ${theme.colors.darkBlue};
      margin-top: 8px;
    }
    ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;
const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 34px;
    font-weight: 400;
    line-height: 123.5%;
    letter-spacing: 0.25px;
  `}
`;
const SubTitle = styled(Typography)`
  ${({ theme }) => css`
    color: rgba(0, 0, 0, 0.6);
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;
