import { TransitionAnimation } from 'shared/ui/animations';
import { useFormik } from 'formik';
import { Button as ButtonMUI } from '@mui/material';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import { Skeleton } from 'components/skeleton';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { selectAutomations, setAutomations } from '../../model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import styles from './styles.module.scss';
import {
  AutomationsForm,
  AutomationsSchema,
  createOptionsAutomationsAcc,
} from 'entities/transfers';
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer';

export const ManageAutomationsAdd = () => {
  const { goNext } = useDrawerStepperContext();
  const dispatch = useAppDispatch();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();
  const { data: scheduledTransfers, isLoading: isLoadingScheduledTransfers } =
    useGetScheduledTransferQuery();

  const automations = useAppSelector(selectAutomations);

  const formik = useFormik({
    initialValues: {
      from: automations.from,
      to: automations.to,
      amount: automations.amount,
      memo: automations.memo,
      minimumTopUpAmount: automations.minimumTopUpAmount,
      minAmount: automations.minAmount || '',
    },
    validateOnMount: true,
    validationSchema: AutomationsSchema,
    onSubmit: (values) => {
      dispatch(setAutomations({ value: values }));
      goNext('12');
    },
  });

  const optionsToAcc = createOptionsAutomationsAcc(
    unclosedFinancialAccounts,
    scheduledTransfers || []
  );

  return financialAccountsIsLoading ||
    isLoadingPayees ||
    isLoadingScheduledTransfers ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <AutomationsForm
        formik={formik}
        payees={plaidPayees}
        optionsToAcc={optionsToAcc}
        warningAmount={'Minimum balance for replenishment'}
        subTitle={
          'Funds will be transferred from your connected account when your balance falls below a set amount.'
        }
        slotBtn={
          <ButtonMUI
            variant='contained'
            className={styles.btn}
            type='submit'
            disabled={!formik.isValid}
          >
            Confirm
          </ButtonMUI>
        }
      />
    </TransitionAnimation>
  );
};
