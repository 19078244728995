import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { requireNotNull } from '../../../../shared/utils/require-not-null';

interface ContextProps {
  mode: string; // 'Cashflow' | 'Balance history';
  changeMode(newMode: string): void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const DiagramsProvider = ({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState('Cashflow');

  const value = useMemo((): ContextProps => {
    return {
      mode,
      changeMode(newMode: string) {
        setMode(newMode);
      }
    }
  }, [mode])

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export const useDiagrams = () => requireNotNull(useContext(Context));
