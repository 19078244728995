import { Button } from 'shared/ui/buttons';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAppSelector } from 'shared/models';
import { DetailsTransfer } from 'entities/transfers';
import moment from 'moment';
import styles from './styles.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useDeleteScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useDialog } from 'providers/dialogController.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { selectScheduleTransfer } from '../../model';

export const ManageScheduleTransferDetail = () => {
  const { goNext } = useDrawerStepperContext();
  const { setSnackBar } = useSnackBar();
  const dialog = useDialog();

  const [
    deleteScheduledTransfer,
    { isLoading: isLoadingDeleteScheduledTransfer },
  ] = useDeleteScheduledTransferMutation();

  const scheduleTransfer = useAppSelector(selectScheduleTransfer);

  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Cancel transfer
    </Button>
  );
  const renderSlotCancelButton = <Button variant='text'>Close</Button>;

  const handeleDeletePayee = async () => {
    const scheduleTransferName = `${scheduleTransfer?.toAccount.name} (${scheduleTransfer?.toAccount.last4Number})`;

    dialog.show({
      dialogTitle: 'Cancel upcoming transfer',
      dialogText: `Are you sure you want to cancel upcoming transfer to ${scheduleTransferName} ?`,
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: renderSlotCancelButton,
      async onApply() {
        try {
          if (scheduleTransfer) {
            await deleteScheduledTransfer({
              id: scheduleTransfer.id,
            }).unwrap();

            setSnackBar({
              type: 'success',
              message: `Upcoming transfer to ${scheduleTransferName} cancelled successfully.`,
              isShow: true,
            });
            goNext('0');
          }
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  const handleToEditTransfer = () => {
    goNext('5');
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {scheduleTransfer && (
          <DetailsTransfer
            amount={`$${helper.moneyFormat(scheduleTransfer?.amount)}`}
            accountFrom={scheduleTransfer?.fromAccount}
            accountTo={scheduleTransfer?.toAccount}
            date={moment(scheduleTransfer?.dateTransfer).format('MM/DD/YYYY')}
            frequency={scheduleTransfer?.frequency}
            memo={scheduleTransfer?.memo}
          />
        )}
        <div className={styles.boxBtn}>
          <Button
            variant='outlined'
            onClick={handleToEditTransfer}
          >
            edit transfer
          </Button>
          <Button
            variant='contained'
            onClick={handeleDeletePayee}
            loading={isLoadingDeleteScheduledTransfer}
          >
            cancel transfer
          </Button>
        </div>
      </div>
    </TransitionAnimation>
  );
};
