import * as yup from 'yup';

export const AutomationsSchema = yup.object().shape({
  from: yup.string().required(),
  to: yup.string().required(),
  memo: yup.string().max(1024, 'Memo must be at most 1024 characters'),
  amount: yup
    .string()
    .required('Balance is a required field')
    .test({
      name: 'is-non-zero',
      message: 'Float value must not be zero',
      test: (value) => Number(value) !== 0,
    }),
  minimumTopUpAmount: yup.boolean(),
  minAmount: yup.string().when('minimumTopUpAmount', {
    is: true,
    then: (schema) =>
      schema
        .required('Amount is a required field')
        .test(
          'amount-check',
          'Float value must not be zero',
          (value) => Number(value) !== 0
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
});
