import { Typography as TypographyMUI } from '@mui/material';
import type { TypographyProps as TypographyPropsMUI } from '@mui/material';
import cn from 'classnames';
import styles from './styles.module.scss';

interface TypographyProps extends TypographyPropsMUI {}

export const Typography = (props: TypographyProps) => {
    const { children, className, ...rest } = props;

    const classes: TypographyPropsMUI['classes'] = {
        root: styles.root,
        h1: styles.h1,
        h2: styles.h2,
        h3: styles.h3,
        h4: styles.h4,
        h5: styles.h5,
        h6: styles.h6,
        subtitle1: styles.subtitle1,
        subtitle2: styles.subtitle2,
        body1: styles.body1,
        body2: styles.body2,
        caption: styles.caption,
        overline: styles.overline,
    };

    return (
        <TypographyMUI classes={classes} className={cn(className)} {...rest}>
            {children}
        </TypographyMUI>
    );
};
