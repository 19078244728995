import { FormControl, FormHelperText, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import InputMask from 'react-input-mask';

interface Props {
  formikInstance: any;
}

export const SignUpPersonForm = ({ formikInstance: formik }: Props) => {
  return (
    <>
      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='First name'
        error={Boolean(formik.touched.givenName && formik.errors.givenName)}
        helperText={formik.touched.givenName && formik.errors.givenName}
        {...formik.getFieldProps('givenName')}
      />

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='Last name'
        error={Boolean(formik.touched.familyName && formik.errors.familyName)}
        helperText={formik.touched.familyName && formik.errors.familyName}
        {...formik.getFieldProps('familyName')}
      />

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='Middle name'
        error={Boolean(formik.touched.middleName && formik.errors.middleName)}
        helperText={formik.touched.middleName && formik.errors.middleName}
        {...formik.getFieldProps('middleName')}
      />

      <TextField
        fullWidth
        type='email'
        variant='outlined'
        label='Email'
        error={Boolean(formik.touched.email && formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        {...formik.getFieldProps('email')}
      />

      <InputMask
        mask='(999) 999-9999'
        maskChar=' '
        {...formik.getFieldProps('phone')}
        onChange={(e: any) => {
          formik.setFieldValue('phone', e.target.value);
        }}
        onBlur={() => {
          formik.setFieldTouched('phone', true);
        }}
      >
        {() => (
          <TextField
            fullWidth
            type='tel'
            variant='outlined'
            label='Phone'
            error={Boolean(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        )}
      </InputMask>

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='SSN'
        error={Boolean(formik.touched.ssn && formik.errors.ssn)}
        helperText={formik.touched.ssn && formik.errors.ssn}
        {...formik.getFieldProps('ssn')}
        onChange={(e) => {
          const value = e.target.value;
          if (value.length > 9) {
            return;
          }
          formik.setFieldValue('ssn', e.target.value.replace(/\D/g, ''));
        }}
      />

      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            fullWidth
            disableFuture
            label='Date of Birth'
            name='date'
            value={formik.values.dateOfBirth}
            onChange={(newDate) => {
              formik.setFieldValue('dateOfBirth', newDate);
              if (dayjs.isDayjs(newDate) && newDate.isValid()) {
                setTimeout(() => {
                  formik.validateForm();
                });
              }
            }}
            slotProps={{
              textField: {
                error: Boolean(
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                ),
              },
            }}
          />
          {Boolean(formik.touched.dateOfBirth && formik.errors.dateOfBirth) && (
            <FormHelperText error>{formik.errors.dateOfBirth}</FormHelperText>
          )}
        </LocalizationProvider>
      </FormControl>
    </>
  );
};
