import styles from '../style.module.scss';
import { DiagramTopPanel } from '../shared/diagramTopPanel';
import cx from 'classnames';
import { LinearProgress } from '@mui/material';
import { LineChart, areaElementClasses } from '@mui/x-charts';
import { colors } from '../../../../../theme';
import { centsToDollars } from '../../../../../shared/utils/price';
import * as React from 'react';
import { BalanceDiagramProvider, useBalanceDiagram } from './provider';
import { categories, divideSegment, formatYLabel } from '../shared/common';
import { useDiagrams } from '../provider';
import moment from 'moment';
import { useMemo } from 'react';
import { SvgIcon } from '../../../../../shared/components/svgIcon';
import { useYScale, useDrawingArea } from '@mui/x-charts/hooks';
import { useScreenSize } from 'shared/hooks/useScreensSize';

function getMonthsOptions() {
  const currentDate = moment();
  return new Array(6).fill(null).map((_, index) => {
    return {
      value: currentDate.clone().startOf('month').subtract(index, 'months').format('YYYY-MM-DD'),
      label: currentDate.clone().subtract(index, 'months').format('MMM').toUpperCase()
    };
  }).reverse();
}

type AreaBgProps = {
  threshold: number;
  color1: string;
  id: string;
};

function AreaBg({ threshold, color1, id }: AreaBgProps) {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  const scale = useYScale() as any; // You can provide the axis Id if you have multiple ones
  const y0 = scale(threshold); // The coordinate of of the origine
  const off = y0 !== undefined ? y0 / svgHeight : 0;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
      >
        <stop offset={off} stopColor={color1} stopOpacity={1}/>
      </linearGradient>
    </defs>
  );
}

const BalanceHistoryComponent = () => {
  const balanceDiagram = useBalanceDiagram();
  const diagrams = useDiagrams();
  const { isMobile }= useScreenSize()

  const periodRangeString = useMemo(() => {
    const date = moment(balanceDiagram.selectedPeriod);
    const startOfMonth = date.clone().startOf('month').format('MMM D');
    const endOfMonth = date.clone().endOf('month').format('MMM D');
    return `${startOfMonth} - ${endOfMonth}`;

  }, [balanceDiagram.selectedPeriod]);

  const yAxisConfig: any = useMemo(() => {
    if (!balanceDiagram?.balanceHistoryData) {
      return undefined;
    }

    const minValue = balanceDiagram.minValue < 0 ? balanceDiagram.minValue : 0;
    const maxValue = balanceDiagram.maxValue * 1.1;
    return [{
      tickInterval: divideSegment(minValue, maxValue),
      max: maxValue,
      min: minValue,
      valueFormatter: (value: number, context) => {
        if (context.location === 'tick' && typeof value === 'number') {
          if (100 >= value) {
            return formatYLabel(centsToDollars(value),'$');
          }
          return formatYLabel(Math.round(centsToDollars(value)),'$');
        }
        return `$${value}`;
      }
    }];
  }, [balanceDiagram.balanceHistoryData, balanceDiagram.maxValue]);

  const xAxisConfig = useMemo(() => {
    const monthDays = balanceDiagram.balanceHistoryData?.map(i => Number(moment.utc(i.date).format('D')))
      || [];

    return [{
      data: monthDays,
      min: Math.min(...monthDays),
      max: Math.max(...monthDays),
      tickInterval: divideSegment(Math.min(...monthDays), Math.max(...monthDays)),
      valueFormatter: (value) => {
        if (balanceDiagram.isDataLoading) {
          return '';
        }

        return `${value} ${moment(balanceDiagram.selectedPeriod).format('MMM')}`;
      }
    }];
  }, [balanceDiagram.balanceHistoryData, balanceDiagram.selectedPeriod, balanceDiagram.isDataLoading]);

  const balanceValue = useMemo(() => {
    if (!balanceDiagram.balanceHistoryData) {
      return undefined;
    }

    const data = balanceDiagram.balanceHistoryData;
    const startBalance = data[0]?.balance;
    const endBalance = data[data.length - 1]?.balance;

    if (startBalance && endBalance) {
      return endBalance - startBalance;
    }
  }, [balanceDiagram.balanceHistoryData]);

  return (
    <div className={styles.wrapper}>
      <DiagramTopPanel
        periods={getMonthsOptions()}
        categories={categories}
        selectedCategory="Balance history"
        selectedPeriod={balanceDiagram.selectedPeriod}
        isDisabled={balanceDiagram.isDataLoading}
        onChangePeriod={(newPeriod) => balanceDiagram.setPeriod(newPeriod)}
        onChangeCategory={(newCategory) => diagrams.changeMode(newCategory)}
      />
      <div className={styles.chartWrapper}>
        <div className={cx(styles.loader, balanceDiagram.isDataLoading && styles.loaderActive)}>
          <LinearProgress/>
        </div>
        <div className={styles.periodInfoWrapper}>
          <div className={cx(styles.periodLabel, styles.historyLabel)}>
            Balance history for {periodRangeString}
            {!balanceDiagram.isDataLoading && balanceValue !== undefined && balanceValue !== 0 && (
              <div
                className={
                cx(styles.balanceBadge, {
                  [styles.balanceBadgeMinus]: balanceValue < 0,
                  [styles.balanceBadgePlus]: balanceValue > 0
                })}
              >
                {balanceValue > 0 ? '+' : '-'} $ {centsToDollars(Math.abs(balanceValue))}</div>
            )}
          </div>
        </div>
        {balanceDiagram.balanceHistoryData?.length && balanceDiagram.maxValue ? (
          <>
            <LineChart
              sx={{
                '.MuiChartsAxis-line': {
                  display: 'none',
                },
                '.MuiChartsAxis-tick': {
                  'display': 'none'
                },
                [`& .${areaElementClasses.root}`]: {
                  fill: 'url(#swich-color-id-1)',
                },
                '.MuiMarkElement-root': {
                  fill: colors.primaryBlue,
                  'stroke-width': 0,
                }
              }}
              xAxis={xAxisConfig}
              yAxis={yAxisConfig}
              series={[
                {
                  data: balanceDiagram.balanceHistoryData.map(i => i.balance),
                  color: colors.primaryBlue,
                  label: 'Balance',
                  showMark: true,
                  // area: true,
                  valueFormatter: (v) => `$${centsToDollars(v ?? 0)}`
                },
              ]}

              height={isMobile ? 191 : 157}
              skipAnimation={true}
              slotProps={{ legend: { hidden: true } }}
              grid={{ horizontal: true }}
              margin={{ top: 10, bottom: 25, left: 40, right: 20 }}
            >
              {/*<AreaBg*/}
              {/*  color1="rgba(101,190,115,0.4)"*/}
              {/*  threshold={0}*/}
              {/*  id="swich-color-id-1"*/}
              {/*/>*/}
            </LineChart>
            <div className={styles.hint}>
              <SvgIcon name="InfoOutlined" sizePx={19}/>
              <div>
                Data doesn’t&nbsp;include last&nbsp;2&nbsp;days.
                Authorizations and&nbsp;partially cleared transactions are&nbsp;not&nbsp;included
              </div>
            </div>
          </>
        ) : (
          <div style={{ height: '40px' }}/>
        )}
        {balanceDiagram.maxValue === 0 && !balanceDiagram.isDataLoading && (
          <div className={styles.emptyFallback}>no data to display</div>
        )}
      </div>
    </div>
  );
};

export const BalanceHistory = () => (
  <BalanceDiagramProvider>
    <BalanceHistoryComponent/>
  </BalanceDiagramProvider>
);
