import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useConfirmDialog } from "providers/confirm.provider";
import {
  useDetachAllRulesControllerMutation,
  useDetachSpendRulesControllerMutation,
  useDetachVelocityControllerMutation,
  useAddRuleCountriesControllerMutation,
  useAddRuleMerchantCategoriesControllerMutation,
  useAddRuleAmountLimitControllerMutation,
  useAddAmountLimitMonthlyControllerMutation,
} from "api/endpoints/spendRules";
import { useAvailableToSpendCardControllerQuery } from "api/endpoints/transaction";
import { usePaymentCards } from "api/hooksApi/usePaymentCards";
import { useCardLimits } from "api/hooksApi/useCardLimits";
import SimpleContainer from "shared/components/containers/simpleContainer";
import LimitsList from "./components/limitsList";
import LimitComponent from "./components/limitsComponent";
import { useTranslationProvider } from "providers/translation/translation.provider";
import SnackBarWarning from "shared/components/snackBar/snackBarWarning";

type LimitsPageMode = "limits" | "merchant-category" | "country";

const Limits = () => {
  const { t } = useTranslationProvider();
  const params = useParams();
  const confirm = useConfirmDialog();
  const { paymentCards } = usePaymentCards();
  const card = paymentCards.find((c: any) => c.id === params?.cardId)!;
  const { cardLimits, cardLimitsIsLoading, cardLimitsRefetch } = useCardLimits(
    params?.cardId
  );
  const [detachAllRulesMutation, { isLoading: detachAllRulesIsLoading }] =
    useDetachAllRulesControllerMutation();
  const [detachSpendRulesMutation, { isLoading: detachSpendRulesIsLoading }] =
    useDetachSpendRulesControllerMutation();
  const [detachVelocityMutation, { isLoading: detachVelocityIsLoading }] =
    useDetachVelocityControllerMutation();
  const [addRuleCountriesMutation, { isLoading: addRuleCountriesIsLoading }] =
    useAddRuleCountriesControllerMutation();
  const [addRuleCategoriesMutation, { isLoading: addRuleCategoriesIsLoading }] =
    useAddRuleMerchantCategoriesControllerMutation();
  const [
    addRuleAmountLimitMutation,
    { isLoading: addRuleAmountLimitIsLoading },
  ] = useAddRuleAmountLimitControllerMutation();
  const [
    addRuleAmountLimitMonthlyMutation,
    { isLoading: addRuleAmountLimitMonthlyIsLoading },
  ] = useAddAmountLimitMonthlyControllerMutation();
  const { refetch: availableToSpendCardRefetch } =
    useAvailableToSpendCardControllerQuery({
      paymentCardId: card?.id,
    });
  const [countries, setCountries] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [amountLimit, setAmountLimit] = useState<any>();
  const [amountLimitMonthly, setAmountLimitMonthly] = useState<any>();
  const [enabledLimitTransaction, setEnabledLimitTransaction] =
    useState<boolean>(false);
  const [enabledLimitMonth, setEnabledLimitMonth] = useState<boolean>(false);
  const [limitsPageMode, setLimitsPageMode] =
    useState<LimitsPageMode>("limits");

  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isLoading = [
    cardLimitsIsLoading,
    detachAllRulesIsLoading,
    detachSpendRulesIsLoading,
    detachVelocityIsLoading,
    addRuleCountriesIsLoading,
    addRuleCategoriesIsLoading,
    addRuleAmountLimitIsLoading,
    addRuleAmountLimitMonthlyIsLoading,
  ].some(Boolean);

  useEffect(() => {
    if (cardLimits) {
      updateCardLimitsState(cardLimits);
    }
  }, [cardLimits]);

  const updateCardLimitsState = (cardLimits) => {
    setCountries(cardLimits?.countriesLimit);
    setCategories(cardLimits?.categoriesLimit);
    setAmountLimit(cardLimits?.amountLimit);
    setAmountLimitMonthly(cardLimits?.amountLimitMonthlyData);
    setEnabledLimitTransaction(!!cardLimits?.amountLimit);
    setEnabledLimitMonth(!!cardLimits?.amountLimitMonthlyData);
  };

  const detachAllLimits = async () => {
    confirm.show({
      applyButtonText: t("common.Yes", {
        defaultValue: "Yes",
      }),
      dialogText: t("Card.Are you sure you want to remove all limits", {
        defaultValue: "Are you sure you want to remove all limits?",
      }),
      cancelButtonType: "text",
      cancelError: true,
      async onApply() {
        try {
          await detachAllRulesMutation({ PaymentCardId: card?.id }).unwrap();
          setIsShowSuccess(true);
          setEnabledLimitTransaction(false);
          setEnabledLimitMonth(false);
        } catch (e: any) {
          console.log("error", e);
        }
      },
    });
  };
  const searchWithRule = (arr, type) => {
    const temptArr = arr?.filter((i) => i.rule === type)?.map((i) => i.name);
    return temptArr;
  };

  const compressionArr = (arr, originArr) => {
    const isEqual = arr.toString() === originArr.toString();
    return isEqual;
  };
  const addOrDetachCountries = async () => {
    const blockedMerchantCountries = searchWithRule(countries, "blocked");
    const allowedMerchantCountries = searchWithRule(countries, "allowed");
    const blockedOriginCountries = searchWithRule(
      cardLimits?.countriesLimit,
      "blocked"
    );
    const allowedOriginCountries = searchWithRule(
      cardLimits?.countriesLimit,
      "allowed"
    );

    if (
      compressionArr(blockedMerchantCountries, blockedOriginCountries) &&
      compressionArr(allowedMerchantCountries, allowedOriginCountries)
    ) {
      return;
    }

    if (!blockedMerchantCountries.length && !allowedMerchantCountries.length) {
      const countriesSpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.__typename === "MerchantCountrySpendRule"
      );
      await detachSpendRulesMutation({
        paymentCardId: card?.id,
        spendRuleId: countriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCountriesMutation({
        paymentCardId: card?.id,
        blocked: blockedMerchantCountries,
        allowed: allowedMerchantCountries,
      }).unwrap();
    }
  };

  const addOrDetachCategories = async () => {
    const blockedMerchantCategories = searchWithRule(categories, "blocked");
    const allowedMerchantCategories = searchWithRule(categories, "allowed");
    const blockedOriginCategories = searchWithRule(
      cardLimits?.categoriesLimit,
      "blocked"
    );
    const allowedOriginCategories = searchWithRule(
      cardLimits?.categoriesLimit,
      "allowed"
    );
    if (
      compressionArr(blockedMerchantCategories, blockedOriginCategories) &&
      compressionArr(allowedMerchantCategories, allowedOriginCategories)
    ) {
      return;
    } else if (
      !blockedMerchantCategories.length &&
      !allowedMerchantCategories.length
    ) {
      const categoriesSpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.__typename === "MerchantCategorySpendRule"
      );
      await detachSpendRulesMutation({
        paymentCardId: card?.id,
        spendRuleId: categoriesSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleCategoriesMutation({
        paymentCardId: card?.id,
        blocked: blockedMerchantCategories,
        allowed: allowedMerchantCategories,
      }).unwrap();
    }
  };
  const addOrDetachAmountLimit = async () => {
    if (amountLimit === cardLimits?.amountLimit) {
      return;
    } else if (!Number(amountLimit)) {
      await detachSpendRulesMutation({
        paymentCardId: card?.id,
        spendRuleId: cardLimits?.amountLimitSpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMutation({
        paymentCardId: card?.id,
        value: Number(amountLimit) * 100,
      }).unwrap();
    }
  };

  const addOrDetachAmountLimitMonthly = async () => {
    if (amountLimitMonthly === cardLimits?.amountLimitMonthlyData) {
      return;
    } else if (!Number(amountLimitMonthly)) {
      await detachVelocityMutation({
        paymentCardId: card?.id,
        spendRuleId: cardLimits?.amountLimitSpendRuleMonthly?.node?.id,
      }).unwrap();

      const amountLimitMonthlySpendRule = cardLimits?.attachedSpendRules?.find(
        (i) => i.node.name === "Amount Limit [MONTHLY]"
      );
      await detachSpendRulesMutation({
        paymentCardId: card?.id,
        spendRuleId: amountLimitMonthlySpendRule?.node?.id,
      }).unwrap();
    } else {
      await addRuleAmountLimitMonthlyMutation({
        paymentCardId: card?.id,
        value: Number(amountLimitMonthly) * 100,
      }).unwrap();
    }
  };
  const onSave = async () => {
    try {
      const promisesOperations = [
        addOrDetachCountries(),
        addOrDetachCategories(),
        addOrDetachAmountLimit(),
        addOrDetachAmountLimitMonthly(),
      ];
      await Promise.all(promisesOperations);
      setIsShowSuccess(true);
      availableToSpendCardRefetch();
      cardLimitsRefetch();
    } catch (e: any) {
      setErrorMessage(e?.data[""] && e.data?.length && e?.data[""][0] || "");
      // setIsShowWarning(true);
    }
  };
  const handleNavigateToMerchantCategories = () => {
    setLimitsPageMode("merchant-category");
  };

  const handleNavigateToCountries = () => {
    setLimitsPageMode("country");
  };
  if (limitsPageMode === "limits") {
    return (
      <>
        <LimitComponent
          handleNavigateToMerchantCategories={
            handleNavigateToMerchantCategories
          }
          handleNavigateToCountries={handleNavigateToCountries}
          setEnabledLimitTransaction={setEnabledLimitTransaction}
          enabledLimitTransaction={enabledLimitTransaction}
          setAmountLimit={setAmountLimit}
          amountLimit={amountLimit}
          setEnabledLimitMonth={setEnabledLimitMonth}
          enabledLimitMonth={enabledLimitMonth}
          amountLimitMonthly={amountLimitMonthly}
          setAmountLimitMonthly={setAmountLimitMonthly}
          onSave={onSave}
          detachAllLimits={detachAllLimits}
          isLoading={isLoading}
          setIsShowSuccess={setIsShowSuccess}
          isShowSuccess={isShowSuccess}
          isShowWarning={isShowWarning}
          setIsShowWarning={setIsShowWarning}
        />
        <SnackBarWarning
          isOpen={!!errorMessage}
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      </>
    );
  }
  if (limitsPageMode === "merchant-category") {
    return (
      <SimpleContainer
        title={t("Limits.Filter by Merchant category", {
          defaultValue: "Filter by Merchant category",
        })}
        onTitleClick={() => setLimitsPageMode("limits")}
      >
        <LimitsList
          setLimitsPageMode={setLimitsPageMode}
          arr={categories}
          setArr={setCategories}
        />
      </SimpleContainer>
    );
  }
  if (limitsPageMode === "country") {
    return (
      <SimpleContainer
        title={t("Limits.Filter by Country", {
          defaultValue: "Filter by Country",
        })}
        onTitleClick={() => setLimitsPageMode("limits")}
      >
        <LimitsList
          setLimitsPageMode={setLimitsPageMode}
          arr={countries}
          setArr={setCountries}
        />
      </SimpleContainer>
    );
  }
  return <></>;
};

export default Limits;
