import { BusinessSignUpScreen } from '../provider';
import styles from './style.module.scss';
import cx from 'classnames';

const screenOrders: BusinessSignUpScreen[] = [
  BusinessSignUpScreen.businessInfo,
  BusinessSignUpScreen.businessAddress,
  BusinessSignUpScreen.primaryAuthorizedPerson,
  BusinessSignUpScreen.primaryOwner,
  BusinessSignUpScreen.confirm,
];

interface Props {
  curScreen: BusinessSignUpScreen;
}

export const SignUpBusinessProgressBar = ({ curScreen }: Props) => {
  return (
    <div className={styles.wrapper}>
      {screenOrders.map((screen, index) => (
        <div
          key={screen}
          className={cx(styles.item, {
            [styles.itemChecked]:
              curScreen === screen || screenOrders.indexOf(curScreen) > index,
          })}
        />
      ))}
    </div>
  );
};
