import styles from "./style.module.scss";
import { useState } from "react";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import SelectTopic from "./components/selectTopic/SelectTopic";
import { useCreateTicketControllerMutation } from "../../../api/endpoints/support";
import { useUploadTicketAttachmentControllerMutation } from "../../../api/endpoints/tickets";
import { convertFileToBase64 } from "../../../shared/utils/convertFileToBase64";
import helper from "services/helper";
import FormComment from "./components/formComment";
import Dropzone from "./components/dropzone";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSupportHistory } from "api/hooksApi/useSupportHistory";
import { useSearchParams,useLocation } from "react-router-dom";
import { useHolderV2ControllerQuery } from "api/endpoints/account";
import { useSnackBar } from "providers/snackBar.provider";
import { SupportModeEnum } from "../types/enums";
import { Button } from "shared/ui/buttons";
import { TopicApi } from "../../../api/endpoints/support";

const CreateNewRequest = () => {
  const location = useLocation();
  const { setSnackBar } = useSnackBar();
  const { historyRefetch } = useSupportHistory();
  const { t } = useTranslationProvider();
  const { data: holder } = useHolderV2ControllerQuery();
  const [, setSearchParams] = useSearchParams();


  const topicWithLocation = location?.state?.topic
  const textWithLocation = location?.state?.requestText


  const [createTickerMutation, { isLoading }] =
    useCreateTicketControllerMutation();
  const [uploadTicketFiles] = useUploadTicketAttachmentControllerMutation();

  const [topic, setTopic] = useState<TopicApi | undefined>(topicWithLocation ?? undefined);
  const [question, setQuestion] = useState<string>(textWithLocation ?? "");
  const [files, setFiles] = useState<File[]>([]);
  const disabled = !topic || !question;

  const handleSubmitQuestion = async () => {
    try {
      if (topic && question) {
        const { ticketId } = await createTickerMutation({
          topicId: topic.id,
          text: question,
        }).unwrap();

        if (files?.length) {
          const fileList = Array.from(files);
          await Promise.all([
            fileList.map(async (file, i) => {
              const fileBase64 = (await convertFileToBase64(file)) as string;
              const formattedFileBase64 = fileBase64?.substring(
                fileBase64.indexOf(",") + 1
              );

              await uploadTicketFiles({
                ticketId: Number(ticketId),
                fileName: file.name,
                contentType: file.type,
                fileBase64: formattedFileBase64,
              }).unwrap();
            }),
          ]);
        }
        setSnackBar({
          type: 'success',
          message:  t("Support.Your request has been created", {
            defaultValue:
              "Your request has been created. Expect an answer 1-2 days",
          }),
          isShow: true,
        })
        historyRefetch();
      
        setTopic(undefined);
        setQuestion("");
        setFiles([]);
        setSearchParams({
          pageMode: SupportModeEnum.History,
          title: "Requests history",
        });
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div className={styles.boxEmail}>
          <p className={styles.boxEmail_titleText}>
            {t("common.Email address", {
              defaultValue: "Email address",
            })}
          </p>
          <p className={styles.boxEmail_text}>
            {holder?.businessAccountHolder?.email}
          </p>
        </div>
        <div className={styles.boxForm}>
          <SelectTopic topic={topic} setTopic={setTopic} />
          <FormComment question={question} setQuestion={setQuestion} />
          <Dropzone
            files={files}
            setFiles={setFiles}
            maxFileSize={10 * 1024 * 1024}
            acceptedFiles={["image/*", "application/pdf"]}
          />
        </div>
        <Button
          onClick={handleSubmitQuestion}
          loading={isLoading}
          variant="contained"
          className={styles.button}
          disabled={disabled}
        >
          {t("Settings.Send Request", {
            defaultValue: "Send Request",
          })}
        </Button>
      </div>
    </AnimateWrapper>
  );
};

export default CreateNewRequest;
