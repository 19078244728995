import React from 'react';
import { ReactComponent as LogoutOutlined } from './icons/LogoutOutlined.svg';
import { ReactComponent as ArrowDropDownFilter } from './icons/ArrowDropDownFilter.svg';
import { ReactComponent as InfoOutlined } from './icons/InfoOutlined.svg';
import { ReactComponent as SuccessMark } from './icons/SuccessMark.svg';
import { ReactComponent as ArrowUpwardFilled } from './icons/ArrowUpwardFilled.svg';
import { ReactComponent as MonetizationonOutlined } from './icons/MonetizationonOutlined.svg';
import { ReactComponent as StarBorderFilled } from './icons/StarBorderFilled.svg';
import { ReactComponent as CardGiftcardOutlined } from './icons/CardGiftcardOutlined.svg';
import { ReactComponent as MoneyOutlined } from './icons/MoneyOutlined.svg';
import { ReactComponent as FileCopyOutlined } from './icons/FileCopyOutlined.svg';
import { ReactComponent as AccountBalanceWalletOutlined } from './icons/AccountBalanceWalletOutlined.svg';
import { ReactComponent as Home } from './icons/Home.svg';
import { ReactComponent as Reward } from './icons/Reward.svg';
import { ReactComponent as Settings } from './icons/Settings.svg';
import { ReactComponent as Transfer } from './icons/Transfer.svg';
import { ReactComponent as ConnectedApps } from './icons/ConnectedApps.svg';
import { ReactComponent as Locator } from './icons/Locator.svg';
import { ReactComponent as Person } from './icons/Person.svg';
import { ReactComponent as Security } from './icons/Security.svg';
import { ReactComponent as Support } from './icons/Support.svg';
import { ReactComponent as Logout } from './icons/Logout.svg';
import { ReactComponent as MenuBurger } from './icons/MenuBurger.svg';
import { ReactComponent as Close } from './icons/Close.svg';
import { ReactComponent as ChevronRightFilled } from './icons/ChevronRightFilled.svg';
import { ReactComponent as ContactSupportOutlined } from './icons/ContactSupportOutlined.svg';
import { ReactComponent as PercentOutlined } from './icons/PercentOutlined.svg';

export type SvgIconType =
  | 'LogoutOutlined'
  | 'ArrowDropDownFilter'
  | 'InfoOutlined'
  | 'SuccessMark'
  | 'ArrowUpwardFilled'
  | 'MonetizationonOutlined'
  | 'StarBorderFilled'
  | 'CardGiftcardOutlined'
  | 'MoneyOutlined'
  | 'FileCopyOutlined'
  | 'AccountBalanceWalletOutlined'
  | 'Home'
  | 'Reward'
  | 'Settings'
  | 'Transfer'
  | 'ConnectedApps'
  | 'Locator'
  | 'Person'
  | 'Security'
  | 'Support'
  | 'Logout'
  | 'MenuBurger'
  | 'Close'
  | 'ChevronRightFilled'
  | 'ContactSupportOutlined'
  | 'PercentOutlined';

const IconsMap: { [iconName in SvgIconType]: React.FC } = {
  LogoutOutlined,
  ArrowDropDownFilter,
  InfoOutlined,
  SuccessMark,
  ArrowUpwardFilled,
  MonetizationonOutlined,
  StarBorderFilled,
  CardGiftcardOutlined,
  MoneyOutlined,
  FileCopyOutlined,
  AccountBalanceWalletOutlined,
  Home,
  Reward,
  Settings,
  Transfer,
  ConnectedApps,
  Locator,
  Person,
  Security,
  Support,
  Logout,
  MenuBurger,
  Close,
  ChevronRightFilled,
  ContactSupportOutlined,
  PercentOutlined,
};

interface SvgIconProps {
  name: SvgIconType;
  sizePx?: number;
}

const DEFAULT_SIZE_PX = 24;

const createStyles = (size: number) => {
  const result: React.CSSProperties = {};
  result.width = `${size}px`;
  result.height = `${size}px`;
  result.minWidth = `${size}px`;
  result.minHeight = `${size}px`;
  return result;
};

export const SvgIcon = ({ sizePx = DEFAULT_SIZE_PX, name }: SvgIconProps) => {
  const IconComponent = IconsMap[name] as any;
  return <IconComponent style={createStyles(sizePx)}/>;
};
