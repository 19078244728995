import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";

interface SpendingReportArg {
  finAccountId: string;
  from?: string;
  to?: string;
}

export interface Category {
  category: string;
  amount: number;
  percentage: number;
}

export interface SpendingReportApi {
  categories: Category[];
  total: number;
  color?: string;
}
interface DownloadSpendingReportApi{
    name: string,
    content: string,
    contentType: string
}

export const userReportApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "userReport",
  endpoints: (builder) => ({
    spendingReport: builder.query<SpendingReportApi, SpendingReportArg>({
      query: (params) => ({
        url: "api/userReport/spendingReportByAccountGroupByCategory/v2",
        method: "GET",
        params,
      }),
    }),
    
    downloadSpendingReport: builder.mutation<DownloadSpendingReportApi, SpendingReportArg>({
      query: (params) => ({
        url: "api/userReport/spendingReportByAccountGroupByCategoryXl",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useSpendingReportQuery,
  useDownloadSpendingReportMutation
} = userReportApi;