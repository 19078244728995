import { Box, Paper } from "@mui/material";
import { AnimateWrapper } from "components/animate-wrapper";

interface PaperComponentProps {
  children: any;
}
const PaperComponent = ({ children }: PaperComponentProps) => (
  <AnimateWrapper className="fade">
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          borderRadius: "10px",
          overflow: "hidden",
          boxShadow: "none",
          border: "1px solid #E5E5E5",
          margin: 0,
        }}
      >
        {children}
      </Paper>
    </Box>
  </AnimateWrapper>
);
export default PaperComponent;
