import moment from 'moment';
import helper from 'services/helper';
import { Transaction } from 'api/endpoints/transaction';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { FinancialAccount } from 'api/endpoints/financialAccounts';

const giftCardPurchase = 'Gift Card Purchase';
const rewardsDeposit = 'Rewards deposit';


export const createNewRequestText = (
  transaction: Transaction | null,
  currentAccount: FinancialAccount | undefined,
  legalName: string,
  card?: PaymentCard | undefined, 
): string => {
  const currentDate = `Time of the request: ${moment().format(
    'D MMM YYYY h:mm A'
  )}\n`;

  const transactionAmount = `Transaction amount: ${
    transaction?.amount?.symbol
  }$${helper.formatUsdValue(transaction?.amount?.value)}\n`;

  const fromAccount =
    !card && transaction?.type !== rewardsDeposit && currentAccount?.accountNumber
      ? `From: ****${currentAccount?.accountNumber.slice(-4)}\n`
      : '';

  const toAccount =
    !card && transaction?.type !== giftCardPurchase && transaction?.last4?.value
      ? `To: ****${transaction.last4.value}\n`
      : '';

  const transactionDate = `Transaction date: ${moment(transaction?.date).format(
    'D MMM YYYY h:mm A'
  )}\n`;

  const name = `Transaction name: ${legalName}\n`;
  const type = `Transaction type: ${transaction?.type}\n`;
  const status = `Status: ${helper.capitalizeFirstLetter(transaction?.status || '')}\n\n`;
  const userComment = `Comment:`

  return [
    currentDate,
    transactionAmount,
    fromAccount,
    toAccount,
    transactionDate,
    name,
    type,
    status,
    userComment
  ].join('');
};

export const createStateForSupportReportProblem = (
  transaction: Transaction | null,
  currentAccount: FinancialAccount | undefined,
  legalName: string = '',
  card?: PaymentCard | undefined,
) => {

  return {
    topic: {
      id: 4,
      isDeleted: false,
      isHidden: false,
      name: 'Transaction Dispute',
      responseTime: '1-2 days',
    },
    requestText: createNewRequestText(
      transaction,
      currentAccount,
      legalName,
      card,
    ),
  };
};


