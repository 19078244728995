import PaperComponent from "shared/components/PaperComponent"
import { useTransactions } from "../provider";
import TableBlock from "shared/components/TableBlock";
import { ExternalTransactionsCells } from "shared/components/TableBlock/TableCells/ExternalTransactionsCells";

export const RecentTransactions = () => {
  const { resentTransactions } = useTransactions();

  return (
    <PaperComponent>
      <TableBlock
        rows={resentTransactions}
        tabCells={ExternalTransactionsCells()}
        title='Recent Transactions'
      />                
    </PaperComponent>
  )
}