export default function ChevronRight(props: ChevronRightProps) {
  return (
    <div className="w-6 h-6">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 9 18 L 15 12 L 9 6"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

ChevronRight.defaultProps = {};

interface ChevronRightProps {}
