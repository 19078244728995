import { Typography } from "@mui/material"
import { BankIcon } from "shared/ui/icons"
import cx from 'classnames';

import styles from './style.module.scss'

interface BankNameProps {
  bankName: string;
  className?: string | string[];
}

const BankName = ({ bankName, className }: BankNameProps) => {
  return (
    <div className={cx(styles.bankName, className)}>
      <BankIcon />
      <Typography>{bankName}</Typography>
    </div>
  )
}

export default BankName;