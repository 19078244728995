import React, { ChangeEvent, FC } from "react";
import Loupe from "assets/svg/loupe.svg";
import styles from "../style.module.scss";

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const LimitSearchInput: FC<Props> = ({ onChange, value }) => {
  return (
    <div className={styles.limitSearch}>
      <img src={Loupe} width="24px" />
      <input
        type="text"
        className={styles.limitSearch_input}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default LimitSearchInput;
