import { Skeleton } from "components/skeleton";
import { useExternalBanks } from "api/hooksApi/useExternalBanks";
import { ExternalBanksList } from "./components/ExternalBanksList";

const ExternalBanks = () => {
  const { isLoading } = useExternalBanks();
  
  if (isLoading) {
    return <Skeleton width="100%" height="210px" />
  }

  return <ExternalBanksList />
}

export default ExternalBanks