import { Badge } from 'shared/ui/badge'
import {
  SuccessGreenIcon,
  ClockBlueIcon,
  StopCircleRedIcon,
} from 'shared/ui/icons'
import styles from './styles.module.scss'
import { AccountStatus } from 'api/endpoints/fundsMovement'
import cn from 'classnames'

interface ExternalBadgeStatusProps {
  status: AccountStatus
}

export const ExternalBadgeStatus = ({ status }: ExternalBadgeStatusProps) => {
  const renderBadgeStatus = () => {
    switch (status) {
      case AccountStatus.REJECTED:
      case AccountStatus.CLOSED:
        return (
          <Badge
            styledWrapper={styles.styledWrapperDeclined}
            styledLabel={cn(styles.styledLabel, styles.labelInDeclined)}
            label={'Declined'}
            startIcon={<StopCircleRedIcon className={styles.icon} />}
          />
        )
      case AccountStatus.REVIEW:
        return (
          <Badge
            styledWrapper={styles.styledWrapperInRiview}
            styledLabel={cn(styles.styledLabel, styles.labelInRiview)}
            label={'In review'}
            startIcon={<ClockBlueIcon className={styles.icon} />}
          />
        )
      case AccountStatus.ACTIVE:
        return (
          <Badge
            styledWrapper={styles.styledWrapperSuccess}
            styledLabel={cn(styles.styledLabel, styles.labelSuccess)}
            label={'Connected'}
            startIcon={<SuccessGreenIcon className={styles.icon} />}
          />
        )
    }
  }
  return renderBadgeStatus()
}
