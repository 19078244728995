import React, { FC, Dispatch } from "react";
import { css, styled, Typography, Divider } from "@mui/material";
import DocumentItem from "../documentItem";
import { Document, ObjFiles } from "../../index";

interface DocumentListProps {
  title: string;
  array: Document[];
  uploadFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    id: string,
    verificatedEntity: string
  ) => void;
  setFile: Dispatch<React.SetStateAction<ObjFiles[]>>;
  files: ObjFiles[];
  verificatedEntity: string;
  isDeleteDisabled?: boolean;
}

const DocumentList: FC<DocumentListProps> = ({
  title,
  array,
  uploadFile,
  setFile,
  files,
  verificatedEntity,
  isDeleteDisabled,
}) => {
  const deleteUploadFile = (foundObject: ObjFiles) => {
    setFile((prevFiles: ObjFiles[]) =>
      prevFiles.filter((file) => file !== foundObject)
    );
  };

  const renderList = () => {
    return array?.map((item, index) => {
      const foundObject = files.find(
        (file) =>
          file.documentType ===
          item?.documentUploadSession?.primaryDocumentTypes
      );
      if (item.documentUploadSession.primaryDocumentTypes) {
        return (
          <div key={index}>
            <DocumentItem
              name={item.documentUploadSession.primaryDocumentTypes}
              idSession={item.documentUploadSession.id}
              uploadFile={uploadFile}
              fileName={foundObject && foundObject.file.name}
              status={item.status}
              verificatedEntity={verificatedEntity}
              deleteFile={() => foundObject && deleteUploadFile(foundObject)}
              isDeleteDisabled={isDeleteDisabled || item.status === 'SUBMITTED'}
            />
            {index < array.length - 1 && <Divider />}
          </div>
        );
      }
    });
  };
  return (
    <Wrapper>
      <Title>{title}</Title>
      <div>
        <List>{renderList()}</List>
        <Divider />
      </div>
    </Wrapper>
  );
};

export default DocumentList;

const Wrapper = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`;

const List = styled("div")`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.borderLightGrey};
    border-radius: 4px;
  `}
`;
const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;
