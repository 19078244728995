import { AnimateWrapper } from "components/animate-wrapper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Skeleton, Typography } from "@mui/material";

interface StepperProps {
  data: any;
  isLoading: boolean;
}

const StepperComponent = ({ data, isLoading }: StepperProps) => {
  return (
    <AnimateWrapper className="fade">
      {isLoading ? (
        <Skeleton height={300} />
      ) : (
        <Stepper orientation="vertical" activeStep={data.length}>
          {data.map(({ name, date }) => (
            <Step key={name}>
              <StepLabel
                optional={<Typography variant="caption">{date}</Typography>}
                StepIconProps={{
                  style: {
                    color: "#023047",
                  },
                }}
              >
                {name}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </AnimateWrapper>
  );
};

export default StepperComponent;
