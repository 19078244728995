import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import styles from './styles.module.scss';
import { NeteviaTab } from './components/NeteviaTab';
import { AccountBalanceTopPanel } from './components/accountBalanceTopPanel';
import { useAccountBalance } from './provider';
import cn from 'classnames';
import { BANKS_TAB, AccountBalanceMode } from 'pages/home/enums';
import { BANK_TAB } from 'pages/home/constants';
import { useSearchParams } from 'react-router-dom';
import { OtherBanksTab } from './components/OtherBanksTab';

const tabMapping = {
  [AccountBalanceMode.externalAccounts]: BANKS_TAB.OTHER_BANKS,
  [AccountBalanceMode.neteviaAccounts]: BANKS_TAB.NETEVIA_BANK,
};

const AccountBalance = () => {
  const { mode, changeMode, categories } = useAccountBalance();
  const { financialAccountsIsLoading } = useFinancialAccounts();
  const [_, setSearchParams] = useSearchParams();
  const modeExternal = mode === AccountBalanceMode.externalAccounts;

  const onChangeCategory = (value: AccountBalanceMode) => {
    changeMode(value);

    setSearchParams({ [BANK_TAB]: tabMapping[value] });
  };

  const renderContent = modeExternal ? <OtherBanksTab /> : <NeteviaTab />;
  return (
    <div
      className={
        modeExternal
          ? cn(styles.externalWrap, styles.container)
          : styles.container
      }
    >
      <AccountBalanceTopPanel
        categories={categories}
        selectedCategory={mode}
        onChangeCategory={onChangeCategory}
      />
      {financialAccountsIsLoading ? (
        <Skeleton
          width='100%'
          height='90px'
        />
      ) : (
        renderContent
      )}
    </div>
  );
};

export default AccountBalance;
