import { ReactNode, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import NavigationTitle from 'shared/components/navigationTitle'

interface PropTypes {
  children: ReactNode
  title?: string
  onTitleClick?: () => void
}

const SimpleContainer = ({ children, title, onTitleClick }: PropTypes) => {
  const previousUrlRef = useRef<string>('')

  useEffect(() => {
    previousUrlRef.current = window.location.pathname
  }, [])

  return (
    <div className={styles.container}>
      {title && (
        <NavigationTitle
          handleNavigate={onTitleClick}
          title={title}
        />
      )}
      {children}
    </div>
  )
}

export default SimpleContainer
