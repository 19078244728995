import { CashFlow } from './cashFlow';
import { DiagramsProvider, useDiagrams } from './provider';
import { BalanceHistory } from './balanceHistory';

const HomeDiagramsWrapper = () => {
  const { mode } = useDiagrams();
  return mode === 'Cashflow' ? <CashFlow /> : <BalanceHistory />;
}


export const HomeDiagrams = () => {
  return (
    <DiagramsProvider>
      <HomeDiagramsWrapper />
    </DiagramsProvider>
  )
}
