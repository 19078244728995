import { useCurrentUser } from '../../providers/user.provider';
import { AnimateWrapper } from '../../components/animate-wrapper';
import AccountBalance from './components/accountBalance';
import { usePermission } from 'shared/hooks/usePermission';
import { LoginAs } from './components/loginAs';
import { HomeDiagrams } from './components/diagrams';
import { HomeSlider } from './components/homeSlide';
import { Loans } from './components/loans';
import styles from './style.module.scss';
import { HomeLists } from './components/homeLists';
import { useScreenSize } from 'shared/hooks/useScreensSize';
import { useIsOthereBankTab } from './hooks';
import ExternalBanks from './components/externalBanks';

const Home = () => {
  const { isMobile } = useScreenSize();
  const { cashflowPermission, accountBalancePermission } = usePermission();
  const { isMainBusiness } = useCurrentUser();

  const isOtherBankTab = useIsOthereBankTab();

  const renderContent = () => {
    return !isOtherBankTab ? (
      <div className={styles.content}>
        <div className={styles.box}>
         {accountBalancePermission &&  <AccountBalance />}
          <HomeLists />
          {!isMobile && cashflowPermission && <HomeDiagrams />}
        </div>
        <div className={styles.widgetBox}>
          {!isMobile && <HomeSlider />}
          {isMainBusiness && <Loans />}
          {isMobile && <HomeSlider />}
          {isMobile && cashflowPermission && <HomeDiagrams />}
        </div>
      </div>
    ) : (
      <div className={styles.contentOther}>
        <AccountBalance />
        <ExternalBanks />
      </div>
    );
  };

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        <LoginAs />
        {renderContent()}
      </div>
    </AnimateWrapper>
  );
};
export default Home;
