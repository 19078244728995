import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { FilterByDate } from 'pages/accountComponent/components/filterTransactions/componentns/filterByDate';
import { FilterByAmount } from 'pages/accountComponent/components/filterTransactions/componentns/filterByAmount';
import { FilterByMoneyMovement } from 'pages/accountComponent/components/filterTransactions/componentns/filterByMoneyMovement';
import { FilterByMerchantType } from 'pages/accountComponent/components/filterTransactions/componentns/filterByMerchantType';
import { Divider } from '@mui/material';
import styles from './style.module.scss';
import { Button } from 'shared/ui/buttons';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { TransactionFilterSchema } from 'pages/accountComponent/components/filterTransactions/componentns/validationFilterTransactionSchema';
import { Dayjs } from 'dayjs';
import { useCardInformation } from 'pages/cardComponent/provider';
import { useSearchParams } from 'react-router-dom';
import { initialTransactionApiParams , initialCardTransactionFilter} from 'pages/accountComponent/contsants';
import { CardsFilters } from 'api/endpoints/transactionEvents';

export const FilterCardTransactions = () => {
  const {
    transactionFilterData,
    transactionApiParams,
    isFetchingCardTransactions,
    expandFilter,
    setExpandFilter,
    setTransactionApiParams,
    setTransactionApiFilter,
  } = useCardInformation();

  const [_, setSearchParams] = useSearchParams();
  const [filterObj, setFilterObj] = useState<CardsFilters>(transactionFilterData);
  

  const formik = useFormik({
    initialValues: {
      date: [transactionApiParams.dateFrom, transactionApiParams.dateTo] as [
        Dayjs | null,
        Dayjs | null,
      ],
      amountFrom: transactionApiParams.amountFrom,
      amountTo: transactionApiParams.amountTo,
    },
    validationSchema: TransactionFilterSchema,
    onSubmit: (form) => {
      setTransactionApiParams({
        dateFrom: form.date[0] as Dayjs,
        dateTo: form.date[1] as Dayjs,
        amountFrom: form.amountFrom,
        amountTo: form.amountTo,
      });
      setTransactionApiFilter(filterObj);
      setSearchParams({});
    },
  });

  const onReset = () => {
    formik.setValues({
      date: [
        initialTransactionApiParams.dateFrom,
        initialTransactionApiParams.dateTo,
      ],
      amountFrom: initialTransactionApiParams.amountFrom,
      amountTo: initialTransactionApiParams.amountTo,
    });
    setFilterObj(initialCardTransactionFilter);
  };

  return (
    <TransitionAnimation>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.container}
      >
        <div className={styles.boxScroll}>
          <FilterByDate formik={formik} />
          <FilterByAmount formik={formik} />
          <FilterByMoneyMovement
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            loading={isFetchingCardTransactions}
            expandFilter={expandFilter}
            setExpandFilter={setExpandFilter}
          />
          <Divider />
          <FilterByMerchantType
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            loading={isFetchingCardTransactions}
            expandFilter={expandFilter}
            setExpandFilter={setExpandFilter}
          />
        </div>
        <div className={styles.boxBtn}>
          <Button
            variant='text'
            onClick={() => onReset()}
            loading={isFetchingCardTransactions}
          >
            clear all
          </Button>
          <LoadingButton
            type='submit'
            variant='contained'
            classes={{ disabled: styles.btnDisabled }}
            disabled={Object.values(formik.errors).some(Boolean)}
            loading={isFetchingCardTransactions}
          >
            apply
          </LoadingButton>
        </div>
      </form>
    </TransitionAnimation>
  );
};
