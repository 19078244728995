import React, { FC } from "react";
import { Snackbar, Alert } from "@mui/material";

interface Props {
  isOpen: boolean;
  message: string;
  onClose: (isOpen: boolean) => void;
}

const SnackBarWarning: FC<Props> = ({ isOpen, message, onClose }) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isOpen}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarWarning;
