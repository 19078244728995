import { useEffect, useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import DrawerModal from 'shared/components/drawerModal';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useSubUsersAvailableFinancialAccounts } from 'api/hooksApi/useSubUsersAvailableFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import { AccountItem } from '../../../accountItem';
import { List, Switch } from '@mui/material';
import { sortByDate } from 'pages/settings/accountSettings/lib/sortByDate';
import styles from './style.module.scss';
import { useAttachToFinAccControllerMutation } from 'api/endpoints/subProfiles';
import { useDetachToFinAccControllerMutation } from 'api/endpoints/subProfiles';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { AddNewAccount } from 'pages/home/components/accountList/components/addNewAccount';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
interface ManageConnectedAccountsProps {
  subId: number;
}

export const ManageConnectedAccounts = ({
  subId,
}: ManageConnectedAccountsProps) => {
  const { setSnackBar } = useSnackBar();
  const { goNext, lockForClose, unlockForClose } = useDrawerStepperContext();
  const {
    subUsersAvailableFinancialAccounts,
    subUsersAvailableFinancialAccountsIsLoading,
    subUsersAvailableFinancialAccountsRefetch,
  } = useSubUsersAvailableFinancialAccounts(subId.toString());

  const [attachToFinAccMutation] = useAttachToFinAccControllerMutation();
  const [detachToFinAccMutation] = useDetachToFinAccControllerMutation();

  const [availableAccounts, setAvailableAccounts] = useState(
    subUsersAvailableFinancialAccounts || []
  );

  const openNewAccountBool = useBoolean();
  const connectedAccUpdateBool = useBoolean();

  useEffect(() => {
    if (subUsersAvailableFinancialAccounts) {
      setAvailableAccounts(subUsersAvailableFinancialAccounts);
    }
  }, [subUsersAvailableFinancialAccounts]);

  const onSelect = (acc) => {
    setAvailableAccounts((prevAccounts) =>
      prevAccounts.map((i) => ({
        ...i,
        selected:
          acc.financialAccountId === i.financialAccountId
            ? !i.selected
            : i.selected,
      }))
    );
  };

  const hasChanges = () => {
    return availableAccounts.some(
      (account) => account.selected !== account.attached
    );
  };

  const renderList = () => {
    if (!subUsersAvailableFinancialAccounts?.length) return <></>;

    return (
      <List
        disablePadding
        className={styles.list}
      >
        {availableAccounts.sort(sortByDate).map((item) => (
          <AccountItem
            key={item.financialAccountId}
            item={item}
            endSlot={
              <Switch
                onChange={() => onSelect(item)}
                checked={!!item.selected}
                disabled={connectedAccUpdateBool.value}
              />
            }
          />
        ))}
      </List>
    );
  };

  const editSubUserAccount = async () => {
    try {
      connectedAccUpdateBool.setTrue();
      lockForClose();
      await Promise.all(
        availableAccounts.map(async (account) => {
          if (!account.attached && account.selected) {
            await attachToFinAccMutation({
              financialAccountId: account.financialAccountId,
              SubProfileId: subId,
            });
          } else if (account.attached && !account.selected) {
            await detachToFinAccMutation({
              FinancialAccoutId: account.financialAccountId,
              SubProfileId: subId,
            });
          }
        })
      );

      subUsersAvailableFinancialAccountsRefetch();
      setSnackBar({
        type: 'success',
        message: 'Your connected accounts have been updated',
        isShow: true,
      });
      connectedAccUpdateBool.setFalse();
      unlockForClose();
      goNext('0');
    } catch (e: any) {
      connectedAccUpdateBool.setFalse();
      unlockForClose();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleAccountCreationSuccess = (id: string | undefined) => {
    subUsersAvailableFinancialAccountsRefetch();
    if (id) {
      setAvailableAccounts((prevAccounts) =>
        prevAccounts.map((acc) =>
          acc.financialAccountId === id ? { ...acc, selected: true } : acc
        )
      );
    }
    openNewAccountBool.setFalse();
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>
          Choose accounts to connect to the authorized user
        </Typography>
        <Button
          variant='text'
          className={styles.btnOpen}
          onClick={() => openNewAccountBool.setTrue()}
        >
          + Open new account
        </Button>
        {subUsersAvailableFinancialAccountsIsLoading ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          renderList()
        )}
        <Button
          className={styles.btnSave}
          onClick={editSubUserAccount}
          disabled={!hasChanges()}
          loading={connectedAccUpdateBool.value}
        >
          <div>Save changes</div>
        </Button>
        <DrawerModal
          isShow={openNewAccountBool.value}
          onClose={() => openNewAccountBool.setFalse()}
          goBack={() => openNewAccountBool.setFalse()}
          titleText='Manage Connected Accounts'
        >
          <AddNewAccount onSuccessRedirect={handleAccountCreationSuccess} />
        </DrawerModal>
      </div>
    </TransitionAnimation>
  );
};
