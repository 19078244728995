import Button from "@mui/material/Button";
import appStoreSvg from "../../assets/svg/app-store.svg";
import googlePlaySvg from "../../assets/svg/google-play.svg";
import { TStoreButton } from "./types";

const StoreButton = ({ to, secondary }: TStoreButton) => {
  return (
    <a href={to}>
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
          width: secondary ? "120px" : "120px",
          height: "40px",
          backgroundImage: secondary
            ? `url(${appStoreSvg})`
            : `url(${googlePlaySvg})`,
          backgroundSize: "cover",
          // backgroundColor: 'black',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: 2,
          border: "none",
          "&:hover": {
            border: "none",
          },
        }}
      />
    </a>
  );
};

export default StoreButton;
