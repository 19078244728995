import { usePartnerProductIdByName } from './usePartnerProductIdByName';
import { useEffect, useState } from 'react';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import { PartnerUsageStatus, useGetPartnerUsageStatusQuery } from '../endpoints/partners';
import { useBoolean } from '../../shared/hooks/useBoolean';

interface Params {
  productName: string;
}

interface ReturnType {
  isLoading: boolean;
  partnerUsageStatus?: PartnerUsageStatus;
}


export const usePartnerUsageStatus = ({ productName }: Params): ReturnType => {
  const partnerProduct = usePartnerProductIdByName();
  const [productId, setProductId] = useState<string | null>(null);
  const isMounted = useIsMounted();
  const { data: partnerUsageStatus, isLoading } = useGetPartnerUsageStatusQuery({ productId: productId! }, { skip: !productId });
  const isLoadingIdBool = useBoolean();


  useEffect(() => {
    (async () => {
      isLoadingIdBool.setTrue();
      try {
        const id = await partnerProduct.getProductId(productName);
        if (isMounted()) {
          setProductId(id);
        }
      } finally {
        if (isMounted()) {
          isLoadingIdBool.setFalse();
        }
      }
    })();
  }, []);

  return {
    partnerUsageStatus,
    isLoading: isLoading || isLoadingIdBool.value,
  }
};
