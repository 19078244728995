import { Typography } from 'shared/ui/typography';
import styles from './style.module.scss';
import {
  AttachedPaymentCard,
  useUpdateSubProfilePaymentCardMutation,
} from 'api/endpoints/subProfiles';
import { TransitionAnimation } from 'shared/ui/animations';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import Badge from 'pages/accountComponent/components/Badge';
import { List } from '@mui/material';
import { appRoutes } from 'routes';
import { useNavigate } from 'react-router-dom';
import { Switch } from '../../../../../shared/ui/inputs';
import { useDrawerBehavior } from '../../../../../providers/drawer-behavior';
import { useSnackBar } from '../../../../../providers/snackBar.provider';
import helper from '../../../../../services/helper';

interface ConnectedCardListProps {
  attachedPaymentCards: AttachedPaymentCard[];
  subProfileId: number;
  onSubUsersRefetch: () => Promise<void>;
}

export const ConnectedCardList = ({
  attachedPaymentCards,
  subProfileId,
  onSubUsersRefetch,
}: ConnectedCardListProps) => {
  const navigate = useNavigate();
  const drawerBehavior = useDrawerBehavior();
  const [updateSubProfileCard] = useUpdateSubProfilePaymentCardMutation();
  const { setSnackBar } = useSnackBar();

  const handleNavigateToCard = (accountId: string | null, cardId: string) => {
    if (accountId) {
      navigate(appRoutes.cardById(accountId, cardId));
    }
  };

  const handleChangeEmailNotify = async (
    card: AttachedPaymentCard,
    checked: boolean
  ) => {
    drawerBehavior.setLockForCloseStatus(true);
    try {
      await updateSubProfileCard({
        SubProfileId: subProfileId,
        PaymentCardId: card.id,
        IsEmailNotify: checked,
      }).unwrap();
      await onSubUsersRefetch();
      setSnackBar({
        isShow: true,
        type: 'success',
        message: `Notifications for the card ${card.formFactor} (${
          card.last4
        }) have been successfully ${checked ? 'enabled' : 'disabled'}.`,
      });
    } catch(err: any) {
      setSnackBar({
        isShow: true,
        type: 'error',
        message: helper.formatErrors(err?.data),
      });
    } finally {
      drawerBehavior.setLockForCloseStatus(false);
    }
  };

  const renderList = () => {
    if (!attachedPaymentCards?.length)
      return (
        <Typography className={styles.empyText}>
          No connected cards yet.
        </Typography>
      );

    return (
      <List
        disablePadding
        className={styles.list}
      >
        {attachedPaymentCards.map((card) => (
          <div
            key={card.id}
            className={styles.wrapper}
            style={{
              pointerEvents: drawerBehavior.isCloseDisabled
                ? 'none'
                : 'initial',
            }}
            onClick={() =>
              handleNavigateToCard(card.financialAccountId, card.id)
            }
          >
            <div className={styles.boxInfo}>
              <div className={styles.boxImg}>
                <img
                  src={CreditCardOutlined}
                  alt='Card'
                />
              </div>
              <div>
                <Typography className={styles.formFactor}>
                  {card.formFactor}
                </Typography>
                <Typography className={styles.last4}>
                  ***{card.last4}
                </Typography>
                <div
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.nativeEvent.stopImmediatePropagation();
                  }}
                >
                  <Switch
                    size='small'
                    disabled={drawerBehavior.isCloseDisabled}
                    label='Email notifications'
                    checked={card.isEmailNotify}
                    onChange={(_: unknown, checked: boolean) =>
                      handleChangeEmailNotify(card, checked)
                    }
                  />
                </div>
              </div>
            </div>
            <Badge item={card} />
          </div>
        ))}
      </List>
    );
  };

  return <TransitionAnimation>{renderList()}</TransitionAnimation>;
};
