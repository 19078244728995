import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper'
import { Intro } from '../intro'
import { SelectingCardType } from '../selecting-card-type'
import { OpenVirtualCardStep1 } from '../open-virtual-card-step-1'
import { OpenPhysicalCardStep1 } from '../open-physical-card-step-1'
import { OpenVirtualCardStep2 } from '../open-virtual-card-step-2'
import { OpenPhysicalCardStep2 } from '../open-physical-card-step-2'
import { OpenPhysicalCardStep3 } from '../open-physical-card-step-3'

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Open OrderOut card',
      prevId: null,
      Element: <Intro />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'Open OrderOut card',
      prevId: '0',
      Element: <SelectingCardType />,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: true,
      title: 'Open physical OrderOut card',
      prevId: '1',
      Element: <OpenPhysicalCardStep1 />,
    },
  ],
  [
    '3',
    {
      id: '3',
      isCanGoBack: true,
      title: 'Open virtual OrderOut card',
      prevId: '1',
      Element: <OpenVirtualCardStep1 />,
    },
  ],
  [
    '4',
    {
      id: '4',
      isCanGoBack: false,
      title: 'Open virtual OrderOut card',
      prevId: '3',
      Element: <OpenVirtualCardStep2 />,
      resetStepsOnReach: true
    },
  ],
  [
    '5',
    {
      id: '5',
      isCanGoBack: true,
      title: 'Open physical OrderOut card',
      prevId: '2',
      Element: <OpenPhysicalCardStep2 />,
    },
  ],
  [
    '6',
    {
      id: '6',
      isCanGoBack: false,
      title: 'Open physical OrderOut card',
      prevId: '5',
      Element: <OpenPhysicalCardStep3 />,
      resetStepsOnReach: true
    },
  ],
]

const MapSteps = new Map(steps)

interface Props {
  isShow: boolean
  onClose: () => void
}

export const OpenOrderOutCard = (props: Props) => {
  return (
    <DrawerStepper
      {...props}
      startStep='0'
      steps={MapSteps}
    />
  )
}
