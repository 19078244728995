import React, { FC } from "react";
import styles from "./style.module.scss";
import { ReactComponent as ShowIcon } from "assets/svg/eyes-show-wite.svg";
import { ReactComponent as HideIcon } from "assets/svg/eyes-hide-white.svg";

interface CardShowOtherSideProps {
  handleToggleShow: (event: React.MouseEvent) => void;
  isShow: boolean;
  textColor?: string;
  buttonBg?: string;
}

const CardShowOtherSide: FC<CardShowOtherSideProps> = ({
  handleToggleShow,
  isShow,
  textColor = '#fff',
  buttonBg = '#1b548240'
}) => {
  return (
    <div style={{ color: textColor, background: buttonBg }} className={styles.wrapper} onClick={handleToggleShow}>
      {isShow ? <HideIcon /> : <ShowIcon />}
      <span>{isShow ? "HIDE CARD DETAILS" : "SHOW CARD DETAILS"}</span>
    </div>
  );
};
export default CardShowOtherSide;
