import React from "react";
import NavigateButton from "../../components/button/NavigateButton";
import CheckCircle from "../../assets/svg/check-circle.svg";
import { Link } from "react-router-dom";
import ArrowLeft from "../../assets/svg/arrow-left.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";

const OrderSuccess = () => {
  const { t } = useTranslationProvider();
  return (
    <div className="flex flex-col">
      <Link to="/home">
        <div className="flex gap-5 xs:mt-6 pt-10">
          <img src={ArrowLeft} />
          <span className="font-bold text-primblue">
            {t("Card.Order physical card", {
              defaultValue: "Order physical card",
            })}
          </span>
        </div>
      </Link>
      <div className="flex flex-col mb-40 ml-10 mt-11">
        <img src={CheckCircle} width="90px" />
        <p className="text-2xl my-4">
          {t("Card.Physical card is ordered", {
            defaultValue: "Physical card is ordered!",
          })}
        </p>
        <div className="mb-2 my-2">
          <p className="text-stone-600 w-80 text-base mt-12">
            {t("Card.Congratulations", {
              defaultValue:
                "Congratulations! The cards has been ordered. You may check delivery status in the card menu.",
            })}
          </p>
        </div>
        <div className="flex mt-12 items-center">
          <NavigateButton
            name={t("Card.Go to your Card", {
              defaultValue: "Go to your Card",
            })}
            to="/home"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
