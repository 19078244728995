import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppLoader, hideAppLoader, showAppLoader } from '../components/appLoader';
import { PaymentCardDetails, usePaymentCardByid } from '../api/hooksApi/usePaymentCardById';
import { appRoutes } from '../routes';
import { requireNotNull } from '../shared/utils/require-not-null';
import { useTimeout } from '../shared/hooks/useTimeout';

// this helper direct /card-transactions/:cardId to new url -> /account/:accountId/card/:cardId
export const CardTransactionsDeprecated = () => {
  const params = useParams();
  const { paymentCardById, paymentCardByIdIsLoading } = usePaymentCardByid()
  const navigate = useNavigate();
  const [cardState, setCardState] = useState<PaymentCardDetails | undefined>()

  useEffect(() => {
    if (cardState) {
      const fa = cardState?.financialAccounts[0];
      if (fa) {
        let urlToDirect = appRoutes.cardById(fa.id, requireNotNull(params.cardId));
        if (params['*']) {
          urlToDirect += `/${params['*']}`
        }
        navigate(urlToDirect);
      }
    }
  }, [cardState])

  useTimeout(() => {
    if (!cardState?.financialAccounts && !paymentCardByIdIsLoading) {
      (async () => {
        try {
          const card = requireNotNull(await paymentCardById(requireNotNull(params.cardId)))
          setCardState(card);
        } catch {
          navigate(appRoutes.home());
        }
      })();
    }
  }, 2000);

  return <AppLoader isVisible={true} />;
};
