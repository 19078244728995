import { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import styles from "./style.module.scss";
import { EMAIL, SMS, CheckBox } from "../..";
import { useTranslationProvider } from "providers/translation/translation.provider";

const CHECK_BOX_COLOR = "#187AC9";
interface CheckBoxGroupProps {
  type: CheckBox;
  handleChangeType: (string) => void;
}
const CheckBoxGroup: FC<CheckBoxGroupProps> = ({ type, handleChangeType }) => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.wrapper}>
      <div className={styles.checkBox}>
        <Checkbox
          checked={type === EMAIL}
          onChange={() => handleChangeType(EMAIL)}
          inputProps={{ "aria-label": "controlled" }}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon style={{ color: CHECK_BOX_COLOR }} />
          }
        />
        <span className={styles.checkBox_label}>
          {t("Login.Send a recovery code via email", {
            defaultValue: "Send a recovery code via email",
          })}
        </span>
      </div>
      <div className={styles.checkBox}>
        <Checkbox
          checked={type === SMS}
          onChange={() => handleChangeType(SMS)}
          inputProps={{ "aria-label": "controlled" }}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon style={{ color: CHECK_BOX_COLOR }} />
          }
        />
        <span className={styles.checkBox_label}>
          {t("Login.Send a recovery code via SMS", {
            defaultValue: "Send a recovery code via SMS",
          })}
        </span>
      </div>
    </div>
  );
};
export default CheckBoxGroup;
