import { FC, ReactNode } from 'react'
import {
  Drawer,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material'
import styles from './style.module.scss'
import { useSearchParams } from 'react-router-dom'
import cx from 'classnames';

import { ArrowBackIcon,CloseOutlinedIcon } from 'shared/ui/icons'
import { useDrawerBehavior } from '../../../providers/drawer-behavior';

interface DrawerModalProps {
  isShow: boolean
  onClose: (isOpen: boolean) => void
  children: ReactNode
  goBack?: () => void
  titleText?: string
  isControled?: boolean
  isCloseOnBackdropDisabled?: boolean;
}

const DrawerModal: FC<DrawerModalProps> = ({
  isShow,
  onClose,
  children,
  goBack,
  titleText,
  isControled,
  isCloseOnBackdropDisabled,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  let page = searchParams.get('page')
  const title = searchParams.get('title')
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints?.up('sm'))
  const drawerBehavior = useDrawerBehavior();
  const isCloseDisabled = drawerBehavior.isCloseDisabled && isShow;

  const handleClose = () => {
    if (isShow && !isCloseDisabled) {
      onClose(false)
      setSearchParams(page ? { page } : {})
    }
  }

  const titleDisplay =
    (titleText ? titleText : title?.replace(/([a-z])([A-Z])/g, '$1 $2')) ??
    'Edit data'

  return (
    <>
     {isCloseDisabled && (
       <div
         style={{
           position: 'absolute',
           zIndex: 4,
           top: 0,
           left: 0,
           right: 0,
           bottom: 0,
         }}
       />
     )}
      <Drawer
        anchor={matches ? 'right' : 'bottom'}
        open={isShow}
        onClose={isCloseOnBackdropDisabled ? undefined : handleClose}
        variant='temporary'
        PaperProps={{ className: styles.drawerPaper }}
        ModalProps={{
          slotProps: {
            backdrop: {
              className: cx(styles.backdrop, isCloseDisabled && styles.backdropDrawerDisabled)
            }
          },
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.boxTitle}>
            <div className={styles.goBack}>
              {goBack && (
                <IconButton
                  disabled={isCloseDisabled}
                  color='inherit'
                  aria-label='Go Back'
                  onClick={() => goBack()}
                >

                  <ArrowBackIcon/>
                </IconButton>
              )}
              <span className={styles.boxTitle_text}>{titleDisplay}</span>
            </div>
            <IconButton
              color='inherit'
              aria-label='Close Drawer'
              onClick={handleClose}
              disabled={isCloseDisabled}
            >
              <CloseOutlinedIcon/>
            </IconButton>
          </div>
          {!isControled && (
            <Divider
              sx={{
                '&.MuiDivider-root': {
                  marginBottom: '24px',
                },
              }}
            />
          )}
          {isShow && children}
        </div>
      </Drawer>
   </>
  )
}
export default DrawerModal
