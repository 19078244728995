import { SvgIcon as SvgIconMUI } from '@mui/material'
import type { SvgIconProps as SvgIconMUIProps } from '@mui/material'
import cn from 'classnames'

export const wrapIcon = (
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >,
  options?: { className?: string }
) => {
  return function SvgIcon(props: SvgIconMUIProps) {
    return (
      <SvgIconMUI
        component={icon}
        {...props}
        className={cn(props.className, options?.className)}
        inheritViewBox
      />
    )
  }
}
