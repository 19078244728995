import styles from "./style.module.scss";
import StoreButton from "components/button/StoreButton";
import { useTranslationProvider } from "providers/translation/translation.provider";

const GroupingStoreButton = () => {
  const { t } = useTranslationProvider();
  return (
    <>
      <p className={styles.text}>
        {t("Login.Download Netevia app", {
          defaultValue: "Download Netevia app",
        })}
      </p>
      <div className={styles.groupingStoreButton}>
        <StoreButton
          secondary
          to={"https://apps.apple.com/us/app/netevia-banking/id1625351334"}
        />
        <StoreButton
          to={
            "https://play.google.com/store/apps/details?id=com.neteviacard.card&hl=en&gl=US"
          }
        />
      </div>
    </>
  );
};
export default GroupingStoreButton;
