import { TextInput } from 'shared/ui/inputs'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Button } from 'shared/ui/buttons'
import { IconButton, InputAdornment, Button as ButtonMUI } from '@mui/material'
import styles from './style.module.scss'
import { useBoolean } from 'shared/hooks/useBoolean'
import { FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { appRoutes } from 'routes'

interface FormValues {
  password: string
  passwordConfirm: string
}

interface ChangePaswordHqFormProps {
  formik: FormikProps<FormValues>
}

export const ChangePaswordHqForm = ({ formik }: ChangePaswordHqFormProps) => {
  const navigate = useNavigate()
  const showPasswordBool = useBoolean(false)

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapperForm}
    >
      <div className={styles.boxInput}>
        <TextInput
          fullWidth
          name='password'
          label='New password'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type={showPasswordBool.value ? 'text' : 'password'}
          error={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={showPasswordBool.toggle}
                  onMouseDown={(e) => e.preventDefault()}
                  edge='end'
                >
                  {showPasswordBool.value ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextInput
          fullWidth
          label='Confirm password'
          name='passwordConfirm'
          value={formik.values.passwordConfirm}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              formik.handleSubmit()
            }
          }}
          error={
            formik.touched.passwordConfirm && formik.errors.passwordConfirm
          }
          type={showPasswordBool.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={showPasswordBool.toggle}
                  onMouseDown={(e) => e.preventDefault()}
                  edge='end'
                >
                  {showPasswordBool.value ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={styles.boxBtn}>
        <ButtonMUI
          variant='contained'
          className={styles.btn}
          type='submit'
          disabled={!formik.isValid}
        >
          Confirm
        </ButtonMUI>
        <Button
          variant='text'
          onClick={() => navigate(appRoutes.home())}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}
