import { FC } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
interface DropzoneProps {
  files: File[];
  setFiles: (newFiles: File[]) => void;
  maxFileSize?: number;
  acceptedFiles?: string[];
}
const Dropzone: FC<DropzoneProps> = ({
  files,
  setFiles,
  maxFileSize,
  acceptedFiles,
}) => {
  const { t } = useTranslationProvider();
  const addFiles = (newFiles) => {
    setFiles(newFiles);
  };

  return (
    <DropzoneArea
      onChange={addFiles}
      fileObjects={files}
      showPreviewsInDropzone={false}
      showAlerts={false}
      dropzoneText={t("Support.Click to upload", {
        defaultValue: "Click to upload or drag and drop",
      })}
      dropzoneClass={styles.dropzone}
      dropzoneParagraphClass={styles.dropzoneText}
      filesLimit={8}
      maxFileSize={maxFileSize}
      acceptedFiles={acceptedFiles}
      useChipsForPreview
      showPreviews={true}
      previewText={""}
      previewGridClasses={{
        container: styles.previewContainer,
      }}
      previewChipProps={{ classes: { root: styles.previewChip } }}
    />
  );
};
export default Dropzone;
