import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { Button } from 'shared/ui/buttons';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { CircularLoader } from 'shared/ui/loaders';
import { TransitionAnimation } from 'shared/ui/animations';
import { useState } from 'react';
import { formatAddress } from 'shared/lib/format';
import { Switch } from 'shared/ui/inputs/switch';
import { DeliveryService } from 'entities/delivery-service/ui/delivery-service';
import { DeliveryForm } from './delivery-form';
import { useAppDispatch } from 'store/hooks';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { PhysicalCardOrderSchema, PhysicalCardOrder } from '../../model';
import { OrderOutCardModel } from 'entities/orderout-card';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useMediaQuery } from '@mui/material';
import { useFormik } from 'formik';

export const OpenPhysicalCardStep1 = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();

  const [deliveryService, setDeliveryService] = useState('');
  const [useAnotherAddress, setUseAnotherAddress] = useState(false);

  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();

  const { data: holderData, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery();

  const formik = useFormik<PhysicalCardOrder>({
    initialValues: {
      state: '',
      address: '',
      firstName: '',
      lastName: '',
      postCode: '',
      city: '',
    },
    validationSchema: useAnotherAddress && PhysicalCardOrderSchema,
    onSubmit: (form) => {
      if (useAnotherAddress) {
        dispatch(
          OrderOutCardModel.setDeliveryInfo({
            ...form,
            method: deliveryService,
            countryCodeAlpha3:
              formattedHolderData?.bussinessInfo.addressInfo
                .countryCodeAlpha3 ?? 'USA',
            companyName:
              holderData?.businessAccountHolder?.legalBusinessName ?? 'Unknown',
          })
        );
      } else {
        const adddressInfo = formattedHolderData?.bussinessInfo.addressInfo;

        dispatch(
          OrderOutCardModel.setDeliveryInfo({
            address: adddressInfo?.streetAddress!,
            city: adddressInfo?.locality!,
            postCode: adddressInfo?.postalCode!,
            state: adddressInfo?.region!,
            method: deliveryService,
            firstName: holderData?.businessAccountHolder?.givenName ?? '',
            lastName: holderData?.businessAccountHolder?.familyName ?? '',
            countryCodeAlpha3:
              formattedHolderData?.bussinessInfo.addressInfo
                .countryCodeAlpha3 ?? 'USA',
            companyName:
              holderData?.businessAccountHolder?.legalBusinessName ?? 'Unknown',
          })
        );
      }

      goNext('5');
    },
  });

  const fullAddress = formattedHolderData?.bussinessInfo.addressInfo
    ? formatAddress(formattedHolderData?.bussinessInfo.addressInfo)
    : '';

  const handleChangeDeliveryService = (value: string) => {
    setDeliveryService(value);
    formik.validateForm()
  };

  const handleChangeAnotherAddress = () => {
    setUseAnotherAddress((prev) => !prev);
  };


  if (formattedholderDataIsLoading || isLoadingHolder) {
    return (
      <div className={styles.loaderContainer}>
        <CircularLoader />
      </div>
    );
  }


  return (
    <TransitionAnimation>
      <form className={styles.root} onSubmit={formik.handleSubmit}>
        <Typography variant='h6'>
          Select where you would like your card to be delivered
        </Typography>

        <div className={styles.addressContainer}>
          <Typography variant='subtitle1'>Delivery address</Typography>

          <Typography variant='body2'>{fullAddress}</Typography>
        </div>

        <Switch
          label={'Ship to a different address'}
          LabelProps={{ labelPlacement: 'start' }}
          fullWidth
          checked={useAnotherAddress}
          onChange={handleChangeAnotherAddress}
        />

        <TransitionAnimation
          inState={useAnotherAddress}
          containerProps={{ className: styles.containerForm }}
        >
          <DeliveryForm formik={formik}/>
        </TransitionAnimation>

        <DeliveryService
          value={deliveryService}
          onChange={handleChangeDeliveryService}
        />

        <Button
          type='submit'
          fullWidth={isMobile}
          disabled={!fullAddress || !deliveryService || (useAnotherAddress && !formik.isValid)}
        >
          NEXT
        </Button>
      </form>
    </TransitionAnimation>
  );
};
