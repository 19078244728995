import { FC, ReactNode, SyntheticEvent } from "react";
import ListItem from "@mui/material/ListItem";

import chevronRigth from "assets/svg/chevron-right-gray.svg";
import styles from "./style.module.scss";

interface ButtonChevronRigthProps {
  children?: ReactNode;
  label: string;
  img?: string;
}

const ButtonChevronRigth: FC<ButtonChevronRigthProps> = ({
  label,
  img,
  children,
  // handleNavigate,
}) => {
  return (
    <ListItem disablePadding>
      {/* <ListItemButton
        onClick={() => handleNavigate(id)}
        sx={{
          padding: 0,
        }}
      > */}
      <div className={styles.wrapper}>
        <div className={styles.wrapper_boxContent}>
          {img && <img src={img} className={styles.wrapper_img} />}
          <div className={styles.wrapper_boxChild}>
            <span className={styles.label}>{label}</span>
            {children}
          </div>
        </div>
        <img src={chevronRigth} />
      </div>
      {/* </ListItemButton> */}
    </ListItem>
  );
};
export default ButtonChevronRigth;
