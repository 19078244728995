import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../assets/svg/arrow-left.svg";
import { useAtmLocationControllerMutation } from "../../../api/endpoints/paymentCards";
import { usePaymentCards } from "../../../api/hooksApi/usePaymentCards";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  InfoWindow,
} from "@react-google-maps/api";
import styles from "./style.module.scss";
import atmMarker from "../../../assets/svg/atmMarker.svg";
import CustomMarker from "./components/marker";
import ATMListItem from "./components/ATMListItem";
import MoonLoader from "react-spinners/MoonLoader";
import { Skeleton } from "../../../components/skeleton";
import Button from "./components/buuton";
import { useTranslationProvider } from "providers/translation/translation.provider";

const ATMLocation = () => {
  const { t } = useTranslationProvider();
  const { paymentCards, paymentCardsIsLoading } = usePaymentCards();
  const [atmLocationMutation, { isLoading: atmLocationIsLoading }] =
    useAtmLocationControllerMutation();
  const [userLocation, setUserLocation] = useState<any>(null);
  const [atmLocations, setAtmLocations] = useState<any>([]);

  useEffect(() => {
    if (navigator.geolocation) {
      getLocationUser();
    }
  }, []);

  useEffect(() => {
    getAtmLocations(userLocation);
  }, [userLocation]);

  const getLocationUser = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAtmLocations = async (userLocation) => {
    try {
      const res = await atmLocationMutation({
        paymentCardId: !!paymentCards && paymentCards[0]?.id,
        lt: 40.708601, //userLocation.latitude,
        ld: -73.942799, //userLocation.longitude,
        mile: 10,
        filter: ["OPEN_24_HOURS"],
      }).unwrap();
      const atm = res?.node?.atmLocations?.atmLocations;
      console.log("atmRes", res);
      if (atm) {
        setAtmLocations(atm);
      }
    } catch (e: any) {
      console.log("error", e);
    }
  };

  const center = useMemo(() => ({ lat: 40.708601, lng: -73.942799 }), []);

  const renderTooltip = (item) => {
    return (
      <InfoWindow>
        <div /*className={styles.tooltipContainer}*/>
          <p>
            {t("Settings.Address", {
              defaultValue: "Address",
            })}
            :
          </p>
          <p>{item.description}</p>
        </div>
      </InfoWindow>
    );
  };

  const renderAtmList = (atmLocations) => {
    return (
      !!atmLocations &&
      atmLocations.map((item, index) => <ATMListItem key={index} item={item} />)
    );
  };

  return (
    <div>
      <Link to="/home">
        <div className={styles.nav}>
          <img src={ArrowLeft} />
          <span className={styles.nav_title}>
            {t("common.ATM", {
              defaultValue: "ATM",
            })}
          </span>
        </div>
      </Link>
      <div className={styles.container}>
        <div className={styles.container_wrapTab}>
          <Button
            name={t("common.Open now", {
              defaultValue: "Open now",
            })}
            width={150}
          />
          <Button
            name={t("common.Deposit available", {
              defaultValue: "Deposit available",
            })}
            secondary
            width={150}
          />
        </div>
        <div className={styles.container_wrapMap}>
          {atmLocationIsLoading ? (
            <div className={styles.loader}>
              <MoonLoader />
            </div>
          ) : (
            <GoogleMap
              mapContainerClassName={styles.mapcontainer}
              center={center}
              zoom={15}
            >
              <Marker
                position={center}
                //position={{ lat: 40.6994755, lng: -73.95701125 }}
              />

              {!!atmLocations &&
                atmLocations.map((item, key) => {
                  if (item?.coordinates?.latitude) {
                    const latitude = Number(item.coordinates.latitude);
                    const longitude = Number(item.coordinates.longitude);

                    return (
                      <CustomMarker
                        position={{ lat: latitude, lng: longitude }}
                        key={key}
                        icon={atmMarker}
                        renderTooltip={() => renderTooltip(item)}
                      />
                    );
                  }
                })}
            </GoogleMap>
          )}
        </div>
        <p className={styles.labelText}>
          {t("common.List of all ATM", {
            defaultValue: "List of all ATM",
          })}
          :
        </p>
        <div className={styles.grid}>
          {atmLocationIsLoading ? (
            <>
              <Skeleton width={437} height={188} />
              <Skeleton width={437} height={188} />
            </>
          ) : (
            renderAtmList(atmLocations)
          )}
        </div>
      </div>
    </div>
  );
};

export default ATMLocation;
