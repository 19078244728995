import React, { ChangeEvent, FC } from "react";
import styles from "../style.module.scss";
import On from "assets/svg/on.svg";
import Off from "assets/svg/off.svg";
import { FormControl, OutlinedInput } from "@mui/material";
import BeatLoader from "react-spinners/BeatLoader";

interface Props {
  title: string;
  onToggle: () => void;
  enabled: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  isLoading: boolean;
}

const LimitSwitchItem: FC<Props> = ({
  title,
  onToggle,
  enabled,
  value,
  onChange,
  disabled,
  isLoading,
}) => {
  return (
    <div className={styles.limitSwitch}>
      <div className={styles.limitSwitch_item}>
        <p className={styles.limitSwitchItem_title}>{title}</p>
        <div
          onClick={disabled ? undefined : onToggle}
          className="cursor-pointer"
        >
          <img src={enabled ? On : Off} />
        </div>
      </div>

      {isLoading && <BeatLoader size={10} color="#939598" />}

      {enabled && (
        <FormControl fullWidth>
          <OutlinedInput
            type="number"
            onChange={onChange}
            value={value}
            disabled={disabled}
            maxRows={7}
          />
        </FormControl>
      )}
    </div>
  );
};

export default LimitSwitchItem;
