import { FC } from "react";
import styles from "./style.module.scss";
import { Box, LinearProgress, styled } from "@mui/material";
import helper from "services/helper";

interface CategoryProccentItemProps {
  category: string;
  color: string;
  percentage: number;
  img: any;
  amount: number;
}
const CategoryProccentItem: FC<CategoryProccentItemProps> = ({
  category,
  color,
  percentage,
  img,
  amount,
}) => {
  return (
    <div className={styles.container}>
      {img && <img src={img} />}
      <div className={styles.container_progressBar}>
        <span className={styles.container_category}>{category}</span>
        <Box sx={{ width: "100%" }}>
          <LinearProgressComponent
            sx={{
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "2px",
              "& .MuiLinearProgress-bar": { backgroundColor: color },
            }}
            variant="determinate"
            value={percentage}
          />
        </Box>
        <span className={styles.container_proccenet}>%{percentage}</span>
      </div>

      <div>{`$${helper.formatUsdValue(amount)}`}</div>
    </div>
  );
};

export default CategoryProccentItem;

const LinearProgressComponent = styled(LinearProgress)`
  /* background-color: rgba(0, 0, 0, 1); */
  /* & .MuiLinearProgress-bar {
    background-color: color;
  } */
`;
