import React, { useState } from 'react';
import styles from './style.module.scss';
import {
  useDeleteLinkedAccountControllerMutation,
  useDeleteLinkedAccountMeControllerMutation,
  usePendingLinkedAccountControllerQuery,
  useRequestLinkedAccountControllerMutation,
  useAcceptLinkedAccountControllerMutation,
  LinkedAccountApi,
  LinkedAccountMeApi,
  PendingLinkedAccountApi,
} from 'api/endpoints/linkedAccount';
import cx from 'classnames';
import helper from 'services/helper';
import { useCurrentUser } from 'providers/user.provider';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useSearchParams } from 'react-router-dom';
import { useConfirmDialog } from 'providers/confirm.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import { useLoginManagement } from 'shared/hooks/useLoginManagement';
import LoginButtonGroup from '../loginButtonGroup';
import ConnectedAccountList from '../ConnectedAccountList';
import { AnimateWrapper } from 'components/animate-wrapper';
import {
  CONNECTED_ACCOUNT,
  CONNECTE_ACCOUNT,
  REQUEST_ACCESS,
} from '../accountSettings/searchParamsTypes';
import { ConnectedAccountRequestAccess } from '../ConnectedAccountRequestAccess';
import DrawerModal from 'shared/components/drawerModal';
import { Typography } from 'shared/ui/typography';
import { useMediaQuery } from '@mui/material';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { SecureOperationType, ShortMessageType } from 'enums';
import { useMfa } from 'providers/mfa/mfa';
import { BackToMyAccount } from '../../../components/backToMyAccount';

export type AccountsItem =
  | LinkedAccountApi
  | LinkedAccountMeApi
  | PendingLinkedAccountApi;

const ConnectedAccount = () => {
  const confirm = useConfirmDialog();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || ' ';
  const pageMode = searchParams.get('pageMode');
  const mode = searchParams.get('mode');
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const { user } = useCurrentUser();

  const mfa = useMfa();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const {
    isUserLinkedAccountsLoading,
    userLinkedAccounts,
    userConnectedAccounts,
    isUserConnectedAccountsLoading,
    connectedAccountsToShow,
    ...connectedAccount
  } = useLoginManagement();

  const {
    data: getPendingConnectedAccounts,
    isFetching: getPendingConnectedAccountsIsLoading,
  } = usePendingLinkedAccountControllerQuery({});

  const [deleteLinkedAccountMutation] =
    useDeleteLinkedAccountControllerMutation();
  const [deleteLinkedAccountMeMutation] =
    useDeleteLinkedAccountMeControllerMutation();

  const [
    requestLinkedAccountMutation,
    { isLoading: requestLinkedAccountIsLoadind },
  ] = useRequestLinkedAccountControllerMutation();

  const [
    acceptLinkedAccountMutation,
    { isLoading: acceptLinkedAccountIsLoadind },
  ] = useAcceptLinkedAccountControllerMutation();

  const [accontNameTo, setAccounNameTo] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);

  const sendLinkedAccountTo = async () => {
    try {
      await requestLinkedAccountMutation({
        userName: accontNameTo,
      }).unwrap();
      setAccounNameTo('');
      setSnackBar({
        type: 'success',
        message: `${t('Settings.Request sent', {
          defaultValue: 'Request sent',
        })}.
         ${t('Settings.Once your request is approved, you will be notified', {
           defaultValue: 'Once your request is approved, you will be notified.',
         })}`,
        isShow: true,
      });
      setSearchParams({
        page: CONNECTED_ACCOUNT,
        title: CONNECTE_ACCOUNT,
      });
    } catch (e: any) {
      console.log('error', e);
      setErrors(e);
    }
  };

  const findDbaById = (
    id: number,
    accountsArray: AccountsItem[] | undefined
  ) => {
    return accountsArray?.find((user) => user.id === id)?.dba;
  };

  const handleDeleteConnectedAccount = async (id: number) => {
    const userDBA = findDbaById(id, userConnectedAccounts);

    const findWithStatusPending = userConnectedAccounts?.find(
      (account) => account.id === id && account.status === 'PENDING'
    );

    confirm.show({
      applyButtonText: 'Remove',
      applyError: true,
      cancelButtonText: t('common.Cancel', {
        defaultValue: 'Cancel',
      }),
      dialogText: `Are you sure you want to cancel the connection to this account?`,
      cancelButtonType: 'text',
      typeNew: true,
      async onApply() {
        try {
          await deleteLinkedAccountMutation({ userId: id }).unwrap();
          setSnackBar({
            type: 'success',
            message: `${t('Settings.Request denied', {
              defaultValue: 'Request denied',
            })}. ${
              findWithStatusPending
                ? `Your request has been cancelled`
                : `Access to the account “${userDBA}” has been rejected`
            }`,
            isShow: true,
          });
        } catch (e: any) {
          console.log('error', e);
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  const handleDeleteConnectedAccountToMe = async (id: number) => {
    const accountMeDBA = findDbaById(id, userLinkedAccounts);
    const accountPendingDBA = findDbaById(id, getPendingConnectedAccounts);
    const userDBA = accountMeDBA || accountPendingDBA;

    confirm.show({
      applyButtonText: 'Remove',
      applyError: true,
      cancelButtonText: t('common.Cancel', {
        defaultValue: 'Cancel',
      }),
      dialogText:
        'Are you sure you want to revoke access to your account for this client?',
      cancelButtonType: 'text',
      typeNew: true,
      async onApply() {
        try {
          await deleteLinkedAccountMeMutation({ userId: id }).unwrap();
          setSnackBar({
            type: 'success',
            message: `${t('Settings.Request denied', {
              defaultValue: 'Request denied',
            })}. Request of “${userDBA}” to get access to your account was denied.`,
            isShow: true,
          });
        } catch (e: any) {
          console.log('error', e);
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  const handleRequestAccess = () => {
    setSearchParams({
      page,
      pageMode: pageMode || CONNECTE_ACCOUNT,
      mode: REQUEST_ACCESS,
      title: t('common.Request access', {
        defaultValue: 'Request access',
      }),
    });
    setErrors(null);
  };

  const handleRequestCansel = () => {
    setSearchParams({
      page,
      pageMode: CONNECTE_ACCOUNT,
    });
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccounNameTo(event.target.value);
    setErrors(null);
  };

  const approvePendingAccount = async (
    code: string,
    operationType: SecureOperationType,
    userId: number
  ) => {
    try {
      const userDBA = findDbaById(userId, getPendingConnectedAccounts);
      await acceptLinkedAccountMutation({
        userId: userId,
        oneTimeCode: code,
        secureOperationType: operationType,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: `${t('common.Request approved', {
          defaultValue: 'Request approved',
        })}. The access request for ${userDBA} has been approved successfully.`,
        isShow: true,
      });
      mfa.close();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleAddConnectedAccount = (id: number) => {
    mfa.show({
      title: 'Confirm  Request',
      shortMessageType: ShortMessageType.ApproveLinkedAccount,
      isOnlySmsFlow: true,
      async onCodeSubmit(
        secureOperationType: SecureOperationType,
        code: string
      ) {
        await approvePendingAccount(code, secureOperationType, id);
      },
    });
  };

  const filteredArr = (arr: LinkedAccountApi[] | undefined, status: string) => {
    return arr?.filter((account) => account?.status === status);
  };

  const pendingMyRequestBoolean = !!filteredArr(
    userConnectedAccounts,
    'PENDING'
  )?.length;
  const pendingFromOtherBoolean = !!getPendingConnectedAccounts?.length;

  return (
    <AnimateWrapper className='fade'>
      {user && !user.isOriginalUser && (
        <div className={styles.backToMyWrapper}>
          <BackToMyAccount />
        </div>
      )}
      <div className={styles.wrapper}>
        {isMobile && (
          <div className={styles.wrapper_nav}>
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
            <Typography className={styles.wrapper_nav_title}>
              Connected accounts
            </Typography>
          </div>
        )}

        {page === CONNECTED_ACCOUNT && (
          <div className={styles.container}>
            <div>
              <div className={styles.box}>
                <Typography className={styles.box_title}>
                  {t('common.Connected accounts', {
                    defaultValue: 'Connected accounts',
                  })}
                  :
                </Typography>
                <LoginButtonGroup
                  isOriginalUser={!!user?.isOriginalUser}
                  handleRequestAccess={handleRequestAccess}
                  handleReturToYourLogin={connectedAccount.backToMyAccount}
                />
              </div>
              <ConnectedAccountList
                arr={connectedAccountsToShow || []}
                isLoading={isUserConnectedAccountsLoading}
                handleDelete={handleDeleteConnectedAccount}
                handleSumbit={connectedAccount.loginAsAccount}
                approveButtonText={t('common.Select', {
                  defaultValue: 'Select',
                })}
                emptyArrText={t('Settings.No one has granted access to you', {
                  defaultValue: 'No one has granted access to you',
                })}
              />
            </div>
            {pendingMyRequestBoolean && (
              <div>
                <Typography className={styles.box_title}>
                  {t('common.Your requests', {
                    defaultValue: 'Your requests',
                  })}
                  :
                </Typography>
                <ConnectedAccountList
                  arr={filteredArr(userConnectedAccounts, 'PENDING') || []}
                  isLoading={isUserConnectedAccountsLoading}
                  handleDelete={handleDeleteConnectedAccount}
                  handleSumbit={handleAddConnectedAccount}
                  cancelButtonText={t('common.Cancel', {
                    defaultValue: 'Cancel',
                  })}
                />
              </div>
            )}
            <div>
              <Typography className={styles.box_title}>
                {t('Settings.Who has access to my account', {
                  defaultValue: 'Who has access to my account',
                })}
                :
              </Typography>
              <ConnectedAccountList
                arr={userLinkedAccounts || []}
                isLoading={isUserLinkedAccountsLoading}
                handleDelete={handleDeleteConnectedAccountToMe}
                cancelButtonText={t('common.Disconnect', {
                  defaultValue: 'Disconnect',
                })}
                cancelButtonType={'outlined'}
                emptyArrText={t('Settings.You do not grant access', {
                  defaultValue: 'You do not grant access to your account',
                })}
              />
            </div>
            {pendingFromOtherBoolean && (
              <div>
                <div className={cx(styles.box, styles.pending_box)}>
                  <Typography className={styles.box_title}>
                    {t('Settings.Pending access requests', {
                      defaultValue: 'Pending access requests',
                    })}
                    :
                  </Typography>
                </div>
                <ConnectedAccountList
                  arr={getPendingConnectedAccounts || []}
                  isLoading={getPendingConnectedAccountsIsLoading}
                  handleDelete={handleDeleteConnectedAccountToMe}
                  handleSumbit={handleAddConnectedAccount}
                  approveButtonText={t('common.Accept', {
                    defaultValue: 'Accept',
                  })}
                  cancelButtonText={t('common.Deny', {
                    defaultValue: 'Deny',
                  })}
                  buttonAppropveContained={true}
                />
              </div>
            )}
          </div>
        )}
        <DrawerModal
          isShow={mode === REQUEST_ACCESS}
          onClose={() => handleRequestCansel()}
          titleText={'Request access'}
        >
          <ConnectedAccountRequestAccess
            onCancel={handleRequestCansel}
            onChange={handleChangeInput}
            valueInput={accontNameTo}
            onSumbit={sendLinkedAccountTo}
            onSumbitLoading={requestLinkedAccountIsLoadind}
            isError={errors}
          />
        </DrawerModal>
      </div>
    </AnimateWrapper>
  );
};
export default ConnectedAccount;
