import { FC, useCallback, useState } from "react";
import SelectTransferPointItem from "../selectTransferPointItem";
import { ClientNameApi } from "api/endpoints/search";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import { AnimateWrapper } from "components/animate-wrapper";
import arrowUpIcon from "assets/svg/arrow-up-blue.svg";
import arrowDownIcon from "assets/svg/arrow-down-blue.svg";
import { Skeleton } from "components/skeleton";
interface RecentTransfersListProps {
  dataArr: ClientNameApi[];
  title: string;
  handleChooseAcc: (id: number) => void;
  selectItem: ClientNameApi | undefined;
  isLoading: boolean;
  recentTransfers?: boolean;
}

const RecentTransfersList: FC<RecentTransfersListProps> = ({
  dataArr,
  title,
  handleChooseAcc,
  selectItem,
  isLoading,
}) => {
  const [toggleShow, setToggleShow] = useState<boolean>(false);

  const checkSelectItem = useCallback(
    (id: number) => selectItem?.profileId === id,
    [selectItem]
  );

  const renderItems = (dataArr: ClientNameApi[]) => {
    const itemsToRenderRecentTransfers = toggleShow
      ? dataArr
      : dataArr.slice(0, 2);

    return itemsToRenderRecentTransfers?.map((i, index) => (
      <AnimateWrapper className="fade" key={index}>
        <SelectTransferPointItem
          id={i.profileId}
          name={i.doingBusinessName}
          phone={i.businessPhoneNumber}
          handleChooseAcc={handleChooseAcc}
          checked={checkSelectItem(i.profileId)}
          selectItem={selectItem}
        />
      </AnimateWrapper>
    ));
  };
  return isLoading ? (
    <Skeleton width="100%" height="90px" />
  ) : (
    <div className={styles.list}>
      <span className={styles.title}>{title}</span>
      {renderItems(dataArr)}

      {dataArr?.length > 2 && (
        <div className={styles.box}>
          <Button
            variant="text"
            onClick={() => setToggleShow(!toggleShow)}
            className={styles.box_button}
          >
            <span>{toggleShow ? "Hide" : "Expand"}</span>
            <img
              src={toggleShow ? arrowUpIcon : arrowDownIcon}
              alt="Expand"
              width={18}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecentTransfersList;
