import { RadioGroup } from '@mui/material'
import { Radio } from 'shared/ui/inputs'
import { Typography } from 'shared/ui/typography'
import {
  DELIVERY_SERVICES,
  DELIVERY_SERVICE_ICONS,
} from '../../config/constants'

import styles from './styles.module.scss'

interface DeliveryServiceProps {
  value: null | string
  onChange: (value: string) => void
}

export const DeliveryService = (props: DeliveryServiceProps) => {
  const { value, onChange } = props

  return (
    <div className={styles.root}>
      <Typography variant='subtitle1'>Choose delivery service</Typography>

      <div className={styles.services}>
        <div className={styles.service}>
          <div className={styles.containerLogo}>
            <Typography variant='body2'>
              {DELIVERY_SERVICE_ICONS.USPS.title}
            </Typography>
            <DELIVERY_SERVICE_ICONS.USPS.icon className={styles.uspsIcon} />
          </div>

          <RadioGroup
            value={value}
            onChange={(_, value) => onChange(value)}
            name='USPS'
            className={styles.containerItems}
          >
            {DELIVERY_SERVICES.USPS.map((item) => (
              <div
                key={item.id}
                className={styles.item}
              >
                <Radio value={item.id as string} />
                <Typography
                  variant='body2'
                  textAlign='center'
                >
                  {item.name}
                </Typography>
                <Typography
                  variant='caption'
                  textAlign='center'
                >
                  {item.description}
                </Typography>
                <Typography
                  variant='caption'
                  textAlign='center'
                >
                  {item.price}
                </Typography>
              </div>
            ))}
          </RadioGroup>
        </div>

        <div className={styles.service}>
          <div className={styles.containerLogo}>
            <Typography variant='body2'>
              {DELIVERY_SERVICE_ICONS.UPS.title}
            </Typography>
            <DELIVERY_SERVICE_ICONS.UPS.icon className={styles.upsIcon} />
          </div>

          <RadioGroup
            value={value}
            onChange={(_, value) => onChange(value)}
            name='UPS'
            className={styles.containerItems}
          >
            {DELIVERY_SERVICES.UPS.map((item) => (
              <div
                key={item.id}
                className={styles.item}
              >
                <Radio value={item.id as string} />
                <Typography
                  variant='body2'
                  textAlign='center'
                >
                  {item.name}
                </Typography>
                <Typography
                  variant='caption'
                  textAlign='center'
                >
                  {item.description}
                </Typography>
                <Typography
                  variant='caption'
                  textAlign='center'
                >
                  {item.price}
                </Typography>
              </div>
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  )
}
