export const findObjById = (id: string, arr) => {
  const foundItem = arr?.find(
    (item) => item.financialAccountId === id || item.id === id
  )

  return {
    id: foundItem?.financialAccountId || foundItem?.id,
    name: foundItem?.name || '',
    last4Number: foundItem?.accountNumber?.slice(-4) || foundItem?.last4,
    availableCash: foundItem?.availableCash,
  }
}
