import { FC } from "react";
import { Popup } from "components/popup";
import { Typography, Button, css, styled } from "@mui/material";
import styles from "./styles.module.scss";

interface PopupInstructionsProps {
  onClose(): void;
}

const PopupInstructions: FC<PopupInstructionsProps> = ({ onClose }) => {
  return (
    <Popup onClose={onClose} stylesWrapper={styles.popup}>
      <Wrapper>
        <Title>Authenticator Setup Instructions</Title>
        <Box>
          <SubTitle>
            1. Download a TOTP-based authenticator app (if not installed).
          </SubTitle>
          <SubTitle>
            2. Open the app and choose `Add Account` or Scan QR Code.
          </SubTitle>
          <SubTitle>3. Scan the QR code below to link your account.</SubTitle>
          <SubTitle>
            4. Your app will generate a 6-digit security code every 30 seconds.
          </SubTitle>
        </Box>
        <BoxButton>
          <Button
            onClick={onClose}
            variant="text"
            sx={{
              width: "fit-content",
              color: "#187AC9",
            }}
          >
            got it
          </Button>
        </BoxButton>
      </Wrapper>
    </Popup>
  );
};
export default PopupInstructions;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Title = styled(Typography)`
  font-family: "Noto Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
`;
const Box = styled("div")`
  display: flex;
  flex-direction: column;
`;

const BoxButton = styled("div")`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const SubTitle = styled(Typography)`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;
