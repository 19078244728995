import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { requireNotNull } from '../../../../shared/utils/require-not-null';
import { usePermission } from 'shared/hooks/usePermission';
import { useSearchParams } from 'react-router-dom';
import { AccountBalanceMode } from 'pages/home/enums';
import { BANK_TAB } from 'pages/home/constants';
import { useIsOthereBankTab } from 'pages/home/hooks';
interface ContextProps {
  mode: AccountBalanceMode; // 'Netevia accounts' | 'External accounts';
  changeMode(newMode: AccountBalanceMode): void;
  categories: AccountBalanceMode[];
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const AccountBalanceProvider = ({ children }: PropsWithChildren) => {
  const { otherBanksPermision } = usePermission();
  const isOtherBankTab  = useIsOthereBankTab()
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState<AccountBalanceMode>(
    AccountBalanceMode.neteviaAccounts
  );

  const bankTab = searchParams.get(BANK_TAB) || '';

  const value = useMemo((): ContextProps => {
    const categories = otherBanksPermision
      ? [
          AccountBalanceMode.neteviaAccounts,
          AccountBalanceMode.externalAccounts,
        ]
      : [AccountBalanceMode.neteviaAccounts];

    if (!bankTab) {
      setMode(AccountBalanceMode.neteviaAccounts);
    }
    if (isOtherBankTab) {
      setMode(AccountBalanceMode.externalAccounts);
    }
   
    return {
      mode,
      changeMode(newMode: AccountBalanceMode) {
        setMode(newMode);
      },
      categories,
    };
  }, [mode, otherBanksPermision, bankTab]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAccountBalance = () => requireNotNull(useContext(Context));
