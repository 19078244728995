import React from "react";
import styles from "./style.module.scss";
import Square from "assets/svg/radioOff.svg";
import NewCheckSquare from "assets/svg/radioOn.svg";

interface RadiogroupProps {
  onChange: (value?: any) => void;
  iteams: { label: string; description?: string; value: any }[];
  currentValue: any;
}

const Radiogroup: React.FC<RadiogroupProps> = ({
  onChange,
  iteams,
  currentValue,
}: RadiogroupProps) => {
  return (
    <div className={styles.wrap}>
      {iteams.map((item) => (
        <div
          key={item.value}
          className={styles.container}
          onClick={() => onChange(item.value)}
        >
          <div className={styles.content}>
            <img
              className={styles.wrapperAddress_img}
              src={item.value === currentValue ? NewCheckSquare : Square}
            />
            <div className="flex flex-col justify-start">
              <span>{item.label}</span>
              {item.description && (
                <span className={styles.content_description}>
                  {item.description}
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Radiogroup;
