import { useNavigate } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";

const Consumer = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  return (
    <div className={styles.consumerBannerContainer}>
      <div className={styles.consumerBannerTitle}>
        {t("Login.Exclusive to Netevia Mobile App", {
          defaultValue: "Exclusive to Netevia Mobile App",
        })}
      </div>
      <div className={styles.consumerBannerText}>
        {t("Login.Login.We re sorry, but the", {
          defaultValue:
            "We're sorry, but the 'Personal' login is exclusively available on the Netevia mobile app for now. Currently, we only support 'Business' login on the desktop. Download the Netevia app now to access your 'Personal' account.",
        })}
      </div>
      <div
        onClick={() => navigate("/login")}
        className={styles.consumerBannerNavigate}
      >
        {t("Login.Log in with business account", {
          defaultValue: "Log in with business account",
        })}
        {">"}
      </div>
    </div>
  );
};

export default Consumer;
