import React, { FC } from "react";
import { DevicesItem } from "../devicesItem";
import moment from "moment";
import { Skeleton } from "@mui/material";
import styles from "./style.module.scss";
import { List, Divider } from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";
interface DeviceListProps {
  isLoading: boolean;
  devicesArr: any[] | undefined;
  removeDevice: (id: string) => void;
}

const DeviceList: FC<DeviceListProps> = ({
  devicesArr,
  isLoading,
  removeDevice,
}) => {
  const { t } = useTranslationProvider();
  const renderItems = () => {
    return devicesArr?.map((item, index) => (
      <div key={index}>
        <DevicesItem
          key={item.deviceId}
          id={item.deviceId}
          name={item.deviceName}
          date={moment(item.creationDate).format("MM/DD/YY, hh:mm")}
          handleDelete={removeDevice}
        />
        {index < devicesArr.length - 1 && <Divider />}
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      <span className={styles.container_title}>
        {t("Settings.Other Accounts", {
          defaultValue: "Other Accounts",
        })}
      </span>
      {isLoading ? (
        <Skeleton width="100%" height={125} />
      ) : (
        <List disablePadding className={styles.listWrapper}>
          {renderItems()}
        </List>
      )}
    </div>
  );
};
export default DeviceList;
