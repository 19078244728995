import SimpleContainer from 'shared/components/containers/simpleContainer';
import { CardManage } from './CardManage';
import { CardManageProvider } from './provider';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../routes';
import { useCardRouteParams } from '../../shared/hooks/useCardRouteParams';

export const CardManageComponent = () => {
  const navigate = useNavigate();
  const { cardId, accountId } = useCardRouteParams();
  const handleNavigateClick = () => {
    navigate(appRoutes.cardById(accountId, cardId));
  };
  return (
    <SimpleContainer title={'Manage card'} onTitleClick={handleNavigateClick}>
      <CardManageProvider>
        <CardManage/>
      </CardManageProvider>
    </SimpleContainer>
  );
};
