import { useEffect, useRef, useState } from 'react'
import { TextField, FormHelperText } from '@mui/material'
import useGooglePlaceAutoCompleteV3 from './useGooglePlaceAutoCompleteV3'
import React from 'react';

export interface GoogleAdress {
  address1: any;
  locality: any;
  adminArea1Short: any;
  adminArea1Long: any;
  postalCode: string;
  countryShort: any;
  countryLong: any;
}

interface MyOldAutocompleteProps {
  address?: string
  label: string
  onAddressChange?: (address: string) => void
  setAddressFull: (addressFull: GoogleAdress) => void
  error?: string
  startAdornment?: React.ReactNode;
}

function MyAutocomplete_V3({
  address,
  label,
  onAddressChange,
  setAddressFull,
  error,
  startAdornment,
}: MyOldAutocompleteProps) {
  const address1Ref = useRef()
  const [formattedAddress, setFormattedAddress] = useState(address ?? '');
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteV3()
  const autoComplete = useRef<null | google.maps.places.Autocomplete>()

  useEffect(() => {
    async function loadGoogleMaps() {
      autoComplete.current = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      )
    }
    loadGoogleMaps()
  }, [])

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(
      autoComplete.current
    )

    setFormattedAddress(addressObj.formattedAddress);
    setAddressFull(addressObj)
  }

  const handleAddressChnage = (address: string) => {
    setFormattedAddress(address);
    onAddressChange?.(address)
  }

  return (
    <>
      <div>
        <TextField
          id='outlined-required'
          type='text'
          label={label}
          inputRef={address1Ref}
          fullWidth
          value={formattedAddress}
          InputProps={{
            startAdornment,
          }}
          onChange={(e: any) => {
            handleAddressChnage(e.target.value)
          }}
        />
        {error && <FormHelperText error={true}>{error}</FormHelperText>}
      </div>
    </>
  )
}

export default MyAutocomplete_V3
