import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import arrowIcon from "assets/svg/arrow-rigth-blue.svg";
import { useNavigate } from "react-router-dom";
import { useRewards } from "pages/reward/provider/useRewards";
import RecentPointsItem from "./components/recentPointsItem";
import moment from "moment";
import { Divider } from "@mui/material";
import { Skeleton } from "components/skeleton";
import helper from "services/helper";
import { useTranslationProvider } from "providers/translation/translation.provider";
import EmptyRecentPoits from "./components/emptyRecentPoits";
import { appRoutes,RewardsRouteSubRoute } from "routes";

const RecentTransactions = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate()
  const { recentTransactions5, recentTransactionsIsLoading } = useRewards();

  const handleNavigate = () => {
    navigate(appRoutes.rewards(RewardsRouteSubRoute.history))
  };

  const renderItems = () => {
    if (!!recentTransactions5?.length) {
      return recentTransactions5.map((el, index) => (
        <div key={index}>
          <RecentPointsItem
            title={el.typeName}
            amount={helper.moneyFormat(el.amount)}
            points={el.points}
            period={moment(el.publishDate).format("MM.DD.YYYY, hh:mm A")}
            type={el.type}
            subjectName={el.subjectName}
            typeName={el.typeName}
          />
          {index < recentTransactions5.length - 1 && <Divider />}
        </div>
      ));
    } else {
      return <EmptyRecentPoits />;
    }
  };

  return recentTransactionsIsLoading ? (
    <Skeleton width="100%" height="194px" />
  ) : (
    <div className={styles.container}>
      <div className={styles.box}>
        <span>Recent transactions</span>
        <Button className={styles.box_button} onClick={handleNavigate}>
          <span>
            {t("seeAll", {
              defaultValue: "See all",
            })}
          </span>
          <img src={arrowIcon} alt="Arrow Rigth" />
        </Button>
      </div>
      {renderItems()}
    </div>
  );
};

export default RecentTransactions;
