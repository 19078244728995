import { ReactNode } from 'react'
import { Popup } from 'components/popup'
import styles from './styles.module.scss'

interface IDialogProps {
  open: boolean
  onApply(): void
  onCancel(): void
  onClose(): void
  dialogText?: string | ReactNode
  dialogTitle?: string | ReactNode
  slotApplyButton?: ReactNode
  slotCancelButton?: ReactNode
}
export const Dialog = ({
  open,
  onClose,
  onApply,
  onCancel,
  dialogTitle,
  dialogText,
  slotApplyButton,
  slotCancelButton,
}: IDialogProps) => {
  const handleApply = () => {
    if (onApply) {
      onApply()
    }
    onClose()
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
    onClose()
  }
  if (open) {
    return (
      <Popup
        onClose={onClose}
        stylesWrapper={styles.popup}
      >
        <div className={styles.content}>
          {dialogTitle && (
            <div className={styles.dialogTitle}>{dialogTitle}</div>
          )}

          {dialogText && <div className={styles.dialogText}>{dialogText}</div>}
          <div className={styles.controls}>
            {slotCancelButton && (
              <div onClick={handleCancel}>{slotCancelButton}</div>
            )}
            {slotApplyButton && (
              <div onClick={handleApply}>{slotApplyButton}</div>
            )}
          </div>
        </div>
      </Popup>
    )
  }

  return null
}
