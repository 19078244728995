import {
  TableBody as TableBodyMUI,
  TableRow as TableRowMUI,
  TableCell as TableCellMUI,
} from '@mui/material'
import styles from './styles.module.scss'
import { EmptyPayees } from 'entities/transfers'
import { ClockRestoreIcon, GiftCardIcon } from 'shared/ui/icons'
import { TableBodyList } from '../tableBodyList'
import {
  GiftCardData,
  TypeGiftCards,
} from 'pages/reward/components/giftCard/types'
import { emptyCards } from '../..'
interface TableBodyProps {
  data: GiftCardData[]
  typeTable: TypeGiftCards
}

export const TableBody = ({ data, typeTable }: TableBodyProps) => {
  const renderTable = () => {
    if (!data.length) {
      return (
        <EmptyPayees
          icon={emptyCards[typeTable].icon}
          title={emptyCards[typeTable].title}
          subTitle={emptyCards[typeTable].subTitle}
          stylesWrapper={styles.empty}
        />
      )
    }
    return data.map((item, index) => (
      <TableBodyList
        key={item.id}
        item={item}
        index={index}
        typeTable={typeTable}
        lastIndex={data.length - 1}
      />
    ))
  }
  return <TableBodyMUI>{renderTable()}</TableBodyMUI>
}
