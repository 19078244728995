import styles from './style.module.scss';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import {
  PersonalSignUpScreen,
  SignUpPersonalBaseInfoForm,
  useSignUpPersonal,
} from '../provider';
import { Button } from '../../../shared/ui/buttons';
import { useFormik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { SignUpAddressForm } from '../../../shared/signUp/addressForm';
import {
  signUpAddressValidateSchema,
  signUpPersonValidationSchema,
} from '../../../shared/signUp/shared';
import { SignUpPersonForm } from '../../../shared/signUp/personForm';
import { useScrollToMuiFieldAfterError } from '../../../shared/hooks/useScrollToMuiFieldAfterError';

const validateSchema = signUpPersonValidationSchema.concat(
  signUpAddressValidateSchema
);

export const PersonalSignUpBaseInfo = () => {
  const { setScreen, contextForm, updateContextForm } = useSignUpPersonal();

  const formik = useFormik<SignUpPersonalBaseInfoForm>({
    validationSchema: validateSchema,
    validateOnChange: false,
    initialValues: {
      givenName: contextForm.givenName,
      middleName: contextForm.middleName,
      familyName: contextForm.familyName,
      dateOfBirth: contextForm.dateOfBirth,
      email: contextForm.email,
      phone: contextForm.phone,
      streetAddress: contextForm.streetAddress,
      extendedAddress: contextForm.extendedAddress,
      locality: contextForm.locality,
      state: contextForm.state,
      postalCode: contextForm.postalCode,
      ssn: contextForm.ssn,
    },
    onSubmit(values) {
      updateContextForm(values);
      setScreen(PersonalSignUpScreen.confirm);
    },
  })

  useScrollToMuiFieldAfterError(formik.isSubmitting);

  return (
    <SignUpFormsLayout isConsumerMode>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Welcome to Netevia! Let&apos;s get started.
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Let&apos;s tailor your Netevia experience! Share a few details with
          us—it&apos;s quick and easy.
        </SignUpFormsLayout.Subtitle>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.formWrapper}
        >
          <SignUpFormsLayout.SectionTitle>
            Personal info
          </SignUpFormsLayout.SectionTitle>
          <SignUpPersonForm formikInstance={formik} />

          <SignUpFormsLayout.SectionTitle>
            Address
          </SignUpFormsLayout.SectionTitle>
          <SignUpAddressForm formikInstance={formik} />

          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: styles.checkedCheck,
                }}
                checked={contextForm.isAgreementAccepted}
                onChange={(_: any, checked) => {
                  updateContextForm({
                    isAgreementAccepted: checked,
                  });
                }}
                name='isAgreementAccepted'
              />
            }
            label={
              <div>
                I have read and agree to the
                <br />
                <a
                  className={styles.link}
                  href='/consumer-banking-clients.pdf'
                  target="_blank"
                >
                  Card Holder Agreement
                </a>
              </div>
            }
          />

          <SignUpFormsLayout.FormControls>
            <Button
              onClick={() => setScreen(PersonalSignUpScreen.loginAndPass)}
              type='button'
              variant='text'
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={
                !contextForm.isAgreementAccepted
              }
            >
              Next
            </Button>
          </SignUpFormsLayout.FormControls>
        </form>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
