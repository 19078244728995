import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import { ListButton } from 'shared/ui/lists'
import styles from './styles.module.scss'
import { ExternalPayee } from 'entities/transfers'
import { useAppDispatch } from 'shared/models'
import { setSelectPayeeId } from '../../model'
import moment from 'moment'
import { EmptyPayees } from 'entities/transfers'
import { PersonGearIcon } from 'shared/ui/icons'
import { TransitionAnimation } from 'shared/ui/animations'
import { Skeleton } from 'components/skeleton'
import { Button } from 'shared/ui/buttons'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'

export const ManagePayee = () => {
  const dispatch = useAppDispatch()
  const { goNext } = useDrawerStepperContext()
  const { externalPayees, isLoadingPayees, isFetchingPayees } =
    useExternalPayees()

  const handleNavigateToDetail = (id: string) => {
    dispatch(
      setSelectPayeeId({
        value: id,
      })
    )
    goNext('9')
  }
  const renderPayee = () => {
    return !!externalPayees?.length ? (
      <div className={styles.listWrapper}>
        <ListButton className={styles.list}>
          {externalPayees.map((payee) => (
            <ExternalPayee
              key={payee.id}
              id={payee.id}
              name={payee.name}
              type={payee.type}
              last4={payee.last4}
              createdOn={moment(payee.createdAt).format('MM/DD/YYYY')}
              handleNavigate={handleNavigateToDetail}
            />
          ))}
        </ListButton>
      </div>
    ) : (
      <EmptyPayees
        title={'No added payees'}
        subTitle={'External payees will be displayed here.'}
        icon={<PersonGearIcon />}
      />
    )
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        {isLoadingPayees || isFetchingPayees ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <>
            {renderPayee()}
            <Button
              className={styles.btn}
              onClick={() => goNext('10')}
            >
              add new payee
            </Button>
          </>
        )}
      </div>
    </TransitionAnimation>
  )
}
