import { AccountProvider } from "./provider";
import AccountSettings from "./accountSettings";

const Account = () => {
  return (
    <AccountProvider>
      <AccountSettings />
    </AccountProvider>
  );
};
export default Account;
