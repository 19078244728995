import { FC, useState } from "react";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
import helper from "services/helper";
import LoginInputAutoFillAware from "../loginInputAutoFillAware";
import { FormControl } from "@mui/material";

interface GroupingInputProps {
  username: string;
  pass: string;
  setUsername: (string) => void;
  setPass: (string) => void;
  error: any;
  setError: () => void;
  handleSubmit: (username: string, password: string) => void;
}

const GroupingInput: FC<GroupingInputProps> = ({
  username,
  pass,
  setUsername,
  setPass,
  error,
  setError,
  handleSubmit,
}) => {
  const { t } = useTranslationProvider();
  const [showPass, setShowPass] = useState<boolean>(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!!username && !!pass && !error) {
        handleSubmit(username, pass);
      }
    }
  };

  return (
    <FormControl
      sx={{ backgroundColor: "transparent", padding: 0 }}
      variant="filled"
      fullWidth
      onKeyDown={handleKeyDown}
      className={styles.wrapperInput}
    >
      <LoginInputAutoFillAware
        label={t("Login.Username", {
          defaultValue: "Username",
        })}
        type="text"
        value={username}
        setValue={setUsername}
        error={!!error}
        setError={setError}
        inputProps={{
          maxLength: 50,
        }}
        modeInput="username"
      />
      <div>
        <LoginInputAutoFillAware
          label={t("Login.Password", {
            defaultValue: "Password",
          })}
          type="text"
          value={pass}
          setValue={setPass}
          isShow={showPass}
          setShow={setShowPass}
          error={!!error}
          setError={setError}
          modeInput="password"
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </FormControl>
  );
};
export default GroupingInput;
