import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";

export interface AuthLoginArg {
  nickname: string;
  password: string;
  tokenDevice: string;
  nameDevice: string;
  reCaptchaResponse?: string;
}

interface AuthLoginResponse {
  expiration: string;
  token: string;
  userId: number;
}

export interface AuthLoginErrorCaptcha {
  needReCaptcha: boolean;
  siteKey: string;
}

interface ForgotLoginArg {
  email: string;
}

interface RestoreLoginArg {
  email: string;
  code: string;
}

interface Login2FacArg {
  username: string;
  password: string;
  ticket: string;
  code: string;
  remember30day: boolean;
}

interface Login2FacResults {
  token: string;
  userId: string;
  expiration: string; // date
}
interface ValidateUnlockOtpArg {
  nickName: string;
  code: string;
}
interface SendUnlockOtpArg {
  nickName: string;
}
interface GetAuthConnectedAccountApi {
  token: string;
  expiration: Date;
  userId: number;
}
interface GetAuthConnectedAccountArg {
  linkedUserId: number;
}
export const authApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "auth",
  endpoints: (builder) => ({
    useAuthControllerLogin: builder.mutation<AuthLoginResponse, AuthLoginArg>({
      query: (queryArg) => ({
        url: "api/auth/v2",
        method: "POST",
        body: queryArg,
      }),
    }),
    forgotLoginController: builder.mutation<any, ForgotLoginArg>({
      query(queryArg) {
        return {
          url: "v2/forgotLogin",
          method: "POST",
          body: queryArg,
        };
      },
    }),
    restoreLoginController: builder.mutation<any, RestoreLoginArg>({
      query(queryArg) {
        return {
          url: "v2/restoreLogin",
          method: "POST",
          body: queryArg,
        };
      },
    }),
    login2FacController: builder.mutation<Login2FacResults, Login2FacArg>({
      query(queryArg) {
        return {
          url: "api/auth/v2/2Fac",
          method: "POST",
          body: queryArg,
        };
      },
    }),
    getAuthConnectedAccountController: builder.mutation<
      GetAuthConnectedAccountApi,
      GetAuthConnectedAccountArg
    >({
      query({ linkedUserId }) {
        return {
          url: `api/auth/linked/${linkedUserId}`,
          method: "GET",
        };
      },
    }),
    getAuthConnectedAccountReturnController: builder.mutation<
      GetAuthConnectedAccountApi,
      any
    >({
      query() {
        return {
          url: `api/auth/linked/return`,
          method: "GET",
        };
      },
    }),
    sendUnlockOtpController: builder.mutation<any, SendUnlockOtpArg>({
      query(queryArg) {
        return {
          url: "api/auth/SendUnlockOtp",
          method: "POST",
          body: queryArg,
        };
      },
    }),
    validateUnlockOtpController: builder.mutation<any, ValidateUnlockOtpArg>({
      query(queryArg) {
        return {
          url: "api/auth/ValidateUnlockOtp",
          method: "POST",
          body: queryArg,
        };
      },
    }),
    getLinkedSecret: builder.mutation<{ token: string }, { id: number }>({
      query(queryArg) {
        return {
          url: `api/auth/linked/secret/${queryArg.id}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useUseAuthControllerLoginMutation,
  useForgotLoginControllerMutation,
  useRestoreLoginControllerMutation,
  useLogin2FacControllerMutation,
  useGetAuthConnectedAccountControllerMutation,
  useGetAuthConnectedAccountReturnControllerMutation,
  useSendUnlockOtpControllerMutation,
  useValidateUnlockOtpControllerMutation,
  useGetLinkedSecretMutation,
} = authApi;
