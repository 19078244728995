import ArrowLeft from 'assets/svg/ArrowBackFilled.svg'
import styles from './style.module.scss'

interface PropTypes {
  handleNavigate?: () => void
  title: string
}

const NavigationTitle = ({ handleNavigate, title }: PropTypes) => {
  return (
    <div className={styles.nav}>
      {handleNavigate && (
        <img
          src={ArrowLeft}
          width={24}
          height={24}
          onClick={handleNavigate}
        />
      )}
      <span className={styles.nav_title}>{title}</span>
    </div>
  )
}

export default NavigationTitle
