import MyAutocomplete_v3, {
  GoogleAdress,
} from '../../pages/settings/autocomplete/MyAutocomplete_v3';
import { SearchIcon } from '../ui/icons';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { states } from '../../constants/data';
import { SignUpPersonalBaseInfoForm } from '../../pages/signUpPersonal/provider';

interface Props {
  formikInstance: any;
}

export const SignUpAddressForm = ({ formikInstance: formik }: Props) => {
  const handleChangeAutocompleteAddress = async (address: GoogleAdress) => {
    const newValues: Partial<SignUpPersonalBaseInfoForm> = {};
    if (address.address1) {
      newValues.streetAddress = address.address1;
    }
    if (address.locality) {
      newValues.locality = address.locality;
    }
    if (address.postalCode) {
      newValues.postalCode = address.postalCode;
    }
    if (address.adminArea1Short) {
      const existingState = states.find(
        ({ label }) =>
          label.toLowerCase() === address.adminArea1Short?.toLowerCase()
      );

      if (existingState) {
        newValues.state = existingState.label;
      }
    }
    await formik.setValues((curValues: any) => {
      return {
        ...curValues,
        ...newValues,
      };
    });
    setTimeout(() => formik.validateForm(), 50);
  };

  return (
    <>
      <MyAutocomplete_v3
        address={''}
        label={'Search address'}
        setAddressFull={handleChangeAutocompleteAddress}
        startAdornment={
          <div style={{ display: 'flex', marginRight: '8px' }}>
            <SearchIcon />
          </div>
        }
      />

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='Address line 1'
        error={Boolean(
          formik.touched.streetAddress && formik.errors.streetAddress
        )}
        helperText={formik.touched.streetAddress && formik.errors.streetAddress}
        {...formik.getFieldProps('streetAddress')}
      />

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='Address line 2(optional)'
        error={Boolean(
          formik.touched.extendedAddress && formik.errors.extendedAddress
        )}
        helperText={
          formik.touched.extendedAddress && formik.errors.extendedAddress
        }
        {...formik.getFieldProps('extendedAddress')}
      />

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='City'
        error={Boolean(formik.touched.locality && formik.errors.locality)}
        helperText={formik.touched.locality && formik.errors.locality}
        {...formik.getFieldProps('locality')}
      />

      <FormControl fullWidth>
        <InputLabel id='state-select-label'>State</InputLabel>
        <Select
          labelId='state-select-label'
          id='state-select'
          value={formik.values.state}
          label='State'
          onChange={(e) => formik.setFieldValue('state', e.target.value)}
          error={Boolean(formik.touched.state && formik.errors.state)}
        >
          {states.map((i) => {
            return (
              <MenuItem
                key={i.value}
                value={i.label}
              >
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
        {formik.touched.state && formik.errors.state && (
          <FormHelperText error>{formik.errors.state}</FormHelperText>
        )}
      </FormControl>

      <TextField
        fullWidth
        type='text'
        variant='outlined'
        label='ZIP code'
        error={Boolean(formik.touched.postalCode && formik.errors.postalCode)}
        helperText={formik.touched.postalCode && formik.errors.postalCode}
        {...formik.getFieldProps('postalCode')}
      />
    </>
  );
};
