import { useEffect, useState } from 'react'
import {
  useForgotPasswordOTPMutation,
  useRestorePasswordMutation,
} from 'api/endpoints/account'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ChangePasswordHQSchema } from './validationSchema/changePasswordHQSchema'
import { ChangePaswordHqForm } from './components/changePasswordHqForm'
import { Typography } from 'shared/ui/typography'
import { useFormik } from 'formik'
import { useSnackBar } from 'providers/snackBar.provider'
import helper from 'services/helper'
import { AuntificatorSms } from './components/auntificatorSms'
import moment from 'moment'
import { useBoolean } from 'shared/hooks/useBoolean'
import styles from './style.module.scss'

const TIMER = 240

const ChangePasswordHQ = () => {
  const { setSnackBar } = useSnackBar()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const nickName = searchParams.get('NickName')

  const [forgotPasswordOTP, { isLoading: forgotPasswordOTPIsLoading }] =
    useForgotPasswordOTPMutation()
  const [restorePassword, { isLoading: isLoadingRestorePassword }] =
    useRestorePasswordMutation()

  const [code, setCode] = useState<string>('')
  const [phonePath, setPhonePath] = useState<string>('')
  const [nextSendDate, setNextSendDate] = useState<Date | undefined>()

  const showAuntificatorSmsBool = useBoolean(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: ChangePasswordHQSchema,
    onSubmit: () => {
      showAuntificatorSmsBool.setTrue()
    },
  })

  useEffect(() => {
    if (showAuntificatorSmsBool.value) {
      handleSendCode()
    }
  }, [showAuntificatorSmsBool.value])

  const handleSendCode = async () => {
    try {
      if (nickName) {
        const res = await forgotPasswordOTP({ nickName: nickName }).unwrap()
        const nextSendDate = moment().add(TIMER, 'seconds').toDate()
        setPhonePath(res?.success)
        setNextSendDate(nextSendDate)
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      })
    }
  }

  const handleRestorePassword = async () => {
    try {
      const res = await restorePassword({
        NickName: nickName,
        Code: code,
        Password: formik.values.password,
        PasswordConfirm: formik.values.passwordConfirm,
      }).unwrap()

      if (res === 1) {
        navigate('/login/password-changed')
      } else {
        setCode('')
        setSnackBar({
          type: 'error',
          message:
            res === 3
              ? 'You have run out of code input'
              : 'The entered code is incorrect',
          isShow: true,
        })
      }
    } catch (err: any) {
      setCode('')
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(err.data),
        isShow: true,
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <Typography className={styles.boxTitle}>Create new password</Typography>
        <Typography className={styles.boxText}>
          For your security, please change your temporary password to a new,
          unique one. You&apos;ll need to create a password that meets our
          security requirements, ensuring the safety of your account.
        </Typography>
      </div>
      <ChangePaswordHqForm formik={formik} />
      {showAuntificatorSmsBool.value && (
        <AuntificatorSms
          onSubmit={handleRestorePassword}
          isLoadingOnSubmit={isLoadingRestorePassword}
          onSend={handleSendCode}
          isLoadingOnSend={forgotPasswordOTPIsLoading}
          nextSendDate={nextSendDate}
          setNextSendDate={setNextSendDate}
          onClose={showAuntificatorSmsBool.setFalse}
          code={code}
          setCode={setCode}
          phonePath={phonePath}
        />
      )}
    </div>
  )
}

export default ChangePasswordHQ
