import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationTitle from '../navigationTitle';
import FinancialAccountsSelect from 'shared/components/financialAccountsSelect';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { AnimateWrapper } from 'components/animate-wrapper';
import {
  useOpenPaymentCardControllerMutation,
  usePaymentCardsControllerQuery,
} from 'api/endpoints/paymentCards';
import moment from 'moment';
import helper from 'services/helper';
import styles from './style.module.scss';
import WarningModal from 'components/modals/WarningModal';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { useCurrentUser } from 'providers/user.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import { appRoutes } from '../../../routes';
import { OPEN_NEW_CARD_SLEEP_MS } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { usePaymentCards } from '../../../api/hooksApi/usePaymentCards';

interface PropTypes {
  setChooseCardsMode?: (string) => void;
  isAuthorizedUsers?: boolean;
  onSuccess?: (value: string) => void;
  onStart?(): void;
  onFinish?(): void;

  onBack?(): void;
  onCardOpened?(newCardId: string): void;
}

const NewVirtualCard = ({
  setChooseCardsMode = () => {
  },
  isAuthorizedUsers,
  onFinish,
  onStart,
  onCardOpened,
  onBack,
}: PropTypes) => {
  const { t } = useTranslationProvider();
  const paymentCards = usePaymentCards();
  const navigate = useNavigate();
  const {
    financialAccounts,
    financialAccountsIsLoading,
    unclosedFinancialAccounts,
  } = useFinancialAccounts();
  const { isSubUser, subUserRights } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const { accountId } = useParams();
  const [selectAccount, setSelectAccount] = useState<FinancialAccount>(
    accountId && financialAccounts?.find(f => f.financialAccountId === accountId) || financialAccounts[0]
  );
  const { refetch: refetchPaymentCards, isUninitialized } = usePaymentCardsControllerQuery();
  const [createPaymentCardMutation] = useOpenPaymentCardControllerMutation();
  const [isLoading, setIsloading] = useState<boolean>();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const daisabledPaymentCard = isLoading;

  const subUserNotHavePermissionFinAcc =
    isSubUser &&
    subUserRights({
      viewAllCardsAndFinancialAccounts: false,
      mainAccess: false,
    }) &&
    !unclosedFinancialAccounts?.length;

  useEffect(() => {
    if (subUserNotHavePermissionFinAcc) {
      setSnackBar({
        type: 'info',
        message:
          'Your account is not currently linked to any financial accounts.',
        isShow: true,
        width: '400px',
      });
    }
  }, [subUserNotHavePermissionFinAcc]);

  const createPaymentCard = async () => {
    try {
      onStart && onStart();
      setIsloading(true);
      const newPaymentCard = await createPaymentCardMutation({
        financialAccountId: selectAccount.financialAccountId,
        expirationDate: moment().add(24, 'months').format('YYYY-MM-DD'),
        activateNow: true,
      }).unwrap();
      await helper.sleep(OPEN_NEW_CARD_SLEEP_MS);
      await refetchPaymentCards().unwrap();
      paymentCards.paymentCardsRefetch();
      setSnackBar({
        type: 'success',
        message: 'New card was opened',
        isShow: true,
      });

      if (onCardOpened) {
        onCardOpened(newPaymentCard.issuePaymentCardForFinancialAccount.id);
      } else {
        navigate(appRoutes.cardTransactions(newPaymentCard.issuePaymentCardForFinancialAccount.id));
      }

      return newPaymentCard;
    } catch (e: any) {
      setIsloading(false);
      console.log('error', e);
      setErrorText(helper.formatErrors(e.data) || 'Something went wrong');
      setWarningModal(true);
    } finally {
      onFinish && onFinish();
    }
  };

  const handleNavigate = () =>
    onBack
      ? onBack()
      : navigate('/home/open-new-product');

  return (
    <AnimateWrapper className="fade">
      {!isAuthorizedUsers && (
        <NavigationTitle
          handleNavigate={handleNavigate}
          title={t('Home.Open new card', {
            defaultValue: 'Open New Card',
          })}
        />
      )}
      <>
        <p className={styles.container_title}>
          {t('common.Choose an account to connect', {
            defaultValue: 'Choose an account to connect',
          })}
        </p>

        <FinancialAccountsSelect
          selectAccount={selectAccount}
          setSelectAccount={setSelectAccount}
          isPartnersAccountsHidden
          isDisabled={isLoading}
        />

        <div className={styles.buttonBox}>
          <LoadingButton
            onClick={createPaymentCard}
            loading={daisabledPaymentCard}
            variant="contained"
          >
            <div>{t('common.Confirm', {
              defaultValue: 'Confirm',
            })}</div>
          </LoadingButton>
        </div>
      </>
      <WarningModal
        titleMassage={errorText}
        onClose={() => setWarningModal(false)}
        isOpen={warningModal}
      />
    </AnimateWrapper>
  );
};

export default NewVirtualCard;
