import { FC } from "react";
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextInputMask from "react-masked-text";
import dollarIcon from "assets/svg/dollar-gray.svg";
import styles from "./styles.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

interface CurrencyInputRewardsProps {
  value: string;
  isLoading?: boolean;
}

const CurrencyInputRewards: FC<CurrencyInputRewardsProps> = ({
  value,
  isLoading,
}) => {
  const renderIcon = () =>
    isLoading ? (
      <div className={styles.wrapperInp_box}>
        <CircularProgress
          color="inherit"
          className={styles.wrapperInp_box_progress}
          sx={{
            "&.MuiCircularProgress-root": {
              color: "#00000099",
            },
          }}
        />
      </div>
    ) : (
      <img src={dollarIcon} width={24} alt="DollarIcon" />
    );

  const renderTextInputMask = value && value !== "0";
  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-adornment-amount"
        label="Cash value"
        sx={{
          "& .MuiInputBase-input": {
            width: "0px",
          },
          "& .Mui-disabled": {
            color: "rgb(102 102 102)",
          },
        }}
        disabled={true}
        InputProps={{
          readOnly: true,
          startAdornment: renderTextInputMask && (
            <div className={styles.wrapperInp}>
              {renderIcon()}
              <TextInputMask
                className={styles.amount}
                kind="money"
                value={value}
                maxLength={16}
                placeholder="0.00"
                options={{
                  precision: 2,
                  separator: ".",
                  delimiter: ",",
                  unit: "",
                  suffixUnit: "",
                }}
              />
            </div>
          ),
        }}
      />
    </FormControl>
  );
};
export default CurrencyInputRewards;
