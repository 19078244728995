import { FC, useRef } from "react";
import {
  FormControl,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { FinancialAccount } from "api/endpoints/financialAccounts";

interface SelectRewardsProps {
  id: string;
  toggleArrow: boolean;
  setToggleArrow: (toggleArrow: boolean) => void;
}

const SelectRewards: FC<SelectRewardsProps> = ({
  id,
  toggleArrow,
  setToggleArrow,
}) => {
  const { t } = useTranslationProvider();
  const { unclosedFinancialAccounts } = useFinancialAccounts();

  const convertValue = (id: string) => {
    const finAcc = unclosedFinancialAccounts.find(
      (i: FinancialAccount) => i.financialAccountId === id
    );
    const accNumberLast4 = finAcc?.accountNumber.slice(-4);

    return id
      ? `${t("Rewards.Checking account", {
          defaultValue: "Checking account",
        })} ****${accNumberLast4}`
      : id;
  };
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (toggleArrow: boolean) => {
    setToggleArrow(!toggleArrow);
    textFieldRef.current && !toggleArrow && textFieldRef.current.focus();
  };

  return (
    <FormControl fullWidth>
      <TextField
        id="outlined-required"
        label={t("Rewards.Convert to account", {
          defaultValue: "Convert to account",
        })}
        value={convertValue(id)}
        inputRef={textFieldRef}
        onFocus={() => setToggleArrow(!toggleArrow)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => handleClick(toggleArrow)}>
                {toggleArrow ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
export default SelectRewards;
