import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useConfirmDialog } from "providers/confirm.provider";
import { useTranslationProvider } from "providers/translation/translation.provider";
import UnlockProfileWithCodeVerificate from "../unlockProfileWithCodeVerificate";

const ACCESS_BLOCK_1_MINUTES =
  "After 3 incorrect password attempts, access will be restricted for 1 minute. Please try again later.";
const ACCESS_BLOCK_5_MINUTES =
  "After 6 incorrect password attempts, access will be restricted for 5 minutes. Please try again later.";
export const APP_ACCESS_RESTRICTED = "Access to the app is restricted.";

export const ACCESS_BLOCK_TIMES = [
  ACCESS_BLOCK_1_MINUTES,
  ACCESS_BLOCK_5_MINUTES,
];

export interface DataObj {
  message: string;
  success: boolean;
  phone?: string | undefined;
}

interface AccessRestrictionDialogProps {
  username: string;
  password: string;
  pathPhone: string;
  onSumbit: (username: string, password: string) => void;
  data: string | DataObj;
  setPathPhone: (string) => void;
  setIsShowAccessDialogs: (boolean) => void;
}

const AccessRestrictionDialogs: FC<AccessRestrictionDialogProps> = ({
  username,
  password,
  pathPhone,
  onSumbit,
  data,
  setPathPhone,
  setIsShowAccessDialogs,
}) => {
  const confirm = useConfirmDialog();
  const { t } = useTranslationProvider();
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    showDialogOrCodeVerificate();
  }, [data]);

  const typeDataString = typeof data === "string";

  const getTitleAndSubtitle = () => {
    let title: string | React.ReactNode;
    let subTitle: string | React.ReactNode;

    if (data === ACCESS_BLOCK_1_MINUTES) {
      title = t("Login.Account locked for 1 minute", {
        defaultValue: "Account locked for 1 minute",
      });
      subTitle = t("Login.After 3 incorrect", {
        defaultValue:
          "After 3 incorrect password attempts, access will be restricted for 1 minute. Please try again later.",
      });
    } else if (data === ACCESS_BLOCK_5_MINUTES) {
      title = t("Login.Account locked for 5 minutes", {
        defaultValue: "Account locked for 5 minutes",
      });
      subTitle = t("Login.After 6 incorrect", {
        defaultValue:
          "After 6 incorrect password attempts, access will be restricted for 5 minutes. Please try again later.",
      });
    } else if (!typeDataString && data.message === APP_ACCESS_RESTRICTED) {
      title = t("Login.Account locked for security reasons", {
        defaultValue: "Account locked for security reasons",
      });
      subTitle = (
        <div className={styles.warning}>
          <p className={styles.warning_text}>
            {t("Login.After multiple ", {
              defaultValue:
                "After multiple unsuccessful login attempts, access to banking is temporarily restricted for security purposes. To unlock your account, please follow these steps:",
            })}
          </p>
          <p className={styles.warning_text}>
            1.{" "}
            {t("Login.Enter the correct password", {
              defaultValue:
                "Enter the correct password. If you don't remember your password, click on the 'forgot password' button and create a new one.",
            })}
          </p>
          <p className={styles.warning_text}>
            2.{" "}
            {t("Login.Enter the verification code ", {
              defaultValue:
                "Enter the verification code that you'll receive via SMS.",
            })}
          </p>
        </div>
      );
    }

    return { title, subTitle };
  };

  const showDialogOrCodeVerificate = () => {
    const unlockAccess =
      !typeDataString && data.message === APP_ACCESS_RESTRICTED && data.success;

    if (unlockAccess) {
      setPathPhone(data.phone);
      setIsShow(true);
    } else {
      const { title, subTitle } = getTitleAndSubtitle();

      confirm.show({
        applyButtonText: t("common.Close", {
          defaultValue: "Close",
        }),
        dialogTitle: title,
        dialogText: subTitle,
        typeNew: true,
        async onApply() {
          setIsShowAccessDialogs(false);
        },
      });
      // need for unmount the component
      setIsShowAccessDialogs(false);
    }
  };

  return isShow ? (
    <UnlockProfileWithCodeVerificate
      username={username}
      password={password}
      pathPhone={pathPhone}
      onClose={() => setIsShow(false)}
      onSumbit={onSumbit}
    />
  ) : (
    <></>
  );
};
export default AccessRestrictionDialogs;
