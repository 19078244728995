import { LinearProgress, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';

import { Typography } from 'shared/ui/typography';
import { OrderOutCard } from 'entities/orderout-card';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import { appRoutes, SettingsRouteSubRoute } from '../../../../routes';
import { useNavigate } from 'react-router-dom';
import { usePartnerUsageStatus } from '../../../../api/hooksApi/usePartnerUsageStatus';
import { usePaymentCards } from '../../../../api/hooksApi/usePaymentCards';

const steps: Array<{ title: string; desc: string }> = [
  {
    title: 'Open new financial account',
    desc: `Activate your OrderOut virtual card effortlessly by having us open a financial account for you in Netevia. No extra steps – we've got it covered! Plus, if you want, you can also order a physical card.`,
  },
  {
    title: 'Automatic card linking',
    desc: `Whether it's a virtual or physical card, your OrderOut card is now automatically connected to the new account.`,
  },
  {
    title: 'Start using your card right away',
    desc: `Once your account is set up and your card is linked, you can start using your OrderOut virtual card instantly. If you've ordered a physical card, we'll send it your way.`,
  },
];

export const Intro = () => {
  const { goNext } = useDrawerStepperContext();
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width: 600px)');

  const { partnerUsageStatus, isLoading } = usePartnerUsageStatus({ productName: 'OrderOut' });

  const renderContent = () => {
    // if (partnerUsageStatus?.status === 'APPROVED') {
    //   const orderOutCard = cards.paymentCards.find(c => c.partnerName?.toLocaleLowerCase() === 'orderout');
    //   return (
    //     <TransitionAnimation>
    //       <Typography variant="h6">
    //         You already have an OrderOut card!
    //       </Typography>
    //       <br/>
    //       <Typography variant="body1">
    //         Since each account is limited to one OrderOut card, you can access yours by selecting it from the list of cards under your account.
    //       </Typography>
    //       <br/>
    //       <Button
    //         fullWidth={isMobile}
    //         onClick={() => navigate(
    //           orderOutCard
    //             ? appRoutes.cardTransactions(orderOutCard.id)
    //             : appRoutes.home()
    //         )}
    //       >
    //         Access your card
    //       </Button>
    //     </TransitionAnimation>
    //   );
    // }
    if (partnerUsageStatus?.status === 'DENIED') {
      return (
        <TransitionAnimation>
          <Typography variant="h6">
            Your OrderOut card issuance has been declined
          </Typography>
          <br/>
          <Typography variant="body1">
            Please try submitting your application again, or feel free to contact us for further assistance.
            We apologize for any inconvenience this may cause. Thank you for your understanding.
          </Typography>
          <br/>
          <Button
            fullWidth={isMobile}
            onClick={() => navigate(appRoutes.settings(SettingsRouteSubRoute.support))}
          >
            Contact support
          </Button>
        </TransitionAnimation>
      );
    }
    return (
      <TransitionAnimation>
        <div className={styles.root}>
          <Typography variant="body1">
            Open your OrderOut card in 3 simple steps
          </Typography>

          <div className={styles.cardContainer}>
            <OrderOutCard/>
          </div>

          <Stepper orientation="vertical">
            {steps.map((item, index) => (
              <Step
                active
                key={index}
              >
                <StepLabel>
                  <p className={styles.stepLabel}>{item.title}</p>
                  <p className={styles.stepDesc}>{item.desc}</p>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Button
            fullWidth={isMobile}
            onClick={() => goNext('1')}
          >
            Continue
          </Button>
        </div>
      </TransitionAnimation>
    );
  };

  return (
    <>
      {isLoading ? (
        <div>
          <LinearProgress/>
        </div>
      ) : renderContent()}
    </>
  );
};
