import React, { useState } from "react";
import styles from "./style.module.scss";
import { AnimateWrapper } from "components/animate-wrapper";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import CreateNewRequest from "pages/support/create-new-request";
import DrawerModal from "shared/components/drawerModal";
import ContactSupport from "../contactSupport";
import { useTranslationProvider } from "providers/translation/translation.provider";
const AccountLocked = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <div className={styles.wrapper_content}>
          <div className={styles.title}>
            <span className={styles.title_text}>
              {t("Login.Your banking access temporarily restricted!", {
                defaultValue: "Your banking access temporarily restricted!",
              })}
            </span>
            <p className={styles.title_subTitleText}>
              {t("Login.For security reasons", {
                defaultValue:
                  "For security reasons, we have temporarily locked your access to banking services. Please contact our customer support for further assistance.",
              })}
            </p>
          </div>

          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              onClick={handleNavigateToLogin}
              fullWidth
              sx={{
                backgroundColor: "#023047",
              }}
            >
              {t("Login. Log in with another account", {
                defaultValue: "Log in with another account",
              })}
            </Button>
            {/* <Button
              variant="text"
              onClick={() => setIsShowDrawer(true)}
              fullWidth
              sx={{
                color: "#187AC9",
              }}
            >
              contact support
            </Button> */}
          </div>
        </div>
      </div>
      {/* <DrawerModal
        isShow={isShowDrawer}
        onClose={setIsShowDrawer}
        titleText={"asdasdad"}
      >
        <ContactSupport />
      </DrawerModal> */}
    </AnimateWrapper>
  );
};
export default AccountLocked;
