import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { DetailsTransfer } from 'entities/transfers/ui/detailsTransfer';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import { clearDataToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer/model';
import { useAppSelector, useAppDispatch } from 'shared/models';
import {
  selectFromFinancialAccount,
  selectToCustomer,
  selectAmount,
  selectDateTransfer,
  selectFrequency,
  selectNotRegisteredPayee,
  selectTransferMemo,
} from 'features/transfers/transfer-to-netevia-customer/model';
import moment from 'moment';
import { useDialog } from 'providers/dialogController.provider';
import { Typography } from 'shared/ui/typography';
import helper from 'services/helper';

export const MakeTransferSuccess = () => {
  const dispatch = useAppDispatch();
  const dialog = useDialog();
  const { close, goNext } = useDrawerStepperContext();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toCustomer = useAppSelector(selectToCustomer)
  const amount = useAppSelector(selectAmount)
  const dateTransfer = useAppSelector(selectDateTransfer)
  const frequency = useAppSelector(selectFrequency)
  const notRegisteredPayee = useAppSelector(selectNotRegisteredPayee)
  const memo = useAppSelector(selectTransferMemo)

  const dialogText = (
    <Typography>
      Do you want to save payee *{notRegisteredPayee?.name}* for future
      <br />
      transactions?
    </Typography>
  );

  const slotApplyButton = <Button variant='text'>save payee</Button>;

  const slotCancelButton = (
    <Button
      variant='text'
      className={styles.btnCancel}
    >
      Don’t save
    </Button>
  );

  const addNotRegisteredPayee = () => {
    dialog.show({
      dialogTitle: 'Save payee for the future',
      dialogText: dialogText,
      slotApplyButton: slotApplyButton,
      slotCancelButton: slotCancelButton,
      onApply() {
        goNext('10');
      },
      onCancel() {
        dispatch(clearDataToNeteviaCustomer());
        goNext('0');
        close();
      },
    });
  };

  const handleDone = () => {
    if (notRegisteredPayee) {
      addNotRegisteredPayee();
    } else {
      dispatch(clearDataToNeteviaCustomer());
      goNext('0');
      close();
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsTransfer
          title='Transfer successfully completed'
          amount={`$${helper.moneyFormat(amount)}`}
          accountFrom={fromFinancialAccount}
          accountTo={toCustomer}
          date={moment(dateTransfer).format('MM/DD/YYYY')}
          frequency={frequency}
          memo={memo}
        />
        <Button
          variant='contained'
          onClick={handleDone}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  );
};
