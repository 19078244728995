import { ReactNode } from "react";
import styles from "./style.module.scss";

interface PropTypes {
  children: ReactNode;
}

const NewProductContainer = ({ children }: PropTypes) => {
  return <div className={styles.container}>{children}</div>;
};

export default NewProductContainer;
