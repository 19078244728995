import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { FinancialAccount } from 'api/endpoints/financialAccounts';

export const createScheduleTransferSchema = (
  financialAccounts: FinancialAccount[]
) => {
  return yup.object().shape({
    from: yup
      .string()
      .required('This field is required')
      .min(1)
      .test(
        'amount-check',
        'The amount in your account should not exceed the balance',
        function (value) {
          const { amount } = this.parent;
          const financialAccount = financialAccounts.find(
            (account) => account.financialAccountId === value
          );
          if (
            financialAccount &&
            amount &&
            Number(amount) > financialAccount.availableCash.value
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
    to: yup.string().required('This field is required').min(1),
    amount: yup
      .string()
      .required('Amount is a required field')
      .test({
        name: 'is-non-zero',
        message: 'Float value must not be zero',
        test: (value) => Number(value) !== 0,
      }),
    frequency: yup.string().required('This field is required'),
    dateTransfer: yup
      .mixed<Dayjs>()
      .required('Date is required')
      .test('is-dayjs', 'Invalid date format', (value) => {
        return value && dayjs.isDayjs(value) && value.isValid();
      })
      .test('is-not-past-date', 'Date cannot be in the past', (value) => {
        return value && !value.isBefore(dayjs(), 'day');
      }),
  });
};
