import { useState } from 'react';
import { TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateFinancialAccountControllerMutation } from '../../../api/endpoints/financialAccounts';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import Success from '../../../components/Success';
import helper from '../../../services/helper';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useAttachToFinAccControllerMutation } from 'api/endpoints/subProfiles';
import { useSubUsersAttachedFinancialAccounts } from 'api/hooksApi/useSubUsersAttachedFinancialAccounts';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackBar } from 'providers/snackBar.provider';

interface PropTypes {
  setPageStatus?: (string) => void;
  setOpenNewAccountId?: (string) => void;
  backRoute?: string;
  setChooseFinAccMode?: (string) => void;
  chooseFinAccToConnectSubUserMode?: string;
  onFinish?(): void;
}

const NewAccount = ({
  setPageStatus = () => {},
  setOpenNewAccountId = () => {},
  backRoute = '',
  setChooseFinAccMode = () => {},
  chooseFinAccToConnectSubUserMode = '',
  onFinish,
}: PropTypes) => {
  const { t } = useTranslationProvider();
  const [searchParams, setSearchParams] = useSearchParams();
  const subUserId = searchParams.get('id') || '';

  const navigate = useNavigate();
  const { setSnackBar } = useSnackBar();
  const [text, setText] = useState<string>('');
  const [newAccountId, setNewAccountId] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [createAccountMutation, { isLoading: createAccountMutationIsLoadimg }] =
    useCreateFinancialAccountControllerMutation();
  const [
    attachToFinAccMutation,
    { isLoading: attachToFinAccMutationIsLoading },
  ] = useAttachToFinAccControllerMutation();

  const { subUserAttachedFinAccRefetch } =
    useSubUsersAttachedFinancialAccounts(subUserId);

  const createAccount = async () => {
    try {
      const res = await createAccountMutation({ Name: text }).unwrap();
      setNewAccountId(res);
      if (backRoute && subUserId && res) {
        await attachToFinAccMutation({
          financialAccountId: res,
          SubProfileId: subUserId,
        }).unwrap();
      }
      setSnackBar({
        type: 'success',
        message: 'Account created',
        isShow: true,
      });
      onFinish && onFinish();
      // setIsSuccess(true);
    } catch (e: any) {
      if (
        e.data ===
        'You have reached the maximum amount of financial accounts allowed'
      ) {
        setSnackBar({
          type: 'error',
          message: t(
            'Transfers.The number of financial accounts has exceeded the limit',
            {
              defaultValue:
                'The number of financial accounts has exceeded the limit',
            }
          ),
          isShow: true,
        });
      } else {
        setSnackBar({
          type: 'error',
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  };

  const handleNavigateModal = async () => {
    if (backRoute) {
      subUserAttachedFinAccRefetch();
      setOpenNewAccountId(newAccountId);
      setPageStatus('manage');
    } else {
      navigate(`/account/${newAccountId}`);
    }
  };
  const isLoading =
    createAccountMutationIsLoadimg || attachToFinAccMutationIsLoading;

  const textSuccess = backRoute
    ? t('Account.Congratulations The account has been', {
        defaultValue: 'Congratulations! The account has been created.',
      })
    : t('Account.Congratulations The account has been created', {
        defaultValue:
          'Congratulations! The account has been created. You may now go to the account by pressing the button below.',
      });

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {isSuccess ? (
          <Success
            label={t('Account.Account created', {
              defaultValue: 'Account created!',
            })}
            infotext={textSuccess}
            buttonText={
              backRoute
                ? t('Login.Return to Authorized user profile', {
                    defaultValue: 'Return to Authorized user profile',
                  })
                : t('Rewards.Go to your account', {
                    defaultValue: 'Go to your account',
                  })
            }
            handleSubmit={handleNavigateModal}
          />
        ) : (
          <>
            <p className={styles.container_title}>
              {t('common.Create name for the account', {
                defaultValue: 'Create name for the account',
              })}
            </p>
            <div className={styles.boxForm}>
              <TextField
                placeholder={t('Transfers.Enter name', {
                  defaultValue: 'Enter name',
                })}
                value={text}
                onChange={(e: any) => {
                  setText(e.target.value);
                }}
                inputProps={{ maxLength: 20 }}
              />
              <div className={styles.buttonContainer}>
                <LoadingButton
                  onClick={createAccount}
                  disabled={isLoading || !text}
                  variant='contained'
                  loading={isLoading}
                >
                  {t('common.Apply', {
                    defaultValue: 'Apply',
                  })}
                </LoadingButton>
              </div>
            </div>
          </>
        )}
      </div>
    </AnimateWrapper>
  );
};

export default NewAccount;
