import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';
import { DeliveryDetails } from './paymentCards';
import { FormFactor, Status, CountryCodeAlpha3 } from 'enums';
import { buildQs } from '../../shared/utils/build-qs';


export enum Department {
  Marketing = 'Marketing',
  Finance = 'Finance',
  OperationsManagement = 'Operations management',
  HumanResource = 'Human Resource',
  Technology = 'Technology',
  RiskManagement = 'Risk Management',
  Sales = 'Sales',
  Support = 'Support',
  TopManagement = 'Top management',
}
interface AddSubUserArg {
  email: string;
  givenName: string;
  familyName: string;
  department: string | Department;
  dateOfBirth: string;
  phoneNumber: string;
  financialAccountId: string;
}

interface UpdateSubUserArg {
  email: string;
  givenName: string;
  familyName: string;
  department: string;
  subProfileId: number;
}

interface DeleteSubUserArg {
  id: string;
  deleteCards: boolean;
}

interface ProductApplicationArg {
  subUserId: string;
}

export interface OpenPaymentCardForSubUserArg {
  subProfileId: number;
  paymentCardName: string;
  activateOnCreate: boolean;
  expirationDate: string;
  orderPhysicalPaymentCard?: {
    deliveryDetails: DeliveryDetails;
    courier: {
      method: string;
    };
  };
}
interface UpdateSubUserPhoneNumberArg {
  subProfileId: number;
  phoneNumber: string;
  phoneNumberCountryCode: string;
}
interface UpdateSubUserAddressArg {
  subProfileId: number;
  extendedAddress: string;
  updatePaymentCardBillingAddress: boolean;
  streetAddress: string;
  postalCode: string;
  state: number;
  locality: string;
  countryCodeAlpha3: string;
}

interface UppdateSyncWithHighnoteSubUserArg {
  phoneNumber: string;
  dateOfBirth: string;
}

interface SubUsersAvailableFinancialAccountsArg {
  subUserId: string;
}
export interface SubUsersAvailableFinancialAccountsApi {
  id: string;
  name: string;
  created: Date;
  directDeposit: DirectDeposit;
  accountStatus: string;
  ledgers: Ledger[];
  cardProduct: CardProduct;
}
interface CardProduct {
  id: string;
  vertical: null;
  name: string;
}

interface DirectDeposit {
  id: string;
  restrictedDetails: RestrictedDetails;
}

interface RestrictedDetails {
  typename: null;
  number: string;
  routingNumber: string;
}

interface Ledger {
  id: string;
  name: string;
  creditBalance: Balance;
  debitBalance: Balance;
  normalBalance: string;
  financialAccount: null;
}

interface Balance {
  currencyCode: string;
  value: number;
}

export interface AttachedPaymentCard {
  id: string;
  formFactor: string | FormFactor;
  bin: null | string;
  last4: string;
  status: string | Status;
  financialAccountId: string | null;
  isMainCard: boolean;
  network: null;
  isEmailNotify?: boolean;
}


export interface SubUsersApi {
  id: number;
  email: string;
  givenName: string;
  familyName: string;
  department: string | Department;
  attachedPaymentCards: AttachedPaymentCard[];
  financialName: string | null;
  financialAccountId: string;
  isSync: boolean;
}
export interface SubProfilesMeApi {
  city: string;
  countryCodeAlpha3: CountryCodeAlpha3 | string;
  department: string;
  email: string;
  familyName: string;
  financialAccountId: string;
  financialAccountName: string;
  givenName: string;
  id: number;
  paymentCardId: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  postalCode: string;
  state: number;
  streetAddress: string;
}

export interface SubProfilePaymentCardUpdateRequest {
  PaymentCardId: string;
  SubProfileId: number;
  IsEmailNotify: boolean;
}

export interface AddSubUserResponse {
  department: string;
  email: string; 
  familyName: string;
  givenName: string;
  subProfileId: number;
}

export const subProfilesApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'subProfiles',
  tagTypes: [
    ApiTags.GetSubUsersList,
    ApiTags.GetSubUsersFinAcc,
    ApiTags.GetSubUserCard,
    ApiTags.GetSubUserME,
    ApiTags.GetPaymentCards,
  ],
  endpoints: (builder) => ({
    subUsersController: builder.query<SubUsersApi[], void>({
      providesTags: [ApiTags.GetSubUsersList],
      query: () => ({
        url: 'api/subProfiles',
        method: 'GET',
      }),
    }),

    addSubUserController: builder.mutation<AddSubUserResponse, AddSubUserArg>({
      invalidatesTags: [ApiTags.GetSubUsersList],
      query: (queryArg) => ({
        url: 'api/subProfiles/add',
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateSubUserController: builder.mutation<any, UpdateSubUserArg>({
      invalidatesTags: [ApiTags.GetSubUsersList, ApiTags.GetSubUserME],
      query: (queryArg) => ({
        url: 'api/subProfiles/update',
        method: 'POST',
        body: queryArg,
      }),
    }),
    deleteSubUserController: builder.mutation<any, DeleteSubUserArg>({
      invalidatesTags: [ApiTags.GetSubUsersList],
      query: ({ id, deleteCards }) => ({
        url: `api/subProfiles/remove`,
        method: 'POST',
        params: {
          id,
          deleteCards,
        },
      }),
    }),
    subUsersAvailablePaymentCardsController: builder.query<any, any>({
      providesTags: [ApiTags.GetSubUserCard],
      query: () => ({
        url: `api/subProfiles/availablePaymentCards`,
        method: 'GET',
      }),
    }),
    attachToPaymentCardController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetSubUsersList],
      query: ({ PaymentCardId, SubProfileId }) => ({
        url: `api/subProfiles/attachToPaymentCard`,
        method: 'POST',
        params: {
          PaymentCardId,
          SubProfileId,
        },
      }),
    }),
    detachToPaymentCardController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetSubUsersList],
      query: ({ PaymentCardId, SubProfileId }) => ({
        url: `api/subProfiles/detachFromPaymentCard`,
        method: 'POST',
        params: {
          PaymentCardId,
          SubProfileId,
        },
      }),
    }),
    subUsersAvailableFinancialAccountsController: builder.query<
      SubUsersAvailableFinancialAccountsApi[],
      SubUsersAvailableFinancialAccountsArg
    >({
      providesTags: [ApiTags.GetSubUsersFinAcc],
      query: ({ subUserId }) => ({
        url: `api/subProfiles/availableFinancialAccounts`,
        method: 'GET',
        params: {
          subUserId,
        },
      }),
    }),
    attachToFinAccController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetSubUsersFinAcc],
      query: (queryArg) => ({
        url: `api/subProfiles/attachToFinancialAccount`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    detachToFinAccController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetSubUsersFinAcc],
      query: (queryArg) => ({
        url: `api/subProfiles/detachFromFinancialAccount`,
        method: 'POST',
        params: queryArg,
      }),
    }),
    productApplicationController: builder.mutation<any, ProductApplicationArg>({
      query: ({ subUserId }) => ({
        url: 'api/subProfiles/productApplication',
        method: 'POST',
        params: {
          subUserId,
        },
      }),
    }),
    openPaymentCardForSubUserController: builder.mutation<
      any,
      OpenPaymentCardForSubUserArg
    >({
      invalidatesTags: [ApiTags.GetPaymentCards],
      query: (queryArg) => ({
        url: 'api/subProfiles/issuePaymentCard',
        method: 'POST',
        body: queryArg,
      }),
    }),
    subUserMEController: builder.query<any, any>({
      providesTags: [ApiTags.GetSubUserME],
      query: () => ({
        url: 'api/subProfiles/me',
        method: 'GET',
      }),
    }),
    updateSubUserPhoneNumberController: builder.mutation<
      any,
      UpdateSubUserPhoneNumberArg
    >({
      invalidatesTags: [ApiTags.GetSubUserME],
      query: (queryArg) => ({
        url: 'api/subProfiles/phone',
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateSubUserAddressController: builder.mutation<
      any,
      UpdateSubUserAddressArg
    >({
      invalidatesTags: [ApiTags.GetSubUserME],
      query: (queryArg) => ({
        url: 'api/subProfiles/address',
        method: 'POST',
        body: queryArg,
      }),
    }),
    subProfilesMe: builder.query<SubProfilesMeApi, void>({
      query: () => ({
        url: 'api/subProfiles/me',
        method: 'GET',
      }),
    }),
    updateSyncWithHighnoteSubUserController: builder.mutation<
      any,
      UppdateSyncWithHighnoteSubUserArg
    >({
      query: (queryArg) => ({
        url: 'api/subProfiles/sync',
        method: 'POST',
        body: queryArg,
      }),
    }),
    updateSubProfilePaymentCard: builder.mutation<
      any,
      SubProfilePaymentCardUpdateRequest
    >({
      invalidatesTags: [ApiTags.GetSubUserCard],
      query: (params) => ({
        url: buildQs('/api/subProfiles/PaymentCard', params),
        method: 'POST'
      }),
    }),
  }),
});

export const {
  useSubUsersControllerQuery,
  useAddSubUserControllerMutation,
  useUpdateSubUserControllerMutation,
  useSubUsersAvailablePaymentCardsControllerQuery,
  useAttachToPaymentCardControllerMutation,
  useDetachToPaymentCardControllerMutation,
  useSubUsersAvailableFinancialAccountsControllerQuery,
  useAttachToFinAccControllerMutation,
  useDetachToFinAccControllerMutation,
  useDeleteSubUserControllerMutation,
  useProductApplicationControllerMutation,
  useOpenPaymentCardForSubUserControllerMutation,
  useSubUserMEControllerQuery,
  useUpdateSubUserPhoneNumberControllerMutation,
  useUpdateSubUserAddressControllerMutation,
  useSubProfilesMeQuery,
  useUpdateSyncWithHighnoteSubUserControllerMutation,
  useUpdateSubProfilePaymentCardMutation
} = subProfilesApi;
