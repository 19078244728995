import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from '../api-tags';
import { SecureOperationType, CountryCodeAlpha3 } from 'enums';

export enum GiftCardStatus {
  Issued = 'Issued',
  Canceled = 'Canceled',
}

export interface GetGiftCardApi {
  id: string;
  brandId: string;
  brandName: string;
  logoUrl: string;
  url: string;
  currency: string;
  faceAmount: number;
  amount: number;
  discount: number;
  expirationDate: string;
  createdDate: string;
  cancelDate: string | null;
  issueTransactionId: string;
  cancelTransactionId: string | null;
  status: GiftCardStatus;
  userName: string;
}
interface GetGiftCardArg {
  archive: boolean;
}

interface DeleteGiftCardArg {
  id: string;
}

interface DigitalFaceValueLimits {
  lower: number;
  upper: number;
  minorUnit: number;
}

export interface GetGiftCardsBrandsApi {
  categories: string[];
  clientDiscount: number;
  description: string;
  digitalDenominations: number[] | null;
  digitalFaceValueLimits: DigitalFaceValueLimits | null;
  giftCardUrl: string;
  id: string;
  logoUrl: string;
  name: string;
}
interface CreateGiftCardArg {
  brandId: string;
  faceAmount: number;
  oneTimeCode: string;
  secureOperationType: SecureOperationType;
  fromFinancialAccountId: string;
}
interface CreateGiftCardApi {
  id: string;
  brandId: string;
  brandName: string;
  logoUrl: string;
  url: string;
  currency: CountryCodeAlpha3;
  faceAmount: number;
  amount: number;
  discount: number;
  expirationDate: string;
  createdDate: string;
  cancelDate: string;
  issueTransactionId: string;
  cancelTransactionId: string;
  status: GiftCardStatus;
  userName: string;
}

export const giftCardsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'giftCards',
  tagTypes: [ApiTags.GetGiftCards],
  endpoints: (builder) => ({
    getGiftCards: builder.query<GetGiftCardApi[], GetGiftCardArg>({
      providesTags: [ApiTags.GetGiftCards],
      query: ({ archive }) => ({
        url: 'api/giftCards/orders',
        method: 'GET',
        params: {
          archive,
        },
      }),
    }),
    getGiftCardsBrands: builder.query<GetGiftCardsBrandsApi[], void>({
      query: () => ({
        url: 'api/giftCards/brands',
        method: 'GET',
      }),
    }),
    createGiftCard: builder.mutation<CreateGiftCardApi, CreateGiftCardArg>({
      invalidatesTags: [ApiTags.GetGiftCards],
      query: (arg) => ({
        url: 'api/giftCards/card',
        method: 'POST',
        body: arg,
      }),
    }),
    deleteGiftCard: builder.mutation<void, DeleteGiftCardArg>({
      invalidatesTags: [ApiTags.GetGiftCards],
      query: ({ id }) => ({
        url: 'api/giftCards/card',
        method: 'DELETE',
        params: {
          id,
        },
      }),
    }),
  }),
});

export const {
  useGetGiftCardsQuery,
  useGetGiftCardsBrandsQuery,
  useCreateGiftCardMutation,
  useDeleteGiftCardMutation,
} = giftCardsApi;
