import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { NEW_REQUEST_PARAMS } from 'pages/support/types/enums';
import { Button } from "shared/ui/buttons";
import { TransitionAnimation } from "shared/ui/animations";

const OpenNewRequest = () => {
  const { t } = useTranslationProvider();
  const [, setSearchParams] = useSearchParams();
  const handleNavigate = () => {
    setSearchParams(NEW_REQUEST_PARAMS);
  };
  return (
    <TransitionAnimation>
    <div className={styles.wrapper}>
      <span className={styles.wrapper_massage}>
        {t("Support.Need assistance", {
          defaultValue:
            "Need assistance? Please submit your request for help or information.",
        })}
      </span>
      <Button
        onClick={handleNavigate}
        variant="contained"
        className={styles.wrapper_button}
      >
          +{" "}
          {t("Support.Create new request", {
            defaultValue: "Create new request",
          })}
      </Button>
    </div>
    </TransitionAnimation>
  );
};

export default OpenNewRequest;
