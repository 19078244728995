export enum AccountMode {
  filtersCards = 'filtersCards',
  filtersTransactions = 'filtersTransactions',
  openCard = 'openCard',
}

export enum AccountAction {
  transfer = 'transfer',
  statements = 'statements',
  accountDetails = 'accountDetails',
  more = 'more'
}

export enum ChartType {
  pieChart = 'pieChart',
  barChart = 'barChart'
}

export enum FilterCards {
  AllAvailableCards = 'All available cards',
  MyCards = 'My cards',
  ClosedCards = 'Closed cards'
}