import { FC } from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
interface InputForPasswordProps {
  value: string;
  label: string;
  isShow: boolean;
  setShow: (boolean) => void;
  setValue: (string) => void;
  setError?: (string) => void;
  error?: any;
}
const InputForPassword: FC<InputForPasswordProps> = ({
  value,
  label,
  isShow,
  setShow,
  setValue,
  setError,
  error,
}) => {
  const handleClickShowPassword = () => setShow((isShow) => !isShow);
  const handleOnChange = (e) => {
    setValue(e.target.value.trim());
    setError && setError("");
  };
  return (
    <div>
      <FormControl
        sx={{ backgroundColor: "transparent", padding: 0 }}
        variant="outlined"
        fullWidth
      >
        <InputLabel htmlFor="outlined-adornment" error={error}>
          {label}
        </InputLabel>
        <OutlinedInput
          label={label}
          value={value}
          onChange={handleOnChange}
          type={isShow ? "text" : "password"}
          sx={{ backgroundColor: "transparent" }}
          fullWidth
          error={error}
          inputProps={{
            autoComplete: isShow ? "off" : "new-password",
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {isShow ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default InputForPassword;
