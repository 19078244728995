import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from 'api/baseApi';

export interface PartnerUsageStatus {
    status: 'APPROVED' | 'CLOSED' | 'DENIED' | 'IN_REVIEW' | 'PENDING';
    message?: string;
}

export const partnersApi = createApi({
    reducerPath: 'partnersApi',
    baseQuery: rtkBaseQuery,
    endpoints: (builder) => ({
        getPartnerUsageStatus: builder.query<
          PartnerUsageStatus,
          { productId: string }
        >({
            query: (queryArg) => ({
                url: `api/Partners/PartnerUsageStatus/${queryArg.productId}`,
            }),
        }),
    }),
});

export const { useGetPartnerUsageStatusQuery } = partnersApi;
