import { AnimateWrapper } from "components/animate-wrapper";

import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface BadgeProps {
  item: any;
  text?: string;
}

const Badge = ({ item, text }: BadgeProps) => {
  const { t } = useTranslationProvider();
  const getStatusStyle = (status) => {
    if (!!text) return styles.statusBadge_textValue;
    switch (status) {
      case "SUSPENDED":
        return styles.statusBadge_locked;
      case "ACTIVATION_REQUIRED":
        return styles.statusBadge_activation_required;
      case "ACTIVE":
        return styles.statusBadge_active;
      case "CLOSED":
      case "FAILED":
        return styles.statusBadge_closed;
      case "COMPLETED":
        return styles.statusBadge_active;
      case "PENDING":
        return styles.statusBadge_pending;
      default:
        return "";
    }
  };
  
  const statusClass = getStatusStyle(item.status);
  const statusText =  (item && item.status) && item.status.charAt(0) + item.status.slice(1).toLowerCase();
  
  const getLabel = (status) =>
    ({
      SUSPENDED: "Locked",
      ACTIVATION_REQUIRED: t("Card.Activate card", {
        defaultValue: "Activate card",
      }),
    })[status] || statusText;

  return (
    <div className={`${styles.statusBadge}  ${statusClass}`}>
      <p className={styles.statusBadge_text}>
        {text ? text : getLabel(item.status)}
      </p>
    </div>
  );
};

export default Badge;
