import styles from './style.module.scss';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { useBoolean } from '../../../shared/hooks/useBoolean';
import { Button } from '../../../shared/ui/buttons';
import GroupingStoreButton from '../../../pages/login/components/groupingStoreButton';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../routes';
import { useCheckAvailableLoginMutation } from '../../../api/endpoints/user';
import { useSnackBar } from '../../../providers/snackBar.provider';
import helper from '../../../services/helper';
import { SignUpLoginAndPassForm } from '../shared';

interface Props {
  initialValues: SignUpLoginAndPassForm;

  onSubmit(values: SignUpLoginAndPassForm): void;
}

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

const validateSchema = yup.object({
  login: yup
    .string()
    .required('Enter an username')
    .min(6)
    .max(40, 'Username must be exactly 40 characters'),
  password: yup
    .string()
    .required('Enter a password')
    .test(
      'is-password-valid',
      'Password must contain at least 1 digit, 1 lowercase letter, 1 capital letter, 1 special character, and must be 8+ characters',
      function (value) {
        return Boolean(value && value.length >= 8 && passwordRegex.test(value));
      }
    ),
  passwordConfirm: yup
    .string()
    .required('Enter a confirm password')
    .test('is-password-equals', `Password must match`, function (value) {
      const { password } = this.parent;
      return Boolean(password === value);
    }),
});

export const SignUpLoginAndPass = ({ initialValues, onSubmit }: Props) => {
  const showPasswordBool = useBoolean();
  const [checkAvailableLogin, { isLoading: isLodingCheckAvailableLogin }] =
    useCheckAvailableLoginMutation();
  const { setSnackBar } = useSnackBar();

  const formik = useFormik<SignUpLoginAndPassForm>({
    initialValues,
    validationSchema: validateSchema,
    validateOnChange: false,
    async onSubmit(values, formHelper) {
      try {
        const { isFree } = await checkAvailableLogin(
          encodeURIComponent(values.login)
        ).unwrap();
        if (isFree) {
          onSubmit(values);
        } else {
          const errText = `The username is not available.`;
          formHelper.setFieldError('login', errText);
          setSnackBar({
            isShow: true,
            type: 'error',
            message: errText,
          });
        }
      } catch (err: any) {
        setSnackBar({
          isShow: true,
          type: 'error',
          message: helper.formatErrors(err?.data),
        });
      }
    },
  });

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.containerTitle}>Join Netevia today!</div>
          <div className={styles.containerSubtitle}>
            Experience seamless money transfers, top-notch security, and
            exclusive rewards.
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className={styles.formWrapper}
          >
            <TextField
              disabled={isLodingCheckAvailableLogin}
              fullWidth
              type='text'
              variant='outlined'
              label='Username'
              error={Boolean(formik.errors.login && formik.touched.login)}
              helperText={formik.touched.login && formik.errors.login}
              {...formik.getFieldProps('login')}
            />

            <TextField
              disabled={isLodingCheckAvailableLogin}
              fullWidth
              label='Password'
              type={showPasswordBool.value ? 'text' : 'password'}
              sx={{ backgroundColor: 'transparent' }}
              error={Boolean(formik.errors.password && formik.touched.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={showPasswordBool.toggle}
                      edge='end'
                    >
                      {showPasswordBool.value ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('password')}
            />

            <TextField
              disabled={isLodingCheckAvailableLogin}
              fullWidth
              label='Confirm password'
              type={showPasswordBool.value ? 'text' : 'password'}
              sx={{ backgroundColor: 'transparent' }}
              error={Boolean(formik.touched.passwordConfirm && formik.errors.passwordConfirm)}
              helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={showPasswordBool.toggle}
                      edge='end'
                    >
                      {showPasswordBool.value ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...formik.getFieldProps('passwordConfirm')}
            />

            <Button
              type='submit'
              variant='contained'
              disabled={isLodingCheckAvailableLogin}
              loading={isLodingCheckAvailableLogin}
            >
              <div>Sign Up</div>
            </Button>
          </form>

          <div className={styles.appsContent}>
            <div>
              <span className={styles.appsContentText}>
                Already have an account?
              </span>{' '}
              <Link
                className={styles.appsContentLink}
                to={appRoutes.login()}
              >
                Log in
              </Link>
            </div>
            <div className={styles.appsContentDivider}>
              <Divider />
            </div>
            <GroupingStoreButton />
          </div>
        </div>
      </div>
    </AnimateWrapper>
  );
};
