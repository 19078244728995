import * as yup from 'yup';
import dayjs from 'dayjs';
import { requireNotNull } from '../utils/require-not-null';
import { states } from '../../constants/data';
import { onlyNumbers } from '../utils/pipes';

export interface SignUpLoginAndPassForm {
  login: string;
  password: string;
  passwordConfirm: string;
}

export interface SignUpAddAddressFrom {
  streetAddress: string;
  extendedAddress: string;
  locality: string;
  state: string;
  postalCode: string;
}

export interface SignUpPersonBaseForm {
  givenName: string;
  familyName: string;
  middleName: string;
  dateOfBirth: string | null | dayjs.Dayjs;
  email: string;
  phone: string;
  ssn: string;
}

export const signUpPersonalBaseInitialForm: SignUpPersonBaseForm = {
  givenName: '',
  familyName: '',
  middleName: '',
  phone: '',
  dateOfBirth: null,
  email: '',
  ssn: '',
};

export const signUpAddressInitialForm: SignUpAddAddressFrom = {
  streetAddress: '',
  extendedAddress: '',
  locality: '',
  state: '',
  postalCode: '',
};

export const signUpAddressValidateSchema = yup.object({
  streetAddress: yup.string().required('Enter an Address'),
  locality: yup.string().required('Enter a City'),
  state: yup.string().required('Choose a State'),
  postalCode: yup
    .string()
    .required('Enter a ZIP code')
    .min(5, 'Postal code must be exactly 5 characters')
    .max(5, 'Postal code must be exactly 5 characters'),
});

const forbiddenChars = ['’', ',', '.', '-'];

export const signUpPersonValidationSchema = yup.object({
  givenName: yup
    .string()
    .required('Enter a First name')
    .max(255, 'First name must be exactly 255 characters')
    .test(
      'test-chars',
      "Characters ‘’', ‘,’, ‘.’, ' ‘,’-' not allowed",
      (value) => {
        return forbiddenChars.every((c) => !value?.includes(c));
      }
    ),
  middleName: yup
    .string()
    .max(255, 'Middle name must be exactly 255 characters'),
  familyName: yup
    .string()
    .required('Enter a Last name')
    .min(2)
    .max(255, 'Last name must be exactly 255 characters'),
  dateOfBirth: yup
    .mixed()
    .required('Enter a Date of birth')
    .test(
      'test-birth-date',
      'You must be between 18 and 99 years old',
      (value) => {
        const diff = dayjs().diff(dayjs(value as any), 'years');
        return Boolean(diff && diff >= 18 && diff <= 99);
      }
    ),
  email: yup.string().email('Must be valid email').required('Enter an Email'),
  phone: yup
    .string()
    .required('Enter a Phone')
    .transform((val) => onlyNumbers(val))
    .min(10, 'Phone number must be exactly 10 characters')
    .max(10, 'Phone number must be exactly 10 characters'),
  ssn: yup
    .string()
    .required('Enter a SSN')
    .min(9, 'SSN must be exactly 9 characters')
    .max(9, 'SSN must be exactly 9 characters'),
});

export const formatSignUpAddress = <T extends SignUpAddAddressFrom>(
  val: T
): string => {
  return `${val.streetAddress}, ${
    val.extendedAddress ? `${val.extendedAddress},` : ''
  } ${val.locality}, ${val.state}, ${val.postalCode}`;
};

export const formatSignUpBirthOfDate = (
  date: SignUpPersonBaseForm['dateOfBirth']
) => {
  if (!date) {
    return 'invalid date';
  }
  if (dayjs.isDayjs(date)) {
    return date.format('DD/MM/YYYY');
  }
  return date;
};

export const getAddressNumberStateByLabel = (stateLabel: string) => {
  return requireNotNull(
    states.find(({ label }) => label.toLowerCase() === stateLabel.toLowerCase())
  ).value;
};

export const createArrByNumber = (n: number) =>
  Array(n)
    .fill(null)
    .map((_: any, i) => i + 1);

export const BUSINESS_TYPES_DATA = [
  {
    businessType: 0,
    description:
      "You've established a legally recognized entity with the ability to own assets and incur debts independently of its owners.",
    title: 'Corporation',
  },
  {
    businessType: 1,
    description:
      'Your business consists of two or more people who combine their resources to form a business and agree to share risks, profits, and losses.',
    title: 'Partnership',
  },
  {
    businessType: 2,
    description: 'Your business owned and operated by a single individual.',
    title: 'Sole Proprietorship',
  },
  {
    businessType: 4,
    description:
      'Your LLC is a business structure that combines limited liability protection with flexibility.',
    title: 'Limited Liability Company',
  },
  {
    businessType: 3,
    description:
      'Your organization is dedicated to advancing a mission rather than generating profit.',
    title: 'Non Profit',
  },
];

export const JOB_TITLE_DATA = [
  'President',
  'Vice President',
  'Treasurer',
  'Owner',
  'Chief Executive Officer',
  'Chief Financial Officer',
  'Chief Operating Officer',
];

export enum SignUpQsTypes {
  ownerPosition = 'ownpos',
}
