import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AnimateWrapper } from "../../components/animate-wrapper";
import { useCurrentUser } from "../../providers/user.provider";
import ButtonWithChevron from "components/button/buttonWithChevron";
import cardImg from "../../assets/svg/credit-card.svg";
import burnercCard from "assets/svg/burnercCard.svg";
import accountImg from "../../assets/svg/account-black.svg";
import ArrowLeft from "../../assets/svg/arrow-left.svg";
import styles from "./style.module.scss";
import { usePaymentCards } from "../../api/hooksApi/usePaymentCards";
import WarningModal from "../../components/modals/WarningModal";
import { useTranslationProvider } from "providers/translation/translation.provider";

const NewProduct = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const { paymentCards } = usePaymentCards();
  const { isSubUser, subUserRights, user } = useCurrentUser();

  const userOrsubUserRigthOpenFin =
    !isSubUser ||
    subUserRights({ openFinancialAccount: true, mainAccess: true });
  const userOrsubUserRigthCard =
    !isSubUser || subUserRights({ openBankingCard: true, mainAccess: true });

  const [warningModal, setWarningModal] = useState<boolean>(false);

  const warningText = t(
    "NewProduct.Only one primary card is available per authorized user",
    {
      defaultValue:
        "Only one primary card is available per authorized user. The primary card will use your billing address and phone number for verification purposes. The primary card has already been issued.",
    }
  );
  const handleNavigatePrimaryCard = (path) => {
    const findPrimaryCard = !!paymentCards.find(
      (card) =>
        card.attachedToSubProfile?.isMainCard === true &&
        card.attachedToSubProfile?.subProfileId === user?.id
    );
    findPrimaryCard ? setWarningModal(true) : navigate(path);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <AnimateWrapper className="fade">
      <div>
        <Link to="/home">
          <div className={styles.nav}>
            <img src={ArrowLeft} />
            <span className={styles.nav_title}>
              {t("NewProduct.Open New Product", {
                defaultValue: "Open New Product",
              })}
            </span>
          </div>
        </Link>
        <div className={styles.container}>
          <p className={styles.container_title}>
            {t("NewProduct.Choose one of the options", {
              defaultValue: "Choose one of the options",
            })}
            :
          </p>
          <div className={styles.container_boxButtons}>
            {userOrsubUserRigthOpenFin && (
              <div onClick={() => handleNavigate("./open-new-account")}>
                <ButtonWithChevron
                  text={t("NewProduct.Open new account", {
                    defaultValue: "Open new account",
                  })}
                  img={accountImg}
                />
              </div>
            )}

            {userOrsubUserRigthCard && (
              <>
                <div onClick={() => handleNavigate("./open-new-card")}>
                  <ButtonWithChevron
                    text={t("NewProduct.Open virtual card", {
                      defaultValue: "Open virtual card",
                    })}
                    img={cardImg}
                  />
                </div>
                <div onClick={() => handleNavigate("./open-physical-card")}>
                  <ButtonWithChevron
                    text={t("NewProduct.Open physical card", {
                      defaultValue: "Open physical card",
                    })}
                    img={cardImg}
                  />
                </div>
              </>
            )}

            {isSubUser && userOrsubUserRigthCard && (
              <div
                onClick={() => handleNavigatePrimaryCard("./open-primary-card")}
              >
                <ButtonWithChevron
                  text={t("NewProduct.Open primary card", {
                    defaultValue: "Open primary card",
                  })}
                  img={cardImg}
                />
              </div>
            )}
            <div onClick={() => handleNavigate("./open-burner-card")}>
              <ButtonWithChevron
                text={t("NewProduct.Open new  burner card", {
                  defaultValue: "Open new  burner card",
                })}
                img={burnercCard}
              />
            </div>
          </div>
        </div>
        <WarningModal
          titleMassage={warningText}
          onClose={() => setWarningModal(false)}
          isOpen={warningModal}
        />
      </div>
    </AnimateWrapper>
  );
};

export default NewProduct;
