import styles from './styles.module.scss';
import { TextInput } from 'shared/ui/inputs';
import { Select } from 'shared/ui/inputs';
import { MenuItem } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { FormikProps } from 'formik';

interface FormValues {
  payeeName: string;
  payeeType: string;
  accountNumber: string;
  routingNumber: string;
}
interface ExternalPayeeAddForm {
  formik: FormikProps<FormValues>;
}

export const ExternalPayeeAddForm = (props: ExternalPayeeAddForm) => {
  const { formik } = props;

  const disabedBtn =
    !formik.isValid ||
    !formik.values.payeeName ||
    !formik.values.payeeType ||
    !formik.values.accountNumber ||
    !formik.values.routingNumber;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.form}
    >
      <Typography>External account payee details</Typography>
      <div className={styles.box}>
        <TextInput
          label='Beneficiary name'
          autoComplete='off'
          {...formik.getFieldProps('payeeName')}
          error={formik.touched.payeeName && formik.errors.payeeName}
        />
        <Select
          label='Beneficiary type'
          {...formik.getFieldProps('payeeType')}
          error={formik.touched.payeeType && formik.errors.payeeType}
        >
          <MenuItem
            key={'Checking'}
            value={'Checking'}
          >
            <Typography>Checking</Typography>
          </MenuItem>
          <MenuItem
            key={'Savings'}
            value={'Savings'}
          >
            <Typography>Savings</Typography>
          </MenuItem>
        </Select>
        <TextInput
          label='Account number'
          autoComplete='off'
          {...formik.getFieldProps('accountNumber')}
          error={formik.touched.accountNumber && formik.errors.accountNumber}
        />
        <TextInput
          label='Routing number'
          autoComplete='off'
          {...formik.getFieldProps('routingNumber')}
          error={formik.touched.routingNumber && formik.errors.routingNumber}
        />
      </div>
      <Button
        type='submit'
        className={styles.btn}
        variant='contained'
        disabled={disabedBtn}
      >
        add payee
      </Button>
    </form>
  );
};
