import { useMemo } from 'react'

import {
  useExternalBankAccountsControllerQuery,
  AccountStatus,
  ExternalBankAccountsProvider,
} from '../endpoints/fundsMovement'
import helper from 'services/helper'

enum Type {
  Checking = 'Checking',
  Savings = 'Savings',
}

export interface Payee {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date | null
  accountNumber?: string
  routingNumber: string
  last4: string
  type: Type
  typename: string
  accountStatus: AccountStatus
  provider: ExternalBankAccountsProvider | null
}

type ExternalPayees = Omit<Payee, 'provider'> & { provider: null }

export type PlaidPayees = Omit<Payee, 'provider'> & {
  provider: ExternalBankAccountsProvider
}
interface UsePayeesReturnType {
  externalPayees: ExternalPayees[]
  plaidPayees: PlaidPayees[]
  payees: Payee[]
  isLoadingPayees: boolean
  isFetchingPayees: boolean
  refetchExternalPayees: () => void
}

export const useExternalPayees = (): UsePayeesReturnType => {
  const {
    data: externalBankPayee,
    isLoading: isLoadingExternalBankPayee,
    isFetching: isFetchingExternalBankPayee,
    refetch: refetchExternalPayees,
  } = useExternalBankAccountsControllerQuery()

  const value = useMemo<any>(() => {
    if (!externalBankPayee) {
      return { externalPayees: [], plaidPayees: [], payees: [] }
    }

    const payees = externalBankPayee
      ?.map((payee) => {
        const { externalBankAccountDetails } = payee

        return {
          name: payee.name,
          createdAt: externalBankAccountDetails.createdAt,
          last4: externalBankAccountDetails.last4,
          routingNumber: externalBankAccountDetails.routingNumber,
          type: helper.capitalizeFirstLetter(externalBankAccountDetails.type),
          typename: payee.typename,
          id: payee.id,
          updatedAt: payee.updatedAt,
          accountStatus: payee.accountStatus,
          provider: payee.provider,
        }
      })
      .filter((payee) => payee.accountStatus !== AccountStatus.CLOSED)

    const plaidPayees = payees.filter(
      (payee) =>
        payee.provider !== null &&
        [
          ExternalBankAccountsProvider.FINICITY,
          ExternalBankAccountsProvider.PLAID,
        ].includes(payee.provider)
    )

    const externalPayees = payees.filter((payee) => payee.provider == null)

    return {
      payees,
      externalPayees,
      plaidPayees,
    }
  }, [externalBankPayee])

  return {
    externalPayees: value.externalPayees,
    plaidPayees: value.plaidPayees,
    payees: value.payees,
    isLoadingPayees: isLoadingExternalBankPayee,
    isFetchingPayees: isFetchingExternalBankPayee,
    refetchExternalPayees,
  }
}
