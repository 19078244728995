import {
  SubUsersApi,
  useSubUsersControllerQuery,
} from '../../../../../../../../api/endpoints/subProfiles';
import { SubUsersList } from '../../../../../../../../components/SubUsersList';
import { Skeleton } from '../../../../../../../../components/skeleton';
import { useState } from 'react';
import { EditSubUser } from '../../../../../../AuthorizedSubUser/components/editSubUser';

export const SubUsers = () => {
  const { data: subUsers } = useSubUsersControllerQuery();
  const [selectedSubUserToEdit, setSelectedSubUserToEdit] =
    useState<SubUsersApi>();

  const handleOpenEditSubUser = (id: number) => {
    const subUser = subUsers?.find((user) => user.id === id);
    if (subUser) {
      setSelectedSubUserToEdit(subUser);
    }
  };

  const onCloseEditSubuser = () => {
    setSelectedSubUserToEdit(undefined);
  };

  if (!subUsers?.length) {
    return (
      <Skeleton
        height='50px'
        width='100%'
      />
    );
  }

  return (
    <>
      <SubUsersList
        data={subUsers}
        onClick={handleOpenEditSubUser}
      />
      {selectedSubUserToEdit && (
        <EditSubUser
          isShow={true}
          onClose={onCloseEditSubuser}
          subUserGivenName={selectedSubUserToEdit?.givenName}
          subUserFamilyName={selectedSubUserToEdit?.familyName}
          subId={selectedSubUserToEdit?.id}
          readonly={true}
          isConnectedAccountsHidden={true}
        />
      )}
    </>
  );
};
