import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import { FinancialAccount } from 'entities/transfers'
import helper from 'services/helper'
import cn from 'classnames'
import TooltipText from 'shared/components/toolTip/toolTipText'

interface SourceAccountItemProps {
  from: FinancialAccount
  totalAmount: number
  visibleError: boolean
}

export const SourceAccountItem = (props: SourceAccountItemProps) => {
  const { from, totalAmount, visibleError } = props

  const styleWrapper = cn(styles.wrapper, {
    [styles.errorWrapper]: visibleError,
  })

  const styleAmount = cn(styles.text, {
    [styles.errorText]: visibleError,
  })

  return (
    <div className={styleWrapper}>
      <div className={styles.box}>
        <TooltipText
          label={from.name}
          maxLength={12}
        />
        <Typography>({from.last4Number})</Typography>
      </div>

      <Typography className={styleAmount}>
        ${helper.moneyFormat(totalAmount)}
      </Typography>
    </div>
  )
}
