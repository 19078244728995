import { Button, Skeleton } from "@mui/material";
import styles from "./style.module.scss";
import { MoonLoader } from 'react-spinners';
import cx from 'classnames';

interface ActionTileProps {
  title: string;
  subTitle?: string | JSX.Element;
  infoImg: any;
  actionImg?: any;
  onClick?: () => void;
  isLoading?: boolean;
  actionController?: JSX.Element;
  isActionLoading?: boolean;
  isFullClickable?: boolean;
}
export const ActionTile = ({
  title,
  subTitle,
  infoImg,
  actionImg,
  onClick,
  isLoading,
  actionController,
  isActionLoading,
  isFullClickable,
}: ActionTileProps) => {
  return isLoading ? (
    <Skeleton height={50} width={200} />
  ) : (
    <div
      onClick={isFullClickable && onClick ? onClick : undefined}
      className={cx(styles.container, isFullClickable && styles.containerClickable)}
    >
      <div className={styles.container_start}>
        <img className={styles.infoImg} src={infoImg} />
        <div className={styles.container_content}>
          <div className={styles.container_title}>{title}</div>
          {subTitle && (
            <div className={styles.container_subTitle}>{subTitle}</div>
          )}
        </div>
      </div>

      {isActionLoading ? (
          <div style={{ position: 'relative', right: '15px' }}>
            <MoonLoader size="20px" />
          </div>
      ) : (
        actionImg && !!onClick && (
          <div className={styles.boxIcon}>
            <img src={actionImg} />
          </div>
        )
      )}

      {!!actionController && actionController}
    </div>
  );
};
