import { EnumTransactionStatuses, Transaction } from '../../../../../api/endpoints/transaction';
import { colors } from '../../../../../theme';
import helper from '../../../../../services/helper';
import { useMediaQuery } from '@mui/material';

interface Props {
  transaction: Transaction;
  hideTransactionBalance?: boolean;
}

export const TransactionHints = ({ transaction, hideTransactionBalance }: Props) => {
  const isPending = transaction?.status === EnumTransactionStatuses.PENDING;
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {transaction?.clearAmount && isPending && (
        <div style={{ color: colors.primaryBlue, fontSize: '12px' }}>
          {
            isMobile ? (
              <>Partial clearing $ {helper.formatUsdValue(transaction.clearAmount.value)}</>
            ) : (
              <>
                Partial clearing <br/>
                $ {helper.formatUsdValue(transaction.clearAmount.value)} / $ {helper.formatUsdValue(transaction.amount.value)}
              </>
            )
          }
        </div>
      )}
      {transaction?.pendingAmount && isPending && (
        <div style={{ color: '#949494', fontSize: '12px' }}>
          Remaining $ {helper.formatUsdValue(transaction.pendingAmount.value)}
        </div>
      )}
      {!hideTransactionBalance && transaction.balance && !isPending && (
        <div
          style={{ color: '#949494', fontSize: '12px' }}
        >{`$ ${helper.formatUsdValue(transaction.balance.value)}`}</div>
      )}
    </>
  )
}
