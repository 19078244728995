import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import styles from './styles.module.scss';
import { useAppDispatch, useAppSelector } from 'shared/models';
import { findObjById } from 'entities/transfers';
import { createToMyOwnAccountSchema } from 'features/transfers/transfer-to-my-own-account/model';
import { ToMyOwnAccountForm } from './to-my-own-accoutn-form';
import helper from 'services/helper';
import { TransitionAnimation } from 'shared/ui/animations';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import {
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
  selectTransferMemo,
  setFromFinancialAccount,
  setToFinancialAccount,
  setAmount,
  setMemo,
} from 'features/transfers/transfer-to-my-own-account/model';
import { Skeleton } from 'components/skeleton';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';

export const MakeTransfer = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)
  const memo = useAppSelector(selectTransferMemo);

  const formik = useFormik({
    initialValues: {
      from: fromFinancialAccount?.id || '',
      to: toFinancialAccount?.id || '',
      amount: amount || '',
      memo: memo
    },
    validationSchema: createToMyOwnAccountSchema(unclosedFinancialAccounts),
    onSubmit: (form) => {
      dispatch(
        setFromFinancialAccount({
          value: findObjById(form.from, unclosedFinancialAccounts),
        })
      );
      dispatch(
        setToFinancialAccount({
          value: findObjById(form.to, unclosedFinancialAccounts),
        })
      );
      dispatch(setAmount({ value: form.amount }));
      dispatch(setMemo({ value: form.memo }));
      goNext('1');
    },
  });

  const createOptionsToAcc = (
    accounts: FinancialAccount[],
    idToRemove: string
  ) => {
    return accounts
      .filter((account) => account.financialAccountId !== idToRemove)
      .map((account) => ({
        id: account.financialAccountId,
        value: account.financialAccountId,
        content: (
          <div className={styles.dropDown}>
            <div>{`${formatAccountName(
              account.name
            )} (****${account.accountNumber.slice(-4)})`}</div>
            <div>{`$${
              helper.moneyFormat(account?.availableCash?.value) || ''
            }`}</div>
          </div>
        ),
      }));
  };

  const optionsToAcc = createOptionsToAcc(
    unclosedFinancialAccounts,
    formik.values.from
  );

  const optionsFromAcc = createOptionsToAcc(
    unclosedFinancialAccounts,
    formik.values.to
  );

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <ToMyOwnAccountForm
        formik={formik}
        optionsFromAcc={optionsFromAcc}
        optionsToAcc={optionsToAcc}
      />
    </TransitionAnimation>
  );
};
