import { useEffect } from 'react';

export const useScrollToMuiFieldAfterError = (watchElement: boolean) => {
  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    if (!el) {
      return undefined;
    }

    const elementPosition = el.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 100;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });

    setTimeout(
      () => (document.querySelector('.Mui-error .MuiInputBase-input') as any)?.focus(),
      500
    );
  }, [watchElement]);
};
