import React, { FC } from "react";
import moment from "moment";
import helper from "../../../../services/helper";
import styles from "./style.module.scss";
import { AnimateWrapper } from "../../../../components/animate-wrapper";
import cx from 'classnames';
import { SvgIcon } from '../../../../shared/components/svgIcon';
import { upperFirst } from '../../../../shared/utils/string';
import { CurrencyCodeSign } from '../../../../enums';

interface TransactionShowDetailProps {
  events: any;
  detailsTransaction: boolean;
  remainingClearAmount?: number;
}

interface EventItemProps {
  isDraft?: boolean;
  name: string;
  status: string;
  value: string;
  hint: string;
}

const EventItem = ({ isDraft, name, status, value, hint }: EventItemProps) => (
  <div className={styles.boxItem}>
    <div className={cx(styles.successCirlce, isDraft && styles.successCirlceDraft)}>
      <SvgIcon name="SuccessMark" sizePx={24}/>
    </div>
    <div className={styles.eventInfoWrapper}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div className={styles.eventName}>{name}</div>
        <div className={styles.eventStatus}>
          {status}
        </div>
      </div>
      <div className={cx(styles.eventAmountDate)}>
        <div className={styles.eventValue}>
          {value}
        </div>
        <div className={styles.eventStatus}>{hint}</div>
      </div>
    </div>
  </div>
)

const TransactionShowDetail: FC<TransactionShowDetailProps> = ({
  events,
  detailsTransaction,
  remainingClearAmount,
}) => {
  const renderCardTransctionsDetail = () => {
    return (
      events &&
      detailsTransaction && (
        <AnimateWrapper className="fade">
          <div className={styles.wrapper}>
            {remainingClearAmount && (
              <div>
                <EventItem
                  isDraft
                  name="Clearing"
                  status="Pending"
                  value={`$ ${helper.formatUsdValue(remainingClearAmount)}`}
                  hint="Remaining amount"
                />
                <div className={styles.separator}/>
              </div>
            )}
            {events?.map((item, index, arr) => {

              const isReversal = item?.__typename === 'ReversalEvent';
              const i = item;
              let amount = i?.requestedAmount?.value;
              let symbol = i?.requestedAmount?.symbol;
              let eventName = i?.__typename
                ?.replace('Event', '')
                .replace(/(?=[A-Z])/g, ' ')
                ?.replace(' And ', ' and ');

              let date = moment(i?.createdAt).format('DD MMM YYYY hh:mm A');
              let responseCode = i?.responseCode
                ?.replace('_', ' ')
                ?.replace('_', ' ');


              let postfix = '';
              const isAuthorizationEvent = ['AuthorizationEvent', 'AuthorizationAndClearEvent'].includes(item?.__typename);
              const raCurCode = i?.requestedAmount?.currencyCode;
              if (isAuthorizationEvent && raCurCode && raCurCode !== 'USD') {
                postfix = ` / ${CurrencyCodeSign[raCurCode] || raCurCode} ${helper.formatUsdValue(i?.requestedAmount.value)}`
              }

              if (i?.approvedAmount?.value) {
                amount = i?.approvedAmount?.value;
                symbol = i?.approvedAmount?.symbol;
              }

              if (!isReversal && item?.transactionSource?.typename === 'CreditTransaction') {
                eventName = 'Refund';
                amount = item?.amount?.value;
                symbol = item?.amount?.symbol;
              }

              if (!isReversal && item?.transaction?.__typename === 'CreditTransaction') {
                eventName = 'Refund';
                amount = i?.approvedAmount?.value;
                symbol = i?.approvedAmount?.symbol;
              }
              if (i?.__typename === 'IssuerPreliminaryAuthorizationEvent') {
                eventName = 'Pre-authorization';
                amount = i?.approvedAmount?.value;
                symbol = i?.approvedAmount?.symbol;
              }

              if (i?.createdAt === '0001-01-01T00:00:00') {
                date = moment(item?.createdAt).format(
                  'DD MMM YYYY hh:mm A, ddd'
                );
              }

              if (i?.responseCode === 'APPROVED_FOR_PARTIAL_AMOUNT') {
                responseCode = 'APPROVED';
              }

              return (
                <div key={index}>
                  <EventItem
                    name={eventName}
                    status={responseCode ? upperFirst(responseCode.toLowerCase()) : '-'}
                    value={`${symbol ? symbol : ''}$ ${helper.formatUsdValue(amount)}${postfix}`}
                    hint={date}
                  />
                  {arr.length - 1 !== index && (
                    <div className={styles.separator}/>
                  )}
                </div>
              );
            })}
          </div>
        </AnimateWrapper>
      )
    );
  };
  return <>{renderCardTransctionsDetail()}</>;
};
export default TransactionShowDetail;
