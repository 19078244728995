import { AnimateWrapper } from "components/animate-wrapper"
import SimpleContainer from "shared/components/containers/simpleContainer"
import { AccountHeader } from "./AccountHeader"
import { AccountTransactions } from "./AccountTransactions"
import { FilterTransactionsControls } from "./FilterTransactionsControls"
import { Typography } from "shared/ui/typography"

import { useExternalAccountById } from "api/hooksApi/useExternalAccountById"
import { useNavigateToExternal } from "pages/home/hooks"

import styles from './style.module.scss';

const ExternalAccount = () => {
  const { account } = useExternalAccountById();
  const { navigateToExternalBank } = useNavigateToExternal()

  return (
    <SimpleContainer
      title={'Your Account'}
      onTitleClick={() => navigateToExternalBank(account?.bankName ?? 'External Bank')}
    >
      <AnimateWrapper className='fade'>
        <div className={styles.container}>
          <AccountHeader />
          <Typography variant='h6'>Transactions</Typography>
          <FilterTransactionsControls />
          <AccountTransactions />
        </div>
      </AnimateWrapper>
    </SimpleContainer>
  )
}

export default ExternalAccount;


