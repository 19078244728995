import DrawerModal from '../../shared/components/drawerModal';
import React, { useEffect, useRef } from 'react';
import { VerificationDocument } from '../verificationDocument';
import { useCurrentUser } from '../../providers/user.provider';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { useSnackBar } from '../../providers/snackBar.provider';
import styles from './style.module.scss';
import { FileFormatsHint } from '../FileFormatsHint';
import { useDocumentUploadRequirementQuery } from '../../api/endpoints/account';

export const RequestDocsDrawer = () => {
  const { user } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const showDrawerBool = useBoolean(false);
  const ref = useRef(localStorage.getItem('isFromLogin'));
  const isLoginReferrer = ref.current === "1";
  const { data: docs } = useDocumentUploadRequirementQuery({}, { skip: !isLoginReferrer });

  useEffect(() => {
    localStorage.removeItem('isFromLogin');
  }, []);

  useEffect(() => {
    let timeout: any;
    if (isLoginReferrer && docs && Object.values(docs)?.find(s => s?.requiredDocuments?.find(d => d?.status !== 'SUBMITTED'))) {
      timeout = setTimeout(() => {
        showDrawerBool.setTrue();
      }, 10_000);
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [docs]);

  const closeDrawer = () => {
    showDrawerBool.setFalse();
  };

  return (
    <DrawerModal
      isCloseOnBackdropDisabled
      onClose={closeDrawer}
      isShow={showDrawerBool.value}
      titleText="Document verification"
    >
      <div>
        <div className={styles.topWrapper}>
          <div className={styles.text}>
            To complete your application and verify your account, we need to verify the documents below.
          </div>
          <FileFormatsHint />
        </div>
        <VerificationDocument
          isBackButtonHidden
          userId={user?.id}
          successText="'Your application has been successfully submitted, and we have received all the required documents.'"
          submitButtonText="submit"
          onAllDocsSubmit={() => {
            closeDrawer();
            setSnackBar({
              isShow: true,
              type: 'success',
              message: 'Your application has been successfully submitted, and we have received all the required documents.'
            });
          }}
        />
      </div>
    </DrawerModal>
  );
};
