import React from "react";
import styles from "./style.module.scss";
import logo from "../../../../../assets/svg/Layer.svg";
import share from "../../../../../assets/svg/share-1.svg";
import route from "../../../../../assets/svg/route.svg";
import helper from "../../../../../services/helper";
import { useTranslationProvider } from "providers/translation/translation.provider";

const ATMListItem = ({ key, item }: any) => {
  const { t } = useTranslationProvider();
  console.log(key);
  //key = undefined
  const hours = !!item?.features?.length
    ? item.features[0].replace(/_/g, " ").toUpperCase()
    : "";

  return (
    <div key={key} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.row_wrap}>
          <p className={styles.label}>
            {t("Settings.Address", {
              defaultValue: "Address",
            })}
            :
          </p>
          <p className={styles.text}> {helper.formatAddress(item.address)}</p>
        </div>
        <div className={styles.row_logo}>
          <img src={logo} width={110} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.row_wrap}>
          <p className={styles.label}>
            {t("common.Working hours", {
              defaultValue: "Working hours",
            })}
            :
          </p>
          <p className={styles.text}> {hours}</p>
        </div>
        <div className={styles.row_share}>
          <img src={route} width={32} height={32} />
          <img src={share} width={32} height={32} />
        </div>
      </div>
    </div>
  );
};

export default ATMListItem;
