export enum TypeGiftCards {
  Archive = 'Archive',
  Active = 'Active',
}

export enum GiftCardMethod {
  PayUsingYourFinancialAccount = 'Pay using your financial account',
  PayWithRewardPoints = 'Pay with reward points',
}

export enum BrandsSort {
  AZ = 'A-Z',
  ZA = 'Z-A',
  DescendingDiscount = 'Descending Discount',
  AscendingDiscount = 'Ascending Discount',
}

export enum BrandsCategory {
  Baby = 'Baby',
  Beauty = 'Beauty',
  Books = 'Books',
  Cars = 'Cars',
  Charity = 'Charity',
  Craft = 'Craft',
  Cryptocurrency = 'Cryptocurrency',
  Cycling = 'Cycling',
  DepartmentStore = 'Department Store',
  Fashion = 'Fashion',
  Electronics = 'Electronics',
  FoodDrink = 'Food & Drink',
  Gaming = 'Gaming',
  Home = 'Home',
  Jewellery = 'Jewellery',
  Music = 'Music',
  Other = 'Other',
  SchoolVouchers = 'School Vouchers',
  Sports = 'Sports',
  Supermarket = 'Supermarket',
  Toys = 'Toys',
  TravelLeisure = 'Travel & Leisure',
  TVMovies = 'TV & Movies',
}
