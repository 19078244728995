import { FC } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import styles from './style.module.scss'
import cn from 'classnames'

interface TooltipTextProps {
  label: string
  maxLength: number
  description?: string
  stylesWrapper?: any
  stylesToolTip?: any
  stylesArrow?: any
}

const TooltipText: FC<TooltipTextProps> = ({
  label,
  maxLength,
  description,
  stylesWrapper,
  stylesToolTip,
  stylesArrow,
}) => {
  const renderReductionText = () => {
    if (label?.length - 1 >= maxLength) {
      return (
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={description ? description : label}
          arrow
          classes={{
            tooltip: cn(styles.toolTip, stylesToolTip),
            arrow: cn(styles.arrow, stylesArrow),
          }}
        >
          <p className={stylesWrapper}>{label.slice(0, maxLength)}...</p>
        </Tooltip>
      )
    } else {
      return <p className={stylesWrapper}>{label}</p>
    }
  }

  return renderReductionText()
}

export default TooltipText
