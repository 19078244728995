import styles from './styles.module.scss';
import { TextInput } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import { FormikProps } from 'formik';
import { Typography } from 'shared/ui/typography';
interface FormValues {
  accountName: string;
  accountNumber: string;
}
interface PayeeAddFormrops {
  formik: FormikProps<FormValues>;
}

export const NeteviaPayeeAddForm = (props: PayeeAddFormrops) => {
  const { formik } = props;

  const disabedBtn =
    !formik.isValid ||
    !formik.values.accountName ||
    !formik.values.accountNumber;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.form}
    >
      <Typography className={styles.title}>
        Netevia’s account payee details
      </Typography>
      <div className={styles.box}>
        <TextInput
          label='Account holder name'
          {...formik.getFieldProps('accountName')}
        />
        <TextInput
          label='Account number'
          {...formik.getFieldProps('accountNumber')}
          error={formik.touched.accountNumber && formik.errors.accountNumber}
        />
      </div>
      <Button
        className={styles.btn}
        type='submit'
        variant='contained'
        disabled={disabedBtn}
      >
        add payee
      </Button>
    </form>
  );
};
