import { useParams } from 'react-router-dom';
import { requireNotNull } from '../utils/require-not-null';

interface ReturnType {
  cardId: string;
  accountId: string;
}

export const useCardRouteParams = (): ReturnType => {
  const { cardId, accountId } = useParams();

  return {
    cardId: requireNotNull(cardId),
    accountId: requireNotNull(accountId),
  }
}
