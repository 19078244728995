import { useRefreshAuth } from './useRefreshAuth';
import { useLogoutAfterTimeout } from './useLogoutAfterTimeout';
import { Route, Routes } from 'react-router-dom';
import { HomeLayout } from '../../layouts/HomeLayout';
import NewProduct from '../../pages/newProduct';
import NewAccount from '../../shared/components/newAccount';
import NewVirtualCard from '../../shared/components/newVirtualCard';
import NewPhisicalCard from 'shared/components/newPhysicalCard';
import NewBurnerCard from 'shared/components/newBurnerCard';
import AccountCreated from '../../pages/newAccount/AccountCreated';
import AuthorizedSubUser from '../../pages/settings/AuthorizedSubUser';
import Transfer from '../../pages/transfer';
import { AccountComponent } from 'pages/accountComponent/index';
import Limits from 'pages/cardTransactions/limits/limits';
import AutorizedUserCards from '../../pages/cardTransactions/AutorizedUserCards';
import SetUpPin from '../../pages/cardTransactions/SetUpPin';
import OrderSuccess from '../../pages/cardTransactions/OrderSuccess';
import { Navigate } from 'react-router-dom';
import ATMLocation from '../../shared/components/atmLocation';
import ReportProblem from '../../shared/components/reporProblem';
import NewPrimaryCard from '../../shared/components/newPrimaryCard';
import SpendingReport from 'pages/spendingReport';
import AccountSettings from 'pages/settings/accountSettings';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useEffect, useState } from 'react';
import Security from 'pages/settings/security';
import { Integrations } from 'pages/settings/Integrations';
import NewProductContainer from 'shared/components/containers/newProductContainer';
import { CardComponent } from 'pages/cardComponent';
import { CardManageComponent } from 'pages/cardManage';
import Home from 'pages/home';
import {
  appRoutes,
  CardSubRoute,
  TransferRoute,
  RewardsRouteSubRoute,
  SettingsRouteSubRoute,
} from '../../routes';
import { CardTransactionsDeprecated } from '../../pages/cardTransactionsDeprecated';
import { BatchTransfer } from 'features/transfers/transfer-batch';
import { GiftCard } from 'pages/reward/components/giftCard';
import { Dashboard } from 'pages/reward/components/dashboard';
import HistoryRewards from 'pages/reward/components/historyRewards';
import { RewardLayout } from 'pages/reward/rewardLayout';
import Support from 'pages/support';
import { AllCards } from 'pages/allCards';
import { HomePageProvider } from 'pages/home/components/homeLists/provider';
import { ExternalAccountComponent } from 'pages/externalAccountComponent';
import { ExternalBank } from 'pages/externalBank';
import { AccountBalanceProvider } from 'pages/home/components/accountBalance/provider';
import { useCurrentUser } from '../../providers/user.provider';

export const AuthorizedRoutes = () => {
  const { currentLanguage } = useTranslationProvider();
  const [, setlang] = useState('');
  const { userIsLoading } = useCurrentUser();

  useEffect(() => {
    setlang(currentLanguage);
  }, [currentLanguage]);

  useRefreshAuth();
  useLogoutAfterTimeout();

  if (userIsLoading) {
    return null;
  }

  return (
    <HomeLayout>
      <Routes>
        <Route path={appRoutes.home()}>
          <Route
            index
            element={
              <AccountBalanceProvider>
                <Home />
              </AccountBalanceProvider>
            }
          />
          <Route
            path={appRoutes.homeAllCards()}
            element={
              <HomePageProvider>
                <AllCards />
              </HomePageProvider>
            }
          />
          <Route path='open-new-product'>
            <Route
              index
              element={<NewProduct />}
            />
            <Route
              path='open-new-account'
              element={<NewAccount />}
            />
            <Route
              path='open-new-card'
              element={<NewVirtualCard />}
            />
            <Route
              path='open-physical-card'
              element={<NewPhisicalCard />}
            />
            <Route
              path='open-burner-card'
              element={<NewBurnerCard />}
            />
            <Route
              path='open-primary-card'
              element={
                <NewProductContainer>
                  <NewPrimaryCard />
                </NewProductContainer>
              }
            />
            <Route
              path='open-new-burner-card'
              element={<NewBurnerCard />}
            />
            <Route
              path='account-created'
              element={<AccountCreated />}
            />
          </Route>
        </Route>
        <Route path={appRoutes.settings()}>
          <Route
            index
            element={<AccountSettings />}
          />
          <Route
            path={SettingsRouteSubRoute.security}
            element={<Security />}
          />
          <Route
            path='integrations'
            element={<Integrations />}
          />

          <Route
            path='subuser'
            element={<AuthorizedSubUser />}
          />
          <Route
            path={SettingsRouteSubRoute.support}
            element={<Support />}
          />
        </Route>
        <Route path={appRoutes.transfer()}>
          <Route
            index
            element={<Transfer />}
          />
          <Route
            path={appRoutes.transfer(TransferRoute.batchTransfer)}
            element={<BatchTransfer />}
          />
        </Route>
        <Route
          path='/card-transactions/:cardId*'
          element={<CardTransactionsDeprecated />}
        />
        <Route path={appRoutes.accountById(':accountId')}>
          <Route
            index
            element={<AccountComponent />}
          />
          <Route
            path='report-problem'
            element={<ReportProblem />}
          />
        </Route>
        <Route path={appRoutes.othereBankByName(':bankName')}>
          <Route
            index
            element={<ExternalBank />}
          />
        </Route>
        <Route path={appRoutes.externalAccountById(':accountId')}>
          <Route
            index
            element={<ExternalAccountComponent />}
          />
        </Route>
        <Route path={appRoutes.cardById(':accountId', ':cardId')}>
          <Route
            index
            element={<CardComponent />}
          />
          <Route
            path={CardSubRoute.manageCard}
            element={<CardManageComponent />}
          />
          <Route
            path={CardSubRoute.limits}
            element={<Limits />}
          />
          <Route
            path={CardSubRoute.autorizedCards}
            element={<AutorizedUserCards />}
          />
          <Route
            path='setup-up'
            element={<SetUpPin />}
          />
          <Route
            path='order-physical-card/order-success'
            element={<OrderSuccess />}
          />
          <Route
            path='transaction-spending-report'
            element={<SpendingReport />}
          />
          <Route
            path='report-problem'
            element={<ReportProblem />}
          />
        </Route>
        <Route path='/atm-location'>
          <Route
            index
            element={<ATMLocation />}
          />
        </Route>

        <Route
          path={appRoutes.rewards()}
          element={<RewardLayout />}
        >
          <Route
            path={RewardsRouteSubRoute.dashboard}
            element={<Dashboard />}
          />
          <Route
            path={RewardsRouteSubRoute.history}
            element={<HistoryRewards />}
          />
          <Route
            path={RewardsRouteSubRoute.myGiftCard}
            element={<GiftCard />}
          />
        </Route>
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
    </HomeLayout>
  );
};
