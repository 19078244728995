import { useEffect } from 'react'
import { Popup } from 'components/popup'
import { Typography } from 'shared/ui/typography'
import styles from './style.module.scss'
import { CodeInput } from 'shared/ui/inputs'
import { Button } from 'shared/ui/buttons'
import BeatLoader from 'react-spinners/BeatLoader'
import TimerSMS from 'pages/login/components/forgotPassword/components/timerSMS'

const INPUT_LENGTH = 6

interface AuntificatorSmsProps {
  code: string
  setCode: (code: string) => void
  onSubmit: () => Promise<void>
  isLoadingOnSubmit: boolean
  onSend: () => void
  isLoadingOnSend: boolean
  nextSendDate: Date | undefined
  setNextSendDate: (timer: Date | undefined) => void
  onClose: () => void
  phonePath: string
}

export const AuntificatorSms = (props: AuntificatorSmsProps) => {
  const {
    code,
    setCode,
    onSubmit,
    isLoadingOnSubmit,
    onSend,
    isLoadingOnSend,
    nextSendDate,
    setNextSendDate,
    onClose,
    phonePath,
  } = props

  const renderTimerOrResend = () => {
    return nextSendDate ? (
      <TimerSMS
        targetDate={nextSendDate}
        onTimerComplete={setNextSendDate}
      />
    ) : (
      <Button
        onClick={() => onSend()}
        variant='text'
        className={styles.btnResend}
      >
        Resend code
      </Button>
    )
  }

  const isButtonDisabled = code.length < INPUT_LENGTH

  return (
    <Popup onClose={onClose}>
      <div className={styles.wrapper}>
        <Typography className={styles.title}>
          Please enter the 6-digit security code received via text message sent
          to number ending <span className={styles.phonePath}>{phonePath}</span>
        </Typography>

        <CodeInput
          value={code}
          setValue={setCode}
          inputLength={INPUT_LENGTH}
          stylesContainer={styles.wrapperCodeInput}
        />

        <div>{isLoadingOnSend ? <BeatLoader /> : renderTimerOrResend()}</div>
        <div className={styles.boxBtn}>
          <Button
            variant='text'
            onClick={onClose}
          >
            Done
          </Button>
          <Button
            variant='contained'
            onClick={onSubmit}
            disabled={isButtonDisabled || isLoadingOnSubmit}
            loading={isLoadingOnSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Popup>
  )
}
