import React, { PropsWithChildren, useMemo, useState } from 'react';
import { requireNotNull } from '../../shared/utils/require-not-null';
import dayjs from 'dayjs';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { useRegistrationPersonalMutation } from '../../api/endpoints/user';
import { states } from '../../constants/data';
import { useSnackBar } from '../../providers/snackBar.provider';
import helper from '../../services/helper';
import { getAddressNumberStateByLabel, SignUpAddAddressFrom, signUpAddressInitialForm, SignUpLoginAndPassForm, signUpPersonalBaseInitialForm, SignUpPersonBaseForm } from '../../shared/signUp/shared';
import { onlyNumbers } from '../../shared/utils/pipes';


export interface SignUpPersonalBaseInfoForm extends SignUpPersonBaseForm, SignUpAddAddressFrom {
}

interface InternalAgreementForm {
  isAgreementAccepted: boolean;
}

type ContextForm = SignUpLoginAndPassForm &
  SignUpPersonalBaseInfoForm &
  InternalAgreementForm;

export enum PersonalSignUpScreen {
  loginAndPass,
  baseInfo,
  confirm,
  applicationStatus,
}

interface ContextProps {
  contextForm: ContextForm;

  updateContextForm(values: Partial<ContextForm>): void;

  screen: PersonalSignUpScreen;

  setScreen(nextScreen: PersonalSignUpScreen): void;

  isSubmitApplicationLoading: boolean;

  submitSignUpApplication(): void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const SignUpPersonalProvider = ({ children }: PropsWithChildren) => {
  const [screen, setScreen] = useState(PersonalSignUpScreen.loginAndPass);
  const apiLoadingBool = useBoolean();
  const [sendApplication] = useRegistrationPersonalMutation();
  const { setSnackBar } = useSnackBar();
  const [contextForm, setContextForm] = useState<ContextForm>({
    login: '',
    password: '',
    passwordConfirm: '',

    isAgreementAccepted: false,

    ...signUpPersonalBaseInitialForm,
    ...signUpAddressInitialForm,
  });

  const value: ContextProps = useMemo(() => {
    return {
      screen,
      contextForm: contextForm,
      isSubmitApplicationLoading: apiLoadingBool.value,
      updateContextForm(values: Partial<ContextForm>) {
        setContextForm((curValues) => {
          const newValues = { ...curValues };
          Object.keys(values).forEach((key) => {
            newValues[key] = values[key];
          });
          return newValues;
        });
      },
      setScreen(screen) {
        setScreen(screen);
        window.scrollTo({ top: 0 });
      },
      async submitSignUpApplication() {
        try {
          apiLoadingBool.setValue(true);
          const birthDate = requireNotNull(contextForm.dateOfBirth);
          await sendApplication({
            email: contextForm.email,
            givenName: contextForm.givenName,
            familyName: contextForm.familyName,
            middleName: contextForm.middleName,
            phone: onlyNumbers(contextForm.phone),
            dateOfBirth: dayjs.isDayjs(birthDate)
              ? birthDate.toISOString()
              : birthDate,
            streetAddress: contextForm.streetAddress,
            extendedAddress: contextForm.extendedAddress,
            postalCode: contextForm.postalCode,
            locality: contextForm.locality,
            ssn: contextForm.ssn,
            state: getAddressNumberStateByLabel(contextForm.state),
            login: contextForm.login,
            password: contextForm.password,
          }).unwrap();

          setScreen(PersonalSignUpScreen.applicationStatus);
        } catch (err: any) {
          setSnackBar({
            isShow: true,
            type: 'error',
            message: helper.formatErrors(err?.data),
          });
        } finally {
          apiLoadingBool.setValue(false);
        }
      },
    };
  }, [contextForm, screen, apiLoadingBool.value, sendApplication]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSignUpPersonal = () =>
  requireNotNull(React.useContext(Context));
