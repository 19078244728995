import infoIcon from "assets/svg/info-blue.svg";
import { List } from "@mui/material";
import styled, { css } from "styled-components";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";

interface InfoitemProps {
  label?: string;
  infoItems?: string[];
  tooltipText?: string;
}

export const InfoItem = ({ label, infoItems, tooltipText }: InfoitemProps) => {
  return (
    <Container>
      <Label>
        {label}
        {tooltipText && (
          <TooltipChildren description={tooltipText}>
            <img src={infoIcon} />
          </TooltipChildren>
        )}
      </Label>
      <Content>
        <ListInfoItems disablePadding>
          {infoItems
            ?.filter((item) => !item.includes("undefined"))
            ?.map((item) => <li key={item}>{item}</li>)}
        </ListInfoItems>
      </Content>
    </Container>
  );
};
const Container = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const Label = styled("div")`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  gap: 8px;
  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;
const Content = styled("div")`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
`;
const ListInfoItems = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0px;
`;
