import CheckCircle from "../../assets/svg/check-circle.svg";
import NavigateButton from "../../components/button/NavigateButton";
import { setError, setForgotLoginStatus } from "../../store/login/loginSlice";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AnimateWrapper } from "../../components/animate-wrapper";
import { useTranslationProvider } from "providers/translation/translation.provider";

const AccountCreated = () => {
  const { t } = useTranslationProvider();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(setError(""));
    dispatch(setForgotLoginStatus());
  };

  useEffect(() => {
    return () => {
      dispatch(setForgotLoginStatus());
    };
  }, []);

  return (
    <AnimateWrapper className="fade">
      <div className="flex flex-col mb-40">
        <img src={CheckCircle} width="90px" />
        <AnimateWrapper className="fade" delay={50}>
          <p className="text-2xl my-4">
            {t("Account.Account created", {
              defaultValue: "Account created!",
            })}
          </p>
        </AnimateWrapper>
        <AnimateWrapper className="fade" delay={150}>
          <div className="mb-2 my-2">
            <p className="text-stone-600 w-80 text-base mt-12">
              {t("Account.Congratulations The account has been created", {
                defaultValue:
                  "Congratulations! The account has been created. You may now go to the account by pressing the button below.",
              })}
            </p>
          </div>
          <div className="flex mt-12 items-center">
            <NavigateButton
              name={t("Login.Log In", {
                defaultValue: "Log In",
              })}
              to="/home"
              handleSubmit={handleSubmit}
            />
          </div>
        </AnimateWrapper>
      </div>
    </AnimateWrapper>
  );
};

export default AccountCreated;
