import { Outlet } from 'react-router-dom';
import Footer from 'shared/components/footer';
import styles from './style.module.scss';
import StartMainSection from './components/startMainSection';
import { PropsWithChildren } from 'react';

const LoginLayout = () => {
  return (
      <div className={styles.container}>
        <div className={styles.content}>
          <StartMainSection/>
          <Outlet/>
        </div>
        <Footer stylesFooter={{container: styles.stylesWrapperFooter}}/>
      </div>
  );
};

export const LoginLayoutRender = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <StartMainSection/>
        {children}
      </div>
      <Footer stylesFooter={{container: styles.stylesWrapperFooter}}/>
    </div>
  );
};

export default LoginLayout;
