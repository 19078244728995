import { useState } from 'react'
import { StepContent } from '../../model'
import { BatchTransferTypeSelect } from '../batch-transfer-type-select'
import { BatchTransferForm } from '../batch-transfer-form'
import { BatchTransferConfirm } from '../batch-transfer-confirm'
import { BatchTransferSuccess } from '../batch-transfer-success'

export const BatchTransfer = () => {
  const [batchTransferStep, setBatchTransferStep] = useState<StepContent>(
    StepContent.typeSelect
  )

  return (
    <div>
      {batchTransferStep === StepContent.typeSelect && (
        <BatchTransferTypeSelect setStepContent={setBatchTransferStep} />
      )}
      {batchTransferStep === StepContent.form && (
        <BatchTransferForm setStepContent={setBatchTransferStep} />
      )}
      {batchTransferStep === StepContent.confirm && (
        <BatchTransferConfirm setStepContent={setBatchTransferStep} />
      )}
      {batchTransferStep === StepContent.success && <BatchTransferSuccess />}
    </div>
  )
}
