import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './styles.module.scss';
import { useAppSelector, useAppDispatch } from 'shared/models';
import helper from 'services/helper';
import { ManageScheduleTransferForm } from './manage-schedule-transfer-form';
import { useExternalPayees } from 'api/hooksApi/useExternalPayees';
import {
  FREQUENCY_DATA,
  createScheduleTransferSchema,
  findObjById,
} from 'entities/transfers';
import { setSelectScheduleTransfer, selectScheduleTransfer } from '../../model';
import { Skeleton } from 'components/skeleton';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';
import dayjs from 'dayjs';

export const ManageScheduleTransferEdit = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { plaidPayees, isLoadingPayees } = useExternalPayees();

  const scheduleTransfer = useAppSelector(selectScheduleTransfer);

  const formik = useFormik({
    initialValues: {
      from: scheduleTransfer?.fromAccount.id || '',
      to: scheduleTransfer?.toAccount.id || '',
      amount: scheduleTransfer?.amount || '',
      frequency: scheduleTransfer?.frequency || '',
      dateTransfer: dayjs(scheduleTransfer?.dateTransfer),
      memo: scheduleTransfer?.memo || '',
    },
    validationSchema: createScheduleTransferSchema(unclosedFinancialAccounts),
    onSubmit: (form) => {
      dispatch(
        setSelectScheduleTransfer({
          value: {
            id: scheduleTransfer?.id!,
            fromAccount: findObjById(form.from, plaidPayees),
            toAccount: findObjById(form.to, unclosedFinancialAccounts),
            amount: form.amount,
            frequency: form.frequency,
            dateTransfer: form.dateTransfer.toISOString(),
            memo: form.memo
          },
        })
      );
      goNext('6');
    },
  });

  const optionsToAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }));

  return financialAccountsIsLoading || isLoadingPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <ManageScheduleTransferForm
        formik={formik}
        optionsToAcc={optionsToAcc}
        optionsFrequency={optionsFrequency}
        payees={plaidPayees}
      />
    </TransitionAnimation>
  );
};
