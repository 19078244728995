import { CashFlowApi, CashFlowDataItem, CashFlowPeriodEnum, useCashFlowControllerQuery } from '../../../../../api/endpoints/financialAccounts';
import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { requireNotNull } from '../../../../../shared/utils/require-not-null';


interface ContextProps {
  isDataLoading: boolean;
  selectedPeriod: CashFlowPeriodEnum;
  setPeriod(period: CashFlowPeriodEnum): void;
  cashFlowApi?: CashFlowApi;
  maxCashValue: number;
}

const Context = createContext<ContextProps | undefined>(undefined);

export const CashFlowProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(CashFlowPeriodEnum.D7);
  const { data: cashFlowApiData, isLoading, isFetching } = useCashFlowControllerQuery({
    period: selectedPeriod,
  });

  const value = useMemo((): ContextProps => {
    return {
      selectedPeriod,
      cashFlowApi: cashFlowApiData,
      isDataLoading: isLoading || isFetching,
      maxCashValue: cashFlowApiData?.data && cashFlowApiData.data.length > 0
        ? Math.max(...cashFlowApiData.data.flatMap(i => [i.cashIn, i.cashOut]))
        : 0,
      setPeriod(period: CashFlowPeriodEnum) {
        setSelectedPeriod(period);
      }
    }
  }, [selectedPeriod, isLoading, isFetching, cashFlowApiData]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
};

export const useCashFlow = () => requireNotNull(React.useContext(Context));
