import React from 'react'
import { Typography } from '../typography'
import styles from './styles.module.scss'
import cn from 'classnames'

interface IBadgeProps {
  label: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  styledWrapper?: any
  styledLabel?: any
}

export const Badge = (props: IBadgeProps) => {
  const { label, startIcon, endIcon, styledWrapper, styledLabel } = props

  return (
    <div className={cn(styles.wrapper, styledWrapper)}>
      {startIcon && startIcon}

      <Typography className={cn(styles.label, styledLabel)}>{label}</Typography>

      {endIcon && endIcon}
    </div>
  )
}
