import React, { PropsWithChildren, useMemo, useState } from 'react';
import { requireNotNull } from '../../shared/utils/require-not-null';

import { useSnackBar } from '../../providers/snackBar.provider';
import helper from '../../services/helper';
import {
  createArrByNumber,
  getAddressNumberStateByLabel,
  SignUpAddAddressFrom,
  signUpAddressInitialForm,
  SignUpLoginAndPassForm,
  SignUpPersonBaseForm,
} from '../../shared/signUp/shared';
import dayjs from 'dayjs';
import { signUpPersonalBaseInitialForm } from '../../shared/signUp/shared';
import { useRegistrationBusinessMutation } from '../../api/endpoints/user';
import { onlyNumbers } from '../../shared/utils/pipes';

interface InternalAgreementForm {
  isTermsAccepted: boolean;
  isAgreementAccepted: boolean;
}

export interface SignUpBusinessInfo {
  legalBusinessName: string;
  doingBusinessAsName: string;
  businessPhone: string;
  website: string;
  taxId: string;
  businessType: number | null;
  businessStartDate: string | null | dayjs.Dayjs; // iso date
  annualBusinessRevenue: string; // iso date
}

export interface SignUpBusinessPrimaryAuthPersonForm
  extends SignUpPersonBaseForm,
    SignUpAddAddressFrom {
  jobTitle: number;
  ownershipPercentage: string;
}

export interface SignUpOwnersForm {
  isAgreementAccepted: boolean;
  isTermsAccepted: boolean;
  isSameAsPrimary: boolean;
  anotherPersonsCount: number;
  owner1: AnotherOwnerForm | null;
  owner2: AnotherOwnerForm | null;
  owner3: AnotherOwnerForm | null;
  owner4: AnotherOwnerForm | null;
}

export type AnotherOwnerForm = SignUpAddAddressFrom &
  SignUpPersonBaseForm & {
    ownershipPercentage: string;
  };

type ContextForm = SignUpLoginAndPassForm &
  InternalAgreementForm &
  SignUpBusinessInfo &
  SignUpAddAddressFrom & {
    primaryAuthPerson: SignUpBusinessPrimaryAuthPersonForm;
    owners: SignUpOwnersForm;
  };

export enum BusinessSignUpScreen {
  loginAndPass,
  businessInfo,
  businessAddress,
  primaryAuthorizedPerson,
  primaryOwner,
  anotherOwners,
  confirm,
  applicationStatus,
}

interface ContextProps {
  contextForm: ContextForm;

  updateContextForm(values: Partial<ContextForm>): void;

  screen: BusinessSignUpScreen;

  setScreen(nextScreen: BusinessSignUpScreen): void;

  isSubmitApplicationLoading: boolean;

  submitSignUpApplication(): void;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const SignUpBusinessProvider = ({ children }: PropsWithChildren) => {
  const [screen, setScreen] = useState(BusinessSignUpScreen.loginAndPass);
  const { setSnackBar } = useSnackBar();
  const [registrationBusiness, { isLoading: isLoadingRegistrationBusiness }] =
    useRegistrationBusinessMutation();

  const [contextForm, setContextForm] = useState<ContextForm>({
    login: '',
    password: '',
    passwordConfirm: '',

    isAgreementAccepted: false,
    isTermsAccepted: false,

    legalBusinessName: '',
    doingBusinessAsName: '',
    businessPhone: '',
    website: '',
    taxId: '',
    businessType: null,
    businessStartDate: null,
    annualBusinessRevenue: '',

    primaryAuthPerson: {
      jobTitle: 0,
      ownershipPercentage: '',
      ...signUpPersonalBaseInitialForm,
      ...signUpAddressInitialForm,
    },

    owners: {
      isAgreementAccepted: false,
      isTermsAccepted: false,
      isSameAsPrimary: true,
      anotherPersonsCount: 1,
      owner1: null,
      owner2: null,
      owner3: null,
      owner4: null,
    },

    ...signUpAddressInitialForm,
  });

  const value: ContextProps = useMemo(() => {
    return {
      screen,
      contextForm: contextForm,
      isSubmitApplicationLoading: isLoadingRegistrationBusiness,
      updateContextForm(values: Partial<ContextForm>) {
        setContextForm((curValues) => {
          const newValues = { ...curValues };
          Object.keys(values).forEach((key) => {
            newValues[key] = values[key];
          });
          return newValues;
        });
      },
      setScreen(screen) {
        setScreen(screen);
        window.scrollTo({ top: 0 });
      },
      async submitSignUpApplication() {
        try {
          const { primaryAuthPerson } = contextForm;

          const requestForm: any = {
            login: contextForm.login,
            password: contextForm.password,

            businessDetails: {
              legalBusinessName: contextForm.legalBusinessName,
              doingBusinessAsName: contextForm.doingBusinessAsName,
              businessPhone: onlyNumbers(contextForm.businessPhone),
              website: contextForm.website,
              businessType: contextForm.businessType,
              taxId: onlyNumbers(contextForm.taxId),
              streetAddress: contextForm.streetAddress,
              extendedAddress: contextForm.extendedAddress,
              city: contextForm.locality,
              postalCode: contextForm.postalCode,
              state: getAddressNumberStateByLabel(contextForm.state),
              businessStartDate: dayjs.isDayjs(contextForm.businessStartDate)
                ? contextForm.businessStartDate.toISOString()
                : contextForm.businessStartDate,
              annualBusinessRevenue: contextForm.annualBusinessRevenue,
            },

            primaryAythorizedPerson: {
              email: primaryAuthPerson.email,
              givenName: primaryAuthPerson.givenName,
              familyName: primaryAuthPerson.familyName,
              middleName: primaryAuthPerson.middleName,
              ssn: primaryAuthPerson.ssn,
              dateOfBirth: dayjs.isDayjs(primaryAuthPerson.dateOfBirth)
                ? primaryAuthPerson.dateOfBirth.toISOString()
                : primaryAuthPerson.dateOfBirth,
              streetAddress: primaryAuthPerson.streetAddress,
              extendedAddress: primaryAuthPerson.extendedAddress,
              city: primaryAuthPerson.locality,
              postalCode: primaryAuthPerson.postalCode,
              state: getAddressNumberStateByLabel(primaryAuthPerson.state),
              percentageOwnership: primaryAuthPerson.ownershipPercentage,
              authorizingPersonTitle: primaryAuthPerson.jobTitle,
              phone: onlyNumbers(primaryAuthPerson.phone),
            },

            owner: null,
          };

          if (!contextForm.owners.isSameAsPrimary) {
            const toOwnerRequest = (ownerForm: AnotherOwnerForm) => {
              return {
                city: ownerForm.locality,
                firstName: ownerForm.givenName,
                lastName: ownerForm.familyName,
                middleName: ownerForm.middleName,
                homeAddress: ownerForm.streetAddress,
                extendedAddress: ownerForm.extendedAddress,
                state: getAddressNumberStateByLabel(ownerForm.state),
                zipCode: ownerForm.postalCode,
                homePhone: onlyNumbers(ownerForm.phone),
                percentageOwnership: ownerForm.ownershipPercentage,
                ssn: ownerForm.ssn,
                dob: dayjs.isDayjs(ownerForm.dateOfBirth)
                  ? ownerForm.dateOfBirth.toISOString()
                  : ownerForm.dateOfBirth,
                email: ownerForm.email,
              };
            };

            createArrByNumber(contextForm.owners.anotherPersonsCount).forEach(
              (ownerPosition) => {
                if (ownerPosition === 1) {
                  requestForm.owner = toOwnerRequest(
                    requireNotNull(contextForm.owners.owner1)
                  );
                } else {
                  requestForm[`owner${ownerPosition}`] = toOwnerRequest(
                    requireNotNull(contextForm.owners[`owner${ownerPosition}`])
                  );
                }
              }
            );
          }

          await registrationBusiness(requestForm).unwrap();
          setScreen(BusinessSignUpScreen.applicationStatus);
        } catch (err: any) {
          setSnackBar({
            isShow: true,
            type: 'error',
            message: helper.formatErrors(err?.data),
          });
        }
      },
    };
  }, [contextForm, screen, isLoadingRegistrationBusiness]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSignUpBusiness = () =>
  requireNotNull(React.useContext(Context));
