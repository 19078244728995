import { forwardRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePickerProps as DatePickerPropsMUI } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker as DesktopDatePickerMui } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';

export const DatePicker = forwardRef<
  HTMLInputElement,
  DatePickerPropsMUI<Dayjs>
>((props, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePickerMui
        {...props}
        ref={ref}
      />
    </LocalizationProvider>
  );
});
DatePicker.displayName = 'DatePicker';
