import qs from "qs";

import { axiosInstance } from "./tempAxios";
import { getLocalStorageObject } from "../shared/utils/local-storage";
import { LocalStorageKeys } from "../shared/utils/local-storage";
const BASE_URL_API = "https://api.banking.netevia.dev/";

const login2Fac = async ({
  username,
  password,
  ticket,
  code,
  remember30day,
}) => {
  const data = {
    username,
    password,
    ticket,
    code,
    remember30day,
  };

  var config = {
    method: "post",
    url: `api/auth/2Fac`,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const refreshToken = async () => {
  const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
  const headers = {
    "Content-Type": "application/json",
  };

  const config = {
    method: "post",
    url: `/api/auth/refresh?token=${token}`,
    headers,
  };
  const response = await axiosInstance(config);
  return response.data.bearer;
};

const saveFcmToken = async ({ pushToken, deviceId }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    pushToken,
    deviceId,
  };

  const config = {
    method: "post",
    url: `settings/setup`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getPaymentCard = async ({
  paymentCardId,
  from,
  to,
  afterCursor,
  loadNextPage,
}) => {
  let data = {
    paymentCardId,
    from,
    to,
    count: 20,
  };

  if (loadNextPage) {
    data.afterCursor = afterCursor;
  }

  data = qs.stringify(data);

  var config = {
    method: "get",
    url: `api/paymentCards/findPaymentCard?${data}`,
  };

  const response = await axiosInstance(config);
  return response;
};

const getFinancialAccountTransactions = async ({
  financialAccountId,
  from,
  to,
  afterCursor,
  loadNextPage,
}) => {
  let data = {
    financialAccountId,
    from,
    to,
    count: 20,
  };

  if (loadNextPage) {
    data.afterCursor = afterCursor;
  }

  data = qs.stringify(data);

  var config = {
    method: "get",
    url: `api/transaction/byFinancialAccount?${data}`,
  };

  const response = await axiosInstance(config);
  return response;
};

const getAtmLocations = async ({
  paymentCardId,
  latitude,
  longitude,
  distanceLength,
  filter,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    lt: latitude,
    ld: longitude,
    mile: distanceLength,
    filter: filter,
  };

  var config = {
    method: "post",
    url: `api/paymentCards/atmPaymentCard`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getPaymentCardLimits = async ({ paymentCardId }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/spendRules/byCard?paymentCardId=${paymentCardId}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const addRuleMerchantCountries = async ({
  paymentCardId,
  blockedMerchantCountries,
  allowedMerchantCountries,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    blocked: blockedMerchantCountries,
    allowed: allowedMerchantCountries,
  };

  var config = {
    method: "post",
    url: `api/spendRules/merchantCountry`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const addRuleMerchantCategories = async ({
  paymentCardId,
  blockedMerchantCategories,
  allowedMerchantCategories,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    blocked: blockedMerchantCategories,
    allowed: allowedMerchantCategories,
  };

  var config = {
    method: "post",
    url: `api/spendRules/merchantCategory`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const addRuleAmountLimit = async ({ paymentCardId, value }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    value: Number(value + "00"),
  };

  var config = {
    method: "post",
    url: `api/spendRules/amountLimit`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const addRuleAmountLimitMonthly = async ({ paymentCardId, value }) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const data = {
    paymentCardId: paymentCardId,
    value: Number(value + "00"),
  };

  var config = {
    method: "post",
    url: `api/spendRules/amountLimitMonthly`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const detachAllRules = async ({ paymentCardId }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    PaymentCardId: paymentCardId,
  };

  const config = {
    method: "post",
    url: `api/spendRules/detachAll`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const detachVelocity = async ({ paymentCardId, spendRuleId }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId,
    spendRuleId,
  };

  const config = {
    method: "post",
    url: `api/spendRules/detachVelocity`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const detachSpendRules = async ({ paymentCardId, spendRuleId }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId,
    spendRuleId,
  };

  const config = {
    method: "post",
    url: `api/spendRules/detachSpendRules`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getSubUsers = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  const config = {
    method: "get",
    url: `api/subProfiles`,
    headers,
  };
  const response = await axiosInstance(config);
  const data = await response.data;
  return data;
};

const addSubUser = async ({ firstName, lastName, email, department }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const data = {
    Email: email,
    GivenName: firstName,
    FamilyName: lastName,
    Department: department,
  };

  var config = {
    method: "post",
    url: `api/subProfiles/add`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const updateSubUser = async ({
  id,
  firstName,
  lastName,
  department,
  email,
}) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  const data = {
    SubProfileId: id,
    GivenName: firstName,
    FamilyName: lastName,
    Department: department,
    Email: email,
  };

  var config = {
    method: "post",
    url: `api/subProfiles/update`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const subUserAttachToPaymentCard = async ({ paymentCardId, subUserId }) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  const data = {
    PaymentCardId: paymentCardId,
    SubProfileId: subUserId,
  };

  var config = {
    method: "post",
    url: `api/subProfiles/attachToPaymentCard`,
    headers,
    data: data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getSubUserByPaymentCard = async ({ paymentCardId }) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  var config = {
    method: "get",
    url: `api/subProfiles/byPaymentCard?paymentCardId=${paymentCardId}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const subUserDetachFromPaymentCard = async ({ paymentCardId, subUserId }) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  const data = {
    PaymentCardId: paymentCardId,
    SubProfileId: subUserId,
  };

  var config = {
    method: "post",
    url: `api/subProfiles/detachFromPaymentCard`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const deleteSubUser = async ({ subUserId, deleteCards }) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  var config = {
    method: "post",
    url: `api/subProfiles/remove?id=${subUserId}&deleteCards=${deleteCards}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getSubUsersAvailablePaymentCards = async () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };

  var config = {
    method: "get",
    url: `api/subProfiles/availablePaymentCards`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const orderPhysicalPaymentCard = async ({
  paymentCardId,
  givenName,
  familyName,
  companyName,
  streetAddress,
  postalCode,
  region,
  locality,
  deliveryChoise,
  countryCodeAlpha3,
}) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    deliveryDetails: {
      name: {
        GivenName: givenName,
        FamilyName: familyName,
      },
      companyName: companyName,
      address: {
        StreetAddress: streetAddress,
        ExtendedAddress: "",
        PostalCode: postalCode,
        Locality: locality,
        Region: region,
        CountryCodeAlpha3: "USA",
      },
    },
    cardPersonalization: {
      Line1: givenName + " " + familyName,
      Line2: companyName,
    },
    courier: {
      method: deliveryChoise,
    },
  };

  var config = {
    method: "post",
    url: `api/paymentCards/orderPhysicalPaymentCard`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const findPhysicalPaymentCardOrder = async ({ paymentCardId, orderId }) => {
  var config = {
    method: "get",
    url: `api/paymentCards/findPhysicalPaymentCardOrder?paymentCardId=${paymentCardId}&orderId=${orderId}`,
  };

  const response = await axiosInstance(config);
  return response;
};

const cancelPhysicalPaymentCardOrder = async ({ paymentCardId, orderId }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    orderId: orderId,
    PaymentCardId: paymentCardId,
  };

  var config = {
    method: "post",
    url: `api/paymentCards/cancelPhysicalPaymentCardOrder`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const forgotPassword = async (login) => {
  const data = {
    NickName: login,
  };

  var config = {
    method: "post",
    url: `forgotPassword`,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const restorePassword = async ({ login, code, password, passwordConfirm }) => {
  const data = {
    NickName: login,
    Code: code,
    Password: password,
    PasswordConfirm: passwordConfirm,
  };

  var config = {
    method: "post",
    url: `restorePassword`,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const openPaymentCard = async ({ financialAccountId, expirationDate }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    financialAccountId: financialAccountId,
    expirationDate: expirationDate,
    activateNow: true,
    // "paymentCardName": null
  };

  var config = {
    method: "post",
    url: `api/paymentCards`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const addPayee = async ({ name, routingNumber, accountNumber, type }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    RoutingNumber: routingNumber,
    AccountNumber: accountNumber,
    Name: name,
    BankAccountType: type,
  };

  var config = {
    method: "post",
    url: `api/fundsMovement/connectNonVerifiedExternalBankAccount`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const initialTransfer = async ({ from, to, amount, payee, fastTransfer }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    FromFinancialAccountId: from,
    ToFinancialAccountId: to,
    Amount: amount,
    CurrencyCode: 840,
    Descriptor: {
      CompanyEntryDescription: "Transfer",
      Payee: payee,
    },
    sameDay: fastTransfer,
  };

  var config = {
    method: "post",
    url: `api/fundsMovement/initiateFundsWithdrawalACHTransfer`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const initiateExternalTransfer = async ({
  from,
  to,
  amount,
  payee,
  fastTransfer,
}) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    fromFinancialAccountId: from,
    toFinancialAccountId: to,
    amount: amount,
    currencyCode: 840,
    descriptor: {
      companyEntryDescription: "Transfer",
      payee: payee,
    },
    sameDay: fastTransfer,
  };

  var config = {
    method: "post",
    url: `api/fundsMovement/initiateFundsDepositACHTransfer`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getFinicityLink = async () => {
  var config = {
    method: "get",
    url: `api/connectFinancialAccount`,
  };

  const response = await axiosInstance(config);
  return response;
};

const getExternalFinancialAccounts = async () => {
  var config = {
    method: "get",
    url: `api/financialAccounts/findFinancialAccountWithExternal`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const signup = async ({
  login,
  password,
  businessDetails,
  primaryAythorizedPerson,
  owner,
}) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    login,
    password,
    businessDetails: {
      ...businessDetails,
      website: businessDetails?.website,
    },
    primaryAythorizedPerson,
    owner,
  };

  var config = {
    method: "post",
    url: `api/users`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const checkUsername = async (username) => {
  const headers = {
    Accept: "application/json",
  };

  var config = {
    method: "get",
    url: `${BASE_URL_API}api/users/ByNickname?login=${username}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const productApplication = async () => {
  var config = {
    method: "get",
    url: `productApplication`,
  };

  const response = await axiosInstance(config);
  return response;
};

const uploadDocument = async ({
  profileId,
  documentUploadSessionId,
  documentType,
  fileName,
  fileBase64,
  contentType,
  verificatedEntity,
}) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    profileId,
    documentUploadSessionId,
    documentType,
    fileName,
    data: fileBase64,
    contentType,
    verificatedEntity,
  };

  var config = {
    method: "post",
    url: `uploadDocument`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const endUploadDocumentSession = async ({
  profileId,
  documentUploadSessionId,
  verificatedEntity,
}) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    profileId,
    documentUploadSessionId,
    verificatedEntity,
  };

  var config = {
    method: "post",
    url: `endUploadDocumentSession`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getQRCodeImage = async ({ secret }) => {
  var config = {
    method: "get",
    url: `api/qrCode?secret=${secret}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const saveTwoAuth = async ({ secret = "" }) => {
  const headers = {
    Accept: "application/json",
  };

  var config = {
    method: "post",
    url: `api/twoAuth?secret=${secret}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getNickNameCard = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/paymentCards/viewNickNames`,
    headers,
  };
  const response = await axiosInstance(config);
  return response;
};

const updateNickNameCard = async ({ paymentCardId, nickName }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    paymentCardId: paymentCardId,
    nickName: nickName,
  };

  var config = {
    method: "post",
    url: `api/paymentCards/nickName`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getLoginHistory = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/users/logins?count=10`,
    headers,
  };
  const response = await axiosInstance(config);
  return response;
};

const getTopics = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/support/topics`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getFAQ = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/support/faq`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const createTicketId = async ({ typeTopic, question }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    topicId: typeTopic,
    text: question,
  };

  var config = {
    method: "post",
    url: `api/support/createTicket`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const filesUploadTicket = async ({ data }) => {
  const headers = {
    Accept: "application/json",
  };

  var config = {
    method: "post",
    url: `api/tickets/attachment`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getFilesAttachmentTicket = async ({ ticketIdi, fileName }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/tickets/attachment?ticketId=${ticketIdi}&fileName=${fileName}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getAllTicket = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `${BASE_URL_API}api/tickets/all`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getTicket = async ({ ticketId }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/tickets?ticketId=${ticketId}`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const updateTicketMassage = async ({ ticketId, question }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    ticketId: ticketId,
    text: question,
  };

  var config = {
    method: "post",
    url: `api/tickets/message`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getStates = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  var config = {
    method: "get",
    url: `api/enums/state`,
    headers,
  };

  const response = await axiosInstance(config);
  return response;
};

const getSubUserPaymentCards = async () => {
  var config = {
    method: "get",
    url: `api/subProfiles/paymentCards`,
  };

  const response = await axiosInstance(config);
  return response;
};

const reopenTickets = async ({ ticketId, question }) => {
  const headers = {
    Accept: "application/json",
  };

  const data = {
    ticketId: ticketId,
    text: question,
  };

  var config = {
    method: "post",
    url: `api/support/reopenTicket`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const getPaymentCardEvent = async ({ paymentCardId, from, to }) => {
  let data = {
    paymentCardId,
    from,
    to,
    count: 30,
  };

  data = qs.stringify(data);

  var config = {
    method: "get",
    url: `api/paymentCards/findPaymentCard?${data}`,
  };

  const response = await axiosInstance(config);
  return response;
};

const getCardSpendingReport = async ({ paymentCardId, dateFrom, dateTo }) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    paymentCardId,
    dateFrom,
    dateTo,
  };

  var config = {
    method: "post",
    url: `api/paymentCards/spendingReport`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const uploadAvatar = async ({ file }) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "text/plain",
  };

  let formData = new FormData();
  formData.append("File", {
    uri: file.path,
    type: file.mime,
    name: file.filename,
    // name: 'file.jpeg',
  });

  var config = {
    method: "post",
    url: `Files/UploadFile`,
    headers,
    body: formData,
  };

  const response = await axiosInstance(config);
  return response;
};

const getAvatar = async () => {
  var config = {
    method: "get",
    url: `Files/icon`,
  };

  const response = await axiosInstance(config);
  return response;
};

const createBurnerCard = async ({
  account,
  countTransactions,
  amount,
  dateEnd,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    ActivateNow: true,
    FinancialAccountId: account,
    PaymentCardName: null,
    ExpirationDate: dateEnd,
    MaxTotalSpending: amount,
    MaxTransactionsCount: countTransactions,
  };

  var config = {
    method: "POST",
    url: `api/paymentCards/orderBurnerPaymentCard`,
    headers,
    data,
  };
  const response = await axiosInstance(config);
  return response;
};

const getSubUserAccessRights = async ({ subUserId }) => {
  var config = {
    method: "get",
    url: `api/usersManagement/${subUserId}/accessRights`,
  };

  const response = await axiosInstance(config);
  return response;
};

const editSubUserAccessRights = async ({
  subUserId,
  openBankingCard,
  openFinancialAccount,
  viewAllCardsAndFinantialAccounts,
  makeTransfers,
  mainAccess,
}) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const data = {
    openBankingCard,
    openFinancialAccount,
    viewAllCardsAndFinantialAccounts,
    makeTransfers,
    mainAccess,
  };

  var config = {
    method: "post",
    url: `api/usersManagement/${subUserId}/accessRights`,
    headers,
    data,
  };

  const response = await axiosInstance(config);
  return response;
};

const logOut = async () => {
  var config = {
    method: "post",
    url: `/logout`,
  };

  const response = await axiosInstance(config);
  return response;
};

export default {
  login2Fac,
  refreshToken,
  saveFcmToken,
  getPaymentCard,
  getFinancialAccountTransactions,
  getAtmLocations,
  getPaymentCardLimits,
  addRuleMerchantCountries,
  addRuleMerchantCategories,
  addRuleAmountLimit,
  addRuleAmountLimitMonthly,
  detachAllRules,
  detachVelocity,
  detachSpendRules,
  getSubUsers,
  addSubUser,
  updateSubUser,
  subUserAttachToPaymentCard,
  getSubUserByPaymentCard,
  subUserDetachFromPaymentCard,
  deleteSubUser,
  getSubUsersAvailablePaymentCards,
  orderPhysicalPaymentCard,
  findPhysicalPaymentCardOrder,
  cancelPhysicalPaymentCardOrder,
  forgotPassword,
  restorePassword,
  openPaymentCard,
  addPayee,
  initialTransfer,
  initiateExternalTransfer,
  getFinicityLink,
  getExternalFinancialAccounts,
  signup,
  checkUsername,
  productApplication,
  uploadDocument,
  endUploadDocumentSession,
  getQRCodeImage,
  saveTwoAuth,
  getNickNameCard,
  updateNickNameCard,
  getLoginHistory,
  getTopics,
  getFAQ,
  createTicketId,
  filesUploadTicket,
  getFilesAttachmentTicket,
  getAllTicket,
  getTicket,
  updateTicketMassage,
  getStates,
  getSubUserPaymentCards,
  reopenTickets,
  getPaymentCardEvent,
  getCardSpendingReport,
  uploadAvatar,
  getAvatar,
  createBurnerCard,
  getSubUserAccessRights,
  editSubUserAccessRights,
  logOut,
};
