import { useMemo } from 'react';
import { useGetFAQControllerQuery } from 'api/endpoints/support';

interface FAQGroupingItem {
  answer: string;
  question: string;
  category: string;
}

interface FAQGroupingReturnType {
  FAQLabel: string[];
  FAQGroupingByTopic: FAQGroupingItem[];
  FAQIsLoading: boolean;
}

export const useFAQGrouping = (searchTopic?: string): FAQGroupingReturnType => {
  const { data, isFetching: FAQIsLoading } = useGetFAQControllerQuery({});

  const FAQLabel = useMemo<any>(() => {
    const results: string[] = [];
    data?.forEach(item => {
      if (!results.includes(item.category)) {
        results.push(item.category);
      }
    });

    return results;
  }, [data]);

  const FAQGroupingByTopic = useMemo<any>(() => {
    if (!data) {
      return [];
    }
    return data.filter((item) => item.category === searchTopic);
  }, [data, searchTopic]);

  return {
    FAQLabel,
    FAQGroupingByTopic,
    FAQIsLoading,
  };
};
