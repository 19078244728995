import { FC } from 'react';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Button } from 'shared/ui/buttons';


interface LoginButtonGroupProps {
  isOriginalUser: boolean;
  handleRequestAccess: () => void;
  handleReturToYourLogin: () => void;
}

const LoginButtonGroup: FC<LoginButtonGroupProps> = ({
  isOriginalUser,
  handleRequestAccess,
  handleReturToYourLogin,
}) => {
  const { t } = useTranslationProvider();
  return (
    <div>
      {isOriginalUser ? (
        <Button
          variant='text'
          onClick={handleRequestAccess}
        >
          {t('Settings.Request Access', {
            defaultValue: 'Request Access',
          })}
        </Button>
      ) : (
        <Button
          variant='text'
          onClick={handleReturToYourLogin}
        >
          {t('Settings.Return to your account', {
            defaultValue: 'Return to your account',
          })}
        </Button>
      )}
    </div>
  );
};
export default LoginButtonGroup;
