import axios from "axios";
import {
  getLocalStorageObject,
  LocalStorageKeys,
} from "../shared/utils/local-storage";
import { logOut } from "./logOut";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

axiosInstance.interceptors.request.use((config) => {
  const token = getLocalStorageObject(LocalStorageKeys.AuthToken);

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401 && error?.config?.url !== '/logout') {
    logOut();
  }
});
