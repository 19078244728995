import styles from './style.module.scss';

interface Props {
  items: [key: string, value?: string][];
}

export const SignUpSummaryBlock = ({ items }: Props) => {
  return (
    <div className={styles.contentWrapper}>
      {items.map(([key, value]) => (
        <div
          key={key}
          className={styles.contentItem}
        >
          {value ? (
            <>
              <div className={styles.contentItemLabel}>{key}</div>
              <div className={styles.contentItemValue}>{value}</div>
            </>
          ) : (
            <div className={styles.contentItemValue}>{key}</div>
          )}
        </div>
      ))}
    </div>
  );
};
