import { Popup } from "components/popup";
import { FC } from "react";
import styles from "./style.module.scss";
import AccountButtons from "components/button/AccountButtons";
import ApprovedIcon from "../../../assets/svg/check-circle.svg";
import cx from "classnames";

interface Props {
  isOpen: boolean;
  img?: any;
  message?: string | React.ReactNode;
  onClose: (isOpen: boolean) => void;
  stopPropagation?: boolean;
  buttonText?: string;
  titleMassage?: string;
  stylesWrapper?: string;
}

const SuccessModal: FC<Props> = ({
  isOpen,
  onClose,
  message,
  buttonText,
  titleMassage,
  stopPropagation,
  stylesWrapper,
  img,
}) => {
  const BUTTON_WIDTH = 224;

  const handleClose = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    onClose(!isOpen);
  };
  const handleClosePopup = () => {
    onClose(!isOpen);
  };
  const popupStyle = stylesWrapper
    ? cx(styles.popup, stylesWrapper)
    : styles.popup;

  return isOpen ? (
    <Popup
      stylesWrapper={popupStyle}
      onClose={handleClosePopup}
      stopPropagation={stopPropagation}
    >
      <div className={styles.wrapper}>
        <img src={img ? img : ApprovedIcon} className={styles.wrapper_img} />
        {titleMassage && <p className={styles.wrapper_title}>{titleMassage}</p>}
        {message && <p className={styles.wrapper_massage}>{message}</p>}
        {buttonText && (
          <AccountButtons
            disabled={false}
            name={buttonText}
            handleSubmit={(event) => handleClose(event)}
            width={BUTTON_WIDTH}
          />
        )}
      </div>
    </Popup>
  ) : null;
};

export default SuccessModal;
