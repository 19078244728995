import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'

interface IDetailsPayee {
  type: string
  name: string
  accountNumber?: string
  routingNumber?: string
  typeAccount?: string
}
export const DetailsPayee = (props: IDetailsPayee) => {
  const { type, name, accountNumber, routingNumber, typeAccount } = props

  return (
    <div className={styles.info}>
      <div>
        <Typography>Payee type</Typography>
        <Typography>{type}</Typography>
      </div>
      <div>
        <Typography>Account holder name</Typography>
        <Typography>{name}</Typography>
      </div>
      {accountNumber && (
        <div>
          <Typography>Account number</Typography>
          <Typography>{accountNumber}</Typography>
        </div>
      )}
      {routingNumber && (
        <div>
          <Typography>Routing number</Typography>
          <Typography>{routingNumber}</Typography>
        </div>
      )}
      {typeAccount && (
        <div>
          <Typography>Type of account</Typography>
          <Typography>{typeAccount}</Typography>
        </div>
      )}
    </div>
  )
}
