import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../baseApi';

interface ImageUrls {
  contentType: string;
  filePath: string;
  id: number;
}


export enum Place {
  Home = 1,
  Reward = 2,
}
export enum TypeUser {
  Business = 1,
  Consumer = 2,
  SubUser = 8,
}
export enum Platform {
  All = 0,
  Web = 1,
  Mobile = 2,
}
export interface RewardIntegrationsApi {
  name: string;
  description: string;
  url: string;
  imageUrls: ImageUrls[];
  type: number;
  id: number;
  userType: TypeUser
  place: Place
  platform: Platform
  order: number
}


export interface RewardIntegrationsArg {
  skip?: number;
  count?: number ;
  place?: Place;
  typeUser?: TypeUser;
  platform?: Platform;
  userAccessRights?: number;
}
export const rewardsIntegrationsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'rewardsIntegrations',
  endpoints: (builder) => ({
    rewardIntegrationsController: builder.query<
      RewardIntegrationsApi[],
      RewardIntegrationsArg
    >({
      query: (arg) => ({
        url: 'api/RewardIntegrations',
        method: 'GET',
        params: arg
      }),
    }),
  }),
});

export const { useRewardIntegrationsControllerQuery } = rewardsIntegrationsApi;
