import { DrawerStepper, TStepOfDrawerStepper, useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import FirstStep from '../FirstStep';
import { ManageConnectedAccount } from 'features/transfers/transfer-from-connected-account/ui/manage-connected-account';
import { ConnectedAccountHistory } from 'features/transfers/transfer-from-connected-account/ui/connected-account-history';

interface DrawerAddExternalBankProps {
  isShow: boolean;
  onClose: () => void;
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Connect external bank account',
      prevId: null,
      Element: <FirstStep />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'Connect external bank account',
      prevId: '0',
      Element: <ManageConnectedAccount />,
      resetStepsOnReach: true,
    },
  ],
  [
    '9',
    {
      id: '9',
      isCanGoBack: true,
      title: 'Connected Accounts History',
      prevId: '1',
      Element: <ConnectedAccountHistory />,
    },
  ],
]

const MapSteps = new Map(steps)

const DrawerAddExternalBank = ({ isShow, onClose }: DrawerAddExternalBankProps) => {
  return (
    <DrawerStepper 
      isShow={isShow} 
      onClose={onClose} 
      steps={MapSteps} 
      startStep={"0"} 
    />
  );
}

export default DrawerAddExternalBank;