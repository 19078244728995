import styles from "./style.module.scss";
import { List, ListItemButton, Divider } from "@mui/material";
import ButtonChevronRigth from "shared/components/buttonChevronRigth";
import { useSearchParams } from "react-router-dom";
import { SupportModeEnum } from "pages/support/types/enums";
import { TransitionAnimation } from "shared/ui/animations";
interface FaqListBasicThemeProps {
  data: string[];
}

const FaqListBasicTheme = ({ data }:FaqListBasicThemeProps) => {
  const [, setSearchParams] = useSearchParams();
  const renderItems = () => {
    return data.map((topic, index) => (
      <div key={index}>
        <ListItemButton
          onClick={() => {
            setSearchParams({
              pageMode: SupportModeEnum.Faq,
              mode: topic,
              title: topic,
            });
          }}
          sx={{
            padding: 0,
          }}
        >
          <ButtonChevronRigth label={topic} />
        </ListItemButton>
        {index < data.length - 1 && <Divider />}
      </div>
    ));
  };
  return (
    <TransitionAnimation>
    <List disablePadding className={styles.wrapper}>
      {renderItems()}
    </List>
    </TransitionAnimation>
  );
};

export default FaqListBasicTheme;
