import { BankIcon } from 'shared/ui/icons';
import { Typography } from '@mui/material';

import styles from './style.module.scss';

const NoContent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BankIcon />
    
        <Typography className={styles.title}>You don’t have any external bank </Typography>
      </div>
    </div>
  )
}

export default NoContent;