import { PaymentCard } from "api/endpoints/paymentCards";
import cardBurnerIcon from "assets/svg/card-tab-burner-origin.svg";
import cardBurnerClosedIcon from "assets/svg/card-tab-burner-closed.svg";
import cardVirtualIcon from "assets/svg/card-tab-origin.svg";
import cardVirtualIsClosedIcon from "assets/svg/card-tab-closed.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import styles from './style.module.scss';
import { useBoolean } from '../../hooks/useBoolean';
import cx from 'classnames';

interface CardViewProps {
  card: PaymentCard;
  isSmallSize?: boolean;
}

const getCardIcon = (card: PaymentCard) => {
  if (card?.partnerName && card.partnerCardIcons.length > 0) {
    return card.partnerCardIcons[0];
  }

  switch (card?.cardType) {
    case 2:
      return card?.status === "CLOSED"
        ? cardBurnerClosedIcon
        : cardBurnerIcon;
    default:
      return card?.status === "CLOSED"
        ? cardVirtualIsClosedIcon
        : cardVirtualIcon;
  }
};

export const CardView = ({ card, isSmallSize }: CardViewProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const loadImageBool = useBoolean();
  const loadImageErrorBool = useBoolean();


  const handleLoad = () => {
    loadImageBool.setTrue();
  }

  const handleError = () => {
    loadImageErrorBool.setTrue();
  }

  return (
    <div className={cx(styles.cardWrapper, !isSmallSize && styles.cardWrapperFull)}>
      <img
        alt={card?.partnerName ? card.partnerName : 'netevia card'}
        src={getCardIcon(card)}
        width={(matches && !isSmallSize) ? 300 : 55}
        onLoad={handleLoad}
        onError={handleError}
        className={cx(styles.cardImage, loadImageBool.value && styles.cardImageLoaded)}
      />
    </div>
  )
};
