import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

interface SubUserAccessRightsApi {
  mainAccess: boolean;
  makeTransfers: boolean;
  openBankingCard: boolean;
  openFinancialAccount: boolean;
  viewAllCardsAndFinancialAccounts: boolean;
}

export const usersManagementApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "usersManagement",
  tagTypes: [ApiTags.GetSubUserAccessRights],
  endpoints: (builder) => ({
    subUserAccessRightsController: builder.query<SubUserAccessRightsApi, any>({
      providesTags: [ApiTags.GetSubUserAccessRights],
      query: ({ subUserId }) => ({
        url: `api/usersManagement/${subUserId}/accessRights`,
        method: "get",
      }),
    }),
    editSubUserAccessRightsController: builder.mutation<any, any>({
      invalidatesTags: [ApiTags.GetSubUserAccessRights],
      query: ({ subUserId, data }) => ({
        url: `api/usersManagement/${subUserId}/accessRights`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useEditSubUserAccessRightsControllerMutation,
  useSubUserAccessRightsControllerQuery,
} = usersManagementApi;
