import { ReactNode, forwardRef, Ref, useState } from 'react'
import { NumberFormatBase, NumberFormatValues } from 'react-number-format'
import { OutlinedTextFieldProps } from '@mui/material/TextField'
import { NumberFormatBaseProps } from 'react-number-format/types/types'
import { TextInput } from '../text-input'
import styles from './styles.module.scss'
import cn from 'classnames'

export type ValueAmount = {
  value: string | undefined
  formattedValue: string | undefined
  floatValue: number | undefined
}

type Props = Omit<
  OutlinedTextFieldProps,
  'onChange' | 'value' | 'error' | 'onBlur'
> &
  Omit<NumberFormatBaseProps, 'value' | 'onBlur'> & {
    value: string
    onChange: (value: string) => void
    onBlur: (value: string) => void
    error?: ReactNode | boolean
    styleWrapper?: any
  }

const format = (numStr: string) => {
  if (numStr === '') return ''

  const floatValueToCent = Number(numStr) / 100
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(floatValueToCent)
}

export const CurrencyInput2 = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const { value, onChange, onBlur, size, InputProps, styleWrapper, ...rest } =
      props

    const [valuesAmount, setValuesAmount] = useState<string>(value)

    const returnOnBlur = () => {
      onBlur && onBlur(valuesAmount)
    }

    return (
      <NumberFormatBase
        className={cn(styles.input, styleWrapper)}
        value={value}
        onValueChange={(values) => {
          if (values.value) {
            onChange(values.value)
            setValuesAmount(values.value)
          }else{
            onChange('')
            setValuesAmount('')
          }
        }}
        onBlur={returnOnBlur}
        format={format}
        {...rest}
        customInput={TextInput}
        autoComplete='off'
        inputRef={ref}
        InputProps={InputProps}
      />
    )
  }
)
CurrencyInput2.displayName = 'CurrencyInput2'
