import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type DeliveryInfo = {
  firstName: string
  lastName: string
  address: string
  additionalAddress?: string
  city: string
  state: string
  postCode: string
  countryCodeAlpha3: string
  method: string
  companyName: string
}
type OrderOutState = {
  deliveryInfo: DeliveryInfo
}

const initialState: OrderOutState = {
  deliveryInfo: {
    firstName: '',
    lastName: '',
    address: '',
    additionalAddress: '',
    city: '',
    state: '',
    postCode: '',
    countryCodeAlpha3: '',
    method: '',
    companyName: '',
  },
}

export const slice = createSlice({
  name: 'orderOutCard',
  initialState,
  reducers: {
    clearData: () => {
      return initialState
    },
    setDeliveryInfo: (state, { payload }: PayloadAction<DeliveryInfo>) => {
      state.deliveryInfo = payload
    },
  }
})

export const selectdeliveryInfo = (state: RootState) =>
  state.orderOutCard.deliveryInfo

export const { clearData, setDeliveryInfo } = slice.actions
