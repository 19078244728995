import { BellIcon } from 'shared/ui/icons';
import { IconButton, Badge } from '@mui/material';
import styles from './style.module.scss';
import { useBoolean } from 'shared/hooks/useBoolean';
import DrawerModal from '../../shared/components/drawerModal';
import { Intro } from './componentns/intro';
import { useGetNotificationsNewQuery } from 'api/endpoints/notifications';

export const BellNotificationWidget = () => {
  const { data: newNotifications, isLoading: isLoadingNewNotifications } =
    useGetNotificationsNewQuery();

  const allNotificationsAreEmpty = newNotifications?.every(
    (item) => item.count === 0
  );
  const showDraweBool = useBoolean();

  return (
    <>
      <IconButton
        aria-label='open notification'
        onClick={() => showDraweBool.setTrue()}
      >
        {!isLoadingNewNotifications && (
          <Badge
            overlap='circular'
            variant='dot'
            invisible={allNotificationsAreEmpty}
            classes={{ badge: styles.badge }}
          >
            <div className={styles.wrapIcon}>
              <BellIcon />
            </div>
          </Badge>
        )}
      </IconButton>
      <DrawerModal
        isShow={showDraweBool.value}
        onClose={() => showDraweBool.setFalse()}
        titleText={'Notifications'}
      >
        <Intro />
      </DrawerModal>
    </>
  );
};
