import {
  useRewardIntegrationsControllerQuery,
  RewardIntegrationsApi,
  RewardIntegrationsArg,
  Platform,
  TypeUser,
} from 'api/endpoints/rewardIntegrations';
import { useMemo } from 'react';
import { useCurrentUser } from 'providers/user.provider';
import { Rights } from '../endpoints/user';
import {
  RewardIntegrationType,
  useGetRewardIntegrationsTypesQuery,
} from '../endpoints/enums';

export interface RewardIntegrationWithType extends RewardIntegrationsApi {
  joinedType?: RewardIntegrationType;
}

interface RewardIntegrationsReturnType {
  rewardIntegrations: RewardIntegrationWithType[];
  rewardIntegrationsIsLoading: boolean;
}

interface UseRewardIntegrationsParams {
  place: RewardIntegrationsArg['place'];
}

const arrToFlags = (values: number[]): number =>
  values.reduce((acc, cur) => ((acc |= cur), acc), 0);

export const useRewardIntegrations = ({
  place,
}: UseRewardIntegrationsParams): RewardIntegrationsReturnType => {
  const { isSubUser, isConsumer, isMainBusiness, userIsLoading, user } =
    useCurrentUser();
  const { data: rewardIntegrationTypes } = useGetRewardIntegrationsTypesQuery();

  const apiTypeUser = useMemo(() => {
    if (isSubUser) {
      return TypeUser.SubUser;
    }
    if (isConsumer) {
      return TypeUser.Consumer;
    }
    if (isMainBusiness) {
      return TypeUser.Business;
    }
  }, [isSubUser, isConsumer, isMainBusiness]);

  const accessRightsFlag = useMemo(() => {
    if (!isSubUser || !user?.accessRights) {
      return undefined;
    }
    const acr = user.accessRights;
    const accessFlags: { [key in keyof Partial<Rights>]: number } = {
      mainAccess: 1,
      openBankingCard: 2,
      openFinancialAccount: 4,
      viewAllCardsAndFinancialAccounts: 8,
      makeTransfers: 16,
    };

    if (acr.mainAccess) {
      return 1;
    }

    const noAccess = Object.keys(acr)
      .filter((key) => typeof acr[key] === 'boolean')
      .every((key) => !acr[key]);
    if (noAccess) {
      return 32;
    }

    const availableAccess = Object.keys(acr)
      .filter((key) => typeof acr[key] === 'boolean' && acr[key])
      .map((key) => accessFlags[key]);

    return arrToFlags(availableAccess);
  }, [isSubUser, user]);

  const { data, isLoading } = useRewardIntegrationsControllerQuery(
    {
      place,
      typeUser: apiTypeUser,
      platform: Platform.Web,
      skip: 0,
      count: 1000,
      userAccessRights: accessRightsFlag,
    },
    { skip: !user }
  );

  const value = useMemo((): RewardIntegrationWithType[] => {
    if (!rewardIntegrationTypes) {
      return [];
    }
    return [...(data || [])]
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return {
          ...item,
          joinedType: rewardIntegrationTypes.find((r) => r.id === item.type),
        };
      });
  }, [data, rewardIntegrationTypes]);

  return {
    rewardIntegrations: value,
    rewardIntegrationsIsLoading: isLoading || userIsLoading,
  };
};
