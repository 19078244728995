import { useMemo } from 'react'
import { Tabs } from 'shared/ui/tabs'
import styles from './styles.module.scss'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { MakeTransfer } from '../make-transfer'
import { Manage } from '../manage'

type TabId = '0' | '1'
const MAKE_TRANSFER: TabId = '0'
const MANAGE: TabId = '1'
const MANAGE_PAGES = ['4', '7', '8']

const arr = [
  {
    id: '0',
    label: 'Make Transfer',
    component: <MakeTransfer />,
  },
  {
    id: '1',
    label: 'Manage',
    component: <Manage />,
  },
]

export const Intro = () => {
  const { prevStepId } = useDrawerStepperContext()

  const initialStepTab = useMemo(() => {
    const isPayeePage =
      prevStepId !== undefined && MANAGE_PAGES.includes(prevStepId)

    return isPayeePage ? MANAGE : MAKE_TRANSFER
  }, [prevStepId])

  return (
    <div className={styles.container}>
      <Tabs
        data={arr}
        initialTab={initialStepTab}
      />
    </div>
  )
}
