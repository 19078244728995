import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import { Button } from "shared/ui/buttons";
import { TextInput } from "shared/ui/inputs";
interface RenameCardProps {
  onSubmit: (nickName: string) => void;
  currentName: string;
  isLoading: boolean;
  close?: () => void;
}

export const RenameCard = ({
  onSubmit,
  currentName,
  isLoading,
  close,
}: RenameCardProps) => {
  const [nickName, setNickName] = useState<string>(currentName || "");
  const input = useRef<HTMLInputElement>();
  const handleClose = () => close && close();

  useEffect(() => {
    input?.current?.focus();
  }, []);

  return (
    <div className={styles.container}>
      <TextInput
        label="Card name"
        onChange={(event) => setNickName(event.target.value)}
        value={nickName}
        onBlur={handleClose}
        inputRef={input}
        inputProps={{ maxLength: 15 }}
      />
      <Button
        onClick={() => onSubmit(nickName)}
        loading={isLoading}
      >
       Apply
      </Button>
    </div>
  );
};
