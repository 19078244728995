import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import UserInformation from "./components/userInformation";
import styles from "./style.module.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useHolderV2ControllerQuery } from "api/endpoints/account";

const CompanyInformation = () => {
  const { t } = useTranslationProvider();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: holder } = useHolderV2ControllerQuery();

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {!matches && (
          <div className={styles.container_nav}>
            <img src={ArrowLeft} onClick={() => setSearchParams({})} />
            <span className={styles.container_nav_title}>
              {t("Settings.Company information", {
                defaultValue: "Company information",
              })}
            </span>
          </div>
        )}
        <span className={styles.container_holderName}>
          {holder?.businessAccountHolder?.legalBusinessName}
        </span>
        <UserInformation />
      </div>
    </AnimateWrapper>
  );
};

export default CompanyInformation;
