//page
const NOTIFICATIONS = 'Notifications';
const LOGIN_OPTIONS = 'LoginOptions';
const CHANGE_PASSWORD = 'ChangePassword';

const CHANGE_LOGIN = 'ChangeLogin';
export const MFA_SETTINGS = 'MFA_SETTINGS';

//mode
// const SAVE_KEY = "SaveKey";
// const
export {
  NOTIFICATIONS,
  LOGIN_OPTIONS,
  CHANGE_PASSWORD,
  CHANGE_LOGIN,
};
