import { useMediaQuery } from '@mui/material';
import { FilterByName } from '../filterTransactions/componentns/filterByName';
import { useSearchParams } from 'react-router-dom';
import { FilterIcon } from 'shared/ui/icons';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AccountMode } from '../types';
import { Button } from 'shared/ui/buttons';
import DrawerModal from 'shared/components/drawerModal';
import { FilterTransactions } from '../filterTransactions';

import styles from './style.module.scss'

export const FilterTransactionsControls = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get('mode') || ''

  return (
    <>
    <div className={styles.wrapper}>
      <FilterByName />
      <Button
        onClick={() =>
          setSearchParams({ mode: AccountMode.filtersTransactions })
        }
        variant='outlined'
        className={styles.btnFilterCard}
        disabled={false}
      >
        {true ? <FilterListIcon /> : <FilterIcon />}
        {!isMobile && 'filter'}
      </Button>
    </div>
    <DrawerModal
      isShow={currentMode === AccountMode.filtersTransactions}
      onClose={() => {}}
      titleText={'Filters'}
    >
      <FilterTransactions/>
    </DrawerModal>
    </>
  );
};