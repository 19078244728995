import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
interface CountdownTimerProps {
  targetDate: Date;
  onTimerComplete: (timer: Date | undefined) => void;
}

const TimerSMS: React.FC<CountdownTimerProps> = ({
  targetDate,
  onTimerComplete,
}: CountdownTimerProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    Math.floor((targetDate.getTime() - new Date().getTime()) / 1000)
  );
  const { t } = useTranslationProvider();
  useEffect(() => {
    if (remainingSeconds > 0) {
      const interval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(interval);
            onTimerComplete(undefined);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      onTimerComplete(undefined);
    }
  }, [remainingSeconds, onTimerComplete]);

  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return (
    <div className={styles.text}>
      {t("common.Re-send code in", {
        defaultValue: "Re-send code in",
      })}{" "}
      <span className={styles.time}>{`${minutes}:${seconds
        .toString()
        .padStart(2, "0")}`}</span>
    </div>
  );
};
export default TimerSMS;
