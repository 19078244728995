import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import { useSnackBar } from "providers/snackBar.provider";
import { MapIcon } from "shared/ui/icons";

const HeaderButton = () => {
  const { t, changeLanguage, currentLanguage } = useTranslationProvider();
  const { setSnackBar } =  useSnackBar()
  const { pathname } = useLocation();

  const hideHeaderButton =
    pathname === "/login/forgot-password" ||
    pathname === "/login/forgot-userName";

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "es" : "en";
    changeLanguage(newLanguage);

    setSnackBar({
      type: 'success',
      message: t("Settings.The language has been successfully changed", {
        defaultValue: "The language has been successfully changed",
      }),
      isShow: true,
    })
  };

  const capitalize = () => {
    const langugage = currentLanguage === "en" ? "es" : "en";
    return langugage.charAt(0).toUpperCase() + langugage.slice(1);
  };

  const renderComponent = () => (
      <div className={styles.wrapper}>
        <IconButton
          aria-label="change language"
          onClick={() => toggleLanguage()}
        >
          <span className={styles.changeLanguage}>{capitalize()}</span>
        </IconButton>
        <IconButton
          aria-label="navigate to moneypass"
          onClick={() => window.open("https://www.moneypass.com/")}
        >
          <MapIcon className={styles.icon}/>
        </IconButton>
      </div>
  );

    return hideHeaderButton ? <></> : renderComponent();

};

export default HeaderButton;
