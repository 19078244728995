import React, { useState, useEffect, ChangeEvent, FC, useRef } from "react";
import AccountButtons from "../../../components/button/AccountButtons";
import BeatLoader from "react-spinners/BeatLoader";
import EditFilled from "assets/svg/EditFilled.svg";
import NoteAltOutlined from "assets/svg/NoteAltOutlined.svg";
import { TextField, FormControl, Button } from "@mui/material";
import { useMediaQuery, useTheme, InputAdornment } from "@mui/material";
import styles from "./style.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";
import helper from "services/helper";

const ICON_SIZE = 24;
const DEFAULT_LABEL = "Attach documents (optional)";

interface Props {
  memo: string | null;
  setMemo: (memo: string) => void;
  onSumbit: () => Promise<void>;
  isLoading: boolean;
}

const Memo = ({ memo, setMemo, onSumbit, isLoading }: Props) => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const [isСhanging, setIsChanging] = useState(false);
  const textField = useRef(null);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setMemo(e.target.value);
  };
  const handleEditMemo = async () => {
    setIsChanging(true);
    await helper.sleep(0);
    //@ts-ignore
    textField.current.focus();
  };
  const handleBlur = (event) => {
    if (event?.relatedTarget?.id === "memoSaveButton") onSumbit();
    setIsChanging(false);
  };
  const handleKeyDiwn = (event) => {
    if (event.code === "Enter") {
      onSumbit();
      setIsChanging(false);
    }
  };
  return (
    <div className={styles.container}>
      {isСhanging ? (
        <div className={styles.wrapForm}>
          <div className={styles.wrapForm_form}>
            <FormControl fullWidth>
              <TextField
                inputRef={textField}
                onBlur={handleBlur}
                value={memo}
                onKeyDown={handleKeyDiwn}
                onChange={handleChangeValue}
                variant="outlined"
                multiline
                label="Memo"
                InputProps={{
                  className: styles.textFieldInput,
                }}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </FormControl>
          </div>
          <Button id="memoSaveButton" disabled={!memo}>
            {isLoading
              ? ""
              : t("save", {
                  defaultValue: "Save",
                })}
          </Button>
        </div>
      ) : !memo ? (
        <div>
          <Button onClick={handleEditMemo}>
            <img
              style={{ marginRight: "8px" }}
              src={NoteAltOutlined}
              onClick={handleEditMemo}
            />
            {isLoading
              ? ""
              : t("common.addMemo", {
                  defaultValue: "Add memo",
                })}
          </Button>
        </div>
      ) : (
        <div className={styles.memoValue}>
          {memo}
          <Button onClick={handleEditMemo}>
            <img src={EditFilled} />
          </Button>
        </div>
      )}
    </div>
  );
};
export default Memo;
