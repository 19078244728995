import {
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import { ChartType } from "pages/accountComponent/types";
import styles from './style.module.scss'
interface TogleButtonProps {
  value: ChartType;
  setValue: (value: ChartType) => void;
}

export const TogleButton = ({ value, setValue }: TogleButtonProps) => {
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ChartType 
  ) => {
    if(newAlignment){
      setValue(newAlignment);
    }
  };
  return (
      <ToggleButtonGroup  
      value={value}
      exclusive
      onChange={handleAlignment}
      classes={{
        root: styles.toggleButtonGroupRoot,
        grouped:styles.toggleButtonGroupGrouped,
        disabled: styles.toggleButtonGroupDisabled,
      }}
    >
      <ToggleButton value={ChartType.pieChart} aria-label="left aligned"
      classes={{
        root: styles.buttonRoot,
        selected: styles.buttonSelected,
      }}
      >
      <PieChartIcon />
      </ToggleButton>
      <ToggleButton value={ChartType.barChart}  aria-label="right aligned"
       classes={{
        root: styles.buttonRoot,
        selected: styles.buttonSelected,
      }}
      >
        <BarChartIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

