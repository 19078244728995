import { useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { DetailsInfo } from '../detailsInfo';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Typography } from 'shared/ui/typography';
import { FormHelperText } from '@mui/material';
import helper from 'services/helper';
import { RadioGroup } from '@mui/material';
import { Radio } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { calculateDiscountWithAmount } from 'pages/reward/components/giftCard/utils/calculateDiscountWithAmount';
import { GiftCardMethod } from 'pages/reward/components/giftCard/types';

export const DetailsAmount = () => {
  const { goNext } = useDrawerStepperContext();
  const { selectBrand, amount, setAmount } = useGiftCards();
  const {
    logoUrl,
    name,
    clientDiscount,
    description,
    digitalFaceValueLimits,
    digitalDenominations,
  } = selectBrand;

  //not vorking when created handleOnChange and condition if()
  useEffect(() => {
    if (
      digitalFaceValueLimits &&
      Number(amount) > digitalFaceValueLimits?.upper
    ) {
      setAmount((digitalFaceValueLimits?.upper).toString());
    }
  }, [amount, digitalFaceValueLimits]);

  const amountWithDiscount = calculateDiscountWithAmount(
    Number(amount),
    clientDiscount
  );

  const isAmountPositive = Number(amount) > 0;

  const validAmount = digitalFaceValueLimits
    ? isAmountPositive && Number(amount) >= digitalFaceValueLimits?.lower
    : isAmountPositive;

  const renderAmountSelection = () => {
    if (digitalDenominations) {
      return (
        <RadioGroup
          value={amount}
          onChange={(_, value) => setAmount(value)}
          name='Amount'
        >
          <Typography>Select gift card value:</Typography>
          {digitalDenominations.map((item) => (
            <Radio
              key={item}
              LabelProps={{ className: styles.boxRadio }}
              value={item}
              label={<Typography>${helper.moneyFormat(item)}</Typography>}
            />
          ))}
        </RadioGroup>
      );
    } else {
      return (
        <div>
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='Amount'
            placeholder='$0.00'
            getCaretBoundary={() => [false]}
            value={amount}
            onChange={(value: any) => setAmount(value)}
            onBlur={() => {}}
          />
          <FormHelperText>
            You may select any amount from $
            {helper.moneyFormat(digitalFaceValueLimits?.lower)} to $
            {helper.moneyFormat(digitalFaceValueLimits?.upper)}
          </FormHelperText>
        </div>
      );
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsInfo
          logo={logoUrl}
          name={name}
          clientDiscount={clientDiscount}
          description={description}
        />
        {renderAmountSelection()}
        <div className={styles.boxTotalPrice}>
          <Typography>Total price to pay: </Typography>

          {validAmount && (
            <TransitionAnimation>
              <div className={styles.boxTotalPrice}>
                <Typography className={styles.strikethrough}>
                  ${helper.moneyFormat(amount || 0)}
                </Typography>
                <Typography>
                  ${helper.moneyFormat(amountWithDiscount)}
                </Typography>
              </div>
            </TransitionAnimation>
          )}
        </div>

        <Button
          className={styles.btn}
          disabled={!validAmount}
          onClick={() => goNext('2')}
        >
          Next
        </Button>
      </div>
    </TransitionAnimation>
  );
};
