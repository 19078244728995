import {
  LocalStorageKeys,
  setLocalStorageObject,
  getLocalStorageObject,
} from "../../shared/utils/local-storage";
import { useEffect, useCallback, useState } from "react";
import { mainApi } from "../../api";
import { apiRtkMutex } from "../../api/baseApi";

const TIME_TO_REFRESH_MS = 5 * 60 * 1000;

export const useRefreshAuth = () => {
  const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
  const [prevRefreshTime, setPrevRefreshTime] = useState<string | null>(
    localStorage.getItem("lastRefresh")
  );

  const refreshToken = async () => {
    const release = await apiRtkMutex.acquire();
    try {
      const newToken = await mainApi.refreshToken();
      if (newToken) {
        setLocalStorageObject(LocalStorageKeys.AuthToken, newToken);
        const newRefreshTime = new Date().toISOString();
        localStorage.setItem("lastRefresh", newRefreshTime);
        setPrevRefreshTime(newRefreshTime);
      }
    } catch (e: any) {
      console.log("refresh error:", e);
    } finally {
      release();
    }
  };

  const checkTimeAndRefresh = useCallback(async () => {
    if (!prevRefreshTime) {
      refreshToken();
      return;
    }

    const prevDate = new Date(prevRefreshTime);
    const currentDate = new Date();
    const diff = currentDate.getTime() - prevDate.getTime();

    if (diff > TIME_TO_REFRESH_MS) {
      refreshToken();
    }
  }, [prevRefreshTime, refreshToken]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const timer = setTimeout(() => {
      refreshToken();
    }, TIME_TO_REFRESH_MS);

    checkTimeAndRefresh();

    return () => {
      clearTimeout(timer);
    };
  }, [token, prevRefreshTime, checkTimeAndRefresh]);
};
