import { useInitiateTransferBetweenFinancialAccControllerMutation } from 'api/endpoints/fundsMovement';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector } from 'shared/models';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { DetailsTransfer } from 'entities/transfers';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import {
  selectFromFinancialAccount,
  selectToFinancialAccount,
  selectAmount,
  selectTransferMemo,
} from 'features/transfers/transfer-to-my-own-account/model';
import { useFinancialAccounts } from '../../../../../api/hooksApi/useFinancialAccounts';


export const MakeTransferConfirm = () => {
  const { setSnackBar } = useSnackBar()
  const { goNext } = useDrawerStepperContext()
  const { finAccountsRefetch } = useFinancialAccounts();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount)
  const toFinancialAccount = useAppSelector(selectToFinancialAccount)
  const amount = useAppSelector(selectAmount)
  const memo = useAppSelector(selectTransferMemo);

  const [payeeTransferMutation, { isLoading: isLoadingPayeeTransfer }] =
    useInitiateTransferBetweenFinancialAccControllerMutation();

  const handleTransfer = async () => {
    try {
      if (fromFinancialAccount && toFinancialAccount && amount) {
        await payeeTransferMutation({
          fromFinancialAccountId: fromFinancialAccount.id,
          toFinancialAccountId: toFinancialAccount.id,
          amount: Number(amount),
          currencyCode: 840,
          memo,
        }).unwrap()
        goNext('2')
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        });
        finAccountsRefetch();
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>Review & confirm</Typography>
        <DetailsTransfer
          amount={`$${helper.moneyFormat(amount)}`}
          accountFrom={fromFinancialAccount}
          accountTo={toFinancialAccount}
          memo={memo}
        />
        <Button
          variant='contained'
          loading={isLoadingPayeeTransfer}
          onClick={handleTransfer}
        >
          confirm transfer
        </Button>
      </div>
    </TransitionAnimation>
  );
};
