import { useState, useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Select } from 'shared/ui/inputs';
import { BrandsSort } from 'pages/reward/components/giftCard/types';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { Button } from 'shared/ui/buttons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useBoolean } from 'shared/hooks/useBoolean';
import {
  dataSort,
  initialDataCategory,
} from 'pages/reward/components/giftCard/config/constants';
import { DataCategory } from 'pages/reward/components/giftCard/types';

const selectAll = 'Select all';

export const BrandsFilter = () => {
  const { goNext } = useDrawerStepperContext();
  const {
    filterBySort,
    setFilterBySort,
    filterByCategory,
    setFilterByCategory,
  } = useGiftCards();

  const [selectSort, setSelectSort] = useState<BrandsSort>(filterBySort);
  const [categories, setCategories] =
    useState<DataCategory[]>(filterByCategory);
  const selectAllCategoriesBool = useBoolean();

  const optionsSortBy = dataSort.map((item) => ({
    id: item,
    value: item,
    content: item,
  }));

  useEffect(() => {
    const allSelected = categories.every((category) => category.value);
    selectAllCategoriesBool.setValue(allSelected);
  }, [filterByCategory, categories]);

  const handleAddCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCategories((prev) =>
      prev.map((category) =>
        category.label === name ? { ...category, value: checked } : category
      )
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    selectAllCategoriesBool.setValue(checked);
    setCategories((prev) =>
      prev.map((category) => ({ ...category, value: checked }))
    );
  };

  const renderCheckbox = (
    label: string,
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => (
    <FormControlLabel
      key={label}
      classes={{ root: styles.rootFormControlLabel }}
      control={
        <Checkbox
          classes={{
            checked: styles.checked,
          }}
          checked={checked}
          onChange={onChange}
          name={label}
        />
      }
      label={label}
    />
  );

  const renderFilterByCategory = () => (
    <div className={styles.wrapper}>
      {renderCheckbox(
        selectAll,
        selectAllCategoriesBool.value,
        handleSelectAll
      )}
      {categories.map((category) =>
        renderCheckbox(category.label, category.value, handleAddCategory)
      )}
    </div>
  );

  const handleApply = () => {
    setFilterBySort(selectSort);
    setFilterByCategory(categories);
    goNext('0');
  };

  const handleClear = () => {
    setCategories(initialDataCategory);
    setFilterByCategory(initialDataCategory);
    setSelectSort(BrandsSort.AZ);
    setFilterBySort(BrandsSort.AZ);
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Select
          fullWidth
          options={optionsSortBy}
          label='Sort by'
          value={selectSort}
          onChange={(e) => setSelectSort(e.target.value as BrandsSort)}
        />
        <Typography className={styles.text}>Show categories:</Typography>
        <FormGroup>{renderFilterByCategory()}</FormGroup>
        <div className={styles.boxBtn}>
          <Button
            variant='outlined'
            onClick={handleClear}
          >
            Clear all
          </Button>
          <Button onClick={handleApply}>Apply filter</Button>
        </div>
      </div>
    </TransitionAnimation>
  );
};
