import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { theme } from 'theme'
import { store } from 'store'

export const withProviders = (app: () => React.ReactNode) =>
  function WrapperAppProvider() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <StyledThemeProvider theme={theme}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {app()}
              </MuiThemeProvider>
            </StyledThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </BrowserRouter>
    )
  }
