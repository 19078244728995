import React, { ChangeEvent, FC } from "react";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import styles from "./style.module.scss";
import AttachIcon from "../../../../../assets/svg/paperclip-color.svg";
import FileAttachedIcon from "../../../../../assets/svg/check-circle2.svg";
import DeleteIcon from "../../../../../assets/svg/delete.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import { useTranslationProvider } from "providers/translation/translation.provider";
const ICON_SIZE = 24;

interface FormReportProblem {
  question: string;
  setQuestion: (question: string) => void;
  files: FileList | null;
  setFiles: (files: FileList | null) => void;
}

const FormReportProblem = ({
  question,
  setQuestion,
  files,
  setFiles,
}: FormReportProblem) => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const isFileAttached = files !== null;
  const leftIcon = isFileAttached ? FileAttachedIcon : AttachIcon;
  const DEFAULT_LABEL = t("common.Attach documents", {
    defaultValue: "Attach documents (optional)",
  });
  const renderDefaultLabel = matches ? DEFAULT_LABEL : "";

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files;
      setFiles(files);
    }
  };

  const handleDeleteFile = () => {
    setFiles(null);
  };

  // @ts-ignore
  const data = files ? [...files] : [];

  const renderAttachDocument = () => {
    return (
      <div className={styles.attachDocument}>
        <label htmlFor="fileInput" className={styles.attachDocument_image}>
          <img
            src={leftIcon}
            alt="Attach document"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
          {!isFileAttached && (
            <input
              id="fileInput"
              type="file"
              multiple
              onChange={handleFileChange}
              className={styles.attachDocument_input}
            />
          )}
        </label>
        <label className={styles.attachDocument_text}>
          {data.length
            ? data.map((file, index) => <p key={index}>{file.name}</p>)
            : renderDefaultLabel}
        </label>
        {isFileAttached && (
          <img
            onClick={handleDeleteFile}
            src={DeleteIcon}
            alt="Attach document"
            className={styles.attachDocument_image}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <FormControl fullWidth>
        <TextField
          value={question}
          onChange={handleChangeValue}
          variant="outlined"
          placeholder={t("Support.Type your question here", {
            defaultValue: "Type your question here",
          })}
          multiline
          rows={matches ? 10 : 5}
          InputProps={{
            className: styles.textFieldInput,
            startAdornment: !matches && (
              <InputAdornment
                position="end"
                style={{
                  position: "absolute",
                  right: 15,
                  bottom: 0,
                  marginBottom: 25,
                  marginTop: 10,
                }}
              >
                {renderAttachDocument()}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {matches && renderAttachDocument()}
    </>
  );
};
export default FormReportProblem;
