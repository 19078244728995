import { UPSIcon, USPSIcon } from 'shared/ui/icons'

interface ServiceTypeInfo {
  id: string
  name: string
  description: string
  price: string
}

// * if something changes, use useDeliveryCompanyControllerMutation to get delivery services.
export const DELIVERY_SERVICES: Readonly<Record<string, ServiceTypeInfo[]>> = {
  USPS: [
    {
      id: 'USPS_GROUND',
      name: 'Ground',
      description: '2–8 Business Days',
      price: 'FREE',
    },
    {
      id: 'USPS_PRIORITY',
      name: 'Priority',
      description: '1-3 Business Days',
      price: 'FREE',
    },
    {
      id: 'USPS_EXPRESS',
      name: 'Express',
      description: 'Next Business Day-2 days',
      price: 'FREE',
    },
  ],
  UPS: [
    {
      id: 'UPS_GROUND',
      name: 'Ground',
      description: '1-5 Business Days',
      price: 'FREE',
    },
    {
      id: 'UPS_SECOND_DAY',
      name: 'Priority',
      description: '2 Business Days',
      price: 'FREE',
    },
    {
      id: 'UPS_NEXT_DAY',
      name: 'Express',
      description: 'Next Business Day',
      price: 'FREE',
    },
  ],
}

export const DELIVERY_SERVICE_ICONS = {
  USPS: {
    title: 'USPS',
    icon: USPSIcon,
  },
  UPS: {
    title: 'UPS',
    icon: UPSIcon,
  },
} as const
