import { useLoginHistoryQuery } from 'api/endpoints/user';
import LoginHistoryList from '../loginHistoryList';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';

const LoginHistory = () => {
  const { t } = useTranslationProvider();
  const { data: loginHistory, isLoading } = useLoginHistoryQuery({ count: 10 });

  return (
    <div className={styles.container}>
      <span className={styles.container_title}>
        {t('Settings.Login History', {
          defaultValue: 'Login History',
        })}
      </span>
      <LoginHistoryList
        loginHistory={loginHistory ?? []}
        isLoading={isLoading}
      />
    </div>
  );
};
export default LoginHistory;
