import {
  useState,
  ReactNode,
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ForwardedRef,
} from 'react'
import {
  TextField,
  TextFieldProps as TextFieldPropsMUI,
  InputBaseProps as InputPropsMUI,
  InputLabelProps as InputLabelPropsMUI,
  OutlinedInputProps as OutlinedInputPropsMUI,
  FilledInputProps as FilledInputPropsMUI,
} from '@mui/material'

import cn from 'classnames'
import styles from './styles.module.scss'

export type TextInputProps = Omit<
  TextFieldPropsMUI,
  'classes' | 'error' | 'color' | 'select'
> & {
  InputLabelProps?: Omit<
    TextFieldPropsMUI['InputLabelProps'],
    'className' | 'classes'
  >
  InputProps?: Omit<
    TextFieldPropsMUI['InputProps'],
    'disableUnderline' | 'classes' | 'className'
  >

  error?: ReactNode
}
// TODO: style according to the layout in the future
export const TextInput = forwardRef(function TextInput(
  props: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    onChange,
    onFocus,
    onBlur,
    InputLabelProps,
    InputProps,
    error,
    variant = 'outlined',
    className,
    value,
    ...rest
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const labelClasses: InputLabelPropsMUI['classes'] = {
    root: styles.inputLabel,
  }

  const inputClasses:
    | InputPropsMUI['classes']
    | OutlinedInputPropsMUI['classes']
    | FilledInputPropsMUI['classes'] = {
    root: styles.inputWrapper,
    input: styles.input,
    notchedOutline: styles.notchedOutline,
    focused: styles.focused,
    adornedEnd: styles.adornedEnd,
    adornedStart: styles.adornedStart,
  }

  return (
    <TextField
      {...rest}
      value={value}
      ref={ref}
      error={!!error}
      className={cn(styles.root, className)}
      variant={variant}
      onChange={handleChange}
      InputLabelProps={{
        classes: labelClasses,
        shrink: Boolean(value) || isFocused,
        ...InputLabelProps,
      }}
      InputProps={{
        ...InputProps,
        onFocus: handleFocus,
        onBlur: handleBlur,
        classes: inputClasses,
      }}
      helperText={typeof error !== 'boolean' ? error : ''}
    />
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange?.(event)
  }

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    onFocus ? onFocus(event) : InputProps?.onFocus?.(event)
    setIsFocused(true)
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    onBlur ? onBlur(event) : InputProps?.onBlur?.(event)
    setIsFocused(false)
  }
})
