import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { CasesOutlinedIcon } from 'shared/ui/icons';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';

interface PrimaryAccountProps {
  name: string;
}

export const PrimaryAccount = ({ name }: PrimaryAccountProps) => {
  const { t } = useTranslationProvider();
  return (
    <TransitionAnimation>
      <div className={styles.wrapper}>
        <Typography>
          {t('common.Connected accounts', {
            defaultValue: 'Connected account',
          })}
        </Typography>

        <div className={styles.box}>
          <CasesOutlinedIcon />
          <Typography>{name}</Typography>
        </div>
      </div>
    </TransitionAnimation>
  );
};
