import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import helper from 'services/helper'
import { TransitionAnimation } from 'shared/ui/animations'
import cn from 'classnames'

interface TotalBalanceProps {
  total: number
  errorForTotalBalance: boolean
}

export const TotalBalance = ({
  total,
  errorForTotalBalance,
}: TotalBalanceProps) => {
  const styleWrapper = cn(styles.wrapper, {
    [styles.errorWrapper]: errorForTotalBalance,
  })

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>Total batch amount:</Typography>
      <TransitionAnimation>
        <div className={styleWrapper}>
          <Typography className={styles.textTotal}>
            ${helper.moneyFormat(total)}
          </Typography>
          {errorForTotalBalance && (
            <Typography className={styles.errorText}>
              Insufficient balance
            </Typography>
          )}
        </div>
      </TransitionAnimation>
    </div>
  )
}
