export enum LocalStorageKeys {
  AuthToken = "auth_token",
  dmVy = "dmVy", // original user vector
  dmU = "dmU", // original user id
  dmOrig = "dmOrig", // is original user bool flag
  dmVy_2 = "dmVy_2", // linked account vector
  dmU_2 = "dmU_2" // linked account user id
}

export const getLocalStorageObject = (key: LocalStorageKeys) => {
  const json = localStorage.getItem(key);
  if (!json || json === "undefined") {
    return undefined;
  }

  return JSON.parse(json);
};

export const setLocalStorageObject = (
  key: LocalStorageKeys,
  value: unknown,
): void => {
  const json = JSON.stringify(value);
  localStorage.setItem(key, json);
};

export const removeLocalStorageObject = (key: LocalStorageKeys): void => {
  localStorage.removeItem(key);
};
