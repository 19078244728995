import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
  ReactNode,
} from 'react'
import { Dialog } from 'shared/ui/dialog'

export interface DialogShowProps {
  onApply?(): void
  onCancel?(): void
  dialogText?: string | React.ReactNode
  dialogTitle?: string | React.ReactNode
  slotTextInput?: React.ReactNode
  slotApplyButton?: React.ReactNode
  slotCancelButton?: React.ReactNode
}

export interface DialogContextInterface {
  show(dialogShowProps: DialogShowProps): void
}

const Context = createContext<DialogContextInterface | undefined>(undefined)

export const DialogControllerProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [dialogParams, setdialogParams] = useState<DialogShowProps | null>(null)

  const handleClose = () => {
    setdialogParams(null)
  }

  const handleShow = useCallback((data: DialogShowProps) => {
    setdialogParams(data)
  }, [])

  const handleApply = () => {
    if (dialogParams?.onApply) {
      dialogParams.onApply()
    }
    handleClose()
  }

  const handleCancel = () => {
    if (dialogParams?.onCancel) {
      dialogParams.onCancel()
    }
    handleClose()
  }
  const params: DialogContextInterface = {
    show: handleShow,
  }

  return (
    <Context.Provider value={params}>
      <Dialog
        open={!!dialogParams}
        onClose={handleClose}
        onApply={handleApply}
        onCancel={handleCancel}
        dialogTitle={dialogParams?.dialogTitle}
        dialogText={dialogParams?.dialogText}
        slotApplyButton={dialogParams?.slotApplyButton}
        slotCancelButton={dialogParams?.slotCancelButton}
      />

      {children}
    </Context.Provider>
  )
}

export const useDialog = () => useContext(Context) as DialogContextInterface
