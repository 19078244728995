import { CSSTransition } from 'react-transition-group'
import type { CSSTransitionProps } from 'react-transition-group/CSSTransition'
import './styles.scss'

type TransitionAnimationProps = {
  inState?: boolean
  classNames?: 'fade' | CSSTransitionProps['classNames']
  containerProps?: React.ComponentPropsWithoutRef<'div'>
} & Omit<CSSTransitionProps, 'in' | 'classNames'>

export const TransitionAnimation = (props: TransitionAnimationProps) => {
  const {
    children,
    inState = true,
    classNames = 'fade',
    containerProps,
    ...rest
  } = props

  return (
    <CSSTransition
      appear={true}
      unmountOnExit
      in={inState}
      timeout={300}
      classNames={classNames}
      {...rest}
    >
      <div {...containerProps}>{children}</div>
    </CSSTransition>
  )
}
