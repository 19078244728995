import { AnimateWrapper } from '../../../components/animate-wrapper';
import { PersonalSignUpScreen, useSignUpPersonal } from '../provider';
import { Button } from '../../../shared/ui/buttons';
import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { SignUpSummaryBlock } from '../../../shared/signUp/summaryBlock';
import {
  formatSignUpAddress,
  formatSignUpBirthOfDate,
} from '../../../shared/signUp/shared';

const EditButton = () => {
  const { setScreen, isSubmitApplicationLoading } = useSignUpPersonal();
  return (
    <Button
      disabled={isSubmitApplicationLoading}
      variant='text'
      onClick={() => setScreen(PersonalSignUpScreen.baseInfo)}
    >
      Edit
    </Button>
  );
};

export const PersonalSignUpConfirm = () => {
  const {
    isSubmitApplicationLoading,
    setScreen,
    submitSignUpApplication,
    contextForm,
  } = useSignUpPersonal();

  return (
    <SignUpFormsLayout isConsumerMode>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Last touch! Let&apos;s make sure that the information entered is
          correct.
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Check all the information below and make sure that the information
          entered correctly.
        </SignUpFormsLayout.Subtitle>

        <SignUpFormsLayout.SectionTitle extraAction={<EditButton />}>
          Personal info
        </SignUpFormsLayout.SectionTitle>
        <SignUpSummaryBlock
          items={[
            ['First name', contextForm.givenName],
            ['Last name', contextForm.familyName],
            ['Email', contextForm.email],
            ['Phone', contextForm.phone],
            ['Date of birth', formatSignUpBirthOfDate(contextForm.dateOfBirth)],
          ]}
        />

        <SignUpFormsLayout.SectionTitle extraAction={<EditButton />}>
          Address
        </SignUpFormsLayout.SectionTitle>
        <SignUpSummaryBlock items={[[formatSignUpAddress(contextForm)]]} />

        <br />
        <br />

        <SignUpFormsLayout.FormControls>
          <Button
            onClick={() => setScreen(PersonalSignUpScreen.baseInfo)}
            type='button'
            variant='text'
            disabled={isSubmitApplicationLoading}
          >
            Back
          </Button>
          <Button
            type='button'
            variant='contained'
            onClick={submitSignUpApplication}
            disabled={isSubmitApplicationLoading}
            loading={isSubmitApplicationLoading}
          >
            <div>Submit application</div>
          </Button>
        </SignUpFormsLayout.FormControls>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
