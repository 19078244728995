import React, { FC } from "react";
import CardShowOtherSide from "./component/cardShowOtherSide";
import masterCardIcon from "assets/svg/master-card.svg";
import styles from "./style.module.scss";

interface CardFooterProps {
  handleShowData: (event: React.MouseEvent) => void;
  isDataShowing: boolean;
  hideMasterCardIcon?: boolean;
  textColor?: string;
  buttonBg?: string;
}

const CardFooter: FC<CardFooterProps> = ({ handleShowData, isDataShowing, hideMasterCardIcon, textColor, buttonBg }) => {
  return (
    <div className={styles.wrapper}>
      <CardShowOtherSide
        handleToggleShow={handleShowData}
        isShow={isDataShowing}
        textColor={textColor}
        buttonBg={buttonBg}
      />
      {!hideMasterCardIcon && <img src={masterCardIcon} alt="Master Card" />}
    </div>
  );
};
export default CardFooter;
