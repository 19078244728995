import { Skeleton } from '@mui/material';
import ItemBalance from '../itemBalance';
import { AnimateWrapper } from 'components/animate-wrapper';
import styles from '../../styles.module.scss';
import { useExternalBanks } from 'api/hooksApi/useExternalBanks';
import helper from 'services/helper';

const availableBalanceToolTip =
  'The amount of money in your account that you can use right now.';

export const OtherBanksTab = () => {
  const { availableBalance, isLoading } = useExternalBanks();

  const renderBalance = () => {
    if (!availableBalance) {
      return <></>;
    }

    return (
      <div className={styles.box}>
        <ItemBalance
          balance={helper.moneyFormat(availableBalance) || ''}
          description={'Available Balance'}
          tooltip={availableBalanceToolTip}
        />
      </div>
    );
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='100px'
    />
  ) : (
    <AnimateWrapper className='fade'>{renderBalance()}</AnimateWrapper>
  );
};
