import { useSearchParams } from 'react-router-dom'
import infoOutlinedIcon from 'assets/svg/InfoOutlined.svg'
import moreVertFilled from 'assets/svg/MoreVertFilled.svg'
import { AccountAction } from 'pages/accountComponent/types'
import ActionButton from 'pages/accountComponent/components/ActionButton'
import DrawerModal from 'shared/components/drawerModal'
import AccountDetails from '../AccountDetails'
import { More } from '../More'
import transfersIcon from 'assets/svg/transfers.svg'
import { Transfers } from 'pages/accountComponent/Actions/Transfers'

import styles from './style.module.scss'


const actions = [
  {
    title: 'Transfer',
    modalTitle: 'Transfer',
    description: 'Easy, Secure Money Transfers',
    img: transfersIcon,
    path: AccountAction.transfer,
    component: <Transfers />,
  },
  {
    title: 'Account details',
    modalTitle: 'Account details',
    description: 'Explore your financial profile',
    img: infoOutlinedIcon,
    path: AccountAction.accountDetails,
    component: <AccountDetails />,
  },
  {
    title: 'More',
    modalTitle: 'More',
    description: 'Unlink account',
    img: moreVertFilled,
    path: AccountAction.more,
    component: <More />,
    isControled: true,
  },
]

export const ActionsButtons = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentAction = searchParams.get('action') || ''

  const handleChangeCardAction = (action: AccountAction) => {
    setSearchParams({ action })
  }

  return (
    <>
      {actions.map((action) => (
        <div className={styles.item} key={action.path}>
          <ActionButton
            {...action}
            onClick={() => handleChangeCardAction(action.path)}
          />
          <DrawerModal
            isShow={action.path === currentAction}
            onClose={() => {}}
            titleText={action.modalTitle}
            isControled={action.isControled}
          >
            {action.component}
          </DrawerModal>
        </div>
      ))}
    </>
  )
}