import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircle from 'assets/svg/check-circle.svg';
import { AnimateWrapper } from 'components/animate-wrapper';
import NavigationTitle from '../navigationTitle';
import styles from './style.module.scss';
import { Button, SelectChangeEvent, Switch, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import helper from 'services/helper';
import dayjs from 'dayjs';
import {
  useOpenBurnerCardControllerMutation,
  usePaymentCardsControllerQuery,
} from 'api/endpoints/paymentCards';
import { AccountsFromSelect } from '../accountsSelect';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useSubUsersAvailablePaymentCardsControllerQuery } from 'api/endpoints/subProfiles';
import { useCurrentUser } from 'providers/user.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import { appRoutes } from '../../../routes';
import { OPEN_NEW_CARD_SLEEP_MS } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { usePaymentCards } from '../../../api/hooksApi/usePaymentCards';
import { requireNotNull } from '../../utils/require-not-null';

interface PropTypes {
  isAuthorizedUsers?: boolean;
  setPageStatus?: (string) => void;
  setChooseCardsMode?: (string) => void;
  onStart?(): void;
  onFinish?(): void;

  onCardOpened?(newCardId: string): void;
}

const MAX_VALUE = 4294967295;

const NewBernerCard = ({
  isAuthorizedUsers,
  setPageStatus,
  setChooseCardsMode,
  onStart,
  onFinish,
  onCardOpened,
}: PropTypes) => {
  const { t } = useTranslationProvider();
  const paymentCards = usePaymentCards();
  const navigate = useNavigate();
  const { isSubUser, subUserRights } = useCurrentUser();
  const { setSnackBar } = useSnackBar();
  const [openBurnerCardController] = useOpenBurnerCardControllerMutation();
  const { unclosedFinancialAccounts } = useFinancialAccounts();
  const { refetch: refetchPaymentCards } = usePaymentCardsControllerQuery();
  const { refetch: refetchSubUsersAvailablePaymentCards } =
    useSubUsersAvailablePaymentCardsControllerQuery({});
  const { accountId } = useParams();
  const [account, setAccount] = useState<any>(accountId);
  const [isCheckedMaxNumber, setIsCheckedMaxNumber] = useState(false);
  const [isCheckedMaxAmount, setIsCheckedMaxAmount] = useState(false);
  const [isCheckedExpirationDate, setIsCheckedExpirationDate] = useState(false);
  const [maxNumber, setMaxNumber] = useState<string>('');
  const [maxAmount, setMaxAmount] = useState<string | undefined>();
  const [isLoading, setIsloading] = useState<boolean>();
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [expirationDate, setExpirationDate] = useState<any>(
    dayjs().add(1, 'month')
  );
  const subUserNotHavePermissionFinAcc =
    isSubUser &&
    subUserRights({
      viewAllCardsAndFinancialAccounts: false,
      mainAccess: false,
    }) &&
    !unclosedFinancialAccounts?.length;

  useEffect(() => {
    if (subUserNotHavePermissionFinAcc) {
      setSnackBar({
        type: 'info',
        message:
          'Your account is not currently linked to any financial accounts.',
        isShow: true,
        width: '400px',
      });
    }
  }, [subUserNotHavePermissionFinAcc]);

  const handleNavigateBack = () =>
    setChooseCardsMode
      ? setChooseCardsMode('manage-card')
      : navigate('/home/open-new-product');
  const handleChangeMaxNumber = (event) => {
    const value = event.target.value;
    if ((!!Number(value) && Number(value) <= 20) || value === '')
      setMaxNumber(event.target.value);
  };

  const sxProp = {
    width: '100%',
    background: '#fff',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
    },
  };

  const items = [
    {
      title: `${t('common.Max number of transaction', {
        defaultValue: 'Max number of transaction',
      })}:`,
      onCheck: setIsCheckedMaxNumber,
      isChecked: isCheckedMaxNumber,
      controller: (
        <TextField
          disabled={isLoading}
          sx={sxProp}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          variant="outlined"
          value={maxNumber}
          onChange={handleChangeMaxNumber}
          placeholder="Enter a value from 1 to 20"
        />
      ),
    },
    {
      title: `${t('common.Max amount available to spend', {
        defaultValue: 'Max amount available to spend',
      })}:`,
      onCheck: setIsCheckedMaxAmount,
      isChecked: isCheckedMaxAmount,
      controller: (
        <TextField
          disabled={isLoading}
          variant="outlined"
          fullWidth
          sx={sxProp}
          value={maxAmount ? '$ ' + helper.formatUsdValue(maxAmount) : ''}
          placeholder="$ 0.00"
          onChange={(event) =>
            handleChangeMaxAmount(
              event.target.value.toString().match(/\d/g)?.join('')
            )
          }
        />
      ),
    },
    {
      title: `${t('Card.Expiration date', {
        defaultValue: 'Expiration date',
      })}:`,
      onCheck: setIsCheckedExpirationDate,
      isChecked: isCheckedExpirationDate,
      controller: (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={isLoading}
            value={expirationDate}
            onChange={setExpirationDate}
            sx={sxProp}
            disablePast
            maxDate={dayjs().add(1, 'month')}
          />
        </LocalizationProvider>
      ),
    },
  ];
  const handleChangeMaxAmount = (value) => {
    if (+value > MAX_VALUE) return;
    setMaxAmount(value);
  };
  const handleSubmit = async () => {
    try {
      onStart && onStart();
      setIsloading(true);
      const response: any = await openBurnerCardController({
        financialAccountId: account,
        paymentCardName: null,
        expirationDate: isCheckedExpirationDate
          ? expirationDate?.toISOString()
          : undefined,
        maxTotalSpending: isCheckedMaxAmount ? maxAmount : undefined,
        maxTransactionsCount: isCheckedMaxNumber ? maxNumber : undefined,
      }).unwrap();

      await helper.sleep(OPEN_NEW_CARD_SLEEP_MS);
      await refetchPaymentCards().unwrap();
      refetchSubUsersAvailablePaymentCards();
      paymentCards.paymentCardsRefetch();
      setSnackBar({
        type: 'success',
        message:
          'Card successfully opened. Your new card is now active and ready to use!',
        isShow: true,
      });

      const newCardId = requireNotNull(response?.issuePaymentCardForFinancialAccount?.id);
      if (onCardOpened) {
        onCardOpened(newCardId)
      } else {
        navigate(appRoutes.cardTransactions(newCardId));
      }
    } catch (error: any) {
      setIsloading(false);
      setSnackBar({
        type: 'error',
        message: error?.data?.ExpirationDate?.length
          && error.data.ExpirationDate[0] || helper.formatErrors(error.data),
        isShow: true,
      });
    } finally {
      onFinish && onFinish();
    }
  };
  const handlerChangeAccoutnFrom = async (event: SelectChangeEvent) => {
    setAccount(event.target.value as string);
  };

  const isValid = () => {
    if (
      (!isCheckedExpirationDate &&
        !isCheckedMaxAmount &&
        !isCheckedMaxNumber) ||
      !account ||
      (isCheckedExpirationDate && !expirationDate) ||
      (isCheckedMaxAmount && !maxAmount) ||
      (isCheckedMaxNumber && !maxNumber)
    ) {
      return false;
    }
    return true;
  };

  return (
    <AnimateWrapper className="fade">
      {!isAuthorizedUsers && (
        <NavigationTitle
          handleNavigate={handleNavigateBack}
          title={t('Card.Open New Burner Card', {
            defaultValue: 'Open New Burner Card',
          })}
        />
      )}

      {!isSuccess && (
        <div className={styles.container}>
          <AccountsFromSelect
            title={t('common.Select account', {
              defaultValue: 'Select account',
            })}
            onChange={handlerChangeAccoutnFrom}
            accounts={unclosedFinancialAccounts}
            value={account}
            isPartnersAccountsHidden
            isDisabled={isLoading}
          />

          <div className={styles.controllers}>
            <div className={styles.title}>
              {t('Card.Card settings', {
                defaultValue: 'Card settings',
              })}
            </div>
            {items.map((item, index) => (
              <div key={index}>
                <div className={styles.controllerItem}>
                  <span>{item.title}</span>
                  <Switch
                    disabled={isLoading}
                    checked={item.isChecked}
                    onChange={() => item.onCheck(!item.isChecked)}
                  />
                </div>
                {item.isChecked && item.controller}
              </div>
            ))}
          </div>
          <div className={styles.buttonsContainer}>
            <LoadingButton
              onClick={handleSubmit}
              loading={isLoading}
              disabled={!isValid()}
              variant="contained"
            >
              {t('common.Accept', {
                defaultValue: 'Accept',
              })}
            </LoadingButton>
          </div>
        </div>
      )}
      {isSuccess && (
        <div className={styles.container}>
          <img src={CheckCircle} width={120} height={120}/>
          <div className={styles.successText}>
            <div className={styles.title}>
              {t('Card.Burner card created', {
                defaultValue: 'Burner card created!',
              })}
            </div>
            <div className={styles.message}>
              {t('Card.Congratulations The cards has been created you', {
                defaultValue:
                  'Congratulations! The cards has been created. You may now go to the card details by pressing the button below.',
              })}
            </div>
          </div>

          <Button
            onClick={setPageStatus ? () => setPageStatus('manage') : undefined}
            variant="contained"
          >
            {setPageStatus
              ? t('Login.Return to Authorized user profile', {
                defaultValue: 'Return to Authorized user profile',
              })
              : t('Card.Go To Your Card', {
                defaultValue: 'Go To Your Card',
              })}
          </Button>
        </div>
      )}
    </AnimateWrapper>
  );
};

export default NewBernerCard;
