import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import { ClockIcon } from 'shared/ui/icons'

export const EmptyScheduledTransfer = () => {
  return (
    <div className={styles.container}>
      <ClockIcon />
      <Typography>No scheduled transfers</Typography>
      <Typography>Scheduled transfers will be displayed here</Typography>
    </div>
  )
}
