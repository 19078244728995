import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { SupportModeEnum } from "pages/support/types/enums";
import { Button } from "shared/ui/buttons";

const Empty = () => {
  const { t } = useTranslationProvider();
  const [, setSearchParams] = useSearchParams();

  const handleNavigate = () => {
    setSearchParams({
      pageMode: SupportModeEnum.CreateNewRequest,
      title: t("Support.Create new request", {
        defaultValue: "Create new request",
      }),
    });
  };
  return (
    <div className={styles.wrapper}>
      <p className={styles.wrapper_message}>
        {t("Support.There are currently ", {
          defaultValue:
            "There are currently no outstanding requests to address. If you have any inquiries or need assistance, feel free to reach out to our support team. Thank you for using our services.",
        })}
      </p>
      <Button
        onClick={handleNavigate}
        variant="contained"
        className={styles.wrapper_button}
      >
          +{" "}
          {t("Support.Create new request", {
            defaultValue: "Create new request",
          })}
      </Button>
    </div>
  );
};

export default Empty;
