import { BrandsSort, BrandsCategory } from '../types';

export const dataSort = [
  BrandsSort.AZ,
  BrandsSort.ZA,
  BrandsSort.DescendingDiscount,
  BrandsSort.AscendingDiscount,
];

export const initialDataCategory = [
  { label: BrandsCategory.Baby, value: true },
  { label: BrandsCategory.Beauty, value: true },
  { label: BrandsCategory.Books, value: true },
  { label: BrandsCategory.Cars, value: true },
  { label: BrandsCategory.Charity, value: true },
  { label: BrandsCategory.Craft, value: true },
  { label: BrandsCategory.Cryptocurrency, value: true },
  { label: BrandsCategory.Cycling, value: true },
  { label: BrandsCategory.DepartmentStore, value: true },
  { label: BrandsCategory.Fashion, value: true },
  { label: BrandsCategory.Electronics, value: true },
  { label: BrandsCategory.FoodDrink, value: true },
  { label: BrandsCategory.Gaming, value: true },
  { label: BrandsCategory.Home, value: true },
  { label: BrandsCategory.Jewellery, value: true },
  { label: BrandsCategory.Music, value: true },
  { label: BrandsCategory.SchoolVouchers, value: true },
  { label: BrandsCategory.Sports, value: true },
  { label: BrandsCategory.Supermarket, value: true },
  { label: BrandsCategory.Toys, value: true },
  { label: BrandsCategory.TravelLeisure, value: true },
  { label: BrandsCategory.TVMovies, value: true },
  { label: BrandsCategory.Other, value: true },
];
