import styles from "./style.module.scss";
import { AccountsFromSelect } from "shared/components/accountsSelect";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import CurrencyDollar from "assets/svg/currency-dollar.svg";
import { useState } from "react";
import { SelectChangeEvent, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import helper from "services/helper";

const AccountSelect = () => {
  const { t } = useTranslationProvider();
  const navigate = useNavigate();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { accountId } = useParams();
  const handlerChangeAccoutnFrom = async (event: SelectChangeEvent) => {
    navigate(`/account/${event.target.value}`);
  };
  const currentAccount = unclosedFinancialAccounts.find(
    (account) => account.financialAccountId === accountId
  );

  if (financialAccountsIsLoading) {
    return <Skeleton height={160} />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <div className={styles.iconContainer}>
          <img src={CurrencyDollar} />
        </div>

        <AccountsFromSelect
          onChange={handlerChangeAccoutnFrom}
          accounts={unclosedFinancialAccounts}
          value={accountId!}
          height={40}
          hideBalance
        />
      </div>
      <div className={styles.balanceWrapper}>
        <div className={styles.balanceContainer}>
          <div className={styles.balanceValue}>
            ${helper.moneyFormat(currentAccount?.availableCash?.value)}
          </div>
          <div className={styles.balanceType}>Available Balance</div>
        </div>
        {currentAccount?.cash?.value !==
          currentAccount?.availableCash?.value && currentAccount?.cash?.value && (
            <div className={styles.balanceContainer}>
              <div className={styles.balanceValue}>
                ${helper.moneyFormat(currentAccount?.cash?.value)}
              </div>
              <div className={styles.balanceType}>Present Balance</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default AccountSelect;
