import { FC } from "react";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";
import closeIcon from "assets/svg/close-gray.svg";
import searchIcon from "assets/svg/search-gray.svg";
import { ChangeEvent } from "react";
import IconButton from "@mui/material/IconButton";

interface SelectTransferPointsProps {
  value: string;
  handleOnChangeTransferTo: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  clearInput: () => void;
}

const SelectTransferPoints: FC<SelectTransferPointsProps> = ({
  value,
  handleOnChangeTransferTo,
  clearInput,
}) => {
  const { t } = useTranslationProvider();

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="outlined-adornment-amount" shrink>
        {t("Transfers.Transfer to", {
          defaultValue: "Transfer to",
        })}
      </InputLabel>
      <OutlinedInput
        notched
        id="outlined-required"
        label={t("Transfers.Transfer to", {
          defaultValue: "Transfer to",
        })}
        value={value}
        inputProps={{ shrink: "true" }}
        placeholder={t("Rewards.Enter email or phone", {
          defaultValue: "Enter email or phone",
        })}
        onChange={handleOnChangeTransferTo}
        autoComplete="off"
        startAdornment={
          value && (
            <img src={searchIcon} alt="Search" style={{ marginRight: "4px" }} />
          )
        }
        endAdornment={
          value && (
            <IconButton aria-label="Delete" edge="start" onClick={clearInput}>
              <img src={closeIcon} alt="Delete" />
            </IconButton>
          )
        }
      />
    </FormControl>
  );
};
export default SelectTransferPoints;
