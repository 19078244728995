import { useMemo } from "react";
import { useSpendingReportControllerQuery } from "../endpoints/paymentCards";
 import data from "constants/data";

export const useSpendingReport = (paymentCardId, dateFrom, dateTo) => {
  const {
    data: spendingReportData,
    isFetching,
    refetch,
  } = useSpendingReportControllerQuery({
    paymentCardId,
    dateFrom: dateFrom,
    dateTo: dateTo,
  });

  const value = useMemo<any>(() => {
    if (!spendingReportData) {
      return {};
    }

    if (spendingReportData) {
      const total = spendingReportData.total;
      const categories = spendingReportData.categories.map((i) => {
        const categoryData = data.reportCategories.find(
          (d) => d.category === i.category
        );
        return {
          ...i,
          ...categoryData,
        };
      });

      return {
        categories,
        total,
      };
    }
  }, [spendingReportData, dateFrom, dateTo]);

  return {
    spendingReport: value,
    spendingReportIsLoading: isFetching,
    refetch,
  };
};
