import { Department } from 'api/endpoints/subProfiles';

export const departmentList = [
  Department.Marketing,
  Department.Finance,
  Department.OperationsManagement,
  Department.HumanResource,
  Department.Technology,
  Department.RiskManagement,
  Department.Sales,
  Department.Support,
  Department.TopManagement,
];

export const primaryAccountToolTipText =
  'Your primary account is where incoming funds are deposited, including cases when other clients of Netevia send you money via email or phone. To change your primary account, please select one below.';
