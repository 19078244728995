import { useCallback } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MonthCalendar from "shared/components/calendar/monthCalendar";
import { DateField } from "@mui/x-date-pickers/DateField";
import moment from "moment";
import { useRewards } from "pages/reward/provider/useRewards";
import dayjs from "dayjs";
import calendarIcon from "assets/svg/calendarIcon-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";

const RewardsFilterMonth = () => {
  const { t } = useTranslationProvider();

  const { rewardsHistoryDatePeriod, setRewardsHistoryDatePeriod } =
    useRewards();

  const handleDateChange = useCallback(
    (date) => {
      const dateFrom = moment(new Date(date))
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00Z");
      const dateTo = moment(new Date(date))
        .endOf("month")
        .format("YYYY-MM-DD 23:59:ssZ");
      setRewardsHistoryDatePeriod({
        dateFrom,
        dateTo,
      });
    },
    [rewardsHistoryDatePeriod]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MonthCalendar
        currentDate={moment(rewardsHistoryDatePeriod.dateFrom).toDate()}
        onChange={handleDateChange}
        placement="bottom"
        styleWrapperTooltip={{
          borderRadius: "4px",
          boxShadow:
            "0px 4px 18px 3px rgba(46, 50, 52, 0.08), 0px 1px 6px 0px rgba(11, 60, 93, 0.12)",
          backgroundColor: "#FFF",
          color: "#000000",
          ".Mui-selected": {
            color: "#0277BD",
            backgroundColor: "#FFF",
            "&:hover": {
              backgroundColor: "#0277BD",
              color: "#FFF",
            },
            "&:focus": {
              backgroundColor: "#0277BD",
              color: "#FFF",
            },
          },
        }}
        fill={"#000000"}
        disableFuture
      >
        <DateField
          format="MM/YYYY"
          label={t("Limits.Period", {
            defaultValue: "Period",
          })}
          value={dayjs(rewardsHistoryDatePeriod.dateFrom)}
          sx={{
            width: "100%",
          }}
          InputProps={{
            endAdornment: <img src={calendarIcon} alt="Calendar" />,
          }}
        />
      </MonthCalendar>
    </LocalizationProvider>
  );
};
export default RewardsFilterMonth;
