import {
  useGetNotificationsQuery,
  useGetNotificationsNewQuery,
  useNotificationsReadMutation,
  GetNotificationsApi,
} from 'api/endpoints/notifications';
import { Skeleton } from 'components/skeleton';
import { NotificationsGroup } from 'enums';
import { ButtonTab } from 'shared/ui/buttons';
import {
  BellIcon,
  MessageOutlinedIcon,
  UpcomingOutlinedIcon,
} from 'shared/ui/icons';
import { EmptyItem } from './components/emptyItem';
import { ContentHeader } from './components/contentHeader';
import { ContentItem } from './components/contentItem';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import styles from './style.module.scss';


interface ContentProps {
  contentGroup: NotificationsGroup;
}

export const Content = ({ contentGroup }: ContentProps) => {
    const { setSnackBar } = useSnackBar()
  const { data: allNotifications, isLoading: isLoadingAllNotifications } =
    useGetNotificationsQuery();
  const { data: newNotifications, isLoading: isLoadingNewNotifications } =
    useGetNotificationsNewQuery();

    const [notificationsRead, {isLoading:  isLoadingNotificationsRead}]=useNotificationsReadMutation()
  const newContentObj = newNotifications?.find(
    (item) => item.group === contentGroup
  );

  const renderEmptyItem = (contentGroup: NotificationsGroup) => {
    const emptyItemProps = {
      [NotificationsGroup.Notifications]: {
        img: <BellIcon />,
        title: 'You don’t have any notifications yet',
        subTitle: 'All notifications will be displayed here.',
      },
      [NotificationsGroup.Messages]: {
        img: <MessageOutlinedIcon />,
        title: 'You don’t have any messages yet',
        subTitle: 'All messages will be displayed here.',
      },
      [NotificationsGroup.Campaigns]: {
        img: <UpcomingOutlinedIcon />,
        title: 'You don’t have any offers yet',
        subTitle: 'All offers will be displayed here.',
      },
    };

    const emptyItemPropsForGroup = emptyItemProps[contentGroup];

    return (
      <EmptyItem
        img={emptyItemPropsForGroup.img}
        title={emptyItemPropsForGroup.title}
        subTitle={emptyItemPropsForGroup.subTitle}
      />
    );
  };

const readMessage = async(id: number) => {
    try {
       await notificationsRead({
            ids: [id],
            readAll: false 
       }).unwrap()
    }catch(e:any){
        setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
        })
    }
}

const readAllMessages = async() => {
    try {
       await notificationsRead({
            notificationGroup: contentGroup,
            readAll: true 
       }).unwrap()
    }catch(e:any){
        setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
        })
    }
}

  const renderContentItem = (contentItems: GetNotificationsApi[]) =>
    contentItems?.map((item) => (
        <ContentItem
          key={item.id}
          item={item}
          handleReadMessage={readMessage}
          disabled={isLoadingNotificationsRead || Boolean(item.dateRead)}
        />
    ));


  const renderContentList = () => {
    const contentItems = allNotifications?.filter(
      (item) => item.group === contentGroup
    );

    return !!contentItems?.length
      ? renderContentItem(contentItems)
      : renderEmptyItem(contentGroup);
  };


  return isLoadingAllNotifications || isLoadingNewNotifications ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <div className={styles.container}>
      <ContentHeader
        count={newContentObj?.count || 0}
        disabled={!newContentObj?.count || isLoadingNotificationsRead}
        handleReadAllMessages={readAllMessages}
      />
      {renderContentList()}
    </div>
  );
};
