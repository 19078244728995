import { FC } from "react";
import { css, styled, Stepper, Step, StepLabel } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useMediaQuery, useTheme } from "@mui/material";

interface LineStepperProps {
  activeStep: number;
  steps: string[];
}

const LineStepper: FC<LineStepperProps> = ({ steps, activeStep }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const styles = {
    ".MuiSvgIcon-root": {
      width: "24px",
      height: "24px",
      zIndex: 1,
    },
    ".MuiSvgIcon-root.Mui-completed": {
      color: "#023047",
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: "#023047",
    },

    ".MuiSvgIcon-root:not(.Mui-completed, .Mui-active)": {
      color: "#d9dddf",
      ".MuiStepIcon-text": {
        fill: "rgba(0, 0, 0, 0.87)",
      },
    },
    ".MuiStepLabel-labelContainer span": {
      color: "rgba(0, 0, 0, 0.60)",
      fontFamily: "Noto Sans",
      fontSize: matches ? "16px" : "10px",
      fontWeight: matches ? 400 : 500,
      lineHeight: matches ? "175%" : "14px",
      letterSpacing: "0.15px",
      whiteSpace: matches ? "nowrap" : "normal",
    },
  };

  return (
    <Wrapper>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={styles}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Wrapper>
  );
};

export default LineStepper;
const Wrapper = styled("div")`
  ${({ theme }) => css`
    width: 100%;
  `}
`;
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 2,
    left: "-51%",
    right: "48%",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#cfd8de",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#cfd8de",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: "20px",
    border: 0,
    backgroundColor: "#eaeff1",
    borderRadius: 1,
  },
  [`${theme.breakpoints.down("sm")}`]: {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 2,
      left: "-57%",
      right: "48%",
    },
  },
}));
