import { FC } from "react";
import { Typography } from "@mui/material";
import exclamationIcon from "assets/svg/exclamation-mark-blue.svg";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";
import styles  from './style.module.scss'; // Импортируем SCSS

interface ItemBalanceProps {
  balance: string;
  description: string;
  tooltip?: string;
}

const ItemBalance: FC<ItemBalanceProps> = ({
  balance,
  description,
  tooltip,
}) => {
  return (
    <div className={styles.wrapper}>
      <Typography className={styles.balanceText}>${balance}</Typography>
      <div className={styles.box}>
        <Typography className={styles.descriptionText}>{description}</Typography>
        {tooltip && <TooltipChildren description={tooltip}>
          <img src={exclamationIcon} alt="tooltip icon" />
        </TooltipChildren>}
      </div>
    </div>
  );
};

export default ItemBalance;
