import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";

export interface Period {
  name: string;
  value: string;
  fileFormats: string[];
}

export interface YearDataApi {
  year: string;
  periods: Period[];
}

interface StatementsPeriodsArg {
  financialAccountId?: string
}

export enum FileFormat {
  PDF = 'PDF',
  CSV = 'CSV',
  QBO = 'QBO', 
  OFX = 'OFX'
}
interface StatementsV2AllArg {
  fileFormat: FileFormat
  financialAccountIds: string[]
  period: string
  email?: string
}
interface StatementsV2PDFArg {
  financialAccountId: string
  period: string
}
interface StatementsApi {
  name: string
  content: string
  contentType: string
}

interface StatementsV2Arg {
  financialAccountId: string
  period: string
  fileFormat: FileFormat
}
export const statementsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "statements",
  endpoints: (builder) => ({
    statementsV2: builder.mutation<StatementsApi, StatementsV2Arg>({
      query: ({ period, financialAccountId, fileFormat }) => ({
        url: "api/statements/v2",
        method: "GET",
        params: {
          period,
          financialAccountId,
          fileFormat,
        },
      }),
    }),
    statementsPeriods: builder.query<YearDataApi[], StatementsPeriodsArg>({
      query: ({ financialAccountId }) => ({
        url: "api/statements/periods",
        method: "GET",
        params: {
          financialAccountId,
        },
      }),
    }),
    ///////////////// Statement/V2
    statementsV2PDF: builder.mutation<StatementsApi, StatementsV2PDFArg>({
      query: ({ period, financialAccountId }) => ({
        url: "api/Statement/V2/pdf",
        method: "GET",
        params: {
          period,
          financialAccountId,
        },
      }),
    }),
    statementsV2All: builder.mutation<string, StatementsV2AllArg>({
      query: (queryArg) => ({
        url: "api/Statement/V2/full",
        method: "POST",
        body: queryArg,
      }),
    }),
  
  }),
});

export const {
  useStatementsV2Mutation,
  useStatementsPeriodsQuery,
  useStatementsV2PDFMutation,
  useStatementsV2AllMutation,
} = statementsApi;
