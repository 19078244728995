import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper'
import { Intro } from '../intro'
import { MakeTransferConfirm } from '../make-transfer-confirm'
import { AuntificatorTransfer } from '../auntificatorTransfer'
import { MakeTransferSuccess } from '../make-transfer-success'
import { ManageScheduleTransferDetail } from '../manage-schedule-transfer-detail'
import { ManageScheduleTransferEdit } from '../manage-schedule-transfer-edit'
import { AuntificatorScheduleTransfer } from '../auntificatorScheduleTransfer '
import { ManageScheduleTransferSuccess } from '../manage-schedule-transfer-success'
import { ManagePayee } from '../manage-payee'
import { ManagePayeeDetail } from '../manage-payee-detail'
import { ManagePayeeAdd } from '../manage-payee-add'
import { AuntificatorExternalPayee } from '../auntificatorExternalPayee'

interface Props {
  isShow: boolean
  onClose: () => void
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'ACH transfer',
      prevId: null,
      Element: <Intro />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'ACH transfer',
      prevId: '0',
      Element: <MakeTransferConfirm />,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: true,
      title: 'ACH transfer',
      prevId: '1',
      Element: <AuntificatorTransfer />,
    },
  ],
  [
    '3',
    {
      id: '3',
      isCanGoBack: false,
      title: 'Transfer details',
      prevId: '0',
      Element: <MakeTransferSuccess />,
    },
  ],
  [
    '4',
    {
      id: '4',
      isCanGoBack: true,
      title: 'Transfer details',
      prevId: '0',
      Element: <ManageScheduleTransferDetail />,
    },
  ],
  [
    '5',
    {
      id: '5',
      isCanGoBack: true,
      title: 'Edit transfer',
      prevId: '4',
      Element: <ManageScheduleTransferEdit />,
    },
  ],
  [
    '6',
    {
      id: '6',
      isCanGoBack: true,
      title: 'Edit transfer',
      prevId: '5',
      Element: <AuntificatorScheduleTransfer />,
    },
  ],
  [
    '7',
    {
      id: '7',
      isCanGoBack: false,
      title: 'Edit transfer',
      prevId: '6',
      Element: <ManageScheduleTransferSuccess />,
    },
  ],
  [
    '8',
    {
      id: '8',
      isCanGoBack: true,
      title: 'Manage payees',
      prevId: '0',
      Element: <ManagePayee />,
    },
  ],
  [
    '9',
    {
      id: '9',
      isCanGoBack: true,
      title: 'Payee detail',
      prevId: '8',
      Element: <ManagePayeeDetail />,
    },
  ],
  [
    '10',
    {
      id: '10',
      isCanGoBack: true,
      title: 'Add payee',
      prevId: '8',
      Element: <ManagePayeeAdd />,
    },
  ],
  [
    '11',
    {
      id: '11',
      isCanGoBack: true,
      title: 'Add payee',
      prevId: '10',
      Element: <AuntificatorExternalPayee />,
    },
  ],
]

const MapSteps = new Map(steps)

export const AchTransfer = (props: Props) => {
  const { isShow, onClose } = props

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={onClose}
    />
  )
}
