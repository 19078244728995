import React, { useState } from 'react';
import parse from 'html-react-parser'; 
import { Button } from 'shared/ui/buttons';

import styles from './style.module.scss';

interface ExpandableTextProps {
  shortText: string;
  fullText: string;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ shortText, fullText }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatTextWithLineBreaks = (inputText: string) => {
    return inputText.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {parse(line)}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={styles.expandable}>
      {isExpanded ? formatTextWithLineBreaks(fullText) : formatTextWithLineBreaks(shortText)}
      <Button variant='text' onClick={toggleExpand} className={styles.expandableBtn}>
        {isExpanded ? 'See less' : 'See more'}
      </Button>
    </div>
  );
};

export default ExpandableText;
