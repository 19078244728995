import { FC } from 'react';
import styles from './style.module.scss';
import deviceIcon from 'assets/svg/device-grayColor.svg';
import Button from '@mui/material/Button';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useConfirmDialog } from 'providers/confirm.provider';

interface DevicesItemProps {
  id: string;
  name: string;
  date: string;
  handleDelete?: (id: string) => void;

  ip?: string | null;
  location?: string | null;
}

export const DevicesItem: FC<DevicesItemProps> = ({
  id,
  name,
  date,
  handleDelete,
  location,
  ip,
}) => {
  const confirm = useConfirmDialog();
  const { t } = useTranslationProvider();

  const onDelete = () => {
    confirm.show({
      applyButtonText: 'Delete',
      dialogText: `Are you sure you want to delete device ${name}`,
      cancelButtonType: 'text',
      cancelButtonText: 'CANCEL',
      cancelError: true,
      typeNew: true,
      onApply() {
        handleDelete?.(id);
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <img src={deviceIcon} />
      <div className={styles.box}>
        <div className={styles.deviceInfo}>
          <span className={styles.deviceInfo_name}>{name}</span>
          <span className={styles.deviceInfo_date}>
            {date}
            {''}
            {location ? `, ${location}` : ''}
          </span>
          {ip && <div className={styles.deviceInfo_date}>IP {ip}</div>}
        </div>
        {handleDelete && (
          <Button
            variant='outlined'
            className={styles.button}
            onClick={onDelete}
          >
            <span className={styles.button_label}>
              {t('Settings.Delete device', {
                defaultValue: 'Delete device',
              })}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};
