import SettingsDropdown from 'shared/components/sidebar/components/dropDown/SettingsDropdown';
import { Link, useLocation } from 'react-router-dom';
import { logOut } from 'api/logOut';
import { showAppLoader } from '../../../../../components/appLoader';
import cx from 'classnames';
import styles from './style.module.scss';
import AccordionSideBar from '../accordionSideBar';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { usePermission } from 'shared/hooks/usePermission';
import { appRoutes, RewardsRouteSubRoute } from 'routes';
import { SvgIcon } from '../../../svgIcon';

export enum SideBarListLink {
  home = 'home',
  transfer = 'transfes',
  rewards = 'rewards',
  settings = 'settings',
  loans = 'loans',
}
interface Props {
  isSettingsOpen: boolean;
  setIsSettingsOpen: (value: boolean) => void;
  isLightMode?: boolean;
}

const SideBarList = ({ isSettingsOpen, setIsSettingsOpen, isLightMode }: Props) => {
  const { t } = useTranslationProvider();
  const { pathname } = useLocation();
  const { rewardsPermission , giftCardPermission, transferPermission} = usePermission();


  const activeLink = pathname.split("/")[1].trim();
  const activeLinkHome = activeLink === SideBarListLink.home;
  const activeLinkTransfer = activeLink === SideBarListLink.transfer;
  const activeLinkRewards = activeLink === SideBarListLink.rewards;
  const activeLinkSettings = activeLink === SideBarListLink.settings;
  // const activeLinkLoans = activeLink === SideBarListLink.loans;

  const handleLogout = async () => {
    showAppLoader();
    logOut();
  };

  const activeStyleItem = (activeLink) =>
    cx(styles.item, { [styles.active_item]: activeLink });

  return (
    <div className={cx(styles.container, isLightMode && styles.containerLightMode)}>
      <div className={styles.container_box}>
        <Link to={appRoutes.home()} className={activeStyleItem(activeLinkHome)}>
          <SvgIcon name="Home" />
          <span className={styles.item_text}>
            {t("SideBar.Home", {
              defaultValue: "Home",
            })}
          </span>
        </Link>

        {transferPermission && (
          <Link to={appRoutes.transfer()} className={activeStyleItem(activeLinkTransfer)}>
            <SvgIcon name="Transfer" />
            <span className={styles.item_text}>
            {t("SideBar.Transfer", {
              defaultValue: "Transfer",
            })}
          </span>
          </Link>
        )}

        {rewardsPermission && (
          <Link to={appRoutes.rewards()} className={activeStyleItem(activeLinkRewards)}>
            <SvgIcon name="Reward" />
            <span className={styles.item_text}>
              {t("SideBar.Rewards", {
                defaultValue: "Rewards",
              })}
            </span>
          </Link>
        )}

        {giftCardPermission && (
          <Link
            className={activeStyleItem(activeLinkRewards)}
            to={appRoutes.rewards(RewardsRouteSubRoute.myGiftCard)}
          >
            <SvgIcon name="Reward" />
            <span className={styles.item_text}>
            Gift Cards
            </span>
          </Link>
        )}

        {/* <Link to="/loans" className={activeStyleItem(activeLinkLoans)}>
          <img src={LoansIcon} />
          <span className={styles.item_text}>Loans</span>
        </Link> */}

        <AccordionSideBar
          isLightMode={isLightMode}
          buttonLable={t("SideBar.Settings", {
            defaultValue: "Settings",
          })}
          img={<SvgIcon name="Settings" />}
          expanded={isSettingsOpen}
          setExpanded={setIsSettingsOpen}
          activeSummary={activeLinkSettings}
        >
          <SettingsDropdown isLightMode={isLightMode} />
        </AccordionSideBar>
      </div>
      <div className={styles.container_logOut} onClick={handleLogout}>
        <Link to="#" className={styles.item}>
          <SvgIcon name="Logout" />
          <span className={styles.item_text}>
            {t("Verify.Log Out", {
              defaultValue: "Log Out",
            })}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default SideBarList;
