import { ChangeEvent, useState, FC } from "react";
import Square from "assets/svg/square.svg";
import CheckSquare from "assets/svg/check-square.svg";
import BlockedSquare from "assets/svg/x-square.svg";
import LimitItem from "./LimitItem";
import LimitButtons from "./LimitButtons";
import styles from "../style.module.scss";
import LimitSearchInput from "./LimitSearchInput";
import { useCurrentUser } from "providers/user.provider";

interface itemProps {
  description: string;
  name: string;
  rule: string | null;
  visible: boolean;
}

interface LimitsListProps {
  arr: itemProps[];
  setLimitsPageMode: (string) => void;
  setArr: (newArr: itemProps[]) => void;
}

const LimitsList: FC<LimitsListProps> = ({
  arr,
  setLimitsPageMode,
  setArr,
}) => {
  const { isSubUser, subUserRights } = useCurrentUser();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const handleSelect = (el: itemProps) => {
    const newArr = arr.map((item) => {
      const isMatching = item.name === el.name;
      const shouldUpdateRule = isMatching && !isSubUser;

      if (shouldUpdateRule) {
        switch (item.rule) {
          case null:
            return { ...item, rule: "allowed" };
          case "allowed":
            return { ...item, rule: "blocked" };
          case "blocked":
            return { ...item, rule: null };
          default:
            return item;
        }
      } else {
        return item;
      }
    });

    setArr(newArr);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    const newArr = arr.map((item) => {
      const itemData = item.description.toUpperCase();
      const textData = inputValue.toUpperCase();
      const visible = itemData.indexOf(textData) > -1 ? true : false;
      return {
        ...item,
        visible,
      };
    });

    setArr(newArr);
  };

  const handleSelectAll = (isSelectAll) => {
    if (!isSubUser) {
      setIsSelectAll((prevIsSelectAll) => !prevIsSelectAll);
      const newRule = !isSelectAll ? "allowed" : null;
      const newArr = arr.map((i) => ({
        ...i,
        rule: newRule,
      }));
      setArr(newArr);
    }
  };

  const handleNavigate = () => {
    setLimitsPageMode("limits");
  };

  const renderItems = () => {
    const iconMappings = {
      allowed: CheckSquare,
      blocked: BlockedSquare,
      null: Square,
    };

    return arr
      ?.filter((item) => item.visible)
      .map((el, index) => {
        const icon = el.rule == null ? Square : iconMappings[el.rule];

        return (
          <LimitItem
            key={`${el.name}${index}`}
            title={el.description}
            icon={icon}
            onClick={() => handleSelect(el)}
          />
        );
      });
  };

  return (
    <>
      <LimitSearchInput onChange={handleSearch} value={searchInput} />

      <div className={styles.countries_list}>
        <LimitItem
          title="Select all"
          icon={isSelectAll ? CheckSquare : Square}
          onClick={() => handleSelectAll(isSelectAll)}
        />
        {renderItems()}
      </div>

      <LimitButtons
        onSubmit={handleNavigate}
        onCancel={handleNavigate}
        disabled={isSubUser}
        isLoading={false}
      />
    </>
  );
};

export default LimitsList;
