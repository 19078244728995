import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../baseApi";
import { ApiTags } from "../api-tags";

interface ClientNameArg {
  arg: string;
}
export interface ClientNameApi {
  profileId: number;
  doingBusinessName: string;
  businessPhoneNumber: string;
}

export const searchApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "search",
  endpoints: (builder) => ({
    lastTransferPointsController: builder.query<ClientNameApi[], any>({
      query: () => ({
        url: "lastTransferPoints",
        method: "GET",
      }),
    }),
    clientNameController: builder.mutation<ClientNameApi[], ClientNameArg>({
      query: ({ arg }) => ({
        url: `clientName`,
        method: "GET",
        params: {
          q: arg,
          take: 10,
        },
      }),
    }),
  }),
});

export const {
  useLastTransferPointsControllerQuery,
  useClientNameControllerMutation,
} = searchApi;
