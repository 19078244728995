import { useEffect, useMemo } from "react";
import {
  EnumTransactionStatuses,
  Filters,
  Transaction,
  TransactionData,
  transactionApi,
  useFinancialAccountTransactionsControllerMutation,
  useFinancialAccountTransactionsServerControllerQuery,
} from "../endpoints/transaction";
import moment from "moment";

interface FinancialAccountTransactionsServerRueturnType {
  financialAccountTransactionsServer: {
    pendingTransactions: Transaction[];
    approvedTransactions: Transaction[];
  };
  financialAccountTransactionsServerIsFetching: boolean;
  financialAccountTransactionsServerIsSuccess: boolean;
  filter: Filters;
  financialAccountByFilter: (any) => void;
}

export const useFinancialAccountTransactionsServer = (
  financialAccountId,
  from,
  to,
  merchantCategories,
  filter = {}
): FinancialAccountTransactionsServerRueturnType => {
  // const {
  //   data,
  //   isFetching: financialAccountTransactionsServerIsFetching,
  //   isSuccess: financialAccountTransactionsServerIsSuccess,
  // } = useFinancialAccountTransactionsServerControllerQuery({
  //   financialAccountId,
  //   from,
  //   to,
  // });

  const [
    financialAccountByFilter,
    {
      isLoading: financialAccountTransactionsServerIsFetching,

      isSuccess: financialAccountTransactionsServerIsSuccess,
      data,
    },
  ] = useFinancialAccountTransactionsControllerMutation();

  useEffect(() => {
    financialAccountByFilter({ financialAccountId, from, to, filter });
  }, []);

  const value = useMemo<any>(() => {
    if (!data) {
      return {};
    }
    const transactions = data.data;
    const pendingTransactions = transactions.filter(
      (t) => t.status === EnumTransactionStatuses.PENDING
    );
    const approvedTransactions = transactions.filter(
      (t) => t.status !== EnumTransactionStatuses.PENDING
    );

    return {
      pendingTransactions,
      approvedTransactions,
    };
  }, [data, from, to, filter]);

  return {
    filter: data?.filter!,
    financialAccountTransactionsServer: value,
    financialAccountTransactionsServerIsFetching,
    financialAccountTransactionsServerIsSuccess,
    financialAccountByFilter,
  };
};
