import { useEffect } from 'react';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { TransitionAnimation } from 'shared/ui/animations';
import { useDepositACHTransferMutation } from 'api/endpoints/fundsMovement';
import { useAddScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useAppSelector } from 'shared/models';
import {
  selectFromPayee,
  selectToFinancialAccount,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  selectTransferMemo,
} from 'features/transfers/transfer-from-connected-account/model';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { ScheduleTransferType, findFrequencyByTitle } from 'entities/transfers';
import moment from 'moment';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';
import { requireNotNull } from '../../../../../shared/utils/require-not-null';

export const AuntificatorTransfer = () => {
  const { setSnackBar } = useSnackBar();
  const mfa = useMfa();
  const { goNext, goBack } = useDrawerStepperContext();
  const [depositACHTransferMutation] = useDepositACHTransferMutation();

  const [scheduledTransferToPayee] = useAddScheduledTransferMutation();

  const fromPayee = useAppSelector(selectFromPayee);
  const toFinancialAccount = useAppSelector(selectToFinancialAccount);
  const amount = useAppSelector(selectAmount);
  const frequency = useAppSelector(selectFrequency);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const memo = useAppSelector(selectTransferMemo);

  const frequencyValue = findFrequencyByTitle(frequency);

  const isTransferToday = moment(dateTransfer).isSame(moment(), 'day');

  const depositAch = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      if (code && fromPayee && toFinancialAccount && amount) {
        await depositACHTransferMutation({
          fromFinancialAccountId: fromPayee?.id,
          toFinancialAccountId: toFinancialAccount?.id,
          amount: Number(amount),
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          memo,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        });
        mfa.close();
        goNext('3');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const scheduledTransferToExternalPayee = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      if (fromPayee && toFinancialAccount && amount) {
        await scheduledTransferToPayee({
          fromFinancialAccountId: fromPayee?.id,
          toFinancialAccountId: toFinancialAccount?.id,
          amount: Number(amount),
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          frequency: frequencyValue,
          nextDate: requireNotNull(dateTransfer),
          transferType: ScheduleTransferType.IncommingAchTransfer,
          memo,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message: 'Transfer successfully completed',
          isShow: true,
        });
        mfa.close();
        goNext('3');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handlerSubmit = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    if (!frequencyValue && isTransferToday) {
      await depositAch(secureOperationType, code);

      return;
    } else {
      await scheduledTransferToExternalPayee(secureOperationType, code);
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(
        secureOperationType: SecureOperationType,
        code: string
      ) {
        await handlerSubmit(secureOperationType, code);
      },
    });
  }, []);

  return (
    <TransitionAnimation>
      <Skeleton
        width='100%'
        height={100}
      />
    </TransitionAnimation>
  );
};
