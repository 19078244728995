import React from 'react'
import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import { PersonOutlineIcon } from 'shared/ui/icons'
import cn from 'classnames'
interface IEmptyPayeesProps {
  icon: React.ReactNode
  title: string
  subTitle: string
  stylesWrapper?: any
}

export const EmptyPayees = (props: IEmptyPayeesProps) => {
  const { icon, title, subTitle, stylesWrapper } = props
  return (
    <div className={cn(stylesWrapper, styles.empty)}>
      {icon}
      <Typography>{title}</Typography>
      <Typography>{subTitle}</Typography>
    </div>
  )
}
