import { AccountsTable } from './accountsTable';
import { CardsTable } from './cardsTable';
import { HomePageProvider, useHomePage } from './provider';
import { HomeTableTopPanel } from './homeTableTopPanel';
import { ModeHomeLists } from './types';
import styles from './styles.module.scss';
import DrawerModal from 'shared/components/drawerModal';
import { AddNewAccount } from '../accountList/components/addNewAccount';
import { NewCardFromAccount } from 'pages/accountComponent/Actions/NewCardFromAccount';
import { useSearchParams } from 'react-router-dom';
import { homeSearchParams } from 'routes';

const HomeListsWrapper = () => {
  const { modeHomeList } = useHomePage();
  const [searchParams, setSearchParams] = useSearchParams();
  const drawerMode = searchParams.get(homeSearchParams.drawerMode) || '';

  return (
    <div className={styles.wrapper}>
      <HomeTableTopPanel />
      {modeHomeList === ModeHomeLists.Accounts ? (
        <AccountsTable />
      ) : (
        <CardsTable />
      )}
      <DrawerModal
        isShow={drawerMode === 'addAccount'}
        onClose={() => setSearchParams({})}
        titleText={'Add new account'}
      >
        <AddNewAccount />
      </DrawerModal>
      <NewCardFromAccount />
    </div>
  );
};

export const HomeLists = () => {
  return (
    <HomePageProvider>
      <HomeListsWrapper />
    </HomePageProvider>
  );
};
