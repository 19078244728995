import { useEffect, useState } from 'react';
import { CurrencyCode, SecureOperationType, ShortMessageType } from 'enums';
import { useAppSelector, useAppDispatch } from 'shared/models';
import { selectAutomations, clearDataAutomations } from '../../model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { useSnackBar } from 'providers/snackBar.provider';
import moment from 'moment';
import { ScheduleTransferType } from 'entities/transfers';
import helper from 'services/helper';
import { useUpdateScheduledTransferMutation } from 'api/endpoints/scheduledTransfer';
import { useMfa } from '../../../../../providers/mfa/mfa';
import { Skeleton } from '../../../../../components/skeleton';

export const AuntificatorAutomationsEdit = () => {
  const dispatch = useAppDispatch();
  const mfa = useMfa();
  const { goNext, goBack } = useDrawerStepperContext();
  const { setSnackBar } = useSnackBar();
  const [updateScheduledTransfe] = useUpdateScheduledTransferMutation();

  const automations = useAppSelector(selectAutomations);

  const editAutomations = async (
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      const { id, from, to, amount, memo, minAmount, minimumTopUpAmount } =
        automations;
      if (id && from && to && amount) {
        await updateScheduledTransfe({
          id: id,
          fromFinancialAccountId: from,
          toFinancialAccountId: to,
          amount: Number(amount),
          currencyCode: CurrencyCode.USD,
          oneTimeCode: code,
          secureOperationType: secureOperationType,
          frequency: null,
          nextDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          transferType: ScheduleTransferType.AutoPopup,
          memo,
          minAmount: minimumTopUpAmount ? Number(minAmount) : null,
        }).unwrap();
        setSnackBar({
          type: 'success',
          message: 'Automation updated successfully.',
          isShow: true,
        });
        dispatch(clearDataAutomations());
        mfa.close();
        goNext('10');
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  useEffect(() => {
    mfa.show({
      title: 'Confirm Transfer',
      shortMessageType: ShortMessageType.ApproveTransaction,
      onDrawerClose() {
        goBack();
      },
      async onCodeSubmit(
        secureOperationType: SecureOperationType,
        code: string
      ) {
        await editAutomations(secureOperationType, code);
      },
    });
  }, []);

  return (
    <Skeleton
      width='100%'
      height={100}
    />
  );
};
