import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ActionTile } from 'shared/components/ActionTile';
import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import EditFilled from 'assets/svg/EditFilled.svg';
import VerifiedUserOutlined from 'assets/svg/VerifiedUserOutlined.svg';
import AccountCircleOutlined from 'assets/svg/AccountCircleOutlined.svg';
import HomeOutlined from 'assets/svg/HomeOutlined.svg';
import CopyBlue from 'assets/svg/copy-blue.svg';
import ChevronRightGray from 'assets/svg/chevron-right-gray.svg';
import { useCardInformation } from '../../provider';
import { usePermission } from 'shared/hooks/usePermission';
import { RenameCard } from 'shared/components/RenameCard';
import { useSnackBar } from 'providers/snackBar.provider';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useRenamePaymentCardNameControllerMutation } from 'api/endpoints/paymentCards';
import { useDefaultCardName } from 'shared/hooks/useDefaultCardName';

interface ActionTileProps {
  title: string;
  subTitle: string | JSX.Element;
  infoImg: any;
  actionImg?: any;
  onClick: () => void;
  component?: JSX.Element | false;
  isHide?: boolean;
  isActionLoading?: boolean;
}

const ActionTiles: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackBar();
  const changeNicknameBool = useBoolean();
  const { currentCard, paymentCardDetails, cardsIsLoading, refetchCard } =
    useCardInformation();
  const { linkedAccountPermission } = usePermission();
  const defaultCardName = useDefaultCardName();
  const [isRenameMode, setIsRenameMode] = useState<boolean>(false);
  const [renamePaymentCard, { isLoading: renamePaymentCardIsLoading }] =
    useRenamePaymentCardNameControllerMutation({});

  const handleRenameCard = async (nickName: string) => {
    changeNicknameBool.setTrue();
    try {
      await renamePaymentCard({
        paymentCardId: currentCard.id,
        nickName,
      }).unwrap();
      await refetchCard();
      snackbar.setSnackBar({
        type: 'success',
        message: 'The card has been successfully renamed',
        isShow: true,
      });
    } catch (error: any) {
      changeNicknameBool.setFalse();
      const errMessage =
        error?.data?.NickName?.join(',') || 'Change nickname error';
      snackbar.setSnackBar({
        type: 'error',
        message: errMessage,
        isShow: true,
      });
    } finally {
      setIsRenameMode(false);
    }
  };

  const handleAddressCopy = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      snackbar.setSnackBar({
        type: 'success',
        message: 'Address copied',
        isShow: true,
      });
    } catch (err) {
      snackbar.setSnackBar({
        type: 'error',
        message: 'Failed to copy address',
        isShow: true,
      });
    }
  };

  const actionTiles: ActionTileProps[] = [
    {
      title: 'Card Nickname',
      subTitle: changeNicknameBool.value ? (
        <Skeleton />
      ) : (
        currentCard?.cardName ?? defaultCardName
      ),
      onClick: () => setIsRenameMode(true),
      infoImg: CreditCardOutlined,
      actionImg: EditFilled,
      component: isRenameMode && (
        <RenameCard
          onSubmit={handleRenameCard}
          currentName={currentCard?.cardName}
          isLoading={renamePaymentCardIsLoading}
        />
      ),
      isHide: false,
    },
    {
      title: 'Linked Account',
      subTitle:
        (currentCard?.financialAccounts &&
          currentCard?.financialAccounts[0]?.name) ||
        '',
      onClick: () =>
        currentCard?.financialAccounts &&
        currentCard?.financialAccounts[0]?.id &&
        navigate(`/account/${currentCard?.financialAccounts[0]?.id}`),
      infoImg: AccountCircleOutlined,
      actionImg: ChevronRightGray,
      isHide: !linkedAccountPermission,
      isActionLoading: !currentCard?.financialAccounts,
    },
    {
      title: 'Authorized User',
      subTitle: paymentCardDetails?.attachedToSubProfile
        ? currentCard?.subProfile || currentCard?.cardholder
        : '',
      onClick: () => {},
      infoImg: VerifiedUserOutlined,
      isHide: !paymentCardDetails?.attachedToSubProfile,
    },
    {
      title: 'Billing Address',
      subTitle: paymentCardDetails?.address || '',
      onClick: () => handleAddressCopy(paymentCardDetails?.address || ''),
      infoImg: HomeOutlined,
      actionImg: CopyBlue,
      isHide: false,
    },
  ].filter((item) => !item.isHide);

  if (cardsIsLoading) {
    return (
      <>
        <Skeleton
          height={200}
          width={'100%'}
        />
        <Skeleton
          height={200}
          width={'100%'}
        />
      </>
    );
  }

  return (
    <ActionBlock>
      {actionTiles.map((item) => {
        return item.component ? (
          item.component
        ) : (
          <ActionTile
            key={item.title}
            {...item}
            isLoading={cardsIsLoading}
            isFullClickable={true}
          />
        );
      })}
    </ActionBlock>
  );
};

const ActionBlock = styled('div')`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100%;
    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      border: 1px solid ${theme.colors.borderLightGrey};
      border-radius: 4px;
      & > div {
        border: none;
        border-bottom: 1px solid ${theme.colors.borderLightGrey};
        border-radius: 0;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  `}
`;

export default ActionTiles;
