import { BusinessSignUpScreen, useSignUpBusiness } from '../provider';
import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { SignUpBusinessProgressBar } from '../progressBar';
import styles from './style.module.scss';
import { Button } from '../../../shared/ui/buttons';
import { Checkbox, FormControlLabel } from '@mui/material';
import cx from 'classnames';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { createArrByNumber } from '../../../shared/signUp/shared';

const MAX_ANOTHER_OWNERS = 4;

export const BusinessSignUpPrimaryOwnerInformation = () => {
  const { setScreen, contextForm, updateContextForm } = useSignUpBusiness();

  const handleChangeOwnerType = (
    isSame: boolean,
    anotherPersonsCount?: number
  ) => {
    updateContextForm({
      owners: {
        ...contextForm.owners,
        isSameAsPrimary: isSame,
        anotherPersonsCount: anotherPersonsCount
          ? anotherPersonsCount
          : contextForm.owners.anotherPersonsCount,
      },
    });
  };

  const handleSubmit = () => {
    setScreen(
      contextForm.owners.isSameAsPrimary
        ? BusinessSignUpScreen.confirm
        : BusinessSignUpScreen.anotherOwners
    );
  };

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Primary owner information
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Provide details for the primary owner.
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar
          curScreen={BusinessSignUpScreen.primaryOwner}
        />

        <div className={styles.formWrapper}>
          <div className={styles.ownersWrapper}>
            <div
              tabIndex={0}
              onClick={() => handleChangeOwnerType(true)}
              className={cx(
                styles.ownerType,
                contextForm.owners.isSameAsPrimary && styles.ownerTypeActive
              )}
            >
              <div className={styles.ownerTypeTitle}>
                Same as primary authorized person
              </div>
              <div className={styles.ownerTypeSubtitle}>
                Percentage ownership for Primary Authorized Person must be min.
                25%
              </div>
            </div>

            <div
              className={cx(
                styles.ownerType,
                !contextForm.owners.isSameAsPrimary && styles.ownerTypeActive
              )}
            >
              <div className={styles.ownerTypeTitle}>Another person</div>
              <div className={styles.additionals}>
                {createArrByNumber(MAX_ANOTHER_OWNERS).map((n) => (
                  <FormControlLabel
                    key={n}
                    label={`${n} additional owner`}
                    control={
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        checked={
                          !contextForm.owners.isSameAsPrimary &&
                          contextForm.owners.anotherPersonsCount === n
                        }
                        onChange={() => {
                          handleChangeOwnerType(false, n);
                        }}
                      />
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: styles.checkedCheck,
                }}
                checked={contextForm.owners.isTermsAccepted}
                onChange={(_: any, checked) => {
                  updateContextForm({
                    owners: {
                      ...contextForm.owners,
                      isTermsAccepted: checked,
                    },
                  });
                }}
                name='isTermsAccepted'
              />
            }
            label={
              <div>
                I have read and agree to these
                <br />
                <a
                  className={styles.link}
                  href='https://netevia.com/wp-content/themes/netevia/Netevia-Terms-and-Condiitons.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms & Conditions
                </a>
              </div>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                classes={{
                  checked: styles.checkedCheck,
                }}
                checked={contextForm.owners.isAgreementAccepted}
                onChange={(_: any, checked) => {
                  updateContextForm({
                    owners: {
                      ...contextForm.owners,
                      isAgreementAccepted: checked,
                    },
                  });
                }}
                name='isAgreementAccepted'
              />
            }
            label={
              <div>
                I have read and agree to the
                <br />
                <a
                  className={styles.link}
                  href='https://netevia.com/wp-content/themes/netevia/Netevia-Commercial-Cardholder-Agreement-Template-Debit-Card-Program.pdf'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Card Holder Agreement
                </a>
              </div>
            }
          />

          <SignUpFormsLayout.FormControls>
            <Button
              onClick={() =>
                setScreen(BusinessSignUpScreen.primaryAuthorizedPerson)
              }
              type='button'
              variant='text'
            >
              Back
            </Button>
            <Button
              type='button'
              onClick={handleSubmit}
              variant='contained'
              disabled={
                !contextForm.owners.isAgreementAccepted ||
                !contextForm.owners.isTermsAccepted
              }
            >
              Next
            </Button>
          </SignUpFormsLayout.FormControls>
        </div>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
