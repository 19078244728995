import { useExternalBanks } from "api/hooksApi/useExternalBanks";
import BankName from "../BankName";
import TableBlock from "shared/components/TableBlock";
import { Typography } from "shared/ui/typography";
import helper from "services/helper";

import styles from '../../style.module.scss'
import AddExternalBank from "../AddExternalBank";
import NoContent from "../NoContent";
import ButtonViewDetails from "../ButtonViewDetails";
import { useNavigateToExternal } from "pages/home/hooks";
import { AnimateWrapper } from "components/animate-wrapper";

const tabCells = () => [
  {
    label: "Bank account",
    name: "bankName",
    transform: (bankName: string) => 
      <BankName bankName={bankName} />
  },
  {
    label: "Available balance",
    name: "balance",
    transform: (balance: { value: number } | null) => {
      if (!balance) return null;

      return (
      <Typography>
        ${helper.moneyFormat(balance) || ''}
      </Typography>
    )},
  },
  {
    label: '',
    name: '',
    props: { align: 'right' },
    transform: (_, row) => {
      return <ButtonViewDetails bankName={row.bankName} />
    }
  }
]

export const ExternalBanksList = () => {
  const { externalBanks } = useExternalBanks();
  const { navigateToExternalBank } = useNavigateToExternal();

  return (
    <AnimateWrapper className="fade">
    <div className={styles.container}>
     <TableBlock
        rows={externalBanks}
        tabCells={tabCells()}
        title='External bank accounts'
        Action={AddExternalBank}
        NoContent={NoContent}
        onClick={navigateToExternalBank}
        isRowsClickable
        hasMobileVersion={false}
      />
    </div>
    </AnimateWrapper>
  )
}