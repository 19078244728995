import { BankIcon } from 'shared/ui/icons';
import { Typography } from '@mui/material';

import styles from './style.module.scss';

const NoContent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BankIcon />
    
        <Typography className={styles.title}>You don’t have any external bank accounts connected</Typography>
        <Typography className={styles.subTitle}>This section allows you to link your accounts from other banks, so you can view all your financial information in one place.</Typography>
      </div>
    </div>
  )
}

export default NoContent;