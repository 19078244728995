import {
  useDeleteFileMutation,
  useGetReceiptFileMutation,
  useUploadFileMutation,
} from "api/endpoints/transaction";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UploadReceipt from "shared/components/UploadReceipt";

export const UploadFile = () => {
  const [reseipt, setReseipt] = useState();
  const [uploadFile] = useUploadFileMutation();
  const [deliteFile] = useDeleteFileMutation();

  const [getReceiptFile, { isLoading: getReceiptFileIsLoading }] =
    useGetReceiptFileMutation();

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTransactionId = searchParams.get("transaction") || "";

  useEffect(() => {
    handleGetReceiptFile();
  }, [currentTransactionId]);
  const handleGetReceiptFile = async () => {
    const handleConvertStringToFile = (base64String, filename) => {
      const binaryString = atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: "application/png" });
      const file = new File([blob], filename, { type: "application/png" });
      //@ts-ignore
      setReseipt(file);
    };
    try {
      const res = await getReceiptFile({
        transactionId: currentTransactionId,
      }).unwrap();
    } catch (error: any) {
      if (error.originalStatus === 200) {
        handleConvertStringToFile(error.data, "Reseipt");
      } else {
        setReseipt(undefined);
      }
    }
  };

  const handleUploadFile = async (file) => {
    setReseipt(file);
    if (!file) return;

    let formData = new FormData();
    formData.append("TransactionId", currentTransactionId);
    formData.append("FileType", "TransactionIcon");
    formData.append("File", file);

    await uploadFile(formData);
  };
  const handleDeliteFile = async () => {
    let formData = new FormData();
    formData.append("transactionId", currentTransactionId);
    const data = JSON.stringify({
      transactionId: currentTransactionId,
    });
    await deliteFile({
      transactionId: currentTransactionId,
    });
  };
  return (
    <UploadReceipt
      file={reseipt}
      onUpload={handleUploadFile}
      onDelite={handleDeliteFile}
    />
  );
};
