import { List, Divider } from "@mui/material";
import HistoryItem from "../historyItem";
import styles from "./styles.module.scss";
import { useRewards } from "pages/reward/provider/useRewards";
import moment from "moment";
import helper from "services/helper";
import { Skeleton } from "components/skeleton";
import { AnimateWrapper } from "components/animate-wrapper";

const HistoryList = () => {
  const { isDataLoading, isFetchingHistory, rewardsHistory } = useRewards();

  const isLoading = [isDataLoading || isFetchingHistory].some(Boolean);

  const renderItems = () => {
    return rewardsHistory.map((el, index) => (
      <div key={index} className={styles.itemWrapper}>
        <HistoryItem
          date={moment(el.publishDate).format("MM.DD.YYYY, hh:mm A")}
          amount={helper.moneyFormat(el.amount)}
          points={el.points}
          type={el.type}
          typeName={el.typeName}
          subjectName={el.subjectName}
        />
        {index < rewardsHistory?.length - 1 && (
          <Divider className={styles.itemWrapper_divider} />
        )}
      </div>
    ));
  };

  const renderContent = () => {
    if (!!rewardsHistory?.length) {
      return (
        <AnimateWrapper className="fade">
          <List disablePadding className={styles.listWrapper}>
            {renderItems()}
          </List>
        </AnimateWrapper>
      );
    } else {
      return <div>You don`t have points yet.</div>;
    }
  };

  return isLoading ? <Skeleton width="100%" height={125} /> : renderContent();
};

export default HistoryList;
