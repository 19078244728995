import { ListItemButton } from "@mui/material";
import styles from "./style.module.scss";
import ChevronRight from "assets/svg/chevron-right-gray.svg";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { SupportModeEnum } from "pages/support/types/enums";

const OpenHistory = () => {
  const { t } = useTranslationProvider();
  const [, setSearchParams] = useSearchParams();

  const handleNavigate = () => {
    setSearchParams({
      pageMode: SupportModeEnum.History,
      title: "Requests history",
    });
  };

  return (
    <ListItemButton onClick={handleNavigate} className={styles.wrapper}>
      <div>
        <span className={styles.wrapper_title}>
          {t("Support.Request History", {
            defaultValue: "Request History",
          })}
        </span>
        <p className={cx(styles.wrapper_title, styles.wrapper_subTitle)}>
          {t("Support.Review your past", {
            defaultValue: "Review your past support requests and their status",
          })}
        </p>
      </div>
      <img src={ChevronRight} />
    </ListItemButton>
  );
};
export default OpenHistory;
