import React, { FC } from "react";

import MoonLoader from "react-spinners/MoonLoader";
import { Divider } from "@mui/material";
import SendIcon from "assets/svg/send-message.svg";
import PaperClip from "assets/svg/paperclip-blue.svg";
import styles from "./style.module.scss";

interface InputChatProps {
  message: string;
  setMessage: (string) => void;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSendMessage: () => Promise<void>;
  isLoadingMessage: boolean;
}
const InputChat: FC<InputChatProps> = ({
  message,
  setMessage,
  handleFileChange,
  handleSendMessage,
  isLoadingMessage,
}) => {
  return (
    <div className={styles.wrapper}>
      <Divider className={styles.divider} />
      <div className={styles.inputContainer}>
        <label htmlFor="fileInput" className={styles.attachedImage}>
          <img src={PaperClip} className={styles.inputContainer_send} />
          <input
            id="fileInput"
            type="file"
            multiple
            onChange={handleFileChange}
            className={styles.attachedInput}
          />
        </label>

        <input
          type="text"
          placeholder="Message"
          className={styles.chatInput}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        {isLoadingMessage ? (
          <MoonLoader size={25} />
        ) : (
          <img
            src={SendIcon}
            className={styles.inputContainer_send}
            onClick={handleSendMessage}
          />
        )}
      </div>
    </div>
  );
};

export default InputChat;
