import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { DetailsTransfer } from 'entities/transfers/ui/detailsTransfer';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { TransitionAnimation } from 'shared/ui/animations';
import { useAppSelector, useAppDispatch } from 'shared/models';
import moment from 'moment';
import {
  selectFromFinancialAccount,
  selectToPayee,
  selectAmount,
  selectDateTransfer,
  selectFrequency,
  clearDataAch,
  selectTransferMemo,
} from '../../model';
import helper from 'services/helper';

export const MakeTransferSuccess = () => {
  const dispatch = useAppDispatch();
  const { close, goNext } = useDrawerStepperContext();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount);
  const toPayee = useAppSelector(selectToPayee);
  const amount = useAppSelector(selectAmount);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const frequency = useAppSelector(selectFrequency);
  const memo = useAppSelector(selectTransferMemo);

  const handleClick = () => {
    dispatch(clearDataAch());
    goNext('0');
    close();
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsTransfer
          title='Transfer successfully completed'
          amount={`$${helper.moneyFormat(amount)}`}
          accountFrom={fromFinancialAccount}
          accountTo={toPayee}
          date={moment(dateTransfer).format('MM/DD/YYYY')}
          frequency={frequency}
          memo={memo}
        />
        <Button
          variant='contained'
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  );
};
