import { Skeleton } from "@mui/material";
import { PandingTransactions } from "./PendingTransactions";
import { RecentTransactions } from "./RecentTransactions";
import { useTransactions } from "../provider";

export const AccountTransactions = () => {
  const { isFetchingTransactions } = useTransactions();
  
  if (isFetchingTransactions) {
    return <Skeleton height={400} />
  }
  
  return (
    <>
      <PandingTransactions />
      <RecentTransactions />
    </>
  )
};