import { useTranslationProvider } from 'providers/translation/translation.provider';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './style.module.scss';

interface ConnectedAccountRequestAccessProsp {
    onCancel: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    valueInput: string;
    onSumbit: () => Promise<void>;
    onSumbitLoading: boolean;
    isError?: any;
}

export const ConnectedAccountRequestAccess = (props: ConnectedAccountRequestAccessProsp) => {
    const {
        onCancel,
        onChange,
        valueInput,
        onSumbit,
        onSumbitLoading,
        isError,
      } = props;

    const { t } = useTranslationProvider();
    const handleCancel = () => {
      onCancel();
    };

    return (
        <TransitionAnimation>
        <div className={styles.container}>
            <Typography className={styles.container_subTitle}>
              {t('Settings.Request access to an account by submitting a request', {
                defaultValue: 'Request access to an account by submitting a request',
              })}
              .
            </Typography>
            <TextInput
                fullWidth
                autoComplete='off'
                value={valueInput}
                placeholder={t('Settings.Enter username', {
                    defaultValue: 'Enter username',
                })}
                onChange={onChange}
                error={isError?.data?.title}
            />
            <div className={styles.container_box_buttons}>
              <Button
                onClick={onSumbit}
                loading={onSumbitLoading}
                variant='contained'
                className={styles.button}
                disabled={!valueInput}
              >
                  {t('Settings.Send Request', {
                    defaultValue: 'Send Request',
                  })}
              </Button>
              <Button
                variant={'text'}
                onClick={handleCancel}
                className={styles.btnCancel}
              >
                CANCEL
              </Button>
            </div>
      </div>
    </TransitionAnimation>
    )
}