import { useExternalPayees } from 'api/hooksApi/useExternalPayees'
import styles from './styles.module.scss'
import { Button } from 'shared/ui/buttons'
import { TransitionAnimation } from 'shared/ui/animations'
import { useSnackBar } from 'providers/snackBar.provider'
import helper from 'services/helper'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useDialog } from 'providers/dialogController.provider'
import { useAppSelector } from 'shared/models'
import {
  DetailsPayee,
  filteredScheduleTransferByType,
  ScheduleTransferType,
} from 'entities/transfers'
import { useDeleteExternalPayeeMutation } from 'api/endpoints/fundsMovement'
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer'
import { selectExternalPayeeId } from '../../model'

export const ManagePayeeDetail = () => {
  const { setSnackBar } = useSnackBar()
  const { goNext } = useDrawerStepperContext()
  const dialog = useDialog()
  const { externalPayees } = useExternalPayees()
  const { data: scheduledTransfers, refetch: refetchGetScheduledTransfer } =
    useGetScheduledTransferQuery()

  const selectPayee = useAppSelector(selectExternalPayeeId)

  const [deleteExternalPayee, { isLoading: isLoadingDeleteExternalPayee }] =
    useDeleteExternalPayeeMutation()

  const selectedPayee = externalPayees?.find(
    (payee) => payee.id === selectPayee
  )

  const outcommingAchTrasfers = filteredScheduleTransferByType(
    scheduledTransfers ?? [],
    ScheduleTransferType.OutcommingAchTrasfer
  )

  const hasSheduleTransferWithPayee = outcommingAchTrasfers.some(
    (transfer) => transfer.toFinancialAccountId === selectedPayee?.id
  )

  const dialogTextWithOutPayee = `Are you sure you want to delete ${selectedPayee?.name} from your payee list?`
  const dialogTextWithPayee =
    'You have scheduled transfers for this payee. Do you want to remove the payee and all associated scheduled and recurring transfers?'

  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Delete
    </Button>
  )
  const renderSlotCancelButton = <Button variant='text'>Cancel</Button>

  const handeleDeletePayee = async () => {
    dialog.show({
      dialogTitle: 'Delete payee',
      dialogText: hasSheduleTransferWithPayee
        ? dialogTextWithPayee
        : dialogTextWithOutPayee,
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: renderSlotCancelButton,
      async onApply() {
        try {
          await deleteExternalPayee({
            financialAccountId: selectPayee,
            force: true,
          }).unwrap()

          setSnackBar({
            type: 'success',
            message: 'Payee deleted successfully.',
            isShow: true,
          })
          refetchGetScheduledTransfer()
          goNext('8')
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          })
        }
      },
    })
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsPayee
          type={selectedPayee?.typename || ''}
          name={selectedPayee?.name || ''}
          accountNumber={`****${selectedPayee?.last4}`}
          routingNumber={selectedPayee?.routingNumber}
          typeAccount={`${selectedPayee?.type} account`}
        />
        <Button
          onClick={handeleDeletePayee}
          variant='outlined'
          className={styles.btn}
          loading={isLoadingDeleteExternalPayee}
        >
          Delete payee
        </Button>
      </div>
    </TransitionAnimation>
  )
}
