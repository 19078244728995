import { useState, ChangeEvent, useRef } from 'react';
import styles from './style.module.scss';
import { useCreateTicketControllerMutation } from 'api/endpoints/support';
import { convertFileToBase64 } from 'shared/utils/convertFileToBase64';
import { useUploadTicketAttachmentControllerMutation } from 'api/endpoints/tickets';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { AnimateWrapper } from 'components/animate-wrapper';
import { useConfirmDialog } from 'providers/confirm.provider';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { Button } from 'shared/ui/buttons';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import helper from 'services/helper';
import { Typography } from 'shared/ui/typography';
import { Skeleton } from 'components/skeleton';
import { FormCloseAccount } from './formCloseAccount';
import { v4 as uuidv4 } from 'uuid';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useSnackBar } from 'providers/snackBar.provider';
import { useSearchParams } from 'react-router-dom';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
export interface FileWithID {
  file: File;
  id: string;
}

export enum ReasonEnum {
  AccountWasCompromised = 'Account was compromised',
  NoNeed = 'No need',
  Other = 'Other',
}

const CloseAccount = () => {
  const { t } = useTranslationProvider();
  const { close } = useDrawerStepperContext();
  const confirm = useConfirmDialog();
  const { setSnackBar } = useSnackBar();

  const isLoadingBool = useBoolean();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { data: holder } = useHolderV2ControllerQuery();

  const [createTicketMutation] = useCreateTicketControllerMutation();
  const [uploadTicketFiles] = useUploadTicketAttachmentControllerMutation();

  const [selectReason, setSelectReason] = useState<string>('');
  const [selectAccountId, setSelectAccountId] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [files, setFiles] = useState<FileWithID[]>([]);
  const validReasonOther = selectReason === ReasonEnum.Other && !comment;
  const disabled = !selectReason || validReasonOther || !selectAccountId;

  const isFileAttached = !!files.length;

  const optionsReason = [
    {
      id: ReasonEnum.AccountWasCompromised,
      content: t('CloseFinancialAcc.Account was compromised', {
        defaultValue: 'Account was compromised',
      }),
      value: ReasonEnum.AccountWasCompromised,
    },
    {
      id: ReasonEnum.NoNeed,
      content: t('CloseFinancialAcc.No need', {
        defaultValue: 'No need',
      }),
      value: ReasonEnum.NoNeed,
    },
    {
      id: ReasonEnum.Other,
      content: t('CloseFinancialAcc.Other', {
        defaultValue: 'Other',
      }),
      value: ReasonEnum.Other,
    },
  ];

  const optionsAccount = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const confirmModal = () => {
    confirm.show({
      applyButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      dialogTitle: 'Are you sure you want to close this account?',
      cancelButtonType: 'text',
      cancelError: true,
      typeNew: true,
      async onApply() {
        await onSubmit();
      },
    });
  };

  const onSubmit = async () => {
    try {
      isLoadingBool.setTrue();
      const { ticketId } = await createTicketMutation({
        topicId: 6, //-----'Topic - Account closure',
        text: `${t('CloseFinancialAcc.Business name', {
          defaultValue: 'Business name',
        })}: ${holder?.businessAccountHolder?.legalBusinessName}${`\n`}${t(
          'CloseFinancialAcc.Financial account ID',
          {
            defaultValue: 'Financial account ID',
          }
        )}: ${selectAccountId}${`\n`}${t(
          'CloseFinancialAcc.Reason of closure',
          {
            defaultValue: 'Reason of closure',
          }
        )}: ${comment ? comment : selectReason}`,
      }).unwrap();
      if (files?.length) {
        const allFiles = files ? files.filter((file) => file.file.type) : [];
        await Promise.all([
          allFiles.map(async (file, i) => {
            const fileBase64 = (await convertFileToBase64(file.file)) as string;

            const formattedFileBase64 = fileBase64?.substring(
              fileBase64.indexOf(',') + 1
            );

            await uploadTicketFiles({
              ticketId,
              fileName: file.file.name,
              contentType: file.file.type,
              fileBase64: formattedFileBase64,
            });
          }),
        ]);
      }
      isLoadingBool.setFalse();
      setComment('');
      setFiles([]);
      setSnackBar({
        type: 'success',
        message:
          'Account closure request received. Processing may take up to 3 business days.',
        isShow: true,
      });
      close()
    } catch (e: any) {
      isLoadingBool.setFalse();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const dataFiles = files ? Array.from(files) : [];

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
        .map((file) => {
          if (file.size < 20 * 1000 * 1000) {
            return {
              file,
              id: uuidv4(),
            } as FileWithID;
          } else {
            setSnackBar({
              type: 'error',
              message: `File ${file.name} exceeds the maximum size of 20MB`,
              isShow: true,
            });
            return null;
          }
        })
        .filter((item): item is FileWithID => item !== null);

      setFiles((prevFiles) => [...prevFiles, ...files]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDeleteFile = (id: string) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);
  };

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <AnimateWrapper className='fade'>
      <div className={styles.wrapper}>
        <Typography className={styles.wrapper_label}>
          {t('reasonСlosingYourAccountTitle', {
            defaultValue:
              'Please help us improve. Select a reason for closing your account.',
          })}
        </Typography>
        <FormCloseAccount
          isFileAttached={isFileAttached}
          handleFileChange={handleFileChange}
          handleDeleteFile={handleDeleteFile}
          dataFiles={dataFiles}
          selectAccountId={selectAccountId}
          setSelectAccountId={setSelectAccountId}
          selectReason={selectReason}
          setSelectReason={setSelectReason}
          comment={comment}
          setComment={setComment}
          optionsAccount={optionsAccount}
          optionsReason={optionsReason}
          fileInputRef={fileInputRef}
        />
        <div className={styles.boxButtons}>
          <Button
            variant='contained'
            onClick={confirmModal}
            loading={isLoadingBool.value}
            disabled={disabled}
          >
            {t('CloseFinancialAcc.Close account', {
              defaultValue: 'Close account',
            })}
          </Button>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default CloseAccount;
