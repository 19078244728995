import { useNavigate, useLocation } from "react-router-dom";
import { AnimateWrapper } from "components/animate-wrapper";
import {
  css,
  styled,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clockIcon from "assets/svg/clock-verify-inprocess.svg";
import LineStepper from "./components/lineStepper";

const steps = [
  "Get started",
  "Application submitted",
  "Under review",
  "Account ready",
];

const VerificationInProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeStep = location.state?.activeStep;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  return (
    <AnimateWrapper className="fade">
      <Container>
        <Title>Sign up complete!</Title>
        <SubTitle>
          We need some time to check the details. Application review usually
          takes approximately 1-2 business days. You will get a notification to
          your email address, when it’s done. In case of any questions, feel
          free to contact us.
        </SubTitle>
        <LineStepper steps={steps} activeStep={activeStep} />
        <img src={clockIcon} alt="Clock" />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#023047",
            width: matches ? "188px" : "100%",
            marginBottom: "40px",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          Log in
        </Button>
      </Container>
    </AnimateWrapper>
  );
};

export default VerificationInProcess;
const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    & > img {
      width: 261px;
      height: 244px;
      margin: 24px 0px;
    }
    ${theme.breakpoints.down("sm")} {
      width: 100%;
      gap: 16px;
      & > img {
        width: 195px;
        height: 185px;
      }
    }
  `}
`;
const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 34px;
    font-weight: 400;
    line-height: 123.5%;
    letter-spacing: 0.25px;
    ${theme.breakpoints.down("sm")} {
      font-size: 24px;
      line-height: 133.4%;
    }
  `}
`;

const SubTitle = styled(Typography)`
  ${({ theme }) => css`
    color: rgba(0, 0, 0, 0.6);
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
    text-align: center;
    ${theme.breakpoints.down("sm")} {
      font-size: 14px;
      line-height: 143%;
      letter-spacing: 0.17px;
    }
  `}
`;
