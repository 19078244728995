import { useMemo } from "react";
import { useSubUserMEControllerQuery } from "../endpoints/subProfiles";
import { states } from "constants/data";
interface SubUserInfoReturnType {
  subUserInfo: any;
  subUserInfoIsLoading: boolean;
}
export const useSubUserInfo = (isSubUser): SubUserInfoReturnType => {
  const { data, isFetching } = useSubUserMEControllerQuery(
    {},
    { skip: !isSubUser }
  );

  const value = useMemo<any>(() => {
    if (!data) {
      return {};
    }

    const givenName = data.givenName;
    const familyName = data.familyName;
    const fullName = `${givenName} ${familyName}`;
    const id = data.id;
    const phoneNumbers = data.phoneNumber;
    const email = data.email;
    const streetAdress = data.streetAddress;
    const locality = data.city;
    const postalCode = data.postalCode;
    const region = states.find((state) => state.value === data.state)?.label;
    const countryCodeAlpha3 = data.countryCodeAlpha3;
    const fullAdress = {
      streetAddress: streetAdress,
      postalCode: postalCode,
      region: region,
      locality: locality,
    };
    return {
      givenName,
      familyName,
      fullName,
      id,
      phoneNumbers,
      email,
      streetAdress,
      locality,
      postalCode,
      region,
      countryCodeAlpha3,
      fullAdress,
    };
  }, [data, isSubUser]);

  return {
    subUserInfo: value,
    subUserInfoIsLoading: isFetching,
  };
};
