import { useSubUserMEControllerQuery, useUpdateSubUserControllerMutation, useUpdateSubUserPhoneNumberControllerMutation, useUpdateSubUserAddressControllerMutation } from "api/endpoints/subProfiles";
import { useCallback } from "react";
import { EditType } from "../accountSettings/types";
import { User } from "../personalInformation/types";
import { useChangePhoneOtpMutation, useChangeEmailOtpMutation, useChangeAddressOtpMutation } from "api/endpoints/account";
import { ShortMessageType } from "enums";
import { useMfa } from "providers/mfa/mfa";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";

export const useUpdateSubUser = () => {
  const { data: subUserInfo } = useSubUserMEControllerQuery({});
  const [updateSubUserMutation, { isLoading: updateSubUserMutationIsLoading }] =
    useUpdateSubUserControllerMutation();
	const [updateSubUserPhoneNumberMutation,
      { isLoading: updateSubUserPhoneNumberIsLoading },
    ] = useUpdateSubUserPhoneNumberControllerMutation();
   
  const [
    updateSubUserAddressMutation,
    { isLoading: updateSubUserAddressMutationIsLoading },
  ] = useUpdateSubUserAddressControllerMutation();
  
  const updateSubUser = useCallback(async (newUser: Partial<User> , mode: EditType) => {
    switch (mode) {
      case EditType.Email:
        await updateSubUserMutation({
          email: newUser.email ?? '',
          givenName: subUserInfo.givenName,
          familyName: subUserInfo.familyName,
          department: subUserInfo.department,
          subProfileId: subUserInfo.id,
        }).unwrap();
        break;
      case EditType.Phone:
        await updateSubUserPhoneNumberMutation({
          phoneNumber: newUser.phoneNumber ?? '',
          subProfileId: subUserInfo.id,
          phoneNumberCountryCode: subUserInfo.phoneNumberCountryCode,
        }).unwrap();
        break;
      case EditType.Address:
        await updateSubUserAddressMutation({
          subProfileId: subUserInfo?.id,
          countryCodeAlpha3: subUserInfo?.countryCodeAlpha3,
          updatePaymentCardBillingAddress: false,
          locality: newUser.city ?? '',
          streetAddress: newUser.streetAddress ?? '',
          postalCode: newUser.postalCode ?? '',
          state: Number(newUser.region?.value),
          extendedAddress: newUser.extendedAddress ?? '',
        }).unwrap();
        break;
    }
  }, []);
  
  const isLoading = updateSubUserPhoneNumberIsLoading || updateSubUserAddressMutationIsLoading || updateSubUserMutationIsLoading;
  
  return { updateSubUser, isLoading }
}

export const useUpdateConsumer = () => {
  const mfa = useMfa();
  const { setSnackBar } = useSnackBar();
  const [changeEmailOtpMutation, 
    { isLoading: isLoadingChangeEmailOtpMutation },
  ] = useChangeEmailOtpMutation();
	const [changePhoneOtpMutation, 
    { isLoading: isLoadingChangePhoneOtpMutation },
  ] = useChangePhoneOtpMutation();
	const [changeAddressOtpMutation, 
    { isLoading: isLoadingChangeAddressOtpMutation },
  ] = useChangeAddressOtpMutation();


  const showMfaDialog = (mutation, data, message) => {
    mfa.show({
      title: 'Confirm Request',
      shortMessageType: ShortMessageType.ContactInfoUpdateRequest,
      isOnlySmsFlow: true,
      async onCodeSubmit(secureOperationType, code) {
        try{
          await mutation({
            ...data,
            oneTimeCode: code,
            secureOperationType
          }).unwrap();
          setSnackBar({
            type: "success",
            message,
            isShow: true,
          });
          mfa.close();
        } catch (e: any) {
          setSnackBar({
            type: "error",
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      }
    });
  };

  const updateConsumer = useCallback(async (newUser: Partial<User> , mode: EditType) => {
      switch (mode) {
        case EditType.Email:
          showMfaDialog(changeEmailOtpMutation, { email: newUser.email ?? '' }, "Email is updated");
          break;

        case EditType.Phone:
          showMfaDialog(changePhoneOtpMutation, { phone: newUser.phoneNumber ?? '' }, "Phone is updated");
          break;	

        case EditType.Address:
          const addressData = {
            streetAddress: newUser.streetAddress ?? '',
            extendedAddress: newUser.extendedAddress ?? '',
            postalCode: newUser.postalCode ?? '',
            state: Number(newUser.region?.value),
            city: newUser.city ?? ''
          };
          showMfaDialog(changeAddressOtpMutation, addressData, "Address is updated");
          break;
        }
  }, []);

  const isLoading = isLoadingChangeEmailOtpMutation || isLoadingChangePhoneOtpMutation || isLoadingChangeAddressOtpMutation;

  return { updateConsumer, isLoading };
}