import { Select, SelectItem,TextInput } from 'shared/ui/inputs';
import { Divider } from '@mui/material';
import styles from './styles.module.scss';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import { FormikProps } from 'formik';
interface FormValues {
  from: string;
  to: string;
  amount: string;
  memo: string;
}
interface ToMyOwnAccountFormProps {
  formik: FormikProps<FormValues>;
  optionsFromAcc: SelectItem[];
  optionsToAcc: SelectItem[];
}

export const ToMyOwnAccountForm = (props: ToMyOwnAccountFormProps) => {
  const { formik, optionsFromAcc, optionsToAcc } = props;

  const disabedBtn =
    !formik.isValid ||
    !formik.values.from ||
    !formik.values.to ||
    !formik.values.amount;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.form}
    >
      <Select
        label='From'
        options={optionsFromAcc}
        {...formik.getFieldProps('from')}
        error={formik.touched.from && formik.errors.from}
      />
      <Select
        label='To'
        options={optionsToAcc}
        {...formik.getFieldProps('to')}
        error={formik.touched.to && formik.errors.to}
      />

      <Divider />
      <CurrencyInput2
        fullWidth
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        name='amount'
        value={formik.values.amount}
        onChange={(value) => {
          formik.setFieldTouched('amount', true);
          formik.setFieldValue('amount', value);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && formik.errors.amount}
        getCaretBoundary={() => [false]}
      />
       <TextInput
        label='Memo'
        autoComplete='off'
        error={formik.touched.memo && formik.errors.memo}
        {...formik.getFieldProps('memo')}
      />
      <Button
        className={styles.btn}
        type='submit'
        variant='contained'
        disabled={disabedBtn}
      >
        Continue
      </Button>
    </form>
  );
};

