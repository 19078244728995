import { useState } from 'react'
import { Typography, css, styled } from '@mui/material'
import openIcon from 'assets/svg/open-blue.svg'
import PopupInstructions from '../popupInstructions'

const AuntificatorInstructions = () => {
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  return (
    <Wrapper>
      <Title>Need help setting up your authenticator?</Title>
      <Box onClick={() => setIsShowPopup(true)}>
        <LabelLink>Click here for instructions</LabelLink>
        <img src={openIcon} />
      </Box>
      {isShowPopup && (
        <PopupInstructions onClose={() => setIsShowPopup(false)} />
      )}
    </Wrapper>
  )
}

export default AuntificatorInstructions

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: rgba(24, 122, 201, 0.04);
  gap: 8px;
  padding: 8px;
`
const Title = styled(Typography)`
  font-family: 'Noto Sans';
  font-size: 20px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.15px;
`
const Box = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  & > img {
    width: 20px;
    height: 20px;
  }
`

const LabelLink = styled(Typography)`
  color: #187ac9;
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`
