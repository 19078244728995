import { ChangeEvent, RefObject } from 'react';
import { TextInput } from 'shared/ui/inputs';
import { Select, SelectItem } from 'shared/ui/inputs';
import styles from './style.module.scss';
import { useMediaQuery } from '@mui/material';
import AttachIcon from 'assets/svg/paperclip-color.svg';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import closeIcon from 'assets/svg/close-gray.svg';
import { FileWithID } from '..';
import { Typography } from 'shared/ui/typography';
const ICON_SIZE = 24;

interface FormCloseAccountProps {
  isFileAttached: boolean;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: (id: string) => void;
  dataFiles: FileWithID[] | [];
  selectAccountId: string;
  setSelectAccountId: (selectAccountId: string) => void;
  selectReason: string;
  setSelectReason: (selectReason: string) => void;
  comment: string;
  setComment: (comment: string) => void;
  optionsAccount: SelectItem[];
  optionsReason: SelectItem[];
  fileInputRef: RefObject<HTMLInputElement>;
}

export const FormCloseAccount = (props: FormCloseAccountProps) => {
  const {
    isFileAttached,
    handleFileChange,
    handleDeleteFile,
    dataFiles,
    selectAccountId,
    setSelectAccountId,
    selectReason,
    setSelectReason,
    comment,
    setComment,
    optionsAccount,
    optionsReason,
    fileInputRef,
  } = props;
  const { t } = useTranslationProvider();

  const DEFAULT_LABEL = t('common.Attach documents', {
    defaultValue: 'Attach documents (optional)',
  });

  const renderAttachDocument = () => {
    return (
      <div className={styles.attachDocument}>
        <label
          htmlFor='fileInput'
          className={styles.attachDocument_image}
        >
          <div className={styles.boxLabeUpload}>
            <img
              src={AttachIcon}
              alt='Attach document'
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
            <input
              id='fileInput'
              ref={fileInputRef}
              type='file'
              accept='image/jpeg, image/jpg, image/png, application/pdf'
              multiple
              onChange={handleFileChange}
              className={styles.attachDocument_input}
            />
            <Typography className={styles.boxLabeUploadText}>
              {DEFAULT_LABEL}
            </Typography>
          </div>
        </label>
        <label className={styles.attachDocument_text}>
          {!!dataFiles.length &&
            dataFiles.map((file) => (
              <div
                key={file.id}
                className={styles.boxFiles}
              >
                <p>{file.file.name}</p>
                <img
                  src={closeIcon}
                  alt='Attach document'
                  className={styles.attachDocument_image}
                  onClick={() => handleDeleteFile(file.id)}
                />
              </div>
            ))}
        </label>
      </div>
    );
  };
  return (
    <div className={styles.form}>
      <Select
        value={selectAccountId}
        label='Select account'
        onChange={(event) => setSelectAccountId(event.target.value)}
        options={optionsAccount}
      />
      <Select
        value={selectReason}
        label={t('reasonСlosingYourAccountPlaceholder', {
          defaultValue: 'Select reason for closure',
        })}
        onChange={(event) => setSelectReason(event.target.value)}
        options={optionsReason}
      />
      {selectReason === 'Other' && (
        <div>
          <TextInput
            fullWidth
            multiline
            rows={5}
            placeholder={t('common.Write a comment', {
              defaultValue: 'Write a comment(optional)',
            })}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            inputProps={{ maxLength: 520 }}
            variant='outlined'
          />
          {renderAttachDocument()}
        </div>
      )}
    </div>
  );
};
