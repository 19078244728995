import {
  LoadingButton as LoadingButtonMUI,
  LoadingButtonProps as LoadingButtonPropsMUI,
} from '@mui/lab'
import styles from './styles.module.scss'
import cn from 'classnames'

export const ButtonTab = (props: LoadingButtonPropsMUI) => {
  const { children, className, ...rest } = props

  return (
    <LoadingButtonMUI
      className={cn(styles.btnTab, className)}
      {...rest}
    >
      {children}
    </LoadingButtonMUI>
  )
}
