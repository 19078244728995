import { CodeItem } from "../..";
import { ForwardRefRenderFunction } from "react";
import styles from "./style.module.scss";

interface MultiFactorInput {
  onChange: (number: CodeItem, index: number) => void;
  value: CodeItem;
  index: number;
  inputRef: React.Ref<HTMLInputElement>;
}

const MultiFactorInput: ForwardRefRenderFunction<
  HTMLInputElement,
  MultiFactorInput
> = ({ onChange, index, value, inputRef }) => {
  const handleChange = (event) => {
    if (event.code !== "Backspace" && event.target.value)
      onChange(event.target.value, index);
  };
  const handleKeyUp = (event) => {
    if (event.code === "Backspace") onChange("", index);
  };

  return (
    <input
      ref={inputRef}
      className={styles.input}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      maxLength={1}
    />
  );
};

export default MultiFactorInput;
