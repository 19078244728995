import { useEffect } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { ListButton } from 'shared/ui/lists';
import { Button } from 'shared/ui/buttons';
import { Switch } from 'shared/ui/inputs';
import { ChevronRightIcon } from 'shared/ui/icons';
import { StoreIcon } from 'shared/ui/icons';
import { LocationIcon } from 'shared/ui/icons';
import { useBoolean } from 'shared/hooks/useBoolean';
import { Collapse } from '@mui/material';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Skeleton } from 'components/skeleton';
import { useDialog } from 'providers/dialogController.provider';
import { useSnackBar } from 'providers/snackBar.provider';
import { useDetachAllRulesControllerMutation } from 'api/endpoints/spendRules';
import { useCardInformation } from 'pages/cardManage/provider';
import { useCardLimits } from 'api/hooksApi/useCardLimits';
import { FormHelperText } from '@mui/material';
import helper from 'services/helper';
import styles from './styles.module.scss';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';

interface SetUpLimitsProps {
  isLoading: boolean;
  amountLimit: string;
  setAmountLimit: (values: string) => void;
  amountLimitMonthlyData: string;
  setAmountLimitMonthlyData: (values: string) => void;
  amountLimitDailyData: string;
  setAmountLimitDailyData: (values: string) => void;
  onSubmit: () => Promise<void>;
  isLoadingonSubmit: boolean;
  onSumbitDiabled: boolean;
  allowanceForChanges: boolean;
}

export const SetUpLimits = (props: SetUpLimitsProps) => {
  const {
    isLoading,
    amountLimit,
    setAmountLimit,
    amountLimitMonthlyData,
    setAmountLimitMonthlyData,
    amountLimitDailyData,
    setAmountLimitDailyData,
    onSubmit,
    isLoadingonSubmit,
    onSumbitDiabled,
    allowanceForChanges,
  } = props;

  const { goNext } = useDrawerStepperContext();

  const dialog = useDialog();
  const { setSnackBar } = useSnackBar();
  const { currentCard } = useCardInformation();
  const { cardLimits, cardLimitsRefetch } = useCardLimits(currentCard?.id);

  const [detachAllRulesMutation, { isLoading: isLoadingDetachAllRules }] =
    useDetachAllRulesControllerMutation();

  const transactionBool = useBoolean();
  const calendarMonthBool = useBoolean();
  const dailyBool = useBoolean();
  const errorDailyBool = useBoolean();

  useEffect(() => {
    transactionBool.setValue(!!cardLimits.amountLimit);
    calendarMonthBool.setValue(!!cardLimits.amountLimitMonthlyData);
    dailyBool.setValue(!!cardLimits.amountLimitDailyData);
  }, [cardLimits]);

  const handeleRemoveAllLimits = async () => {
    dialog.show({
      dialogTitle: 'Remove all limits',
      dialogText: 'Are you sure you want to remove all limits for this card?',
      slotApplyButton: <Button variant='text'>Confirm</Button>,
      slotCancelButton: (
        <Button
          variant='text'
          color='error'
        >
          Cancel
        </Button>
      ),
      async onApply() {
        try {
          await detachAllRulesMutation({
            PaymentCardId: currentCard?.id,
          }).unwrap();
          cardLimitsRefetch();
          setSnackBar({
            type: 'success',
            message: 'Card limits successfully removed.',
            isShow: true,
          });
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  const onChangeDailyLimit = (value) => {
    const isError = value > 80000;
    errorDailyBool.setValue(isError);
    setAmountLimitDailyData(value);
  };

  const handleOnChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    stateSwitch: UseBooleanReturn,
    setInputValue: (values: string) => void
  ) => {
    stateSwitch.setValue(event.target.checked);
    if (!event.target.checked) {
      setInputValue('0');
    }
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <ListButton className={styles.listBtn}>
          <Button
            startIcon={<StoreIcon className={styles.icon} />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('1')}
          >
            Merchant category
          </Button>

          <Button
            startIcon={<LocationIcon className={styles.icon} />}
            endIcon={<ChevronRightIcon />}
            onClick={() => goNext('2')}
          >
            Country
          </Button>
        </ListButton>
        <div>
          <Switch
            label='Max amount for transaction'
            checked={transactionBool.value}
            onChange={(e) =>
              handleOnChangeSwitch(e, transactionBool, setAmountLimit)
            }
            LabelProps={{ className: styles.switch }}
          />
          <Collapse
            timeout={90}
            in={transactionBool.value}
          >
            <CurrencyInput2
              fullWidth
              variant='outlined'
              label='Amount'
              placeholder='$0.00'
              value={amountLimit}
              onChange={(value) => setAmountLimit(value)}
              onBlur={() => {}}
              getCaretBoundary={() => [false]}
              disabled={!allowanceForChanges}
            />
          </Collapse>
        </div>
        <div>
          <Switch
            label='Max amount for calendar month'
            checked={calendarMonthBool.value}
            onChange={(e) =>
              handleOnChangeSwitch(
                e,
                calendarMonthBool,
                setAmountLimitMonthlyData
              )
            }
            LabelProps={{ className: styles.switch }}
          />
          <Collapse
            timeout={90}
            in={calendarMonthBool.value}
          >
            <CurrencyInput2
              fullWidth
              variant='outlined'
              label='Amount'
              placeholder='$0.00'
              value={amountLimitMonthlyData}
              onChange={(value) => setAmountLimitMonthlyData(value)}
              onBlur={() => {}}
              getCaretBoundary={() => [false]}
              disabled={!allowanceForChanges}
            />
          </Collapse>
        </div>

        <div>
          <Switch
            label='Daily ATM Limit'
            checked={dailyBool.value}
            onChange={(e) =>
              handleOnChangeSwitch(e, dailyBool, setAmountLimitDailyData)
            }
            LabelProps={{ className: styles.switch }}
          />
          <Collapse
            timeout={90}
            in={dailyBool.value}
          >
            <CurrencyInput2
              fullWidth
              variant='outlined'
              label='Amount'
              placeholder='$0.00'
              value={amountLimitDailyData}
              onChange={onChangeDailyLimit}
              onBlur={() => {}}
              getCaretBoundary={() => [false]}
              error={errorDailyBool.value}
              disabled={!allowanceForChanges}
            />
            {errorDailyBool.value && (
              <FormHelperText error={true}>
                Enter a value no less than $0 and no more than $800
              </FormHelperText>
            )}
          </Collapse>
        </div>
        <div className={styles.boxBtn}>
          <Button
            onClick={onSubmit}
            loading={isLoadingonSubmit || isLoadingDetachAllRules}
            disabled={
              onSumbitDiabled || errorDailyBool.value || !allowanceForChanges
            }
          >
            Save changes
          </Button>
          <Button
            variant='outlined'
            onClick={handeleRemoveAllLimits}
            loading={isLoadingDetachAllRules || isLoadingonSubmit}
            disabled={
              (!cardLimits?.attachedSpendRules?.length &&
                !cardLimits?.attachedVelocityRules?.length) ||
              !allowanceForChanges
            }
          >
            Remove all limits
          </Button>
        </div>
      </div>
    </TransitionAnimation>
  );
};
