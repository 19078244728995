import { Button } from 'shared/ui/buttons'
import { Typography } from 'shared/ui/typography'
import { ChevronRightIcon, BankInCircleIcon } from 'shared/ui/icons'
import styles from './styles.module.scss'

interface IExternalPayee {
  id: string
  last4: string
  name: string
  type: string
  createdOn: string
  handleNavigate: (id: string) => void
}

export const ExternalPayee = (props: IExternalPayee) => {
  const { id, name, last4, type, createdOn, handleNavigate } = props

  return (
    <Button
      className={styles.btn}
      key={id}
      startIcon={<BankInCircleIcon className={styles.icon} />}
      endIcon={<ChevronRightIcon />}
      onClick={() => handleNavigate(id)}
    >
      <div className={styles.title}>
        <Typography>{name}</Typography>
        <Typography>
          {type} Account ****({last4})
        </Typography>
        <Typography>Created on: {createdOn}</Typography>
      </div>
    </Button>
  )
}
