import { SelectChangeEvent, Skeleton, css, styled } from "@mui/material";
import { FinancialAccount } from "api/endpoints/financialAccounts";
import { AnimateWrapper } from "components/animate-wrapper";
import { useState } from "react";
import { AccountsFromSelect } from "shared/components/accountsSelect";
import { ChartReport } from "./ChartReport";
import CategoryProccentList from "pages/spendingReport/componetns/categoryProccnetList";
import helper from "services/helper";
import { TogleButton } from "shared/components/TogleButton";
import {  SpendingReportData } from "api/hooksApi/useAccountSpendingReport";
import { ChartType } from "pages/accountComponent/types";
import styles from './style.module.scss'
import { Typography } from "shared/ui/typography";
interface SpendingReportProps {
  data: SpendingReportData;
  handleDateChange: (value: Date) => void;
  date: Date;
  setCurrentAccountId: (id: string) => void;
  isLoading: boolean;
  downloadReport: () => Promise<void>;
  isLoadingDownloadReport: boolean;
  currentAccountId?: string | undefined;
  financialAccounts?: FinancialAccount[];
}

export const SpendingReport = ({
  financialAccounts,
  data,
  date,
  handleDateChange,
  currentAccountId,
  setCurrentAccountId,
  isLoading,
  downloadReport,
  isLoadingDownloadReport
}: SpendingReportProps) => {
  
  const [chartType, setChartType] = useState<ChartType>(ChartType.pieChart);

  const handlerChangeAccoutnFrom = async (event: SelectChangeEvent) => {
    setCurrentAccountId(event.target.value);
  };
  
  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        {currentAccountId && financialAccounts && (
          <AccountsFromSelect
            onChange={handlerChangeAccoutnFrom}
            accounts={financialAccounts}
            value={currentAccountId!}
            height={56}
            isShowAllAccount
          />
        )}
        <div className={styles.boxCharts}>
          {!!data?.categories && !isLoading ? (
            <ChartReport
              date={date}
              handleDateChange={handleDateChange}
              data={data}
              chartType={chartType}
            />
            ) : (
            <Skeleton height={300} />
          )}
          {!!data.total && !isLoading && (
            <div className={styles.boxTotal}>
              <Typography className={styles.boxTotalText}>
                Total: {`$${helper.formatUsdValue(data.total)}`}
              </Typography>
              <TogleButton value={chartType} setValue={setChartType} />
            </div>
          )}
          {!isLoading && (
            <CategoryProccentList
              categories={data?.categories}
              handleDownloadRepor={downloadReport}
              isLoading={isLoadingDownloadReport}
            />
          )}
        </div>
      </div>
    </AnimateWrapper>
  );
};
