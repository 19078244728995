import { VerificationDocument } from '../../../../components/verificationDocument';
import { useLocation, useNavigate } from 'react-router-dom';
import { appRoutes, VerifyDocsSubRoute } from '../../../../routes';
import { css, styled, Typography } from '@mui/material';
import { AnimateWrapper } from '../../../../components/animate-wrapper';
import { FileFormatsHint } from '../../../../components/FileFormatsHint';


const Container = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 16px;

    ${theme.breakpoints.down('sm')} {
      padding: 24px 16px 64px 16px;
    }
  `}
`;
const Box = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 400px;
    min-height: 448px;
    gap: 16px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 34px;
    font-weight: 400;
    line-height: 123.5%;
    letter-spacing: 0.25px;
  `}
`;
const SubTitle = styled(Typography)`
  ${({ theme }) => css`
    color: rgba(0, 0, 0, 0.6);
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.15px;
  `}
`;

export const VerificationDocumentUnauthorizedPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AnimateWrapper className="fade">
      <Container>
        <Box>
          <Title>Document verification</Title>
          <SubTitle>
            To complete your application and verify your account, we need to
            verify the documents below.
          </SubTitle>
          <FileFormatsHint />
          <VerificationDocument
            successText="'Your application has been submitted, and we have all the necessary documents. Press “Done” to continue.'"
            userId={location.state?.userId}
            submitButtonText="done"
            onAllDocsSubmit={() => {
              navigate(appRoutes.verifyDocs(VerifyDocsSubRoute.inProcess), {
                state: {
                  activeStep: 3,
                },
              });
            }}
          />
        </Box>
      </Container>
    </AnimateWrapper>
  );
};
