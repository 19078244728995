import Button from "@mui/material/Button";
import { TNavigateButton } from "./types";

const NavigateButton = ({
  name,
  to,
  secondary,
  warning,
  handleSubmit,
  children,
  disabled,
  login,
}: TNavigateButton) => {
  const secondaryColor = secondary ? "#939598" : "#51B9E5";
  const warningColor = warning ? "#FF8585" : "#51B9E5";
  const buttonColor = secondary ? secondaryColor : warningColor;
  return (
    <div className="w-full flex justify-center">
      <Button
        variant="outlined"
        sx={{
          borderRadius: 6,
          textTransform: "none",
          width: {
            xs: login ? 200 : 272,
            sm: 305,
          },
          height: 48,
          borderColor: buttonColor,
          color: buttonColor,
          "@media print": {
            width: 272,
          },
        }}
        href={to}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {name}
        {children}
      </Button>
    </div>
  );
};

export default NavigateButton;
