import {
  useRewardsTypesControllerQuery,
  RewardsTypesApi,
} from "api/endpoints/enums";
import { useMemo } from "react";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface useRewardsTypesForFilterReturn {
  rewardsTypesForFilter: RewardsTypesApi[];
  rewardsTypesForFilterIsLoading: boolean;
}

export const useRewardsTypesForFilter = (): useRewardsTypesForFilterReturn => {
  const { t } = useTranslationProvider();
  const { data, isLoading } = useRewardsTypesControllerQuery();
  const typeAll: RewardsTypesApi = {
    name: "All",
    id: 15,
    description: t("common.All", {
      defaultValue: "All",
    }),
    groupName: null,
  };
  const value: RewardsTypesApi[] = useMemo<any>(() => {
    if (!data) {
      return [typeAll];
    }

    const typesForFilter = [typeAll]
      .concat(data)
      .filter((item) => item.id !== 10);

    return typesForFilter;
  }, [data]);

  return {
    rewardsTypesForFilter: value,
    rewardsTypesForFilterIsLoading: isLoading,
  };
};
