import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { usePayeeHistoryAllControllerQuery } from 'api/endpoints/financialAccounts';
import { Divider } from '@mui/material';
import { Skeleton } from 'components/skeleton';
import { ConnectedPayeeHistory, EmptyPayees } from 'entities/transfers';
import { AttacheCaseIcon, InfoIcon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { useDeleteExternalPayeeMutation } from 'api/endpoints/fundsMovement';

const WARNING_TEXT =
  'It usually takes 5-10 minutes to verify your newly added external account. You`ll see the status update reflected soon.';

export const ConnectedAccountHistory = () => {
  const { setSnackBar } = useSnackBar();
  const {
    data: payeeHistory,
    isLoading: isLoadingPayeeHistory,
    isFetching: isFetchingPayeeHistory,
    refetch: refetchPayeeHistory,
  } = usePayeeHistoryAllControllerQuery();

  const [deleteExternalPayee, { isLoading: isLoadingDeleteExternalPayee }] =
    useDeleteExternalPayeeMutation();

  const [deletingPayeeId, setDeletingPayeeId] = useState<string | null>(null);

  const deletePayee = async (id: string) => {
    try {
      setDeletingPayeeId(id);
      await deleteExternalPayee({
        financialAccountId: id,
        force: true,
      }).unwrap();

      setSnackBar({
        type: 'success',
        message: 'Payee deleted successfully.',
        isShow: true,
      });
      setDeletingPayeeId(null);
      refetchPayeeHistory();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };
  const renderPayeeList = () => {
    return !!payeeHistory?.length ? (
      payeeHistory.map((payee) => (
        <ConnectedPayeeHistory
          key={payee.id}
          payee={payee}
          handleDeletePayee={deletePayee}
          isLoadingDelete={isLoadingDeleteExternalPayee}
          deletingPayeeId={deletingPayeeId}
        />
      ))
    ) : (
      <EmptyPayees
        title={'No connected financial accounts'}
        subTitle={'Connected financial accounts will be displayed here'}
        icon={<AttacheCaseIcon />}
      />
    );
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Divider />
        <div className={styles.boxWarning}>
          <InfoIcon />
          <Typography>{WARNING_TEXT}</Typography>
        </div>
        {isLoadingPayeeHistory || isFetchingPayeeHistory ? (
          <Skeleton
            width='100%'
            height='170px'
          />
        ) : (
          renderPayeeList()
        )}
      </div>
    </TransitionAnimation>
  );
};
