import { BalanceHistoryItemApi,  CashFlowPeriodEnum, useBalanceControllerQuery, useCashFlowControllerQuery } from '../../../../../api/endpoints/financialAccounts';
import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { requireNotNull } from '../../../../../shared/utils/require-not-null';
import moment from 'moment';


interface ContextProps {
  isDataLoading: boolean;
  selectedPeriod: string;
  setPeriod(period: string): void;
  balanceHistoryData?: BalanceHistoryItemApi[];
  maxValue: number;
  minValue: number;
}

const Context = createContext<ContextProps | undefined>(undefined);

export const BalanceDiagramProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>(moment().startOf('month').format('YYYY-MM-DD'));
  const { data: balanceHistoryData, isLoading, isFetching } = useBalanceControllerQuery({
    period: selectedPeriod,
  })

  const value = useMemo((): ContextProps => {
    return {
      selectedPeriod,
      balanceHistoryData,
      isDataLoading: isLoading || isFetching,
      maxValue: balanceHistoryData && balanceHistoryData?.length > 0
        ? Math.max(...balanceHistoryData.map(i => i.balance))
        : 0,
      minValue: balanceHistoryData && balanceHistoryData?.length > 0
        ? Math.min(...balanceHistoryData.map(i => i.balance))
        : 0,
      setPeriod(period: CashFlowPeriodEnum) {
        setSelectedPeriod(period);
      }
    }
  }, [selectedPeriod, isLoading, isFetching, balanceHistoryData]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
};

export const useBalanceDiagram = () => requireNotNull(React.useContext(Context));
