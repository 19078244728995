import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FinancialAccount } from 'entities/transfers';

export interface SourceAccount {
  fromFinancialAccount: string;
  totalAmount: number;
}

export enum PayeeAddContent {
  form = 'form',
  auntificator = 'auntificator',
}

export enum TransferType {
  toNetevia = 'To Netevia’s customer',
  toAch = 'ACH transfer',
}

export enum StepContent {
  typeSelect = 'typeSelect',
  form = 'form',
  confirm = 'confirm',
  success = 'success',
}

export interface TransferItem {
  amount: string;
  from: string;
  to: string;
  memo?: string;
}

type TransferBatch = {
  fromFinancialAccount: FinancialAccount | null;
  transferType: TransferType | null;
  totalAmount: number | null;
  transfers: TransferItem[] | null;
};

const initialState: TransferBatch = {
  fromFinancialAccount: null,
  transferType: null,
  totalAmount: null,
  transfers: null,
};

export const slice = createSlice({
  name: 'batchTransfer',
  initialState,
  reducers: {
    clearDataBatch: () => {
      return initialState;
    },
    clearTransfers: (state) => {
      state.transfers = null;
    },

    setFromFinancialAccount: (
      state,
      { payload }: PayloadAction<{ value: FinancialAccount }>
    ) => {
      state.fromFinancialAccount = payload.value;
    },

    setTransferType: (
      state,
      { payload }: PayloadAction<{ value: TransferType }>
    ) => {
      state.transferType = payload.value;
    },
    setTotalAmount: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.totalAmount = payload.value;
    },

    setTransfers: (
      state,
      { payload }: PayloadAction<{ value: TransferItem[] }>
    ) => {
      state.transfers = payload.value;
    },
  },
});

export const selectFromFinancialAccount = (state: RootState) =>
  state.batchTransfer.fromFinancialAccount;

export const selectTransferType = (state: RootState) =>
  state.batchTransfer.transferType;

export const selectTotalAmount = (state: RootState) =>
  state.batchTransfer.totalAmount;

export const selectTransfers = (state: RootState) =>
  state.batchTransfer.transfers;

export const {
  clearDataBatch,
  setFromFinancialAccount,
  setTransferType,
  setTotalAmount,
  setTransfers,
  clearTransfers,
} = slice.actions;
