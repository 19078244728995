import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { Switch } from 'shared/ui/inputs/switch';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';
import { useFormattedHolderData } from 'shared/lib/hooks/useFormattedHolderData';
import { formatAddress } from 'shared/lib/format';
import { DeliveryForm } from 'features/open-orderout-card/ui/open-physical-card-step-1/delivery-form';
import { DeliveryService } from 'entities/delivery-service/ui/delivery-service';
import { Button } from 'shared/ui/buttons';
import {
  PhysicalCardOrderSchema,
  PhysicalCardOrder,
} from 'features/open-orderout-card/model';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { FullName, FullAddress } from 'pages/cardManage/types';
import { Skeleton } from 'components/skeleton';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import styles from './styles.module.scss';
import { DeliveryMethod } from 'api/endpoints/paymentCards';
import { useCurrentUser } from 'providers/user.provider';
import { useSubUserMEControllerQuery } from 'api/endpoints/subProfiles';
import { useFormik } from 'formik';

interface PhysicalCardReissueProps {
  deliveryService: string | DeliveryMethod;
  setDeliveryService: (value: string | DeliveryMethod) => void;
  anotherAddressBool: UseBooleanReturn;
  setFullAddress: (value: FullAddress) => void;
  setFullName: (value: FullName) => void;
}

export const PhysicalCardReissue = (props: PhysicalCardReissueProps) => {
  const {
    deliveryService,
    setDeliveryService,
    anotherAddressBool,
    setFullAddress,
    setFullName,
  } = props;

  const { goNext } = useDrawerStepperContext();
  const { isSubUser } = useCurrentUser();
  const { data: subUserInfo, isLoading: subUserInfoIsLoading } =
    useSubUserMEControllerQuery({}, { skip: !isSubUser });
  const { formattedHolderData, formattedholderDataIsLoading } =
    useFormattedHolderData();
  const { data: holderData, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery();

  const businessAccountHolder = holderData?.businessAccountHolder;
  const addressInfo = isSubUser
    ? subUserInfo?.fullAdress
    : formattedHolderData?.bussinessInfo?.addressInfo;
  const fullAddress = addressInfo ? formatAddress(addressInfo) : '';
  const formik = useFormik<PhysicalCardOrder>({
    initialValues: {
      state: '',
      address: '',
      firstName: '',
      lastName: '',
      postCode: '',
      city: '',
      additionalAddress: '',
    },
    validationSchema: anotherAddressBool.value && PhysicalCardOrderSchema,
    onSubmit: (form) => {
      const formValues = form;
      const updatedFullAddress = getUpdatedFullAddress(formValues);
      const updatedFullName = getUpdatedFullName(formValues);

      setFullAddress(updatedFullAddress);
      setFullName(updatedFullName);
      goNext('2');
    },
  });

  const handleChangeDeliveryService = (value: string) => {
    setDeliveryService(value as DeliveryMethod);
    formik.validateForm();
  };

  const getUpdatedFullAddress = (formValues): FullAddress => ({
    locality: anotherAddressBool.value
      ? formValues.city
      : addressInfo?.locality,

    postalCode: anotherAddressBool.value
      ? formValues.postCode
      : addressInfo?.postalCode,

    region: anotherAddressBool.value ? formValues.state : addressInfo?.region,

    streetAddress: anotherAddressBool.value
      ? formValues.address
      : addressInfo?.streetAddress,

    additionalAddress: anotherAddressBool.value
      ? formValues.additionalAddress
      : businessAccountHolder?.extendedAddress,
  });

  const getUpdatedFullName = (formValues): FullName => {
    if (anotherAddressBool.value) {
      return {
        familyName: formValues.firstName,
        givenName: formValues.lastName,
      };
    } else if (isSubUser) {
      return {
        familyName: subUserInfo?.familyName,
        givenName: subUserInfo?.givenName,
      };
    } else {
      return {
        familyName: businessAccountHolder?.familyName || '',
        givenName: businessAccountHolder?.givenName || '',
      };
    }
  };

  const disabled =
    fullAddress === '' ||
    deliveryService === '' ||
    (anotherAddressBool.value && !formik.isValid);

  const isLoading =
    formattedholderDataIsLoading || isLoadingHolder || subUserInfoIsLoading;

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.container}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.boxAddress}>
          <Typography variant='subtitle1'>Delivery address</Typography>
          <Typography className={styles.boxAddressText}>
            {fullAddress}
          </Typography>
        </div>
        <Switch
          label={'Ship to a different address'}
          LabelProps={{ labelPlacement: 'start' }}
          fullWidth
          checked={anotherAddressBool.value}
          onChange={() => anotherAddressBool.toggle()}
        />
        <TransitionAnimation
          inState={anotherAddressBool.value}
          containerProps={{ className: styles.containerForm }}
        >
          <DeliveryForm formik={formik} />
        </TransitionAnimation>
        <DeliveryService
          value={deliveryService}
          onChange={handleChangeDeliveryService}
        />
        <Button
          type='submit'
          disabled={disabled}
        >
          Next
        </Button>
      </form>
    </TransitionAnimation>
  );
};
