import { FC, useCallback } from "react";
import SelectTransferPointItem from "../selectTransferPointItem";
import { ClientNameApi } from "api/endpoints/search";
import styles from "./styles.module.scss";
import { AnimateWrapper } from "components/animate-wrapper";
import { Skeleton } from "components/skeleton";
import NotFound from "assets/svg/search-not found.svg";
interface SelectTransferPointListProps {
  dataArr: ClientNameApi[];
  title: string;
  handleChooseAcc: (id: number) => void;
  selectItem: ClientNameApi | undefined;
  isLoading: boolean;
}

const SelectTransferPointList: FC<SelectTransferPointListProps> = ({
  dataArr,
  title,
  handleChooseAcc,
  selectItem,
  isLoading,
}) => {
  const checkSelectItem = useCallback(
    (id) => selectItem?.profileId === id,
    [selectItem]
  );

  const renderItems = (dataArr) => {
    if (dataArr?.length) {
      return dataArr?.map((i, index) => (
        <AnimateWrapper className="fade" key={index}>
          <SelectTransferPointItem
            id={i.profileId}
            name={i.doingBusinessName}
            phone={i.businessPhoneNumber}
            handleChooseAcc={handleChooseAcc}
            checked={checkSelectItem(i.profileId)}
            selectItem={selectItem}
          />
        </AnimateWrapper>
      ));
    } else {
      return (
        <div className={styles.notFound}>
          <img src={NotFound} />
          <p>The payee with the entered data was not found in our system.</p>
        </div>
      );
    }
  };

  return isLoading ? (
    <Skeleton width="100%" height="90px" />
  ) : (
    <div className={styles.list}>
      <span className={styles.title}>{title}</span>
      {renderItems(dataArr)}
    </div>
  );
};
export default SelectTransferPointList;
