import React from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import styles from './style.module.scss';

interface EmptyItemProps {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
}

export const EmptyItem = (props: EmptyItemProps) => {
  const { icon, title, subTitle } = props;

  return (
    <TransitionAnimation>
      <div className={styles.wrapper}>
        {icon}
        <Typography>{title}</Typography>
        <Typography className={styles.subTitle}>{subTitle}</Typography>
      </div>
    </TransitionAnimation>
  );
};
