import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSearchParams } from "react-router-dom";
import styles from "./style.module.scss";
import MonetizationonOutlined from "assets/svg/MonetizationonOutlined.svg";
import CardGiftcardOutlined from "assets/svg/CardGiftcardOutlined.svg";
import { InfoItem } from "./InfoItem";
import ButtonWithChevron from "components/button/buttonWithChevron";
import DrawerModal from "shared/components/drawerModal";
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { useLimitsAndRewardsConditions } from "api/hooksApi/useLimitsAndRewardsConditions";
import { usePermission } from "shared/hooks/usePermission";

export const LimitsConditions = () => {
  const { limitsAndConditionsPermission, rewardConditionsPermission } =
    usePermission();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const page = searchParams.get("page") || "";
  const { t } = useTranslationProvider();
  const { conditions } = useLimitsAndRewardsConditions();

  const handleClickButton = (mode: string) => {
    setSearchParams({ page, mode });
  };

  return (
    <div className={styles.container}>
      {!matches && (
        <div className={styles.container_nav}>
          <img src={ArrowLeft} onClick={() => setSearchParams({})} />
          <span className={styles.container_nav_title}>
            {t("Settings.Company information", {
              defaultValue: "Company information",
            })}
          </span>
        </div>
      )}
      {limitsAndConditionsPermission && (
        <ButtonWithChevron
          key={"Transaction limits"}
          text={"Transaction limits"}
          img={MonetizationonOutlined}
          onClick={() => handleClickButton("TransactionLimits")}
        />
      )}
      {rewardConditionsPermission && (
        <ButtonWithChevron
          key={"Rewards limits & conditions"}
          text={"Rewards limits & conditions"}
          img={CardGiftcardOutlined}
          onClick={() => handleClickButton("RewardsLimits")}
        />
      )}

      <DrawerModal
        isShow={searchParams.get("mode") === "TransactionLimits"}
        onClose={() => setSearchParams({ page })}
        titleText="Transaction limits"
      >
        {conditions?.transactionLimitsItems?.map((item) => (
          <InfoItem key={item.name} {...item} />
        ))}
      </DrawerModal>
      <DrawerModal
        isShow={searchParams.get("mode") === "RewardsLimits"}
        onClose={() => setSearchParams({ page })}
        titleText="Rewards limits & conditions"
      >
        {conditions?.rewardsLimitsItems?.map((item) => (
          <InfoItem key={item.name} {...item} />
        ))}
      </DrawerModal>
    </div>
  );
};
