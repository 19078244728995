import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';
import { CreditCardIcon } from 'shared/ui/icons';
import { DeliveryServiceCard } from 'entities/delivery-service';
import { useAppSelector } from 'shared/models';
import { OrderOutCardModel } from 'entities/orderout-card';
import { TextInput } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { formatAddress } from 'shared/lib/format';
import { useOpenPhysicalCard } from 'features/open-orderout-card/model';
import { useMediaQuery } from '@mui/material';
import { TransitionAnimation } from 'shared/ui/animations';
import { useSnackBar } from '../../../../providers/snackBar.provider';

export const OpenPhysicalCardStep2 = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { goNext } = useDrawerStepperContext();
  const deliveryInfo = useAppSelector(OrderOutCardModel.selectdeliveryInfo);
  const snackBar = useSnackBar();

  const { openPhysicalCard, isLoading } = useOpenPhysicalCard();

  const fullAddress = formatAddress(
    {
      locality: deliveryInfo.city,
      postalCode: deliveryInfo.postCode,
      region: deliveryInfo.state,
      streetAddress: deliveryInfo.address,
    },
    deliveryInfo.additionalAddress
  );

  const handleSubmit = async () => {
    const result = await openPhysicalCard();
    if (result.isError) {
      snackBar.setSnackBar({
        type: 'error',
        isShow: true,
        message: result.errorMessage,
      });
    }

    if (!result.isError) goNext('6');
  };

  return (
    <TransitionAnimation containerProps={{ className: styles.animContainer }}>
      <div className={styles.root}>
        <Typography variant="body1">Confirm entered details</Typography>

        <div className={styles.orderTypeCard}>
          <CreditCardIcon/>

          <div className={styles.info}>
            <Typography variant="body2">Type of card</Typography>
            <Typography
              variant="body2"
              className={styles.secColor}
            >
              Physical OrderOut
            </Typography>
          </div>
        </div>

        <div className={styles.deliveryCard}>
          <Typography variant="subtitle1">Delivery service</Typography>

          <DeliveryServiceCard serviceTypeId={deliveryInfo.method}/>
        </div>

        <div className={styles.shipAddress}>
          <Typography variant="subtitle1">Shipping address</Typography>

          <TextInput
            fullWidth
            InputProps={{ readOnly: true }}
            value={fullAddress}
          />
        </div>

        <Button
          className={styles.button}
          onClick={handleSubmit}
          loading={isLoading}
          fullWidth={isMobile}
        >
          PLACE ORDER
        </Button>
      </div>
    </TransitionAnimation>
  );
};
