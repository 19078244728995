import styles from './styles.module.scss';
import OrderCard from 'assets/img/OrderOut_virtual.png';
import cn from 'classnames';

interface Props {
    className?: string;
}

export const OrderOutCard = (props: Props) => {
    const { className } = props;

    return (
        <img
            src={OrderCard}
            className={cn(styles.root, className)}
            alt="OrderOut card"
        />
    );
};
