import { FilterCards } from 'pages/accountComponent/types';
import { PaymentCard, EnumCardStatuses } from 'api/endpoints/paymentCards';
import { User } from 'providers/user.provider';

export const filteredCards = (
  cards: PaymentCard[],
  filter: FilterCards,
  user: User,
  subUserNotHaveAccess: boolean
): PaymentCard[] => {
  const isSubUser = user?.type === 4;
  const userProfileName = isSubUser ? user?.fullName : null;

  switch (filter) {
    case FilterCards.MyCards:
      return cards.filter(
        (card) =>
          card.status !== EnumCardStatuses.CLOSED &&
          card.subProfile === userProfileName
      );

    case FilterCards.AllAvailableCards:
      return cards.filter((card) => card.status !== EnumCardStatuses.CLOSED);

    case FilterCards.ClosedCards:
      return subUserNotHaveAccess
        ? cards.filter(
            (card) =>
              card.status === EnumCardStatuses.CLOSED &&
              card.subProfile === user?.fullName
          )
        : cards.filter((card) => card.status === EnumCardStatuses.CLOSED);

    default:
      return cards;
  }
};
