import styles from './style.module.scss';
import { SvgIcon } from '../../shared/components/svgIcon';
import React from 'react';

export const FileFormatsHint = () => {
  return (
    <div className={styles.formatsHint}>
      <SvgIcon name="InfoOutlined" sizePx={20}/>
      Accepted formats: PDF, JPG, JPEG, PNG
    </div>
  );
};
