import { MutableRefObject, useEffect, useRef } from 'react';

export const useTimeout = (callback: () => void, delay: number): MutableRefObject<number | null> => {
  const timeoutRef = useRef<number | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    timeoutRef.current = window.setTimeout(tick, delay)
    return () => {
      timeoutRef.current && window.clearTimeout(timeoutRef.current)
    }
  }, [delay]);

  return timeoutRef;
};
