import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { useRewardIntegrations } from 'api/hooksApi/useRewardsIntegrations';
import { Place } from 'api/endpoints/rewardIntegrations';
import styles from './styles.module.scss';
import { SliderRewards } from 'pages/reward/components/dashboard/components/featuredRewards/componetns/sliderRewards';
import { useRewardIntegrationsHandler } from 'providers/rewardIntegrationsHandler';

const SLIDER_LENGTH = 1;

export const HomeSlider = () => {
  const { rewardIntegrations, rewardIntegrationsIsLoading } =
    useRewardIntegrations({ place: Place.Home });

  const rewardIntegrationsHandler = useRewardIntegrationsHandler();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: SLIDER_LENGTH,
    slidesToScroll: 1,
    arrows: false,
  };

  const BANNER_LENGTH = rewardIntegrations?.length;

  const disabledPrevSlide = currentSlideIndex === 0;

  const disabledNextSlide = currentSlideIndex === BANNER_LENGTH - SLIDER_LENGTH;

  if (rewardIntegrations.length === 0) {
    return null;
  }

  return (
    <TransitionAnimation>
      <SliderRewards
        data={rewardIntegrations}
        loadingData={rewardIntegrationsIsLoading}
        disabledPrevSlide={disabledPrevSlide}
        disabledNextSlide={disabledNextSlide}
        setCurrentSlideIndex={setCurrentSlideIndex}
        sliderHandler={rewardIntegrationsHandler.handle}
        settingsSlider={settingsSlider}
        styleWrapper={styles.wrapper}
        styleWrapperHeader={styles.styledHeader}
        styleItem={{
          wrapper: styles.styledItemWrapper,
          item: styles.styledItem,
        }}
      />
    </TransitionAnimation>
  );
};
