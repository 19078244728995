import styles from "./styles.module.scss";
import { Divider } from "@mui/material";
import { useRewards } from "pages/reward/provider/useRewards";
import { replaceNumberWithComma } from "shared/utils/price";
import { Skeleton } from "components/skeleton";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useSearchParams } from "react-router-dom";
import DrawerModal from "shared/components/drawerModal";
import { InfoItem } from "pages/settings/LimitsConditions/InfoItem";
import { useLimitsAndRewardsConditions } from "api/hooksApi/useLimitsAndRewardsConditions";
import { RewardMode } from "pages/reward/types";

export const AvaiblePointsRewards = () => {
  const { t } = useTranslationProvider();
  const { totalPoints, isDataLoading } = useRewards();
  const [searchParams, setSearchParams] = useSearchParams();
  const { conditions, conditionsIsLoading } = useLimitsAndRewardsConditions();
  const page = searchParams.get("page") || "";

  const handleClickButton = (mode: string) => {
    setSearchParams({ page, mode });
  };

  return isDataLoading ? (
    <Skeleton width="100%" height="194px" />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <span className={styles.box_total}>
          {replaceNumberWithComma(totalPoints)}
        </span>
        <span className={styles.discription}>Available points</span>
        <Divider className={styles.box_divider} />
        <span
          className={styles.discription}
          onClick={() => handleClickButton(RewardMode.RewardsLimits)}
        >
          <span className={styles.blueColor}>
            {t("rewards.contentTitle1", {
              defaultValue: "Rewards Conditions",
            })}
          </span>{" "}
          {t("common.apply", {
            defaultValue: "apply",
          })}
        </span>
        <DrawerModal
          isShow={searchParams.get("mode") === RewardMode.RewardsLimits}
          onClose={() => setSearchParams({ page })}
          titleText="Rewards conditions"
        >
          {conditionsIsLoading ? (
            <Skeleton width="100%" height="194px" />
          ) : (
            conditions?.rewardsLimitsItems.map((item) => (
              <InfoItem key={item.name} {...item} />
            ))
          )}
        </DrawerModal>
      </div>
    </div>
  );
};
