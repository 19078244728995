import styles from "./style.module.scss";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import FaqListBasicTheme from "./components/faqListBasicTheme";
import { useFAQGrouping } from "api/hooksApi/useFAQGrouping";
import { Skeleton } from "components/skeleton";

const FAQ = () => {
  const { FAQLabel, FAQIsLoading } = useFAQGrouping();

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <span className={styles.container_title}>FAQ</span>
        {FAQIsLoading ? (
          <Skeleton width="100%" height={60} />
        ) : (
          <FaqListBasicTheme data={FAQLabel} />
        )}
      </div>
    </AnimateWrapper>
  );
};

export default FAQ;
