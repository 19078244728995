import { useRef } from 'react';
import styles from './styles.module.scss';
import Slider from 'react-slick';
import SliderHeader from '../sliderHeader';
import { Skeleton } from 'components/skeleton';
import { RewardIntegrationsApi } from 'api/endpoints/rewardIntegrations';
import { SliderItem } from '../sliderItem';
import cn from 'classnames';
import { RewardIntegrationWithType } from '../../../../../../../../api/hooksApi/useRewardsIntegrations';

interface SliderProps {
  data: RewardIntegrationWithType[];
  loadingData: boolean;
  disabledPrevSlide: boolean;
  disabledNextSlide: boolean;
  setCurrentSlideIndex: (currentSlideIndex: number) => void;
  sliderHandler: (item: RewardIntegrationWithType) => Promise<void>;
  settingsSlider: any;
  title?: string;
  styleWrapper?: string;
  styleWrapperHeader?: string;
  styleItem?: {
    wrapper?: string;
    item?: string;
  };
}

export const SliderRewards = (props: SliderProps) => {
  const {
    data,
    loadingData,
    disabledPrevSlide,
    disabledNextSlide,
    setCurrentSlideIndex,
    sliderHandler,
    settingsSlider,
    title,
    styleWrapper,
    styleWrapperHeader,
    styleItem,
  } = props;

  const sliderRef = useRef<Slider>(null);

  const goToNextSlide = () => {
    sliderRef?.current?.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef?.current?.slickPrev();
  };

  return (
    <div className={cn(styleWrapper, styles.wrapper)}>
      <SliderHeader
        goToPrev={goToPrevSlide}
        disabledPrev={disabledPrevSlide}
        goToNext={goToNextSlide}
        disabledNext={disabledNextSlide}
        title={title}
        styleWrapperHeader={styleWrapperHeader}
      />

      {loadingData ? (
        <Skeleton
          width='100%'
          height='217px'
        />
      ) : (
        <div className={styles.sliderContainer}>
          <Slider
            {...settingsSlider}
            ref={sliderRef}
            beforeChange={(currentSlide: number, nextSlide: number) => {
              setCurrentSlideIndex(nextSlide);
            }}
          >
            {data?.map((item) => (
              <SliderItem
                key={item.id}
                item={item}
                onClick={sliderHandler}
                styleItem={styleItem}
              />
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};
