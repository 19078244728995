import { TransitionAnimation } from 'shared/ui/animations';
import { Accordion } from '../accordion';
import { FormControlLabel, Checkbox } from '@mui/material';
import styles from './style.module.scss';
import { FilterProps } from 'pages/accountComponent/types';
import { Skeleton } from 'components/skeleton';
import { ByMoneyMovement } from 'pages/accountComponent/types';

export const FilterByMoneyMovement = (props: FilterProps) => {
  const { filterObj, setFilterObj, loading, expandFilter, setExpandFilter } =
    props;
  const { expandByMoneyMovement } = expandFilter;

  const byMoneyMovement = [filterObj.deposit, filterObj.withdraw];

  const handleChangeExpand = (value: boolean) => {
    setExpandFilter({
      ...expandFilter,
      expandByMoneyMovement: value,
    });
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === ByMoneyMovement.Deposits) {
      setFilterObj({
        ...filterObj,
        deposit: {
          ...filterObj.deposit,
          value: checked,
        },
      });
    } else if (name === ByMoneyMovement.Withdrawals) {
      setFilterObj({
        ...filterObj,
        withdraw: {
          ...filterObj.withdraw,
          value: checked,
        },
      });
    }
  };

  const renderLabelCheckBox = (displayName: string) =>
    displayName === ByMoneyMovement.Deposits ? 'Money in' : 'Money out';

  const renderCheckbox = () => {
    return byMoneyMovement.map((item) => (
      <FormControlLabel
        key={item?.displayName}
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            checked={item?.value}
            onChange={handleCheckboxChange}
            name={item?.displayName}
          />
        }
        label={renderLabelCheckBox(item?.displayName)}
      />
    ));
  };

  return loading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <Accordion
        expanded={expandByMoneyMovement ?? false}
        onChangeExpanded={handleChangeExpand}
        summary='By money movement'
      >
        {renderCheckbox()}
      </Accordion>
    </TransitionAnimation>
  );
};
