import { Typography } from 'shared/ui/typography'
import { Skeleton } from 'components/skeleton'
import { Button } from 'shared/ui/buttons'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { CopyGrayIcon } from 'shared/ui/icons'
import styles from './style.module.scss'

interface IProps {
  isLoading: boolean
  qrCode: string
  secret: string
  saveSettingOtp: () => Promise<void>
  loadingSubmit: boolean
  copySecret: (secret: string) => void
  slotToggleTypeAuntificator?: React.ReactNode
  slotInstructions?: React.ReactNode
}
export const SecretForm = (props: IProps) => {
  const {
    isLoading,
    qrCode,
    secret,
    saveSettingOtp,
    loadingSubmit,
    copySecret,
    slotToggleTypeAuntificator,
    slotInstructions,
  } = props

  return (
    <div className={styles.container}>
      {/*<Typography className={styles.title}>*/}
      {/*  Confirm transfer via Authenticator code*/}
      {/*</Typography>*/}
      <Typography className={styles.subTitle}>
        Set up a TOTP authenticator (e.g., Google Authenticator) before saving
        changes. It`s essential for secure login. If the secret changes, update
        your authenticator for continued access.
      </Typography>

      <div className={styles.qrCode}>
        {isLoading ? (
          <Skeleton
            width='240px'
            height='240px'
          />
        ) : (
          <img
            src={qrCode}
            alt='QR Code'
            className={styles.qrCode_img}
          />
        )}
      </div>
      {isLoading ? (
        <Skeleton
          width='100%'
          height='56px'
        />
      ) : (
        <TextField
          type='text'
          label={'Setup key'}
          value={secret}
          fullWidth
          inputProps={{ readOnly: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='copy data'
                  onClick={(e) => {
                    e.stopPropagation()
                    copySecret(secret)
                  }}
                >
                  <CopyGrayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {slotToggleTypeAuntificator}
      {slotInstructions}
      <Button
        className={styles.button}
        onClick={saveSettingOtp}
        loading={loadingSubmit}
        variant='contained'
      >
        Continue
      </Button>
    </div>
  )
}
