import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { ConfirmDialog } from "../components/confirmDialog";
import { Dialog } from "components/dialog";
export interface ConfirmShowProps {
  onApply(value?: string | undefined): void;
  onCancel?(): void;
  dialogText?: string | React.ReactNode;
  dialogTitle?: string | React.ReactNode;
  applyButtonText?: string;
  applyButtonType?: "text" | "contained" | "outlined";
  applyError?: boolean;
  cancelButtonText?: string;
  cancelButtonType?: "text" | "contained" | "outlined";
  cancelError?: boolean;
  modalType?: string;
  placeholder?: string;
  defaultValue?: string;
  hideButtons?: boolean;
  typeNew?: boolean;
}

export interface ConfirmContextInterface {
  show(confirmShowProps: ConfirmShowProps): void;
}

const Context = createContext<ConfirmContextInterface | undefined>(undefined);

export const ConfirmDialogProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [dialogParams, setdialogParams] = useState<ConfirmShowProps | null>(
    null
  );

  const handleClose = () => {
    setdialogParams(null);
  };

  const handleShow = useCallback((data: ConfirmShowProps) => {
    setdialogParams(data);
  }, []);

  const handleApply = (value) => {
    dialogParams?.onApply(value);
    handleClose();
  };

  const handleCancel = () => {
    if (dialogParams && dialogParams.onCancel) {
      dialogParams.onCancel();
    }
    handleClose();
  };
  const params: ConfirmContextInterface = {
    show: handleShow,
  };

  const getDefaultButtonType = (
    value: "text" | "contained" | "outlined" | undefined = "contained"
  ): "text" | "contained" | "outlined" => {
    return value;
  };
  const cancelButtonType = getDefaultButtonType(dialogParams?.cancelButtonType);
  const applyButtonType = getDefaultButtonType(dialogParams?.applyButtonType);

  return (
    <Context.Provider value={params}>
      {dialogParams?.typeNew ? (
        <Dialog
          onApply={handleApply}
          onCancel={handleCancel}
          open={!!dialogParams}
          onClose={handleCancel}
          dialogTitle={dialogParams?.dialogTitle}
          dialogText={dialogParams?.dialogText}
          applyButtonText={dialogParams?.applyButtonText || "Yes"}
          applyButtonType={applyButtonType}
          applyError={dialogParams?.applyError || false}
          cancelButtonText={dialogParams?.cancelButtonText || undefined}
          cancelButtonType={cancelButtonType}
          cancelError={dialogParams?.cancelError || false}
          modalType={dialogParams?.modalType}
          placeholder={dialogParams?.placeholder}
          hideButtons={dialogParams?.hideButtons}
          defaultValue={dialogParams?.defaultValue}
        />
      ) : (
        <ConfirmDialog
          onApply={handleApply}
          onCancel={handleCancel}
          open={!!dialogParams}
          onClose={handleCancel}
          dialogTitle={dialogParams?.dialogTitle}
          dialogText={dialogParams?.dialogText}
          applyButtonText={dialogParams?.applyButtonText || "Yes"}
          applyButtonType={applyButtonType}
          cancelButtonText={dialogParams?.cancelButtonText || "Cancel"}
          cancelButtonType={cancelButtonType}
          cancelError={dialogParams?.cancelError || false}
          modalType={dialogParams?.modalType}
          placeholder={dialogParams?.placeholder}
          hideButtons={dialogParams?.hideButtons}
          defaultValue={dialogParams?.defaultValue}
        />
      )}

      {children}
    </Context.Provider>
  );
};

export const useConfirmDialog = () =>
  useContext(Context) as ConfirmContextInterface;
