import { SvgIcon } from '../components/svgIcon';
import { TextField } from '@mui/material';

interface Props {
  formikInstance: any;
}

export const PercentageField = ({ formikInstance: formik }: Props) => {
  const handleChange = (e: any) => {
    const rawValue = e?.target?.value?.replace(/\D/g, '');
    if (rawValue === '') {
      formik.setFieldValue('ownershipPercentage', '');
      return;
    }
    const valueNum = Number(e.target.value.replace(/\D/g, ''));
    formik.setFieldValue(
      'ownershipPercentage',
      Number.isNaN(valueNum) ? '' : valueNum > 100 ? 100 : valueNum
    );
  };
  return (
    <TextField
      fullWidth
      type='text'
      variant='outlined'
      label='Ownership percentage'
      error={Boolean(
        formik.touched.ownershipPercentage && formik.errors.ownershipPercentage
      )}
      helperText={
        formik.touched.ownershipPercentage && formik.errors.ownershipPercentage
      }
      InputProps={{
        startAdornment: (
          <div style={{ display: 'flex', marginRight: '8px' }}>
            <SvgIcon name='PercentOutlined' />
          </div>
        ),
      }}
      {...formik.getFieldProps('ownershipPercentage')}
      onChange={handleChange}
    />
  );
};
