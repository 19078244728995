export function requireNotNull<T>(
  value: T | undefined | null,
  message?: string,
): T {
  if (value === undefined || value === null) {
    throw new TypeError(message ?? "Required value is not defined");
  }
  return value;
}

export const safeJsonParse = <T = any>(candidate: any, fallback: T): T => {
  try {
    return JSON.parse(candidate);
  } catch {
    return fallback;
  }
};
