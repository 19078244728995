import { createRoot } from 'react-dom/client';
import {
  getLocalStorageObject,
  LocalStorageKeys,
} from 'shared/utils/local-storage';
import { AuthorizedRoutes } from 'app/authorized/routes';
import { AppLoader } from 'components/appLoader';
import { ErrorBoundary } from 'react-error-boundary';
import { UserProvider } from 'providers/user.provider';
import { LicenseInfo } from '@mui/x-license-pro';
import { TranslationProvider } from 'providers/translation/translation.provider';
import { SnackBarProvider } from 'providers/snackBar.provider';
import { UnauthorizedRoutes } from 'app/unauthorizedRoutes/routes';
import { ConfirmDialogProvider } from 'providers/confirm.provider';
import { withProviders } from 'app/providers';
import { DialogControllerProvider } from 'providers/dialogController.provider';
import './styles/index.css';
import { RequestDocsDrawer } from './components/RequestDocsDrawer';
import { AppError } from './pages/appError';
import { DrawerBehaviorProvider } from './providers/drawer-behavior';
import { MfaProvider } from './providers/mfa/mfa';
import { SwitchDirectDepositProvider } from 'providers/switchDirectDeposit';
import { RewardIntegrationsHandlerProvider } from './providers/rewardIntegrationsHandler';

window.Buffer = window.Buffer || require('buffer').Buffer;

const App = withProviders(() => {
  LicenseInfo.setLicenseKey(
    'f601fc42aee8d9b10c3b8be634dc950aTz05NjkxOCxFPTE3NTYzMDYwOTcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
  );
  const token = getLocalStorageObject(LocalStorageKeys.AuthToken);
  return (
    <>
      <AppLoader />
      <TranslationProvider>
        <ErrorBoundary FallbackComponent={AppError}>
          <DrawerBehaviorProvider>
            <DialogControllerProvider>
              <ConfirmDialogProvider>
                <SnackBarProvider>
                  {token ? (
                    <UserProvider>
                      <MfaProvider>
                        <SwitchDirectDepositProvider>
                          <RewardIntegrationsHandlerProvider>
                            <RequestDocsDrawer />
                            <AuthorizedRoutes />
                          </RewardIntegrationsHandlerProvider>
                        </SwitchDirectDepositProvider>
                      </MfaProvider>
                    </UserProvider>
                  ) : (
                    <UnauthorizedRoutes />
                  )}
                </SnackBarProvider>
              </ConfirmDialogProvider>
            </DialogControllerProvider>
          </DrawerBehaviorProvider>
        </ErrorBoundary>
      </TranslationProvider>
    </>
  );
});

const root = createRoot(document.getElementById('root')!);
root.render(<App />);
