import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { DetailsBrands } from './componetns/detailsBrands';
import { DetailsAmount } from './componetns/detailsAmount';
import { DetailsPaymentMethod } from './componetns/detailsPaymentMethod';
import { AuntificatorSms } from './componetns/auntificatorSms';
import { BrandsFilter } from './componetns/brandsFilter';
interface Props {
  isShow: boolean;
  onClose: () => void;
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Gift card',
      prevId: null,
      Element: <DetailsBrands />,
    },
  ],
  [
    '1',
    {
      id: '1',
      isCanGoBack: true,
      title: 'Gift card',
      prevId: '0',
      Element: <DetailsAmount />,
      resetStepsOnReach: true,
    },
  ],
  [
    '2',
    {
      id: '2',
      isCanGoBack: true,
      title: 'Gift card',
      prevId: '1',
      Element: <DetailsPaymentMethod />,
      resetStepsOnReach: true,
    },
  ],
  [
    '3',
    {
      id: '3',
      isCanGoBack: true,
      title: 'Gift card',
      prevId: '2',
      Element: <AuntificatorSms />,
      resetStepsOnReach: true,
    },
  ],
  [
    '4',
    {
      id: '4',
      isCanGoBack: true,
      title: 'Filter',
      prevId: '0',
      Element: <BrandsFilter />,
      resetStepsOnReach: true,
    },
  ],
];

const MapSteps = new Map(steps);

export const DrawerStepperGiftCard = (props: Props) => {
  const { isShow, onClose } = props;

  return (
    <DrawerStepper
      {...props}
      isShow={isShow}
      startStep='0'
      steps={MapSteps}
      onClose={onClose}
    />
  );
};
