import { TransitionAnimation } from 'shared/ui/animations';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { CardView } from 'shared/components/CardView';
import { Badge as Chip } from 'shared/ui/badge';
import Badge from 'pages/accountComponent/components/Badge';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import helper from 'services/helper';
import styles from './style.module.scss';
import cn from 'classnames';
import { Skeleton } from 'components/skeleton';
import { FilterControl } from '../filterControl';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';
import { EmptyItem } from '../emptyItem';
import { useScreenSize } from 'shared/hooks/useScreensSize';
import { usePermission } from 'shared/hooks/usePermission';

interface TableDesctopProps {
  cards: PaymentCard[];
  cardsIsLoading: boolean;
  searchCardByFunctionality: string;
  setSearchCardByFunctionality: (searchTransactionByName: string) => void;
  filterDrawerBool: UseBooleanReturn;
}

const tableHeaders = [
  { label: 'Card', className: 'cellCard' },
  { label: 'Cardholder name', className: 'cellHolder' },
  { label: 'Card type', className: 'cellType' },
  { label: 'Department', className: 'cellDepartment' },
  { label: 'Status', className: 'cellStatus' },
  { label: 'Action', className: 'cellActive' },
];

export const TableDesctop = (props: TableDesctopProps) => {
  const {
    cards,
    cardsIsLoading,
    searchCardByFunctionality,
    setSearchCardByFunctionality,
    filterDrawerBool,
  } = props;
  const { isMobile } = useScreenSize();
  const [_, setSearchParams] = useSearchParams();
  const { canOpenCard } = usePermission();
  const navigate = useNavigate();

  const handleNavigateToCard = (accountId: string | null, cardId: string) => {
    if (accountId) {
      navigate(appRoutes.cardById(accountId, cardId));
    }
  };

  const renderCardRow = (card: PaymentCard) => (
    <div className={styles.box}>
      <CardView
        isSmallSize
        card={card}
      />
      <div>
        <Typography className={styles.boxText}>
          {card.cardName !== null ? card.cardName : 'Business Debit'}
        </Typography>
        {card.last4 && (
          <Typography className={styles.boxLast4}>({card.last4})</Typography>
        )}
      </div>
    </div>
  );

  const renderCardHolderRow = ({ subProfile, cardholder }: PaymentCard) =>
    subProfile ? (
      <>
        <Typography>{subProfile}</Typography>
        <Chip label='Authorized user' />
      </>
    ) : (
      <Typography>{cardholder}</Typography>
    );

  const renderTableBody = () =>
    !!cards?.length ? (
      cards.map((card) => (
        <TransitionAnimation key={card.id}>
          <TableRow
            key={card.id}
            component='div'
            classes={{ root: styles.tableBodyRow }}
            onClick={() =>
              handleNavigateToCard(card.financialAccounts[0]?.id, card.id)
            }
          >
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellCard }}
            >
              {renderCardRow(card)}
            </TableCell>
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellHolder }}
            >
              {renderCardHolderRow(card)}
            </TableCell>
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellType }}
            >
              <Typography>
                {helper.capitalizeFirstLetter(card.formFactor) || ''}
              </Typography>
              {card.isMainCard && (
                <Chip
                  styledWrapper={styles.chipPrimary}
                  label='Primary Card'
                />
              )}
            </TableCell>
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellDepartment }}
            >
              <Typography>{card.department}</Typography>
            </TableCell>
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellStatus }}
            >
              <Badge item={card} />
            </TableCell>
            <TableCell
              component='div'
              scope='row'
              classes={{ root: styles.cellActive }}
            >
              <Button
                variant='text'
                className={styles.btnView}
                onClick={() =>
                  handleNavigateToCard(card.financialAccounts[0]?.id, card.id)
                }
              >
                View Details
              </Button>
            </TableCell>
          </TableRow>
        </TransitionAnimation>
      ))
    ) : (
      <div className={styles.wrapperEmpty}>
        <EmptyItem />
      </div>
    );

  return (
    <TransitionAnimation>
      <div className={styles.wrapper}>
        <div className={styles.boxFiler}>
          <FilterControl
            isLoading={cardsIsLoading}
            searchCardByFunctionality={searchCardByFunctionality}
            setSearchCardByFunctionality={setSearchCardByFunctionality}
            filterDrawerBool={filterDrawerBool}
          />
          {canOpenCard && (
            <Button
              variant='text'
              onClick={() => setSearchParams({ mode: 'openCard' })}
            >
              + Open new card
            </Button>
          )}
        </div>
        {cardsIsLoading ? (
          <div className={styles.loader}>
            <Skeleton
              width='100%'
              height='250px'
            />
          </div>
        ) : (
          <Table
            classes={{ root: styles.table }}
            component='div'
          >
            {!isMobile && (
              <TableHead
                component='div'
                classes={{ root: styles.tableHeader }}
              >
                <TableRow
                  component='div'
                  classes={{ root: styles.tableHeaderRow }}
                >
                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header.label}
                      scope='row'
                      component='div'
                      classes={{
                        root: cn(
                          styles[header.className],
                          styles.tableHeaderRowCell
                        ),
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody
              classes={{ root: styles.tableBody }}
              component='div'
            >
              {renderTableBody()}
            </TableBody>
          </Table>
        )}
      </div>
    </TransitionAnimation>
  );
};
