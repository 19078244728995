import { useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { replaceNumberWithComma } from 'shared/utils/price';
import { useBoolean } from 'shared/hooks/useBoolean';
import styles from './style.module.scss';
import { Option } from 'api/endpoints/loans';
import { ChevronRightIcon } from 'shared/ui/icons';
import DrawerModal from 'shared/components/drawerModal';
import { FormFundingApplication } from './components/formFundingApplication';

interface GotLoanOfferProps {
  options: Option[];
}

export const GotLoanOffer = ({ options }: GotLoanOfferProps) => {
  const isShowDrawerBool = useBoolean();
  const [selectedOption, setSelectOption] = useState<Option | null>(null);

  const renderOptions = () => {
    return options.map((item, index) => (
      <Button
        key={index}
        endIcon={<ChevronRightIcon />}
        className={styles.btnOptions}
        onClick={() => {
          setSelectOption(item);
          isShowDrawerBool.setTrue();
        }}
      >
        <Typography className={styles.boxOptionsLabel}>
          ${replaceNumberWithComma(item.amount)}
        </Typography>
      </Button>
    ));
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography className={styles.title}>
          You’ve got a funding offer!
        </Typography>
        <div className={styles.boxOptions}>
          <Typography className={styles.boxOptionsTitle}>
            You’ve been preselected for an exclusive business funding
            opportunity!
            <br />
            <br />
            Take advantage of competitive interest rates:
          </Typography>
          {renderOptions()}
        </div>
      </div>
      <DrawerModal
        onClose={() => isShowDrawerBool.setFalse()}
        isShow={isShowDrawerBool.value}
        titleText='Request funding application'
      >
        <FormFundingApplication
          options={options}
          selectedOption={selectedOption}
        />
      </DrawerModal>
    </TransitionAnimation>
  );
};
