import * as yup from 'yup';
import { Dayjs } from 'dayjs';

export const FromConnectedAccountSchema = yup.object().shape({
  from: yup.string().required(),
  to: yup.string().required(),
  amount: yup
    .string()
    .required('Amount is a required field')
    .test({
      name: 'is-non-zero',
      message: 'Float value must not be zero',
      test: (value) => Number(value) !== 0,
    }),
  frequency: yup.string(),
  dateTransfer: yup.mixed<Dayjs>().nullable(),
  memo: yup.string().max(1024, 'Memo must be at most 1024 characters'),
});
