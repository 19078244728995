import {
  FormControl,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { useRewards } from "pages/reward/provider/useRewards";
import { useRewardsTypesForFilter } from "api/hooksApi/useRewardsTypesForFilter";
import { Skeleton } from "components/skeleton";
const RewardFilterType = () => {
  const { t } = useTranslationProvider();
  const { rewardHistoryType, setRewardsHistoryType } = useRewards();

  const { rewardsTypesForFilter, rewardsTypesForFilterIsLoading } =
    useRewardsTypesForFilter();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedType = rewardsTypesForFilter.find(
      (item) => item.id === selectedId
    );
    if (selectedType) {
      setRewardsHistoryType(selectedType);
    }
  };
  return rewardsTypesForFilterIsLoading ? (
    <Skeleton width="100%" height="56px" />
  ) : (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {t("Rewards.Transaction type", {
          defaultValue: "Transaction type",
        })}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t("Rewards.Transaction type", {
          defaultValue: "Transaction type",
        })}
        value={rewardHistoryType.id.toString()}
        onChange={handleChange}
      >
        {rewardsTypesForFilter?.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default RewardFilterType;
