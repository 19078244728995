import {
  PersonalSignUpScreen,
  SignUpPersonalProvider,
  useSignUpPersonal,
} from './provider';
import { PersonalSignUpBaseInfo } from './baseInfo';
import { PersonalSignUpConfirm } from './confirm';
import { SignUpLoginAndPass } from '../../shared/signUp/loginAndPass';
import { LoginLayoutRender } from '../../layouts/LoginLayout';
import { SignUpApplicationStatus } from '../../shared/signUp/applicationStatus';

const ResolveScreen = () => {
  const { screen, updateContextForm, setScreen, contextForm } =
    useSignUpPersonal();

  if (screen === PersonalSignUpScreen.loginAndPass) {
    return (
      <LoginLayoutRender>
        <SignUpLoginAndPass
          initialValues={{
            login: contextForm.login,
            password: contextForm.password,
            passwordConfirm: contextForm.passwordConfirm,
          }}
          onSubmit={(values) => {
            updateContextForm(values);
            setScreen(PersonalSignUpScreen.baseInfo);
          }}
        />
      </LoginLayoutRender>
    );
  }

  if (screen === PersonalSignUpScreen.baseInfo) {
    return <PersonalSignUpBaseInfo />;
  }

  if (screen === PersonalSignUpScreen.confirm) {
    return <PersonalSignUpConfirm />;
  }

  if (screen === PersonalSignUpScreen.applicationStatus) {
    return (
      <SignUpApplicationStatus
        isConsumerMode={true}
        title='Sign up complete!'
        subtitle='We need some time to check the details. Application review usually
            takes approximately 1-2 business days. You will get a notification
            to your email address, when it’s done. In case of any questions,
            feel free to contact us.'
        steps={[
          { title: 'Application started', isPassed: true },
          { title: 'Under review', isPassed: true },
          { title: 'Account ready', isPassed: false },
        ]}
      />
    );
  }

  return null;
};

export const SignUpPersonal = () => (
  <SignUpPersonalProvider>
    <ResolveScreen />
  </SignUpPersonalProvider>
);
