import { FC } from "react";
import LimitItem from "../LimitItem";
import LimitSwitchItem from "../LimitSwitchItem";
import AccountButtons from "components/button/AccountButtons";
import BeatLoader from "react-spinners/BeatLoader";
import RightIcon from "assets/svg/chevron-right5.svg";
import styles from "../../style.module.scss";
import { useCurrentUser } from "providers/user.provider";
import SimpleContainer from "shared/components/containers/simpleContainer";
import { useNavigate, useParams } from "react-router-dom";
import SuccessModal from "components/modals/succesModal";
import WarningModal from "components/modals/WarningModal";

interface LimitComponentProps {
  handleNavigateToMerchantCategories: () => void;
  handleNavigateToCountries: () => void;
  setEnabledLimitTransaction: (boolean) => void;
  setAmountLimit: (value: string) => void;
  setEnabledLimitMonth: (boolean) => void;
  setAmountLimitMonthly: (value: string) => void;
  onSave: () => void;
  detachAllLimits: () => void;
  setIsShowSuccess: (boolean) => void;
  setIsShowWarning: (boolean) => void;
  enabledLimitTransaction: boolean;
  amountLimit: string;
  enabledLimitMonth: boolean;
  amountLimitMonthly: string;
  isLoading: boolean;
  isShowSuccess: boolean;
  isShowWarning: boolean;
}

const LimitComponent: FC<LimitComponentProps> = ({
  handleNavigateToMerchantCategories,
  handleNavigateToCountries,
  setEnabledLimitTransaction,
  setAmountLimit,
  setEnabledLimitMonth,
  setAmountLimitMonthly,
  onSave,
  detachAllLimits,
  setIsShowSuccess,
  setIsShowWarning,
  enabledLimitTransaction,
  amountLimit,
  enabledLimitMonth,
  amountLimitMonthly,
  isLoading,
  isShowSuccess,
  isShowWarning,
}) => {
  const { isSubUser, subUserRights } = useCurrentUser();
  const params = useParams();
  const navigate = useNavigate();
  const accessEditLimits = subUserRights({ mainAccess: true });

  const handleToggleLimitTransaction = () => {
    if (enabledLimitTransaction) {
      setAmountLimit("0");
    }
    setEnabledLimitTransaction(!enabledLimitTransaction);
  };
  const handleToggleLimitCalendarMonth = () => {
    if (enabledLimitMonth) {
      setAmountLimitMonthly("0");
    }
    setEnabledLimitMonth(!enabledLimitMonth);
  };
  return (
    <SimpleContainer
      title="Limits"
      onTitleClick={() => navigate(`/card-transactions/${params.cardId}`)}
    >
      <LimitItem
        title="Merchant category"
        icon={RightIcon}
        onClick={handleNavigateToMerchantCategories}
      />
      <LimitItem
        title="Country"
        icon={RightIcon}
        onClick={handleNavigateToCountries}
      />

      <LimitSwitchItem
        title="Max amount for  transaction:"
        onToggle={handleToggleLimitTransaction}
        enabled={enabledLimitTransaction}
        value={amountLimit}
        onChange={(e) => {
          setAmountLimit(e.target.value);
        }}
        disabled={accessEditLimits || isSubUser}
        isLoading={isLoading}
      />

      <LimitSwitchItem
        title="Max amount for calendar month:"
        onToggle={handleToggleLimitCalendarMonth}
        enabled={enabledLimitMonth}
        value={amountLimitMonthly}
        onChange={(e) => {
          setAmountLimitMonthly(e.target.value);
        }}
        disabled={accessEditLimits || isSubUser}
        isLoading={isLoading}
      />

      {!accessEditLimits && (
        <div className={styles.limitButtons}>
          <AccountButtons
            disabled={isLoading || isSubUser}
            name={isLoading ? "" : "Save changes"}
            handleSubmit={onSave}
            width={285}
          >
            {isLoading && <BeatLoader size={10} color="#939598" />}
          </AccountButtons>
          <AccountButtons
            disabled={isLoading || isSubUser}
            name={isLoading ? "" : "Remove all limits"}
            handleSubmit={detachAllLimits}
            width={285}
            secondary
          >
            {isLoading && <BeatLoader size={10} color="#939598" />}
          </AccountButtons>
        </div>
      )}
      <SuccessModal
        titleMassage={"Changes saved"}
        onClose={() => setIsShowSuccess(false)}
        isOpen={isShowSuccess}
      />
      <WarningModal
        titleMassage={"Something went wrong"}
        onClose={() => setIsShowWarning(false)}
        isOpen={isShowWarning}
      />
    </SimpleContainer>
  );
};
export default LimitComponent;
