import { Box, Chip, Typography } from "@mui/material";
import suitcaseIcon from 'assets/svg/suitcase-blue.svg';

interface AccountInfo {
  name: string;
  last4: string;
  provider: string;
}

export const AccountInfo = ({ name, last4, provider }: AccountInfo) => {
  return (
    <Box display="flex" alignItems="center">
      <img src={suitcaseIcon} />
      <Box ml={2}>
        <Typography variant="body1">
          {name}  
          <Typography variant="body1" color="text.secondary" component="span" sx={{ ml: 0.5 }}>
            ({last4})
          </Typography>
        </Typography>
        {provider && <Chip label={provider} />}
      </Box>
    </Box>
  )
}