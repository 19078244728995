import { useMemo } from 'react';
import {
  useSubUsersControllerQuery,
  SubUsersApi,
} from '../endpoints/subProfiles';
import { useFinancialAccounts } from './useFinancialAccounts';

export interface SubUserReturn {
  subUser: SubUsersApi;
  subUserIsLoading: boolean;
  subUsersIsSuccess: boolean;
}

export const useSubUser = (subUserId: string): SubUserReturn => {
  //TODO skip not working

  const {
    data: subUsers,
    isLoading: subUsersIsLoading,
    isSuccess: subUsersIsSuccess,
  } = useSubUsersControllerQuery(undefined, { skip: !subUserId });

  const {
    financialAccounts,
    financialAccountsIsLoading,
    financialAccountsIsSuccess,
  } = useFinancialAccounts();

  const value = useMemo<any>(() => {
    if (!subUsers) {
      return {};
    }

    if (subUsers && subUserId && financialAccounts) {
      const subUser = subUsers.find((item) => item.id === Number(subUserId));

      const subUserFinancialAccount = financialAccounts.find(
        (item) => item.financialAccountId === subUser?.financialAccountId
      );

      const updatedSubUser = {
        ...subUser,
        financialName: subUserFinancialAccount?.name,
      };

      return updatedSubUser;
    }
  }, [subUsers, subUserId, financialAccounts]);

  return {
    subUser: value,
    subUserIsLoading: subUsersIsLoading || financialAccountsIsLoading,
    subUsersIsSuccess: subUsersIsSuccess && financialAccountsIsSuccess,
  };
};
