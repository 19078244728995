import { FC } from 'react';
import { Skeleton } from 'components/skeleton';
import styles from './style.module.scss';
import { Divider, List } from '@mui/material';
import { AccountsItem } from '../ConnectedAccount';
import ConnectedAccountItem from '../ConnectedAccountItem';

interface ConnectedAccountListProps {
  arr: AccountsItem[];
  isLoading: boolean;
  handleDelete?: (id: number) => Promise<void>;
  handleSumbit?: (id: number) => void;
  approveButtonText?: string;
  cancelButtonText?: string;
  emptyArrText?: string;
  buttonAppropveContained?: boolean;
  cancelButtonType?: 'outlined' | 'contained';
}

const ConnectedAccountList: FC<ConnectedAccountListProps> = ({
  arr,
  isLoading,
  handleDelete,
  handleSumbit,
  approveButtonText,
  cancelButtonText,
  cancelButtonType,
  emptyArrText,
  buttonAppropveContained,
}) => {
  const renderItems = () => {
    if (!!arr?.length) {
      return (
        <List
          disablePadding
          className={styles.listWrapper}
        >
          {arr?.map((item, index) => (
            <div key={index}>
              <ConnectedAccountItem
                item={item}
                key={item.id}
                handleDelete={handleDelete}
                handleSumbit={handleSumbit}
                approveButtonText={approveButtonText}
                cancelButtonText={cancelButtonText}
                cancelButtonType={cancelButtonType}
                buttonAppropveContained={buttonAppropveContained}
              />
              {index < arr.length - 1 && <Divider />}
            </div>
          ))}
        </List>
      );
    } else {
      return <p className={styles.empyText}>{emptyArrText}</p>;
    }
  };
  return (
    <>
      {isLoading ? (
        <Skeleton
          width='581px'
          height='92px'
        />
      ) : (
        renderItems()
      )}
    </>
  );
};

export default ConnectedAccountList;
