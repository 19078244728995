import { useBoolean } from 'shared/hooks/useBoolean';
import { Typography } from 'shared/ui/typography';
import cn from 'classnames';
import styles from './styles.module.scss';
import { CSSTransition } from 'react-transition-group';
interface ReadMoreProps {
  text: string;
  maxLength: number;
  styledText?: string;
  styledReadMore?: string;
  showLabel?: string;
  hideLabel?: string;
}

export const ReadMore = ({
  text,
  maxLength,
  styledText,
  styledReadMore,
  showLabel = 'Read less',
  hideLabel = 'Read more',
}: ReadMoreProps) => {
  const expandedBool = useBoolean();
  const isTextLong = text.length > maxLength;

  const previewText = isTextLong
    ? text.substring(0, maxLength) + (expandedBool.value ? '' : '...')
    : text;

  return (
    <Typography className={cn(styledText, styles.text)}>
      {previewText}
      {isTextLong && (
        <>
          <CSSTransition
            in={expandedBool.value}
            timeout={{ enter: 300, exit: 150 }}
            classNames='fade'
            unmountOnExit
          >
            <span className={styles.additionalText}>
              {text.substring(maxLength)}
            </span>
          </CSSTransition>
          <span
            className={cn(styledReadMore, styles.readMore)}
            onClick={(e) => {
              e.stopPropagation();
              expandedBool.toggle();
            }}
          >
            {expandedBool.value ? showLabel : hideLabel}
          </span>
        </>
      )}
    </Typography>
  );
};
