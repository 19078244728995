import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cn from 'classnames';
import { RewardIntegrationWithType } from '../../../../../../../../api/hooksApi/useRewardsIntegrations';
import { useBoolean } from '../../../../../../../../shared/hooks/useBoolean';
import { CircularProgress } from '@mui/material';

interface SliderItemProps {
  item: RewardIntegrationWithType;
  onClick: (item: RewardIntegrationWithType) => Promise<void>;
  styleItem?: {
    wrapper?: string;
    item?: string;
  };
}

export const SliderItem = ({ item, onClick, styleItem }: SliderItemProps) => {
  const loadBool = useBoolean();
  const btnLabel = () => {
    return item.joinedType?.name || 'learn more';
  };

  const handle = async (item: RewardIntegrationWithType) => {
    loadBool.setTrue();
    await onClick(item).finally(loadBool.setFalse);
  };

  return (
    <div className={cn(styleItem?.wrapper, styles.container)}>
      <div className={cn(styleItem?.item, styles.box)}>
        <Typography className={styles.textName}>{item.name}</Typography>
        <Typography className={styles.textDescription}>
          {item.description}
        </Typography>
        <Button
          variant='text'
          className={styles.btn}
          onClick={() => handle(item)}
          disabled={loadBool.value}
        >
          {btnLabel()} <ArrowForwardIosIcon />
        </Button>
      </div>
      {item?.imageUrls[1]?.filePath && (
        <img
          src={item.imageUrls[1].filePath}
          alt={item.name}
          className={styles.img}
        />
      )}
      {loadBool.value && <CircularProgress className={styles.progress} />}
    </div>
  );
};
