import ReactDOM from "react-dom";
import { requireNotNull } from "../../shared/utils/require-not-null";

export const showAppLoader = () => {
  const loader = requireNotNull(document.getElementById("app-loader"));
  loader.style.display = "flex";
  loader.style.pointerEvents = "initial";
};

export const hideAppLoader = () => {
  const loader = requireNotNull(document.getElementById("app-loader"));
  loader.style.display = "none";
  loader.style.pointerEvents = "none";
};

interface AppLoaderProps {
  isVisible?: boolean;
}

export const AppLoader = (props: AppLoaderProps) => {
  return ReactDOM.createPortal(
    <div
      id="app-loader"
      style={{ display: props.isVisible ? 'flex' : "none", pointerEvents: "none" }}
      className="fixed top-0 left-0 w-full h-full flex-col justify-center items-center bg-white z-10"
    >
      <div className="pb-4">Please, wait...</div>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>,
    requireNotNull(document.getElementById("app-layouts")),
  );
};
