import * as yup from 'yup'
import { GiftCardMethod } from '../types'
import { FinancialAccount } from 'api/endpoints/financialAccounts'

const fromFinacialAccountSchema = (
  financialAccounts: FinancialAccount[],
  limit: number
) => {
  return yup.object().shape({
    from: yup
      .string()
      .test(
        'limit-check',
        'Insufficient funds in the account. Please select a different payment method.',
        (value) => {
          const financialAccount = financialAccounts.find(
            (account) => account.financialAccountId === value
          )

          if (
            financialAccount &&
            financialAccount.availableCash.value < limit
          ) {
            return false
          } else {
            return true
          }
        }
      ),
  })
}

const pointSchema = (point: number, limit: number) => {
  return yup.object().shape({
    point: yup
      .string()
      .test(
        'limit-check',
        'Insufficient points. Please select a different payment method.',
        () => {
          if (point < limit) {
            return false
          } else {
            return true
          }
        }
      ),
  })
}

export const createPaymentMethodSchema = (
  financialAccounts: FinancialAccount[],
  point: number,
  limit: number,
  type: GiftCardMethod
) => {
  const baseSchemaShape = {
    paymentMethod: yup.string().required(),
  }

  const specificSchema =
    type === GiftCardMethod.PayUsingYourFinancialAccount
      ? fromFinacialAccountSchema(financialAccounts, limit)
      : pointSchema(point, limit)

  return yup.object().shape({
    ...baseSchemaShape,
    ...specificSchema.fields,
  })
}
