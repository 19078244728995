import {
  Table as TableMUI,
  TableContainer as TableContainerMUI,
  Collapse as CollapseMUI,
  useMediaQuery,
  IconButton,
} from '@mui/material'
import styles from './styles.module.scss'
import { TableTitle } from './components/tableTitle'
import { TableBody } from './components/tableBody'
import { TableHeader } from './components/tableHeader'
import cn from 'classnames'
import { GiftCardData, TypeGiftCards } from '../../types'
import { ClockRestoreIcon, GiftCardIcon } from 'shared/ui/icons'
import { EmptyPayees } from 'entities/transfers'
import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/buttons'
import { Badge } from 'shared/ui/badge'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useGiftCards } from 'pages/reward/provider/useGiftCards'
import { useBoolean } from 'shared/hooks/useBoolean'
interface TableProps {
  data: GiftCardData[]
  typeTable: TypeGiftCards
  stylesWrapperContainer?: any
}

export const emptyCards = {
  [TypeGiftCards.Active]: {
    title: 'You don’t have any gift cards',
    subTitle: 'Your gift cards will be displayed here once added.',
    icon: <GiftCardIcon />,
  },
  [TypeGiftCards.Archive]: {
    title: 'You don’t have any archived cards',
    subTitle: 'Your archived cards will be displayed here.',
    icon: <ClockRestoreIcon />,
  },
}

const headerGiftCardsActive = [
  'Card',
  'Date of activation',
  'Amount',
  'See details',
  'Archivate card',
]
const headerGiftCardsArchive = [
  'Card',
  'Date of activation',
  'Amount',
  'Restore card',
]

export const Table = (props: TableProps) => {
  const {
    data,
    typeTable,

    stylesWrapperContainer,
  } = props
  const { showDrawerGiftCardBool, giftCardsArchiveData } = useGiftCards()

  const isMobile = useMediaQuery('(max-width: 600px)')
  const collapseArchivedBool = useBoolean()
  const isGiftCardsActive = typeTable === TypeGiftCards.Active

  const shouldRenderTableHeader = Boolean(data?.length) && !isMobile
  const shouldRenderEmptyMobile = !data?.length && isMobile

  const renderTitleTable = (
    <div className={styles.titleTable}>
      {!isMobile && (
        <Typography className={styles.title}>Your gift cards</Typography>
      )}
      <Button
        variant='text'
        onClick={() => showDrawerGiftCardBool.setTrue()}
      >
        + buy new gift card
      </Button>
    </div>
  )

  const renderTitleTableArchived = (
    <div className={cn(styles.archived, styles.titleTable)}>
      <div className={styles.archivedBoxTitle}>
        <Typography className={styles.title}>Archived cards</Typography>
        {!!giftCardsArchiveData?.length && (
          <Badge
            styledWrapper={styles.badge}
            styledLabel={styles.badgeLabel}
            label={`${giftCardsArchiveData.length} card(s) archived`}
            startIcon={<ClockRestoreIcon className={styles.badgeIcon} />}
          />
        )}
      </div>
      <IconButton
        aria-label='expand row'
        size='small'
        onClick={() => collapseArchivedBool.toggle()}
      >
        {collapseArchivedBool.value ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </IconButton>
    </div>
  )

  const renderEmptyState = () => (
    <div className={isGiftCardsActive ? styles.boxEmpty : ''}>
      <EmptyPayees
        icon={emptyCards[typeTable].icon}
        title={emptyCards[typeTable].title}
        subTitle={emptyCards[typeTable].subTitle}
        stylesWrapper={isGiftCardsActive && styles.boxEmptyItem}
      />
      {isGiftCardsActive && (
        <Button
          variant='text'
          onClick={() => showDrawerGiftCardBool.setTrue()}
        >
          + buy NEW gift CARD
        </Button>
      )}
    </div>
  )

  const renderTableContent = () => (
    <TableContainerMUI
      classes={{ root: cn(stylesWrapperContainer, styles.container) }}
      component='div'
    >
      <TableTitle //title={title}
        title={isGiftCardsActive ? renderTitleTable : renderTitleTableArchived}
      />
      <CollapseMUI
        in={isGiftCardsActive ? true : collapseArchivedBool.value}
        timeout='auto'
        unmountOnExit
      >
        <TableMUI aria-label='simple table'>
          {shouldRenderTableHeader && (
            <TableHeader
              header={
                isGiftCardsActive
                  ? headerGiftCardsActive
                  : headerGiftCardsArchive
              }
            />
          )}
          <TableBody
            data={data}
            typeTable={typeTable}
          />
        </TableMUI>
      </CollapseMUI>
    </TableContainerMUI>
  )

  return shouldRenderEmptyMobile ? renderEmptyState() : renderTableContent()
}
