import { useEffect } from "react";
import {
  LocalStorageKeys,
  removeLocalStorageObject,
} from "../../shared/utils/local-storage";

const INACTIVE_TIMEOUT = 10 * 60 * 1000;

export function useLogoutAfterTimeout() {
  useEffect(() => {
    let inactivityTimer: any = null;

    function resetInactivityTimer() {
      if (process.env.REACT_APP_DISABLE_AUTO_LOGOUT === "true") {
        return;
      }
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        removeLocalStorageObject(LocalStorageKeys.AuthToken);
        window.location.href = "/login";
      }, INACTIVE_TIMEOUT);
    }

    function handleActivity() {
      resetInactivityTimer();
    }

    resetInactivityTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("touchstart", handleActivity);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, []);
}
