import { PaymentCard } from 'api/endpoints/paymentCards';

export const filterCardsByFunctionality = (
  cards: PaymentCard[],
  searchCardByFunctionality: string
) =>
  cards.filter(
    (card) =>
      [
        card.last4,
        card.cardholder,
        card.subProfile,
        card.department,
        card.cardName,
      ]?.some(
        (field) =>
          field?.toLowerCase().includes(searchCardByFunctionality.toLowerCase())
      ) ||
      card.financialAccounts?.some(
        (account) =>
          account?.name
            ?.toLowerCase()
            .includes(searchCardByFunctionality.toLowerCase())
      )
  );
