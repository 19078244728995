import { FC } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import ButtonChevronRigth from "../../../../buttonChevronRigth";
import styles from "./style.module.scss";
import { ItemArr } from "../..";

interface ListDividerProps {
  tabsArr: ItemArr[];
  page: string | null;
  handleNavigate: (id: string) => void;
}
const ListDividerWithContainer: FC<ListDividerProps> = ({
  tabsArr,
  page,
  handleNavigate,
}) => {
  const renderItem = () => {
    return tabsArr?.map((i, index) => (
      <div key={i.id}>
        <ListItemButton
          onClick={() => handleNavigate(i.id)}
          sx={{
            padding: 0,
          }}
        >
          <ButtonChevronRigth label={i.label} img={i.img} />
        </ListItemButton>
        {index < tabsArr.length - 1 && <Divider />}
      </div>
    ));
  };
  if (page) {
    return <></>;
  }
  return (
    <div>
      <List disablePadding className={styles.container}>
        {renderItem()}
      </List>
    </div>
  );
};
export default ListDividerWithContainer;
