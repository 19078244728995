import { useMemo } from "react";
import { useSubUsersAvailableFinancialAccountsControllerQuery } from "../endpoints/subProfiles";
import {
  useFinancialAccountsControllerQuery,
  FinancialAccount,
} from "../endpoints/financialAccounts";

export interface SubUsersAttachedFinancialAccountsReturn {
  subUserAttachedFinancialAccount: FinancialAccount[];
  subUserAttachedFinancialAccountIsLoading: boolean;
  subUserAttachedFinAccRefetch: () => void;
}

export const useSubUsersAttachedFinancialAccounts = (
  subUserId: string
): SubUsersAttachedFinancialAccountsReturn => {
  const {
    data: subUsersAttachedAccounts,
    isLoading: subUsersAttachedAccountsIsLoading,
    refetch: subUsersAvailableFinancialAccountsRefetch,
  } = useSubUsersAvailableFinancialAccountsControllerQuery(
    { subUserId },
    { skip: !subUserId }
  );

  const {
    data: financialAccountsData,
    isLoading: finAccountsIsLoading,
    refetch: finAccountsRefetch,
  } = useFinancialAccountsControllerQuery({});

  const subUserAttachedFinAccRefetch = () => {
    subUsersAvailableFinancialAccountsRefetch();
    finAccountsRefetch();
  };

  const financialAccounts = financialAccountsData?.financialAccounts;

  const value = useMemo(() => {
    if (!subUsersAttachedAccounts || !financialAccounts) {
      return [];
    }

    const subUserAttachedFinancialAccount = financialAccounts?.filter((fa) =>
      subUsersAttachedAccounts.some(
        (subUserAcc) => fa.financialAccountId === subUserAcc.id
      )
    );

    return subUserAttachedFinancialAccount || [];
  }, [subUsersAttachedAccounts, financialAccountsData, subUserId]);

  return {
    subUserAttachedFinancialAccount: value,
    subUserAttachedFinancialAccountIsLoading:
      finAccountsIsLoading || subUsersAttachedAccountsIsLoading,
    subUserAttachedFinAccRefetch,
  };
};
