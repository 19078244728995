import { Typography } from 'shared/ui/typography';
import { DotIcon, WarningAmberOutlinedIcon } from 'shared/ui/icons';
import { GetNotificationsApi } from 'api/endpoints/notifications';
import { NotificationsLevel } from 'enums';
import moment from 'moment';
import styles from './style.module.scss';
import { ReadMore } from 'shared/ui/expand/readMore';
import cn from 'classnames';
interface ContentItemProps {
  item: GetNotificationsApi;
  handleReadMessage: (id: number) => Promise<void>;
  disabled: boolean;
}

export const ContentItem = ({
  item,
  handleReadMessage,
  disabled,
}: ContentItemProps) => {
  return (
    <div
      className={cn(styles.wrapper, { [styles.disabled]: disabled })}
      onClick={() => handleReadMessage(item.id)}
    >
      <div className={styles.boxTitle}>
        {!item.dateRead && <DotIcon className={styles.boxTitleDots} />}
        {item.level === NotificationsLevel.Warning && (
          <WarningAmberOutlinedIcon />
        )}
        <Typography>{item.subject}</Typography>
      </div>
      <ReadMore
        text={item.body || ''}
        maxLength={90}
        styledText={styles.subTitle}
      />
      <Typography className={styles.createdDate}>
        {moment(item.createdDate).format('DD MMM YYYY hh:mm A')}
      </Typography>
    </div>
  );
};
