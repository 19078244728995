import { AnimateWrapper } from "components/animate-wrapper";
import moment from "moment";
import { useExternalAccountById } from "api/hooksApi/useExternalAccountById";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Typography } from "shared/ui/typography";
import { formatAccountName } from "shared/lib/format";
import CopyButton from 'shared/components/CopyButton';

import styles from "./style.module.scss";

const AccountDetails = () => {
  const { t } = useTranslationProvider();
  const { account } = useExternalAccountById();

  const items = [
    {
      hasValue: Boolean(account?.externalBankAccountDetails.accountNumber),
      label: t('AccountRoutingNumber.Account number', {
        defaultValue: 'Account number',
      }),
      value: (
        <>
          {account?.externalBankAccountDetails.accountNumber}
          <CopyButton textToCopy={account?.externalBankAccountDetails.accountNumber} />
        </>
      ),
    },
    {
      hasValue: Boolean(account?.externalBankAccountDetails.routingNumber),
      label: t('common.Routing number', {
        defaultValue: 'Routing number',
      }),
      value: (
        <>
          {account?.externalBankAccountDetails.routingNumber}
          <CopyButton textToCopy={account?.externalBankAccountDetails.routingNumber} />
        </>
      ),
    },
    {
      hasValue: Boolean(account?.owners?.[0]?.names[0]),
      label: t('AccountRoutingNumber.Account holder', {
        defaultValue: 'Account holder',
      }),
      value: (
        <>
          {account?.owners?.[0].names[0]}
          <CopyButton textToCopy={account?.owners?.[0].names[0]} />
        </>
      ),
    },
    {
      hasValue: Boolean(account?.balanceInfo?.currency),
      label: t('common.Currency', {
        defaultValue: 'Currency',
      }),
      value: account?.balanceInfo?.currency,
    },
    {
      hasValue: Boolean(account?.name),
      label: t('common.Name', {
        defaultValue: 'Name',
      }),
      value: (
        <>
          {formatAccountName(account?.name ?? '')}
          <CopyButton textToCopy={account?.name} />
        </>
      ),
    },
    {
      hasValue: Boolean(account?.createdAt),
      label: t('AccountInfo.Opened on', {
        defaultValue: 'Opened on',
      }),
      value: moment(account?.createdAt ?? '').format('MM/DD/YYYY'),
    },
  ];

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {items.map((item) => {
          if (!item.hasValue) {
            return null;
          }

          return (
            <div key={item.label} className={styles.detailsItem}>
              <Typography className={styles.detailsItem_label}>{item.label}</Typography>
              <div className={styles.detailsItem_value}>{item.value}</div>
            </div>
          )
        })}
      </div>
    </AnimateWrapper>
  );
};

export default AccountDetails;
