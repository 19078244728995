import ArrowLeft from "assets/svg/arrow-left-gray.svg";
import { useSaveNewPasswordControllerMutation } from "api/endpoints/account";
import helper from "services/helper";
import { logOut } from "../../../../../api/logOut";
import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Typography } from "shared/ui/typography";
import { useFormik } from "formik";
import { TransitionAnimation } from "shared/ui/animations";
import { useSnackBar } from "providers/snackBar.provider";
import { ChangePasswordSchema } from "./validationChangePasswordSchema";
import { ChangePasswordForm, FormValues } from "./components/changePasswordForm";


const errorIncorrectCurrentPassword= `Fields 'Password' and 'Confirm Password' do not match`

export const ChangePassword = () => {
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [, setSearchParams] = useSearchParams();

  const [newPasswordMutation, { isLoading: newPasswordIsLoading }] =
    useSaveNewPasswordControllerMutation();


  const handleSubmit = async (values: FormValues) => {
    const {currentPassword, newPassword, confirmNewPassword} = values
    try {
      await newPasswordMutation({
        currentPassword: currentPassword,
        password: newPassword,
        passwordConfirm: confirmNewPassword,
      }).unwrap();
      setSnackBar({
        type: 'success',
        message: t('Login.Password changed Please login again', {
          defaultValue: 'Password changed! Please login again.',
        }),
        isShow: true,
      });

      logOut();
    } catch (e: any) {
      console.log('e',e)
      if(e.data === errorIncorrectCurrentPassword){
        setSnackBar({
          type: 'error',
          message: 'Incorrect current password',
          isShow: true,
        });
      }else{
        setSnackBar({
          type: 'error',
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
      }
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  });

  return (
    <TransitionAnimation>
      <div className={styles.container} >
        {isMobile && (
          <div className={styles.container_nav}>
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
            <Typography className={styles.container_nav_title}>
              {t('Login.Change Password', {
                defaultValue: 'Change Password',
              })}
            </Typography>
          </div>
        )}
        <ChangePasswordForm
          formik={formik}
          loading={newPasswordIsLoading}
        />
      </div>
    </TransitionAnimation>
  );
};
