import React, { FC } from "react";
import styles from "../style.module.scss";

interface Props {
  title: string;
  icon: any;
  onClick: (e: any) => void;
}

const LimitItem: FC<Props> = ({ icon, title, onClick }) => {
  return (
    <div onClick={onClick} className={styles.limitItem}>
      <p className={styles.limitItem_title}>{title}</p>
      <img src={icon} width="24px" />
    </div>
  );
};

export default LimitItem;
