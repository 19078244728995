import {
  AnotherOwnerForm,
  BusinessSignUpScreen,
  useSignUpBusiness,
} from '../provider';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { SignUpBusinessProgressBar } from '../progressBar';
import styles from '../primaryAuthorizedPerson/style.module.scss';
import { SignUpPersonForm } from '../../../shared/signUp/personForm';
import { SignUpAddressForm } from '../../../shared/signUp/addressForm';
import { PercentageField } from '../../../shared/signUp/percentageField';
import { Button } from '../../../shared/ui/buttons';
import {
  signUpAddressInitialForm,
  signUpAddressValidateSchema,
  signUpPersonalBaseInitialForm,
  signUpPersonValidationSchema,
  SignUpQsTypes,
} from '../../../shared/signUp/shared';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useScrollToMuiFieldAfterError } from '../../../shared/hooks/useScrollToMuiFieldAfterError';

interface OwnerFormProps {
  ownerNumber: number;

  onBack(): void;

  onSubmit(values: AnotherOwnerForm): void;

  initialValues?: AnotherOwnerForm;
}

const validationSchema = yup
  .object({
    ownershipPercentage: yup
      .string()
      .required('Percentage required field')
      .test(
        'test-ownership-25-and-100',
        'Ownership percentage must be between 25-100%',
        (value) => {
          const number = Number(value);
          return !isNaN(number) && number >= 25;
        }
      ),
  })
  .concat(signUpPersonValidationSchema)
  .concat(signUpAddressValidateSchema);

const OwnerForm = ({
  ownerNumber,
  onBack,
  onSubmit,
  initialValues,
}: OwnerFormProps) => {
  const formik = useFormik<AnotherOwnerForm>({
    validationSchema,
    validateOnChange: false,
    initialValues: initialValues || {
      ...signUpPersonalBaseInitialForm,
      ...signUpAddressInitialForm,
      ownershipPercentage: '',
    },
    onSubmit(values) {
      onSubmit(values);
    },
  });

  useScrollToMuiFieldAfterError(formik.isSubmitting);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.formWrapper}
    >
      <SignUpFormsLayout.SectionTitle>
        Primary owner #{ownerNumber}
      </SignUpFormsLayout.SectionTitle>
      <SignUpPersonForm formikInstance={formik} />

      <SignUpFormsLayout.SectionTitle>Address</SignUpFormsLayout.SectionTitle>
      <SignUpAddressForm formikInstance={formik} />

      <SignUpFormsLayout.SectionTitle>
        Other info
      </SignUpFormsLayout.SectionTitle>
      <PercentageField formikInstance={formik} />

      <SignUpFormsLayout.FormControls>
        <Button
          onClick={onBack}
          type='button'
          variant='text'
        >
          Back
        </Button>
        <Button
          type='submit'
          variant='contained'
        >
          Next
        </Button>
      </SignUpFormsLayout.FormControls>
    </form>
  );
};

export const BusinessSignUpAnotherOwners = () => {
  const { contextForm, updateContextForm, setScreen } = useSignUpBusiness();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentOwner, setCurrentOwner] = useState(() => {
    const fromQs = searchParams.get(SignUpQsTypes.ownerPosition);
    if (fromQs && !isNaN(Number(fromQs))) {
      const numberPos = Number(fromQs);
      if (
        numberPos !== 0 &&
        numberPos <= contextForm.owners.anotherPersonsCount
      ) {
        return numberPos;
      }
    }
    return 1;
  });

  useEffect(() => {
    setSearchParams((prev) => ({
      ...prev,
      [SignUpQsTypes.ownerPosition]: '',
    }));
  }, []);

  const handleBack = () => {
    if (currentOwner === 1) {
      setScreen(BusinessSignUpScreen.primaryOwner);
    } else {
      setCurrentOwner((cur) => cur - 1);
      window.scrollTo({ top: 0 });
    }
  };

  const handleSubmit = (values: AnotherOwnerForm) => {
    updateContextForm({
      owners: {
        ...contextForm.owners,
        [`owner${currentOwner}`]: values,
      },
    });
    if (currentOwner === contextForm.owners.anotherPersonsCount) {
      setScreen(BusinessSignUpScreen.confirm);
    } else {
      setCurrentOwner((cur) => cur + 1);
      window.scrollTo({ top: 0 });
    }
  };

  const getInitialValues = () => {
    return contextForm.owners[`owner${currentOwner}`] as AnotherOwnerForm;
  };

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>Owner information</SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Enter owner information bellow.
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar
          curScreen={BusinessSignUpScreen.primaryOwner}
        />
        <OwnerForm
          key={currentOwner}
          ownerNumber={currentOwner}
          onBack={handleBack}
          onSubmit={handleSubmit}
          initialValues={getInitialValues()}
        />
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
