import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import styled from "styled-components";

interface AccordionComponentProps {
  children: any;
  title: string;
  bage?: any;
}

const AccordionComponent = ({
  children,
  title,
  bage,
}: AccordionComponentProps) => {
  const [isOpen, srtIsOpen] = useState<boolean>(true);
  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    srtIsOpen(expanded);
  };
  return (
    <Accordion
      sx={{
        borderRadius: "10px",
        boxShadow: "none",
        overflow: "hidden",
      }}
      onChange={handleChange}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title>
          {title} {isOpen ? "" : bage}
        </Title>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;

const Title = styled("div")`
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 6px;
`;
