import { useMemo } from "react";
import { useLatest } from "./useLatest";
import _ from "lodash";

export const useDebounce = (cb, ms) => {
  const latestCb = useLatest(cb);

  return useMemo(
    () =>
      _.debounce((...args) => {
        latestCb.current(...args);
      }, ms),
    [ms, latestCb]
  );
};
