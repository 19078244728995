import { Popup } from 'components/popup';
import styles from './style.module.scss';
import { Typography } from 'shared/ui/typography';
import moment from 'moment';
import { Period } from 'api/endpoints/statements';
import { Button } from 'shared/ui/buttons';
import { FileFormat } from 'api/endpoints/statements';

interface OnceAccountModalProps {
  setConfirmPeriod: (date: Period | undefined) => void;
  onSubmit: (faormat: FileFormat) => void;
  isLoading: boolean;
  confirmPeriod: Period;
}

export const OneAccountModal = (props: OnceAccountModalProps) => {
  const { setConfirmPeriod, onSubmit, isLoading, confirmPeriod } = props;

  return (
    <Popup onClose={() => setConfirmPeriod(undefined)}>
      <div className={styles.container}>
        <Typography className={styles.title}>
          Choose your preferred statement download format
        </Typography>
        <div className={styles.boxSelected}>
          <Typography>Selected statement</Typography>
          <Typography>
            {moment(confirmPeriod.value).utc().format('MMM YYYY')}
          </Typography>
        </div>
        <div className={styles.boxBtn}>
          {confirmPeriod.fileFormats.includes(FileFormat.PDF) && (
            <Button
              onClick={() => onSubmit(FileFormat.PDF)}
              loading={isLoading}
            >
              DOWNLOAD PDF
            </Button>
          )}
          {confirmPeriod.fileFormats.includes(FileFormat.CSV) && (
            <Button
              onClick={() => onSubmit(FileFormat.CSV)}
              loading={isLoading}
            >
              DOWNLOAD CSV
            </Button>
          )}
        </div>
      </div>
      <div className={styles.boxCancel}>
        <Button
          variant='text'
          className={styles.btnCancel}
          onClick={() => setConfirmPeriod(undefined)}
        >
          Cancel
        </Button>
      </div>
    </Popup>
  );
};
