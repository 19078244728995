import { Typography } from 'shared/ui/typography';
import {
  Select,
  TextInput,
  CurrencyInput2,
  SelectItem,
} from 'shared/ui/inputs';
import { Divider, Checkbox, MenuItem, FormControlLabel } from '@mui/material';
import { InfoIcon } from 'shared/ui/icons';
import TooltipChildren from 'shared/components/toolTip/toolTipChildren';
import { FormikProps } from 'formik';
import { Payee } from 'api/hooksApi/useExternalPayees';
import styles from './styles.module.scss';

interface FormValues {
  from: string;
  to: string;
  amount: string;
  memo: string | undefined;
  minimumTopUpAmount: boolean;
  minAmount: string;
}

interface AutomationsFormProps {
  formik: FormikProps<FormValues>;
  optionsToAcc: SelectItem[];
  optionsFromAcc?: SelectItem[];
  payees?: Payee[];
  warningAmount: string;
  slotBtn: React.ReactNode;
  subTitle: string;
  toolTip?: string;
}

const tolltipText =
  'When your Netevia account balance falls below the minimum balance for replenishment, automatic replenishment initiates to restore it. Funds are then transferred from your connected account to maintain the specified balance.';

export const AutomationsForm = (props: AutomationsFormProps) => {
  const {
    formik,
    payees,
    optionsToAcc,
    optionsFromAcc,
    warningAmount,
    slotBtn,
    subTitle,
    toolTip,
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.boxText}>
        <Typography>Automate Netevia account top-up</Typography>
        <Typography>{subTitle}</Typography>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.wrapperForm}
      >
        <Select
          label='From'
          options={optionsFromAcc}
          {...formik.getFieldProps('from')}
        >
          {payees &&
            payees?.map((payee) => (
              <MenuItem
                key={payee.id}
                value={payee.id}
              >
                <Typography>
                  {payee.name} ({payee.last4})
                </Typography>
              </MenuItem>
            ))}
        </Select>
        <Select
          label='To'
          options={optionsToAcc}
          {...formik.getFieldProps('to')}
        />
        <Divider />
        <div className={styles.boxWarning}>
          <Typography>{warningAmount}</Typography>
          <TooltipChildren description={toolTip ? toolTip : tolltipText}>
            <div>
              <InfoIcon />
            </div>
          </TooltipChildren>
        </div>

        <CurrencyInput2
          fullWidth
          variant='outlined'
          label='Balance'
          placeholder='$0.00'
          name='amount'
          value={formik.values.amount}
          onChange={(value) => {
            formik.setFieldTouched('amount', true);
            formik.setFieldValue('amount', value);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.amount && formik.errors.amount}
          getCaretBoundary={() => [false]}
        />
        <FormControlLabel
          key='minimumTopUpAmount'
          classes={{ root: styles.rootFormControlLabel }}
          control={
            <Checkbox
              classes={{
                checked: styles.checked,
              }}
              name='minimumTopUpAmount'
              checked={formik.values.minimumTopUpAmount}
              onChange={(e) =>
                formik.setFieldValue('minimumTopUpAmount', e.target.checked)
              }
            />
          }
          label='Set minimum top up amount'
        />
        {formik.values.minimumTopUpAmount && (
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='Amount'
            placeholder='$0.00'
            name='minAmount'
            value={formik.values.minAmount}
            onChange={(value) => {
              formik.setFieldTouched('minAmount', true);
              formik.setFieldValue('minAmount', value);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.minAmount && formik.errors.minAmount}
            getCaretBoundary={() => [false]}
          />
        )}
        <TextInput
          label='Memo'
          autoComplete='off'
          error={formik.touched.memo && formik.errors.memo}
          {...formik.getFieldProps('memo')}
        />
        {slotBtn}
      </form>
    </div>
  );
};
