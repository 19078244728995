import React, { PropsWithChildren, useMemo } from 'react';
import { requireNotNull } from '../../shared/utils/require-not-null';
import { RewardIntegrationWithType } from '../../api/hooksApi/useRewardsIntegrations';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { OpenOrderOutCard } from '../../features/open-orderout-card';
import { useAprilURLMutation } from '../../api/endpoints/user';
import helper from '../../services/helper';
import { useSnackBar } from '../snackBar.provider';
import { appRoutes, RewardsRouteSubRoute } from '../../routes';
import { RewardMode } from '../../pages/reward/types';
import { useNavigate } from 'react-router-dom';
import { useSwitchDirectDepositDrawer } from 'providers/switchDirectDeposit';

interface ContextProps {
  handle(rewardItem: RewardIntegrationWithType): Promise<void>;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

enum HandlerTypes {
  link = 0,
  link2 = 1,
  april = 2,
  orderOut = 3,
  giftCard = 4,
  ewa = 5,
  directDeposit = 6,
}

export const RewardIntegrationsHandlerProvider = ({
  children,
}: PropsWithChildren) => {
  const dirDepositContext =  useSwitchDirectDepositDrawer();
  const orderoutShowBool = useBoolean();
  const [aprilURLMutation] = useAprilURLMutation();
  const { setSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const value = useMemo((): ContextProps => {
    return {
      async handle(rewardItem: RewardIntegrationWithType) {
        if ([HandlerTypes.link, HandlerTypes.link2].includes(rewardItem.type)) {
          window.open(rewardItem.url, '_blank');
        }

        if (rewardItem.type === HandlerTypes.april) {
          try {
            const res = await aprilURLMutation().unwrap();
            window.open(res, '_blank');
          } catch (e: any) {
            setSnackBar({
              type: 'error',
              message: helper.formatErrors(e.data),
              isShow: true,
            });
          }
        }

        if (rewardItem.type === HandlerTypes.giftCard) {
          navigate(
            appRoutes.rewards(RewardsRouteSubRoute.myGiftCard, {
              mode: RewardMode.Brands,
            })
          );
        }

        if (rewardItem.type === HandlerTypes.orderOut) {
          orderoutShowBool.setTrue();
        }

        if (rewardItem.type === HandlerTypes.directDeposit) {
          dirDepositContext.show()
        }
      },
    };
  }, []);

  return (
    <Context.Provider value={value}>
      {children}
      <OpenOrderOutCard
        isShow={orderoutShowBool.value}
        onClose={orderoutShowBool.setFalse}
      />
    </Context.Provider>
  );
};

export const useRewardIntegrationsHandler = () =>
  requireNotNull(React.useContext(Context));
