import { useEffect } from 'react';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { RadioGroup } from '@mui/material';
import { Radio } from 'shared/ui/inputs';
import styles from './style.module.scss';
import { useSnackBar } from 'providers/snackBar.provider';
import { useCardInformation } from 'pages/cardManage/provider';
import helper from 'services/helper';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import {
  useReissueCardMutation,
  EnumCardTypes,
  ReissueReason,
} from 'api/endpoints/paymentCards';
import { appRoutes } from 'routes';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionAnimation } from 'shared/ui/animations';
import { OPEN_NEW_CARD_SLEEP_MS } from 'shared/constants';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { useBoolean } from 'shared/hooks/useBoolean';

interface IntroReissueProps {
  darawerOnClose: () => void;
  selectedReason: ReissueReason | null;
  setSelectedReason: (value: ReissueReason | null) => void;
}

const options = [
  {
    title: 'Fraud',
    value: ReissueReason.Fraud,
  },
  {
    title: 'Lost/Stolen',
    value: ReissueReason.LostStolen,
  },
  {
    title: 'Damaged',
    value: ReissueReason.Damaged,
  },
  {
    title: 'I need a new card',
    value: ReissueReason.IneedANewCard,
  },
] as const;

const messageForFraudOrLostStolen =
  'The card will be issued with the new card number. To get a card with an old number, please, select "damaged" or "I need a new card"';
const messageForDamagedOrNeedNewCard =
  'The card will be issued with the same card number. If your card was lost or stolen we highly recommend to get a card with the new number.';

export const IntroReissue = (props: IntroReissueProps) => {
  const { darawerOnClose, selectedReason, setSelectedReason } = props;

  const navigate = useNavigate();
  const params = useParams();
  const { goNext } = useDrawerStepperContext();
  const { currentCard } = useCardInformation();
  const { setSnackBar } = useSnackBar();
  const paymentCards = usePaymentCards();
  const [reissueCard] = useReissueCardMutation();
  const loadingReissueCardBool =useBoolean()

  const financialAccountId = params.accountId;

  const isFraudOrLostStolen =
    selectedReason === ReissueReason.Fraud ||
    selectedReason === ReissueReason.LostStolen;

  const isVirtualCard = currentCard.formFactor == EnumCardTypes.VIRTUAL;
  const isPhysicalCard = currentCard.formFactor == EnumCardTypes.PHYSICAL;

  useEffect(() => {
    if (selectedReason) {
      setSnackBar({
        isShow: true,
        type: 'info',
        width: '400px',
        message: isFraudOrLostStolen
          ? messageForFraudOrLostStolen
          : messageForDamagedOrNeedNewCard,
      });
    }
  }, [selectedReason]);

  const shouldHideLostOrDamageOption = (value: ReissueReason) =>
    isVirtualCard &&
    (value === ReissueReason.LostStolen || value === ReissueReason.Damaged);

  const renderRadioOptions = () =>
    options.map(
      ({ title, value }) =>
        !shouldHideLostOrDamageOption(value) && (
          <Radio
            key={title}
            value={value}
            label={title}
            LabelProps={{ className: styles.radioLabelProps }}
          />
        )
    );

  const reissueVirualCard = async () => {
    try {
      loadingReissueCardBool.setTrue()
      if (selectedReason && financialAccountId) {
        await reissueCard({
          paymentCardId: currentCard.id,
          reason: selectedReason,
        }).unwrap();
       
      await helper.sleep(OPEN_NEW_CARD_SLEEP_MS);
      paymentCards.paymentCardsRefetch();
      loadingReissueCardBool.setFalse()
      setSnackBar({
        type: 'success',
        message:
          'Your card has been successfully re-issued. You can now use it for all your banking needs without interruption.',
        isShow: true,
        width: '400px',
      });
        darawerOnClose();
        navigate(appRoutes.accountById(financialAccountId));
      }
    } catch (e: any) {
      loadingReissueCardBool.setFalse()
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleSubmit = () => {
    if (isPhysicalCard) {
      goNext('1');
    }
    if (isVirtualCard) {
      reissueVirualCard();
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>Select your card re-issue reason</Typography>
        <RadioGroup
          className={styles.radioGroup}
          value={selectedReason}
          onChange={(event) =>
            setSelectedReason(event.target.value as ReissueReason)
          }
        >
          {renderRadioOptions()}
        </RadioGroup>

        <Button
          className={styles.btn}
          disabled={selectedReason === null}
          variant='contained'
          onClick={handleSubmit}
          loading={isVirtualCard && loadingReissueCardBool.value}
        >
          Confirm
        </Button>
      </div>
    </TransitionAnimation>
  );
};
