import { GetGiftCardsBrandsApi } from 'api/endpoints/giftCards';
import styles from './styles.module.scss';
import { Button } from 'shared/ui/buttons';
import { Badge } from 'shared/ui/badge';
import TooltipText from 'shared/components/toolTip/toolTipText';
import { useMediaQuery, Divider } from '@mui/material';

interface BrandItemProps {
  item: GetGiftCardsBrandsApi;
  onClick: (item: GetGiftCardsBrandsApi) => void;
}

export const BrandItem = ({ item, onClick }: BrandItemProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div className={styles.wrapper}>
      <div className={styles.boxLogo}>
        <img
          src={item.logoUrl}
          alt={item.name}
        />
        <TooltipText
          label={item.name}
          maxLength={isMobile ? 20 : 28}
          stylesWrapper={styles.text}
        />
      </div>
      <Divider />
      <div className={styles.box}>
        <Badge
          styledWrapper={styles.badge}
          styledLabel={styles.badgeLabel}
          label={`save ${item.clientDiscount}%`}
        />
        <Button
          variant='text'
          onClick={() => onClick(item)}
        >
          Select
        </Button>
      </div>
    </div>
  );
};
