import { Select, SelectItem, TextInput } from 'shared/ui/inputs';
import { Divider } from '@mui/material';
import styles from './styles.module.scss';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from 'shared/ui/typography';
import { DatePicker } from 'shared/ui/pickers';
import { CalendarOutlineIcon } from 'shared/ui/icons';
import { Payee } from 'api/hooksApi/useExternalPayees';
import { Button } from 'shared/ui/buttons';
import { FormikProps } from 'formik';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Dayjs } from 'dayjs';

interface FormValues {
  from: string;
  to: string;
  amount: string;
  frequency: string;
  dateTransfer: Dayjs;
  memo: string;
}

interface ManageScheduleTransferFormProps {
  formik: FormikProps<FormValues>;
  optionsFromAcc: SelectItem[];
  optionsFrequency: SelectItem[];
  payees: Payee[];
}

export const ManageScheduleTransferForm = (
  props: ManageScheduleTransferFormProps
) => {
  const { formik, optionsFromAcc, optionsFrequency, payees } = props;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.form}
    >
      <Select
        label='From'
        options={optionsFromAcc}
        {...formik.getFieldProps('from')}
        error={formik.touched.from && formik.errors.from}
      />
      <Select
        label='To'
        {...formik.getFieldProps('to')}
        error={formik.touched.to && formik.errors.to}
      >
        {payees?.map((payee) => (
          <MenuItem
            key={payee.id}
            value={payee.id}
          >
            <Typography>
              {payee.name} ({payee.last4})
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <Divider />
      <CurrencyInput2
        fullWidth
        variant='outlined'
        label='Amount'
        placeholder='$0.00'
        name='amount'
        value={formik.values.amount}
        onChange={(value) => {
          formik.setFieldTouched('amount', true);
          formik.setFieldValue('amount', value);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && formik.errors.amount}
        getCaretBoundary={() => [false]}
      />
      <TextInput
        label='Memo'
        autoComplete='off'
        {...formik.getFieldProps('memo')}
        error={formik.touched.memo && formik.errors.memo}
      />
      <Select
        label='Frequency'
        options={optionsFrequency}
        {...formik.getFieldProps('frequency')}
        error={formik.touched.frequency && formik.errors.frequency}
      />
      <DatePicker
        label='Date'
        slots={{
          openPickerIcon: CalendarOutlineIcon,
        }}
        disablePast
        value={formik.values.dateTransfer}
        onChange={(newDate) => {
          formik.setFieldTouched('dateTransfer', true);
          formik.setFieldValue('dateTransfer', newDate);
        }}
        slotProps={{
          textField: {
            error: formik.touched.dateTransfer && !!formik.errors.dateTransfer,
            helperText: <>{formik.errors.dateTransfer}</>,
          },
        }}
      />
      <Button
        className={styles.btnSubmit}
        type='submit'
        variant='contained'
        disabled={!formik.isValid || !formik.dirty}
      >
        save changes
      </Button>
    </form>
  );
};
