import React, { useRef, FC, useState, useEffect } from "react";
import styles from "./style.module.scss";
import MultiFactorInput from "./components/multiFactorInput";
import { Popup } from "components/popup";
import Button from "@mui/material/Button";
import { useTranslationProvider } from "providers/translation/translation.provider";
export type CodeItem = string;

interface MultiFactorAuthProps {
  username: string;
  password: string;
  onSumbit: (username: string, password: string, code: string) => void;
  onClose: () => void;
  errors: string;
  setErrors: (string) => void;
}
const MultiFactorAuth: FC<MultiFactorAuthProps> = ({
  onClose,
  onSumbit,
  username,
  password,
  errors,
  setErrors,
}) => {
  const { t } = useTranslationProvider();
  const inputRefs = useRef<Array<HTMLInputElement>>(
    Array(6).fill(HTMLInputElement)
  );
  const [isValid, setIsvalid] = useState(false);

  const messageContent =
    "Please enter the 6-digit security code from authenticator";

  useEffect(() => {
    if (errors) {
      clearInputs();
      handleInputFocus(0, 0);
      setIsvalid(false);
    }
  }, [errors]);

  const getInputsValue = () =>
    inputRefs.current.map((inputRef) => (inputRef ? inputRef.value : ""));

  const clearInputs = () =>
    inputRefs.current.map((inputRef) => inputRef && (inputRef.value = ""));
  const clearError = () => {
    errors && setErrors("");
  };

  const handleChangeCode = (value: CodeItem, index: number) => {
    if (Number(value) || value === "0") {
      inputRefs.current[index].value = value;
      handleInputFocus(index, 1);
      clearError();
    } else if (!value && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].value = "";
      handleInputFocus(index, -1);
    } else {
      inputRefs.current[index].value = "";
    }

    if (getInputsValue().every((item) => Number(item) || item === "0")) {
      setIsvalid(true);
    } else {
      setIsvalid(false);
    }
  };

  const handleInputFocus = (index: number, step: number) => {
    if (inputRefs.current[index + step]) {
      inputRefs.current[index + step]?.focus();
    }
  };

  const handleSumbit = () => {
    const oneTimeCode = getInputsValue().join("");
    onSumbit(username, password, oneTimeCode);
  };

  return (
    <Popup onClose={onClose} stylesWrapper={styles.popup}>
      <div className={styles.container}>
        <span className={styles.container_title}>
          {t("Login.Confirm login with one time password", {
            defaultValue: "Confirm login with one time password",
          })}
        </span>
        <div className={styles.container_subTitle}>{messageContent}</div>
        <div className={styles.container_input}>
          {getInputsValue().map((codeItem, index) => (
            <MultiFactorInput
              inputRef={(el: HTMLInputElement) =>
                (inputRefs.current[index] = el)
              }
              key={index}
              index={index}
              value={codeItem}
              onChange={handleChangeCode}
            />
          ))}
        </div>
        {errors && <p className={styles.errorMessage}>{errors}</p>}
        <div className={styles.container_buttonGroup}>
          <Button variant="text" onClick={onClose} color="error">
            {t("common.Cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
          <Button variant="text" onClick={handleSumbit} disabled={!isValid}>
            {t("common.Confirm", {
              defaultValue: "Confirml",
            })}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default MultiFactorAuth;
