import * as yup from 'yup';

export const PhysicalCardOrderSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),

  lastName: yup.string().required('Last name is required'),

  address: yup
    .string()

    .required('Address is required'),

  additionalAddress: yup.string().nullable(),

  city: yup.string().required('City is required'),

  state: yup.string().required('State is required'),

  postCode: yup.string().required('Postal code is required'),
});

export type PhysicalCardOrder = {
  firstName: string;
  lastName: string;
  address: string;
  additionalAddress?: string;
  city: string;
  state: string;
  postCode: string;
};
