import { Skeleton } from "@mui/material"
import { AnimateWrapper } from "components/animate-wrapper"
import { BANKS_TAB } from "pages/home/enums"
import { useNavigate } from "react-router-dom"
import { appRoutes } from "routes"
import SimpleContainer from "shared/components/containers/simpleContainer"
import styles from './style.module.scss';
import BankName from "pages/home/components/externalBanks/components/BankName"
import { useExternalBanks } from "api/hooksApi/useExternalBanks"
import NoContent from "./NoContent"
import { AccountsList } from "./AccountsList"


export const ExternalBank = () => {
  const { externalBank, isLoading } = useExternalBanks();
  const navigate = useNavigate();

  if (isLoading) {
    return <Skeleton height={400} />
  }

  if (!externalBank) {
    return (
      <SimpleContainer>
        <AnimateWrapper className='fade'>
          <NoContent />
        </AnimateWrapper>
      </SimpleContainer>
    )
  }

  return (
    <SimpleContainer
      title={'Your Account'}
      onTitleClick={() => navigate(appRoutes.home({ bankTab: BANKS_TAB.OTHER_BANKS }))}
    >
      <AnimateWrapper className='fade'>
        <div className={styles.container}>
          <BankName bankName={externalBank.bankName} className={styles.bankName} />
          <AccountsList />
        </div> 
      </AnimateWrapper>
    </SimpleContainer>)
}
