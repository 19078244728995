import React from 'react'
import {
  TableRow as TableRowMUI,
  TableCell as TableCellMUI,
  useMediaQuery,
  Divider,
} from '@mui/material'
import {
  GiftCardData,
  TypeGiftCards,
} from 'pages/reward/components/giftCard/types'
import styles from './styles.module.scss'

interface TableBodyListProps {
  item: GiftCardData
  index: number
  lastIndex: number
  typeTable: TypeGiftCards
}

interface ActionTableCellProps {
  children: React.ReactNode
  width: string
  isMobile: boolean
}

interface CustomTableCellsProps {
  item: GiftCardData
  isGiftCardsActive: boolean
  isMobile: boolean
}

const ActionTableCell = ({
  children,
  width,
  isMobile,
}: ActionTableCellProps) => (
  <TableCellMUI
    classes={{ root: styles.cell }}
    width={width}
    component={isMobile ? 'div' : 'td'}
  >
    {children}
  </TableCellMUI>
)

const CustomTableCells = ({
  item,
  isGiftCardsActive,
  isMobile,
}: CustomTableCellsProps) => (
  <>
    {isGiftCardsActive ? (
      <>
        <ActionTableCell
          width='20%'
          isMobile={isMobile}
        >
          {item.seeDetails}
        </ActionTableCell>
        <ActionTableCell
          width='15%'
          isMobile={isMobile}
        >
          {item.archivateCard}
        </ActionTableCell>
      </>
    ) : (
      <ActionTableCell
        width='20%'
        isMobile={isMobile}
      >
        {item.restoreCard}
      </ActionTableCell>
    )}
  </>
)

export const TableBodyList = ({
  item,
  index,
  lastIndex,
  typeTable,
}: TableBodyListProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const isLastItem = index === lastIndex
  const isGiftCardsActive = typeTable === TypeGiftCards.Active

  return !isMobile ? (
    <TableRowMUI key={item.id}>
      <TableCellMUI
        classes={{ root: styles.cell }}
        width={isGiftCardsActive ? '25%' : '30%'}
      >
        <div className={styles.boxCard}>
          {item.logo}
          {item.card}
        </div>
      </TableCellMUI>
      <TableCellMUI
        classes={{ root: styles.cell }}
        width={isGiftCardsActive ? '20%' : '25%'}
      >
        {item.dateOfActivation}
      </TableCellMUI>
      <TableCellMUI
        classes={{ root: styles.cell }}
        width={isGiftCardsActive ? '20%' : '25%'}
      >
        {item.amount}
      </TableCellMUI>
      <CustomTableCells
        item={item}
        isGiftCardsActive={isGiftCardsActive}
        isMobile={isMobile}
      />
    </TableRowMUI>
  ) : (
    <React.Fragment key={item.id}>
      <div className={styles.mobWrapper}>
        <div className={styles.boxContent}>
          {item.logo}
          <div className={styles.additionalInfo}>
            <div>
              {item.card} {item.amount}
            </div>
            {item.dateOfActivation}
          </div>
        </div>
        <div className={styles.boxBtn}>
          <CustomTableCells
            item={item}
            isGiftCardsActive={isGiftCardsActive}
            isMobile={isMobile}
          />
        </div>
      </div>
      {!isLastItem && <Divider className={styles.divider} />}
    </React.Fragment>
  )
}
