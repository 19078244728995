import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FC, useCallback, useState } from "react";
interface LoginInputAutoFillAwareProps {
  type: string;
  label: string;
  value: string;
  error: boolean;
  setError?: () => void;
  setValue: (string) => void;
  inputProps?: any;
  InputLabelProps?: any;
  modeInput: "username" | "password";
  isShow?: boolean;
  setShow?: (boolean) => void;
}
const LoginInputAutoFillAware: FC<LoginInputAutoFillAwareProps> = ({
  value,
  setValue,
  label,
  type,
  error,
  setError,
  inputProps,
  InputLabelProps,
  modeInput,
  isShow,
  setShow,
}) => {
  const [isHasValue, setIsHasValue] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill") {
      stateSetter(autofilled);
    }
    if (e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };

  const onChange = useCallback(
    (e) => {
      const trimmedValue = e.target.value.trim();
      setValue(trimmedValue);
      setIsHasValue(trimmedValue !== "");
      setError && setError();
    },
    [setValue]
  );

  const handleClickShowPassword = () => setShow && setShow((isShow) => !isShow);

  return modeInput === "username" ? (
    <TextField
      fullWidth
      id="user-name"
      type={type}
      variant="outlined"
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      inputProps={{
        onAnimationStart: makeAnimationStartHandler(setIsHasValue),
        ...inputProps,
      }}
      InputLabelProps={{
        shrink: value !== "" || isHasValue || isFocused,
        ...InputLabelProps,
      }}
    />
  ) : (
    <TextField
      id="password"
      label={label}
      value={value}
      onChange={onChange}
      type={isShow ? "text" : "password"}
      sx={{ backgroundColor: "transparent" }}
      fullWidth
      error={error}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      InputProps={{
        onAnimationStart: makeAnimationStartHandler(setIsHasValue),
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {isShow ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: value !== "" || isHasValue || isFocused,
        ...InputLabelProps,
      }}
    />
  );
};
export default LoginInputAutoFillAware;
