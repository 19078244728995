import {useMediaQuery} from "@mui/material";
import ArrowCircleRightOutlined from "assets/svg/ArrowCircleRightOutlined.svg";
import styles from "./style.module.scss";
import { Button } from "@mui/material";
import { AccountAction } from "pages/accountComponent/types";

interface ActionButtonProps {
  title: string;
  description: string;
  img: any;
  path: AccountAction;
  onClick: (path: AccountAction) => void;
}

const ActionButton = ({
  title,
  description,
  img,
  path,
  onClick,
}: ActionButtonProps) => {

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div className={styles.container}>
      <Button onClick={() => onClick(path)} variant="text">
        <div
          style={isMobile ? { justifyContent: "center" } : {}}
          className={styles.infoIcon}
        >
          <img src={img} />
        </div>
        {!isMobile && (
          <>
            <div className={styles.title}>{title}</div>
            <div className={styles.contentText}>{description}</div>
            <div className={styles.nextButton}>
              <img src={ArrowCircleRightOutlined} />
            </div>
          </>
        )}
      </Button>
      {isMobile && <div className={styles.title}>{title}</div>}
    </div>
  );
};

export default ActionButton;
