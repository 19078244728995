import { useState } from 'react';
import { GetGiftCardsBrandsApi } from 'api/endpoints/giftCards';
import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { SearchIcon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { BrandItem } from '../brandItem';
import { useGiftCards } from 'pages/reward/provider/useGiftCards';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { FilterIcon } from 'shared/ui/icons';
import { IconButton, Badge } from '@mui/material';

export const DetailsBrands = () => {
  const { isLoadingBrands, setSelectBrand, arrBrands } = useGiftCards();
  const { goNext } = useDrawerStepperContext();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredData = arrBrands?.filter((brand) =>
    brand.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNavigate = (item: GetGiftCardsBrandsApi) => {
    setSelectBrand(item);
    goNext('1');
  };

  const renderItem = () => {
    return filteredData?.map((brand) => (
      <BrandItem
        key={brand.id}
        item={brand}
        onClick={handleNavigate}
      />
    ));
  };

  return isLoadingBrands ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography>
          Select a brand from the list below or use the search for your
          convenience
        </Typography>
        <div className={styles.box}>
          <TextInput
            fullWidth
            placeholder='Search'
            autoComplete='off'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <div className={styles.boxStarAdornment}>
                  <SearchIcon />
                </div>
              ),
            }}
          />

          <IconButton
            aria-label='open filter'
            onClick={() => goNext('4')}
          >
            <Badge
              overlap='circular'
              classes={{ badge: styles.badge }}
            >
              <div className={styles.wrapIcon}>
                <FilterIcon />
              </div>
            </Badge>
          </IconButton>
        </div>

        <div className={styles.list}>{renderItem()}</div>
      </div>
    </TransitionAnimation>
  );
};
