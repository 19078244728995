import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import styles from './style.module.scss';
import cx from 'classnames';
import { SvgIcon } from '../../../shared/components/svgIcon';
import { ReactComponent as CongratsIconSvg } from './congrats-icon.svg';
import { Button } from '../../../shared/ui/buttons';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../routes';

interface Props {
  title: string;
  subtitle: string;
  isConsumerMode: boolean;
  steps: {
    title: string;
    isPassed: boolean;
  }[];
}

export const SignUpApplicationStatus = ({ title, subtitle, steps, isConsumerMode }: Props) => {
  const navigate = useNavigate();

  return (
    <SignUpFormsLayout isConsumerMode={isConsumerMode}>
      <AnimateWrapper className='fade'>
        <div className={styles.wrapper}>
          <SignUpFormsLayout.Title textCenter>{title}</SignUpFormsLayout.Title>
          <SignUpFormsLayout.Subtitle textCenter>
            {subtitle}
          </SignUpFormsLayout.Subtitle>
          <div className={styles.content}>
            <div className={styles.steps}>
              {steps.map(({ title, isPassed }, index) => (
                <div
                  key={title}
                  className={styles.stepItem}
                >
                  <div
                    className={cx(
                      styles.stepItemCircle,
                      isPassed && styles.stepItemCircleSuccess
                    )}
                  >
                    {isPassed ? (
                      <SvgIcon
                        name='SuccessMark'
                        sizePx={20}
                      />
                    ) : (
                      index + 1
                    )}
                  </div>
                  <div className={styles.stepItemLabel}>{title}</div>
                </div>
              ))}
            </div>

            <CongratsIconSvg />

            <SignUpFormsLayout.FormControls>
              <div className={styles.loginButton}>
                <Button
                  fullWidth
                  onClick={() => navigate(appRoutes.login())}
                  variant='contained'
                >
                  Log in
                </Button>
              </div>
            </SignUpFormsLayout.FormControls>

            <br />
          </div>
        </div>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
