import React, { useMemo } from "react";
import styles from "./style.module.scss";

interface Props {
  width?: number | string;
  height?: number | string;
}

export const Skeleton = ({ height, width }: Props) => {
  const htmlStyles = useMemo((): React.CSSProperties => {
    return {
      width,
      height,
    };
  }, [width, height]);
  return <div style={htmlStyles} className={styles.wrapper} />;
};
