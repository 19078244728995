import {
  LocalStorageKeys,
  removeLocalStorageObject,
} from "../shared/utils/local-storage";
import { mainApi } from ".";
import { apiRtkMutex } from './baseApi';

export const logOut = async () => {
  await apiRtkMutex.acquire();
  try {
    await mainApi.logOut();
  } finally {
    removeLocalStorageObject(LocalStorageKeys.AuthToken);
    window.location.href = "/login";
  }
};
