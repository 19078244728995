import { ExternalAccount, useExternalAccountsQuery } from "api/endpoints/financialAccounts"
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export interface ExternalBank {
  id: string,
  bankName: string,
  accounts: ExternalAccount[]
  balance: number | null,
}

const initialExternalBanks: Record<string, ExternalBank> = {
  'externalBank': {
    id: 'externalBank',
    bankName: 'External Bank',
    accounts: [],
    balance: null,
  }
}

const initialData = {
  externalBankMap: initialExternalBanks,
  financialAccounts: [],
}

const parseExternalAccounts = (financialAccounts: ExternalAccount[]) => {
  const unclosedFinancialAccounts = financialAccounts
    .filter((fa) => fa.accountStatus !== 'CLOSED' && fa.provider !== null)
    .map((fa) => ({
      ...fa,
      balanceInfo: fa.balanceInfo
        ? {
            ...fa.balanceInfo,
            value: fa.balanceInfo.value * 100, // convert to cents
          }
        : fa.balanceInfo,
    }));

  const externalBankMap: Record<string, ExternalBank> = unclosedFinancialAccounts.reduce((acc, item) => {
    const bankName = item.bankName || 'External Bank';
    
    if (!acc[bankName]) {
      acc[bankName] = {
        id: bankName,
        bankName,
        accounts: [],
        balance: 0,
      };
    }

    acc[bankName].accounts.push(item);

    if (item.balanceInfo) {
      acc[bankName].balance = (acc[bankName].balance ?? 0) + item.balanceInfo.value;
    }

    return acc;
  }, {} as Record<string, ExternalBank>);

  return { 
    financialAccounts: unclosedFinancialAccounts,
    externalBankMap,
  }
}

export const useExternalBanks = () => {
  const params = useParams();

  const { data = [], isLoading } = useExternalAccountsQuery() 

  const parsedData = useMemo(() => { 
    if (data) {
      return parseExternalAccounts(data) 
    }

    return initialData;
  }, [data]);

  const { externalBankMap, financialAccounts } = parsedData;


  const externalBanks = useMemo(() => 
    Object.values(externalBankMap)
    .sort((a,b) => (b.balance ?? 0) - (a.balance ?? 0)), []);
    
  const totalAvailableBalance = useMemo(() => externalBanks.reduce((balance, externalBank) => {
    if (externalBank.balance) {
      balance += externalBank.balance / 100;
    }

    return balance;
  }, 0) * 100, [externalBanks])

  const externalBank = useMemo(() => {
    const externalBank = externalBankMap[params.bankName ?? ''] ?? null;

    return externalBank;
  }, [externalBankMap, params]);

  return {
    externalBank,
    externalBanks,
    financialAccounts,
    availableBalance: totalAvailableBalance,
    isLoading,
  }
}
