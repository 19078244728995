import AccordionComponent from "shared/components/AccordionComponent"
import PaperComponent from "shared/components/PaperComponent"
import { StatusComponent } from "shared/components/StatusComponent"
import { useTransactions } from "../provider";
import TableBlock from "shared/components/TableBlock";
import { ExternalTransactionsCells } from "shared/components/TableBlock/TableCells/ExternalTransactionsCells";

export const PandingTransactions = () => {
  const { pendingTransactions } = useTransactions();

  return (
    <PaperComponent>
       <AccordionComponent
        title='Pending Transactions'
        bage={
          <StatusComponent
            counter={pendingTransactions.length}
            status={'PROCESSING'}
            text='Pending'
          />
        }                
      >
      <TableBlock
        rows={pendingTransactions}
        tabCells={ExternalTransactionsCells()}
      />
        </AccordionComponent>
      </PaperComponent>
  )
}