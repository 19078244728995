import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginState } from "./types";
import qs from "qs";
import { axiosInstance } from "../../api/tempAxios";

const initialState: LoginState = {
  username: "",
  pass: "",
  token: "",
  freshToken: "",
  twoFactorAuthenticationEnabled: null,
  status: "idle",
  error: undefined,
  ticket: "",
  remember30days: null,
  email: "",
  responseStatus: 0,
  forgotLoginStatus: "",
  forgotLoginStatusError: "",
  paymentCards: [],
  legalBusinessName: "",
  givenName: "",
  familyName: "",
  passwordStatus: "",
  passwordConfirmCode: "",
  nickName: "",
  loginMfaStatus: "",
  lockedStatus: "",
  expirationDate: "",
};

type TChangePassword = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
  token: string;
};

type TloginResProp = {
  email: any;
  confirmCode: any;
};

type TLoginMfaCredentials = {
  username: string;
  pass: string;
  ticket: string;
  mfaCode: string;
  remember30days: boolean;
};

export const fetchMfa = createAsyncThunk(
  "login/fetchMfa",
  async (_, { rejectWithValue }) => {
    try {
      const headers = {
        Accept: "application/json",
      };

      const config = {
        method: "get",
        url: `api/users`,
        headers,
      };

      const response = await axiosInstance(config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginMfa = createAsyncThunk(
  "login/loginMfa",
  async (loginMfaProps: TLoginMfaCredentials, { rejectWithValue }) => {
    try {
      const data = qs.stringify({
        username: loginMfaProps.username,
        password: loginMfaProps.pass,
        ticket: loginMfaProps.ticket,
        code: loginMfaProps.mfaCode,
        remember30day: loginMfaProps.remember30days,
      });

      const config = {
        method: "post",
        url: `api/auth/2Fac`,
        data,
      };

      const response = await axiosInstance(config);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const forgotLogin = createAsyncThunk(
  "login/forgotLogin",
  async (email: any, { rejectWithValue }) => {
    try {
      const data = qs.stringify({
        Email: email.email,
      });

      var config = {
        method: "post",
        url: `forgotLogin`,
        data,
      };

      const response = await axiosInstance(config);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const forgotLogin2 = createAsyncThunk(
  "login/forgotLogin2",
  async (loginResProp: TloginResProp, { rejectWithValue }) => {
    try {
      const data = qs.stringify({
        Email: loginResProp.email,
        Code: loginResProp.confirmCode,
      });

      const config = {
        method: "post",
        url: `restoreLogin`,
        data,
      };

      const response = await axiosInstance(config);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "login/changePassword",
  async (obj: TChangePassword, { rejectWithValue }) => {
    try {
      const headers = {
        Accept: "application/json",
      };

      const data = {
        CurrentPassword: obj.currentPassword,
        Password: obj.password,
        PasswordConfirm: obj.passwordConfirm,
      };

      var config = {
        method: "post",
        url: `changePassword`,
        headers,
        data,
      };

      const response = await axiosInstance(config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "login/forgotPassword",
  async (login: any, { rejectWithValue }) => {
    try {
      const data = qs.stringify({
        NickName: login,
      });

      const config = {
        method: "post",
        url: `forgotPassword`,
        data,
      };

      const response = await axiosInstance(config);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const restorePassword = createAsyncThunk(
  "login/restorePassword",
  async (
    { nickName, code, password, passwordConfirm }: any,
    { rejectWithValue }
  ) => {
    try {
      const data = qs.stringify({
        NickName: nickName,
        Code: code,
        Password: password,
        PasswordConfirm: passwordConfirm,
      });

      const config = {
        method: "post",
        url: `restorePassword4`,
        data,
      };

      const response = await axiosInstance(config);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setStateUsername: (state, action) => {
      state.username = action.payload.username;
    },
    setStatePass: (state, action) => {
      state.pass = action.payload.pass;
    },
    setRemember30days: (state, action) => {
      state.remember30days = action.payload;
    },
    setStateEmail: (state, action) => {
      state.email = action.payload;
    },
    setResponseStatus: (state) => {
      state.responseStatus = 0;
    },
    setForgotLoginStatus: (state) => {
      // console.log(
      //     'setForgotLoginStatus active for state.responseStatus=0 and state.forgotLoginStatus=0'
      // )
      state.responseStatus = 0;
      state.forgotLoginStatus = 0;
    },
    setLoginMfaStatus: (state, action) => {
      console.log("action.payload in setLoginMfaStatus:", action.payload);
      state.loginMfaStatus = action.payload;
    },
    setTicketLoginSlice: (state, action) => {
      state.ticket = action.payload;
    },
    setError: (state, action) => {
      // console.log('action.payload in setError', action.payload)
      state.error = action.payload;
    },
    setPasswordConfirmCode: (state, action) => {
      state.passwordConfirmCode = action.payload;
    },
    setNickName: (state, action) => {
      state.nickName = action.payload;
    },
    setPasswordStatus: (state, action) => {
      state.passwordStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginMfa.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginMfa.fulfilled, (state, action) => {
        state.token = action.payload.data.token;
      })
      .addCase(loginMfa.rejected, (state, action) => {
        state.status = "failed";
        state.loginMfaStatus = action.error.message;
        // state.error = action.error.message;
      })
      .addCase(fetchMfa.fulfilled, (state, action) => {
        // console.log('action.payload in fetchMfa:', action.payload)
        state.twoFactorAuthenticationEnabled =
          action.payload.twoFactorAuthenticationEnabled;
      })
      .addCase(fetchMfa.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.lockedStatus = action.error.message;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.pass = action.payload.pass;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || action.payload;
      })
      .addCase(forgotLogin.fulfilled, (state, action) => {
        state.responseStatus = action.payload.status;
      })
      .addCase(forgotLogin.rejected, (state, action) => {
        state.responseStatus = action.error.message || action.payload;
      })
      .addCase(forgotLogin2.fulfilled, (state, action) => {
        state.forgotLoginStatus = action.payload.status;
      })
      .addCase(forgotLogin2.rejected, (state, action) => {
        state.forgotLoginStatus = action.payload;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.passwordStatus = action.payload?.status;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        // console.log('try to get error in forgotPassword.rejected:', action.error.message);
        state.passwordStatus = action.error.message;
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "idle";
          state.error = action.payload || action.error.message;
        }
      );
  },
});

export const {
  setToken,
  setTicketLoginSlice,
  setError,
  setStateUsername,
  setStatePass,
  setRemember30days,
  setStateEmail,
  setResponseStatus,
  setForgotLoginStatus,
  setLoginMfaStatus,
  setPasswordConfirmCode,
  setNickName,
  setPasswordStatus,
} = loginSlice.actions;

export const selectLogin = (state: any) => state.login.token;

export default loginSlice.reducer;
