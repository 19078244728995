import { FC } from "react";
import Tab from "@mui/material/Tab";
import styles from "./style.module.scss";
import { TabContext, TabList } from "@mui/lab";
import { ItemArr } from "../..";
import cn from 'classnames'

interface TabsPanelProps {
  tabsArr: ItemArr[];
  handleNavigate: (id: string) => void;
  page: string | null;
  stylesWrapper?:  {
    container?:string
    list? : string
  }
}

const TabsPanel: FC<TabsPanelProps> = ({ tabsArr, handleNavigate, page,stylesWrapper }) => {

  return (
    <div className={cn(stylesWrapper?.container,styles.tabWrapper)}>
      <TabContext value={page || tabsArr[0].id}>
        <TabList
         className={cn(stylesWrapper?.list,styles.list)}
        >
          {tabsArr.map((i) => (
            <Tab
              key={i.id}
              label={i.label}
              value={i.id}
              onClick={() => handleNavigate(i.id)}
            />
          ))}
        </TabList>
      </TabContext>
    </div>
  );
};
export default TabsPanel;
