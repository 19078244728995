import {
  TextField,
  TextFieldProps as TextFieldPropsMUI,
  InputLabelProps as InputLabelPropsMUI,
  SelectProps as SelectPropsMUI,
  MenuItem,
  MenuItemProps,
} from '@mui/material'
import styles from './styles.module.scss'
import { ForwardedRef, ReactNode, forwardRef } from 'react'
import cn from 'classnames'

export type SelectItem = {
  id: string
  value: string | number
  content: ReactNode
  MenuItemProps?: MenuItemProps
}

type SelectProps = Omit<
  TextFieldPropsMUI,
  'classes' | 'error' | 'color' | 'select' | 'InputProps'
> & {
  InputLabelProps?: Omit<
    TextFieldPropsMUI['InputLabelProps'],
    'className' | 'classes'
  >

  SelectProps?: Pick<SelectPropsMUI, 'className' | 'classes'>
  error?: ReactNode
  options?: Array<SelectItem>
}
// TODO: style according to the layout in the future
export const Select = forwardRef(function Select(
  props: SelectProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    error,
    className,
    InputLabelProps,
    SelectProps,
    variant = 'outlined',
    options,
    children,
    ...rest
  } = props

  const labelClasses: InputLabelPropsMUI['classes'] = {
    root: styles.label,
  }

  const selectClasses: SelectPropsMUI['classes'] = {
    select: styles.select,
  }

  return (
    <TextField
      {...rest}
      select
      error={!!error}
      className={cn(styles.root, className)}
      variant={variant}
      SelectProps={{
        ...SelectProps,
        classes: selectClasses,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: labelClasses,
      }}
      InputProps={{
        ref: ref,
      }}
      helperText={typeof error !== 'boolean' ? error : ''}
    >
      {options
        ? options.map((item) => (
            <MenuItem
              key={item.id}
              value={item.value}
              {...item.MenuItemProps}
            >
              {item.content}
            </MenuItem>
          ))
        : children}
    </TextField>
  )
})
