import data from "constants/data";
import cx from "classnames";
import TooltipText from "shared/components/toolTip/toolTipText";
import Slash from "assets/svg/slash.svg";
import CheckCircleAccount from "assets/svg/check-circle-account.svg";
import HistoryClock from "assets/svg/history-clock.svg";
import styles from "./style.module.scss";

interface StatusProps {
  status: string;
  counter?: number;
  text?: string;
}

export const StatusComponent = ({ status, counter, text }: StatusProps) => {
  const statusInfo = data.TRANSFER_STATUSES;
  const getStatusElement = (statusText, stylesClass, imageSrc) => (
    <div className={stylesClass}>
      {imageSrc && <img src={imageSrc} width="17px" height="17px" />}
      <TooltipText
        label={counter ? `${counter} ${statusText}` : statusText}
        maxLength={12}
      />
    </div>
  );
  if (statusInfo.processing.includes(status))
    return getStatusElement(
      text ? text : status,
      styles.boxStatus,
      HistoryClock
    );

  if (statusInfo.approved.includes(status))
    return getStatusElement(
      text ? text : status,
      cx(styles.boxStatus, styles.status_approved),
      CheckCircleAccount
    );

  if (statusInfo.declined.includes(status) || status === "RE_ENTER_TRANSACTION")
    return getStatusElement(
      "declined",
      cx(styles.boxStatus, styles.status_declined),
      Slash
    );

  return getStatusElement(text ? text : status, styles.status_text, null);
};
