import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ArrowLeft from "../../../assets/svg/arrow-left.svg";
import { AnimateWrapper } from "../../../components/animate-wrapper";
import styles from "./style.module.scss";
import AccountButtons from "../../../components/button/AccountButtons";
import Square from "../../../assets/svg/new-account-square.svg";
import NewCheckSquare from "../../../assets/svg/new-check-square.svg";
import { useCreateTicketControllerMutation } from "../../../api/endpoints/support";
import { useUploadTicketAttachmentControllerMutation } from "../../../api/endpoints/tickets";
import { convertFileToBase64 } from "../../../shared/utils/convertFileToBase64";
import helper from "../../../services/helper";
import { useFinancialAccountTransactionsServer } from "../../../api/hooksApi/useFinancialAccountTransactionsServer";
import { useMerchantCategories } from "../../../api/hooksApi/useMerchantCategories";
import SuccessModal from "components/modals/succesModal";
import FormReportProblem from "./components/formReportProblem";
import { appRoutes } from '../../../routes';

const arrTest = [
  { text: "I did not authorize that charge.", id: 1 },
  { text: "I was charged more than once on my card.", id: 2 },
  { text: "The item that was received was not what I expected.", id: 3 },
  { text: "Have not received product", id: 4 },
  { text: "Received incorrect item", id: 5 },
  { text: "Requested refund and no refund received", id: 6 },
];

type ReportProblemPageMode = "subject" | "send";

const ReportProblem = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const transactionId = searchParams.get("transactionId");
  const transferId = searchParams.get("transferId");
  const accountId = searchParams.get("accountId");
  const financialAccountId = params.accountId || accountId;
  const from = moment(new Date(String(searchParams.get("sd")))).format(
    "YYYY-MM-DD 00:00:00Z",
  );
  const to = moment(new Date(String(searchParams.get("ed")))).format(
    "YYYY-MM-DD 23:59:ssZ",
  );
  const { merchantCategories } = useMerchantCategories();
  const [createTickerMutation, { isLoading: createTickerIsLoading }] =
    useCreateTicketControllerMutation();
  const { financialAccountTransactionsServer } =
    useFinancialAccountTransactionsServer(
      financialAccountId,
      from,
      to,
      merchantCategories,
    );
  const [uploadTicketFiles] = useUploadTicketAttachmentControllerMutation();
  const [reportProblemPageMode, setReportProblemPageMode] =
    useState<ReportProblemPageMode>("subject");
  const [subject, setSubject] = useState<number | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [files, setFiles] = useState<FileList | null>(null);
  const [transaction, setTransaction] = useState<any>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const dateFormat = "DD MMM, dddd hh:mm A, YYYY";
  const subjectName = arrTest.find((i) => i.id == subject)?.text;

  useEffect(() => {
    if (financialAccountId && financialAccountTransactionsServer) {
      filteredAccountTransactionById();
    }
  }, [search, financialAccountTransactionsServer]);

  const filteredAccountTransactionById = () => {
    const approvedTransactions =
      financialAccountTransactionsServer?.approvedTransactions || [];
    const pendingTransactions =
      financialAccountTransactionsServer?.pendingTransactions || [];
    const accTransaction = [
      ...approvedTransactions,
      ...pendingTransactions,
    ].find((i) => i.id === transferId || i.id === transactionId);
    const transformTransaction = helper.formatTransaction(
      accTransaction,
      dateFormat,
    );
    setTransaction(transformTransaction);
  };

  const handleChooseSubject = (id) => {
    setSubject(id);
  };

  const rednerItems = (arr) =>
    arr.map((item, index) => (
      <AnimateWrapper delay={index * 100} key={item.id}>
        <div
          key={item.id}
          onClick={() => handleChooseSubject(item.id)}
          className={styles.wrapSubject}
        >
          <img
            src={subject === item.id ? NewCheckSquare : Square}
            className={styles.wrapSubject_img}
          />
          <p className={styles.wrapSubject_label}>{item.text}</p>
        </div>
      </AnimateWrapper>
    ));

  const handleSubmitQuestion = async () => {
    try {
      const { ticketId } = await createTickerMutation({
        topicId: 4, //-----'Topic - Transaction dispute',
        text: `Reason: ${subjectName}${`\n`}Amount: ${
          transaction.amount
        }${`\n`}Last 4 digits of the card: ${
          transaction.cardLast4
        }${`\n`}Date/time: ${transaction.date}${`\n`}Transaction name: ${
          transaction.name
        }${`\n`}Type of transaction: ${transaction.type}${`\n`}Status: ${
          transaction.status
        }${`\n`}Text: ${question}`,
      }).unwrap();

      if (files?.length) {
        // @ts-ignore
        const allFiles = files ? [...files].filter((file) => file.type) : [];

        await Promise.all([
          allFiles.map(async (file, i) => {
            const fileBase64 = (await convertFileToBase64(file)) as string;
            // @ts-ignore
            const formattedFileBase64 = fileBase64?.substring(
              fileBase64.indexOf(",") + 1,
            );

            await uploadTicketFiles({
              ticketId,
              fileName: file.name,
              contentType: file.type,
              fileBase64: formattedFileBase64,
            });
          }),
        ]);
      }
      setQuestion("");
      setFiles(null);
      setSuccessModal(true);
    } catch (e: any) {
      console.log("e", e);
    }
  };

  function handleGoBack() {
    if (params.cardId) {
      navigate(
        `${appRoutes.cardTransactions(params.cardId)}/transaction-details?${searchParams.toString()}`,
      );
    } else if (params.accountId) {
      navigate(
        `/account/${
          params.accountId
        }/transaction-details?${searchParams.toString()}`,
      );
    } else {
      navigate(`/transfer/transfer-details?${searchParams.toString()}`);
    }
  }

  if (reportProblemPageMode === "subject") {
    return (
      <AnimateWrapper className="fade">
        <div onClick={() => handleGoBack()} className={styles.nav}>
          <img src={ArrowLeft} />
          <span className={styles.nav_title}>Report a problem</span>
        </div>
        <div className={styles.container}>
          <div className="flex flex-col gap-6">
            <p className={styles.container_title}>Choose the subject:</p>
            {rednerItems(arrTest)}
            <div className={styles.wrapBuutons}>
              <AccountButtons
                disabled={!subject}
                name="Next"
                handleSubmit={() => setReportProblemPageMode("send")}
              />
              <AccountButtons
                name="Go Back"
                secondary={true}
                handleSubmit={() => handleGoBack()}
              />
            </div>
          </div>
        </div>
      </AnimateWrapper>
    );
  }

  if (reportProblemPageMode === "send") {
    return (
      <AnimateWrapper className="fade">
        <div
          onClick={() => setReportProblemPageMode("subject")}
          className={styles.nav}
        >
          <img src={ArrowLeft} />
          <span className={styles.nav_title}>Report a problem</span>
        </div>
        <div className={styles.container}>
          <div>
            <p className={styles.container_title}>{subjectName}</p>

            <FormReportProblem
              question={question}
              setQuestion={setQuestion}
              files={files}
              setFiles={setFiles}
            />
            <div className={styles.wrapBuutons}>
              <AccountButtons
                disabled={createTickerIsLoading}
                name="Send"
                handleSubmit={handleSubmitQuestion}
              />
              <AccountButtons
                name="Go Back"
                secondary={true}
                handleSubmit={() => setReportProblemPageMode("subject")}
              />
            </div>
          </div>
          <SuccessModal
            titleMassage="Your report has been sent."
            isOpen={successModal}
            onClose={() => navigate("/support/history")}
          />
        </div>
      </AnimateWrapper>
    );
  }

  return null;
};

export default ReportProblem;
