import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { SignUpBusinessProgressBar } from '../progressBar';
import { BusinessSignUpScreen, useSignUpBusiness } from '../provider';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import styles from './style.module.scss';
import { Button } from '../../../shared/ui/buttons';
import { useFormik } from 'formik';
import {
  SignUpAddAddressFrom,
  signUpAddressValidateSchema,
} from '../../../shared/signUp/shared';
import { SignUpAddressForm } from '../../../shared/signUp/addressForm';
import { useScrollToMuiFieldAfterError } from '../../../shared/hooks/useScrollToMuiFieldAfterError';

export const BusinessAddress = () => {
  const { setScreen, contextForm, updateContextForm } = useSignUpBusiness();

  const formik = useFormik<SignUpAddAddressFrom>({
    initialValues: contextForm,
    validationSchema: signUpAddressValidateSchema,
    onSubmit(values) {
      updateContextForm(values);
      setScreen(BusinessSignUpScreen.primaryAuthorizedPerson);
    },
  });

  useScrollToMuiFieldAfterError(formik.isSubmitting);

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Please enter your business address
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Provide your business address details accurately to ensure precision
          in our services.
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar
          curScreen={BusinessSignUpScreen.businessAddress}
        />

        <form
          onSubmit={formik.handleSubmit}
          className={styles.formWrapper}
        >
          <SignUpAddressForm formikInstance={formik} />

          <SignUpFormsLayout.FormControls>
            <Button
              onClick={() => setScreen(BusinessSignUpScreen.businessInfo)}
              type='button'
              variant='text'
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
            >
              Next
            </Button>
          </SignUpFormsLayout.FormControls>
        </form>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
