import { useEffect, useState } from 'react';
import { 
  useCancelPhysicalPaymentCardOrderControllerMutation, 
  useFindPhysicalPaymentCardOrderControllerMutation
 } from 'api/endpoints/paymentCards';
import moment from 'moment';
import ActiveStepIcon from 'assets/svg/icon 1 step.svg';
import InActiveStepIcon from 'assets/svg/stepInActive.svg';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useCardRouteParams } from 'shared/hooks/useCardRouteParams';
import { Skeleton } from 'components/skeleton';
import { useSnackBar } from 'providers/snackBar.provider';
import { Button } from 'shared/ui/buttons'
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { useDialog } from 'providers/dialogController.provider';

interface OrderPhysiacalCardDeliveryStatusProps {
  orderId: string;
}

const OrderPhysiacalCardDeliveryStatus = ({ orderId }: OrderPhysiacalCardDeliveryStatusProps) => {
  const { t } = useTranslationProvider();
  const dialog = useDialog()
  const { cardId } = useCardRouteParams();
  const { setSnackBar } = useSnackBar();
  const [
    physicalPaymentCardOrderMutation,
    { isLoading: physicalPaymentCardOrderIsLoading },
  ] = useFindPhysicalPaymentCardOrderControllerMutation();
  const [cancelPhysicalPaymentCardOrderMutation, {isLoading: isLoadingCancelPhysicalPaymentCardOrder}] =
    useCancelPhysicalPaymentCardOrderControllerMutation();
  const [status, setStatus] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [userFullName, setUserFullName] = useState<string>('');
  const [extendedAddress, setExtendedAddress] = useState('');

  const [cancelStatusButton, setCancelStatusButton] = useState<string>('');
  const [deliveryMethod, setDeliveryMethod] = useState<string>('');
 

  useEffect(() => {
    getOrderStatus(cardId, orderId);
  }, [cardId, orderId]);

  const getOrderStatus = async (cardId, orderId) => {
    try {
      const orderStatusInfo = await physicalPaymentCardOrderMutation({
        paymentCardId: cardId,
        orderId: orderId,
      }).unwrap();

      const stateHistory = orderStatusInfo?.node?.stateHistory;
      const deliveryDetails =
        orderStatusInfo?.node?.paymentCardShipment?.deliveryDetails;
      const addressStreet = deliveryDetails?.address?.streetAddress;
      const addressLocality = `${deliveryDetails.address.locality},  ${deliveryDetails.address.region}, ${deliveryDetails.address.postalCode}`;
      const userFullName = `${deliveryDetails.name.givenName} ${deliveryDetails.name.familyName}`;
      setExtendedAddress(deliveryDetails?.address?.extendedAddress || '');
      if (!!stateHistory.length) {
        saveStatusInfo(stateHistory);
      }
      if (orderStatusInfo?.node.orderState.status === 'SHIP_FAILED') {
        setSnackBar({
          type: 'error',
          message:  t('Card.Shipment Did Not Reach Address', {
            defaultValue: 'Shipment Did Not Reach Address',
          }),
          isShow: true,
        });
      }

      setAddress({ addressStreet, addressLocality });
      setUserFullName(userFullName);
      transformDeliverMethod(
        orderStatusInfo?.node.paymentCardShipment.courier.method
      );
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const saveStatusInfo = (arrStatus) => {
    if (arrStatus) {
      const unique = new Map(arrStatus.map((el) => [el.newStatus, el]));
      const item = Object.fromEntries(unique);
      setStatus(item);
      setCancelStatusButton(arrStatus[0]?.newStatus);
    }
  };

  const transformDeliverMethod = (methodDelivery) => {
    let item = methodDelivery.split('_').join(' ');

    if (item === 'UPS SECOND DAY') {
      setDeliveryMethod('UPS PRIORITY');
    } else if (item == 'UPS NEXT DAY') {
      setDeliveryMethod('UPS EXPRESS');
    } else {
      setDeliveryMethod(item);
    }
  };
 
  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      CONFIRM CANCELATION
    </Button>
  )
 
  const handeleCancelOrder = async () => {
    dialog.show({
      dialogTitle: 'Order cancellation confirmation',
      dialogText: 'Are your sure want cancel this order?',
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: <Button variant='text'>KEEP ORDER</Button>,
      async onApply() {
        try {
          if (orderId && cardId) {
            await cancelPhysicalPaymentCardOrderMutation({
              paymentCardId: cardId,
              orderId: orderId,
            }).unwrap();
            setSnackBar({
              type: 'success',
              message: t('common.Order was cancelled successfully', {
                defaultValue: 'Order was cancelled successfully',
              }),
              isShow: true,
            });
          }
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          })
        }
      },
    })
  }

  return physicalPaymentCardOrderIsLoading ? 
    <Skeleton width="100%" height="110px" />
  :
    (
     <TransitionAnimation>
          <div className={styles.deliveryStatus}>
              <div className={styles.deliveryStatus_items}>
                <div className={styles.deliveryStatus_firstStatus}>
                  <div className={styles.lineBox}>
                    <img
                      src={status?.NEW ? ActiveStepIcon : InActiveStepIcon}
                      className={styles.lineBox_img}
                    />
                    <div className={styles.line}/>
                  </div>
                  <div>
                    <Typography className={styles.deliveryStatus_statusTitle}>
                      {t('Card.Card requested', {
                        defaultValue: 'Card requested',
                      })}
                    </Typography>
                    <Typography className={styles.deliveryStatus_userName}>
                      {userFullName}
                    </Typography>
                    <div>
                      <Typography className={styles.deliveryStatus_text}>
                        {address?.addressStreet},{extendedAddress ? ` ${extendedAddress},` : ''}
                      </Typography>
                      <Typography className={styles.deliveryStatus_text}>
                        {address?.addressLocality}
                      </Typography>
                    </div>
                    <Typography className={styles.deliveryStatus_statusDate}>
                      {status?.NEW &&
                        moment(status?.NEW?.createdAt).format(
                          'MMM DD,  YYYY , hh:mm A'
                        )}
                    </Typography>
                  </div>
                </div>

                <div className={styles.deliveryStatus_status}>
                  <div className={styles.lineBox}>
                    <img
                      src={
                        status?.SENT_TO_PRINTER
                          ? ActiveStepIcon
                          : InActiveStepIcon
                      }
                      className={styles.lineBox_img}
                    />
                    <div className={styles.line}/>
                  </div>
                  <div>
                    <Typography className={styles.deliveryStatus_statusTitle}>
                      {t('common.Sent to Printer', {
                        defaultValue: 'Sent to Printer',
                      })}
                    </Typography>
                    <Typography className={styles.deliveryStatus_statusDate}>
                      {status?.SENT_TO_PRINTER &&
                        moment(status?.SENT_TO_PRINTER?.createdAt).format(
                          'MMM DD,  YYYY , hh:mm A'
                        )}
                    </Typography>
                  </div>
                </div>

                <div className={styles.deliveryStatus_status}>
                  <div className={styles.lineBox}>
                    <img
                      src={status?.APPROVED ? ActiveStepIcon : InActiveStepIcon}
                      className={styles.lineBox_img}
                    />
                    <div className={styles.line}/>
                  </div>
                  <div>
                    <Typography className={styles.deliveryStatus_statusTitle}>
                      {t('common.Shipment Approved', {
                        defaultValue: 'Shipment Approved',
                      })}
                    </Typography>
                    <Typography className={styles.deliveryStatus_statusDate}>
                      {status?.APPROVED &&
                        moment(status?.APPROVED?.createdAt).format(
                          'MMM DD,  YYYY , hh:mm A'
                        )}
                    </Typography>
                  </div>
                </div>

                <div className={styles.deliveryStatus_status}>
                  <div className={styles.lineBox}>
                    <img
                      src={status?.SHIPPED ? ActiveStepIcon : InActiveStepIcon}
                      className={styles.lineBox_img}
                    />
                  </div>
                  <div>
                    <Typography className={styles.deliveryStatus_statusTitle}>
                      {t('Card.Card shipped', {
                        defaultValue: 'Card shipped',
                      })}
                    </Typography>
                    {status?.SHIPPED && (
                      <Typography className={styles.deliveryStatus_text}>
                        {t('Card.Shipped via', {
                          defaultValue: 'Shipped via',
                        })}
                        {deliveryMethod}
                      </Typography>
                    )}
                    <Typography className={styles.deliveryStatus_statusDate}>
                      {status?.SHIPPED &&
                        moment(status?.SHIPPED?.createdAt).format(
                          'MMM DD,  YYYY , hh:mm A'
                        )}
                    </Typography>
                  </div>
                </div>
                {cancelStatusButton === 'CANCELED' && (
                  <Typography>
                    {t('Card.You canceled the order', {
                      defaultValue: 'You canceled the order',
                    })}
                  </Typography>
                )}
                {cancelStatusButton === 'NEW' && (
                  <Button
                    variant="outlined"
                    className={styles.btnCancel}
                    onClick={handeleCancelOrder}
                    loading={isLoadingCancelPhysicalPaymentCardOrder}
                  >
                    {t('common.Cancel order', {
                      defaultValue: 'Cancel order',
                    })}
                  </Button>
                )}
              </div>
          </div>
    </TransitionAnimation>
  );
};

export default OrderPhysiacalCardDeliveryStatus;
