import Button from "@mui/material/Button";
import { TNavigateButton } from "./types";

const AccountButtons = ({
  name,
  to,
  secondary,
  warning,
  disabled,
  handleSubmit,
  width,
  children,
  backgroundColor,
}: TNavigateButton) => {
  const secondaryColor = secondary ? "#939598" : "#51B9E5";
  const warningColor = warning ? "#FF8585" : "#51B9E5";
  const buttonColor = secondary ? secondaryColor : warningColor;
  const bg = backgroundColor ? backgroundColor : "";

  //Todo: red button
  return (
    <div>
      <Button
        variant="outlined"
        disabled={disabled}
        sx={{
          ":hover": {
            backgroundColor: bg,
            // borderColor: buttonColor,
            // // bgcolor: 'primary.main', // theme.palette.primary.main
            // color: "white",
          },
          borderRadius: 6,
          textTransform: "none",
          width: {
            xs: width || 272,
            sm: width || 280,
          },
          height: 42,
          borderColor: buttonColor,
          backgroundColor: bg,
          color: buttonColor,
          "@media print": {
            width: 272,
          },
        }}
        href={to}
        onClick={handleSubmit}
      >
        {name}
        {children}
      </Button>
    </div>
  );
};

export default AccountButtons;
