import { Switch } from '@mui/material';
import {
  useSuspendCardControllerMutation,
  useActivateCardControllerMutation,
} from 'api/endpoints/paymentCards';
import { useConfirmDialog } from 'providers/confirm.provider';
import { useState } from 'react';
import helper from 'services/helper';
import { useBoolean } from '../../../../shared/hooks/useBoolean';

interface LockCardProps {
  cardId: string;
  onFinish: (text: string) => void | Promise<void>;
  defaultState?: boolean;
}

export const LockCard = ({ cardId, onFinish, defaultState }: LockCardProps) => {
  const confirm = useConfirmDialog();
  const [isChecked, setIsChecked] = useState(defaultState);

  const [suspendCardMutation, { isLoading: isLoadingSuspendCardMutation }] =
    useSuspendCardControllerMutation();
  const [activateCardMutation, { isLoading: isLoadingActivateCardMutation }] =
    useActivateCardControllerMutation();

  const isProcessingBool = useBoolean();


  const handleProcess = async (isLocked: boolean, text: string) => {
    try {
      isProcessingBool.setTrue();
      if (isLocked) {
        await suspendCardMutation({ cardId }).unwrap();
      } else {
        await activateCardMutation({ cardId }).unwrap();
      }

      await onFinish(text);
      setIsChecked(isLocked);
    } finally {
      isProcessingBool.setFalse();
    }
  };

  const handleLockCard = async (event) => {
    const isChecked = event.target.checked;
    await helper.sleep(200);
    if (isChecked) {
      confirm.show({
        applyButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        dialogTitle: 'Lock card confirmation',
        dialogText:
          'Are you sure you want to lock your card? This action will temporarily disable your card and prevent any new transactions until you unlock it. Please confirm your choice below.',
        cancelButtonType: 'text',
        cancelError: true,
        typeNew: true,
        async onApply() {
          await handleProcess(true, 'Your card has been successfully locked.');
        },
      });
    } else {
      confirm.show({
        applyButtonText: 'Unlock',
        cancelButtonText: 'Cancel',
        dialogText: 'Are you sure you want to unlock the card?',
        cancelButtonType: 'text',
        cancelError: true,
        typeNew: true,
        async onApply() {
          await handleProcess(false, 'Card unlocked.');
        },
      });
    }
  };
  return (
    <Switch
      disabled={isLoadingSuspendCardMutation || isLoadingActivateCardMutation || isProcessingBool.value}
      onClick={handleLockCard}
      checked={isChecked}
    />
  );
};
