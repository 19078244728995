import { Select, SelectItem, Radio } from 'shared/ui/inputs';
import styles from './styles.module.scss';
import { TransferType } from 'features/transfers/transfer-batch/model';
import { RadioGroup } from '@mui/material';
import { Typography } from 'shared/ui/typography';
import { Divider } from '@mui/material';
import { Button } from 'shared/ui/buttons';
import { FormikProps } from 'formik';
import { useCurrentUser } from '../../../../../../providers/user.provider';
interface FormValues {
  from: string;
  transferType: string;
}

interface BatchTransferTypeSelectFormProps {
  formik: FormikProps<FormValues>;
  optionsFromAcc: SelectItem[];
}

export const BatchTransferTypeSelectForm = (
  props: BatchTransferTypeSelectFormProps
) => {
  const { formik, optionsFromAcc } = props;
  const { isConsumer } = useCurrentUser();
  
  const disabedBtn =
    !formik.isValid || !formik.values.from || !formik.values.transferType;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.form}
    >
      <Typography>Select account</Typography>
      <Select
        label='From'
        options={optionsFromAcc}
        {...formik.getFieldProps('from')}
        error={formik.touched.from && formik.errors.from}
      />
      <Typography className={styles.label}>Select transfer type</Typography>
      <RadioGroup
        className={styles.radioGroup}
        {...formik.getFieldProps('transferType')}
      >
        {!isConsumer && (
          <Radio
            LabelProps={{ className: styles.radioLabelProps }}
            value={TransferType.toNetevia}
            label={
              <div className={styles.boxToNetevia}>
                <Typography>To Netevia’s customer</Typography>
                <Typography>Up to 10 transactions daily</Typography>
              </div>
            }
          />
        )}

        <Divider className={styles.divider} />

        <Radio
          LabelProps={{ className: styles.radioLabelProps }}
          value={TransferType.toAch}
          label='ACH transfer'
        />
      </RadioGroup>
      <Button
        type='submit'
        className={styles.btn}
        variant='contained'
        disabled={disabedBtn}
      >
        continue
      </Button>
    </form>
  );
};
