import { ResponseHandler } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { aesDecrypt } from '../shared/utils/crypto';
import { requireNotNull } from '../shared/utils/require-not-null';
import {
  getLocalStorageObject,
  LocalStorageKeys,
} from '../shared/utils/local-storage';
import { logOut } from './logOut';

class SecureResponseHandlerError extends Error {
  name = 'SecureResponseHandlerError';
}

interface SecureParams {
  aes?: string;
}

export const secureResponseHandler: ResponseHandler = async (response) => {
  if (response.status === 200) {
    try {
      if (
        [
          getLocalStorageObject(LocalStorageKeys.dmVy),
          getLocalStorageObject(LocalStorageKeys.dmU),
        ].some((val) => typeof val === 'undefined')
      ) {
        await logOut();
      }

      const results = (await response.json()) as SecureParams;
      const decryptedDataString = await aesDecrypt(requireNotNull(results.aes));
      return JSON.parse(requireNotNull(decryptedDataString));
    } catch (err) {
      throw new SecureResponseHandlerError('Get data error');
    }
  }
};
