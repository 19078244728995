import Button from "@mui/material/Button";

interface TabProps {
  name: string;
  secondary?: boolean;
  warning?: boolean;
  disabled?: boolean;
  width?: number;
  handleSubmit?: any;
  children?: any;
}

const Tab = ({
  name,
  secondary,
  warning,
  handleSubmit,
  children,
  disabled,
  width = 305,
}: TabProps) => {
  const secondaryColor = secondary ? "#939598" : "#51B9E5";
  const warningColor = warning ? "#FF8585" : "#51B9E5";
  const buttonColor = secondary ? secondaryColor : warningColor;
  return (
    <div className="flex justify-center">
      <Button
        variant="outlined"
        sx={{
          borderRadius: 6,
          textTransform: "none",
          width: { width },
          height: 48,
          borderColor: buttonColor,
          color: buttonColor,
          "@media print": {
            width: 272,
          },
        }}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {name}
        {children}
      </Button>
    </div>
  );
};

export default Tab;
