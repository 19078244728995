import { Typography } from 'shared/ui/typography'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { Button } from 'shared/ui/buttons'
import { useMediaQuery } from '@mui/material'
import styles from './styles.module.scss'
import { TransitionAnimation } from 'shared/ui/animations'

export const OpenVirtualCardStep2 = () => {
  const { close } = useDrawerStepperContext()

  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleClick = () => {
    close()
  }

  return (
    <TransitionAnimation containerProps={{ className: styles.animContainer }}>
      <div className={styles.root}>
        <Typography variant='h6'>
          Success! Your request for a virtual card is complete
        </Typography>

        <Typography variant='body1'>
          Your virtual card request has been processed successfully. Your card
          will be available within 5 to 10 minutes.
        </Typography>

        <Button
          fullWidth={isMobile}
          className={styles.button}
          onClick={handleClick}
        >
          Done
        </Button>
      </div>
    </TransitionAnimation>
  )
}
