import dayjs from 'dayjs';
import { DATE_30_DAYS_AGO } from 'shared/constants';

export const initialAccountTransactionFilter = {
  all: {
    displayName: 'Select all',
    value: true, 
    items: null,
  },
  deposit: {
    displayName: 'Deposits',
    value: false,
    items: null,
  },
  withdraw: {
    displayName: 'Withdrawals',
    value: false,
    items: null,
  },
  transfers: {
    displayName: 'Transfers',
    value: true,
    items: [
      {
        displayName: 'ACH transfers',
        value: false,
        items: null,
      },
      {
        displayName: 'Netevia transfers',
        value: false,
        items: null,
      },
    ],
  },
  pos: {
    displayName: 'Card transactions',
    value: true,
    items: [
      {
        displayName: 'Agricultural Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Airlines, Air Carriers',
        value: false,
        items: null,
      },
      {
        displayName: 'ATM transactions',
        value: false,
        items: null,
      },
      {
        displayName: 'Business Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Car Rental Agencies',
        value: false,
        items: null,
      },
      {
        displayName: 'Clothing Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Contracted Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Government Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Hotels, Motels, and Resorts',
        value: false,
        items: null,
      },
      {
        displayName: 'Miscellaneous Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Professional Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Retail Outlet Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Transportation Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Utility Services',
        value: false,
        items: null,
      },
    ],
  },
};

export const initialCardTransactionFilter = {
  all: {
    displayName: 'Select all',
    value: false,
    items: null,
  },
  deposit: {
    displayName: 'Deposits',
    value: false,
    items: null,
  },
  withdraw: {
    displayName: 'Withdrawals',
    value: false,
    items: null,
  },
  transfers: {
    displayName: 'Transfers',
    value: false,
    items: [
      {
        displayName: 'ACH transfers',
        value: false,
        items: null,
      },
      {
        displayName: 'Netevia transfers',
        value: false,
        items: null,
      },
    ],
  },
  pos: {
    displayName: 'Card transactions',
    value: true,
    items: [
      {
        displayName: 'Agricultural Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Airlines, Air Carriers',
        value: false,
        items: null,
      },
      {
        displayName: 'ATM transactions',
        value: false,
        items: null,
      },
      {
        displayName: 'Business Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Car Rental Agencies',
        value: false,
        items: null,
      },
      {
        displayName: 'Clothing Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Contracted Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Government Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Hotels, Motels, and Resorts',
        value: false,
        items: null,
      },
      {
        displayName: 'Miscellaneous Stores',
        value: false,
        items: null,
      },
      {
        displayName: 'Professional Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Retail Outlet Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Transportation Services',
        value: false,
        items: null,
      },
      {
        displayName: 'Utility Services',
        value: false,
        items: null,
      },
    ],
  },
  searchString: ''
};

export const initialTransactionApiParams = {
  dateFrom: DATE_30_DAYS_AGO,
  dateTo: dayjs().endOf('day'),
  amountFrom: '',
  amountTo: '',
};
