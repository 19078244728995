import { useSearchParams } from 'react-router-dom';
import { AnimateWrapper } from 'components/animate-wrapper';
import {
  useDefaultTransfersFinAccountControllerQuery,
  useChangeDefaultTransfersFinAccountControllerMutation,
} from 'api/endpoints/settings';
import { useFinancialAccountsControllerQuery } from 'api/endpoints/financialAccounts';
import styles from './style.module.scss';
import helper from 'services/helper';
import { useMediaQuery, List } from '@mui/material';
import DrawerModal from 'shared/components/drawerModal';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { Button } from 'shared/ui/buttons';
import { EnumFinancialAccountStatus } from 'api/endpoints/financialAccounts';
import { useSnackBar } from 'providers/snackBar.provider';
import { AddNewAccount } from './components/addNewAccount';
import { InfoIcon } from 'shared/ui/icons';
import { Typography } from 'shared/ui/typography';
import TooltipChildren from 'shared/components/toolTip/toolTipChildren';
import { primaryAccountToolTipText } from '../accountSettings/config/constants';
import PrimaryAccItem from './components/primaryAccItem';
import { EmptyItem } from './components/emptyItem';
import { Skeleton } from 'components/skeleton';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import CasesOutlinedIcon from '@mui/icons-material/CasesOutlined';
import { useDialog } from 'providers/dialogController.provider';

const PrimaryAccount = () => {
  const { setSnackBar } = useSnackBar();
  const dialog = useDialog();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || '';

  const isMobile = useMediaQuery('(max-width: 600px)');

  const { data, isFetching: primaryAccIsLoading } =
    useDefaultTransfersFinAccountControllerQuery({});
  const [
    changeFinAccountMutation,
    { isLoading: changeFinAccountMutationIsLading },
  ] = useChangeDefaultTransfersFinAccountControllerMutation();
  const {
    data: financialAccountsData,
    isFetching: financialAccountsIsLoading,
  } = useFinancialAccountsControllerQuery({});

  const financialAccounts = financialAccountsData?.financialAccounts;

  const primaryAcc = financialAccounts?.find(
    (account) => account.financialAccountId === data?.finAccountId
  );

  const activeAccounts = [...(financialAccounts || [])]
    .filter(
      (account) =>
        account.status === EnumFinancialAccountStatus.active &&
        account.financialAccountId !== data?.finAccountId
    )
    .sort((a, b) => Number(new Date(a.created)) - Number(new Date(b.created)));

  const handleNewAccount = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.append('mode', 'NewAccount');
    newSearchParams.append('title', 'NewAccount');
    setSearchParams(newSearchParams);
  };

  const handleChangePrimaryAcc = async (id: string) => {
    const accFrom = primaryAcc?.name;
    const accTo = financialAccounts?.find(
      (account) => account?.financialAccountId === id
    )?.name;
    dialog.show({
      dialogTitle: 'Primary account change',
      dialogText: `You’re about to switch your primary account from “${accFrom} business account” to “${accTo} business account”. To proceed, simply press “Confirm”`,
      slotApplyButton: <Button variant='text'>Confirm</Button>,
      slotCancelButton: (
        <Button
          variant='text'
          color='error'
        >
          Cancel
        </Button>
      ),
      async onApply() {
        try {
          await changeFinAccountMutation({
            finAccountId: id,
          }).unwrap();
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };
  const renderPrimaryAccount = () => {
    if (!primaryAcc) {
      return (
        <EmptyItem
          icon={<CardTravelOutlinedIcon className={styles.emptyIcon} />}
          title='No primary account set'
          subTitle="Looks like you don't have a primary account set at this time."
        />
      );
    }

    return <PrimaryAccItem item={primaryAcc} />;
  };

  const renderOtherAccList = () => {
    if (!activeAccounts?.length) {
      return (
        <EmptyItem
          icon={<CasesOutlinedIcon className={styles.emptyIcon} />}
          title='No additional accounts added'
          subTitle={`Looks like you don't have any additional accounts added.`}
        />
      );
    }

    return (
      <List
        disablePadding
        className={styles.list}
      >
        {activeAccounts.map((item) => (
          <PrimaryAccItem
            key={item.financialAccountId}
            item={item}
            handleSumbit={handleChangePrimaryAcc}
            sumbitIsLoading={changeFinAccountMutationIsLading}
          />
        ))}
      </List>
    );
  };

  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        {isMobile && (
          <div className={styles.container_nav}>
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
            <span className={styles.container_nav_title}>Primary Account</span>
          </div>
        )}
        <Button
          onClick={handleNewAccount}
          className={styles.btn}
        >
          + OPEN NEW ACCOUNT
        </Button>
        <div className={styles.box}>
          <div className={styles.titlePrimary}>
            <Typography>Your Primary Account</Typography>
            <TooltipChildren description={primaryAccountToolTipText}>
              <div>
                <InfoIcon />
              </div>
            </TooltipChildren>
          </div>
          {primaryAccIsLoading || financialAccountsIsLoading ? (
            <Skeleton
              width='100%'
              height='72px'
            />
          ) : (
            renderPrimaryAccount()
          )}
        </div>
        <div className={styles.box}>
          <Typography>Other Accounts</Typography>
          {financialAccountsIsLoading ? (
            <Skeleton
              width='100%'
              height='72px'
            />
          ) : (
            renderOtherAccList()
          )}
        </div>
      </div>
      <DrawerModal
        isShow={searchParams.get('mode') === 'NewAccount'}
        onClose={() => setSearchParams({ page })}
        goBack={undefined}
      >
        <AddNewAccount />
      </DrawerModal>
    </AnimateWrapper>
  );
};

export default PrimaryAccount;
