import React, { FC, ReactNode } from "react";

import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./style.module.scss";
import { AnimateWrapper } from "components/animate-wrapper";
import Button from "@mui/material/Button";

interface ForgotFormProps {
  children?: ReactNode;
  title: string;
  subTitle: string | ReactNode;
  inputLabel: string;
  inputValue: string;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  sumbitButtonText: string;
  onSumbit: () => void;
  cancelButtonText: string;
  onCancel: () => void;
  error?: string;
  inputMaxLength?: number;
  disable?: boolean;
}
const ForgotForm: FC<ForgotFormProps> = ({
  children,
  title,
  subTitle,
  inputLabel,
  inputValue,
  handleOnChange,
  isLoading,
  sumbitButtonText,
  onSumbit,
  cancelButtonText,
  onCancel,
  error,
  inputMaxLength,
  disable,
}) => {
  const renderSubTitle = () => {
    if (typeof subTitle === "string") {
      return <p className={styles.title_subTitleText}>{subTitle}</p>;
    } else {
      return subTitle;
    }
  };
  return (
    <AnimateWrapper className="fade">
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <span className={styles.title_text}>{title}</span>
          {renderSubTitle()}
        </div>
        <div>
          <TextField
            fullWidth
            label={inputLabel}
            variant="outlined"
            value={inputValue}
            onChange={handleOnChange}
            error={!!error}
            inputProps={{
              maxLength: inputMaxLength ? inputMaxLength : 40,
              autoComplete: "off",
            }}
          />
          {error && <p className={styles.error}>{error}</p>}
          {children && children}
        </div>
        <div className={styles.buttonGroup}>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={disable ? disable : !inputValue}
            onClick={onSumbit}
            fullWidth
            sx={{
              backgroundColor: "#023047",
            }}
          >
            {sumbitButtonText}
          </LoadingButton>
          <Button
            variant="text"
            onClick={onCancel}
            fullWidth
            sx={{
              color: "#187AC9",
            }}
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default ForgotForm;
