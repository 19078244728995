import { useEffect, FC } from 'react';
import { usePendingLinkedAccountControllerQuery } from 'api/endpoints/linkedAccount';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import {
  CONNECTED_ACCOUNT,
  CONNECTE_ACCOUNT,
} from 'pages/settings/accountSettings/searchParamsTypes';
import { useDialog } from 'providers/dialogController.provider';
import { Button } from 'shared/ui/buttons';
interface NewAccessRequestForNavbarProps {
  onClose: () => void;
}
const NewAccessRequestForNavbar: FC<NewAccessRequestForNavbarProps> = ({
  onClose,
}) => {
  const { t } = useTranslationProvider();
  const dialog = useDialog()
  const navigate = useNavigate();
  const {
    data: getPendingConnectedAccounts,
    isSuccess: getPendingConnectedAccountsIsSuccess,
  } = usePendingLinkedAccountControllerQuery({});

  useEffect(() => {
    if (
      !!getPendingConnectedAccounts?.length &&
      getPendingConnectedAccountsIsSuccess
    ) {
      updateLocalStorageIfChanged();
    }
  }, [getPendingConnectedAccountsIsSuccess]);

  const saveToLocalStarage = () => {
    const connectedAcc = JSON.stringify(getPendingConnectedAccounts);
    localStorage.setItem('connectedAcc', connectedAcc);
  };

  const getFromLocalStorage = () => {
    const connectedAccFromLocal = localStorage.getItem('connectedAcc');
    if (connectedAccFromLocal !== null) {
      const oldConnectedAcc = JSON.parse(connectedAccFromLocal);
      return oldConnectedAcc;
    }
  };

  const updateLocalStorageIfChanged = () => {
    const getArr = getFromLocalStorage();
    const areArraysEqual =
      JSON.stringify(getPendingConnectedAccounts) === JSON.stringify(getArr);

    if (areArraysEqual) {
      return;
    } else {
      saveToLocalStarage();
      showPendingConnectedAccounts();
    }
  };


  const showPendingConnectedAccounts = async () => {

    dialog.show({
      dialogTitle: 'Access request to your account',
      dialogText: `You've received an access request from another user. Review and respond by clicking “See Request” now, or access it later in the “Account Settings” section.`,
      slotApplyButton: <Button variant='text'>See request</Button>,
      slotCancelButton: <Button variant='text' color='error'>Close</Button>,
      async onApply() {
        navigate({
          pathname: '/settings',
          search: createSearchParams({
            page: CONNECTED_ACCOUNT,
            pageMode: CONNECTE_ACCOUNT,
          }).toString(),
        });
      },
    })
    onClose();
  }
  return <></>;
};
export default NewAccessRequestForNavbar;
