import { Tabs } from 'shared/ui/tabs';
import styles from './styles.module.scss';
import { Content } from '../content';
import { NotificationsGroup } from 'enums';

export const Intro = () => {
  const data = [
    {
      id: '0',
      label: 'notifications',
      component: <Content contentGroup={NotificationsGroup.Notifications} />, 
    },
    {
      id: '1',
      label: 'messages',
      component: <Content contentGroup={NotificationsGroup.Messages} />,
    },
    {
      id: '2',
      label: 'campaigns',
      component: <Content contentGroup={NotificationsGroup.Campaigns} />,
    },
  ];
  return (
    <div className={styles.container}>
      <Tabs data={data} styleWrapper={styles.list}/>
    </div>
  );
};
