import SimpleContainer from "shared/components/containers/simpleContainer";
import { CardInformationPage } from "./CardInformationPage";
import { CardInformationProvider } from './provider';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../routes';
import { useCardRouteParams } from '../../shared/hooks/useCardRouteParams';
import { useFinancialAccounts } from '../../api/hooksApi/useFinancialAccounts';

const CardWrapper = () => {
  const navigate = useNavigate();
  const { accountId } = useCardRouteParams();
  const { unclosedFinancialAccounts } = useFinancialAccounts();

  const handleNavigateClick = () => {
    if (unclosedFinancialAccounts.find(fa => fa.financialAccountId === accountId)) {
      navigate(appRoutes.accountById(accountId, { accountTab: 'cards' }));
    } else {
      navigate(appRoutes.home())
    }
  };

  return (
    <SimpleContainer title="Your Card" onTitleClick={handleNavigateClick}>
      <CardInformationPage />
    </SimpleContainer>
  )
}

export const CardComponent = () => {
  return (
    <CardInformationProvider>
      <CardWrapper />
    </CardInformationProvider>
  );
};
