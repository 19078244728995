import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { GetScheduledTransfer } from 'api/endpoints/scheduledTransfer';
import { ScheduleTransferType } from 'entities/transfers/config';
import helper from 'services/helper';
import styles from './styles.module.scss';

export const createOptionsAutomationsAcc = (
  accounts: FinancialAccount[],
  sheduleTransfers: GetScheduledTransfer[],
  accountIdToRemove: string = '',
  editScheduledTransfersId: number | null = null
) => {
  const sheduleTransfersAutoMations = sheduleTransfers?.filter(
    (transfer) =>
      (transfer.transferType === ScheduleTransferType.AutoPopupFromOwnAccount ||
        transfer.transferType === ScheduleTransferType.AutoPopup) &&
      transfer.id !== editScheduledTransfersId
  );

  const accountIdsToRemove = new Set(
    sheduleTransfersAutoMations.flatMap(
      ({ fromFinancialAccountId, toFinancialAccountId }) => [
        fromFinancialAccountId,
        toFinancialAccountId,
      ]
    )
  );

  return accounts
    .filter(
      (account) =>
        !accountIdsToRemove.has(account.financialAccountId) &&
        account.financialAccountId !== accountIdToRemove
    )
    .map((account) => ({
      id: account.financialAccountId,
      value: account.financialAccountId,
      content: (
        <div className={styles.dropDown}>
          <div>{`${account.name} (${account.accountNumber.slice(-4)})`}</div>
          <div>{`$${
            helper.moneyFormat(account?.availableCash?.value) || ''
          }`}</div>
        </div>
      ),
    }));
};
