import { useRef, FC, useState, useEffect } from "react";
import styles from "./style.module.scss";
import BeatLoader from "react-spinners/BeatLoader";
import { Popup } from "components/popup";
import MultiFactorInput from "../multiFactorAuth/components/multiFactorInput";
import { useTranslationProvider } from "providers/translation/translation.provider";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TimerSMS from "../forgotPassword/components/timerSMS";
import {
  useSendUnlockOtpControllerMutation,
  useValidateUnlockOtpControllerMutation,
} from "api/endpoints/auth";

interface UnlockProfileWithCodeVerificateProps {
  onClose: (boolean) => void;
  onSumbit: (username: string, password: string) => void;
  username: string;
  password: string;
  pathPhone: string;
}

type CodeItem = string;
const TIMER = 60;

const unlockProfileWithCodeVerificate: FC<
  UnlockProfileWithCodeVerificateProps
> = ({ username, password, pathPhone, onSumbit, onClose }) => {
  const { t } = useTranslationProvider();
  const inputRefs = useRef<Array<HTMLInputElement>>(
    Array(6).fill(HTMLInputElement)
  );
  const [sendUnlockOtpCodeSms, { isLoading: IsLoadingsendUnlockOtpCodeSms }] =
    useSendUnlockOtpControllerMutation();
  const [validateUnlockOtp, { isLoading: isLoadingValidateUnlockOtp }] =
    useValidateUnlockOtpControllerMutation();
  const [nextSendDate, setNextSendDate] = useState<Date | undefined>();
  const [isValid, setIsvalid] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");

  const errorMassage =
    errors === "Invalid code"
      ? t("common.The entered code is not correct", {
          defaultValue: "The entered code is not correct",
        })
      : errors;

  const messageContent = (
    <>
      {t(
        "Please enter the 6-digit security code received via text message sent tonumber ending",
        {
          defaultValue:
            "Please enter the 6-digit security code received via text message sent to number ending",
        }
      )}{" "}
      <span>{pathPhone}</span>
    </>
  );

  useEffect(() => {
    handleSendCodeVerification();
    handleInputFocus(0, 0);
  }, []);

  useEffect(() => {
    if (errorMassage || errors) {
      clearInputs();
      handleInputFocus(0, 0);
    }
  }, [errorMassage, errors]);

  const unlockProfile = async (oneTimeCode) => {
    try {
      await validateUnlockOtp({
        nickName: username,
        code: oneTimeCode,
      }).unwrap();
      onClose(false);
      onSumbit(username, password);
    } catch (e: any) {
      console.log("error", e);
      setErrors(e.data);
    }
  };
  const handleSendCodeVerification = async () => {
    try {
      await sendUnlockOtpCodeSms({
        nickName: username,
      }).unwrap();
      const currentDate = new Date();
      const nextSendDate = new Date(currentDate.getTime() + TIMER * 1000);
      setNextSendDate(nextSendDate);
      setErrors("");
    } catch (e: any) {
      console.log(`Error: `, e);
      setErrors(e.data);
    }
  };

  const getInputsValue = () =>
    inputRefs.current.map((inputRef) => (inputRef ? inputRef.value : ""));

  const clearInputs = () =>
    inputRefs.current.map((inputRef) => inputRef && (inputRef.value = ""));

  const clearError = () => {
    errors && setErrors("");
  };

  const handleChangeCode = (value: CodeItem, index: number) => {
    if (Number(value) || value === "0") {
      inputRefs.current[index].value = value;
      handleInputFocus(index, 1);
      clearError();
    } else if (!value && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].value = "";
      handleInputFocus(index, -1);
    } else {
      inputRefs.current[index].value = "";
    }

    if (getInputsValue().every((item) => Number(item) || item === "0")) {
      setIsvalid(true);
    } else {
      setIsvalid(false);
    }
  };

  const handleSumbit = () => {
    const oneTimeCode = getInputsValue().join("");
    unlockProfile(oneTimeCode);
  };

  const handleInputFocus = (index: number, step: number) => {
    if (inputRefs.current[index + step]) {
      inputRefs.current[index + step]?.focus();
    }
  };

  const renderTimerOrResend = () => {
    return nextSendDate ? (
      <TimerSMS targetDate={nextSendDate!} onTimerComplete={setNextSendDate!} />
    ) : (
      <Button
        onClick={handleSendCodeVerification}
        variant="text"
        sx={{
          color: "#187AC9",
        }}
      >
        {t("common.Re-send code", {
          defaultValue: "Re-send code",
        })}
      </Button>
    );
  };
  return (
    <Popup stylesWrapper={styles.popup}>
      <div className={styles.container}>
        <span className={styles.container_title}>
          {t("Login.Confirm login with one time password", {
            defaultValue: "Confirm login with one time password",
          })}
        </span>
        <div className={styles.container_subTitle}>{messageContent}</div>
        <div className={styles.boxTimer}>
          {IsLoadingsendUnlockOtpCodeSms ? (
            <BeatLoader />
          ) : (
            renderTimerOrResend()
          )}
        </div>
        <div className={styles.container_input}>
          {getInputsValue().map((codeItem, index) => (
            <MultiFactorInput
              inputRef={(el: HTMLInputElement) =>
                (inputRefs.current[index] = el)
              }
              key={index}
              index={index}
              value={codeItem}
              onChange={handleChangeCode}
            />
          ))}
        </div>
        {errors && <p className={styles.errorMessage}>{errorMassage}</p>}
        <div className={styles.container_buttonGroup}>
          <Button variant="text" onClick={onClose} color="error">
            {t("common.Cancel", {
              defaultValue: "Cancel",
            })}
          </Button>
          <LoadingButton
            variant="text"
            onClick={handleSumbit}
            disabled={!isValid}
            loading={isLoadingValidateUnlockOtp}
          >
            {t("common.Confirm", {
              defaultValue: "Confirml",
            })}
          </LoadingButton>
        </div>
      </div>
    </Popup>
  );
};
export default unlockProfileWithCodeVerificate;
