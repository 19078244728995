import { FC } from "react";
import Radio from "@mui/material/Radio";
import styles from "./styles.module.scss";

interface SelectTransferPointItemProps {
  id: number;
  name: string;
  phone: string;
  handleChooseAcc: (id: number) => void;
  checked: boolean;
  selectItem: any;
}

const SelectTransferPointItem: FC<SelectTransferPointItemProps> = ({
  id,
  name,
  phone,
  handleChooseAcc,
  checked,
  selectItem,
}) => {
  return (
    <div className={styles.wrapper}>
      <Radio
        checked={checked}
        onChange={() => handleChooseAcc(id)}
        value={id}
        name="radio-buttons"
      />
      <div className={styles.wrapper_box}>
        <span>{name}</span>
        <span>{phone}</span>
      </div>
    </div>
  );
};

export default SelectTransferPointItem;
