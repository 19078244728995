import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import { MagnifyingGlassIcon } from 'shared/ui/icons';
import styles from './style.module.scss';

export const EmptyItem = () => {
  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <MagnifyingGlassIcon />
        <Typography className={styles.text}>
          We couldn’t find anything that matches your request
        </Typography>
      </div>
    </TransitionAnimation>
  );
};
