import { Select } from 'shared/ui/inputs';
import helper from 'services/helper';
import styles from './style.module.scss';
import { Skeleton } from 'components/skeleton';
import { MenuItem } from '@mui/material';
import { Divider } from '@mui/material';
import { formatAccountName } from 'shared/lib/format';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
interface SelectAccountProps {
  value: string;
  onChange: (value: string) => void;
  accounts: FinancialAccount[]
  loading: boolean;
  fullWidth?: boolean;
  showAll?: boolean;

}

export const SelectAccount = (props: SelectAccountProps) => {
  const  { value, onChange, fullWidth = false, showAll = false , accounts, loading} = props
    
  return loading ? (
    <Skeleton
      width='100%'
      height='55px'
    />
  ) : (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth={fullWidth}
    >
      {accounts?.map((account) => (
        <MenuItem
          key={account.financialAccountId}
          value={account.financialAccountId}
        >
          <div className={styles.dropDown}>
            <div>
              {formatAccountName(account?.name)}{' '}
              {account?.accountNumber && `(****${account.accountNumber.slice(-4)})`}
            </div>
            {account?.availableCash && (
              <div>$ {helper.moneyFormat(account.availableCash?.value)}</div>
            )}
          </div>
        </MenuItem>
      ))}
      {showAll && <Divider className={styles.divider} />}
      {showAll &&
          <MenuItem
            key={'All accounts'}
            value={'All accounts'}
          >
            All accounts
          </MenuItem>
      }
    </Select>
  );
};
