import { useOpenPartnerCardPhysicalMutation } from 'api/endpoints/paymentCards';
import { OrderOutCardModel } from 'entities/orderout-card';
import { useState } from 'react';
import { useAppSelector } from 'shared/models/hooks';
import { usePartnerProductIdByName } from '../../../api/hooksApi/usePartnerProductIdByName';
import { requireNotNull } from '../../../shared/utils/require-not-null';

export const useOpenPhysicalCard = () => {
  const [isLoading, setIsLoading] = useState(false);

  const partnerProduct = usePartnerProductIdByName();
  const [openPartnerCardPhysical] = useOpenPartnerCardPhysicalMutation();

  const deliveryInfo = useAppSelector(OrderOutCardModel.selectdeliveryInfo);

  const openPhysicalCard = async () => {
    const result = { isError: false, errorMessage: '' };

    setIsLoading(true);

    try {
      const productId = await partnerProduct.getProductId('OrderOut');
      await openPartnerCardPhysical({
        cardProductId: requireNotNull(productId),
        courier: { method: deliveryInfo.method },
        deliveryDetails: {
          companyName: deliveryInfo.companyName,
          name: {
            givenName: deliveryInfo.firstName,
            familyName: deliveryInfo.lastName,
          },
          address: {
            streetAddress: deliveryInfo.address,
            extendedAddress: deliveryInfo.additionalAddress ?? '',
            locality: deliveryInfo.city,
            countryCodeAlpha3: deliveryInfo.countryCodeAlpha3,
            region: deliveryInfo.state,
            postalCode: deliveryInfo.postCode,
          },
        },
      }).unwrap();
    } catch (error) {
      result.isError = true;
      const errData = (error as any)?.data;
      result.errorMessage = errData
        ? Object.values(errData).map(item => Array.isArray(item) ? item.join('\n') : item).join(`\n`)
        : 'Unknown error';
    } finally {
      setIsLoading(false);
      return result;
    }
  };

  return {
    openPhysicalCard,
    isLoading,
  };
};
