import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../../baseApi";
import { ApiTags } from "../../api-tags";

export interface LinkedAccountApi {
  id: number;
  nickName: string;
  firstName: string;
  lastName: string;
  status: string;
  dba: string;
  created: Date;
  type: number;
}
export interface LinkedAccountMeApi {
  id: number;
  nickName: string;
  firstName: string;
  lastName: string;
  dba: string;
  type: number;
}
export interface PendingLinkedAccountApi {
  id: number;
  nickName: string;
  firstName: string;
  lastName: string;
  dba: string;
  createdDate: Date;
  type: number;
}
interface DeleteAccountArg {
  userId: number;
}
interface RequestLinkedAccountArg {
  userName: string;
}

interface AcceptLinkedAccountArg {
  userId: number;
  oneTimeCode: string;
  secureOperationType: string;
}
export const linkedAccountApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: "linkedAccount",
  tagTypes: [
    ApiTags.GetlinkedAccount,
    ApiTags.GetlinkedAccountMe,
    ApiTags.GetPendingLinkedAccount,
  ],
  endpoints: (builder) => ({
    linkedAccountController: builder.query<LinkedAccountApi[], any>({
      providesTags: [ApiTags.GetlinkedAccount],
      query: () => ({
        url: `api/LinkedAccount`,
        method: "GET",
      }),
    }),
    linkedAccountMeController: builder.query<LinkedAccountMeApi[], any>({
      providesTags: [ApiTags.GetlinkedAccountMe],
      query: () => ({
        url: `api/LinkedAccount/users`,
        method: "GET",
      }),
    }),
    pendingLinkedAccountController: builder.query<
      PendingLinkedAccountApi[],
      any
    >({
      providesTags: [ApiTags.GetPendingLinkedAccount],
      query: () => ({
        url: `api/LinkedAccount/requests`,
        method: "GET",
      }),
    }),
    deleteLinkedAccountController: builder.mutation<any, DeleteAccountArg>({
      invalidatesTags: [ApiTags.GetlinkedAccount],
      query: ({ userId }) => ({
        url: `api/LinkedAccount/${userId}`,
        method: "DELETE",
      }),
    }),
    deleteLinkedAccountMeController: builder.mutation<any, DeleteAccountArg>({
      invalidatesTags: [
        ApiTags.GetlinkedAccountMe,
        ApiTags.GetPendingLinkedAccount,
      ],
      query: ({ userId }) => ({
        url: `api/LinkedAccount/user/${userId}`,
        method: "DELETE",
      }),
    }),
    requestLinkedAccountController: builder.mutation<
      any,
      RequestLinkedAccountArg
    >({
      invalidatesTags: [ApiTags.GetlinkedAccount],
      query: ({ userName }) => ({
        url: `api/LinkedAccount/request`,
        method: "POST",
        params: {
          userName,
        },
      }),
    }),

    acceptLinkedAccountController: builder.mutation<
      any,
      AcceptLinkedAccountArg
    >({
      invalidatesTags: [
        ApiTags.GetlinkedAccountMe,
        ApiTags.GetPendingLinkedAccount,
      ],
      query: (queryArg) => ({
        url: `api/LinkedAccount/accept`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
});

export const {
  useLinkedAccountControllerQuery,
  useLinkedAccountMeControllerQuery,
  usePendingLinkedAccountControllerQuery,
  useDeleteLinkedAccountControllerMutation,
  useDeleteLinkedAccountMeControllerMutation,
  useRequestLinkedAccountControllerMutation,
  useAcceptLinkedAccountControllerMutation,
} = linkedAccountApi;
