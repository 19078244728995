import { Typography } from 'shared/ui/typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormikProps } from 'formik';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Dayjs } from 'dayjs';

interface FormValues {
  date: [Dayjs | null, Dayjs | null];
}

interface FilterByDateProps {
  formik: FormikProps<FormValues>;
}

export const FilterByDate = ({ formik }: FilterByDateProps) => {
  return (
    <>
      <Typography>Date range</Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          disableFuture
          localeText={{ start: 'From', end: 'To' }}
          calendars={1}
          value={formik.values.date}
          onChange={(newDateRange) => {
            formik.setFieldValue('date', newDateRange);
          }}
          slotProps={{
            textField: {
              error: !!formik.errors.date,
            },
            fieldSeparator: {
              display: 'none',
            },
            fieldRoot: {
              direction: 'row',
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};
