import { PropsWithChildren, createContext, useMemo, useContext } from 'react';
import { useBoolean } from '../shared/hooks/useBoolean';
import { requireNotNull } from '../shared/utils/require-not-null';

interface ContextParams {
  lockForClose(): void;
  unlockForClose(): void;
  setLockForCloseStatus(flag: boolean): void;

  isCloseDisabled: boolean;
}

const Context = createContext<ContextParams | undefined>(undefined);

export const DrawerBehaviorProvider = ({ children }: PropsWithChildren) => {
  const lockedBool = useBoolean();

  const value: ContextParams = useMemo(() => {
    return {
      lockForClose: lockedBool.setTrue,
      unlockForClose: lockedBool.setFalse,
      isCloseDisabled: lockedBool.value,
      setLockForCloseStatus: lockedBool.setValue,
    }
  }, [lockedBool.value]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
};

export const useDrawerBehavior = () => requireNotNull(useContext(Context));
