import { useState } from 'react';
import styles from './style.module.scss';
import { ListButton } from 'shared/ui/lists';
import { ChevronRightIcon } from 'shared/ui/icons';
import { Button } from 'shared/ui/buttons';
import { ToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account';
import { useAppDispatch } from 'shared/models';
import { clearDataToMyOwnAccount } from 'features/transfers/transfer-to-my-own-account/model';
import { ToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer';
import { clearDataToNeteviaCustomer } from 'features/transfers/transfer-to-netevia-customer/model';
import { getNextKey } from 'entities/transfers';
import { AchTransfer } from 'features/transfers/transfer-ach';
import { clearDataAch } from 'features/transfers/transfer-ach/model';
import { TransitionAnimation } from 'shared/ui/animations';
import {
  GroupPersonIcon,
  BankIcon,
  ProfileInCircleIcon,
  RefreshIcon,
  SwapIcon,
} from 'shared/ui/icons';
import { FromConnectedAccount } from 'features/transfers/transfer-from-connected-account';
import { clearDataFromConnectedAccount } from 'features/transfers/transfer-from-connected-account/model';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { appRoutes, TransferRoute, TransferQs } from 'routes';
import { clearDataBatch } from 'features/transfers/transfer-batch/model';
import { ClockRestoreIcon } from 'shared/ui/icons';
import { TransferHistory } from './components/transferHistory';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useCurrentUser } from 'providers/user.provider';

const Transfer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isConsumer, isSubUser, subUserRights } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMode = searchParams.get('mode') || '';
  const step = searchParams.get('step') || '';

  const showTransferHistoryBool = useBoolean();

  const showBetweenOwnAccountBool = useBoolean(
    currentMode === TransferQs.betweenOwnAccounts
  );
  const showToNeteviaCustomerBool = useBoolean();
  const showAchTransferrBool = useBoolean();
  const showFromConnectedAccountBool = useBoolean(
    currentMode === TransferQs.fromConnectedAccount
  );

  const [keyToMyOwnAccount, setKeyToMyOwnAccount] =
    useState('toMyOwnAccount_1');
  const [keyToNeteviaCustomer, setKeyToNeteviaCustomer] = useState(
    'toNeteviaCustomer_1'
  );
  const [keyAchTransfer, setKeyAchTransfer] = useState('achTransfer_1');
  const [keyFromConnectedAccount, setKeyFromConnectedAccount] = useState(
    'fromConnectedAccount_1'
  );

  const handleCloseToMyOwnAccount = () => {
    dispatch(clearDataToMyOwnAccount());
    setKeyToMyOwnAccount((prevKey) => getNextKey(prevKey));
    showBetweenOwnAccountBool.setFalse();
    setSearchParams({});
  };

  const handleCloseToNeteviaCustomer = () => {
    dispatch(clearDataToNeteviaCustomer());
    setKeyToNeteviaCustomer((prevKey) => getNextKey(prevKey));
    showToNeteviaCustomerBool.setFalse();
  };
  const handleCloseAchTransfer = () => {
    dispatch(clearDataAch());
    setKeyAchTransfer((prevKey) => getNextKey(prevKey));
    showAchTransferrBool.setFalse();
  };
  const handleCloseFromConnectedAccount = () => {
    dispatch(clearDataFromConnectedAccount());
    setKeyFromConnectedAccount((prevKey) => getNextKey(prevKey));
    showFromConnectedAccountBool.setFalse();
    setSearchParams({});
  };
  const handleNavigateToBatchTransfer = () => {
    dispatch(clearDataBatch());
    navigate(appRoutes.transfer(TransferRoute.batchTransfer));
  };

  return (
    <TransitionAnimation>
      {showBetweenOwnAccountBool.value && (
        <ToMyOwnAccount
          key={keyToMyOwnAccount}
          isShow={showBetweenOwnAccountBool.value}
          onClose={handleCloseToMyOwnAccount}
          startStep={step}
        />
      )}
      {showToNeteviaCustomerBool.value && (
        <ToNeteviaCustomer
          key={keyToNeteviaCustomer}
          isShow={showToNeteviaCustomerBool.value}
          onClose={handleCloseToNeteviaCustomer}
        />
      )}
      {showAchTransferrBool.value && (
        <AchTransfer
          key={keyAchTransfer}
          isShow={showAchTransferrBool.value}
          onClose={handleCloseAchTransfer}
        />
      )}

      {showFromConnectedAccountBool.value && (
        <FromConnectedAccount
          key={keyFromConnectedAccount}
          isShow={showFromConnectedAccountBool.value}
          onClose={handleCloseFromConnectedAccount}
          startStep={step}
        />
      )}

      <div className={styles.container}>
        <span>Transfer</span>
        <div>
          <span>Within Netevia</span>
          <ListButton>
            <Button
              startIcon={<RefreshIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => showBetweenOwnAccountBool.setTrue()}
            >
              Between own accounts
            </Button>
            {!isConsumer && (
              <Button
                startIcon={<ProfileInCircleIcon />}
                endIcon={<ChevronRightIcon />}
                onClick={() => showToNeteviaCustomerBool.setTrue()}
              >
                To Netevia’s customer
              </Button>
            )}
          </ListButton>
        </div>
        <div>
          <span>Outside Netevia</span>
          <ListButton>
            <Button
              startIcon={<BankIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => showFromConnectedAccountBool.setTrue()}
            >
              From connected account
            </Button>
            <Button
              startIcon={<SwapIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => showAchTransferrBool.setTrue()}
            >
              ACH transfer
            </Button>
          </ListButton>
        </div>
        {Boolean(
          !isSubUser || (isSubUser && subUserRights({ mainAccess: true }))
        ) && (
          <div>
            <span>Other</span>
            <ListButton>
              <Button
                startIcon={<GroupPersonIcon />}
                endIcon={<ChevronRightIcon />}
                onClick={handleNavigateToBatchTransfer}
              >
                Batch transfer
              </Button>
            </ListButton>
          </div>
        )}
        <div>
          <span>History</span>
          <ListButton>
            <Button
              startIcon={<ClockRestoreIcon />}
              endIcon={<ChevronRightIcon />}
              onClick={() => showTransferHistoryBool.setTrue()}
            >
              Transfer history
            </Button>
          </ListButton>
        </div>
        {showTransferHistoryBool.value && (
          <TransferHistory
            isShow={showTransferHistoryBool.value}
            onClose={() => showTransferHistoryBool.setFalse()}
          />
        )}
      </div>
    </TransitionAnimation>
  );
};

export default Transfer;
