import { useExternalBanks } from "api/hooksApi/useExternalBanks"
import TableBlock from "shared/components/TableBlock";
import helper from "services/helper";
import { Typography } from "@mui/material";
import { useNavigateToExternal } from "pages/home/hooks";

import NoContent from "./NoContent";
import { formatAccountName } from "shared/lib/format";
import ButtonViewDetails from "pages/home/components/externalBanks/components/ButtonViewDetails";
import AddExternalBank from "pages/home/components/externalBanks/components/AddExternalBank";
import { AccountInfo } from "./AccountInfo";
import styles from '../style.module.scss'

const tabCells = () => [
  {
    label: "Financial Account",
    transform: (_, account) => {
      return <AccountInfo 
        last4={account.externalBankAccountDetails.last4} 
        name={formatAccountName(account.name)} 
        provider={account.provider} 
      />
    }
  },
  {
    label: "Account holder name",
    name: "owners",
    transform: (owners) => {
      const owner = owners?.[0]?.names[0];

      if (!owner) {
        return null;
      }

      return (
        <Typography>
          {owners?.[0]?.names[0] ?? 'card holder'}
        </Typography>
      )
   
    },
  },
  {
    label: "Available balance",
    name: "balanceInfo",
    transform: (balance: { value: number } | null) => {
      if (!balance) return null;

      return (
      <Typography>
        ${helper.moneyFormat(balance?.value) || ''}
      </Typography>
    )},
  },
  {
    label: '',
    name: 'id',
    props: { align: 'right' },
    transform: (bankId: string) => {
      return <ButtonViewDetails bankName={bankId} />
    }
  }
]

export const AccountsList = () => {
  const { externalBank } = useExternalBanks();
  const { navigateToExternalAccount } = useNavigateToExternal();

  return (
    <div className={styles.container}>
      <TableBlock
        rows={externalBank.accounts}
        tabCells={tabCells()}
        title='Financial Accounts'
        Action={AddExternalBank}
        NoContent={NoContent}
        onClick={navigateToExternalAccount}
        isRowsClickable
        hasMobileVersion={false}
      />
    </div>
  )
}