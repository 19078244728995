import { Select, SelectItem, TextInput } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { CloseOutlineGrayIcon } from 'shared/ui/icons';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import styles from './styles.module.scss';
import { FormikProps, FormikErrors } from 'formik';
import { TransferItem } from 'features/transfers/transfer-batch/model';

interface FormValues {
  forms: TransferItem[];
}
interface BatchTransferFormItemProps {
  optionsFromAcc: SelectItem[];
  optionsToPayee: SelectItem[];
  formIndex: number;
  handleDeleteTransfer: (index: number) => void;
  applyAllAmount: (amount: string) => void;
  applyAllMemo: (memo: string) => void;
  formik: FormikProps<FormValues>;
}

export const BatchTransferFormItem = (props: BatchTransferFormItemProps) => {
  const {
    optionsFromAcc,
    optionsToPayee,
    formIndex,
    handleDeleteTransfer,
    applyAllAmount,
    applyAllMemo,
    formik,
  } = props;

  const isMobile = useMediaQuery('(max-width: 600px)');

  const renderButtonDelete = () => (
    <IconButton
      onClick={() => handleDeleteTransfer(formIndex)}
      disabled={isDeleteDisabled}
    >
      <CloseOutlineGrayIcon />
    </IconButton>
  );
  const isDeleteDisabled = formik.values.forms.length === 1;
  return (
    <div className={styles.container}>
      <div className={styles.wrapperForm}>
        {!isMobile && (
          <Typography className={styles.title}>#{formIndex + 1}</Typography>
        )}
        {isMobile && (
          <div className={styles.isMobileTitle}>
            <Typography className={styles.title}>
              Transfer #{formIndex + 1}
            </Typography>
            {renderButtonDelete()}
          </div>
        )}
        <div className={styles.box}>
          <Select
            className={styles.boxInput}
            label='From'
            options={optionsFromAcc}
            {...formik.getFieldProps(`forms[${formIndex}].from`)}
          />
          <Select
            className={styles.boxInput}
            label='To'
            options={optionsToPayee}
            {...formik.getFieldProps(`forms[${formIndex}].to`)}
          />
          <CurrencyInput2
            className={styles.boxInput}
            variant='outlined'
            label='Amount'
            placeholder='$0.00'
            InputProps={{
              endAdornment: (
                <Button
                  className={styles.btn}
                  variant='text'
                  onClick={() => {
                    applyAllAmount(formik.values.forms[formIndex].amount);
                  }}
                >
                  Apply to all
                </Button>
              ),
            }}
            name={`forms[${formIndex}].amount`}
            value={formik.values.forms[formIndex].amount}
            onChange={(value) => {
              formik.setFieldTouched(`forms[${formIndex}].amount`, true);
              formik.setFieldValue(`forms[${formIndex}].amount`, value);
            }}
            onBlur={formik.handleBlur}
            error={
              !!formik.touched.forms?.[formIndex]?.amount &&
              !!(formik.errors.forms?.[formIndex] as FormikErrors<TransferItem>)
                ?.amount
            }
          />
        </div>

        {!isMobile && renderButtonDelete()}
      </div>
      <TextInput
        label='Memo'
        className={styles.memo}
        {...formik.getFieldProps(`forms[${formIndex}].memo`)}
        autoComplete='off'
        error={
          !!(formik.errors.forms?.[formIndex] as FormikErrors<TransferItem>)
            ?.memo
        }
        InputProps={{
          endAdornment: (
            <Button
              className={styles.btn}
              variant='text'
              onClick={() => {
                applyAllMemo(formik.values.forms[formIndex].memo || '');
              }}
            >
              Apply to all
            </Button>
          ),
        }}
      />
      <Divider />
    </div>
  );
};
