import { DrawerStepper, TStepOfDrawerStepper } from 'shared/ui/drawer-stepper';
import { Intro } from './intro';
import { Automations } from './automations';
import { SpendingReport } from './SpendingReport';
import { RenameFinAccount } from './renameFinAccount';
import { useAccount } from 'pages/accountComponent/provider';
import { useCallback, useState } from 'react';
import CloseAccount from './CloseAccount';
import { useAccountSpendingReport } from 'api/hooksApi/useAccountSpendingReport';
import moment from 'moment';
import { useDownloadSpendingReportMutation } from 'api/endpoints/userReport';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { offExtFromFilename } from 'shared/utils/pipes';
interface Props {
  isShow: boolean;
  onClose: () => void;
}

const More = (props: Props) => {
  const { isShow, onClose } = props;
  const { financialAccountId, unclosedFinancialAccounts } = useAccount();
  const { setSnackBar } = useSnackBar();
  const [
    downloadSpendingReport,
    { isLoading: isLoadingDownloadSpendingReport },
  ] = useDownloadSpendingReportMutation();

  const [currentAccountId, setCurrentAccountId] = useState(financialAccountId);
  const [date, setDate] = useState<Date>(moment().toDate());

  const [from, setFrom] = useState<string>(
    moment(date).startOf('month').format('YYYY-MM-DD 00:00:00Z')
  );
  const [to, setTo] = useState<string>(
    moment(date).endOf('month').format('YYYY-MM-DD 23:59:ssZ')
  );

  const finAccountId =
    currentAccountId === 'All accounts' ? '' : currentAccountId;

  const { spendingReport, spendingReportIsLoading } = useAccountSpendingReport(
    finAccountId,
    moment(from).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    moment(to).format('YYYY-MM-DDTHH:mm:ss[Z]')
  );

  const downloadReport = async () => {
    try {
      const resEXEL = await downloadSpendingReport({
        finAccountId,
        from: moment(from).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        to: moment(to).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      }).unwrap();
      if (resEXEL) {
        const { name, content, contentType } = resEXEL;
        const base64Str = content;
        const base64EXEL = `data:${contentType};base64, ${base64Str}`;
        triggerBase64Download(base64EXEL, offExtFromFilename(name));
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const handleDateChange = useCallback(
    (date: Date) => {
      setDate(date);
      const dateStart = moment(date)
        .startOf('month')
        .format('YYYY-MM-DD 00:00:00Z');
      const dateEnd = moment(date)
        .endOf('month')
        .format('YYYY-MM-DD 23:59:ssZ');
      setFrom(dateStart);
      setTo(dateEnd);
    },
    [date]
  );

  const steps: Array<[string, TStepOfDrawerStepper]> = [
    [
      '0',
      {
        id: '0',
        isCanGoBack: false,
        title: 'More',
        prevId: null,
        Element: <Intro />,
      },
    ],
    [
      '1',
      {
        id: '1',
        isCanGoBack: true,
        title: 'Manage automations',
        prevId: '0',
        Element: <Automations />,
        resetStepsOnReach: true,
      },
    ],
    [
      '2',
      {
        id: '2',
        isCanGoBack: true,
        title: 'Spending report',
        prevId: '0',
        resetStepsOnReach: true,
        Element: (
          <SpendingReport
            data={spendingReport}
            financialAccounts={unclosedFinancialAccounts}
            handleDateChange={handleDateChange}
            date={date}
            currentAccountId={currentAccountId}
            setCurrentAccountId={setCurrentAccountId}
            isLoading={spendingReportIsLoading}
            downloadReport={downloadReport}
            isLoadingDownloadReport={isLoadingDownloadSpendingReport}
          />
        ),
      },
    ],
    [
      '3',
      {
        id: '3',
        isCanGoBack: true,
        title: 'Rename account',
        prevId: '0',
        Element: (
          <RenameFinAccount
            financialAccounts={unclosedFinancialAccounts}
            financialAccountId={financialAccountId}
          />
        ),
        resetStepsOnReach: true,
      },
    ],
    [
      '4',
      {
        id: '4',
        isCanGoBack: true,
        title: 'Close account',
        prevId: '0',
        Element: <CloseAccount/>,
        resetStepsOnReach: true,
      },
    ],
  ];

  const MapSteps = new Map(steps);

  return (
    <DrawerStepper
      {...props}
      startStep='0'
      steps={MapSteps}
      isShow={isShow}
      onClose={onClose}
    />
  );
};

export default More;
