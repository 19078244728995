import { useNeteviaPayeesControllerQuery } from 'api/endpoints/fundsMovement'
import styles from './styles.module.scss'
import { Button } from 'shared/ui/buttons'
import { TransitionAnimation } from 'shared/ui/animations'
import { useDeleteNeteviaPayeeMutation } from 'api/endpoints/fundsMovement'
import { useSnackBar } from 'providers/snackBar.provider'
import helper from 'services/helper'
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import { useDialog } from 'providers/dialogController.provider'
import { useAppSelector } from 'shared/models'
import { DetailsPayee } from 'entities/transfers'
import { selectPayeeId } from '../../model'
import { useGetScheduledTransferQuery } from 'api/endpoints/scheduledTransfer'
import {
  filteredScheduleTransferByType,
  ScheduleTransferType,
} from 'entities/transfers'

export const ManagePayeeDetail = () => {
  const { setSnackBar } = useSnackBar()
  const { goNext } = useDrawerStepperContext()
  const dialog = useDialog()

  const selectPayee = useAppSelector(selectPayeeId)
  const { data: neteviaPayees, isLoading: isLoadingPayees } =
    useNeteviaPayeesControllerQuery()

  const { data: scheduledTransfers, refetch: refetchGetScheduledTransfer } =
    useGetScheduledTransferQuery()
  const [deleteNeteviaPayee, { isLoading: isLoadingDeleteNeteviaPayee }] =
    useDeleteNeteviaPayeeMutation()

  const selectedPayee = neteviaPayees?.payees?.find(
    (payee) => payee.id === selectPayee
  )
  const internalTransfer = filteredScheduleTransferByType(
    scheduledTransfers ?? [],
    ScheduleTransferType.InternalTransfer
  )

  const hasSheduleTransferWithPayee = internalTransfer.some(
    (transfer) => transfer.toFinancialAccountId === selectedPayee?.id
  )

  const dialogTextWithOutPayee = `Are you sure you want to delete ${selectedPayee?.name} from your payee list?`
  const dialogTextWithPayee =
    'You have scheduled transfers for this payee. Do you want to remove the payee and all associated scheduled and recurring transfers?'

  const renderSlotApplyButton = (
    <Button
      variant='text'
      className={styles.btnApplyDialog}
    >
      Delete
    </Button>
  )
  const renderSlotCancelButton = <Button variant='text'>Cancel</Button>

  const handeleDeletePayee = async () => {
    dialog.show({
      dialogTitle: 'Delete payee',
      dialogText: hasSheduleTransferWithPayee
        ? dialogTextWithPayee
        : dialogTextWithOutPayee,
      slotApplyButton: renderSlotApplyButton,
      slotCancelButton: renderSlotCancelButton,
      async onApply() {
        try {
          await deleteNeteviaPayee({
            financialAccountId: selectPayee,
            force: true,
          }).unwrap()

          setSnackBar({
            type: 'success',
            message: 'Payee deleted successfully.',
            isShow: true,
          })
          refetchGetScheduledTransfer()
          goNext('0')
        } catch (e: any) {
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          })
        }
      },
    })
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <DetailsPayee
          type={selectedPayee?.type || ''}
          name={selectedPayee?.name || ''}
          accountNumber={selectedPayee?.accountNumber || ''}
        />
        <Button
          onClick={handeleDeletePayee}
          variant='outlined'
          className={styles.btn}
          loading={isLoadingDeleteNeteviaPayee}
        >
          Delete payee
        </Button>
      </div>
    </TransitionAnimation>
  )
}
