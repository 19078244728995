import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';

interface EmptyProp {
  img: React.ReactNode;
  title: string;
  subTitle: string;
}

export const EmptyItem = ({ img, title, subTitle }: EmptyProp) => {
  return (
    <div className={styles.container}>
      {img}
      <Typography>{title}</Typography>
      <Typography>{subTitle}</Typography>
    </div>
  );
};
