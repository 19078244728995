import { FC } from "react";
import CategoryItem, { CategoryItemProps } from "../categoryItem";
import styles from "./style.module.scss";
import { AnimateWrapper } from "components/animate-wrapper";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface CategoryListProps {
  categories: CategoryItemProps[] | undefined;
}

const CategoryList: FC<CategoryListProps> = ({ categories }) => {
  const { t } = useTranslationProvider();
  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <span>Spending Information</span>
        {!!categories?.length ? (
          categories?.map((item) => (
            <CategoryItem
              key={item.category}
              amount={item.amount}
              category={item.category}
              color={item.color}
              img={item.img}
              percentage={item.percentage}
            />
          ))
        ) : (
          <div>
            {t("Settings.No transactions for selected period", {
              defaultValue: "No transactions for selected period",
            })}
          </div>
        )}
      </div>
    </AnimateWrapper>
  );
};
export default CategoryList;
