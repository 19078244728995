import { AnimateWrapper } from "components/animate-wrapper";
import { CategoryItemProps } from "../categoryItem";
import CategoryProccentItem from "../categoryProccentItem";
import styles from "./style.module.scss";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslationProvider } from "providers/translation/translation.provider";

import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "shared/ui/buttons";

interface CategoryProccentListProps {
  categories: CategoryItemProps[];
  handleDownloadRepor:() => Promise<void>;
  isLoading: boolean;
}

const CategoryProccentList = ({
  categories,
  handleDownloadRepor,
  isLoading,
}:CategoryProccentListProps) => {
  const { t } = useTranslationProvider();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const labelButton = matches
    ? t("Settings.Download Report", {
        defaultValue: "Download Report",
      })
    : t("Settings.Download", {
        defaultValue: "Download",
      });

  return categories.length ? (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <div>
          {!!categories?.length &&
            matches &&
            categories?.map((item) => (
              <CategoryProccentItem
                key={item.category}
                category={item.category}
                color={item.color}
                percentage={item.percentage}
                img={item.img}
                amount={item.amount}
              />
            ))}
        </div>
        <div>
          <Button
            loading={isLoading}
            onClick={handleDownloadRepor}
          >
            {labelButton}
            <DownloadIcon />
          </Button>
        </div>
      </div>
    </AnimateWrapper>
  ) : (
    <></>
  );
};

export default CategoryProccentList;
