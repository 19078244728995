import * as yup from 'yup';
import { FinancialAccount } from 'api/endpoints/financialAccounts';

export const createBatchTransfersSchema = (
  financialAccounts: FinancialAccount[]
) => {
  return yup.object().shape({
    forms: yup.array().of(
      yup.object().shape({
        from: yup.string().required(),
        to: yup.string().required(),
        amount: yup
          .string()
          .required()
          .test('is-valid-amount', 'Float value must not be zero', (value) => {
            if (!value) return false;
            const amountAsFloat = parseFloat(value);
            return amountAsFloat !== 0 && !isNaN(amountAsFloat);
          })
          .test(
            'amount-check',
            'The amount in your account should not exceed the balance',
            function (value) {
              const { from } = this.parent;
              const financialAccount = financialAccounts.find(
                (account) => account.financialAccountId === from
              );

              if (
                financialAccount &&
                value &&
                Number(value) > financialAccount.availableCash.value
              ) {
                return false;
              } else {
                return true;
              }
            }
          ),
        memo: yup.string().max(1024, 'Memo must be at most 1024 characters'),
      })
    ),
  });
};
