import { useSearchParams } from "react-router-dom";
import { useFAQGrouping } from "api/hooksApi/useFAQGrouping";
import styles from "./style.module.scss";
import FAQItemQuestions from "../faqItemQuestions";
import cx from "classnames";
import { useTranslationProvider } from "providers/translation/translation.provider";

const FaqListQuestion = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslationProvider();
  const mode = searchParams.get("mode");
  const { FAQGroupingByTopic } = useFAQGrouping(mode || undefined);

  const renderItems = () => {
    return FAQGroupingByTopic.map((item, index) => (
      <FAQItemQuestions
        key={index}
        index={index}
        answer={item.answer}
        question={item.question}
      />
    ));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.boxItem}>{renderItems()}</div>
      <div className={styles.supportMessage}>
        <span className={styles.supportMessage_title}>
          {t("Support.Didnt find an answer for your question", {
            defaultValue: "Didn’ t find an answer for your question?",
          })}
        </span>
        <span className={styles.supportMessage_subTitle}>
          {t("Support.Feel free to contact us by calling", {
            defaultValue: "Feel free to contact us by calling",
          })}{" "}
          <span
            className={cx(
              styles.supportMessage_subTitle,
              styles.phoneNumberColor
            )}
          >
            +1 855 479 4411
          </span>
        </span>
      </div>
    </div>
  );
};

export default FaqListQuestion;
