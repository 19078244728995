export const replaceNumberWithComma = (
  value: number | string | undefined,
): string => {
  if (typeof value !== "number" && typeof value !== "string") return "";
  return `${value}`.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1,");
};

export const dollarsToCents = (cents: number) => cents * 100;

export const centsToDollars = (cents: number) => cents / 100;
