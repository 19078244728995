import { useEffect, useState } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './style.module.scss';
import {
  Transaction,
  TransactionType,
  EnumTransactionStatuses,
} from 'api/endpoints/transaction';
import { Typography } from 'shared/ui/typography';
import helper from 'services/helper';
import moment from 'moment';
import StepperComponent from 'shared/components/TransactionDetails/Stepper';
import { useACHTransferDetailsControllerMutation } from 'api/endpoints/fundsMovement';
import { safeJsonParse } from 'shared/utils/require-not-null';
import { Skeleton } from 'components/skeleton';
import { Divider } from '@mui/material';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { InfoTooltip } from 'shared/components/InfiTooltip';
import { CercleSuccessGreenIcon } from 'shared/ui/icons';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import MailTransaction from 'assets/svg/mail-transaction.svg';
import { appRoutes, SettingsRouteSubRoute } from 'routes';
import { NEW_REQUEST_PARAMS } from 'pages/support/types/enums';
import { createStateForSupportReportProblem } from 'pages/support/lib';
import { useNavigate } from 'react-router-dom';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { UncommingCircleArrowIcon } from 'shared/ui/icons';
import cn from 'classnames';

interface TransferDetailProps {
  selectTransfer: Transaction | null;
  selectAccount: string;
}

const transferStatusCompleted =
  'The transfer has been successfuly processed by the bank and funds are available';

export const TransferDetail = ({
  selectTransfer,
  selectAccount,
}: TransferDetailProps) => {
  const navigate = useNavigate();
  const { t } = useTranslationProvider();

  const { data: holder, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery();

  const [ACHTransferDetailsMutation, { isLoading: ACHTransferIsLoading }] =
    useACHTransferDetailsControllerMutation();

  const [transferDetails, setTransferDetails] = useState<any>();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const fromFinAccount = unclosedFinancialAccounts.find(
    (account) => account.financialAccountId === selectAccount
  );

  useEffect(() => {
    if (selectTransfer) {
      getACHTransferDetails(selectTransfer.id);
    }
  }, [selectTransfer]);

  const getACHTransferDetails = async (transferId: string) => {
    try {
      const res = await ACHTransferDetailsMutation({ transferId }).unwrap();
      const data = safeJsonParse<any>(res, {});

      const formatDate = (inputDate) => {
        const parsedDate = moment(inputDate);
        const formattedDate = parsedDate.format('h:mm A, ddd, DD MMM YYYY');
        return formattedDate;
      };
      const transformData = [
        data.node.createdAt && {
          name: 'Created on',
          date: formatDate(data.node.createdAt),
        },
        data.node.updatedAt && {
          name: 'Last updated',
          date: formatDate(data.node.updatedAt),
        },
        data.node.settlementDate && {
          name: 'Settlement date',
          date: formatDate(data.node.settlementDate),
        },
        data.node.fundsAvailableDate && {
          name: 'Funds available date',
          date: formatDate(data.node.fundsAvailableDate),
        },
      ].filter(Boolean);
      setTransferDetails({ ...data.node, stepperData: transformData });
    } catch (e: any) {
      console.log('e', e);
    }
  };

  const amountCurrencyCode = helper.currencyCode(
    selectTransfer?.amount.currencyCode
  );
  const balanceCurrencyCode = helper.currencyCode(
    selectTransfer?.balance?.currencyCode
  );
  const status = helper.capitalizeFirstLetter(selectTransfer?.status || '');

  const isPositiveTransfer = selectTransfer?.amount.symbol === '+';

  const isGiftTransaction =
    selectTransfer?.type === TransactionType.GiftCardPurchase;

  const checkTransferStatusCompleted =
    selectTransfer?.status === EnumTransactionStatuses.COMPLETED;

  const handleGoToSupport = () => {
    navigate(
      appRoutes.settings(SettingsRouteSubRoute.support, NEW_REQUEST_PARAMS),
      {
        state: createStateForSupportReportProblem(
          selectTransfer,
          fromFinAccount,
          holder?.businessAccountHolder.legalBusinessName
        ),
      }
    );
  };

  const renderTransferTo = () => {
    if (isGiftTransaction) {
      return (
        <div>
          <Typography className={styles.labelText}>
            {TransactionType.GiftCardPurchase}
          </Typography>
          <Typography className={styles.giftName}>
            {selectTransfer.name}
          </Typography>
        </div>
      );
    } else {
      return (
        <div className={styles.boxTransferTo}>
          <div>
            <Typography className={styles.labelText}>
              {isPositiveTransfer ? 'Transfer From' : 'Transfer To'}
            </Typography>
            <Typography>
              {selectTransfer?.name}{' '}
              <span className={styles.last4}>
                {selectTransfer?.last4.value
                  ? `(${selectTransfer?.last4.value})`
                  : ''}
              </span>
            </Typography>
            <Typography className={styles.labelText}>
              {moment(selectTransfer?.date).format('MM.DD.YYYY, hh:mm A')}
            </Typography>
          </div>
          <UncommingCircleArrowIcon
            className={
              isPositiveTransfer
                ? cn(styles.img, styles.imgPositive)
                : styles.img
            }
          />
        </div>
      );
    }
  };

  const renderGiftDateTime = () => (
    <div className={styles.boxGiftDateTime}>
      <div>
        <Typography className={styles.labelText}>
          Transaction Date & Time
        </Typography>
        <Typography>
          {moment(selectTransfer?.date).format('DD MMMM YYYY, hh:mm A')}
        </Typography>
      </div>
      <UncommingCircleArrowIcon
        className={
          isPositiveTransfer ? cn(styles.img, styles.imgPositive) : styles.img
        }
      />
    </div>
  );

  return ACHTransferIsLoading ||
    financialAccountsIsLoading ||
    isLoadingHolder ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.box}>
          <Typography className={styles.boxAmount}>
            {isPositiveTransfer ? selectTransfer?.amount.symbol : '-'}
            {amountCurrencyCode}
            {helper.formatUsdValue(selectTransfer?.amount.value)}
          </Typography>
          {selectTransfer?.balance?.value && (
            <Typography className={styles.boxBalance}>
              {balanceCurrencyCode || amountCurrencyCode}
              {helper.formatUsdValue(selectTransfer.balance.value)}
            </Typography>
          )}
        </div>
        {renderTransferTo()}
        <Divider />
        {isGiftTransaction && renderGiftDateTime()}
        {!isPositiveTransfer && !isGiftTransaction && (
          <div>
            <Typography className={styles.labelText}>Transfer From</Typography>
            <Typography>
              {fromFinAccount?.name}{' '}
              <span className={styles.last4}>
                ({fromFinAccount?.accountNumber.slice(-4)})
              </span>
            </Typography>
          </div>
        )}
        <div>
          <Typography className={styles.labelText}>Transaction Type</Typography>
          <Typography>{selectTransfer?.type}</Typography>
        </div>
        <div>
          <Typography className={styles.labelText}>Status</Typography>
          <div className={styles.status}>
            {checkTransferStatusCompleted && (
              <CercleSuccessGreenIcon className={styles.statusIcon} />
            )}
            <Typography className={styles.statusText}>{status}</Typography>
            {checkTransferStatusCompleted && (
              <InfoTooltip text={transferStatusCompleted} />
            )}
          </div>
        </div>
        {transferDetails?.stepperData && <Divider />}
        <div>
          {transferDetails?.stepperData && (
            <StepperComponent
              data={transferDetails.stepperData}
              isLoading={ACHTransferIsLoading}
            />
          )}
        </div>
        {!!transferDetails?.memo?.trim() && (
          <>
            <Divider />
            <div>
              <Typography className={styles.labelText}>Memo</Typography>
              <Typography>{transferDetails.memo}</Typography>
            </div>
          </>
        )}
        <div className={styles.report}>
          <Typography className={styles.reportTextQuestion}>
            {t('Transactions.Having a problem with this transaction', {
              defaultValue: 'Having a problem with this transaction?',
            })}
          </Typography>
          <div
            className={styles.reportBoxText}
            onClick={handleGoToSupport}
          >
            <img src={MailTransaction} />
            <div className={styles.reportText}>
              {t('Transactions.Report a problem', {
                defaultValue: 'Report a problem',
              })}
            </div>
          </div>
        </div>
      </div>
    </TransitionAnimation>
  );
};
