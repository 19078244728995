import React, { useState, useCallback } from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { TextInput } from 'shared/ui/inputs';
import { IconButton, Badge as BadgeMui, Divider, List } from '@mui/material';
import { SearchIcon } from 'shared/ui/icons';
import { FilterIcon } from 'shared/ui/icons';
import styles from './style.module.scss';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { Skeleton } from 'components/skeleton';
import { Typography } from 'shared/ui/typography';
import { Transaction } from 'api/endpoints/transaction';
import { TransfersHistoryItem } from '../transfersHistoryItem';
import moment from 'moment';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {
  EnumTransactionStatuses,
  TransactionType,
} from 'api/endpoints/transaction';
import { Accordion } from 'pages/accountComponent/components/filterTransactions/componentns/accordion';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';
import { Select } from 'shared/ui/inputs';
import helper from 'services/helper';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { Badge } from 'shared/ui/badge';
import { ClockIcon } from 'shared/ui/icons';
import { useDebounce } from 'shared/hooks/useDebounce';

interface MainProps {
  loadingPage: boolean;
  loadingList: boolean;
  transfers: Transaction[];
  searchQuery: string;
  setSearchQuery: (vlaue: string) => void;
  filtersLength: number;
  setSelectTransfer: (value: Transaction | null) => void;
  expandPedningTransferBool: UseBooleanReturn;
  financialAccounts: FinancialAccount[];
  selectAccount: string;
  setSelectAcoount: (selectAccount: string) => void;
  period: {
    from: string;
    to: string;
  };
}

export const Main = (props: MainProps) => {
  const {
    loadingPage,
    loadingList,
    transfers,
    searchQuery,
    setSearchQuery,
    filtersLength,
    setSelectTransfer,
    expandPedningTransferBool,
    financialAccounts,
    selectAccount,
    setSelectAcoount,
    period,
  } = props;

  const { goNext } = useDrawerStepperContext();
  const [localSearchQuery, setLocalSearchQuery] = useState<string>(searchQuery);

  const debouncedSetSearchQuery = useDebounce(async (query: string) => {
    setSearchQuery(query);
  }, 700);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setLocalSearchQuery(query);
    debouncedSetSearchQuery(query);
  };

  const filteredData = transfers?.filter((transfer) =>
    transfer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const completedTransfer = filteredData.filter(
    (transfer) => transfer.status === EnumTransactionStatuses.COMPLETED
  );

  const pendingTransfer = filteredData.filter(
    (transfer) =>
      transfer.status === EnumTransactionStatuses.PENDING &&
      (transfer.type === TransactionType.ACHTransfer ||
        transfer.type === TransactionType.Deposit)
  );

  const renderDate = (formattedDate: string, shouldRenderDate: boolean) => {
    if (shouldRenderDate) {
      return <Typography className={styles.date}>{formattedDate}</Typography>;
    }
    return null;
  };

  const handleNavigateToDetail = (transfer: Transaction) => {
    setSelectTransfer(transfer);
    goNext('2');
  };

  const renderCompletedList = (completedTransfer: Transaction[]) => {
    let renderedDates: string[] = [];

    return !!completedTransfer.length ? (
      <List className={styles.list}>
        {completedTransfer.map((transfer, index) => {
          const formattedDate = moment(transfer.date).format('MMMM DD, YYYY');
          const shouldRenderDate = !renderedDates.includes(formattedDate);
          const isLastItem = index !== completedTransfer.length - 1;

          if (shouldRenderDate) {
            renderedDates.push(formattedDate);
          }

          return (
            <React.Fragment key={transfer.id}>
              {renderDate(formattedDate, shouldRenderDate)}

              <TransfersHistoryItem
                item={transfer}
                key={transfer.id}
                handleNavigate={handleNavigateToDetail}
              />
              {isLastItem && <Divider className={styles.divider} />}
            </React.Fragment>
          );
        })}
      </List>
    ) : (
      <div className={styles.boxEmpty}>
        <SwapHorizIcon />
        <Typography>No data found</Typography>
        <Typography>
          Your transactions will be displayed here as the become available.
        </Typography>
      </div>
    );
  };

  const renderPendingList = (pendingTransfer: Transaction[]) => {
    let renderedDates: string[] = [];

    return (
      <>
        {pendingTransfer.map((transfer, index) => {
          const formattedDate = moment(transfer.date).format('MMMM DD, YYYY');
          const shouldRenderDate = !renderedDates.includes(formattedDate);
          const isLastItem = index !== pendingTransfer.length - 1;

          if (shouldRenderDate) {
            renderedDates.push(formattedDate);
          }

          return (
            <React.Fragment key={transfer.id}>
              {renderDate(formattedDate, shouldRenderDate)}
              <TransfersHistoryItem
                item={transfer}
                handleNavigate={handleNavigateToDetail}
              />
              {isLastItem && <Divider className={styles.divider} />}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const renderAccorionSummary = () => (
    <div className={styles.boxBadge}>
      <Typography>Pending Transactions</Typography>
      <Badge
        startIcon={<ClockIcon className={styles.boxBadgeIcon} />}
        label={`${pendingTransfer.length} Pending`}
        styledWrapper={styles.boxBadgeWrapper}
        styledLabel={styles.boxBadgeLabel}
      />
    </div>
  );

  const optionsAccount = financialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {account.name} ({account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  return loadingPage ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <Select
          fullWidth
          label='Select account'
          options={optionsAccount}
          value={selectAccount}
          onChange={(e) => setSelectAcoount(e.target.value)}
          disabled={loadingList}
          size='small'
        />
        <div className={styles.boxSearch}>
          <TextInput
            fullWidth
            className={styles.input}
            placeholder='Search'
            autoComplete='off'
            value={localSearchQuery}
            onChange={handleSearchInputChange}
            InputProps={{
              className: styles.boxSearchInput,
              startAdornment: (
                <div className={styles.boxStarAdornment}>
                  <SearchIcon />
                </div>
              ),
            }}
          />

          <IconButton
            aria-label='open filter'
            onClick={() => goNext('1')}
            disabled={loadingList}
          >
            <BadgeMui
              overlap='circular'
              badgeContent={filtersLength}
              classes={{ badge: styles.boxSearchBadge }}
            >
              <div className={styles.wrapIcon}>
                <FilterIcon />
              </div>
            </BadgeMui>
          </IconButton>
        </div>
        <div className={styles.boxPeriod}>
          <Typography>Period:</Typography>
          <Typography>
            {period.from}-{period.to}
          </Typography>
        </div>

        {loadingList ? (
          <Skeleton
            width='100%'
            height='110px'
          />
        ) : (
          <TransitionAnimation>
            <div className={styles.boxLists}>
              {!!pendingTransfer?.length && (
                <Accordion
                  expanded={expandPedningTransferBool.value}
                  onChangeExpanded={() => expandPedningTransferBool.toggle()}
                  summary={renderAccorionSummary()}
                  styleWrapperRoot={styles.accordionRoot}
                  styleWrapperContent={styles.accordionContent}
                  styleWrapperSummary={styles.accordionSummary}
                >
                  {renderPendingList(pendingTransfer)}
                </Accordion>
              )}
              {renderCompletedList(completedTransfer)}
            </div>
          </TransitionAnimation>
        )}
      </div>
    </TransitionAnimation>
  );
};
