import { BarChart } from "@mui/x-charts/BarChart";
import MonthCalendar from "shared/components/calendar/monthCalendar";
import styled from "styled-components";
import helper from "services/helper";
import { PieChart } from "@mui/x-charts/PieChart";
import { ChartType } from "pages/accountComponent/types";
import styles from './style.module.scss'
import { Typography } from "shared/ui/typography";
interface ChartReportProps {
  date: Date;
  data: any;
  handleDateChange: (value:Date) => void;
  chartType: ChartType;
}

export const ChartReport = ({
  data,
  date,
  handleDateChange,
  chartType,
}: ChartReportProps) => {
  const categories = data?.categories;
  const colors = categories?.map((item) => item?.color || "#000000");

  function transformDataForChart(data) {
    const chartArray = Array.from({ length: data?.length }, () =>
      Array(data?.length).fill(0)
    );

    data.forEach((item, rowIndex) => {
       chartArray[rowIndex][rowIndex] = item.amount;
    });
  
    const series = chartArray.map((row, index) => ({
      data: row,
      stack: "1",
      label: data[index].category,
      valueFormatter: (v) => `$${helper.formatUsdValue((v ?? 0))}`  
    }));

    return series;
  }
  const barChartsParams = {
    xAxis: [
      {
        data: categories?.map((item) => item.category),
        scaleType: "band" as const,
      },
    ],

    series: transformDataForChart(categories),
    colors: colors,
    margin: { top: 10, right: 10 },
    height: 200,
    slotProps: {
      legend: {
        hidden: true,
      },
    },
  };


  return (
    <Container>
      <div className={styles.header}>
        <Typography>Your activity</Typography>
        <MonthCalendar
          disableFuture
          currentDate={date}
          onChange={handleDateChange}
          isDatapiker
          hideLabel
          height={36}
        />
      </div>
          {categories.length ? (
        <>
          {chartType === ChartType.pieChart ? (
             <div className={styles.wrapper}>
             <PieChart
               series={[
                 {
                   data: categories.map((item, index) => ({
                     id: index,
                     value: item.amount,
                     label: item.category,
                     color: item.color,
                   })), 
                   valueFormatter: (v) =>`$${helper.formatUsdValue((v.value ?? 0))}`,
                   innerRadius: 50,
                 },
               ]}
               width={280}
               height={200}
             />
           </div>
          ) : (
            <BarChart {...barChartsParams} tooltip={{ trigger: "item" }}   
          />
          
          )}
        </>
      ) : (
        <Typography className={styles.empy}>There is no data yet for this period.</Typography>
      )}
    </Container>
  );
};
const Container = styled("div")`
  width: 100%;
  & .MuiChartsAxis-directionX text {
    display: none;
  }
  & .MuiChartsLegend-root {
    display: none;
  }
`;


