export const getOwnerLabelByType = (ownerType: number, t): string => {
  let label: string;

  switch (ownerType) {
    case 1:
      label = t('Settings.First', { defaultValue: 'Primary' });
      break;
    case 2:
      label = 'Second';
      break;
    case 3:
      label = 'Third';
      break;
    case 4:
      label = 'Fourth';
      break;
    default:
      label = '';
      break;
  }
  const secondPart = t('Settings.Owner', { defaultValue: 'Owner' });

  return `${label} ${secondPart}`;
};
