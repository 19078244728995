import { useMemo } from "react";
import { useSpendingReportQuery } from "api/endpoints/userReport";
import data,{ ReportCategories } from "constants/data";

interface ExtendedCategories extends ReportCategories {
  amount: number;
  percentage: number;
}
export interface SpendingReportData {
  categories: ExtendedCategories[]
  total: number
}
interface SpendingReportReturnType {
  spendingReport: SpendingReportData;
  spendingReportIsLoading: boolean;
  refetchSpendingReport: () => void
}

export const useAccountSpendingReport = (financialAccountId:string, from:string, to: string): SpendingReportReturnType=> {

  const {
    data: spendingReportData,
    isFetching,
    refetch,
  } = useSpendingReportQuery({
    finAccountId: financialAccountId,
    from,
    to,
  });

  const value = useMemo<any>(() => {
    if (!spendingReportData) {
       return {
        categories: [],
        total: 0,
      };
    }

    if (spendingReportData) {
      const total = spendingReportData.total;
      const categories = spendingReportData.categories.map((i) => {
        const categoryData = data.reportCategories.find(
          (d) => d.category === i.category
        );
        return {
          ...i,
          ...categoryData,
        };
      });

      return {
        categories,
        total,
      };
    }
  }, [spendingReportData, from, to]);

  return {
    spendingReport: value,
    spendingReportIsLoading: isFetching,
    refetchSpendingReport: refetch,
  };
};
