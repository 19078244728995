import { useExternalTransactionsQuery } from "api/endpoints/transaction";
import { ExternalTransactionsArgs } from "api/endpoints/transaction";

export const useExternalTransactions = (args: ExternalTransactionsArgs) => {
  const { data, isLoading } = useExternalTransactionsQuery({ 
    financialAccountId: args.financialAccountId ?? '0',  
    dateFrom: args.dateFrom,
    dateTo: args.dateTo,
  });

  return ({
    isLoading,
    transactions: data ?? [],
  })
}