import { Popup } from "../popup";
import Button from "@mui/material/Button";
import React, { useRef, useState } from "react";
import styles from "./style.module.scss";
import { TextField, useMediaQuery, useTheme } from "@mui/material";

interface Props {
  onApply(value?: string): void;
  open: boolean;
  applyButtonText: string;
  onClose(): void;
  dialogText?: string | React.ReactNode;
  applyButtonType: "text" | "contained" | "outlined";
  onCancel(): void;
  dialogTitle?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  cancelButtonType: "text" | "contained" | "outlined";
  cancelError: boolean;
  modalType?: string;
  placeholder?: string;
  defaultValue?: string;
  hideButtons?: boolean;
}

export const ConfirmDialog = ({
  open,
  onClose,
  onApply,
  onCancel,
  dialogText,
  applyButtonText,
  applyButtonType,
  cancelButtonText,
  cancelButtonType,
  dialogTitle,
  cancelError,
  modalType,
  placeholder,
  hideButtons,
  defaultValue = "",
}: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const BUTTON_WIDTH = matches ? 224 : 200;
  const BUTTON_HEIGTH = matches ? 36 : 44;
  const [textFieldValue, setTextFieldValue] = useState<string>();

  const handleApply = () => {
    onApply(textFieldValue !== undefined ? textFieldValue : defaultValue);
    setTextFieldValue(undefined);
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setTextFieldValue(undefined);
    onClose();
  };
  if (open) {
    return (
      <Popup onClose={onClose} stylesWrapper={styles.popup}>
        <div className={styles.content}>
          {dialogTitle && (
            <div className={styles.dialogTitle}>{dialogTitle}</div>
          )}

          {dialogText && <div className={styles.dialogText}>{dialogText}</div>}
          {modalType === "withTextfield" && (
            <TextField
              type="text"
              placeholder={placeholder}
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              defaultValue={defaultValue}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{
                height: 54,
              }}
            />
          )}
          {!hideButtons && (
            <div className={styles.controls}>
              <Button
                variant={cancelButtonType}
                color={cancelError ? "error" : undefined}
                // onClick={onClose}
                onClick={handleCancel}
                sx={{
                  borderRadius: 6,
                  textTransform: "none",
                  width: BUTTON_WIDTH,
                  height: BUTTON_HEIGTH,
                }}
              >
                {cancelButtonText}
              </Button>
              <Button
                variant={applyButtonType}
                onClick={handleApply}
                sx={{
                  borderRadius: 6,
                  textTransform: "none",
                  width: BUTTON_WIDTH,
                  height: BUTTON_HEIGTH,
                  backgroundColor:
                    applyButtonType === "contained" ? "#51B9E5" : undefined,
                  ":hover": {
                    backgroundColor:
                      applyButtonType === "contained" ? "#51B9E5" : undefined,
                  },
                }}
              >
                {applyButtonText}
              </Button>
            </div>
          )}
        </div>
      </Popup>
    );
  }

  return null;
};
