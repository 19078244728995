import {
  BusinessSignUpScreen,
  SignUpBusinessPrimaryAuthPersonForm,
  useSignUpBusiness,
} from '../provider';
import { useFormik } from 'formik';
import {
  signUpPersonValidationSchema,
  signUpAddressValidateSchema,
  JOB_TITLE_DATA,
} from '../../../shared/signUp/shared';
import { SignUpFormsLayout } from '../../../layouts/SignUpFormsLayout';
import { AnimateWrapper } from '../../../components/animate-wrapper';
import { SignUpBusinessProgressBar } from '../progressBar';
import styles from './style.module.scss';
import { SignUpAddressForm } from '../../../shared/signUp/addressForm';
import { Button } from '../../../shared/ui/buttons';
import { SignUpPersonForm } from '../../../shared/signUp/personForm';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { PercentageField } from '../../../shared/signUp/percentageField';
import * as yup from 'yup';
import { useScrollToMuiFieldAfterError } from '../../../shared/hooks/useScrollToMuiFieldAfterError';

const validationSchema = yup
  .object({
    ownershipPercentage: yup
      .string()
      .required('Percentage required field')
      .test(
        'test-ownership-25-and-100',
        'Ownership percentage must be between 25-100%',
        (value) => {
          const number = Number(value);
          return !isNaN(number) && number >= 25;
        }
      ),
  })
  .concat(signUpPersonValidationSchema)
  .concat(signUpAddressValidateSchema);

export const BusinessSignUpPrimaryAuthorizedPerson = () => {
  const { setScreen, contextForm, updateContextForm } = useSignUpBusiness();

  const formik = useFormik<SignUpBusinessPrimaryAuthPersonForm>({
    validateOnChange: false,
    validationSchema,
    initialValues: contextForm.primaryAuthPerson,
    onSubmit(values) {
      updateContextForm({
        primaryAuthPerson: values,
      });
      setScreen(BusinessSignUpScreen.primaryOwner);
    },
  });

  useScrollToMuiFieldAfterError(formik.isSubmitting);

  return (
    <SignUpFormsLayout isConsumerMode={false}>
      <AnimateWrapper className='fade'>
        <SignUpFormsLayout.Title>
          Primary authorized person information
        </SignUpFormsLayout.Title>
        <SignUpFormsLayout.Subtitle>
          Enter the details of the primary authorized person.
        </SignUpFormsLayout.Subtitle>
        <SignUpBusinessProgressBar
          curScreen={BusinessSignUpScreen.primaryAuthorizedPerson}
        />

        <form
          onSubmit={formik.handleSubmit}
          className={styles.formWrapper}
        >
          <SignUpFormsLayout.SectionTitle>
            Personal info
          </SignUpFormsLayout.SectionTitle>
          <SignUpPersonForm formikInstance={formik} />

          <SignUpFormsLayout.SectionTitle>
            Address
          </SignUpFormsLayout.SectionTitle>
          <SignUpAddressForm formikInstance={formik} />

          <SignUpFormsLayout.SectionTitle>
            Other info
          </SignUpFormsLayout.SectionTitle>
          <FormControl fullWidth>
            <InputLabel id='jobtitle-select-label'>Job title</InputLabel>
            <Select
              labelId='jobtitle-select-label'
              id='jobtitle-select'
              value={formik.values.jobTitle}
              label='Job title'
              onChange={(e) => formik.setFieldValue('jobTitle', e.target.value)}
              error={Boolean(formik.touched.jobTitle && formik.errors.jobTitle)}
            >
              {JOB_TITLE_DATA.map((title, index) => {
                return (
                  <MenuItem
                    key={title}
                    value={index}
                  >
                    {title}
                  </MenuItem>
                );
              })}
            </Select>
            {Boolean(formik.touched.jobTitle && formik.errors.jobTitle) && (
              <FormHelperText error>{formik.errors.jobTitle}</FormHelperText>
            )}
          </FormControl>

          <PercentageField formikInstance={formik} />

          <SignUpFormsLayout.FormControls>
            <Button
              onClick={() => setScreen(BusinessSignUpScreen.businessAddress)}
              type='button'
              variant='text'
            >
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
            >
              Next
            </Button>
          </SignUpFormsLayout.FormControls>
        </form>
      </AnimateWrapper>
    </SignUpFormsLayout>
  );
};
