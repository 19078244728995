import { useMemo } from "react";
import {
  useMerchantCategoriesControllerQuery,
  EnumsItemApi,
} from "../endpoints/enums";

interface useMerchanCategoryReturnType {
  merchantCategories: EnumsItemApi[];
  isLoading: boolean;
}

export const useMerchantCategories = (): useMerchanCategoryReturnType => {
  const { data, isLoading } = useMerchantCategoriesControllerQuery();
  const value = useMemo<any>(() => {
    if (!data) {
      return [];
    }

    return data.map((i) => {
      return {
        name: i.name,
        title:
          i.description &&
          i.description
            .split("\n\n")[0]
            .replace("The category for ", "")
            .replace(".", ""),
        description: i.description,
        isSelected: false,
      };
    });
  }, [data]);

  return {
    merchantCategories: value,
    isLoading,
  };
};
