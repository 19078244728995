import React, { FC } from 'react';
import styles from './style.module.scss';
import { IconButton } from '@mui/material';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import cx from 'classnames';
import { XComIcon, FacebookIcon, InstagramIcon } from 'shared/ui/icons';
import { Button } from 'shared/ui/buttons';
import { AnimateWrapper } from "components/animate-wrapper";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { NeteviaLogoFooterIcon } from "shared/ui/icons"

interface FooterProps {
  stylesFooter?: {
    container?: string;
    btnWrapper?: string;
  };
  legal?: React.ReactNode;
  isConsumer?: boolean;
}

const Footer: FC<FooterProps> = ({ stylesFooter, legal, isConsumer }) => {
  const { t, isLoadingLanguage } = useTranslationProvider();
  const hanldeNavigate = (link) => {
    window.open(link);
  };

  return (
    <AnimateWrapper
      className='fade'
      inStatus={!isLoadingLanguage}
    >
      <div className={cx(stylesFooter?.container, styles.container)}>
        <NeteviaLogoFooterIcon className={styles.logo}/>
        <div className={styles.info}>
          <div className={styles.info_contacts}>
            <div className={styles.info_contacts_box}>
              <span className={styles.info_contacts_box_title}>
                {t('Settings.Address', {
                  defaultValue: 'Address',
                })}
              </span>
              <p className={styles.info_contacts_box_text}>
                © 2024 Netevia Group LLC 3363 NE 163rd St, Suite 606 North Miami
                Beach, FL 33160
              </p>
            </div>
              
            <div className={styles.info_contacts_box}>
              <span className={styles.info_contacts_box_title}>
                {t('common.Contact information', {
                  defaultValue: 'Contact information',
                })}
              </span>
              <div className={styles.info_contacts_box_img}>
                <LocalPhoneOutlinedIcon/>
                <span className={styles.info_contacts_box_text}>
                  855-479-4411
                </span>
              </div>
              <div className={styles.info_contacts_box_img}>
                <EmailOutlinedIcon/>
                <span className={styles.info_contacts_box_text}>
                  <a
                    className={styles.colorSecondary}
                    href='mailto:card@netevia.com'
                  >
                    card@netevia.com
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.info_description}>
            <p className={styles.info_description_text}>{legal}</p>
          </div>
        </div>
              
        <div className={cx(stylesFooter?.btnWrapper, styles.buttonGroup)}>
          <div className={styles.buttonGroup_box}>
            <Button
              onClick={() => hanldeNavigate('https://netevia.com/privacy/')}
              variant='text'
              className={cx(styles.btn, {
                [styles.colorSecondary]: isConsumer,
              })}
            >
              <span className={styles.buttonGroup_label}>
                {t('common.Privacy Policy', {
                  defaultValue: 'Privacy Policy',
                })}
              </span>
            </Button>
            <Button
              onClick={() =>
                hanldeNavigate('https://netevia.com/terms-conditions/')
              }
              variant='text'
              className={cx(styles.btn, {
                [styles.colorSecondary]: isConsumer,
              })}
            >
              <span className={styles.buttonGroup_label}>
                {t('common.Terms of service', {
                  defaultValue: 'Terms of service',
                })}
              </span>
            </Button>
            {/* <Button sx={styledButton}>
              <span className={styles.buttonGroup_label}>
                {" "}
                {t("common.Feedback", {
                  defaultValue: "Feedback",
                })}
              </span>
            </Button> */}
          </div>
          <div className={styles.buttonGroup_box}>
            <IconButton
              aria-label='facebook'
              onClick={() => hanldeNavigate('https://www.facebook.com/netevia')}
            >
              <FacebookIcon
                className={cx({
                  [styles.consumerSocialMedia]: isConsumer,
                })}
              />
            </IconButton>
              
            <IconButton
              aria-label='instagram'
              onClick={() =>
                hanldeNavigate('https://www.instagram.com/neteviapayments/')
              }
            >
              <InstagramIcon
                className={cx({
                  [styles.consumerSocialMedia]: isConsumer,
                })}
              />
            </IconButton>
            <IconButton
              aria-label='X'
              onClick={() =>
                hanldeNavigate('https://twitter.com/NeteviaPayments')
              }
            >
              <XComIcon
                className={cx({
                  [styles.consumerSocialMedia]: isConsumer,
                })}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default Footer;
