import { FormControlLabel, Checkbox } from '@mui/material';
import { Accordion } from 'pages/accountComponent/components/filterTransactions/componentns/accordion';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';
import { FormValues } from '..';
import { FormikProps } from 'formik';
import styles from './style.module.scss';

interface ByMoneyMovementProps {
  formik: FormikProps<FormValues>;
  expandByMoneyMovement: UseBooleanReturn;
}

export const ByMoneyMovement = ({
  formik,
  expandByMoneyMovement,
}: ByMoneyMovementProps) => {
  return (
    <Accordion
      expanded={expandByMoneyMovement.value}
      onChangeExpanded={() => expandByMoneyMovement.toggle()}
      summary='By money movement'
    >
      <FormControlLabel
        key='Deposit'
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            name='deposit'
            checked={formik.values.deposit}
            onChange={(e) => formik.setFieldValue('deposit', e.target.checked)}
          />
        }
        label='Money in'
      />
      <FormControlLabel
        key='Withdraw'
        classes={{ root: styles.rootFormControlLabel }}
        control={
          <Checkbox
            classes={{
              checked: styles.checked,
            }}
            name='withdraw'
            checked={formik.values.withdraw}
            onChange={(e) => formik.setFieldValue('withdraw', e.target.checked)}
          />
        }
        label='Money out'
      />
    </Accordion>
  );
};
