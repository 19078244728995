import { Select, SelectItem, TextInput } from 'shared/ui/inputs'
import styles from './styles.module.scss'
import { states } from 'constants/data'
import { PhysicalCardOrder } from '../../../model'
import {
  mapGooglePlace,
  useGooglePlaceAutoComplete,
} from 'shared/services/google-maps'
import { useCallback, useEffect, useRef } from 'react'
import { FormikProps } from 'formik'

const States = states.map<SelectItem>((item) => {
  return { id: item.label, content: item.label, value: item.label }
})

interface DeliveryFormProps {
  formik: FormikProps<PhysicalCardOrder>;
}

export const DeliveryForm = (props: DeliveryFormProps) => {
  const { formik } = props


  const addressRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (addressRef.current) {
      addressRef.current.value = formik.values.address; 
      formik.validateForm();
    }
  }, [formik.values.address]);

  const handleAutoComplete = useCallback(
    (autoComplete: google.maps.places.Autocomplete) => {
      const place = autoComplete.getPlace();
      const mapPlace = mapGooglePlace(place);
  
       if (!mapPlace) return;

        formik.setFieldValue('address', mapPlace.fullAddress || '');
        formik.setFieldTouched('address', true);

        formik.setFieldValue('city', mapPlace.localityLong || '');
        formik.setFieldTouched('city', true);

        formik.setFieldValue('state', mapPlace.adminArea1Short || '');
        formik.setFieldTouched('state', true);

        formik.setFieldValue('postCode', mapPlace.postalCode || '');
        formik.setFieldTouched('postCode', true);
    },
     [] 
  );

  useGooglePlaceAutoComplete(addressRef.current!, handleAutoComplete)

  return (
    <div className={styles.root}>
      <TextInput
        label='Fist name'
        {...formik.getFieldProps('firstName')}
        // error={firstName.fieldState.error?.message}
      />
      <TextInput
        label='Last name'
        {...formik.getFieldProps('lastName')}
        // error={lastName.fieldState.error?.message}
      />
      <TextInput
        label='Address line 1'
        {...formik.getFieldProps('address')}
        inputRef={addressRef}
        // error={address.fieldState.error?.message}
      />
      <TextInput
        label='Address line 2 (optional)'
        {...formik.getFieldProps('additionalAddress')}
        // error={additionalAddress.fieldState.error?.message}
      />
      <TextInput
        label='City'
        {...formik.getFieldProps('city')}
        // error={city.fieldState.error?.message}
      />
      <Select
        options={States}
        label='State'
        {...formik.getFieldProps('state')}
        // error={state.fieldState.error?.message}
      />
      <TextInput
        label='ZIP code'
        {...formik.getFieldProps('postCode')}
        // error={zipCode.fieldState.error?.message}
      />
    </div>
  )
}
