import { FC } from "react";
import styles from "./styles.module.scss";
import { useTranslationProvider } from "providers/translation/translation.provider";

interface SelectRewardsItemProps {
  name: string;
  accountNumberLast4: string;
  balance: string;
}

const SelectRewardsItem: FC<SelectRewardsItemProps> = ({
  name,
  accountNumberLast4,
  balance,
}) => {
  const { t } = useTranslationProvider();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_box}>
        <span className={styles.wrapper_box_name}>{name}</span>
        <span className={styles.wrapper_box__accNumber}>
          {t("Rewards.Checking account", {
            defaultValue: "Checking account",
          })}{" "}
          ****{accountNumberLast4}
        </span>
      </div>

      <span className={styles.wrapper_balance}>$ {balance}</span>
    </div>
  );
};
export default SelectRewardsItem;
