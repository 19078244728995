import React from 'react';
import Reward from '../index';
import { Outlet } from 'react-router-dom';
import { RewardsProvider } from '../provider/useRewards';
import { GiftCardsProvider } from '../provider/useGiftCards';


export const RewardLayout = () => {
  return (
    <React.Fragment>
      <RewardsProvider>
        <GiftCardsProvider>
          <Reward />
          <Outlet />
        </GiftCardsProvider>
      </RewardsProvider>
    </React.Fragment>
  );
};


