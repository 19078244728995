import { useTranslationProvider } from "providers/translation/translation.provider";
import styles from "./style.module.scss";

const LoginTitle = () => {
  const { t } = useTranslationProvider();
  return (
    <div className={styles.title}>
      <span className={styles.title_text}>
        {t("Login.Welcome to Netevia!", {
          defaultValue: "Welcome to Netevia!",
        })}
      </span>
      <p className={styles.title_subTitleText}>
        {t("Login.Enter your username and password", {
          defaultValue:
            "Enter your username and password to access your account",
        })}
      </p>
    </div>
  );
};
export default LoginTitle;
