import FAQ from './FAQ';
import OpenNewRequest from './create-new-request/components/openNewRequest';
import styles from './style.module.scss';
import OpenHistory from './history/components/openHistory';
import { useMediaQuery } from '@mui/material';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { useSearchParams } from 'react-router-dom';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { AnimateWrapper } from 'components/animate-wrapper';
import DrawerModal from 'shared/components/drawerModal';
import FaqListQuestions from 'pages/support/FAQ/components/faqListQuestions';
import CreateNewRequest from 'pages/support/create-new-request';
import History from 'pages/support/history';
import ChatWithSupport from 'pages/support/chat-with-support';
import { SupportModeEnum } from './types/enums';

const SupportMode = [
  SupportModeEnum.Faq,
  SupportModeEnum.ChatSupport,
  SupportModeEnum.CreateNewRequest,
  SupportModeEnum.History,
];

const Support = () => {
  const { t } = useTranslationProvider();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const pageMode = searchParams.get('pageMode');

  const pageChat = pageMode === SupportModeEnum.ChatSupport;

  const goBackChatSupport = () => {
    setSearchParams({
      pageMode: SupportModeEnum.History,
      title: 'Requests history',
    });
  };
  return (
    <AnimateWrapper className='fade'>
      <div className={styles.container}>
        <div className={styles.containerNav}>
          {isMobile && (
            <img
              src={ArrowLeft}
              onClick={() => setSearchParams({})}
            />
          )}
          <span className={styles.containerNavTitle}>
            {t('Support.Support', {
              defaultValue: 'Support',
            })}
          </span>
        </div>
        <OpenNewRequest />
        <OpenHistory />
        <FAQ />
        <DrawerModal
          isShow={SupportMode.includes(pageMode as SupportModeEnum)}
          onClose={() => setSearchParams({})}
          goBack={pageChat ? () => goBackChatSupport() : undefined}
        >
          {pageMode === SupportModeEnum.Faq && <FaqListQuestions />}
          {pageMode === SupportModeEnum.CreateNewRequest && (
            <CreateNewRequest />
          )}
          {pageMode === SupportModeEnum.History && <History />}
          {pageMode === SupportModeEnum.ChatSupport && <ChatWithSupport />}
        </DrawerModal>
      </div>
    </AnimateWrapper>
  );
};

export default Support;
