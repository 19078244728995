import Account from "./Account";
import { AccountProvider } from "./provider";

export const AccountComponent = () => (
  <AccountProvider>
    <Account />
  </AccountProvider>
);

// export default AccountComponent;
