import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  useForgotLoginControllerMutation,
  useRestoreLoginControllerMutation,
} from "api/endpoints/auth";
import { useTranslationProvider } from "providers/translation/translation.provider";
import ForgotForm from "../forgotForm";
import styles from "./style.module.scss";
import { useSnackBar } from "providers/snackBar.provider";
import helper from "services/helper";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address"
    ),
  code: yup.string().matches(/^\d{6}$/, "Code should be exactly 6 digits long"),
});

type ForgotUserName = "Email" | "RecoveryCode";

const ForgotLogin = () => {
  const { t } = useTranslationProvider();
  const { setSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [forgotLoginMutation, { isLoading: forgotLoginIsLoading }] =
    useForgotLoginControllerMutation();
  const [restoreLoginMutation, { isLoading: restoreLoginIsLoading }] =
    useRestoreLoginControllerMutation();
  const [pageMode, setPageMode] = useState<ForgotUserName>("Email");
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [validError, setValidError] = useState<string>("");

  const forgotEmail = async () => {
    try {
      await schema.validate({ email }, { abortEarly: false });
      await forgotLoginMutation({ email }).unwrap();
      setPageMode("RecoveryCode");
    } catch (e: any) {
      console.log("error", e);
      if (e.name === "ValidationError") {
        setValidError(e.message);
      } else {
        setSnackBar({
          type: "error",
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  };

  const sendRecoveryCode = async () => {
    try {
      await schema.validate({ code }, { abortEarly: false });
      const res = await restoreLoginMutation({
        email: email,
        code: code,
      }).unwrap();
      updateResponse(res?.restoreState);
    } catch (e: any) {
      console.log("error", e);
      if (e.name === "ValidationError") {
        setValidError(e.message);
      } else {
        setSnackBar({
          type: "error",
          message: helper.formatErrors(e.data),
          isShow: true,
        });
      }
    }
  };

  const updateResponse = (response) => {
    if (response === "Code accepted") {
      setSnackBar({
        type: "success",
        message: t("Login.Please check your email", {
          defaultValue:
            "Please check your email, we have sent you your username there.",
        }),
        isShow: true,
        width: "350px",
      });
      navigate("/login");
    } else {
      const message =
        response === "Code expired"
          ? t("common.The entered code is expired", {
              defaultValue: "The entered code is expired",
            })
          : t("common.The entered code is not correct", {
              defaultValue: "The entered code is not correct",
            });
      setSnackBar({
        type: "error",
        message: message,
        isShow: true,
      });
    }
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value.trim());
    setValidError("");
  };
  const handleEmailOnCancel = () => {
    navigate("/login");
  };

  const handleOnChangeCode = (e) => {
    setCode(e.target.value.trim());
    setValidError("");
  };
  const handleCodeOnCancel = () => {
    setPageMode("Email");
  };
  return (
    <div className={styles.conteiner}>
      <div className={styles.conteiner_content}>
        {pageMode === "Email" && (
          <ForgotForm
            title={t("Login.Username recovery", {
              defaultValue: "Username recovery",
            })}
            subTitle={t("Login.Please enter your email", {
              defaultValue:
                "Please enter your email, and we will send a recovery code to that email address.",
            })}
            inputLabel={t("Settings.Email", {
              defaultValue: "Email",
            })}
            inputValue={email}
            handleOnChange={handleOnChangeEmail}
            isLoading={forgotLoginIsLoading}
            sumbitButtonText={t("coomon.Send code", {
              defaultValue: "Send code",
            })}
            onSumbit={forgotEmail}
            cancelButtonText={t("Login.Back to login", {
              defaultValue: "Back to login",
            })}
            onCancel={handleEmailOnCancel}
            error={validError}
          />
        )}
        {pageMode === "RecoveryCode" && (
          <ForgotForm
            title={t("Login.Enter recovery code", {
              defaultValue: "Enter recovery code",
            })}
            subTitle={t("Login.After you verify your account ", {
              defaultValue:
                "After you verify your account with the code we will send your username to your email.",
            })}
            inputLabel={t("Login.Recovery code", {
              defaultValue: "Recovery code",
            })}
            inputValue={code}
            handleOnChange={handleOnChangeCode}
            isLoading={restoreLoginIsLoading}
            sumbitButtonText={t("common.Confirm", {
              defaultValue: "Confirm",
            })}
            onSumbit={sendRecoveryCode}
            cancelButtonText={t("common.Back", {
              defaultValue: "Back",
            })}
            onCancel={handleCodeOnCancel}
            error={validError}
            inputMaxLength={6}
          />
        )}
      </div>
    </div>
  );
};

export default ForgotLogin;
