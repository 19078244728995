export enum RewardMode {
  RewardsLimits = 'RewardsLimits',
  RedeemPoints = 'RedeemPoints',
  TransferPoints = 'TransferPoints',
  ProfessionalCards = 'ProfessionalCards',
  Filters = 'Filters',
  Brands = 'Brands',
}

export enum RewardLimitsAndConditionsToolTip {
  SpendingRewards = `Every dollar you spend earns you points! For every dollar spent, you'll receive 1 point. These points are then multiplied by the cashback percentage. So, the more you spend, the more points you earn, boosting your cashback rewards!`,
  SpecialSpendingRewardsCategories = `With every dollar spent, you earn 1 point. Later, these points are multiplied by the cashback percentage (multiplied by 100) applicable to specific transaction MCC/SIC codes. However, there's a spending limit in place to keep things in check!`,
  SpendingCredit = `It's our way of giving back! When you spend in specific categories, we've got a treat for you. You'll earn 100 points for every dollar spent within these categories, up to a certain limit. Enjoy earning rewards as you shop!`,
  EarnedCreditRewards = `We calculate your APR and transform it into valuable credit points for you to enjoy. Start earning rewards simply by using our banking services.`,
  WelcomeBonus = `We add up all your deposits during a specific period, subtract the total amount you've transferred out via ACH, and then multiply that by a certain percentage. The resulting amount is converted into points, with $1 equaling 100 points. It's our way of giving back for your activity!`,
  WelcomeSpendingBonus = `This feature is all about rewarding you. Here's how it works: For every dollar you spend, you'll earn 100 points. Once you've spent a specific amount within the given period, we'll convert those amounts into points just for you! It's our way of saying thank you for choosing us.`,
  EarnedSpendingCredit = `It's our way of giving back! When you spend in specific categories, we've got a treat for you. You'll earn 100 points for every dollar spent within these categories, up to a certain limit. Enjoy earning rewards as you shop!`,
}
