import * as yup from 'yup';
import { PayeeType } from 'api/endpoints/fundsMovement';

export const createPayeeAddSchema = (payees: PayeeType[]) => {
  return yup.object().shape({
    accountName: yup.string().required('Account name is required'),
    accountNumber: yup
      .string()
      .max(20, 'Account number must be at most 20 characters')
      .matches(/^\d+$/, 'Enter numbers only')
      .test(
        'payee-check',
        'Payee with this account number already exists',
        function (value) {
          return !payees.some((payee) => payee.accountNumber === value);
        }
      ),
  });
};
