import { Typography } from 'shared/ui/typography';
import { CurrencyInput2 } from 'shared/ui/inputs';
import styles from './style.module.scss';
import { Dayjs } from 'dayjs';
import { FormikProps } from 'formik';
import { FormHelperText } from '@mui/material';

interface FormValues {
  date: [Dayjs | null, Dayjs | null];
  amountFrom: string;
  amountTo: string;
}

interface FilterByAmountProps {
  formik: FormikProps<FormValues>;
}
export const FilterByAmount = ({ formik }: FilterByAmountProps) => {
  return (
    <div className={styles.container}>
      <Typography>Amount range</Typography>
      <div>
        <div className={styles.box}>
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='From'
            placeholder='$0.00'
            getCaretBoundary={() => [false]}
            name='amountFrom'
            value={formik.values.amountFrom}
            onChange={(value) => {
              formik.setFieldValue('amountFrom', value);
            }}
            onBlur={formik.handleBlur}
            error={!!formik.errors.amountTo}
          />
          <CurrencyInput2
            fullWidth
            variant='outlined'
            label='To'
            placeholder='$0.00'
            getCaretBoundary={() => [false]}
            name='amountTo'
            value={formik.values.amountTo}
            onChange={(value) => {
              formik.setFieldValue('amountTo', value);
            }}
            onBlur={formik.handleBlur}
            error={!!formik.errors.amountTo}
          />
        </div>
        <FormHelperText error={true}>{formik.errors.amountTo}</FormHelperText>
      </div>
    </div>
  );
};
