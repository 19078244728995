import { Popup } from "../popup";
import Button from "@mui/material/Button";
import React, { useRef, useState } from "react";
import styles from "./style.module.scss";
import { TextField } from "@mui/material";

interface Props {
  onApply(value?: string): void;
  open: boolean;
  applyButtonText: string;
  applyError: boolean;
  onClose(): void;
  dialogText?: string | React.ReactNode;
  applyButtonType: "text" | "contained" | "outlined";
  onCancel(): void;
  dialogTitle?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  cancelButtonType: "text" | "contained" | "outlined";
  cancelError: boolean;
  modalType?: string;
  placeholder?: string;
  defaultValue?: string;
  hideButtons?: boolean;
}
export const Dialog = ({
  open,
  onClose,
  onApply,
  onCancel,
  dialogText,
  applyButtonText,
  applyButtonType,
  applyError,
  cancelButtonText,
  cancelButtonType,
  dialogTitle,
  cancelError,
  modalType,
  placeholder,
  hideButtons,
  defaultValue = "",
}: Props) => {
  const [textFieldValue, setTextFieldValue] = useState<string>();

  const handleApply = () => {
    onApply(textFieldValue !== undefined ? textFieldValue : defaultValue);
    setTextFieldValue(undefined);
  };
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setTextFieldValue(undefined);
    onClose();
  };
  if (open) {
    return (
      <Popup onClose={onClose} stylesWrapper={styles.popup}>
        <div className={styles.content}>
          {dialogTitle && (
            <div className={styles.dialogTitle}>{dialogTitle}</div>
          )}

          {dialogText && <div className={styles.dialogText}>{dialogText}</div>}
          {modalType === "withTextfield" && (
            <TextField
              type="text"
              placeholder={placeholder}
              value={textFieldValue}
              onChange={(e) => setTextFieldValue(e.target.value)}
              defaultValue={defaultValue}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{
                height: 54,
              }}
            />
          )}
          {!hideButtons && (
            <div className={styles.controls}>
              {cancelButtonText && (
                <Button
                  variant="text"
                  color={cancelError ? "error" : undefined}
                  onClick={handleCancel}
                >
                  {cancelButtonText}
                </Button>
              )}

              <Button variant="contained" onClick={handleApply}>
                {applyButtonText}
              </Button>
            </div>
          )}
        </div>
      </Popup>
    );
  }

  return null;
};
