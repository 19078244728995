import React, { useState, useEffect, useMemo } from 'react';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { RewardsProvider } from 'pages/reward/provider/useRewards';
import { GiftCardsProvider } from './provider/useGiftCards';
import { ListButton } from 'shared/ui/lists';
import { Button } from 'shared/ui/buttons';
import { ChevronRightIcon } from 'shared/ui/icons';
import { useNavigate } from 'react-router-dom';
import { appRoutes, RewardsRouteSubRoute } from 'routes';
import styles from './styles.module.scss';
import { useMediaQuery, Divider, Tabs, Tab } from '@mui/material';
import { Typography } from 'shared/ui/typography';
import { GiftCardIcon } from 'shared/ui/icons';
import { ClockRestoreIcon } from 'shared/ui/icons';
import { DashboardIcon } from 'shared/ui/icons';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../providers/user.provider';

const Reward = () => {
  const { t } = useTranslationProvider();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const location = useLocation();
  const { isMainBusiness } = useCurrentUser();

  const activeTab = location?.pathname.split('/').slice(2).join('/');

  const [tab, setTab] = useState<string>(activeTab);

  useEffect(() => {
    if (!isMobile && !activeTab) {
      const newTab = RewardsRouteSubRoute.dashboard;
      navigate(appRoutes.rewards(newTab));
      setTab(newTab);
    } else if (activeTab) {
      setTab(activeTab);
    }
  }, [isMobile, activeTab]);

  const pageRewards = useMemo(() => {
    return [
      {
        id: RewardsRouteSubRoute.dashboard,
        label: t('rewards.title1', {
          defaultValue: 'Dashboard',
        }),
        img: <DashboardIcon />,
        isHidden: !isMainBusiness,
      },
      {
        id: RewardsRouteSubRoute.history,
        label: t('rewards.titlte2', {
          defaultValue: 'History',
        }),
        img: <ClockRestoreIcon />,
        isHidden: !isMainBusiness,
      },
      {
        id: RewardsRouteSubRoute.myGiftCard,
        label: 'My Gift Card',
        img: <GiftCardIcon />,
        isHidden: false,
      },
    ].filter((i) => !i.isHidden);
  }, [isMainBusiness]);

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newTab: RewardsRouteSubRoute
  ) => {
    navigate(appRoutes.rewards(newTab));
    setTab(newTab);
  };

  const renderTabForDesctop = (
    <>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        classes={{
          indicator: styles.tabIndicator,
        }}
      >
        {pageRewards.map((page) => (
          <Tab
            key={page.id}
            label={page.label}
            value={page.id}
            classes={{ selected: styles.tabSelected }}
          />
        ))}
      </Tabs>
      <Divider />
    </>
  );

  const renderBtnForMob = !activeTab && (
    <ListButton className={styles.listBtn}>
      {pageRewards.map((page) => (
        <Button
          key={page.id}
          startIcon={page.img}
          endIcon={<ChevronRightIcon />}
          onClick={() => navigate(appRoutes.rewards(page.id))}
        >
          {page.label}
        </Button>
      ))}
    </ListButton>
  );
  return (
    <RewardsProvider>
      <GiftCardsProvider>
        <div className={styles.container}>
          {!isMobile && (
            <Typography className={styles.title}>
              {t('rewards.title1', {
                defaultValue: 'Rewards',
              })}
            </Typography>
          )}
          {!isMobile ? renderTabForDesctop : renderBtnForMob}
        </div>
      </GiftCardsProvider>
    </RewardsProvider>
  );
};

export default Reward;
