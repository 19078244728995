import { TransitionAnimation } from 'shared/ui/animations';
import styles from './style.module.scss';
import { ListButton } from 'shared/ui/lists';
import { YearDataApi } from 'api/endpoints/statements';
import { Button } from 'shared/ui/buttons';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Period } from 'api/endpoints/statements';

interface MonthListProps {
  statementsPeriods: YearDataApi[] | undefined;
  currentYear: string;
  handleClick: (month: Period) => void;
}

export const MonthList = (props: MonthListProps) => {
  const { statementsPeriods, currentYear, handleClick } = props;

  const renderList = () => {
    return statementsPeriods
      ?.find((item) => item.year === currentYear)
      ?.periods?.slice()
      .reverse()
      ?.map((month) => (
        <Button
          key={month.value}
          endIcon={<FileDownloadIcon className={styles.icon} />}
          onClick={() => handleClick(month)}
        >
          {month.name}
        </Button>
      ));
  };

  return (
    <TransitionAnimation>
      <ListButton className={styles.listBtn}>{renderList()}</ListButton>
    </TransitionAnimation>
  );
};
