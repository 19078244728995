import * as yup from 'yup';

export const ExternalPayeeAddSchema = yup.object().shape({
  payeeName: yup.string().required('Payee name is required'),
  payeeType: yup.string().required('Payee type is required'),
  accountNumber: yup
    .string()
    .required('Account number is required')
    .min(9, 'Account number must be at least 9 characters')
    .matches(/^\d+$/, 'Enter numbers only'),
  routingNumber: yup
    .string()
    .required('Routing number is required')
    .length(9, 'Routing number must be exactly 9 characters')
    .matches(/^\d+$/, 'Enter numbers only'),
});
