import React from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';

interface EmptyTableProps {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
}

export const EmptyTable = (props: EmptyTableProps) => {
  const { title, subTitle, icon } = props;

  return (
    <TransitionAnimation>
      <div className={styles.wrapper}>
        {icon}
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.subTitle}>{subTitle}</Typography>
      </div>
    </TransitionAnimation>
  );
};
