import * as yup from 'yup';

export const TransactionFilterSchema = yup.object().shape({
  date: yup
    .array()
    .of(yup.date().nullable())
    .test('both-dates-not-null', function (value) {
      if (Array.isArray(value) && value.length === 2) {
        if (!value[0] || !value[1]) {
          return false;
        }
      }

      return true;
    }),
});
