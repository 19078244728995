import NavigateButton from "./button/NavigateButton";
import { AnimateWrapper } from "./animate-wrapper";
import CheckCircle from "../assets/svg/check-circle.svg";
import { Button } from "@mui/material";

interface SuccessProps {
  label: string;
  infotext: string;
  buttonText: string;
  handleSubmit: () => void;
}

const Success = (props: SuccessProps) => {
  const label = props?.label;
  const infotext = props?.infotext;
  const buttonText = props?.buttonText || "Go to";
  const handleSubmit = props?.handleSubmit;

  return (
    <AnimateWrapper className="fade">
      <div className="flex flex-col xs:flex-col xs:items-center md:items-start pl-9">
        <div className="flex flex-col mb-40">
          <img src={CheckCircle} width={126} height={126} />
          <AnimateWrapper className="fade" delay={50}>
            <p className="text-2xl mt-10">{label}</p>
          </AnimateWrapper>
          <AnimateWrapper className="fade" delay={150}>
            <div>
              <p className="text-stone-600 w-80 text-base mt-4">{infotext}</p>
            </div>
            <div className="mt-10">
              <Button onClick={handleSubmit} variant="contained">
                {buttonText}
              </Button>
            </div>
          </AnimateWrapper>
        </div>
      </div>
    </AnimateWrapper>
  );
};

export default Success;
