import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface ContextProps {
  isShowSuccess: boolean;
  setIsShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  isShowWarning: boolean;
  setIsShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
  massage: string;
  setMassage: React.Dispatch<React.SetStateAction<any>>;
  isShowDrawer: boolean;
  setIsShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const AccountProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const [isShowWarning, setIsShowWarning] = useState<boolean>(false);
  const [massage, setMassage] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const mode = searchParams.get("mode");
  const pageMode = searchParams.get("pageMode");
  const id = searchParams.get("id");

  useEffect(() => {
    if (mode && isShowDrawer) {
      setErrors({});
      setIsShowDrawer(false);
      setTimeout(() => {
        setIsShowDrawer(true);
      }, 500);
    }
    if (!pageMode) {
      setIsShowDrawer(false);
    }
  }, [id, mode, pageMode]);

  const value = useMemo((): ContextProps => {
    return {
      isShowSuccess,
      setIsShowSuccess,
      isShowWarning,
      setIsShowWarning,
      massage,
      setMassage,
      isShowDrawer,
      setIsShowDrawer,
      errors,
      setErrors,
    };
  }, [isShowSuccess, isShowWarning, isShowDrawer, errors]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
export const useAccount = () => React.useContext(Context) as ContextProps;
