interface PlaceDetails {
  fullAddress?: string
  localityLong?: string
  countryShort?: string
  countryLong?: string
  postalCode?: string
  adminArea1Long?: string
  adminArea1Short?: string
}

export const mapGooglePlace = (
  place: google.maps.places.PlaceResult
): PlaceDetails | null => {
  if (!place.address_components) return null

  const result: PlaceDetails = {}

  for (const component of place.address_components) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'street_number':
        result.fullAddress = result.fullAddress
          ? `${result.fullAddress} ${component.long_name}`
          : component.long_name
        break
      case 'route':
        result.fullAddress = result.fullAddress
          ? `${result.fullAddress} ${component.long_name}`
          : component.long_name
        break
      case 'locality':
        result.localityLong = component.long_name
        break
      case 'administrative_area_level_1':
        result.adminArea1Long = component.long_name
        result.adminArea1Short = component.short_name
        break
      case 'postal_code':
        result.postalCode = component.long_name
        break
      case 'country':
        result.countryLong = component.long_name
        result.countryShort = component.short_name
        break
    }
  }

  return result
}
