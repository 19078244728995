import React, { PropsWithChildren, useMemo, useState, ReactNode } from "react";
import { Snackbar, Alert, useMediaQuery, useTheme } from "@mui/material";

type SnackBarType = "success" | "error" | "info";

interface SnackBar {
  type: SnackBarType;
  message: string | ReactNode;
  isShow: boolean;
  width?: string;
}
interface ContextProps {
  snackBar: SnackBar;
  setSnackBar: React.Dispatch<React.SetStateAction<SnackBar>>;
}

const Context = React.createContext<ContextProps | undefined>(undefined);

export const SnackBarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints?.up("sm"));
  const [snackBar, setSnackBar] = useState<SnackBar>({
    type: "success",
    message: "",
    isShow: false,
  });

  const value = useMemo((): ContextProps => {
    return {
      snackBar,
      setSnackBar,
    };
  }, [snackBar]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar((prevSnackBar) => ({ ...prevSnackBar, isShow: false }));
  };

  const styledWrapper = snackBar?.width
    ? {
        width: matches ? snackBar?.width : "96%",
        ".MuiAlert-icon": {
          alignItems: "center",
          justifyContent: "center",
        },
        ".MuiAlert-action": {
          alignItems: "center",
          justifyContent: "center",
        },
      }
    : undefined;

  const renderSnackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        transitionDuration={{ enter: 1000, exit: 1000 }}
        open={snackBar?.isShow}
        onClose={handleClose}
        sx={styledWrapper}
      >
        <Alert
          onClose={handleClose}
          severity={snackBar?.type}
          sx={{ width: "100%" }}
        >
          {snackBar?.message}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <Context.Provider value={value}>
      {children}
      {renderSnackBar()}
    </Context.Provider>
  );
};
export const useSnackBar = () => React.useContext(Context) as ContextProps;
