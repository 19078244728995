import { TransitionAnimation } from 'shared/ui/animations';
import { TextInput } from 'shared/ui/inputs';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import { useSnackBar } from 'providers/snackBar.provider';
import { useCreateFinancialAccountControllerMutation } from 'api/endpoints/financialAccounts';
import { useFinancialAccountsControllerQuery } from 'api/endpoints/financialAccounts';
import { useFormik } from 'formik';
import { RenameFinAccountSchema } from 'pages/accountComponent/Actions/More/renameFinAccount/validationRenameSchema';
import { useBoolean } from 'shared/hooks/useBoolean';
import { useDrawerBehavior } from 'providers/drawer-behavior';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useSearchParams } from 'react-router-dom';

export const AddNewAccount = () => {
  const { setSnackBar } = useSnackBar();
  const drawerBehavior = useDrawerBehavior();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '';

  const { refetch: refetchFinancialAccounts } =
    useFinancialAccountsControllerQuery({});

  const [createAccountMutation] = useCreateFinancialAccountControllerMutation();

  const loadingBool = useBoolean();

  const formik = useFormik({
    initialValues: {
      accountName: '',
    },
    validateOnMount: true,
    validationSchema: RenameFinAccountSchema,
    onSubmit: (form) => {
      createAccount(form.accountName);
    },
  });

  const createAccount = async (name: string) => {
    try {
      loadingBool.setTrue();
      drawerBehavior.lockForClose();

      await createAccountMutation({ Name: name }).unwrap();

      await refetchFinancialAccounts();
      loadingBool.setFalse();
      drawerBehavior.unlockForClose();
      setSearchParams({ page });
    } catch (e: any) {
      loadingBool.setFalse();
      drawerBehavior.unlockForClose();
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <Typography className={styles.title}>
          Create name for the account
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.form}
        >
          <TextInput
            label='Account name'
            autoComplete='off'
            {...formik.getFieldProps('accountName')}
            error={formik.touched.accountName && formik.errors.accountName}
            inputProps={{ maxLength: 30 }}
          />
          <Button
            className={styles.btn}
            type='submit'
            loading={loadingBool.value}
            disabled={!formik.isValid}
          >
            Apply
          </Button>
        </form>
      </div>
    </TransitionAnimation>
  );
};
